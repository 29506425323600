import { t } from "@lingui/macro";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import MediaFavicon from "src/components/MediaFavicon";
import { EPipelineMedia } from "src/enums/pipeline";
import { ISocialMedium } from "src/types/socialAuth";

interface IProps {
  provider: ISocialMedium | EPipelineMedia;
  size?: number;
}

const ProviderWithIcon: FC<IProps> = ({ provider, size }) => (
  <Stack spacing={0.8} direction="row" alignItems="center" display="inline-flex">
    <MediaFavicon media={provider} size={size} borderRadius={8} />

    <Typography>
      <>
        {
          {
            datafan: t`DataFan`,
            elama: t`eLama`,
            facebook: t`Facebook`,
            google: t`Google`,
            instagram: t`Instagram`,
            mytarget: t`myTarget`,
            ok: t`OK`,
            smmplanner: t`SMMplanner`,
            telegram: t`Telegram`,
            tiktok: t`TikTok`,
            vk: t`VK`,
            yandex: t`Yandex`,
          }[provider]
        }
      </>
    </Typography>
  </Stack>
);

export default ProviderWithIcon;
