import { t, Trans } from "@lingui/macro";
import MinimizeIcon from "@mui/icons-material/Minimize";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useCallback, useMemo } from "react";
import UiVirtualTable from "src/components/UiVirtualTable";
import { PrivateFC } from "src/types/helpers";
import { IProjectExtended } from "src/types/project";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import TableProjectsCellComponent from "../TableComponents/TableProjectsCellComponent";

const Projects: PrivateFC = ({ user }) => {
  const { data: projects } = useQueryProjects();

  const sortedProjects = useMemo(
    () =>
      projects &&
      [...projects].sort((a, b) => {
        if (a.role === "owner") {
          return -1;
        }

        return a.creator.user_id > b.creator.user_id ? 1 : -1;
      }),
    [projects],
  );

  const renderCellComponent = useCallback((props) => <TableProjectsCellComponent projects={projects} {...props} />, [projects]);

  const getRows = useCallback(() => sortedProjects, [sortedProjects]);

  return useMemo(
    () => (
      <UiVirtualTable
        gridProps={{
          columns: [
            { name: "name", title: t`Project` },
            {
              getCellValue: (project: IProjectExtended) => (
                <Typography variant="body1">
                  {project.creator.active_subscription?.status === 2 ? (
                    project.creator.active_subscription.tariff_on_period?.tariff.name
                  ) : (
                    <MinimizeIcon color="disabled" />
                  )}
                </Typography>
              ),
              name: "active_subscription",
              title: t`Tariff`,
            },
            {
              getCellValue: (project: IProjectExtended) =>
                project.creator.user_id === user.id ? <Trans id="You" /> : project.creator.humand_name,
              name: "creator",
              title: t`Owner`,
            },
            {
              getCellValue: (project: IProjectExtended) => {
                const availableDatasets = project.creator.active_subscription
                  ? Math.max(
                      project.creator.active_subscription.limits?.pipelines -
                        project.creator.active_subscription.limits_usage?.pipelines,
                      0,
                    )
                  : 0;

                return (
                  <Box textAlign="center">
                    {availableDatasets ? (
                      <Typography variant="body1">{availableDatasets}</Typography>
                    ) : (
                      <MinimizeIcon color="disabled" />
                    )}
                  </Box>
                );
              },
              name: "pipelines",
              title: t`Publics / Ads`,
            },
          ],
          rows: sortedProjects || [],
        }}
        tableStateProps={{
          getRows: getRows,
          infiniteScrolling: true,
          loading: false,
          pageSize: sortedProjects?.length,
          skip: 0,
          totalRowCount: sortedProjects?.length || 0,
        }}
        tableProps={{
          cellComponent: renderCellComponent,
          estimatedRowHeight: 42,
          height: "inherit",
          minHeight: 300,
        }}
      />
    ),
    [getRows, renderCellComponent, sortedProjects, user.id],
  );
};

export default Projects;
