import { useQueryClient } from "react-query";
import { IProject, IProjectExtended } from "src/types/project";
import useProjectAggregation from "src/utils/hoooks/useProjectAggregation";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "project-get";

export default function useQueryProjectGet(
  projectId: IProject["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IProject, IProjectExtended>,
) {
  const aggregateProject = useProjectAggregation();

  return useQueryBase<IProject, IProjectExtended>(
    [QUERY_KEY, projectId],
    {
      enabled: typeof projectId !== "undefined",
      select: (data) => aggregateProject(data),
      ...queryOptions,
    },
    {
      method: "GET",
      url: `project/${projectId}/`,
    },
  );
}

export function useQueryProjectInvalidate() {
  const clientQuery = useQueryClient();

  return (projectId: IProject["id"]) => clientQuery.invalidateQueries([QUERY_KEY, projectId]);
}

export function useQueryProjectUpdateData() {
  const queryClient = useQueryClient();

  return (project: IProject) => {
    const data = queryClient.getQueryData([QUERY_KEY, project.id]);

    if (data) {
      queryClient.setQueryData([QUERY_KEY, project.id], (data) => (data ? project : data));
    }
  };
}
