import { t } from "@lingui/macro";
import { all, call, put, select } from "redux-saga/effects";
import {
  billingPaymentMethodsDeleteApi,
  billingPaymentMethodsGet,
  billingPaymentMethodsGetApi,
  billingTrialMigratePostApi,
  IBillingPaymentMethodsDeleteR,
  IRootState,
} from "src/redux/index";
import { prepareErrorMessage, sagaCompile } from "src/utils";

import {
  billingInternalTransactionsGetApi,
  billingInvoiceGetApi,
  billingPaymentHistoryGetApi,
  billingPaypalTransactionCompletePostApi,
  IBillingInternalTransactionsGetR,
  IBillingInvoiceGetR,
  IBillingPaymentHistoryGetR,
  IBillingPaypalTransactionCompletePostR,
  IModulesActionMap,
  userGet,
} from "../index";
import { popupAdd } from "../popups";
import { apiRequestAuthorized } from "../shared";
import { IBillingCalculateSubscriptionPostR, IBillingInvoicePostR, IBillingPromocodePostR } from "./actions";
import {
  billingActivetePromocodePostApi,
  billingCalculateSubscriptionPostApi,
  billingFreeRenewalSubscriptionPostApi,
  billingInvoicePostApi,
  billingSubscriptionsGetApi,
  billingTariffsGetApi,
  billingTariffsOptionsGetApi,
} from "./api";

const { putTake } = sagaCompile<IModulesActionMap>();

const { takeEveryRequest } = sagaCompile<IModulesActionMap>();

export function* billingTariffsGetSaga() {
  yield takeEveryRequest(["BILLING_TARIFFS_GET__R", "BILLING_TARIFFS_GET__S", "BILLING_TARIFFS_GET__F"], {
    *onR() {
      const currentUser: any = yield select((state: IRootState) => state.modules.user);

      const res: any = currentUser.id
        ? yield call(apiRequestAuthorized, billingTariffsGetApi)
        : yield call(billingTariffsGetApi, {});

      return { data: res.data };
    },
  });
}

export function* billingTariffsOptionsGetSaga() {
  yield takeEveryRequest(
    ["BILLING_TARIFFS_OPTIONS_GET__R", "BILLING_TARIFFS_OPTIONS_GET__S", "BILLING_TARIFFS_OPTIONS_GET__F"],
    {
      *onR() {
        const res: any = yield call(billingTariffsOptionsGetApi);

        return { data: res.data };
      },
    },
  );
}

export function* billingSubscriptionsGetSaga() {
  yield takeEveryRequest(["BILLING_SUBSCRIPTIONS_GET__R", "BILLING_SUBSCRIPTIONS_GET__S", "BILLING_SUBSCRIPTIONS_GET__F"], {
    *onR() {
      const res: any = yield call(apiRequestAuthorized, billingSubscriptionsGetApi, {});
      return { data: res.data };
    },
  });
}

export function* billingInvoicePostSaga() {
  yield takeEveryRequest(["BILLING_INVOICE_POST__R", "BILLING_INVOICE_POST__S", "BILLING_INVOICE_POST__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IBillingInvoicePostR) {
      const { id, system, options, promocode, renewalWithFreeDays, autoRenewalId, savePaymentMethod } = action.payload;

      const res: any = yield call(apiRequestAuthorized, billingInvoicePostApi, {
        autoRenewalId,
        id,
        options,
        promocode,
        renewalWithFreeDays,
        savePaymentMethod,
        system,
      });
      return { model: res.data };
    },
  });
}

export function* billingPromocodePostSaga() {
  yield takeEveryRequest(["BILLING_PROMOCODE_POST__R", "BILLING_PROMOCODE_POST__S", "BILLING_PROMOCODE_POST__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IBillingPromocodePostR) {
      const { promocode, currency } = action.payload;

      yield call(apiRequestAuthorized, billingActivetePromocodePostApi, {
        currency,
        promocode,
      });

      yield put(
        popupAdd({
          text: t`Promocode was applied`,
        }),
      );

      return { value: promocode };
    },
  });
}

export function* billingFreeRenewalSubscriptionPostSaga() {
  yield takeEveryRequest(
    [
      "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R",
      "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S",
      "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__F",
    ],
    {
      *onF(action: any) {
        const { error } = action;
        yield put(
          popupAdd({
            importance: "error",
            text: prepareErrorMessage(error.response.data),
          }),
        );
      },
      *onR() {
        yield call(apiRequestAuthorized, billingFreeRenewalSubscriptionPostApi, {});
      },
      *onS() {
        yield put(
          popupAdd({
            text: t`Tariff successfully renewal`,
          }),
        );
        yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);
      },
    },
  );
}

export function* billingCalculateSubscriptionPostSaga() {
  yield takeEveryRequest(
    [
      "BILLING_CALCULATE_SUBSCRIPTION_POST__R",
      "BILLING_CALCULATE_SUBSCRIPTION_POST__S",
      "BILLING_CALCULATE_SUBSCRIPTION_POST__F",
    ],
    {
      *onR(action: IBillingCalculateSubscriptionPostR) {
        const { tariffId, options, currency, promocode, renewalWithFreeDays } = action.payload;
        const res: any = yield call(apiRequestAuthorized, billingCalculateSubscriptionPostApi, {
          currency,
          options,
          promocode,
          renewalWithFreeDays,
          tariffId,
        });
        return tariffId ? { create: res.data } : { update: res.data };
      },
    },
  );
}

export function* billingPaymentHistoryGetSaga() {
  yield takeEveryRequest(
    ["BILLING_PAYMENT_HISTORY_GET__R", "BILLING_PAYMENT_HISTORY_GET__S", "BILLING_PAYMENT_HISTORY_GET__F"],
    {
      *onR(action: IBillingPaymentHistoryGetR) {
        const { limit, offset, ordering } = action.payload;
        const res: any = yield call(apiRequestAuthorized, billingPaymentHistoryGetApi, {
          limit,
          offset,
          ordering,
        });
        return {
          invoices: res.data.results,
          meta: {
            count: res.data.count,
            offset,
          },
        };
      },
    },
  );
}

export function* billingPaymentMethodsGetSaga() {
  yield takeEveryRequest(
    ["BILLING_PAYMENT_METHODS_GET__R", "BILLING_PAYMENT_METHODS_GET__S", "BILLING_PAYMENT_METHODS_GET__F"],
    {
      *onF(action: any) {
        const { error } = action;
        yield put(
          popupAdd({
            importance: "error",
            text: prepareErrorMessage(error.response.data),
          }),
        );
      },
      *onR() {
        const res: any = yield call(apiRequestAuthorized, billingPaymentMethodsGetApi);
        return res.data.results;
      },
    },
  );
}

export function* billingPaymentMethodsDeleteSaga() {
  yield takeEveryRequest(
    ["BILLING_PAYMENT_METHODS_DELETE__R", "BILLING_PAYMENT_METHODS_DELETE__S", "BILLING_PAYMENT_METHODS_DELETE__F"],
    {
      *onF(action: any) {
        const { error } = action;
        yield put(
          popupAdd({
            importance: "error",
            text: prepareErrorMessage(error.response.data),
          }),
        );
      },
      *onR(action: IBillingPaymentMethodsDeleteR) {
        yield call(apiRequestAuthorized, billingPaymentMethodsDeleteApi, { id: action.payload.id });
      },
      *onS() {
        yield put(
          popupAdd({
            text: t`Autopayment disabled`,
          }),
        );
        yield all([
          putTake(billingPaymentMethodsGet({}), ["BILLING_PAYMENT_METHODS_GET__S", "BILLING_PAYMENT_METHODS_GET__F"]),
          putTake(userGet({}), ["USER_GET__S", "USER_GET__F"]),
        ]);
      },
    },
  );
}

export function* billingInternalTransactionsGetSaga() {
  yield takeEveryRequest(
    ["BILLING_INTERNAL_TRANSACTIONS_GET__R", "BILLING_INTERNAL_TRANSACTIONS_GET__S", "BILLING_INTERNAL_TRANSACTIONS_GET__F"],
    {
      *onR(action: IBillingInternalTransactionsGetR) {
        const { limit, offset, ordering } = action.payload;
        const res: any = yield call(apiRequestAuthorized, billingInternalTransactionsGetApi, {
          limit,
          offset,
          ordering,
        });
        return {
          items: res.data.results,
          meta: {
            count: res.data.count,
            offset,
          },
        };
      },
    },
  );
}

export function* billingInvoiceGetSaga() {
  yield takeEveryRequest(["BILLING_INVOICE_GET__R", "BILLING_INVOICE_GET__S", "BILLING_INVOICE_GET__F"], {
    *onR(action: IBillingInvoiceGetR) {
      const { secret } = action.payload;
      const res: any = yield call(apiRequestAuthorized, billingInvoiceGetApi, {
        secret,
      });

      return { model: res.data.results[0] };
    },
  });
}

export function* billingPaypalTransactionCompletePostSaga() {
  yield takeEveryRequest(
    [
      "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R",
      "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__S",
      "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__F",
    ],
    {
      *onF(action: any) {
        const { error } = action;
        yield put(
          popupAdd({
            importance: "error",
            text: prepareErrorMessage(error.response.data),
          }),
        );
      },
      *onR(action: IBillingPaypalTransactionCompletePostR) {
        const { order_id, is } = action.payload;
        yield call(apiRequestAuthorized, billingPaypalTransactionCompletePostApi, {
          is,
          order_id,
        });
      },
    },
  );
}

export function* billingTrialMigratePostSaga() {
  yield takeEveryRequest(["BILLING_TRIAL_MIGRATE_POST__R", "BILLING_TRIAL_MIGRATE_POST__S", "BILLING_TRIAL_MIGRATE_POST__F"], {
    *onF(action: any) {
      const { error } = action;

      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR() {
      yield call(apiRequestAuthorized, billingTrialMigratePostApi);
    },
    *onS() {
      yield put(
        popupAdd({
          text: t`Subscription successfully updated`,
        }),
      );
    },
  });
}

export function* billingSaga() {
  yield all([
    billingTariffsGetSaga(),
    billingTariffsOptionsGetSaga(),
    billingSubscriptionsGetSaga(),
    billingInvoicePostSaga(),
    billingPromocodePostSaga(),
    billingFreeRenewalSubscriptionPostSaga(),
    billingCalculateSubscriptionPostSaga(),
    billingPaymentHistoryGetSaga(),
    billingPaymentMethodsGetSaga(),
    billingPaymentMethodsDeleteSaga(),
    billingInvoiceGetSaga(),
    billingPaypalTransactionCompletePostSaga(),
    billingInternalTransactionsGetSaga(),
    billingTrialMigratePostSaga(),
  ]);
}
