import { Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigYaDirect } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigYaDirect | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigYaMetrika: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedClient, setSelectedClient] = useState<IPipelineConfigYaDirect["client_login"] | null>(null);

  const {
    data: clients,
    isFetched: clientsFetched,
    isFetching: clientsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.YA_DIRECT_CLIENTS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedClient) {
          setSelectedClient(data[0]?.login || null);
        }
      },
    },
  );

  const clientOptions = useMemo(
    () =>
      clients?.map((client) => ({
        label: client.login,
        value: client.login,
      })) || [],
    [clients],
  );

  const handleSelectCounter = useCallback((value: typeof clientOptions[number]["value"] | null) => {
    if (value) {
      setSelectedClient(value);
    }
  }, []);

  useEffect(() => {
    onChangeValidation(Boolean(selectedClient));

    const selectedClientData = selectedClient && clients?.find(({ login }) => login === selectedClient);

    if (selectedClientData) {
      onChange({
        client_id: selectedClientData.client_id,
        client_login: selectedClientData.login,
        client_type: selectedClientData.type,
      });
    }
  }, [onChangeValidation, onChange, selectedClient, clients]);

  return (
    <>
      {clientOptions.length === 0 && clientsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={clientOptions.length > 7}
          isLoading={clientsIsFetching}
          value={selectedClient}
          onChange={handleSelectCounter}
          options={clientOptions}
          placeholder={<Trans>Ad account</Trans>}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigYaMetrika;
