import produce from "immer";
import update from "immutability-helper";
import { IInvoice } from "src/types/billing";
import { IPaymentMethod } from "src/types/paymentMethod";
import {
  actionCompile,
  COUNT,
  createIds,
  DELETING,
  FAILURE,
  IActionT,
  IActionTPromiseCreator,
  LOADING,
  PAGE,
  PAGE_SIZE,
} from "src/utils";

import { ECurrency, ETariffId, IInternalTransaction, IModulesActionMap, IModulesState, ITariffOnPeriod } from "..";
import { serializeOptions, serializeTariffs } from "./serializers";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();
export interface IBillingActionMap {
  BILLING_TARIFFS_GET__F: {};
  BILLING_TARIFFS_GET__R: {};
  BILLING_TARIFFS_GET__S: {};

  BILLING_TARIFFS_OPTIONS_GET__F: {};
  BILLING_TARIFFS_OPTIONS_GET__R: {};
  BILLING_TARIFFS_OPTIONS_GET__S: {};

  BILLING_PROMOCODE_POST__F: {};
  BILLING_PROMOCODE_POST__R: {
    promocode: string;
    currency: string;
  };
  BILLING_PROMOCODE_POST__S: {};

  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__F: {};
  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R: {};
  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S: {};

  BILLING_CALCULATE_SUBSCRIPTION_POST__F: {};
  BILLING_CALCULATE_SUBSCRIPTION_POST__R: {
    tariffId?: number;
    options?: object[];
    currency: ECurrency;
    promocode?: string;
    renewalWithFreeDays: boolean;
  };
  BILLING_CALCULATE_SUBSCRIPTION_POST__S: {};

  BILLING_SUBSCRIPTIONS_GET__F: {};
  BILLING_SUBSCRIPTIONS_GET__R: {};
  BILLING_SUBSCRIPTIONS_GET__S: {};

  BILLING_INVOICE_POST__F: {};
  BILLING_INVOICE_POST__R: {
    id?: number;
    system: "yandex" | "cloudpayments";
    options?: object[];
    promocode?: string;
    renewalWithFreeDays: boolean;
    autoRenewalId?: IPaymentMethod["id"];
    savePaymentMethod?: boolean;
  };
  BILLING_INVOICE_POST__S: {
    model: IInvoice;
  };

  BILLING_INVOICE_GET__R: {
    secret: string;
  };
  BILLING_INVOICE_GET__F: {};
  BILLING_INVOICE_GET__S: {
    model: IInvoice;
  };

  BILLING_PAYMENT_HISTORY_GET__R: {
    limit?: number;
    offset?: number;
    ordering?: string;
  };
  BILLING_PAYMENT_HISTORY_GET__F: {};
  BILLING_PAYMENT_HISTORY_GET__S: {
    invoices: IInvoice[];
    meta: {
      count: number;
      offset?: number;
    };
  };

  BILLING_PAYMENT_METHODS_GET__R: {};
  BILLING_PAYMENT_METHODS_GET__F: {};
  BILLING_PAYMENT_METHODS_GET__S: IPaymentMethod[];

  BILLING_PAYMENT_METHODS_DELETE__R: {
    id: IPaymentMethod["id"];
  };
  BILLING_PAYMENT_METHODS_DELETE__F: {};
  BILLING_PAYMENT_METHODS_DELETE__S: {};

  BILLING_INVOICE_RESET: {};

  BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R: {
    order_id: string;
    is: string;
  };

  BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__F: {};
  BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__S: {};

  BILLING_INTERNAL_TRANSACTIONS_GET__R: {
    limit?: number;
    offset?: number;
    ordering?: string;
  };
  BILLING_INTERNAL_TRANSACTIONS_GET__F: {};
  BILLING_INTERNAL_TRANSACTIONS_GET__S: {
    items: IInternalTransaction[];
    meta: {
      count: number;
      offset?: number;
    };
  };

  BILLING_TRIAL_MIGRATE_POST__R: {};
  BILLING_TRIAL_MIGRATE_POST__S: {};
  BILLING_TRIAL_MIGRATE_POST__F: {};
}

export const billingPaypalTransactionCompletePost = createActionCreator("BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R");
export type IBillingPaypalTransactionCompletePostF = IActionT<IModulesActionMap, "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__F">;
export type IBillingPaypalTransactionCompletePostR = IActionT<IModulesActionMap, "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R">;
export type IBillingPaypalTransactionCompletePostS = IActionT<IModulesActionMap, "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__S">;
export const billingPaypalTransactionCompletePostPromise = createPromiseCreator<
  "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R",
  "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__S"
>(billingPaypalTransactionCompletePost);

export type IBillingPaypalTransactionCompletePostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__R",
  "BILLING_PAYPAL_TRANSACTION_COMPLETE_POST__S"
>;

export const billingPaymentHistoryGet = createActionCreator("BILLING_PAYMENT_HISTORY_GET__R");
export type IBillingPaymentHistoryGetF = IActionT<IModulesActionMap, "BILLING_PAYMENT_HISTORY_GET__F">;
export type IBillingPaymentHistoryGetR = IActionT<IModulesActionMap, "BILLING_PAYMENT_HISTORY_GET__R">;
export type IBillingPaymentHistoryGetS = IActionT<IModulesActionMap, "BILLING_PAYMENT_HISTORY_GET__S">;

export const billingPaymentMethodsGet = createActionCreator("BILLING_PAYMENT_METHODS_GET__R");
export type IBillingPaymentMethodsGetF = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_GET__F">;
export type IBillingPaymentMethodsGetR = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_GET__R">;
export type IBillingPaymentMethodsGetS = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_GET__S">;

export const billingPaymentMethodsDelete = createActionCreator("BILLING_PAYMENT_METHODS_DELETE__R");
export type IBillingPaymentMethodsDeleteF = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_DELETE__F">;
export type IBillingPaymentMethodsDeleteR = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_DELETE__R">;
export type IBillingPaymentMethodsDeleteS = IActionT<IModulesActionMap, "BILLING_PAYMENT_METHODS_DELETE__S">;

export const billingInternalTransactionsGet = createActionCreator("BILLING_INTERNAL_TRANSACTIONS_GET__R");
export type IBillingInternalTransactionsGetF = IActionT<IModulesActionMap, "BILLING_INTERNAL_TRANSACTIONS_GET__F">;
export type IBillingInternalTransactionsGetR = IActionT<IModulesActionMap, "BILLING_INTERNAL_TRANSACTIONS_GET__R">;
export type IBillingInternalTransactionsGetS = IActionT<IModulesActionMap, "BILLING_INTERNAL_TRANSACTIONS_GET__S">;

export const billingTariffsGet = createActionCreator("BILLING_TARIFFS_GET__R");
export type IBillingTariffsGetF = IActionT<IModulesActionMap, "BILLING_TARIFFS_GET__F">;
export type IBillingTariffsGetR = IActionT<IModulesActionMap, "BILLING_TARIFFS_GET__R">;
export type IBillingTariffsGetS = IActionT<IModulesActionMap, "BILLING_TARIFFS_GET__S">;
export const billingTariffsGetPromise = createPromiseCreator<"BILLING_TARIFFS_GET__R", "BILLING_TARIFFS_GET__S">(
  billingTariffsGet,
);

export type IBillingTariffsGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_TARIFFS_GET__R",
  "BILLING_TARIFFS_GET__S"
>;

export const billingTariffsOptionsGet = createActionCreator("BILLING_TARIFFS_OPTIONS_GET__R");
export type IBillingTariffsOptionsGetF = IActionT<IModulesActionMap, "BILLING_TARIFFS_OPTIONS_GET__F">;
export type IBillingTariffsOptionsGetR = IActionT<IModulesActionMap, "BILLING_TARIFFS_OPTIONS_GET__R">;
export type IBillingTariffsOptionsGetS = IActionT<IModulesActionMap, "BILLING_TARIFFS_OPTIONS_GET__S">;
export const billingTariffsOptionsGetPromise = createPromiseCreator<
  "BILLING_TARIFFS_OPTIONS_GET__R",
  "BILLING_TARIFFS_OPTIONS_GET__S"
>(billingTariffsOptionsGet);

export type IBillingTariffsOptionsGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_TARIFFS_OPTIONS_GET__R",
  "BILLING_TARIFFS_OPTIONS_GET__S"
>;

export const billingPromocodePost = createActionCreator("BILLING_PROMOCODE_POST__R");
export type IBillingPromocodePostF = IActionT<IModulesActionMap, "BILLING_PROMOCODE_POST__F">;
export type IBillingPromocodePostR = IActionT<IModulesActionMap, "BILLING_PROMOCODE_POST__R">;
export type IBillingPromocodePostS = IActionT<IModulesActionMap, "BILLING_PROMOCODE_POST__S">;
export const billingPromocodePostPromise = createPromiseCreator<"BILLING_PROMOCODE_POST__R", "BILLING_PROMOCODE_POST__S">(
  billingPromocodePost,
);

export type IBillingPromocodePostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_PROMOCODE_POST__R",
  "BILLING_PROMOCODE_POST__S"
>;

export const billingCalculateSubscriptionPost = createActionCreator("BILLING_CALCULATE_SUBSCRIPTION_POST__R");
export type IBillingCalculateSubscriptionPostF = IActionT<IModulesActionMap, "BILLING_CALCULATE_SUBSCRIPTION_POST__F">;
export type IBillingCalculateSubscriptionPostR = IActionT<IModulesActionMap, "BILLING_CALCULATE_SUBSCRIPTION_POST__R">;
export type IBillingCalculateSubscriptionPostS = IActionT<IModulesActionMap, "BILLING_CALCULATE_SUBSCRIPTION_POST__S">;
export const billingCalculateSubscriptionPostPromise = createPromiseCreator<
  "BILLING_CALCULATE_SUBSCRIPTION_POST__R",
  "BILLING_CALCULATE_SUBSCRIPTION_POST__S"
>(billingCalculateSubscriptionPost);

export type IBillingCalculateSubscriptionPostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_CALCULATE_SUBSCRIPTION_POST__R",
  "BILLING_CALCULATE_SUBSCRIPTION_POST__S"
>;

export const billingFreeRenewalSubscriptionPost = createActionCreator("BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R");
export type IBillingFreeRenewalSubscriptionPostF = IActionT<IModulesActionMap, "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__F">;
export type IBillingFreeRenewalSubscriptionPostR = IActionT<IModulesActionMap, "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R">;
export type IBillingFreeRenewalSubscriptionPostS = IActionT<IModulesActionMap, "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S">;
export const billingFreeRenewalSubscriptionPostPromise = createPromiseCreator<
  "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R",
  "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S"
>(billingFreeRenewalSubscriptionPost);

export const billingTrialMigratePost = createActionCreator("BILLING_TRIAL_MIGRATE_POST__R");
export type IBillingTrialMigratePostF = IActionT<IModulesActionMap, "BILLING_TRIAL_MIGRATE_POST__F">;
export type IBillingTrialMigratePostR = IActionT<IModulesActionMap, "BILLING_TRIAL_MIGRATE_POST__R">;
export type IBillingTrialMigratePostS = IActionT<IModulesActionMap, "BILLING_TRIAL_MIGRATE_POST__S">;
export const billingTrialMigratePostPromise = createPromiseCreator<
  "BILLING_TRIAL_MIGRATE_POST__R",
  "BILLING_TRIAL_MIGRATE_POST__S"
>(billingTrialMigratePost);

export type IBillingFreeRenewalSubscriptionPostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R",
  "BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S"
>;

export const billingSubscriptionsGet = createActionCreator("BILLING_SUBSCRIPTIONS_GET__R");
export type IBillingSubscriptionsGetF = IActionT<IModulesActionMap, "BILLING_SUBSCRIPTIONS_GET__F">;
export type IBillingSubscriptionsGetR = IActionT<IModulesActionMap, "BILLING_SUBSCRIPTIONS_GET__R">;
export type IBillingSubscriptionsGetS = IActionT<IModulesActionMap, "BILLING_SUBSCRIPTIONS_GET__S">;
export const billingSubscriptionsGetPromise = createPromiseCreator<
  "BILLING_SUBSCRIPTIONS_GET__R",
  "BILLING_SUBSCRIPTIONS_GET__S"
>(billingSubscriptionsGet);

export type IBillingSubscriptionsGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_SUBSCRIPTIONS_GET__R",
  "BILLING_SUBSCRIPTIONS_GET__S"
>;

export const billingInvoicePost = createActionCreator("BILLING_INVOICE_POST__R");
export type IBillingInvoicePostF = IActionT<IModulesActionMap, "BILLING_INVOICE_POST__F">;
export type IBillingInvoicePostR = IActionT<IModulesActionMap, "BILLING_INVOICE_POST__R">;
export type IBillingInvoicePostS = IActionT<IModulesActionMap, "BILLING_INVOICE_POST__S">;
export const billingInvoicePostPromise = createPromiseCreator<"BILLING_INVOICE_POST__R", "BILLING_INVOICE_POST__S">(
  billingInvoicePost,
);
export type IBillingInvoicePostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_INVOICE_POST__R",
  "BILLING_INVOICE_POST__S"
>;

export const billingInvoiceGet = createActionCreator("BILLING_INVOICE_GET__R");
export type IBillingInvoiceGetF = IActionT<IModulesActionMap, "BILLING_INVOICE_GET__F">;
export type IBillingInvoiceGetR = IActionT<IModulesActionMap, "BILLING_INVOICE_GET__R">;
export type IBillingInvoiceGetS = IActionT<IModulesActionMap, "BILLING_INVOICE_GET__S">;
export const billingInvoiceGetPromise = createPromiseCreator<"BILLING_INVOICE_GET__R", "BILLING_INVOICE_GET__S">(
  billingInvoiceGet,
);
export type IBillingInvoiceGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "BILLING_INVOICE_GET__R",
  "BILLING_INVOICE_GET__S"
>;

export const billingInvoiceReset = createActionCreator("BILLING_INVOICE_RESET");

export const billingReducers = {
  BILLING_CALCULATE_SUBSCRIPTION_POST__S: (modulesState: IModulesState, action: any): IModulesState => {
    const data = action.payload;

    return update(modulesState, {
      billing: {
        calculate: {
          $merge: {
            ...data,
          },
        },
      },
    });
  },
  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        updateSubscription: {
          $merge: {
            [LOADING]: false,
          },
        },
      },
    }),
  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        updateSubscription: {
          $merge: {
            [LOADING]: true,
          },
        },
      },
    }),
  BILLING_FREE_RENEWAL_SUBSCRIPTION_POST__S: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        updateSubscription: {
          $merge: {
            [LOADING]: false,
          },
        },
      },
    }),

  BILLING_INTERNAL_TRANSACTIONS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.internalTransactions[LOADING] = true;
    }),
  BILLING_INTERNAL_TRANSACTIONS_GET__S: (
    modulesState: IModulesState,
    action: IBillingInternalTransactionsGetS,
  ): IModulesState => {
    const {
      items,
      meta: { count, offset = 0 },
    } = action.payload;

    return produce(modulesState, (draft) => {
      draft.billing.internalTransactions[COUNT] = count;
      draft.billing.internalTransactions[LOADING] = false;
      draft.billing.internalTransactions[PAGE] = offset / modulesState.billing.internalTransactions[PAGE_SIZE];
      draft.billing.internalTransactions.items = items;
    });
  },
  BILLING_INVOICE_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = true;
    }),

  BILLING_INVOICE_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = true;
    }),
  BILLING_INVOICE_GET__S: (modulesState: IModulesState, action: IBillingInvoiceGetS): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = true;
      draft.billing.invoiceState.model = action.payload.model;
    }),
  BILLING_INVOICE_POST__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = false;
      draft.billing.invoiceState[FAILURE] = true;
    }),
  BILLING_INVOICE_POST__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = true;
      draft.billing.invoiceState[FAILURE] = false;
    }),
  BILLING_INVOICE_POST__S: (modulesState: IModulesState, action: IBillingInvoicePostS): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState[LOADING] = false;
      draft.billing.invoiceState[FAILURE] = false;
      draft.billing.invoiceState.model = action.payload.model;
    }),
  BILLING_INVOICE_RESET: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.invoiceState.model = undefined;
    }),
  BILLING_PAYMENT_HISTORY_GET__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        paymentHistory: {
          $merge: {
            [LOADING]: true,
          },
        },
      },
    }),
  BILLING_PAYMENT_HISTORY_GET__S: (modulesState: IModulesState, action: IBillingPaymentHistoryGetS): IModulesState => {
    const {
      invoices,
      meta: { count, offset = 0 },
    } = action.payload;

    return update(modulesState, {
      billing: {
        paymentHistory: {
          $merge: {
            [COUNT]: count,
            [LOADING]: false,
            [PAGE]: offset / modulesState.billing.paymentHistory[PAGE_SIZE],
            items: invoices,
          },
        },
      },
    });
  },
  BILLING_PAYMENT_METHODS_DELETE__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[DELETING] = false;
    }),
  BILLING_PAYMENT_METHODS_DELETE__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[DELETING] = true;
    }),
  BILLING_PAYMENT_METHODS_DELETE__S: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[DELETING] = false;
    }),
  BILLING_PAYMENT_METHODS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[LOADING] = false;
      draft.billing.paymentMethods.items = undefined;
    }),
  BILLING_PAYMENT_METHODS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[LOADING] = true;
    }),
  BILLING_PAYMENT_METHODS_GET__S: (modulesState: IModulesState, action: IBillingPaymentMethodsGetS): IModulesState =>
    produce(modulesState, (draft) => {
      draft.billing.paymentMethods[LOADING] = false;
      draft.billing.paymentMethods.items = action.payload;
    }),
  BILLING_PROMOCODE_POST__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        promocode: {
          $merge: {
            value: undefined,
          },
        },
      },
    }),

  BILLING_PROMOCODE_POST__S: (modulesState: IModulesState, action: any): IModulesState => {
    const { value } = action.payload;

    return update(modulesState, {
      billing: {
        promocode: {
          $merge: {
            value,
          },
        },
      },
    });
  },
  BILLING_SUBSCRIPTIONS_GET__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        subscriptions: {
          $merge: {
            [LOADING]: false,
          },
        },
      },
    }),
  BILLING_SUBSCRIPTIONS_GET__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        subscriptions: {
          $merge: {
            [LOADING]: true,
          },
        },
      },
    }),
  BILLING_SUBSCRIPTIONS_GET__S: (modulesState: IModulesState, action: any): IModulesState => {
    const { data } = action.payload;

    return update(modulesState, {
      billing: {
        subscriptions: {
          $merge: {
            [LOADING]: false,
            ids: createIds(data.results),
            items: data.results,
          },
        },
      },
    });
  },
  BILLING_TARIFFS_GET__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        tariffs: {
          $merge: {
            [LOADING]: false,
          },
        },
      },
    }),
  BILLING_TARIFFS_GET__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        tariffs: {
          $merge: {
            [LOADING]: true,
          },
        },
      },
    }),
  BILLING_TARIFFS_GET__S: (modulesState: IModulesState, action: any): IModulesState => {
    const { data } = action.payload;
    const sortedTariffs = data.results
      .filter((tariff: ITariffOnPeriod) => tariff.id !== ETariffId.TRIAL_OUTDATED)
      .sort((a: any, b: any) => {
        if (Number(a.rub_price) > Number(b.rub_price)) {
          return 1;
        }
        if (Number(a.rub_price) < Number(b.rub_price)) {
          return -1;
        }
        return 0;
      });

    return update(modulesState, {
      billing: {
        tariffs: {
          $merge: {
            [LOADING]: false,
            ids: createIds(sortedTariffs),
            items: serializeTariffs(sortedTariffs),
          },
        },
      },
    });
  },
  BILLING_TARIFFS_OPTIONS_GET__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        tariffOptions: {
          $merge: {
            [LOADING]: false,
          },
        },
      },
    }),
  BILLING_TARIFFS_OPTIONS_GET__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      billing: {
        tariffOptions: {
          $merge: {
            [LOADING]: true,
          },
        },
      },
    }),
  BILLING_TARIFFS_OPTIONS_GET__S: (modulesState: IModulesState, action: any): IModulesState => {
    const { data } = action.payload;

    return update(modulesState, {
      billing: {
        tariffOptions: {
          $merge: {
            [LOADING]: false,
            items: serializeOptions(data),
          },
        },
      },
    });
  },
};
