import { plural, t, Trans } from "@lingui/macro";
import React, { FC, useMemo } from "react";
import { EProgressType } from "src/enums/progress";
import { IExport } from "src/types/export";
import aggregateProgressValue from "src/utils/aggregateProgressValue";

interface IProps {
  pipelineExport: IExport;
}

const PipelineExportProgress: FC<IProps> = ({ pipelineExport }) => {
  const progressItem = useMemo(
    () => pipelineExport && pipelineExport.progress && pipelineExport.progress.items[pipelineExport.progress.current_item_sign],
    [pipelineExport],
  );

  const progressValue = useMemo(
    () => pipelineExport && pipelineExport.progress && aggregateProgressValue(pipelineExport.progress),
    [pipelineExport],
  );

  return (
    <>
      <div>
        <Trans>Please wait. A file is being formed for download.</Trans>
      </div>
      {pipelineExport?.progress ? (
        <div>
          <Trans>
            Step {pipelineExport.progress.current_item_num} of {pipelineExport.progress.total_items}.
          </Trans>{" "}
          {progressItem?.type === EProgressType.TIMER && typeof progressValue === "number"
            ? (() => {
                const mm = Math.floor(progressValue / 60);
                const ss = progressValue - mm * 60;

                const mmStr = plural(mm, {
                  few: "# minutes",
                  many: "# minutes",
                  one: "# minute",
                  other: "# minutes",
                });
                const ssStr = plural(ss, {
                  few: "# seconds",
                  many: "# seconds",
                  one: "# second",
                  other: "# seconds",
                });

                return t`Remained` + ` ${mm ? mmStr + " " : ""}${ssStr}`;
              })()
            : progressValue || progressItem?.name}
        </div>
      ) : (
        <Trans>This may take a few minutes...</Trans>
      )}
    </>
  );
};

export default PipelineExportProgress;
