import { useQueryClient } from "react-query";
import { IProject, IProjectMember } from "src/types/project";
import { IResponseBasePagination } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "project-members-get";

export default function useQueryProjectMembersGet(
  projectId: IProject["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<IProjectMember>, IProjectMember[]>,
) {
  return useQueryBase<IResponseBasePagination<IProjectMember>, IProjectMember[]>(
    [QUERY_KEY, projectId],
    {
      enabled: typeof projectId !== "undefined",
      select: (resp) => resp.results,
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        limit: 1000,
      },
      url: `project/${projectId}/owners/`,
    },
  );
}

export function useQueryProjectMembersInvalidate() {
  const clientQuery = useQueryClient();

  return (projectId: IProject["id"]) => clientQuery.invalidateQueries([QUERY_KEY, projectId]);
}
