import { Trans } from "@lingui/macro";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import UiButton, { UiButtonProps } from "src/components/UiButton";
import { useBillingContext } from "src/context/billing/billingContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { ITariff } from "src/types/tariff";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IProps {
  tariff: ITariff;
  ButtonProps?: UiButtonProps;
  buttonText?: ReactNode | string;
}

const SelectTariffButton: FC<IProps> = ({ tariff, ButtonProps, buttonText }) => {
  const {
    actions: { openModalSubscriptionOrder },
  } = useBillingContext();
  const history = useHistory();
  const location = useLocation<{ tariffId?: number; next?: string } | undefined>();
  const { data: user } = useQueryMe();

  const isTrial = useMemo(
    () =>
      [ETariffOnPeriodSign.TRIAL, ETariffOnPeriodSign.TRIAL_SINGLE, ETariffOnPeriodSign.TRIAL_OUTDATED].includes(tariff.sign),
    [tariff.sign],
  );

  const handleChangeTariff = useCallback(
    (tariffSign: ETariffOnPeriodSign) => () => {
      if (!user) {
        history.push({
          pathname: "/auth/signin/",
          state: {
            next: location.pathname,
          },
        });
      } else {
        openModalSubscriptionOrder({
          renewalWithFreeDays: false,
          tariffSign,
        });
      }
    },
    [history, location.pathname, openModalSubscriptionOrder, user],
  );

  const handleWriteUs = useCallback(() => {
    carrotquest.openMessenger();
  }, []);

  if (isTrial || user?.billing_enabled === false) {
    return null;
  } else if (tariff.is_negotiable_price) {
    return (
      <UiButton onClick={handleWriteUs} {...ButtonProps}>
        {buttonText || <Trans>Write to us</Trans>}
      </UiButton>
    );
  } else {
    return (
      <UiButton onClick={handleChangeTariff(tariff.sign)} {...ButtonProps}>
        {buttonText || <Trans>Select</Trans>}
      </UiButton>
    );
  }
};

export default SelectTariffButton;
