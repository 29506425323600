import { produce } from "immer";
import update from "immutability-helper";
import { ELanguage } from "src/enums/language";
import { IModulesActionMap, IModulesState, IUser } from "src/redux";
import { actionCompile, IActionT, IActionTPromiseCreator, ITEMS, LOADING, PAGE, parseQueryString } from "src/utils";

declare var carrotquest: any;

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IUsersActionMap {
  USERS_GET__F: {};
  USERS_GET__R: {
    filter?: string;
    cb?: any;
  };
  USERS_GET__S: {
    results: any;
    cb: any;
  };
  USER_GET__F: {};
  USER_GET__R: {
    silent?: boolean;
  };
  USER_GET__S: {
    user: IUser;
  };

  USER_PUT__F: {};
  USER_PUT__R: {
    language?: ELanguage;
    currentPassword?: string;
    newPassword?: string;
  };
  USER_PUT__S: {
    user: IUser;
  };

  USER_PUT_SELECTED_PROJECT__F: {};
  USER_PUT_SELECTED_PROJECT__R: {
    projectId: number | null;
    redirect?: string;
  };
  USER_PUT_SELECTED_PROJECT__S: {
    user: IUser;
  };

  USERS_SENT_CONFIRM__R: {};
  USERS_SENT_CONFIRM__S: {};
  USERS_SENT_CONFIRM__F: {};

  USER_PUT_EMAIL__R: {
    email: string;
    password: string;
  };
  USER_PUT_EMAIL__F: {};
  USER_PUT_EMAIL__S: {
    email: string;
  };

  USER_SET_LANGUAGE: {
    language: ELanguage;
  };
}

export const userEmailPut = createActionCreator("USER_PUT_EMAIL__R");
export type IUserEmailPutF = IActionT<IModulesActionMap, "USER_PUT_EMAIL__F">;
export type IUserEmailPutR = IActionT<IModulesActionMap, "USER_PUT_EMAIL__R">;
export type IUserEmailPutS = IActionT<IModulesActionMap, "USER_PUT_EMAIL__S">;
export const userEmailPutPromise = createPromiseCreator<"USER_PUT_EMAIL__R", "USER_PUT_EMAIL__S">(userEmailPut);
export type IUserEmailPutPromise = IActionTPromiseCreator<IModulesActionMap, "USER_PUT_EMAIL__R", "USER_PUT_EMAIL__S">;

export const userSendConfirmPost = createActionCreator("USERS_SENT_CONFIRM__R");
export type IUserSendConfirmF = IActionT<IModulesActionMap, "USERS_SENT_CONFIRM__F">;
export type IUserSendConfirmR = IActionT<IModulesActionMap, "USERS_SENT_CONFIRM__R">;
export type IUserSendConfirmS = IActionT<IModulesActionMap, "USERS_SENT_CONFIRM__S">;
export const userSendConfirmPostPromise = createPromiseCreator<"USERS_SENT_CONFIRM__R", "USERS_SENT_CONFIRM__S">(
  userSendConfirmPost,
);
export type IUserSendConfirmPostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "USERS_SENT_CONFIRM__R",
  "USERS_SENT_CONFIRM__S"
>;

export const usersGet = createActionCreator("USERS_GET__R");
export type IUsersGetF = IActionT<IModulesActionMap, "USERS_GET__F">;
export type IUsersGetR = IActionT<IModulesActionMap, "USERS_GET__R">;
export type IUsersGetS = IActionT<IModulesActionMap, "USERS_GET__S">;
export const usersGetPromise = createPromiseCreator<"USERS_GET__R", "USERS_GET__S">(usersGet);
export type IUsersGetPromise = IActionTPromiseCreator<IModulesActionMap, "USERS_GET__R", "USERS_GET__S">;

export const userGet = createActionCreator("USER_GET__R");
export type IUserGetF = IActionT<IModulesActionMap, "USER_GET__F">;
export type IUserGetR = IActionT<IModulesActionMap, "USER_GET__R">;
export type IUserGetS = IActionT<IModulesActionMap, "USER_GET__S">;
export const userGetPromise = createPromiseCreator<"USER_GET__R", "USER_GET__S">(userGet);
export type IUserGetPromise = IActionTPromiseCreator<IModulesActionMap, "USER_GET__R", "USER_GET__S">;

export const userPut = createActionCreator("USER_PUT__R");
export type IUserPutF = IActionT<IModulesActionMap, "USER_PUT__F">;
export type IUserPutR = IActionT<IModulesActionMap, "USER_PUT__R">;
export type IUserPutS = IActionT<IModulesActionMap, "USER_PUT__S">;
export const userPutPromise = createPromiseCreator<"USER_PUT__R", "USER_PUT__S">(userPut);
export type IUserPutPromise = IActionTPromiseCreator<IModulesActionMap, "USER_PUT__R", "USER_PUT__S">;

export const userPatchSelectedProject = createActionCreator("USER_PUT_SELECTED_PROJECT__R");
export type IUserPatchSelectedProjectF = IActionT<IModulesActionMap, "USER_PUT_SELECTED_PROJECT__F">;
export type IUserPatchSelectedProjectR = IActionT<IModulesActionMap, "USER_PUT_SELECTED_PROJECT__R">;
export type IUserPatchSelectedProjectS = IActionT<IModulesActionMap, "USER_PUT_SELECTED_PROJECT__S">;

export const userSetLanguage = createActionCreator("USER_SET_LANGUAGE");
export type IUserSetLanguage = IActionT<IModulesActionMap, "USER_SET_LANGUAGE">;

export const usersReducers = {
  USERS_GET__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      users: {
        $merge: {
          [LOADING]: true,
        },
      },
    }),
  USERS_GET__S: (modulesState: IModulesState, action: IUsersGetS): IModulesState => {
    const { results } = action.payload;
    return update(modulesState, {
      users: {
        $merge: {
          [ITEMS]: {
            ...results,
          },
          [LOADING]: false,
        },
      },
    });
  },
  USER_GET__S: (modulesState: IModulesState, action: IUserGetS): IModulesState => {
    const { user } = action.payload;
    try {
      if (user.carrot_quest && carrotquest) {
        carrotquest.auth(user.id, user.carrot_quest);

        if (user.active_subscription?.id) {
          carrotquest.identify({
            Тариф: user.active_subscription.tariff_on_period.tariff.name,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }

    return update(modulesState, {
      user: {
        $set: {
          ...user,
          language: (() => {
            const lang = parseQueryString().get("lang") as ELanguage;

            if ([ELanguage.RU, ELanguage.EN].includes(lang)) {
              return lang;
            }

            return user.language;
          })(),
        },
      },
    });
  },
  USER_PUT_EMAIL__F: (modulesState: IModulesState) =>
    produce(modulesState, (draft) => {
      draft.userMeta[LOADING] = false;
    }),
  USER_PUT_EMAIL__R: (modulesState: IModulesState) =>
    produce(modulesState, (draft) => {
      draft.userMeta[LOADING] = true;
    }),
  USER_PUT_EMAIL__S: (modulesState: IModulesState, action: IUserEmailPutS) =>
    produce(modulesState, (draft) => {
      draft.userMeta[LOADING] = false;
      draft.user.email = action.payload.email;
    }),
  USER_PUT_SELECTED_PROJECT__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      userMeta: {
        $merge: {
          [LOADING]: true,
        },
      },
    }),
  USER_PUT_SELECTED_PROJECT__S: (modulesState: IModulesState, action: IUserPatchSelectedProjectS): IModulesState => {
    const { user } = action.payload;
    return produce(modulesState, (draft) => {
      draft.user = user;
      draft.userMeta[LOADING] = false;
      draft.pipelinesItemsState[PAGE] = 0;
    });
  },
  USER_PUT__F: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      userMeta: {
        $merge: {
          [LOADING]: false,
        },
      },
    }),
  USER_PUT__R: (modulesState: IModulesState): IModulesState =>
    update(modulesState, {
      userMeta: {
        $merge: {
          [LOADING]: true,
        },
      },
    }),
  USER_PUT__S: (modulesState: IModulesState, action: IUserPutS): IModulesState => {
    const { user } = action.payload;
    return update(modulesState, {
      user: {
        $set: user,
      },
      userMeta: {
        $merge: {
          [LOADING]: false,
        },
      },
    });
  },
  USER_SET_LANGUAGE: (modulesState: IModulesState, action: IUserSetLanguage) =>
    produce(modulesState, (draft) => {
      localStorage.setItem("language", action.payload.language);
      draft.user.language = action.payload.language;
    }),
};
