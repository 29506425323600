import { IPipelinesDashboardActionMap, pipelinesDashboardReducers } from "src/redux/modules/pipelines/actions/dashboard";
import { IPipelinesExportsActionMap, pipelinesExportsReducers } from "src/redux/modules/pipelines/actions/exports";
import { IPipelinesItemActionMap, pipelinesItemReducers } from "src/redux/modules/pipelines/actions/item";
import { IPipelinesItemsActionMap, pipelinesItemsReducers } from "src/redux/modules/pipelines/actions/items";
import { IPipelinesMappingActionMap, pipelinesMappingReducers } from "src/redux/modules/pipelines/actions/mapping";
import { IPipelinesMergeActionMap, pipelinesMergeReducers } from "src/redux/modules/pipelines/actions/merge";
import { IPipelinesSamesActionMap, pipelinesSamesReducers } from "src/redux/modules/pipelines/actions/sames";
import { IPipelinesTypesActionMap, pipelinesTypesReducers } from "src/redux/modules/pipelines/actions/types";

export type IPipelinesActionMap = IPipelinesDashboardActionMap &
  IPipelinesSamesActionMap &
  IPipelinesMappingActionMap &
  IPipelinesMergeActionMap &
  IPipelinesItemActionMap &
  IPipelinesItemsActionMap &
  IPipelinesExportsActionMap &
  IPipelinesTypesActionMap;

export const pipelinesReducers = {
  ...pipelinesDashboardReducers,
  ...pipelinesSamesReducers,
  ...pipelinesMappingReducers,
  ...pipelinesMergeReducers,
  ...pipelinesItemReducers,
  ...pipelinesItemsReducers,
  ...pipelinesExportsReducers,
  ...pipelinesTypesReducers,
};
