import { useQueryClient } from "react-query";
import { IPaymentMethod } from "src/types/paymentMethod";
import { IResponseBasePagination } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "billing-payment-methods";

export default function useQueryBillingPaymentMethodsGet(
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<IPaymentMethod>, IPaymentMethod[]>,
) {
  return useQueryBase<IResponseBasePagination<IPaymentMethod>, IPaymentMethod[]>(
    QUERY_KEY,
    {
      select: (data) => data.results,
      ...queryOptions,
    },
    {
      method: "GET",
      url: "billing/recurring-payments/",
    },
  );
}

export function useQueryBillingPaymentMethodsInvalidate() {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(QUERY_KEY);
}
