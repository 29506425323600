import { Paper } from "@mui/material";
import { IconBolt } from "@tabler/icons";
import { FC, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Page404 from "src/app/Page404/Page404";
import MediaFavicon from "src/components/MediaFavicon";
import PipelineCreateForm from "src/components/PipelineCreateForm";
import Section, { SectionHead } from "src/components/Section";
import { EPipelineSubType, EPipelineType } from "src/enums/pipeline";
import { IPipelineExtended, IPipelineType } from "src/types/pipeline";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import * as classes from "./index.css";

interface ILocationState {
  nextState?: { pathname: string; state?: object };
}

const PipelineCreate: FC = () => {
  const { data: user } = useQueryMe();
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  const { source } = useParams<{ source: string }>();
  const {
    data: pipelineTypes,
    isFetching: pipelineTypesIsFetching,
    isFetched: pipelineTypesIsFetched,
  } = useQueryPipelineTypes();

  const pipelineType = useMemo(() => pipelineTypes?.[Number(source) as IPipelineType["id"]], [pipelineTypes, source]);

  const isOpenAccount = useMemo(
    () => pipelineType && [EPipelineType.INST_OPEN_ACCOUNT].includes(pipelineType.id),
    [pipelineType],
  );

  const handleCreate = useCallback(
    (pipeline: IPipelineExtended) => {
      const nextState = location.state?.nextState;

      if (nextState) {
        history.push({
          pathname: nextState.pathname,
          state: {
            ...nextState.state,
            newPipeline: pipeline,
          },
        });
      } else {
        history.push(pipeline.report_links?.main || "/");
      }
    },
    [history, location.state],
  );

  if (
    (!pipelineType && pipelineTypesIsFetched) ||
    (pipelineType && pipelineType.onlyStaff && !user?.is_staff) ||
    (pipelineType && pipelineType.subType === EPipelineSubType.SINGLEPIPELINE)
  ) {
    return <Page404 />;
  }

  if (!pipelineType) {
    return null;
  }

  return (
    <Section isLoading={pipelineTypesIsFetching}>
      <SectionHead
        title={pipelineType.title}
        subTitle={pipelineType.description}
        icon={
          isOpenAccount ? (
            <IconBolt css={classes.headIcon} stroke={0.75} />
          ) : (
            <MediaFavicon media={pipelineType.media} fullSize css={classes.favicon} />
          )
        }
      />

      <Paper sx={{ m: "auto", maxWidth: 800, p: 3 }}>
        <PipelineCreateForm
          pipelineType={pipelineType}
          onSuccess={handleCreate}
          persistState={{
            nextState: location.state?.nextState,
          }}
        />
      </Paper>
    </Section>
  );
};

export default PipelineCreate;
