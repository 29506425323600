import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";
import { GroupTypeBase, IndicatorProps } from "react-select";

type IChevronProps = IndicatorProps<any, boolean, GroupTypeBase<any>>;

export const classes = {
  addButton: (isSearchable: boolean, hasValue: boolean) => css`
    width: 34px;
    height: 34px;
    min-width: 0;
    line-height: 0;
    margin-left: ${isSearchable ? (hasValue ? "30px" : "20px") : hasValue ? 0 : "-4px"};
  `,

  chevron: ({ isFocused, isDisabled }: IChevronProps) => {
    const color = (() => {
      if (isFocused) {
        return "#000000";
      }

      if (isDisabled) {
        return "#E2E2E2";
      }

      return "#505050";
    })();

    return css`
      font-size: 18px !important;
      transform: ${isFocused ? "rotate(180deg)" : ""};
      width: 40px !important;
      color: ${color};
    `;
  },

  control: css`
    position: relative;
  `,

  control__iframe: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border: none;
  `,

  control__input: css`
    input {
      border: 1px solid #cccccc !important;
      width: 100% !important;
      border-radius: 8px;
      margin: 0;
      padding: 10px 10px 10px 36px !important;
      box-sizing: border-box !important;
    }

    overflow: hidden;
    height: 0;
    width: 0;
    margin-right: -30px !important;
    border-radius: 9px;
    position: relative;
  `,

  control__input__icn: css`
    position: absolute;
    top: 10px;
    left: 10px;
    color: #cccccc;
    z-index: 0;
  `,

  control__input__input: css`
    display: block !important;
    margin: -10px -57px 0 -17px;
    background: transparent;
    padding: 9px 17px;
    position: relative;
    z-index: 1;
  `,

  control__input_open: css`
    height: 48px;
    width: 100%;
    overflow: visible;
  `,

  placeholder: ({ disabled }: { disabled: boolean }) => css`
    color: ${disabled ? "#e2e2e2" : "#505050"};
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 11px 0 12px;
    text-overflow: ellipsis;
    transform: none;
    white-space: nowrap;
    width: 100%;
  `,

  singleValue: css`
    color: #333;
    max-width: calc(100% - 16px);
    overflow: hidden;
    position: static;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    margin: 0;
    padding: 11px 0 12px;
  `,

  spinner: (theme: Theme) => css`
    position: relative;
    color: ${theme.palette.neutral.main};
    width: 20px !important;
    height: 20px !important;
    margin-right: 15px;
  `,

  valueContainer: css`
    padding: 1px 16px;
  `,

  wrapper: (fullWidth?: boolean, isMenuOpen?: boolean) => css`
    width: ${fullWidth ? "100%" : "300px"};
    min-height: 46px;
    position: relative;
    z-index: ${isMenuOpen ? 2 : 1};
  `,
};
