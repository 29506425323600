import { css } from "@emotion/react/macro";

export const headIcon = css`
  width: 100%;
  height: 100%;
  color: #55ade0;
`;

export const favicon = css`
  > img {
    display: block;
    border-radius: 35%;
  }
`;
