import { IProject } from "src/types/project";
import { useQueryProjectInvalidate } from "src/utils/queries/project/useQueryProjectGet";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  project_id: IProject["id"];
}

export default function useQueryProjectAcceptInvite(queryOptions?: IUseQueryMutationOptions<unknown, IInput>) {
  const invalidateProjects = useQueryProjectsInvalidate();
  const invalidateProject = useQueryProjectInvalidate();

  return useMutationBase<unknown, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await Promise.all([invalidateProjects(), invalidateProject(args[1].project_id)]);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      url: "project/approve-member/",
    },
  );
}
