import { css } from "@emotion/react/macro";

const WIDTH = `252px`;

export const classes = {
  head: css`
    padding: 14px 20px;
    background: #e8f0f3;
    border-radius: 8px 8px 0 0;
  `,
  head__date: css`
    font-weight: 600;
    color: #000000;
    font-size: 1.75rem;

    :first-letter {
      text-transform: uppercase;
    }
  `,
  head__year: css`
    font-weight: 600;
    color: #505050;
  `,
  picker: (weekDays: string[]) => css`
    .PrivatePickersFadeTransitionGroup-root > div {
      transition: none;
    }

    > div:first-of-type {
      min-width: ${WIDTH};

      > div > div {
        width: ${WIDTH};
      }
    }

    .MuiCalendarPicker-root {
      width: ${WIDTH};
      max-height: 308px;
      height: 308px;

      // month selector
      > div:first-of-type {
        position: relative;
        width: 100%;
        display: block;
        padding: 0;
        margin-top: 10px;

        [role="presentation"] {
          position: absolute;
          left: 0;
          right: 0;
          justify-content: center;
          font-weight: 600;
          color: #505050;
          margin-top: 5px;
        }

        button:first-of-type {
          position: absolute;
          left: 0;
        }

        button:last-child {
          position: absolute;
          right: 0;
        }
      }

      // week days
      > div:nth-of-type(2) {
        > .MuiTypography-root {
          color: #cccccc;
          font-size: 0;
          margin: 0;

          &:after {
            font-size: 10px;
          }

          ${weekDays.map(
            (d, index) =>
              css`
                &:nth-of-type(${index + 1}):after {
                  content: "${d.substr(0, 2)}";
                }
              `,
          )}
        }
      }
    }

    // years buttons
    .PrivatePickersSlideTransition-root {
      min-height: 220px;

      .MuiButtonBase-root {
        margin: 0;
        border-radius: 8px;
        font-size: 0.875rem;
        font-weight: 600;
        border: none !important;

        &.Mui-selected {
          background: #55ade0 !important;
        }

        &:hover {
          background: #f1f6f8;
        }
      }

      .MuiPickersDay-root {
        margin: 0;
      }

      [role="row"] {
        margin: 0;
      }
    }

    .PrivatePickersYear-yearButton {
      padding: 0;
      margin: 0;
      width: 56px;
    }

    // month buttons
    .MuiMonthPicker-root {
      width: 251px;
      border-style: solid;
      border-color: #e8f0f3;
      border-width: 0 1px 1px 0;

      button.MuiTypography-root {
        border-radius: 0;
        font-size: 0.875rem;
        font-weight: 600;
        background: transparent;
        border-style: solid;
        border-color: #e8f0f3;
        border-width: 1px 0 0 1px;

        &.Mui-selected {
          background: #55ade0 !important;
          color: #ffffff;
        }

        &:hover {
          background: #f1f6f8;
        }
      }
    }
  `,
  wrapper: css`
    width: ${WIDTH};
  `,
};
