import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import PipelineCreateForm from "src/components/PipelineCreateForm";
import UiSelect from "src/components/UiSelect";
import { EPipelineType } from "src/enums/pipeline";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import groupPipelineTypes from "src/utils/groupPipelineTypes";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IProps {
  pipelineId: IPipeline["id"] | null;
  onSelect: (pipeline: IPipeline["id"] | null) => void;
  onCreateNew: (pipeline: IPipelineExtended) => void;
}

const SelectPipeline: FC<IProps> = ({ pipelineId, onSelect, onCreateNew }) => {
  const location = useLocation<{ newPipelineSource: EPipelineType } | undefined>();
  const { data: projects, isFetching: isProjectsLoading } = useQueryProjects();
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: user } = useQueryMe();
  const [isCreate, setIsCreate] = useState<boolean>(Boolean(location.state?.newPipelineSource));
  const [source, setSource] = useState<EPipelineType | null>(location.state?.newPipelineSource || null);

  const pipelineOptions = useMemo(
    () =>
      projects
        ?.map(({ pipelines }) =>
          pipelines.map(({ id, name }) => ({
            label: `[${id}] ${name}`,
            value: id,
          })),
        )
        ?.flat()
        .sort((p1, p2) => (p1.value > p2.value ? -1 : 1)),
    [projects],
  );

  const sourceOptions = useMemo(() => {
    const grouped = pipelineTypes && groupPipelineTypes(Object.values(pipelineTypes));

    return (
      grouped &&
      Object.values(grouped).map((group) => ({
        label: group.shortTitle,
        options: group.items
          .filter((pt) => !pt.onlyStaff || user?.is_staff)
          .map((pt) => ({
            label: pt.shortTitle,
            value: pt.id,
          })),
      }))
    );
  }, [pipelineTypes, user?.is_staff]);

  const handleChangeIsCreate = useCallback(
    (e, value: string) => {
      setIsCreate(value === "yes");

      if (value === "yes") {
        onSelect(null);
      } else {
        onSelect(pipelineOptions?.[0]?.value || null);
      }
    },
    [onSelect, pipelineOptions],
  );

  useUpdateEffect(() => {
    if (!isCreate) {
      onSelect(pipelineOptions?.[0]?.value || null);
    } else {
      onSelect(null);
    }
  }, [pipelineOptions]);

  useMount(() => {
    if (!location.state?.newPipelineSource) {
      onSelect(pipelineId || pipelineOptions?.[0]?.value || null);
    }
  });

  if (isProjectsLoading) {
    return <Loader />;
  }

  return (
    <RadioGroup onChange={handleChangeIsCreate} value={isCreate ? "yes" : "no"}>
      <FormControlLabel
        value="no"
        disabled={!Boolean(pipelineOptions?.length)}
        control={<Radio />}
        label={<Trans>Use previously connected public / ad account</Trans>}
      />

      {!isCreate && (
        <Box pt={2} pb={2}>
          <UiSelect
            onChange={onSelect}
            label={<Trans>Select the connected public/ad cabinet</Trans>}
            value={pipelineId}
            options={pipelineOptions}
            fullWidth
          />
        </Box>
      )}

      <FormControlLabel value="yes" control={<Radio />} label={<Trans>Connect a new public / ad account</Trans>} />

      {isCreate && (
        <>
          <Box pt={2} pb={1}>
            <UiSelect
              fullWidth
              value={source}
              onChange={setSource}
              isDisabled={!sourceOptions?.length}
              options={sourceOptions}
              maxMenuHeight={600}
              placeholder={<Trans>Publics and ad accounts</Trans>}
            />
          </Box>

          {source !== null && pipelineTypes?.[source] && (
            <Box pt={2} pb={1}>
              <PipelineCreateForm
                pipelineType={pipelineTypes[source]}
                onSuccess={onCreateNew}
                persistState={{
                  newPipelineSource: source,
                }}
              />
            </Box>
          )}
        </>
      )}
    </RadioGroup>
  );
};

export default SelectPipeline;
