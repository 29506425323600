import { EProgressType } from "src/enums/progress";
import { EStreamReportStatus } from "src/enums/stream";
import { IStreamReportStatus } from "src/redux/modules/pipelines";
import aggregateProgressValue from "src/utils/aggregateProgressValue";

interface IAggregatedProgress {
  sign: string;
  stepCurrent: number;
  stepTotal: number;
  name: string;
  value: string | number;
  type: EProgressType | null;
}

export default function aggregateReportStatusProgress(reportStatus: IStreamReportStatus[]): IAggregatedProgress {
  const { finishedStreamProgress, currentStreamProgress } = reportStatus.reduce(
    (
      acc: {
        currentStreamProgress: null | IStreamReportStatus;
        finishedStreamProgress: null | IStreamReportStatus;
      },
      reportStatus,
    ) => {
      if (reportStatus.status === EStreamReportStatus.LOCK) {
        acc.currentStreamProgress = reportStatus;
      } else if (reportStatus.status === EStreamReportStatus.SUCCESS) {
        acc.finishedStreamProgress = reportStatus;
      }

      return acc;
    },
    {
      currentStreamProgress: null,
      finishedStreamProgress: null,
    },
  );

  return reportStatus.reduce(
    (acc: IAggregatedProgress, reportStatus) => {
      acc.stepTotal += reportStatus.progress?.total_items || 0;

      if (reportStatus.status === EStreamReportStatus.SUCCESS) {
        acc.stepCurrent += reportStatus.progress?.total_items || 0;
      } else if (reportStatus.status === EStreamReportStatus.LOCK) {
        acc.stepCurrent += reportStatus.progress?.current_item_num || 1;
      }

      const { progress } = currentStreamProgress || finishedStreamProgress || {};

      if (progress) {
        const item = progress?.items[progress.current_item_sign];

        acc.sign = item.sign;
        acc.type = item.type;

        if (item) {
          acc.name = item.name;
          acc.value = aggregateProgressValue(progress);
        }
      }

      return acc;
    },
    {
      name: "",
      sign: "",
      stepCurrent: 0,
      stepTotal: 0,
      type: null,
      value: "",
    },
  );
}
