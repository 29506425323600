import { Sorting } from "@devexpress/dx-react-grid";
import produce from "immer";
import { IModulesActionMap, IModulesState, IPipelinesGetSagaResponse } from "src/redux/modules/index";
import {
  actionCompile,
  COUNT,
  FILTERED,
  IActionT,
  IActionTPromiseCreator,
  IFiltered,
  LOADING,
  PAGE,
  PAGE_SIZE,
  SORTED,
  UPDATING,
} from "src/utils";
import { normalizeObjectsArray } from "src/utils/normalizeObjectsArray";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesDashboardActionMap {
  PIPELINES_DASHBOARD_GET__F: {};
  PIPELINES_DASHBOARD_GET__R: {
    limit?: number;
    page?: number;
    ordering?: Sorting[];
    filtered?: IFiltered[];
    name?: string;
  };
  PIPELINES_DASHBOARD_GET__S: IPipelinesGetSagaResponse;

  PIPELINES_DASHBOARD_STATUS_GET__F: {};
  PIPELINES_DASHBOARD_STATUS_GET__R: {
    id__in?: string;
  };
  PIPELINES_DASHBOARD_STATUS_GET__S: IPipelinesGetSagaResponse;
}

export const pipelinesDashboardGet = createActionCreator("PIPELINES_DASHBOARD_GET__R");
export type IPipelinesDashboardGetF = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_GET__F">;
export type IPipelinesDashboardGetR = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_GET__R">;
export type IPipelinesDashboardGetS = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_GET__S">;
export const pipelinesDashboardGetPromise = createPromiseCreator<"PIPELINES_DASHBOARD_GET__R", "PIPELINES_DASHBOARD_GET__S">(
  pipelinesDashboardGet,
);
export type IPipelinesGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_DASHBOARD_GET__R",
  "PIPELINES_DASHBOARD_GET__S"
>;

export const pipelinesDashboardStatusGet = createActionCreator("PIPELINES_DASHBOARD_STATUS_GET__R");
export type IPipelinesDashboardStatusGetF = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_STATUS_GET__F">;
export type IPipelinesDashboardStatusGetR = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_STATUS_GET__R">;
export type IPipelinesDashboardStatusGetS = IActionT<IModulesActionMap, "PIPELINES_DASHBOARD_STATUS_GET__S">;
export const pipelinesDashboardStatusGetPromise = createPromiseCreator<
  "PIPELINES_DASHBOARD_STATUS_GET__R",
  "PIPELINES_DASHBOARD_STATUS_GET__S"
>(pipelinesDashboardStatusGet);
export type IPipelinesStatusGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_DASHBOARD_STATUS_GET__R",
  "PIPELINES_DASHBOARD_STATUS_GET__S"
>;

export const pipelinesDashboardReducers = {
  PIPELINES_DASHBOARD_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[LOADING] = false;
    }),

  PIPELINES_DASHBOARD_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[LOADING] = true;
    }),

  PIPELINES_DASHBOARD_GET__S: (modulesState: IModulesState, action: IPipelinesDashboardGetS): IModulesState => {
    const { meta, results } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[LOADING] = false;
      draft.pipelinesDashboardState[COUNT] = meta.count;
      draft.pipelinesDashboardState[PAGE] = meta.page;
      draft.pipelinesDashboardState[PAGE_SIZE] = meta.limit;
      draft.pipelinesDashboardState[SORTED] = meta.ordering;
      draft.pipelinesDashboardState[FILTERED] = meta.filtered;
      draft.pipelinesDashboardState.models = results;
    });
  },

  PIPELINES_DASHBOARD_STATUS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[UPDATING] = false;
    }),

  PIPELINES_DASHBOARD_STATUS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[UPDATING] = true;
    }),

  PIPELINES_DASHBOARD_STATUS_GET__S: (modulesState: IModulesState, action: IPipelinesDashboardStatusGetS): IModulesState => {
    const resultsNormalized = normalizeObjectsArray(action.payload.results, "id");

    return produce(modulesState, (draft) => {
      draft.pipelinesDashboardState[UPDATING] = false;
      draft.pipelinesDashboardState.models = draft.pipelinesDashboardState.models?.map(
        (pipeline) => resultsNormalized[pipeline.id] || pipeline,
      );
    });
  },
};
