import { ECurrency } from "src/enums/currency";
import { IResponseBillingCalculate } from "src/types/response";
import { ITariff } from "src/types/tariff";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "billing-calculate";

export default function useQueryBillingCalculatePost(
  params?: {
    tariff_on_period_id?: ITariff["id"];
    currency: ECurrency;
    options: Array<{
      count: number;
      option_id: ITariff["option_id"];
    }>;
    renewal_with_free_days: boolean;
    promo_code?: string;
  },
  queryOptions?: IUseQueryBaseOptions<IResponseBillingCalculate>,
) {
  return useQueryBase<IResponseBillingCalculate>(
    [QUERY_KEY, params],
    {
      ...queryOptions,
      enabled: Boolean(params) && queryOptions?.enabled !== false,
    },
    {
      method: "POST",
      params,
      url: "billing/calculate-subscription/",
    },
  );
}
