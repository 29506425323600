import { Table, TableProps } from "@devexpress/dx-react-grid-material-ui";
import { InterpolationWithTheme } from "@emotion/core";
import { t } from "@lingui/macro";
import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import classNames from "classnames";
import { FC, useCallback } from "react";
import useTableMasonry, { ITableMasonryBreakPoints } from "src/utils/hoooks/useTableMasonry";

import useStyles from "./index.css";

interface IProps extends TableProps {
  loading?: boolean;
  masonryBreakPoints?: ITableMasonryBreakPoints;
  masonryContainerSx?: BoxProps["sx"];
  blueHead?: boolean;
  smallPadding?: boolean;
  rowCss?: InterpolationWithTheme<any>;
}

const UiDxTable: FC<IProps> = ({ blueHead, loading = false, masonryBreakPoints, masonryContainerSx, rowCss, ...props }) => {
  const classes = useStyles();
  const isMasonry = useTableMasonry(masonryBreakPoints);

  const renderContainer = useCallback(
    ({ children }) => (
      <Box sx={masonryContainerSx} className={classNames(classes.wrapper, isMasonry && classes.wrapper_masonry)}>
        {children}

        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </Box>
    ),
    [classes.loader, classes.wrapper, classes.wrapper_masonry, isMasonry, loading, masonryContainerSx],
  );

  const renderTable = useCallback(
    (props) => {
      if (isMasonry) {
        const children = props.children.filter((child: any) => child?.type?.name !== "ColumnGroup");

        const { style, ...spread } = props;

        return (
          <Table.Table {...spread}>
            <Grid container spacing={2} component={Table.TableBody} className={classes.masonryTable}>
              {children}
            </Grid>
          </Table.Table>
        );
      }

      return <Table.Table {...props} />;
    },
    [classes.masonryTable, isMasonry],
  );

  const renderRow = useCallback(
    (props: Table.DataRowProps) => {
      if (isMasonry) {
        return (
          <Grid item {...masonryBreakPoints} component={Table.Row} className={classes.masonryTr}>
            <td className={classes.masonryTd}>
              <Paper className={classes.masonryPaper} elevation={0} variant="filled">
                <Box p={2}>{props.children}</Box>
              </Paper>
            </td>
          </Grid>
        );
      }

      return <Table.Row css={rowCss} {...props} />;
    },
    [classes.masonryPaper, classes.masonryTd, classes.masonryTr, isMasonry, masonryBreakPoints, rowCss],
  );

  const renderNoDataRow = useCallback(
    (props: any) => {
      if (isMasonry) {
        return (
          <Grid item xs component="tr">
            {props.children}
          </Grid>
        );
      }

      return <Table.NoDataRow {...props} />;
    },
    [isMasonry],
  );

  const renderNoDataCell = useCallback(
    (props: Table.NoDataCellProps) => {
      if (isMasonry) {
        return (
          <td className={classes.masonryTd}>
            <Paper>
              <Box pt={6} pb={6} textAlign="center">
                {props.getMessage("noData")}
              </Box>
            </Paper>
          </td>
        );
      }

      return <Table.NoDataCell {...props} />;
    },
    [classes.masonryTd, isMasonry],
  );

  const renderHead = useCallback(
    (props) => {
      if (isMasonry) {
        return null;
      }

      return <Table.TableHead className={blueHead && classes.headBlue} {...props} />;
    },
    [blueHead, classes.headBlue, isMasonry],
  );

  const renderBody = useCallback(
    (props) => {
      if (isMasonry) {
        return <>{props.children}</>;
      }

      return <Table.TableBody {...props} />;
    },
    [isMasonry],
  );

  const renderCell = useCallback(
    (props) => {
      if (isMasonry) {
        return (
          <Box
            className={classNames(classes.masonryRow, props.column.masonryFullWidth && classes.masonryRow_fullWidth)}
            sx={props.column.masonryRowSx}
          >
            {props.column.title.replace(/\s/g, "") && <div className={classes.masonryRowTitle}>{props.column.title}</div>}
            <div className={classes.masonryRowValue} style={{ textAlign: props.column.masonryFullWidth ? "left" : undefined }}>
              {props.value}
            </div>
          </Box>
        );
      }

      return <Table.Cell css={props.column.css} {...props} />;
    },
    [classes.masonryRow, classes.masonryRowTitle, classes.masonryRowValue, classes.masonryRow_fullWidth, isMasonry],
  );

  return (
    <Table
      containerComponent={renderContainer}
      tableComponent={renderTable}
      rowComponent={renderRow}
      headComponent={renderHead}
      bodyComponent={renderBody}
      cellComponent={renderCell}
      noDataRowComponent={renderNoDataRow}
      noDataCellComponent={renderNoDataCell}
      messages={{
        noData: loading ? "" : t`No Data`,
      }}
      {...props}
    />
  );
};

export default UiDxTable;
