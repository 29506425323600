import { t, Trans } from "@lingui/macro";
import moment from "moment";
import { createElement, FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import SelectAccountCredential from "src/components/SelectAccountCredential";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import { EPipelineMedia, EPipelineType } from "src/enums/pipeline";
import { ICredential, ICredentialVkGroup } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigPublicAds } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import { IStreamConfig } from "src/types/stream";
import getTargetName from "src/utils/getTargetName";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import SelectProject from "../SelectProject";
import AdsPeriod from "./components/AdsPeriod";
import ConfigFbAds from "./components/ConfigFbAds";
import ConfigFbPublic from "./components/ConfigFbPublic";
import ConfigGgl from "./components/ConfigGgl";
import ConfigInstaPublic from "./components/ConfigInstaPublic";
import ConfigOkPublic from "./components/ConfigOkPublic";
import ConfigTiktokAds from "./components/ConfigTiktokAds";
import ConfigVkAds from "./components/ConfigVkAds";
import ConfigVkPublic from "./components/ConfigVkPublic";
import ConfigYaDirect from "./components/ConfigYaDirect";
import ConfigYaMetrika from "./components/ConfigYaMetrika";
import VkGroupPermissions from "./components/VkGroupPermissions";

interface IProps {
  pipelineType: IPipelineType;
  onConfigReady: (config: IPipelineConfigPublicAds) => void;
  isLoading: boolean;
  startStepNum: number;
  onClickBack?: () => void;
  persistState?: {
    [key: string]: any;
  };
}

interface IPersistState {
  activeStep?: number;
  credential?: ICredential | undefined;
  projectId?: IProject["id"] | null;
  config?: IInstanceConfig;
}

type IInstanceConfig = Omit<
  IPipelineConfigPublicAds["config"][number],
  "account_credentials_id" | "entity_account_name" | "entity_target_name" | "source"
>;

const generateParametersStepLabel = (pipelineType: IPipelineType) => {
  if (pipelineType.group === "ads") {
    return t`Select ad account`;
  } else if (pipelineType.group === "public") {
    if (pipelineType.media === EPipelineMedia.INSTAGRAM) {
      return t`Select Instagram account`;
    } else if (pipelineType.media === EPipelineMedia.FB) {
      return t`Select Facebook page`;
    } else if (pipelineType.media === EPipelineMedia.VK) {
      return t`VK community`;
    } else if (pipelineType.media === EPipelineMedia.OK) {
      return t`Select Odnoklassniki group`;
    }
  }

  return t`Specify data collection parameters`;
};

const CreatePublicAds: FC<IProps> = ({
  pipelineType,
  onConfigReady,
  isLoading,
  startStepNum,
  onClickBack,
  persistState: initialPersistState,
}) => {
  const [isValidConfig, setValidConfig] = useState(false);
  const location = useLocation<IPersistState | undefined>();
  const history = useHistory();
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const [activeStep, setActiveStep] = useState(location.state?.activeStep || 0);
  const [projectId, setProjectId] = useState<IProject["id"] | null>(
    location.state?.projectId || (projects && projects.length === 1 && projects[0].id) || null,
  );
  const [credential, setCredential] = useState<ICredential | undefined>(location.state?.credential);
  const [config, setConfig] = useState<IInstanceConfig | null>(location.state?.config || null);
  const [configChain, setConfigChain] = useState<{ [step: number]: typeof config }>({});
  const isVkPublic = useMemo(
    () => [EPipelineType.VK_COMMUNITY_POST, EPipelineType.VK_POST, EPipelineType.VK_COMMUNITY].includes(pipelineType.id),
    [pipelineType.id],
  );
  const steps = [
    projects && projects.length > 1 ? { id: "project", label: t`Select project` } : undefined,
    { id: "dataSource", label: t`Select data source` },
    pipelineType.media !== EPipelineMedia.MYTARGET
      ? { id: "parameters", label: generateParametersStepLabel(pipelineType) }
      : undefined,
    isVkPublic ? { id: "vkGroup", label: t`VK community Stories` } : undefined,
    pipelineType.group === "ads" ? { id: "adsPeriod", label: t`Choose data collection period` } : undefined,
  ].filter((step) => typeof step !== "undefined");

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      if (onClickBack) {
        onClickBack();
      } else {
        history.goBack();
      }
    } else {
      setConfig(configChain[activeStep - 1]);
      setActiveStep((step) => step - 1);
    }
  }, [activeStep, configChain, history, onClickBack]);

  const handleNext = useCallback(() => {
    setConfigChain((state) => ({
      ...state,
      [activeStep]: config,
    }));
    setActiveStep((step) => step + 1);
  }, [activeStep, config]);

  const handleChangeAdsPeriod = useCallback((period) => {
    setConfig((state) => ({
      ...state,
      date_from: moment().subtract(period, "month").format("YYYY-MM-DD"),
    }));
    setValidConfig(true);
  }, []);

  const handleSelectGroupToken = useCallback(
    (groupCredential: ICredentialVkGroup) => {
      setValidConfig(Boolean(groupCredential || config?.group_data?.admin_level !== 3 || !credential?.own));

      if (groupCredential) {
        setConfig((state) => ({
          ...state,
          account_public_credentials_id: groupCredential.account_credential_id,
        }));
      }
    },
    [config?.group_data?.admin_level, credential?.own],
  );

  const handleSubmitConfig = useCallback(() => {
    if (projectId && pipelineType.media === EPipelineMedia.MYTARGET && credential) {
      onConfigReady({
        config: pipelineType.streams.map((stream) => ({
          account_credentials_id: credential.id,
          date_from: config?.date_from,
          entity_account_name: credential.name,
          entity_target_name: credential.name,
          source: stream.id,
        })),
        name: `${credential.name} [myTarget]`,
        project_id: projectId,
        source: pipelineType.id,
      });
    } else if (isValidConfig && config && credential && projectId) {
      const generalStreamProps = {
        ...config,
        account_credentials_id: credential.id,
        entity_account_name: credential.name,
        entity_target_name: getTargetName(pipelineType.id, config as IStreamConfig) || "",
      };

      const pipelineName = (() => {
        if ([EPipelineType.INST_BA_MEDIA, EPipelineType.INST_BA, EPipelineType.INST_MEDIA].includes(pipelineType.id)) {
          return config.instagram_account?.instagram_business_account_name || generalStreamProps.entity_target_name;
        }

        return generalStreamProps.entity_target_name;
      })();

      onConfigReady({
        config: pipelineType.streams.map((stream) => ({
          ...generalStreamProps,
          source: stream.id,
        })),
        name: pipelineName || pipelineType.shortTitle,
        project_id: projectId,
        source: pipelineType.id,
      });
    }
  }, [
    config,
    credential,
    isValidConfig,
    onConfigReady,
    pipelineType.id,
    pipelineType.media,
    pipelineType.shortTitle,
    pipelineType.streams,
    projectId,
  ]);

  const isNextButtonEnabled = useMemo(() => {
    if (steps[activeStep]?.id === "project") {
      return projectId !== null;
    }

    if (steps[activeStep]?.id === "dataSource") {
      return Boolean(credential);
    }

    if (steps[activeStep]?.id === "parameters") {
      return isValidConfig;
    }

    if (steps[activeStep]?.id === "vkGroup") {
      return Boolean(config?.account_public_credentials_id || config?.group_data?.admin_level !== 3 || !credential?.own);
    }

    if (steps[activeStep]?.id === "adsPeriod") {
      return true;
    }
  }, [
    activeStep,
    config?.account_public_credentials_id,
    config?.group_data?.admin_level,
    credential,
    isValidConfig,
    projectId,
    steps,
  ]);

  const persistState = useMemo(
    () => ({
      ...initialPersistState,
      activeStep: activeStep,
      config,
      credential: credential,
      projectId: projectId,
    }),
    [activeStep, config, credential, initialPersistState, projectId],
  );

  const ConfigComponent = useMemo(() => {
    if (pipelineType.media === EPipelineMedia.INSTAGRAM) {
      return ConfigInstaPublic;
    } else if (pipelineType.media === EPipelineMedia.VK) {
      return (
        pipelineType.group &&
        pipelineType.group !== "merge" &&
        {
          ads: ConfigVkAds,
          open_account: ConfigVkPublic,
          public: ConfigVkPublic,
        }[pipelineType.group]
      );
    } else if (pipelineType.media === EPipelineMedia.FB) {
      return (
        pipelineType.group &&
        pipelineType.group !== "merge" &&
        {
          ads: ConfigFbAds,
          open_account: ConfigFbPublic,
          public: ConfigFbPublic,
        }[pipelineType.group]
      );
    } else if (pipelineType.media === EPipelineMedia.GOOGLE) {
      return ConfigGgl;
    } else if (pipelineType.media === EPipelineMedia.OK) {
      return ConfigOkPublic;
    } else if (pipelineType.media === EPipelineMedia.YA) {
      return (
        pipelineType.group &&
        pipelineType.group !== "merge" &&
        {
          ads: ConfigYaDirect,
          open_account: undefined,
          public: ConfigYaMetrika,
        }[pipelineType.group]
      );
    } else if (pipelineType.media === EPipelineMedia.TIKTOK) {
      return ConfigTiktokAds;
    }
  }, [pipelineType]);

  useUpdateEffect(() => {
    setCredential(undefined);
  }, [projectId]);

  useUpdateEffect(() => {
    setConfig(null);
  }, [credential]);

  useUpdateEffect(() => {
    if (!projectsIsLoading && !projectId && projects && projects.length === 1) {
      setProjectId(projects[0].id);
    }
  }, [projectsIsLoading]);

  if (projectsIsLoading) {
    return <Loader />;
  }

  return (
    <UiSimpleStepper
      num={startStepNum + activeStep}
      title={steps[activeStep]?.label}
      onClickPrev={handleBack}
      onClickNext={activeStep >= steps.length - 1 ? handleSubmitConfig : handleNext}
      isDisabledNextBtn={!isNextButtonEnabled}
      isLoadingNextBtn={isLoading}
      textNextBtn={activeStep >= steps.length - 1 ? <Trans>Finish</Trans> : <Trans>Next</Trans>}
    >
      {steps[activeStep]?.id === "project" && <SelectProject value={projectId} onChange={setProjectId} />}

      {steps[activeStep]?.id === "dataSource" && projectId !== null && (
        <SelectAccountCredential
          projectId={projectId}
          source={pipelineType.id}
          value={credential?.id}
          onChange={setCredential}
          persistState={persistState}
          justifyContent="left"
        />
      )}

      {steps[activeStep]?.id === "parameters" &&
        ConfigComponent &&
        credential &&
        projectId &&
        createElement(ConfigComponent as any, {
          config,
          credentialId: credential?.id,
          onChange: setConfig,
          onChangeValidation: setValidConfig,
          persistState,
          pipelineType,
          projectId,
        })}

      {steps[activeStep]?.id === "vkGroup" && config?.group_id && (
        <VkGroupPermissions
          isOwner={Boolean(credential?.own)}
          isAdmin={config?.group_data?.admin_level === 3}
          groupId={config.group_id}
          pipelineType={pipelineType}
          onSelect={handleSelectGroupToken}
          persistState={persistState}
        />
      )}

      {steps[activeStep]?.id === "adsPeriod" && <AdsPeriod onChange={handleChangeAdsPeriod} />}
    </UiSimpleStepper>
  );
};

export default CreatePublicAds;
