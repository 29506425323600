import { Grid, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import { FC, ReactNode } from "react";

import * as classes from "./index.css";

interface IProps {
  title?: string | ReactNode;
  TitleProps?: TypographyProps;
  subTitle?: string | ReactNode;
  SubTitleProps?: TypographyProps;
  icon?: ReactNode;
}

const Head: FC<IProps> = ({ title, TitleProps, icon, subTitle, SubTitleProps, children }) => (
  <div css={classes.container}>
    <div css={classes.container__inner}>
      {(icon || title) && (
        <Grid spacing={1} container justifyContent="center" alignItems="center">
          {icon && (
            <Grid item>
              <div css={classes.icon}>{icon}</div>
            </Grid>
          )}

          {title && (
            <Grid item>
              <Typography variant={TitleProps?.variant || "h1"} textAlign={TitleProps?.textAlign || "center"} {...TitleProps}>
                {title}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {subTitle && (
        <Typography
          variant={SubTitleProps?.variant || "h4"}
          textAlign={SubTitleProps?.textAlign || "center"}
          css={classes.subTitle}
          {...SubTitleProps}
        >
          {subTitle}
        </Typography>
      )}

      {children && <div css={classes.content}>{children}</div>}
    </div>
  </div>
);

export default Head;
