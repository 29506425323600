import { all } from "redux-saga/effects";
import { actionCompile } from "src/utils/action";

import { billingReducers, billingSaga, billingState, IBillingActionMap, IBillingState } from "./billing";
import { IPipelinesActionMap, IPipelinesState, pipelinesReducers, pipelinesSaga, pipelinesState } from "./pipelines";
import { IPopupsActionMap, IPopupsState, popupsReducers, popupsSaga, popupsState } from "./popups";
import { IUsersActionMap, IUsersState, usersReducers, usersSaga, usersState } from "./users";

export * from "./billing";
export * from "./pipelines";
export * from "./popups";
export * from "./shared";
export * from "./users";

export type IModulesActionMap = IPipelinesActionMap & IUsersActionMap & IPopupsActionMap & IBillingActionMap;

export type IModulesState = IPipelinesState & IUsersState & IPopupsState & IBillingState;

const { createReducer } = actionCompile<IModulesActionMap, IModulesState>();

export const modulesState: any = {
  ...usersState,
  ...pipelinesState,
  ...popupsState,
  ...billingState,
};

export const modulesReducer = createReducer(
  {
    ...usersReducers,
    ...pipelinesReducers,
    ...popupsReducers,
    ...billingReducers,
  },
  modulesState,
);

export const modulesSaga = function* () {
  yield all([pipelinesSaga(), popupsSaga(), usersSaga(), billingSaga()]);
};
