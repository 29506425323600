import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import { FC } from "react";
import { IPipelineType } from "src/types/pipeline";

interface IProps {
  pipelineType: IPipelineType;
}

const NoDataAlert: FC<IProps> = ({ pipelineType }) => (
  <Alert severity="info">
    {pipelineType.noDataText || (
      <Trans>You don't have an available object for data collection. Contact support, we will help you.</Trans>
    )}
  </Alert>
);

export default NoDataAlert;
