import { css } from "@emotion/react/macro";

export const tableWrapper = css`
  .MuiToolbar-root {
    display: none;
  }

  margin: 0 -30px;
`;

export const dialog = css`
  .MuiPaper-root {
    overflow-y: scroll;
  }
`;

export const tab = css`
  height: 48px;
  line-height: 16px;
`;
