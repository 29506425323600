import CancelIcon from "@mui/icons-material/Cancel";
import { Slide } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC, ReactElement, useState } from "react";
import { createPortal } from "react-dom";
import { useMount } from "react-use";
import { useAppContext } from "src/context/app/appContext";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

interface IProps {
  onClose?: () => void;
  open?: boolean;
  children: ReactElement;
  variant?: "info" | "warning";
}

const TopNotice: FC<IProps> = ({ children, onClose, open = true, variant = "info" }) => {
  const { state } = useAppContext();
  const [container, setContainer] = useState<Element | null>(null);

  useMount(() => {
    setContainer(document.querySelector("#top-notices"));
  });

  return container && open && state.context === "default"
    ? createPortal(
        <Slide direction="down" in={true}>
          <div css={cssc([classes.container, variant === "warning" && classes.container_warning])}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item sx={{ flexGrow: 1, textAlign: "center" }}>
                {children}
              </Grid>

              {onClose && (
                <Grid item>
                  <CancelIcon fontSize="large" color="inherit" onClick={onClose} css={classes.btn} />
                </Grid>
              )}
            </Grid>
          </div>
        </Slide>,
        container,
      )
    : null;
};

export default TopNotice;
