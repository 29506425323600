import { Trans } from "@lingui/macro";
import { SentimentVeryDissatisfied } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import Message from "src/components/Message";

const Page500: FC = () => (
  <Message
    icon={<SentimentVeryDissatisfied color="secondary" />}
    title={<Trans>Something went wrong</Trans>}
    message={
      <Typography variant="body1">
        <Trans>Email us at</Trans> <a href="mailto:support@datafan.pro">support@datafan.pro</a>
      </Typography>
    }
  />
);

export default Page500;
