import { t, Trans } from "@lingui/macro";
import { Box, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { FC, useCallback, useState } from "react";
import { ColorResult } from "react-color";
import { useDispatch } from "react-redux";
import ProjectColorPicker from "src/components/ProjectColorPicker";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import UiTextField from "src/components/UiTextField";
import { popupAdd } from "src/redux";
import useQueryProjectCreate from "src/utils/queries/project/useQueryProjectCreate";

interface IProps {
  isOpened: boolean;
  onClose?: () => void;
}

const ModalProjectCreate: FC<IProps> = ({ isOpened, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const { mutate: createProject, isLoading: createProjectIsLoading } = useQueryProjectCreate({
    onSuccess: () => {
      onClose && onClose();

      setColor("");
      setName("");
      dispatch(
        popupAdd({
          text: t`Project successfully created`,
        }),
      );
    },
  });

  const handleChangeColor = useCallback((color: ColorResult) => {
    setColor(color.hex);
  }, []);

  const handleClickSave = useCallback(() => {
    createProject({ color, name });
  }, [color, createProject, name]);

  return (
    <UiDialog open={isOpened} keepMounted onClose={onClose} title={<Trans>Create project</Trans>} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            <Trans>You can create projects and give access to other people.</Trans>
          </Typography>

          <UiTextField autoFocus value={name} onChange={setName} placeholder={t`Project name`} fullWidth />

          <div>
            <Typography variant="h4" mb={1}>
              <Trans>Color</Trans>
            </Typography>
            <Box sx={{ marginLeft: "2px", marginRight: "-20px" }}>
              <ProjectColorPicker color={color} onChange={handleChangeColor} />
            </Box>
          </div>
        </Stack>
      </DialogContent>

      <DialogActions>
        {onClose && (
          <UiButton onClick={onClose} disabled={createProjectIsLoading} variant="text">
            <Trans>Cancel</Trans>
          </UiButton>
        )}

        <UiButton disabled={name === ""} loading={createProjectIsLoading} onClick={handleClickSave}>
          <Trans>Create</Trans>
        </UiButton>
      </DialogActions>
    </UiDialog>
  );
};

export default ModalProjectCreate;
