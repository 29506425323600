import { Stack } from "@mui/material";
import { FC } from "react";
import { components, OptionProps } from "react-select";
import { IProjectExtended } from "src/types/project";

import * as classes from "./index.css";

const SelectProjectOption: FC<OptionProps<{ data: IProjectExtended }, false>> = ({ children, ...props }) => (
  <components.Option {...props}>
    <Stack direction="row" alignItems="center" spacing={0.8}>
      <div css={classes.indicator} style={{ backgroundColor: props.data.data.color }} />
      <div>{children}</div>
    </Stack>
  </components.Option>
);

export default SelectProjectOption;
