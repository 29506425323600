import { useAppContext } from "src/context/app/appContext";
import { IProject, IProjectMember } from "src/types/project";
import { useQueryProjectMembersInvalidate } from "src/utils/queries/project/useQueryProjectMembersGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  id: IProjectMember["id"];
  projectId: IProject["id"];
}

export default function useQueryProjectMemberDelete(queryOptions?: IUseQueryMutationOptions<unknown, IInput>) {
  const invalidateMembers = useQueryProjectMembersInvalidate();
  const { state, updateState } = useAppContext();

  return useMutationBase<unknown, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateMembers(args[1].projectId);

        if (state.dashboard.currentProjects) {
          updateState({
            dashboard: {
              currentProjects: state.dashboard.currentProjects.filter((id) => id !== args[1].projectId),
            },
          });
        }

        queryOptions?.onSuccess?.apply(queryOptions, args);
      },
    },
    {
      method: "DELETE",
      url: (params) => `project/${params.projectId}/owners/${params.id}/`,
    },
  );
}
