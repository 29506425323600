import { Trans } from "@lingui/macro";
import { DialogActions, DialogContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import UiButton from "src/components/UiButton";
import UiDialog, { UiDialogProps } from "src/components/UiDialog";

const ModalBillingDisabledAlert: FC<UiDialogProps> = (props) => (
  <UiDialog keepMounted fullWidth maxWidth="xs" hideCross {...props}>
    <DialogContent>
      <Typography variant="h4" mt={4} mb={3} align="center">
        <Trans>Datafan service payment is currently unavailable.</Trans>
      </Typography>
    </DialogContent>

    <DialogActions>
      <UiButton onClick={props.onClose} color="primary" variant="contained">
        <Trans>Continue</Trans>
      </UiButton>
    </DialogActions>
  </UiDialog>
);

export default ModalBillingDisabledAlert;
