import { FC } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";

import ProjectsAcceptInvite from "./pages/AcceptInvite";
import ProjectsEdit from "./pages/Edit";
import ProjectsHome from "./pages/Home";

const Projects: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ProjectsHome} />
      <Route exact path={`${path}:projectId/`} component={ProjectsEdit} />
      <Route exact path={`${path}:projectId/accept-invite/`} component={ProjectsAcceptInvite} />
    </Switch>
  );
};

export default Projects;
