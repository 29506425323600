import { EPipelineType } from "src/enums/pipeline";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import { IStream, IStreamConfig } from "src/types/stream";
import deserializePipelineConfig from "src/utils/deserializePipelineConfig";

export function getSamePipelines<T extends IPipeline | IPipelineExtended>(pipelines: T[], configs: any[], source: number): T[] {
  if ([EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(source)) {
    const mergeConfigs = configs;

    return pipelines.filter(({ streams }) => {
      const streamIds =
        streams
          .map((stream) => stream.config_source.streams && Object.keys(stream.config_source.streams))
          .flat()
          .sort() || [];
      const configStreamIds =
        mergeConfigs
          .filter((config) => config.streams)
          .map((config) => Object.keys(config.streams))
          .flat()
          .sort() || [];

      return streamIds.join("") === configStreamIds.join("");
    });
  } else {
    const commonConfigs = [...(configs as IStreamConfig[])].sort((c1, c2) => ((c1.source || 0) > (c2.source || 0) ? 1 : -1));

    return pipelines.filter(({ streams }) => {
      if (streams.length !== configs.length) {
        return false;
      }

      if (source === EPipelineType.INST_OPEN_ACCOUNT || source === EPipelineType.TELEGRAM_CHANNEL_POST) {
        const streamPages = streams.map((stream) => stream.config_source.search_account_username);
        const configPages = configs.map((config) => config.search_account_username);

        return JSON.stringify([...streamPages].sort()) === JSON.stringify([...configPages].sort());
      }

      const relativeStreams = commonConfigs.reduce((acc: IStream[], config) => {
        [...streams]
          .sort((c1, c2) => ((c1.source || 0) > (c2.source || 0) ? 1 : -1))
          .forEach((stream) => {
            if (
              stream.source !== config.source ||
              stream.config_source.account_credentials_id !== config.account_credentials_id
            ) {
              return false;
            }

            const streamTargetIds =
              deserializePipelineConfig(stream.source, stream.config_source)
                .map((target) => target.ids)
                .flat()
                .sort() || [];

            const configTargetIds =
              deserializePipelineConfig(config.source, config)
                .map((target) => target.ids)
                .flat()
                .sort() || [];

            if (streamTargetIds.join("") !== configTargetIds.join("")) {
              return false;
            }

            const streamCampaignIds = stream.config_source.campaigns?.map(({ id }) => id).sort() || [];

            const configCampaignIds = config.campaigns?.map(({ id }) => +id).sort() || [];

            if (streamCampaignIds?.join("") === configCampaignIds?.join("")) {
              acc.push(stream);
            }
          });

        return acc;
      }, []);

      return relativeStreams.length === configs.length;
    });
  }
}
