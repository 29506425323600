import { Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme: Theme) => ({
  headBlue: {
    "& th": {
      "&:first-of-type": {
        borderRadius: "8px 0 0 0",
      },

      "&:last-of-type": {
        borderRadius: "0 8px 0 0",
      },

      background: theme.palette.cyan.main,
    },
  },

  loader: {
    alignItems: "center",
    background: alpha("#ffffff", 0.5),
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },

  masonryNoDataTable: {
    "& td": {
      display: "block",
    },

    "& tr": {
      display: "block",
    },

    flexGrow: 1,
    padding: theme.spacing(2),
  },

  masonryPaper: {
    "& .devexpress-table-MuiTableCell-paddingCheckbox": {
      background: "#ffffff",
      borderRadius: "50%",
      height: "40px !important",
      padding: "0 !important",
      position: "absolute",
      right: 10,
      top: 10,
      width: "40px !important",
    },

    height: "100%",
    position: "relative",
  },

  masonryRow: {
    "&:last-of-type": {
      borderBottom: "none",
    },

    alignItems: "baseline",
    borderBottom: "1px solid #E2E2E2",
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 0",
    textOverflow: "ellipsis",
  },

  masonryRowTitle: {
    color: "#000000",
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },

  masonryRowValue: {
    "& .MuiTypography-root:not(.MuiLink-root)": {
      color: "inherit",
    },

    color: "#000000",
    flexGrow: 1,
    textAlign: "right",
  },

  masonryRow_fullWidth: {
    "& .MuiTypography-root": {
      width: "100%",
    },

    flexDirection: "column",
  },

  masonrySelectionCell: {
    position: "absolute",
  },

  masonryTable: {
    display: "flex !important",
    width: "auto",
  },

  masonryTd: {
    display: "block",
    height: "100%",
    padding: 0,
    width: "100%",
  },

  masonryTr: {
    margin: "0 !important",
    position: "relative",
  },

  wrapper: {
    "& .devexpress-table-MuiTableCell-head": {
      fontWeight: 600,
      paddingBottom: 10,
      paddingTop: 10,
    },

    "& .devexpress-table-MuiTableCell-root": {
      "& .MuiTypography-root:not(.MuiLink-root)": {
        color: "inherit",
      },

      "&:first-of-type": {
        paddingLeft: 30,
      },

      "&:last-of-type": {
        paddingRight: 30,
      },

      color: "#000000",
      height: 66,
      lineHeight: "1.2rem",
      paddingBottom: 24,
      paddingTop: 24,
    },

    flexGrow: 1,
    overflow: "auto",
    position: "relative",
    width: "100%",
  },

  wrapper_masonry: {
    "& .devexpress-table-MuiTable-root > colgroup": {
      display: "none",
    },

    "& .devexpress-table-MuiTableCell-root": {
      border: "none",
      display: "block",
      padding: theme.spacing(1, 2),
      textAlign: "left",
      width: "100%",
    },

    "& .devexpress-table-MuiTableHead-root": {
      display: "none",
    },

    "& .devexpress-table-MuiTableRow-root": {
      display: "block",
      marginBottom: theme.spacing(3),
      width: "100%",
    },

    overflow: "visible",
  },
}));
