import { Grid, GridProps } from "@devexpress/dx-react-grid-material-ui";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { FC } from "react";

const generateClassName = createGenerateClassName({
  seed: "devexpress-grid",
});

const UiDxGrid: FC<GridProps> = (props) => (
  <StylesProvider generateClassName={generateClassName}>
    <Grid {...props} />
  </StylesProvider>
);

export default UiDxGrid;
