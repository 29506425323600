import { Dialog, DialogProps, DialogTitle, Grid, Slide } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, ReactNode, Ref, useCallback } from "react";
import CrossIcon from "src/icons/CrossIcon";

import { classes } from "./index.css";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children?: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface UiDialogProps extends Omit<DialogProps, "TransitionComponent" | "title"> {
  title?: string | ReactNode;
  hideCross?: boolean;
  onClose?: () => void;
  withoutPadding?: boolean;
  disableCloseOnBackdropClick?: boolean;
}

const UiDialog = forwardRef<HTMLDivElement | null, UiDialogProps>(
  ({ title, hideCross, children, onClose, withoutPadding, fullScreen, disableCloseOnBackdropClick, ...props }, ref) => {
    const handleCloseDialog = useCallback(
      (e, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick" && disableCloseOnBackdropClick) {
          return false;
        }

        onClose && onClose();
      },
      [disableCloseOnBackdropClick, onClose],
    );

    return (
      <Dialog TransitionComponent={Transition} onClose={handleCloseDialog} fullScreen={fullScreen} ref={ref} {...props}>
        {(title || !hideCross) && (
          <Grid css={classes.head(fullScreen)} container justifyContent="space-between" alignItems="center">
            <Grid item>{title && <DialogTitle>{title}</DialogTitle>}</Grid>

            {!hideCross && (
              <Grid item css={classes.cross}>
                <IconButton size="small" onClick={onClose}>
                  <CrossIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}

        <div css={classes.content(withoutPadding)}>{children}</div>
      </Dialog>
    );
  },
);

export default UiDialog;
