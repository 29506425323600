import { IInvoice } from "src/types/billing";
import { IPaymentMethod } from "src/types/paymentMethod";
import { ITariffLimits } from "src/types/tariff";
import { COUNT, DELETING, FAILURE, LOADING, PAGE, PAGE_SIZE } from "src/utils";

export enum ECurrency {
  RUB = "RUB",
  EUR = "EUR",
  USD = "USD",
}

export enum ETariffId {
  TRIAL_OUTDATED = 1,
  START = 2,
  PROFESSIONAL = 3,
  AGENT = 4,
  TRIAL = 6,
  ENTERPRISE = 7,
}

export enum EInvoiceStatus {
  PROCESSING = 1,
  PAID = 2,
  CANCELED = 3,
}

export enum ETariffPeriodMeasure {
  WEEK = 1,
  MONTH = 2,
  DAY = 3,
}

export interface ITariff {
  id: number;
  title: string;
  sign: string;
  priceRub: string;
  priceRubDiscount: string;
  priceEur: string;
  priceEurDiscount: string;
  period: string;
}

export interface ITariffs {
  [LOADING]: boolean;
  items?: ITariff[];
  ids?: number[];
}

export interface ITariffOptionsItem {
  id: number;
  name: string;
  sign: string;
  priceRub: string;
  priceEur: string;
  tariffId: number | null;
}

export interface ITariffOptions {
  [LOADING]: boolean;
  items?: ITariffOptionsItem[];
}

export interface IPaymentHistory {
  [LOADING]: boolean;
  [PAGE]: number;
  [PAGE_SIZE]: number;
  [COUNT]: undefined | number;
  items?: IInvoice[];
}

export interface IPaymentMethods {
  [LOADING]: boolean;
  [DELETING]: boolean;
  items?: IPaymentMethod[];
}

export interface IInternalTransactionsState {
  [LOADING]: boolean;
  [PAGE]: number;
  [PAGE_SIZE]: number;
  [COUNT]: undefined | number;
  items?: IInternalTransaction[];
}

export interface ISubscriptions {
  [LOADING]: boolean;
  items?: ISubscription[];
  ids?: number[];
}

export enum EInternalTransactionOperation {
  REFILL = 1,
  WITHDRAW = 2,
}

export interface IInternalTransaction {
  account_type: number;
  account_type_display: string;
  amount: string;
  created_at: Date;
  currency: ECurrency;
  description: string;
  id: number;
  invoice: null | IInvoice;
  operation: EInternalTransactionOperation;
  operation_display: string;
}

export interface ITariffOnPeriod {
  id: number;
  tariff: {
    id: number;
    name: string;
    free_renewal: boolean;
    gradation: number;
  };
  period: {
    id: number;
    measure: ETariffPeriodMeasure;
    measure_display: string;
    units: number;
    name: string;
  };
  sign: string;
  rub_price: string;
  eur_price: string;
  rub_discount_price: string | null;
  eur_discount_price: string | null;
  is_negotiable_price: boolean;
}

export interface ISubscription {
  id: number;
  can_renewal_with_free_days: boolean;
  status: number;
  status_display: string;
  limits: ITariffLimits;
  limits_usage: ITariffLimits;
  date_start: string;
  date_finish: string;
  created_at: string;
  tariff_on_period: ITariffOnPeriod;
  time_left: number;
  total_price: string;
  options: Array<{
    id: number;
    name: string;
    sign: string;
    tariff_on_period: number | null;
    rub_price: string;
    eur_price: string;
  }>;
  currency: ECurrency;
  auto_payment: boolean;
}

export interface ICalculate {
  current_discount_price?: number;
  current_discount_promo_code_price?: number | null;
  internal_account_withdrawal_amount: number;
  internal_account_refill_amount: number;
  internal_flags: {
    full_paid_internally: boolean;
  };
  next_price: number;
}
export interface IPromocode {
  value?: string;
}

export interface IUpdateSubscription {
  [LOADING]: boolean;
}

export interface IBillingState {
  billing: {
    subscriptions: ISubscriptions;
    tariffs: ITariffs;
    tariffOptions: ITariffOptions;
    invoiceState: {
      [LOADING]: boolean;
      [FAILURE]: boolean;
      model?: IInvoice;
    };
    calculate: {
      create?: ICalculate;
      update?: ICalculate;
    };
    promocode: IPromocode;
    updateSubscription: IUpdateSubscription;
    paymentHistory: IPaymentHistory;
    paymentMethods: IPaymentMethods;
    internalTransactions: IInternalTransactionsState;
  };
}

export const billingState: IBillingState = {
  billing: {
    calculate: {
      create: undefined,
      update: undefined,
    },
    internalTransactions: {
      [COUNT]: undefined,
      [LOADING]: false,
      [PAGE]: 0,
      [PAGE_SIZE]: 20,
      items: undefined,
    },
    invoiceState: {
      [FAILURE]: false,
      [LOADING]: false,
      model: undefined,
    },
    paymentHistory: {
      [COUNT]: undefined,
      [LOADING]: false,
      [PAGE]: 0,
      [PAGE_SIZE]: 20,
      items: undefined,
    },
    paymentMethods: {
      [DELETING]: false,
      [LOADING]: false,
      items: undefined,
    },
    promocode: {
      value: undefined,
    },
    subscriptions: {
      [LOADING]: false,
      ids: undefined,
      items: undefined,
    },
    tariffOptions: {
      [LOADING]: false,
      items: undefined,
    },
    tariffs: {
      [LOADING]: false,
      ids: undefined,
      items: undefined,
    },
    updateSubscription: {
      [LOADING]: false,
    },
  },
};
