import { css } from "@emotion/react/macro";

export const checkbox = css`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: #ffffff;
  color: #ce383a;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-top: -2px;
  flex-shrink: 0;
`;

export const checkbox_checked = css`
  background: #20a8a6;
  color: #ffffff;
`;
