import { t } from "@lingui/macro";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import UiVirtualTable from "src/components/UiVirtualTable";
import { billingPaymentHistoryGet, EInvoiceStatus, IRootState } from "src/redux";
import { IInvoice } from "src/types/billing";
import { COUNT, LOADING, PAGE, PAGE_SIZE } from "src/utils";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";

const PaymentHistory: FC = () => {
  const dispatch = useDispatch();
  const { paymentHistory, skip } = useSelector((state: IRootState) => ({
    paymentHistory: state.modules.billing.paymentHistory,
    skip: state.modules.billing.paymentHistory[PAGE_SIZE] * state.modules.billing.paymentHistory[PAGE],
  }));

  const getRows = useCallback(
    (skip: number, take: number) => {
      if (!paymentHistory[LOADING]) {
        dispatch(
          billingPaymentHistoryGet({
            limit: take,
            offset: skip,
          }),
        );
      }
    },
    [dispatch, paymentHistory],
  );

  const getRowId = useCallback((row: any) => row.id, []);

  return useMemo(
    () => (
      <UiVirtualTable
        gridProps={{
          columns: [
            {
              getCellValue: (row: IInvoice) => moment(row.created_at).format("YYYY-MM-DD HH:mm"),
              name: "date",
              title: t`Date`,
            },
            {
              name: "description",
              title: t`Operation`,
            },
            {
              getCellValue: (row: IInvoice) => (
                <Typography
                  variant="body1"
                  color={
                    (row.status === EInvoiceStatus.CANCELED && "secondary") ||
                    (row.status === EInvoiceStatus.PAID && "primary") ||
                    "inherit"
                  }
                >
                  {row.status_display}
                </Typography>
              ),
              name: "status_display",
              title: t`Status`,
            },
            {
              getCellValue: (row: IInvoice) => `${row.total} ${getCurrencyDisplayName(row.currency)}`,
              name: "amount",
              title: t`Amount`,
            },
          ],
          getRowId: getRowId,
          rows: paymentHistory.items || [],
        }}
        tableStateProps={{
          getRows: getRows,
          infiniteScrolling: true,
          loading: paymentHistory[LOADING],
          pageSize: paymentHistory[PAGE_SIZE],
          skip: skip,
          totalRowCount: paymentHistory[COUNT] || 0,
        }}
        tableProps={{
          columnExtensions: [
            { columnName: "date", width: 150 },
            { columnName: "status_display", width: 150 },
            { columnName: "amount", width: 150 },
          ],
          height: "inherit",
          minHeight: 300,
        }}
      />
    ),
    [getRowId, getRows, paymentHistory, skip],
  );
};

export default PaymentHistory;
