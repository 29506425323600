import { useHistory } from "react-router";
import { useAppContext } from "src/context/app/appContext";
import getAnalytic from "src/utils/getAnalytic";
import getGaCid from "src/utils/getGaCid";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";
import { updateTokens } from "src/utils/tokens";

interface IParams {
  email: string;
  password: string;
  phone?: string;
}

interface IOutput {
  refresh_token: string;
  token: string;
}

export default function useQueryAuthSignUp({ email, password, phone }: IParams, queryOptions?: IUseQueryBaseOptions<IOutput>) {
  const history = useHistory();
  const {
    state: { language },
  } = useAppContext();

  return useQueryBase<IOutput>(
    "auth-sign-up",
    {
      enabled: false,
      onSuccess: (resp) => {
        updateTokens(resp.token, resp.refresh_token);
        history.replace({ pathname: "/welcome/", state: { isNewUser: true } });
      },
      ...queryOptions,
    },
    {
      method: "POST",
      params: {
        _adb: Boolean(document?.getElementById("adBanner")),
        cid: getGaCid(),
        email,
        language,
        password,
        phone,
        utm: getAnalytic(),
      },
      url: `user/signup/jwt/${language ? "?lang=" + language : ""}`,
    },
  );
}
