import { t, Trans } from "@lingui/macro";
import { FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import { IconCopy } from "@tabler/icons";
import copy from "copy-to-clipboard";
import { FC, useCallback, useMemo, useState } from "react";
import { LinkProps } from "react-router-dom";
import Loader from "src/components/Loader";
import UiButton from "src/components/UiButton";
import UiTextField from "src/components/UiTextField";
import { EPipelineReportTokenStatus } from "src/enums/pipeline";
import { IPipeline } from "src/redux";
import useQueryReportTokenGet from "src/utils/queries/pipeline/useQueryReportTokenGet";
import useQueryPipelinePatch from "src/utils/queries/pipeline/useQueryReportTokenPatch";

interface IProps {
  pipelineId: IPipeline["id"];
  reportLink: LinkProps["to"];
}

const ReportSharing: FC<IProps> = ({ pipelineId, reportLink }) => {
  const [enabled, setEnabled] = useState(false);
  const [copyTooltipIsOpen, setCopyTooltipOpen] = useState(false);
  const { data: tokenData, isLoading: tokenIsLoading } = useQueryReportTokenGet(pipelineId, {
    onSuccess: (data) => {
      setEnabled(data.status === EPipelineReportTokenStatus.ENABLE);
    },
  });
  const { mutate: tokenDataPatch } = useQueryPipelinePatch({
    onError: () => {
      setEnabled((enabled) => !enabled);
    },
    onSuccess: (data) => {
      setEnabled(data.status === EPipelineReportTokenStatus.ENABLE);
    },
  });

  const url = useMemo(
    () =>
      tokenData &&
      process.env.REACT_APP__BASE_URL &&
      `${process.env.REACT_APP__BASE_URL.replace(/\/$/, "")}/app${reportLink}${tokenData.token}`,
    [reportLink, tokenData],
  );

  const handleToggle = useCallback(() => {
    setEnabled(!enabled);

    tokenDataPatch({
      pipelineId,
      status: enabled ? EPipelineReportTokenStatus.DISABLE : EPipelineReportTokenStatus.ENABLE,
    });
  }, [enabled, pipelineId, tokenDataPatch]);

  const handleCopy = useCallback(() => {
    if (url) {
      copy(url);

      setCopyTooltipOpen(true);
      setTimeout(() => {
        setCopyTooltipOpen(false);
      }, 2000);
    }
  }, [url]);

  return useMemo(() => {
    if (tokenIsLoading) {
      return <Loader />;
    }

    if (!tokenData) {
      return null;
    }

    return (
      <>
        <FormControlLabel
          control={<Switch checked={enabled} />}
          label={<Trans>Allow access to the report via link</Trans>}
          onChange={handleToggle}
          sx={{ mt: 2 }}
        />

        {enabled && url && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <UiTextField fullWidth value={url} disabled />

            <Tooltip followCursor={false} open={copyTooltipIsOpen} title={t`Link copied to clipboard`} placement="bottom">
              <span>
                <UiButton
                  disableElevation
                  style={{ width: 200 }}
                  variant="outlined"
                  startIcon={<IconCopy />}
                  onClick={handleCopy}
                >
                  <Trans>Copy</Trans>
                </UiButton>
              </span>
            </Tooltip>
          </Stack>
        )}
      </>
    );
  }, [copyTooltipIsOpen, enabled, handleCopy, handleToggle, tokenData, tokenIsLoading, url]);
};

export default ReportSharing;
