import { Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigTiktok } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigTiktok | null) => void;
  onChangeValidation: (isValid: boolean) => void;
  config: IPipelineConfigTiktok | null;
}

const ConfigTiktokAds: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation, config }) => {
  const [selectedAccount, setSelectedAccount] = useState<IPipelineConfigTiktok["tiktok_ads_account"] | null>(
    config?.tiktok_ads_account || null,
  );

  const {
    data: accounts,
    isFetched: accountsFetched,
    isFetching: accountsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.TIKTOK_ACCOUNTS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedAccount) {
          setSelectedAccount(data[0] || null);
        }
      },
    },
  );

  const accountOptions = useMemo(
    () =>
      accounts?.map((account) => ({
        data: account,
        label: `${account.advertiser_name} (${account.advertiser_id})`,
        value: account.advertiser_id,
      })) || [],
    [accounts],
  );

  const handleSelectAccount = useCallback(
    (value: typeof accountOptions[number]["value"] | null, data: typeof accountOptions[number]["data"] | null) => {
      if (data) {
        setSelectedAccount(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedAccount));

    onChange({
      tiktok_ads_account: selectedAccount || undefined,
      tiktok_ads_advertiser_id: selectedAccount?.["advertiser_id"],
    });
  }, [onChangeValidation, onChange, selectedAccount]);

  return (
    <>
      {accountOptions.length === 0 && accountsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={accountOptions.length > 7}
          isLoading={accountsIsFetching}
          value={selectedAccount?.advertiser_id}
          onChange={handleSelectAccount}
          options={accountOptions}
          placeholder={<Trans>Ad account</Trans>}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigTiktokAds;
