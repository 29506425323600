import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const classes = {
  footer: (theme: Theme) => css`
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #e2e2e2;
    padding: 30px 0 0;
    margin-top: 52px;
    margin-bottom: -10px;
    display: flex;
    justify-content: center;
    text-align: center;

    ${theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  `,

  footer_lock: () => css`
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `,

  link: (theme: Theme) => css`
    color: #505050;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    padding: 0 38px;
    position: relative;
    transition: color 150ms;

    ${theme.breakpoints.down("md")} {
      padding: 5px 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 1px;
      background: #505050;
      height: 14px;

      ${theme.breakpoints.down("md")} {
        display: none;
      }
    }

    &:last-child:after {
      display: none;
    }

    &:hover {
      color: #000000;
    }
  `,
};
