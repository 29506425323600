export enum ECredentialMetaObject {
  INSTAGRAM_ACCOUNTS = "instagram.accounts",
  FACEBOOK_ADS_ACCOUNTS = "facebook.ad_accounts",
  FACEBOOK_PAGE_PERMISSION_WEBHOOK = "facebook.check_facebook_page_permission_webhook",
  FACEBOOK_PAGES = "user.accounts",
  VK_ADS_ACCOUNTS = "ads.Accounts",
  VK_ADS_CLIENTS = "ads.getClients",
  VK_GROUPS = "groups.get",
  YA_METRIKA_COUNTERS = "metrika.counters",
  YA_METRIKA_GOALS = "metrika.goals",
  YA_DIRECT_CLIENTS = "direct.clients",
  GGL_ANALYTICS_PROFILES = "google_analytics.profile",
  OK_GROUPS = "ok.groups",
  TIKTOK_ACCOUNTS = "ads.accounts",
  INSTAGRAM_OPEN_ACCOUNT = "instagram.open_account",
  TELEGRAM_CHANEL = "subscribe_to_channel",
  TELEGRAM_CHANEL_INFO = "channel_info",
}
