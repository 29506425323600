import { Plural, Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { FC, useCallback } from "react";
import { IPipeline } from "src/types/pipeline";
import { ISubscription } from "src/types/subscription";

import * as classes from "./index.css";

interface IProps {
  runDelta: IPipeline["run_delta"];
  subscription: ISubscription;
  onChange: (e: number) => void;
}

const RUN_DELTA_STEP = 3600;
const RUN_DELTA_MAX = 24 * RUN_DELTA_STEP;

const FrequencyUpdate: FC<IProps> = ({ runDelta, subscription, onChange }) => {
  const handleChangeRunDeltaSlider = useCallback(
    (e: Event, value: number | number[]) => {
      if (subscription) {
        value = Array.isArray(value) ? value[0] : value;

        onChange(subscription.limits.update_frequency_seconds - value);
      }
    },
    [onChange, subscription],
  );

  return (
    <>
      <Typography mb={1.6} component="div">
        <Trans>Frequency of data collection</Trans>
        {": "}
        <span css={classes.label__value}>
          <Plural
            value={runDelta / RUN_DELTA_STEP}
            _1="Every hour"
            one="Once every # hour"
            few="Once every # hours"
            many="Once every # hours"
            other="Once every # hours"
          />
        </span>
      </Typography>

      <Slider
        value={subscription.limits.update_frequency_seconds - runDelta}
        step={RUN_DELTA_STEP}
        onChange={handleChangeRunDeltaSlider}
        min={-RUN_DELTA_MAX + subscription.limits.update_frequency_seconds}
        max={0}
      />
    </>
  );
};

export default FrequencyUpdate;
