import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ConnectorExcelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.0677 11.442L5.58125 9.76763V22.1389C5.58125 22.2733 5.60772 22.4064 5.65916 22.5306C5.71059 22.6548 5.78598 22.7676 5.88102 22.8627C5.97607 22.9577 6.0889 23.0331 6.21307 23.0845C6.33725 23.136 6.47034 23.1625 6.60475 23.1625H22.9737C23.1081 23.1625 23.2412 23.136 23.3654 23.0845C23.4895 23.0331 23.6024 22.9577 23.6974 22.8627C23.7924 22.7676 23.8678 22.6548 23.9193 22.5306C23.9707 22.4064 23.9972 22.2733 23.9972 22.1389V22.1389V17.5817L15.0677 11.442Z"
        fill="#185C37"
      />
      <path
        d="M15.0677 0.838924H6.60475C6.3333 0.838924 6.07297 0.94676 5.88102 1.13871C5.68908 1.33066 5.58125 1.591 5.58125 1.86245V1.86245V6.42034L15.0677 12L20.0867 13.681L23.9919 12.0067V6.42034L15.0677 0.838924Z"
        fill="#21A366"
      />
      <path d="M5.58125 6.42034H15.0677V12H5.58125V6.42034Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3709 5.30398H5.58125V19.2557H12.3709C12.6421 19.2547 12.9018 19.1466 13.0935 18.9548C13.2852 18.7631 13.3934 18.5034 13.3944 18.2322V6.32645C13.3936 6.05535 13.2855 5.79561 13.0937 5.60401C12.9019 5.41241 12.642 5.30454 12.3709 5.30398V5.30398Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8129 5.86234H5.58125V19.8141H11.8129C11.9482 19.8143 12.0821 19.7879 12.2071 19.7362C12.332 19.6846 12.4456 19.6088 12.5412 19.5132C12.6368 19.4176 12.7126 19.304 12.7642 19.179C12.8159 19.0541 12.8423 18.9201 12.8421 18.7849V6.88481C12.8417 6.75009 12.8147 6.61677 12.7628 6.49247C12.7108 6.36817 12.6349 6.25532 12.5393 6.16037C12.4438 6.06542 12.3304 5.99023 12.2058 5.93909C12.0812 5.88795 11.9477 5.86187 11.8129 5.86234V5.86234Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8129 5.86234H5.58125V18.7059H11.8129C11.9483 18.7061 12.0823 18.6796 12.2074 18.6279C12.3324 18.5761 12.4461 18.5002 12.5417 18.4044C12.6373 18.3087 12.7131 18.195 12.7646 18.0698C12.8162 17.9447 12.8425 17.8106 12.8421 17.6753V6.88481C12.8417 6.75009 12.8147 6.61677 12.7628 6.49247C12.7108 6.36817 12.6349 6.25532 12.5393 6.16037C12.4438 6.06542 12.3304 5.99023 12.2058 5.93909C12.0812 5.88795 11.9477 5.86187 11.8129 5.86234V5.86234Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2546 5.86234H5.58125V18.7059H11.2546C11.5259 18.7053 11.7859 18.5973 11.9777 18.4055C12.1695 18.2136 12.2776 17.9536 12.2781 17.6823V6.88481C12.2778 6.61353 12.1699 6.35347 11.978 6.16175C11.786 5.97003 11.5259 5.86234 11.2546 5.86234V5.86234Z"
        fill="black"
      />
      <path
        d="M1.0235 5.86234H11.2546C11.389 5.86234 11.5221 5.88881 11.6463 5.94025C11.7705 5.99169 11.8833 6.06708 11.9783 6.16212C12.0734 6.25716 12.1488 6.37 12.2002 6.49418C12.2516 6.61836 12.2781 6.75145 12.2781 6.88587V17.1155C12.2781 17.387 12.1703 17.6473 11.9783 17.8393C11.7864 18.0312 11.5261 18.139 11.2546 18.139H1.0235C0.75205 18.139 0.491719 18.0312 0.299776 17.8393C0.107833 17.6473 0 17.387 0 17.1155V6.88481C0 6.61335 0.107833 6.35301 0.299776 6.16106C0.491719 5.96911 0.75205 5.86128 1.0235 5.86128V5.86234Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M3.16755 15.3275L5.32043 11.9901L3.34402 8.67245H4.93927L6.0157 10.7954C6.11452 10.998 6.18017 11.1483 6.2197 11.2465H6.23276C6.30334 11.082 6.37781 10.9288 6.45652 10.7774L7.60883 8.67669H9.05585L7.03426 11.9696L9.1102 15.3282H7.56084L6.31781 12.9988C6.25854 12.9 6.2089 12.7958 6.16958 12.6875H6.15158C6.11502 12.7932 6.06634 12.8944 6.00653 12.9889L4.72715 15.3286L3.16755 15.3275Z"
        fill="white"
      />
      <path
        d="M22.9733 0.838916H15.0709V6.42033H24V1.86245C24 1.72777 23.9734 1.59441 23.9218 1.47002C23.8702 1.34563 23.7945 1.23266 23.6991 1.13757C23.6037 1.04249 23.4905 0.967163 23.366 0.915913C23.2414 0.864663 23.108 0.838498 22.9733 0.838916V0.838916Z"
        fill="#33C481"
      />
      <path d="M15.0677 12H23.9968V17.58H15.0677V12Z" fill="#107C41" />
      <defs>
        <linearGradient id="paint0_linear" x1="26.4348" y1="-312.869" x2="34.452" y2="-298.995" gradientUnits="userSpaceOnUse">
          <stop stopColor="#18884F" />
          <stop offset="0.5" stopColor="#117E43" />
          <stop offset="1" stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
