import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const head = css`
  overflow: hidden;
  line-height: 24px;
`;

export const num = css`
  display: block;
  float: left;
  width: 24px;
  height: 24px;
  background: #55ade0;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  margin-right: 20px;

  .icon {
    margin-top: 3px;
    width: 18px;
    height: 18px;
  }
`;

export const info = css`
  display: inline-block;
  margin-left: 10px;
  vertical-align: text-top;

  .icon {
    display: block;
    width: 18px;
    height: 18px;
  }
`;

export const content = (theme: Theme) => css`
  padding: 30px 30px 30px 44px;

  ${theme.breakpoints.down("sm")} {
    padding: 20px 0;
  }
`;

export const buttons = (theme: Theme) => css`
  padding-left: 44px;

  ${theme.breakpoints.down("sm")} {
    padding-left: 0;
  }
`;
