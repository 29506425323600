import { Trans } from "@lingui/macro";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import UiDialog from "src/components/UiDialog";
import { parseQueryString } from "src/utils";
import usePromoCode from "src/utils/hoooks/usePromoCode";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const PromoCodeModal: FC<IProps> = ({ isOpen, handleClose }) => {
  const query = parseQueryString();
  const [value, setValue] = useState<string>(query.get("code") || "");
  const { pending, success, send } = usePromoCode();

  const handleChangeValue = useCallback((e: ChangeEvent<{ value: string }>) => {
    setValue(e.target.value);
  }, []);

  const handleSendCode = useCallback(() => {
    send(value);
  }, [send, value]);

  useEffect(() => {
    if (success) {
      setValue("");
      handleClose();
    }
  }, [handleClose, success]);

  return useMemo(
    () => (
      <UiDialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs" title={<Trans>Enter promocode</Trans>}>
        <DialogContent>
          <TextField autoFocus margin="dense" id="name" type="text" fullWidth value={value} onChange={handleChangeValue} />
        </DialogContent>

        <DialogActions>
          <Button disabled={pending} onClick={handleClose} color="primary">
            <Trans>Close</Trans>
          </Button>
          <Button disabled={!value || pending} color="primary" onClick={handleSendCode}>
            <Trans>Activate</Trans>
          </Button>
        </DialogActions>
      </UiDialog>
    ),
    [handleChangeValue, handleClose, handleSendCode, isOpen, pending, value],
  );
};

export default PromoCodeModal;
