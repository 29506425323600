import { useQueryClient } from "react-query";
import { InvalidateQueryFilters } from "react-query/types/core/types";
import { IPipelineType } from "src/types/pipeline";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

type IOutput = {
  [key in IPipelineType["id"]]: IPipelineType;
};

const QUERY_KEY = "dataset-pipeline-types";

export default function useQueryPipelineTypes(queryOptions?: IUseQueryBaseOptions<IOutput>) {
  return useQueryBase(
    QUERY_KEY,
    {
      refetchOnMount: false,
      ...queryOptions,
    },
    {
      method: "GET",
      url: `types/pipelines/`,
    },
  );
}

export function useQueryPipelineTypesInvalidate() {
  const queryClient = useQueryClient();

  return (filters?: InvalidateQueryFilters) => queryClient.invalidateQueries(QUERY_KEY, filters);
}
