import createSentryMiddleware from "@hh.ru/redux-sentry-middleware";
import * as Sentry from "@sentry/browser";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { RouterState } from "react-router-redux";
import { applyMiddleware, combineReducers, compose, createStore, Store } from "redux";

import { sagaMiddleware } from "./middleware";
import { IModulesState, modulesReducer, modulesSaga, modulesState } from "./modules";

const RAVEN_ENABLE = process.env.REACT_APP_RAVEN_ENABLE;
const APP_RAVEN_DSN = process.env.REACT_APP_RAVEN_DSN;
export interface IRootState {
  modules: IModulesState;
  router: RouterState;
}

RAVEN_ENABLE === "true" &&
  Sentry.init({
    blacklistUrls: ["https://cdn.carrotquest.io/api.min.js"],
    dsn: APP_RAVEN_DSN,
  });

const history2 = createBrowserHistory({ basename: "/app" });

const store: Store<{ readonly "[unknown]"?: undefined } & { modules: unknown; router: any }> = createStore(
  combineReducers({
    modules: modulesReducer as any,
    router: connectRouter(history2),
  }),
  { modules: modulesState },
  ((global as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
    applyMiddleware(
      ...[routerMiddleware(history2), sagaMiddleware],
      ...(RAVEN_ENABLE === "true" ? [createSentryMiddleware(Sentry)] : []),
    ),
  ),
);

export default store;
export { history2 };
export * from "./modules";

sagaMiddleware.run(modulesSaga);
