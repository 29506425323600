import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const icon = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
  padding: ${theme.spacing(4, 2, 2)};

  svg {
    font-size: 6rem;
  }
`;

export const paper = (theme: Theme) => css`
  padding: ${theme.spacing(4)};
`;

export const title = (theme: Theme) => css`
  margin-bottom: ${theme.spacing(2)};
`;
