import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: "auto",
  },

  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    padding: theme.spacing(2),
  },

  image: {
    "& img": {
      maxWidth: "100%",
    },

    display: "flex",
    justifyContent: "center",
  },

  link: {
    cursor: "pointer",
    display: "inline",
  },

  list: {
    "& li": {
      margin: theme.spacing(1, 0),
    },

    paddingLeft: 16,
  },

  paper: {
    padding: theme.spacing(2),
  },

  stepper: {
    background: "transparent",
  },

  tutorial: {
    marginLeft: -16,
  },
}));
