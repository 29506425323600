export const serializeFilterParameters = (
  obj?: Partial<{
    [key: string]: string | number | boolean | null | Array<string | number>;
  }>,
) =>
  obj &&
  JSON.stringify(
    Object.keys(obj)
      .map((key) =>
        obj[key] !== null
          ? {
              columnName: key,
              value: obj[key],
            }
          : undefined,
      )
      .filter((v) => typeof v !== "undefined"),
  );
