import { useAppContext } from "src/context/app/appContext";
import { IResponseTariffOption } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "billing-tariff-options";

export default function useQueryBillingTariffOptionsGet(queryOptions?: IUseQueryBaseOptions<IResponseTariffOption[]>) {
  const {
    state: { language },
  } = useAppContext();

  return useQueryBase<IResponseTariffOption[]>(QUERY_KEY, queryOptions, {
    method: "GET",
    params: {
      lang: language,
    },
    url: "billing/options/",
  });
}
