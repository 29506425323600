import { useAppContext } from "src/context/app/appContext";
import { ISocialSmpAccountData } from "src/types/socialAuth";
import getAnalytic from "src/utils/getAnalytic";
import getGaCid from "src/utils/getGaCid";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IOutput {
  token: string;
  refresh_token: string;
}

export default function useQuerySocialSignUp(queryOptions?: IUseQueryMutationOptions<IOutput, ISocialSmpAccountData>) {
  const { state } = useAppContext();

  return useMutationBase<IOutput, ISocialSmpAccountData>(
    {
      ...queryOptions,
      withoutToken: true,
    },
    {
      method: "POST",
      params: (params) => ({
        ...params,
        _adb: Boolean(document?.getElementById("adBanner")),
        analytic: getAnalytic(),
        cid: getGaCid(),
      }),
      url: `sauth/signup/${state.language ? "?lang=" + state.language : ""}`,
    },
  );
}
