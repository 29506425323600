import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BillingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M14,22a1,1,0,0,1-.71-.29L12,20.41l-1.29,1.3a1,1,0,0,1-1.42,0L7.87,20.29,5.55,21.83A1,1,0,0,1,4,21V5A3,3,0,0,1,7,2H17a3,3,0,0,1,3,3V21a1,1,0,0,1-.53.88,1,1,0,0,1-1-.05l-2.32-1.54-1.42,1.42A1,1,0,0,1,14,22Zm-2-4a1,1,0,0,1,.71.29L14,19.59l1.29-1.3a1,1,0,0,1,1.26-.12l1.45,1V5a1,1,0,0,0-1-1H7A1,1,0,0,0,6,5V19.13l1.45-1a1,1,0,0,1,1.26.12L10,19.59l1.29-1.3A1,1,0,0,1,12,18Zm3-2H13a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Zm0-4H9a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Zm0-4H9A1,1,0,0,1,9,6h6a1,1,0,0,1,0,2Z" />
    </SvgIcon>
  );
}
