import { IInvoice } from "src/types/billing";
import { IPaymentMethod } from "src/types/paymentMethod";
import { ITariff } from "src/types/tariff";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IParams {
  id?: ITariff["id"];
  system: "yandex" | "cloudpayments" | "maib";
  options?: object[];
  promocode?: string;
  renewalWithFreeDays: boolean;
  autoRenewalId?: IPaymentMethod["id"];
  savePaymentMethod?: boolean;
  isRedirect?: boolean;
}

export default function useQueryBillingInvoiceCreate(queryOptions?: IUseQueryMutationOptions<IInvoice, IParams>) {
  return useMutationBase<IInvoice, IParams>(queryOptions, {
    method: "POST",
    params: ({ autoRenewalId, options, promocode, renewalWithFreeDays, savePaymentMethod, id, isRedirect }) => ({
      auto_renewal_id: autoRenewalId,
      is_redirect: isRedirect,
      options,
      promo_code: promocode,
      renewal_with_free_days: renewalWithFreeDays,
      save_payment_method: savePaymentMethod,
      tariff_on_period_id: id,
    }),
    url: ({ system }) => `billing/create-invoice/${system}/`,
  });
}
