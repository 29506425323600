import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
  container: {
    justifyContent: "center",
    marginTop: "10px !important",
    textAlign: "center",
    width: "100%",
  },
}));
