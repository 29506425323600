import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ConnectorGdsIcon({
  disabled,
  ...props
}: SvgIconProps & {
  disabled?: boolean;
}) {
  const color1 = disabled ? "#c4c4c4" : "#669DF6";
  const color2 = disabled ? "#919191" : "#1A73E8";

  return (
    <SvgIcon {...props}>
      <path
        d="M20.3459 16.6672H12.3341C10.3929 16.6672 9.29883 18.0084 9.29883 19.4202C9.29883 20.7261 10.2165 22.1731 12.3341 22.1731H20.3459V16.6672Z"
        fill={color1}
      />
      <path
        d="M20.3459 22.1731C21.8635 22.1731 23.0988 20.9378 23.0988 19.4202C23.0988 17.9025 21.8635 16.6672 20.3459 16.6672C18.8282 16.6672 17.5929 17.9025 17.5929 19.4202C17.5929 20.9378 18.8282 22.1731 20.3459 22.1731Z"
        fill={color2}
      />
      <path
        d="M12.0518 9.29076H4.04C2.09883 9.29076 1.00471 10.6319 1.00471 12.0437C1.00471 13.3496 1.92235 14.7966 4.04 14.7966H12.0518V9.29076V9.29076Z"
        fill={color1}
      />
      <path
        d="M12.0518 14.7966C13.5694 14.7966 14.8047 13.5614 14.8047 12.0437C14.8047 10.5261 13.5694 9.29076 12.0518 9.29076C10.5341 9.29076 9.29883 10.5261 9.29883 12.0437C9.29883 13.5614 10.5341 14.7966 12.0518 14.7966Z"
        fill={color2}
      />
      <path
        d="M20.3459 1.91429H12.3341C10.3929 1.91429 9.29883 3.25547 9.29883 4.66723C9.29883 5.97311 10.2165 7.42017 12.3341 7.42017H20.3459V1.91429Z"
        fill={color1}
      />
      <path
        d="M20.3459 7.42017C21.8635 7.42017 23.0988 6.18488 23.0988 4.66723C23.0988 3.14959 21.8635 1.91429 20.3459 1.91429C18.8282 1.91429 17.5929 3.14959 17.5929 4.66723C17.5929 6.18488 18.8282 7.42017 20.3459 7.42017Z"
        fill={color2}
      />
    </SvgIcon>
  );
}
