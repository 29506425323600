import { IconLayoutGrid, IconLayoutList } from "@tabler/icons";
import { FC, useCallback } from "react";
import { IAppState } from "src/context/app/appContextProvider";

import { classes } from "./index.css";

interface IProps {
  value: IAppState["dashboard"]["view"];
  onChange: (view: IAppState["dashboard"]["view"]) => void;
}

const ViewControls: FC<IProps> = ({ value, onChange }) => {
  const handleChange = useCallback(
    (view: IAppState["dashboard"]["view"]) => () => {
      onChange(view);
    },
    [onChange],
  );

  return (
    <>
      <div css={[classes.button, value === "list" && classes.button_active]} onClick={handleChange("list")}>
        <IconLayoutList />
      </div>

      <div css={[classes.button, value === "grid" && classes.button_active]} onClick={handleChange("grid")}>
        <IconLayoutGrid />
      </div>
    </>
  );
};

export default ViewControls;
