import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const appBar = (theme: Theme) => css`
  transition: width 300ms;
  z-index: ${theme.zIndex.drawer + 1};
  box-shadow: 0 4px 20px #0000001a;
  background: #ffffff;
  border-radius: 0;
`;

export const dynamicSection = (theme: Theme) => css`
  padding: 0 50px 0 60px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-shrink: 1;
  width: 0;

  ${theme.breakpoints.down("lg")} {
    padding: 0 40px 0 29px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 22px 0 11px;
  }
`;

export const headerLeft = css`
  align-items: center;
  display: flex;
  margin-right: auto;
`;

export const toolbar = (theme: Theme) => css`
  ${theme.breakpoints.down("lg")} {
    padding-right: 40px;
  }

  ${theme.breakpoints.down("sm")} {
    padding-right: 32px;
  }

  height: 82px;
  padding-right: 60px;
  align-items: center;
  color: #000000;
  flex-wrap: nowrap;
`;

export const stack = (theme: Theme) => css`
  > :not(style) + :not(style) {
    margin-left: 50px;

    ${theme.breakpoints.down("md")} {
      margin-left: 40px;
    }
  }
`;
