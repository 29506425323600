import { EPipelineType } from "src/enums/pipeline";
import { IStreamConfig } from "src/types/stream";

interface ITarget {
  type: "client" | "community" | "page" | "instagram_account" | "ads";
  name: string | undefined;
  ids: Array<string | number>;
}

type IRequiredConfigProps =
  | "account"
  | "client"
  | "walls"
  | "entity_target_name"
  | "group_ids"
  | "page_id"
  | "facebook_page"
  | "instagram_account"
  | "facebook_ad_account_id"
  | "extra_settings"
  | "group_id"
  | "groups"
  | "counters";

export default function deserializePipelineConfig(
  sourceId: EPipelineType,
  config: Pick<IStreamConfig, IRequiredConfigProps>,
): ITarget[] {
  const targets: ITarget[] = [];

  if (config.account && [EPipelineType.VK_ADS, EPipelineType.VK_ADS_SMALL].includes(sourceId)) {
    targets.push({
      ids: [config.account.account_id],
      name: config.account.account_name,
      type: "ads",
    });
  }

  if (config.client && [EPipelineType.VK_ADS, EPipelineType.VK_ADS_SMALL].includes(sourceId)) {
    targets.push({
      ids: [config.client.id],
      name: config.client.name,
      type: "client",
    });
  }

  if ((config.walls || config.group_id) && sourceId === EPipelineType.VK_POST) {
    targets.push({
      ids: [...(config.walls || []).map(({ id }) => id), ...(config.group_id ? [config.group_id] : [])],
      name: config.entity_target_name,
      type: "community",
    });
  }

  if ((config.group_ids || config.group_id) && sourceId === EPipelineType.VK_COMMUNITY) {
    targets.push({
      ids: [...(config.group_ids || []).map(({ id }) => id), ...(config.group_id ? [config.group_id] : [])],
      name: config.entity_target_name,
      type: "community",
    });
  }

  if (config.page_id && sourceId === EPipelineType.FB_PAGE) {
    targets.push({
      ids: [config.page_id],
      name: config.entity_target_name,
      type: "community",
    });
  }

  if (config.facebook_page && [EPipelineType.INST_BA, EPipelineType.FB_ADS].includes(sourceId)) {
    targets.push({
      ids: [config.facebook_page.id],
      name: config.facebook_page.name,
      type: "page",
    });
  }

  if (config.instagram_account && [EPipelineType.INST_BA, EPipelineType.FB_ADS].includes(sourceId)) {
    targets.push({
      ids: [config.instagram_account.instagram_business_account_id],
      name: config.instagram_account.instagram_business_account_name,
      type: "instagram_account",
    });
  }

  if (config.facebook_ad_account_id && [EPipelineType.FB_ADS, EPipelineType.FB_ADS_SMALL].includes(sourceId)) {
    targets.push({
      ids: [config.facebook_ad_account_id],
      name: config.entity_target_name,
      type: "ads",
    });
  }

  if (config.page_id && [EPipelineType.GGL, EPipelineType.FB_POST].includes(sourceId)) {
    targets.push({
      ids: [config.page_id],
      name: config.entity_target_name,
      type: "page",
    });
  }

  if (config.group_id && sourceId === EPipelineType.OK_COMMUNITY) {
    targets.push({
      ids: [config.group_id],
      name: config.entity_target_name,
      type: "community",
    });
  }

  if ((config.groups || config.group_id) && sourceId === EPipelineType.OK_POST) {
    targets.push({
      ids: [...(config.groups || []).map(({ id }) => id), ...(config.group_id ? [config.group_id] : [])],
      name: config.entity_target_name,
      type: "community",
    });
  }

  if (config.counters && sourceId === EPipelineType.YA_METRIKA) {
    targets.push({
      ids: config.counters.map(({ id }) => id),
      name: config.entity_target_name,
      type: "page",
    });
  }

  return targets;
}
