import { t } from "@lingui/macro";
import { Tooltip } from "@mui/material";
import { IconCopy } from "@tabler/icons";
import copy from "copy-to-clipboard";
import { FC, useCallback, useState } from "react";

import * as classes from "./index.css";

interface IProps {
  code: string;
}

const NotificationsTelegramCode: FC<IProps> = ({ code }) => {
  const [tooltipIsOpen, setTooltipOpen] = useState(false);

  const handleCopy = useCallback(
    (text: string) => () => {
      if (tooltipIsOpen) {
        return false;
      }

      copy(text);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    },
    [tooltipIsOpen],
  );

  return (
    <Tooltip followCursor={false} open={tooltipIsOpen} title={t`Code copied to clipboard`} placement="bottom">
      <div css={classes.code} onClick={handleCopy(code)}>
        <div css={classes.code__value}>{code}</div>

        <div css={classes.code__icn}>
          <IconCopy size={18} />
        </div>
      </div>
    </Tooltip>
  );
};

export default NotificationsTelegramCode;
