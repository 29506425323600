import { t, Trans } from "@lingui/macro";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { useMount } from "react-use";
import Loader from "src/components/Loader";
import Message from "src/components/Message";
import UiButton from "src/components/UiButton";
import { popupAdd } from "src/redux";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import useQueryMeEmailConfirmSend from "src/utils/queries/user/useQueryMeEmailConfirmSend";

const ConfirmEmail: FC = () => {
  const dispatch = useDispatch();
  const { data: user, isLoading: userIsLoading } = useQueryMe();
  const { mutate: sendConfirm, isLoading: sendConfirmIsLoading } = useQueryMeEmailConfirmSend({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Email sent, please follow the instructions in the email.`,
        }),
      );
    },
  });
  const { code } = useParams<{ code: "success" | "fail" }>();

  const handleSendConfirm = useCallback(() => {
    sendConfirm(undefined);
  }, [sendConfirm]);

  useMount(() => {
    if (code === "success" || user?.email_confirmed) {
      dispatch(
        popupAdd({
          text: t`Mail confirmed. Welcome to the future.`,
        }),
      );
    }
  });

  if (userIsLoading) {
    return <Loader />;
  }

  if (!user || code === "success" || user?.email_confirmed) {
    return <Redirect to={"/"} />;
  }

  return (
    <Message
      icon={<MailOutlineIcon />}
      title={<Trans>Email verification error</Trans>}
      message={
        <>
          <Typography mb={2}>
            <Trans>
              You can edit email address on your <Link href="/profile/">profile page</Link>.
            </Trans>
          </Typography>

          <UiButton onClick={handleSendConfirm} loading={sendConfirmIsLoading}>
            <Trans>Send confirmation email to {user.email}</Trans>
          </UiButton>
        </>
      }
    />
  );
};

export default ConfirmEmail;
