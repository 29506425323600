import { t, Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProviderWithIcon from "src/components/ProviderWithIcon";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import UiInfinityTable from "src/components/UiInfinityTable";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";
import useQueryProjectCredentialsPost from "src/utils/queries/project/useQueryProjectCredentialsPost";

import * as classes from "./index.css";

interface IProps {
  onClose: () => void;
  projectId: number;
  open: boolean;
  sharedCredentialIds?: number[];
}

const ModalAccountCredentialsShare: FC<IProps> = ({ onClose, open, projectId, sharedCredentialIds }) => {
  const [selection, setSelection] = useState<Array<number | string>>([]);
  const { data: credentials, isLoading: credentialsIsLoading } = useQueryCredentialsGet({ enabled: open }, { own: true });
  const { mutate: credentialsPost, isLoading: credentialsPostIsLoading } = useQueryProjectCredentialsPost({
    onSuccess: onClose,
  });

  const handleSubmit = useCallback(() => {
    credentialsPost({
      account_credentials_ids: credentials?.map(({ id }) => id).filter((id, idx) => selection.includes(idx)) || [],
      projectId,
    });
  }, [credentials, credentialsPost, projectId, selection]);

  useEffect(() => {
    if (credentials?.length) {
      const updatedSelection = credentials?.reduce((acc: number[], cred, index) => {
        if (sharedCredentialIds?.includes(cred.id)) {
          acc.push(index);
        }

        return acc;
      }, []);

      setSelection(updatedSelection || []);
    }
  }, [credentials, sharedCredentialIds]);

  return useMemo(
    () => (
      <UiDialog open={open} fullWidth maxWidth="sm" onClose={onClose} title={<Trans>Share data sources</Trans>}>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 1 }}>
            <Trans>
              All project administrators will be able to use selected data sources to create publics / ad accounts in this
              project
            </Trans>
          </Alert>

          <div css={classes.table}>
            <UiInfinityTable
              paperElevation={0}
              rows={credentials || []}
              columns={[
                { name: "external_id", title: "ID" },
                {
                  name: "name",
                  title: t`Page title`,
                },
                {
                  getCellValue: (row) => <ProviderWithIcon provider={row.medium} />,
                  name: "medium",
                  title: t`Source`,
                },
                {
                  name: "used_by_streams",
                  title: t`Publics / Ads`,
                },
              ]}
              loading={credentialsIsLoading}
              enableSorting={false}
              totalRows={credentials?.length}
              perPage={1}
              masonryBreakPoints={{
                xs: 12,
              }}
              selectionStateProps={{
                onSelectionChange: setSelection,
                selection,
              }}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <UiButton onClick={onClose} variant="text" disabled={credentialsPostIsLoading}>
            <Trans>Cancel</Trans>
          </UiButton>
          <UiButton onClick={handleSubmit} variant="text" loading={credentialsPostIsLoading}>
            <Trans>Save</Trans>
          </UiButton>
        </DialogActions>
      </UiDialog>
    ),
    [open, onClose, credentials, credentialsIsLoading, selection, credentialsPostIsLoading, handleSubmit],
  );
};

export default ModalAccountCredentialsShare;
