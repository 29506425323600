import { IUseQueryBaseOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IResponse {
  auth_code: string;
  deeplink?: string;
}

export default function useQueryTelegramNotificationGetCode(queryOptions?: IUseQueryBaseOptions<IResponse>) {
  return useMutationBase<IResponse>(
    {
      ...queryOptions,
    },
    {
      method: "GET",
      url: "telegram_notifications/auth_code/",
    },
  );
}
