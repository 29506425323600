import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { EProgressType } from "src/enums/progress";
import { EStreamReportStatus } from "src/enums/stream";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import aggregateReportStatusProgress from "src/utils/aggregateReportStatusProgress";
import usePipelineAggregation from "src/utils/hoooks/usePipelineAggregation";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  results: IPipeline[];
}

const QUERY_KEY = "pipeline-get";

export default function useQueryPipelineGet(
  pipelineId: IPipeline["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IResponse, IPipelineExtended>,
) {
  const [forceDisableRefetch, setForceDisableRefetch] = useState(false);
  const queryClient = useQueryClient();
  const countdownId = useRef<number | undefined>();
  const aggregatePipeline = usePipelineAggregation();

  return useQueryBase<IResponse, IPipelineExtended>(
    [QUERY_KEY, pipelineId],
    {
      enabled: typeof pipelineId !== "undefined",
      select: (data) => aggregatePipeline(data.results[0]),
      ...queryOptions,
      onSuccess: async (...args) => {
        const data = args[0];

        if (queryOptions?.refetchInterval) {
          const progress = aggregateReportStatusProgress(data.report_status);

          if (progress?.type === EProgressType.TIMER && progress.value >= 5) {
            if (!countdownId.current) {
              setForceDisableRefetch(true);

              countdownId.current = window.setInterval(() => {
                queryClient.setQueryData<IResponse | undefined>([QUERY_KEY, pipelineId], (resp) => {
                  const pipeline = resp?.results[0];

                  if (!pipeline) {
                    return resp;
                  }

                  return {
                    ...resp,
                    results: resp.results.map((item) =>
                      item.id === pipeline.id
                        ? {
                            ...pipeline,
                            report_status: pipeline.report_status.map((rs) =>
                              rs.status !== EStreamReportStatus.LOCK
                                ? rs
                                : {
                                    ...rs,
                                    progress: rs.progress
                                      ? {
                                          ...rs.progress,
                                          items: {
                                            ...rs.progress.items,
                                            [rs.progress.current_item_sign]: {
                                              ...rs.progress.items[rs.progress.current_item_sign],
                                              current: rs.progress.items[rs.progress.current_item_sign].current + 1,
                                            },
                                          },
                                        }
                                      : undefined,
                                  },
                            ),
                          }
                        : item,
                    ),
                  };
                });
              }, 1000);
            }
          } else {
            if (countdownId.current) {
              clearInterval(countdownId.current);
              setForceDisableRefetch(false);
            }
          }
        }

        queryOptions?.onSuccess?.apply(queryOptions, args);
      },
      refetchInterval: forceDisableRefetch ? false : queryOptions?.refetchInterval,
    },
    {
      method: "GET",
      url: `pipeline/${pipelineId}/`,
    },
  );
}

export function useQueryPipelineUpdateData() {
  const queryClient = useQueryClient();

  return (pipeline: IPipelineExtended) => {
    const data = queryClient.getQueryData([QUERY_KEY, pipeline.id]);

    if (data) {
      queryClient.setQueryData<IResponse | undefined>([QUERY_KEY, pipeline.id], (data) => {
        if (!data) {
          return data;
        }

        return {
          ...data,
          results: data.results.map((item) => (item.id === pipeline.id ? pipeline : item)),
        };
      });
    }
  };
}
