import { useQueryClient } from "react-query";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IProject } from "src/types/project";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  count: number;
  results: ICredential[] | undefined;
}

interface IFilter {
  projects?: IProject["id"][];
  stream?: IPipelineType["streams"][number]["id"];
  own?: boolean;
  id__in?: ICredential["id"][];
}

const QUERY_KEY = "credentials-get";

export default function useQueryCredentialsGet(
  queryOptions?: IUseQueryBaseOptions<IResponse, IResponse["results"]>,
  filter?: IFilter,
) {
  return useQueryBase<IResponse, IResponse["results"]>(
    [QUERY_KEY, filter],
    {
      refetchOnMount: false,
      select: (data) => data?.results?.map((credential) => credential),
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        filtered: JSON.stringify(filter),
      },
      url: `account-credentials/`,
    },
  );
}

export function useQueryCredentialsInvalidate() {
  const clientQuery = useQueryClient();

  return (filter?: IFilter) =>
    clientQuery.invalidateQueries(filter ? [QUERY_KEY, filter] : QUERY_KEY, { refetchInactive: true });
}
