import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const table = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    table {
      width: 100%;
      min-width: 100% !important;
    }

    colgroup {
      display: none;
    }

    margin-top: 60px;
  }

  margin-bottom: -35px;
  margin-top: 30px;
`;

export const table__row = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    display: block !important;
    width: 100% !important;
    border-bottom: 1px solid #e2e2e2;
    padding: 1px 0;

    &:first-of-type {
      border-top: 1px solid #e2e2e2;
    }
  }
`;

export const table__cell = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    th& {
      display: none !important;
    }

    width: 100% !important;
    display: block !important;
    height: auto !important;
    padding: 0 !important;
    border: none !important;
    margin: 20px 0;
  }
`;

export const table__cell_role = (theme: Theme) => css`
  overflow: visible !important;

  ${theme.breakpoints.down("lg")} {
    width: 180px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const avatar = css`
  width: 46px;
  height: 46px;
`;

export const emailStatus = css`
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
  padding: 12px 0;
`;

export const email = css`
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const status = css`
  font-size: 10px;
  height: 10px;
  line-height: 8px;
  margin-bottom: -8px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const select = (theme: Theme) => css`
  max-width: 260px;

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
  }
`;

export const status_me = css`
  color: #cccccc !important;
`;

export const status_invite = css`
  color: #55ade0 !important;
`;

export const actionsStack = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    flex-direction: row-reverse;
    justify-content: space-between;

    & > :not(style) + :not(style) {
      margin-left: 0;
    }
  }
`;

export const btnDelete = css`
  color: #757575;

  :hover {
    color: #000000;
  }
`;
