import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SupportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22ZM12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4Z" />
      <path d="M12,18a1,1,0,1,1,1-1h0A1,1,0,0,1,12,18Z" />
      <path d="M12,14.5a1,1,0,0,1-1-.94,2.49,2.49,0,0,1,1.67-2.5,1.6,1.6,0,0,0,.86-.84,1.57,1.57,0,0,0,0-1.22,1.65,1.65,0,0,0-.85-.89,1.61,1.61,0,0,0-1.92.49A1,1,0,0,1,9.2,7.4a3.6,3.6,0,1,1,4.16,5.53.5.5,0,0,0-.36.51,1,1,0,0,1-.94,1.06Z" />
    </SvgIcon>
  );
}
