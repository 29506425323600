import { t, Trans } from "@lingui/macro";
import { Alert, Box, Paper, Stack, Typography } from "@mui/material";
import { IconLanguage, IconLogin } from "@tabler/icons";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useUpdateEffect } from "react-use";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import { ELanguage } from "src/enums/language";
import { popupAdd } from "src/redux";
import { PrivateFC } from "src/types/helpers";
import { useQueryPipelineTypesInvalidate } from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMeEmailConfirmSend from "src/utils/queries/user/useQueryMeEmailConfirmSend";
import useQueryMePatch from "src/utils/queries/user/useQueryMePatch";

import NotificationsTelegram from "./components/NotificationsTelegram";
import * as classes from "./index.css";

const Profile: PrivateFC = ({ user }) => {
  const dispatch = useDispatch();
  const invalidatePipelineTypes = useQueryPipelineTypesInvalidate();
  const { mutate: mePatch, isLoading: mePatchIsLoading } = useQueryMePatch({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Profile successfully update`,
        }),
      );
    },
  });
  const { mutate: sendConfirm, isLoading: sendConfirmIsLoading } = useQueryMeEmailConfirmSend({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Email sent, please follow the instructions in the email.`,
        }),
      );
    },
  });
  const [language, setLanguage] = useState(user?.language);
  const [mainForm, setMainForm] = useState({
    confirm_password: "",
    email: user.email,
    password: "",
    password2: "",
  });

  const languageOptions = useMemo(
    () => [
      { label: "Русский", value: ELanguage.RU },
      { label: "English", value: ELanguage.EN },
    ],
    [],
  );

  const handleChangeLanguage = useCallback(
    async (newLanguage: ELanguage | null) => {
      if (newLanguage) {
        setLanguage(newLanguage);
        await mePatch({ language: newLanguage });
        await invalidatePipelineTypes();
      }
    },
    [invalidatePipelineTypes, mePatch],
  );

  const isChanged = useMemo(
    () => (mainForm.email && mainForm.email !== user.email) || (mainForm.password !== "" && mainForm.password2 !== ""),
    [mainForm.email, mainForm.password, mainForm.password2, user.email],
  );

  const isValid = useMemo(
    () => mainForm.email !== "" && mainForm.password === mainForm.password2 && mainForm.confirm_password !== "",
    [mainForm.confirm_password, mainForm.email, mainForm.password, mainForm.password2],
  );

  const handleChangeMainForm = useCallback(
    (key: keyof typeof mainForm) => (value: string) => {
      setMainForm((state) => ({
        ...state,
        [key]: value,
      }));
    },
    [],
  );

  const handleSendConfirm = useCallback(() => {
    sendConfirm(undefined);
  }, [sendConfirm]);

  const handleSubmitForm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      mePatch({
        confirm_password: mainForm.confirm_password,
        email: mainForm.email,
        new_password: mainForm.password || undefined,
      });
    },
    [mainForm.confirm_password, mainForm.email, mainForm.password, mePatch],
  );

  useUpdateEffect(() => {
    if (user) {
      setLanguage(user.language);
    }
  }, [user]);

  return (
    <Section>
      <SectionHead title={<Trans>User settings</Trans>}>
        <div css={classes.container}>
          {!user.email_confirmed && (
            <Paper variant="filled" sx={{ p: 3 }}>
              <Typography>
                <Trans>
                  You have not confirmed your registration with DataFan. If you haven't received an email, you can try to send
                  it again or contact support.
                </Trans>
              </Typography>

              <Box mt={1.6}>
                <UiButton loading={sendConfirmIsLoading} onClick={handleSendConfirm}>
                  <Trans>Send confirmation by email</Trans>
                </UiButton>
              </Box>
            </Paper>
          )}

          <Typography variant="h3" component="div" mt={5}>
            <Stack direction="row" spacing={0.8} alignItems="center">
              <IconLanguage />
              <div>
                <Trans>Interface language</Trans>
              </div>
            </Stack>
          </Typography>

          <Box mt={3}>
            <UiSelect
              fullWidth
              value={language}
              onChange={handleChangeLanguage}
              options={languageOptions}
              isLoading={mePatchIsLoading}
            />
          </Box>

          <Typography variant="h3" component="div" mt={5}>
            <Stack direction="row" spacing={0.8} alignItems="center">
              <IconLogin />
              <div>
                <Trans>User data</Trans>
              </div>
            </Stack>
          </Typography>

          <form onSubmit={handleSubmitForm}>
            <Box mt={3}>
              <UiTextField
                fullWidth
                label={<Trans>Email</Trans>}
                value={mainForm.email}
                onChange={handleChangeMainForm("email")}
                disabled={!user.has_password}
              />
            </Box>

            <Box mt={3}>
              <UiTextField
                fullWidth
                label={<Trans>New password</Trans>}
                value={mainForm.password}
                onChange={handleChangeMainForm("password")}
                type="password"
                autoComplete="new-password"
                error={mainForm.password !== mainForm.password2}
                disabled={!user.has_password}
              />
            </Box>

            <Box mt={3}>
              <UiTextField
                fullWidth
                label={<Trans>Repeat new password</Trans>}
                value={mainForm.password2}
                onChange={handleChangeMainForm("password2")}
                type="password"
                autoComplete="new-password"
                error={mainForm.password !== mainForm.password2}
                disabled={!user.has_password}
              />
            </Box>

            {!user.has_password && (
              <Alert severity="info" sx={{ mt: 2 }}>
                <Typography>
                  <Trans>To change your email and/or password, contact support</Trans>
                </Typography>
              </Alert>
            )}

            {isChanged && (
              <Box mt={3}>
                <UiTextField
                  fullWidth
                  label={<Trans>Enter the current password to save the changes</Trans>}
                  value={mainForm.confirm_password}
                  onChange={handleChangeMainForm("confirm_password")}
                  type="password"
                  autoComplete="new-password"
                />
              </Box>
            )}

            <NotificationsTelegram />

            {mainForm.password !== mainForm.password2 && (
              <div css={classes.error}>
                <Trans>Passwords do not match</Trans>
              </div>
            )}

            <Box mt={6}>
              <UiButton
                disableElevation
                type="submit"
                variant="outlined"
                disabled={!isChanged || !isValid}
                loading={mePatchIsLoading}
              >
                <Trans>Save changes</Trans>
              </UiButton>
            </Box>
          </form>
        </div>
      </SectionHead>
    </Section>
  );
};

export default Profile;
