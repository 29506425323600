import { t, Trans } from "@lingui/macro";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { FC, useCallback, useState } from "react";
import useQueryAuthSignIn from "src/utils/queries/auth/useQueryAuthSignIn";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import AuthForm from "../../components/AuthForm";
import AuthTemplate from "../../components/AuthTemplate";
import SocialAuth from "../../components/SocialAuth";

interface IForm {
  email: string;
  password: string;
}

const SignIn: FC = () => {
  const [form, setForm] = useState<IForm>({
    email: "",
    password: "",
  });

  const { refetch: submitForm, isFetching: isFetchingSignIn } = useQueryAuthSignIn(form);
  const { isFetching: isFetchingMe } = useQueryMe({ enabled: false });

  const handleChangeForm = useCallback(
    (key: keyof IForm) => (value: string) => {
      setForm((state) => ({
        ...state,
        [key]: value,
      }));
    },
    [],
  );

  return (
    <AuthTemplate title={<Trans>Sign in</Trans>} icon={<LockOutlined />}>
      <AuthForm
        onSubmit={submitForm}
        fields={[
          {
            name: "email",
            onChange: handleChangeForm("email"),
            title: t`Email Address`,
            type: "text",
            value: form.email,
          },
          {
            name: "password",
            onChange: handleChangeForm("password"),
            title: t`Password`,
            type: "password",
            value: form.password,
          },
        ]}
        button={{ disabled: isFetchingSignIn || isFetchingMe, title: <Trans>Sign in</Trans> }}
        links={[
          {
            title: <Trans>SignUp</Trans>,
            to: "/auth/signup/",
          },
          {
            title: <Trans>Forget password</Trans>,
            to: "/auth/reset-password/",
          },
        ]}
      />

      <SocialAuth signIn signUp={false} />
    </AuthTemplate>
  );
};

export default SignIn;
