import { Button, ButtonProps, Hidden, HiddenProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ElementType } from "react";

import { classes } from "./index.css";

export type UiButtonProps<C extends ElementType = "button"> = Omit<
  ButtonProps<C, { component?: C }>,
  | "color"
  | "centerRipple"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "focusVisibleClassName"
  | "onFocusVisible"
  | "sx"
  | "TouchRippleProps"
  | "disableFocusRipple"
  | "variant"
> & {
  color?: "primary" | "secondary" | "neutral";
  variant?: "contained" | "outlined" | "text";
  loading?: boolean;
  textAlign?: "left" | "right" | "center";
  HiddenTextProps?: HiddenProps;
};

const UiButton = <C extends ElementType>({
  loading,
  children,
  disabled,
  size,
  color,
  variant = "contained",
  textAlign = "center",
  HiddenTextProps,
  ...props
}: UiButtonProps<C>) => (
  <Button
    variant={variant}
    color={color}
    size={size}
    disabled={loading || disabled}
    centerRipple
    css={classes.button(textAlign)}
    {...props}
  >
    {loading && (
      <CircularProgress
        css={classes.progress}
        size={(size === "small" && 20) || (size === "large" && 30) || 24}
        color={color === "neutral" ? "inherit" : color}
      />
    )}

    {children && (
      <Hidden implementation="css" {...HiddenTextProps}>
        <div>{children}</div>
      </Hidden>
    )}
  </Button>
);

export default UiButton;
