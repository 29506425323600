import { ISocialProvider } from "src/types/socialAuth";

import useQueryBaseSmp, { IUseQueryBaseSmpOptions } from "../useQueryBaseSmp";

export default function useQueryCredentialProviders(queryOptions?: IUseQueryBaseSmpOptions<ISocialProvider[]>) {
  return useQueryBaseSmp<ISocialProvider[]>(
    "credential-providers",
    {
      ...queryOptions,
      select: (data: ISocialProvider[]) => {
        const excludedProviders = process.env.REACT_APP__PROVIDER_EXCLUDE && process.env.REACT_APP__PROVIDER_EXCLUDE.split(",");
        const array = data.map((provider) =>
          provider.medium === "mytarget"
            ? {
                ...provider,
                auth_url: `${process.env.REACT_APP__API_BASE_URL}/${process.env.REACT_APP__API_VERSION}/auth-begin-proxy/${provider.id}/`,
              }
            : provider,
        );

        if (excludedProviders) {
          return array.filter(({ id }) => !excludedProviders.includes(String(id)));
        }

        return array;
      },
    },
    {
      method: "GET",
      url: `oauth-client/v1/providers`,
    },
  );
}
