import { useQueryClient } from "react-query";
import { IProject, IProjectInvite } from "src/types/project";
import { IResponseBasePagination } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "project-invite-get";

export default function useQueryProjectInvitesGet(
  projectId?: IProject["id"],
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<IProjectInvite>, IProjectInvite[]>,
) {
  return useQueryBase<IResponseBasePagination<IProjectInvite>, IProjectInvite[]>(
    [QUERY_KEY, projectId],
    {
      select: (data) => data.results,
      ...queryOptions,
    },
    {
      fetchAllPages: true,
      method: "GET",
      params: {
        limit: 1000,
        target_project: projectId,
      },
      url: `invitation/`,
    },
  );
}

export function useQueryProjectInvitesInvalidate() {
  const clientQuery = useQueryClient();

  return (projectId?: IProject["id"]) => clientQuery.invalidateQueries([QUERY_KEY, projectId]);
}
