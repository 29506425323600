import { plural, Trans } from "@lingui/macro";
import { Alert, Grid, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useMount } from "react-use";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface AdsPeriodProps {
  onChange: (period: number) => void;
}

const AdsPeriod: FC<AdsPeriodProps> = ({ onChange }) => {
  const { data: me } = useQueryMe();
  const tariffSign = useMemo(
    () => me?.active_subscription?.tariff_on_period.sign,
    [me?.active_subscription?.tariff_on_period.sign],
  );
  const [currentPeriod, setCurrentPeriod] = useState(3);
  const [showSelector, setShowSelector] = useState(false);

  const availablePeriodsInMonths = useMemo(() => {
    if (
      !tariffSign ||
      tariffSign === ETariffOnPeriodSign.TRIAL ||
      tariffSign === ETariffOnPeriodSign.TRIAL_OUTDATED ||
      tariffSign === ETariffOnPeriodSign.TRIAL_SINGLE
    ) {
      return [];
    }

    return [3, 6, 9, 12, 18].filter(
      (v) =>
        v <=
        {
          [ETariffOnPeriodSign.ENTERPRISE]: 18,
          [ETariffOnPeriodSign.AGENT]: 18,
          [ETariffOnPeriodSign.PROFESSIONAL]: 12,
          [ETariffOnPeriodSign.START]: 6,
          [ETariffOnPeriodSign.ELAMA]: 6,
        }[tariffSign],
    );
  }, [tariffSign]);

  const handleShowSelector = useCallback(() => {
    setShowSelector(true);
  }, []);

  const handleChangePeriod = useCallback(
    (newPeriod) => {
      setCurrentPeriod(newPeriod);
      onChange(newPeriod);
    },
    [onChange],
  );

  useMount(() => {
    onChange(currentPeriod);
  });

  return (
    <>
      {!showSelector ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            {availablePeriodsInMonths.length <= 1 ? (
              <Tooltip title={<Trans>If wider data period is required, contact support</Trans>}>
                <Alert severity="info">
                  <Trans>Data will be collected for 3 month period</Trans>
                </Alert>
              </Tooltip>
            ) : (
              <Alert severity="info">
                <Trans>Data will be collected for 3 month period</Trans>
              </Alert>
            )}
          </Grid>

          {availablePeriodsInMonths.length > 1 && (
            <Grid item>
              <UiButton onClick={handleShowSelector}>
                <Trans>Change data period</Trans>
              </UiButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <Stack spacing={2}>
          <UiSelect
            value={currentPeriod}
            options={availablePeriodsInMonths.map((value) => ({
              label: plural(value, {
                few: "# months",
                many: "# months",
                one: "# month",
                other: "# months",
              }),
              value,
            }))}
            onChange={handleChangePeriod}
          />

          <Alert severity="info">
            <Trans>The wider period is chosen the longer data collection is</Trans>
          </Alert>
        </Stack>
      )}
    </>
  );
};

export default AdsPeriod;
