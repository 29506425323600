import { t } from "@lingui/macro";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import UiVirtualTable from "src/components/UiVirtualTable";
import { billingInternalTransactionsGet, EInternalTransactionOperation, IInternalTransaction, IRootState } from "src/redux";
import { COUNT, LOADING, PAGE, PAGE_SIZE } from "src/utils";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";

const InternalTransactions: FC = () => {
  const dispatch = useDispatch();
  const { internalTransactions, skip } = useSelector((state: IRootState) => ({
    internalTransactions: state.modules.billing.internalTransactions,
    skip: state.modules.billing.internalTransactions[PAGE_SIZE] * state.modules.billing.internalTransactions[PAGE],
  }));

  const getRowId = useCallback((row: any) => row.id, []);

  const getRows = useCallback(
    (skip: number, take: number) => {
      if (!internalTransactions[LOADING]) {
        dispatch(
          billingInternalTransactionsGet({
            limit: take,
            offset: skip,
          }),
        );
      }
    },
    [dispatch, internalTransactions],
  );

  return useMemo(
    () => (
      <UiVirtualTable
        gridProps={{
          columns: [
            {
              getCellValue: ({ created_at }: IInternalTransaction) => moment(created_at).format("YYYY-MM-DD HH:mm"),
              name: "date",
              title: t`Date`,
            },
            {
              getCellValue: ({ operation, operation_display }: IInternalTransaction) => (
                <Typography
                  variant="body1"
                  color={
                    (operation === EInternalTransactionOperation.WITHDRAW && "secondary") ||
                    (operation === EInternalTransactionOperation.REFILL && "primary") ||
                    "inherit"
                  }
                >
                  {operation_display}
                </Typography>
              ),
              name: "operation",
              title: t`Operation`,
            },
            {
              getCellValue: ({ amount, currency }: IInternalTransaction) => `${amount} ${getCurrencyDisplayName(currency)}`,
              name: "amount",
              title: t`Amount`,
            },
          ],
          getRowId: getRowId,
          rows: internalTransactions.items || [],
        }}
        tableStateProps={{
          getRows: getRows,
          infiniteScrolling: true,
          loading: internalTransactions[LOADING],
          pageSize: internalTransactions[PAGE_SIZE],
          skip: skip,
          totalRowCount: internalTransactions[COUNT] || 0,
        }}
        tableProps={{
          columnExtensions: [
            { columnName: "date", width: 150 },
            { columnName: "amount", width: 150 },
          ],
          height: "inherit",
          minHeight: 300,
        }}
      />
    ),
    [getRowId, getRows, internalTransactions, skip],
  );
};

export default InternalTransactions;
