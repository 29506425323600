import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import App from "src/app/App";
import Auth from "src/app/Auth";
import ErrorBoundary from "src/app/ErrorBoundary";
import OAuthSmpComplete from "src/app/OAuthSmpComplete";

interface IState {
  hasError: boolean;
}

class MainRouter extends Component {
  public state: IState = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundary />;
    }

    return (
      <Switch>
        <Route path={"/auth"} component={Auth} />
        <Route path="/oauth-complete/" component={OAuthSmpComplete} />
        <Route path={"/"} component={App} />
      </Switch>
    );
  }
}

export default MainRouter;
