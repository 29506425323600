import { Trans } from "@lingui/macro";
import { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React, { FC, ReactElement } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";

interface IProps {
  isLoaded?: boolean;
  open: boolean;
  text: string | ReactElement;
  title?: string;
  handleAction: any;
  handleClose: any;
  maxWidth?: DialogProps["maxWidth"];
}

const UiDialogConfirm: FC<IProps> = ({ isLoaded, open, text, title, handleAction, handleClose, maxWidth }) => (
  <UiDialog
    open={open}
    keepMounted
    onClose={handleClose}
    fullWidth
    maxWidth={maxWidth || "sm"}
    title={title || <Trans>Confirm</Trans>}
  >
    <DialogContent>
      <DialogContentText>{!!text && text}</DialogContentText>
    </DialogContent>

    <DialogActions>
      <UiButton disabled={isLoaded} onClick={handleClose}>
        <Trans>Cancel</Trans>
      </UiButton>

      <UiButton loading={isLoaded} onClick={handleAction} variant="outlined">
        <Trans>Ok</Trans>
      </UiButton>
    </DialogActions>
  </UiDialog>
);

export default UiDialogConfirm;
