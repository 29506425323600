import { Trans } from "@lingui/macro";
import StopIcon from "@mui/icons-material/Stop";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { FC, useCallback, useMemo } from "react";
import Page500 from "src/app/Page500/Page500";
import Loader from "src/components/Loader";
import UiButton from "src/components/UiButton";
import { IOAuthDataRequestGet } from "src/types/oAuth";
import { parseQueryString } from "src/utils";
import useLogout from "src/utils/hoooks/useLogout";
import useQueryOAuthGet from "src/utils/queries/oAuth/useQueryOAuthGet";
import useQueryOAuthPost from "src/utils/queries/oAuth/useQueryOAuthPost";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paper: {
    margin: "60px auto 0",
    maxWidth: "600px",
    padding: spacing(4),
  },
}));

const OAuth2: FC = () => {
  const params = Object.fromEntries(parseQueryString()) as unknown as IOAuthDataRequestGet;
  const logout = useLogout();
  const classes = useStyles();
  const { data: oauth2Data, isLoading: oauth2DataIsLoading } = useQueryOAuthGet(params, {});

  const { mutate: oauth2Post, isLoading: oauth2PostIsLoading } = useQueryOAuthPost({
    onSuccess: (data) => {
      window.location.href = data.uri;
    },
  });

  const handleCancel = useCallback(() => {
    window.close();
  }, []);

  const handleAuthorize = useCallback(() => {
    oauth2Post({
      ...params,
      scopes: params.scope.split(","),
    });
  }, [oauth2Post, params]);

  return useMemo(() => {
    if (oauth2DataIsLoading) {
      return <Loader />;
    }

    if (!oauth2Data) {
      return <Page500 />;
    }

    return (
      <Paper elevation={3} className={classes.paper}>
        <Box mb={4}>
          <Typography variant="h2" gutterBottom>
            <Trans id="Authorize {app}" values={{ app: oauth2Data.application.name }} />
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          <Trans id="Application requires following permissions:" />
        </Typography>

        <Box mb={4}>
          <List dense disablePadding>
            {oauth2Data.scopes_descriptions?.map((scope) => (
              <ListItem key={scope}>
                <ListItemIcon>
                  <StopIcon />
                </ListItemIcon>
                <ListItemText primary={scope} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Grid justifyContent="center" container spacing={4}>
          <Grid item>
            <UiButton size="medium" variant="contained" onClick={handleCancel}>
              <Trans id="Cancel" />
            </UiButton>
          </Grid>

          <Grid item>
            <UiButton size="medium" variant="contained" onClick={logout}>
              <Trans id="Change user" />
            </UiButton>
          </Grid>

          <Grid item>
            <UiButton size="medium" variant="contained" color="primary" onClick={handleAuthorize} loading={oauth2PostIsLoading}>
              <Trans id="Authorize" />
            </UiButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }, [classes.paper, handleAuthorize, handleCancel, logout, oauth2Data, oauth2DataIsLoading, oauth2PostIsLoading]);
};

export default OAuth2;
