import { t } from "@lingui/macro";
import { AxiosResponse } from "axios";
import { all, call, put, select } from "redux-saga/effects";
import { pipelinesClear } from "src/redux/modules/pipelines/actions/items";
import { prepareErrorMessage, sagaCompile } from "src/utils";
import dataLayerPush from "src/utils/dataLayerPush";

import {
  IModulesActionMap,
  IRootState,
  IUserEmailPutR,
  IUserPatchSelectedProjectR,
  userEmailPatchApi,
  userMetaGetApi,
  userSendConfirmPostApi,
} from "../../";
import { popupAdd } from "../popups";
import { apiRequestAuthorized } from "../shared";
import { IUserGetS, IUserPutR, IUsersGetR } from "./action";
import { userGetApi, userPatchApi, usersGetApi } from "./api";

const { takeEveryRequest } = sagaCompile<IModulesActionMap>();

export interface IUsersGetSagaResponse {
  meta: { count: number };
  res: AxiosResponse;
  results: any;
}

export function* usersGetSaga() {
  yield takeEveryRequest(["USERS_GET__R", "USERS_GET__S", "USERS_GET__F"], {
    *onR(action: IUsersGetR) {
      const { filter, cb } = action.payload;
      const res: any = yield call(apiRequestAuthorized, usersGetApi, {
        cb,
        filter,
      });
      return {
        cb,
        results: res.data,
      };
    },
  });
}

export function* userGetSaga() {
  yield takeEveryRequest(["USER_GET__R", "USER_GET__S", "USER_GET__F"], {
    *onR() {
      const res: any = yield call(apiRequestAuthorized, userGetApi, {
        param: 1,
      });

      if (res.data.id) {
        try {
          const resMeta: any = yield call(apiRequestAuthorized, userMetaGetApi, {
            sign: "gds",
          });

          res.data.meta = resMeta.data?.user_meta;
        } catch (e) {}
      }

      return {
        user: res.data,
      };
    },
    *onS(action: IUserGetS) {
      window.localStorage.setItem("language", action.payload.user.language);

      yield dataLayerPush({ userId: action.payload.user.id });
    },
  });
}

export function* userEmailPatchSaga() {
  yield takeEveryRequest(["USER_PUT_EMAIL__R", "USER_PUT_EMAIL__S", "USER_PUT_EMAIL__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IUserEmailPutR) {
      const { email, password } = action.payload;

      const res: any = yield call(apiRequestAuthorized, userEmailPatchApi, {
        email,
        password,
      });

      return res.data;
    },
    *onS() {
      yield put(
        popupAdd({
          text: t`Email successfully update`,
        }),
      );
    },
  });
}

export function* userPatchSaga() {
  yield takeEveryRequest(["USER_PUT__R", "USER_PUT__S", "USER_PUT__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IUserPutR) {
      const { language, currentPassword, newPassword } = action.payload;

      if (language) {
        window.localStorage.setItem("language", language);
      }

      const res: any = yield call(apiRequestAuthorized, userPatchApi, {
        currentPassword,
        language,
        newPassword,
      });
      return {
        user: res.data,
      };
    },
  });
}

export function* userPatchSelectedProjectSaga() {
  yield takeEveryRequest(["USER_PUT_SELECTED_PROJECT__R", "USER_PUT_SELECTED_PROJECT__S", "USER_PUT_SELECTED_PROJECT__F"], {
    *onR(action: IUserPatchSelectedProjectR) {
      const { projectId } = action.payload;
      const email = yield select((state: IRootState) => state.modules.user.email);

      const res: any = yield call(apiRequestAuthorized, userPatchApi, {
        email,
        projectId,
      });

      return {
        user: res.data,
      };
    },

    *onS() {
      yield put(pipelinesClear({}));
    },
  });
}

export function* userSendConfirmPostSaga() {
  yield takeEveryRequest(["USERS_SENT_CONFIRM__R", "USERS_SENT_CONFIRM__S", "USERS_SENT_CONFIRM__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR() {
      yield call(apiRequestAuthorized, userSendConfirmPostApi, {});
    },
    *onS() {
      yield put(
        popupAdd({
          text: t`Email sent, please follow the instructions in the email.`,
        }),
      );
    },
  });
}

export function* usersSaga() {
  yield all([
    usersGetSaga(),
    userGetSaga(),
    userEmailPatchSaga(),
    userPatchSaga(),
    userPatchSelectedProjectSaga(),
    userSendConfirmPostSaga(),
  ]);
}
