import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const classes = {
  backdrop: (theme: Theme) => css`
    ${theme.breakpoints.up("sm")} {
      display: none;
    },
    
    z-index: 1201;
  `,

  drawer: (theme: Theme) => css`
    .MuiDrawer-paper {
      top: 82px;
      bottom: 0;
      overflow: auto;
      width: 240px;
      background: #f9fbfc;
      border-color: #e2e2e2;
      box-shadow: none;
      padding: 30px 32px;
      box-sizing: content-box;
      transition: width 300ms;
      height: auto;
      justify-content: space-between;
      border-radius: 0;

      ${theme.breakpoints.down("lg")} {
        top: 0;
      }

      ${theme.breakpoints.down("sm")} {
        width: 260px;
      }
    }

    .MuiList-root {
      &:last-child {
        border-bottom: none;
      }

      padding: 14px 0;
      border-bottom: 1px solid #e2e2e2;

      > .MuiButtonBase-root .MuiListItemText-root {
        .MuiTypography-root {
          font-weight: 600;
        }

        padding-left: 16px;
        white-space: nowrap;
        color: inherit;
      }
    }

    .MuiListItem-root {
      color: #505050;
      overflow: hidden;

      &.active {
        color: #000000;
        background: #e8f0f3 !important;
      }

      &:hover {
        background: #f1f6f8;
        color: #000000;
      }
    }

    .MuiButtonBase-root {
      padding: 5px 13px;
      border-radius: 8px;
      flex-wrap: nowrap;
      overflow: hidden;
      transition: padding 300ms;
    }

    .MuiListItemIcon-root {
      min-width: 0;
      line-height: 0;
      color: inherit;
    }

    .MuiCollapse-wrapperInner {
      padding-left: 40px;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
    }

    z-index: 1201;
  `,

  drawer_collapsed: css`
    .MuiDrawer-paper {
      width: 46px;
    }

    .MuiButtonBase-root {
      padding: 5px 11px;
    }
  `,

  foot: css`
    overflow: hidden;
    transition: opacity 200ms;
  `,

  foot__copyright: css`
    font-weight: 600;
    color: #000000;
    text-align: center;
    white-space: nowrap;
  `,

  foot__disc: css`
    font-size: 0.75rem;
    margin: 15px 0 0;
    text-align: center;
  `,

  foot_collapsed: css`
    opacity: 0;
  `,

  head: (theme: Theme) => css`
    ${theme.breakpoints.down("lg")} {
      display: flex;
    }

    border-bottom: 1px solid #e2e2e2;
    height: 82px;
    margin: -30px -30px 30px;
    padding: 0 30px;
    align-items: center;
    width: 302px;
    display: none;
  `,

  locker: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  `,

  logo: css`
    display: block;
    height: 32px;
    margin-left: 9px;
  `,
};
