import { PagingPanel, PagingPanelProps } from "@devexpress/dx-react-grid-material-ui";
import { t } from "@lingui/macro";
import { FC } from "react";

const UiDxPagingPanel: FC<PagingPanelProps> = ({ messages, ...props }) => (
  <PagingPanel
    messages={{
      info: ({ from, to, count }) => `${from}-${to} ${t`of`} ${count}`,
      rowsPerPage: t`Rows per page:`,
      showAll: t`All`,
      ...messages,
    }}
    {...props}
  />
);

export default UiDxPagingPanel;
