// UiSelect

export interface ISelectOption {
  data?: any;
  label: string;
  value: string | number;
  color?: string;
  streams?: any;
  pipelines?: any;
}

// Editor

export enum EPreviewMode {
  MOBILE = "mobile",
  DESKTOP = "desktop",
}

// Etc

export enum ESiteMessageLevel {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export const siteMessageLevelOptions: ISelectOption[] = [
  { label: "Success", value: ESiteMessageLevel.SUCCESS },
  { label: "Info", value: ESiteMessageLevel.INFO },
  { label: "Warning", value: ESiteMessageLevel.WARNING },
  { label: "Error", value: ESiteMessageLevel.ERROR },
];

export enum ESiteNotificationLevel {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export const siteNotificationLevelOptions: ISelectOption[] = [
  { label: "Success", value: ESiteMessageLevel.SUCCESS },
  { label: "Info", value: ESiteMessageLevel.INFO },
  { label: "Warning", value: ESiteMessageLevel.WARNING },
  { label: "Error", value: ESiteMessageLevel.ERROR },
];

export enum ESiteNotificationPosition {
  TR = "tr",
  TL = "tl",
  TC = "tc",
  BR = "br",
  BL = "bl",
  BC = "bc",
}

export const siteNotificationPositionOptions: ISelectOption[] = [
  { label: "Top center", value: ESiteNotificationPosition.TC },
  { label: "Top left", value: ESiteNotificationPosition.TL },
  { label: "Top right", value: ESiteNotificationPosition.TR },
  { label: "Bottom center", value: ESiteNotificationPosition.BC },
  { label: "Bottom left", value: ESiteNotificationPosition.BL },
  { label: "Bottom right", value: ESiteNotificationPosition.BR },
];
