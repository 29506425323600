import { css } from "@emotion/react/macro";

export const paperLeft = css`
  padding: 30px 30px 62px;
`;

export const paperRight = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
