export enum ETmplType {
  GOOGLE_DATA_TMPL = 0,
  EXCEL_DATA_TMPL = 1,
  PBI_DATA_TMPL = 2,
  DATAFAN_DATA_TMPL = 3,
  GOOGLE_SHEETS = 4,
}

export enum ETmplSource {
  VK = "vk",
  FB = "fb",
  INSTAGRAM = "instagram",
  OK = "ok",
  GOOGLE = "google",
  MYTARGET = "mytarget",
  TIKTOK_ADS = "tiktok_ads",
  TIKTOK = "tiktok",
  MERGE = "merge",
  MERGE_PLAN_FACT = "merge_plan_fact",
}
