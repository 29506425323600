import { t, Trans } from "@lingui/macro";
import { DialogContent } from "@mui/material";
import { Moment } from "moment";
import React, { FC, useCallback, useMemo, useState } from "react";
import CellCreatedAt from "src/components/ModalPipelineExport/components/CellCreatedAt";
import CellDelete from "src/components/ModalPipelineExport/components/CellDelete";
import CellDownload from "src/components/ModalPipelineExport/components/CellDownload";
import CellStatus from "src/components/ModalPipelineExport/components/CellStatus";
import CellType from "src/components/ModalPipelineExport/components/CellType";
import * as classes from "src/components/ModalPipelineExport/index.css";
import UiDialog from "src/components/UiDialog";
import UiInfinityTable from "src/components/UiInfinityTable";
import { EExportStatus } from "src/enums/export";
import { IExport } from "src/types/export";
import { IPipelineExtended } from "src/types/pipeline";
import useQueryExportsGet from "src/utils/queries/export/useQueryExportsGet";

import CreateForm from "./components/CreateForm";

interface IProps {
  pipeline: IPipelineExtended;
  isOpen?: boolean;
  onClose: () => void;
  onCreateStart?: (result: IExport) => void;
  initialPeriod?: [Moment, Moment];
}

const ModalPipelineExport: FC<IProps> = ({ pipeline, isOpen, onClose, onCreateStart, initialPeriod }) => {
  const [updatedExports, setUpdatedExports] = useState<{ [key: IExport["id"]]: IExport }>({});
  const [needUpdateExportIds, setNeedUpdateExportIds] = useState<number[]>([]);
  const {
    data: exports,
    isFetching: exportsIsLoading,
    fetchNextPage: exportsFetchNext,
    refetch: exportsRefetch,
  } = useQueryExportsGet(
    {
      pipelineId: pipeline.id,
    },
    {
      enabled: Boolean(isOpen),
      keepPreviousData: true,
      onSuccess: (data) => {
        const { needUpdate, updated } = data.pages.reduce(
          (acc, page) => {
            page.results.forEach((item) => {
              if ([EExportStatus.STARTED, EExportStatus.REPEAT].includes(item.status)) {
                acc.needUpdate.push(item.id);
              }

              acc.updated[item.id] = item;
            });

            return acc;
          },
          {
            needUpdate: [],
            updated: {},
          } as {
            needUpdate: number[];
            updated: { [key: IExport["id"]]: IExport };
          },
        );

        setUpdatedExports((state) => ({
          ...state,
          ...updated,
        }));

        setNeedUpdateExportIds(needUpdate);
      },
    },
  );

  useQueryExportsGet(
    {
      id__in: needUpdateExportIds,
      pipelineId: pipeline.id,
    },
    {
      enabled: Boolean(isOpen && needUpdateExportIds.length),
      keepPreviousData: true,
      onSuccess: (data) => {
        const { completed, updated } = data.pages.reduce(
          (acc, page) => {
            page.results.forEach((item) => {
              if (![EExportStatus.STARTED, EExportStatus.REPEAT].includes(item.status)) {
                acc.completed.push(item.id);
              }

              acc.updated[item.id] = item;
            });

            return acc;
          },
          {
            completed: [],
            updated: {},
          } as {
            completed: number[];
            updated: { [key: IExport["id"]]: IExport };
          },
        );

        setNeedUpdateExportIds((state) => state.filter((id) => !completed.includes(id)));

        setUpdatedExports((state) => ({
          ...state,
          ...updated,
        }));
      },
      refetchInterval: Boolean(needUpdateExportIds.length) ? 3000 : false,
      silent: true,
    },
  );

  const exportsArray = useMemo(
    () => [
      ...(exports?.pages.reduce((acc, { results }) => {
        results.forEach((item) => {
          acc.push(updatedExports?.[item.id] || item);
        });

        return acc;
      }, [] as IExport[]) || []),
    ],
    [exports?.pages, updatedExports],
  );

  const exportsCount = useMemo(() => exports?.pages[0].count, [exports?.pages]);

  const handleCreate = useCallback(
    (item: IExport) => {
      onCreateStart && onCreateStart(item);
      exportsRefetch();
    },
    [exportsRefetch, onCreateStart],
  );

  const handleDelete = useCallback(
    (exportId: IExport["id"]) => {
      exportsRefetch({
        refetchPage: (lastPage, index) => Boolean(exports?.pages[index].results.some((report) => report.id === exportId)),
      });
    },
    [exports?.pages, exportsRefetch],
  );

  return useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return (
      <UiDialog open={true} onClose={onClose} fullWidth title={<Trans>Export</Trans>} css={classes.dialog}>
        <DialogContent css={classes.container}>
          <CreateForm
            updatedExports={updatedExports}
            pipeline={pipeline}
            initialPeriod={initialPeriod}
            onCreateStart={handleCreate}
          />

          <UiInfinityTable
            paperElevation={0}
            rows={exportsArray}
            columns={[
              {
                getCellValue: (row) => <CellCreatedAt report={row} />,
                name: "created_at",
                title: t`Created at`,
              },
              {
                getCellValue: (row) => <CellStatus report={row} />,
                name: "status",
                title: t`Status`,
              },
              {
                getCellValue: (row) => <CellType report={row} />,
                name: "_type",
                title: t`Format`,
              },
              {
                getCellValue: (row) => <CellDownload report={row} />,
                name: "download",
                title: t`Download file`,
              },
              {
                getCellValue: (row) => <CellDelete report={row} onSuccess={handleDelete} />,
                masonryFullWidth: true,
                name: "delete",
                title: " ",
              },
            ]}
            loading={exportsIsLoading}
            enableSorting={false}
            totalRows={exportsCount}
            getRows={exportsFetchNext}
            masonryBreakPoints={{
              xs: 12,
            }}
            tableProps={{
              blueHead: true,
              columnExtensions: [
                { columnName: "created_at", width: 150 },
                { columnName: "status", width: 100 },
                { columnName: "_type", width: 100 },
                { align: "right", columnName: "delete", width: 78 },
              ],
            }}
          />
        </DialogContent>
      </UiDialog>
    );
  }, [
    exportsArray,
    exportsCount,
    exportsFetchNext,
    exportsIsLoading,
    handleCreate,
    handleDelete,
    initialPeriod,
    isOpen,
    onClose,
    pipeline,
    updatedExports,
  ]);
};

export default ModalPipelineExport;
