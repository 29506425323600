import { t } from "@lingui/macro";
import { EProjectRole } from "src/enums/project";

export default function getRoleDisplayName(role: EProjectRole) {
  return {
    [EProjectRole.OWNER]: t`Owner`,
    [EProjectRole.ADMIN]: t`Admin`,
    [EProjectRole.USER]: t`User`,
  }[role];
}
