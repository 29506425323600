import { Trans } from "@lingui/macro";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FC, Fragment, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";
import Loader from "src/components/Loader";
import ProviderWithIcon from "src/components/ProviderWithIcon";
import UiDialog from "src/components/UiDialog";
import { ICredential } from "src/types/credential";
import { ISocialProvider } from "src/types/socialAuth";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";
import useQueryCredentialProviders from "src/utils/queries/credentials/useQueryCredentialProviders";

interface IProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (credential: ICredential) => void;
}

const SourceListModal: FC<IProps> = ({ open, onClose, onSuccess }) => {
  const location = useLocation<{ newCredential?: ICredential } | null>();
  const { auth, status } = useSocialConnect();
  const { data: providers, isLoading: providersIsLoading } = useQueryCredentialProviders();

  const handleOpenSmpAuth = useCallback(
    (provider: ISocialProvider) => () =>
      auth({
        action: "connect",
        providerId: provider.id,
      }),
    [auth],
  );

  useMount(() => {
    if (location.state?.newCredential) {
      onSuccess && onSuccess(location.state.newCredential);
      window.history.replaceState({}, document.title);
    }
  });

  return useMemo(
    () => (
      <UiDialog fullScreen open={open} keepMounted onClose={onClose} title={<Trans>Add source</Trans>}>
        <List>
          {providersIsLoading ? (
            <ListItem>
              <Loader />
            </ListItem>
          ) : (
            providers?.map((object) => (
              <Fragment key={object.id}>
                <ListItem onClick={handleOpenSmpAuth(object)} button disabled={status === "loading"}>
                  {object.medium === "tiktok" && (
                    <ListItemText primary={<ProviderWithIcon provider={object.medium} />} secondary={<Trans id="Ads" />} />
                  )}
                  {object.medium === "smmplanner" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="SmmPlanner" />}
                    />
                  )}
                  {object.medium === "vk" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="Ads, communities and posts" />}
                    />
                  )}
                  {object.medium === "facebook" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="Page, Ads, Instagram Business Account" />}
                    />
                  )}
                  {object.medium === "google" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="Google Analytics" />}
                    />
                  )}
                  {object.medium === "ok" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="Communities and posts" />}
                    />
                  )}
                  {object.medium === "mytarget" && (
                    <ListItemText primary={<ProviderWithIcon provider={object.medium} />} secondary={<Trans id="Ads" />} />
                  )}
                  {object.medium === "yandex" && (
                    <ListItemText
                      primary={<ProviderWithIcon provider={object.medium} />}
                      secondary={<Trans id="Yandex metrika and ads" />}
                    />
                  )}
                </ListItem>
                <Divider />
              </Fragment>
            ))
          )}
        </List>
      </UiDialog>
    ),
    [handleOpenSmpAuth, onClose, open, providers, providersIsLoading, status],
  );
};

export default SourceListModal;
