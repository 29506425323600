import "slick-carousel/slick/slick.css";
import "./index.css";

import { useMediaQuery, useTheme } from "@mui/material";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { forwardRef, ReactNode } from "react";
import Slider, { Settings } from "react-slick";

export interface CarouselProps extends Settings {
  children: ReactNode;
}

const SlickButton = ({ currentSlide, slideCount, children, ...props }: any) => <div {...props}>{children}</div>;

const Carousel = forwardRef<Slider, CarouselProps>(({ children, ...settings }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Slider
      ref={ref}
      prevArrow={
        <SlickButton>
          <IconChevronLeft />
        </SlickButton>
      }
      nextArrow={
        <SlickButton>
          <IconChevronRight />
        </SlickButton>
      }
      arrows={!isMobile}
      dots
      infinite
      {...settings}
      className={isMobile ? "slick-slider_mobile" : ""}
    >
      {children}
    </Slider>
  );
});

export default Carousel;
