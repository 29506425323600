import { IExport } from "src/types/export";
import { IPipeline } from "src/types/pipeline";
import { IUseQueryInfiniteOptions, useQueryInfinite } from "src/utils/queries/useQueryBase";

interface IRequestParams {
  pipelineId: IPipeline["id"];
  limit?: number;
  offset?: number;
  ordering?: string;
  id__in?: number[];
}

const QUERY_KEY = "pipeline-exports";

export default function useQueryExportsGet(params: IRequestParams, queryOptions?: IUseQueryInfiniteOptions<IExport>) {
  return useQueryInfinite<IExport>(
    [QUERY_KEY, params],
    {
      enabled: Boolean(params.pipelineId),
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        ...params,
        id__in: params.id__in?.join(","),
        limit: params.limit || 100,
        ordering: params.ordering || "-id",
      },
      url: `pipeline/${params.pipelineId}/export/`,
    },
  );
}
