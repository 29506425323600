import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { IconChevronLeft } from "@tabler/icons";
import { FC, useCallback } from "react";
import { useAppContext } from "src/context/app/appContext";
import logoImage from "src/statics/logo.svg";
import cssc from "src/utils/emotionComposition";

import { classes } from "./index.css";

const SidebarButton: FC = () => {
  const { state, updateState } = useAppContext();

  const handleToggleSidebar = useCallback(() => {
    updateState({ sidebar: { expanded: !state.sidebar.expanded } }, "ls");
  }, [state.sidebar.expanded, updateState]);

  return (
    <Grid container css={cssc([classes.container, state.sidebar.expanded && classes.container_expanded])}>
      <Grid item>
        <img alt="DataFan" src={logoImage} css={classes.logo} />
      </Grid>

      <Grid item>
        <IconButton onClick={handleToggleSidebar} css={[classes.btn, state.sidebar.expanded && classes.btn_expended]}>
          {state.sidebar.expanded ? <IconChevronLeft /> : <MenuIcon />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SidebarButton;
