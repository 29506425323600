import { css } from "@emotion/react/macro";

export const classes = {
  button: css`
    display: inline-flex;
    width: 46px;
    height: 46px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
  `,
  button_active: css`
    background: #e8f0f3;
    cursor: default;
  `,
};
