import { ICredential } from "src/types/credential";
import { ISocialSmpAccountData } from "src/types/socialAuth";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryCredentialConnect(queryOptions?: IUseQueryMutationOptions<ICredential, ISocialSmpAccountData>) {
  return useMutationBase<ICredential, ISocialSmpAccountData>(queryOptions, {
    method: "POST",
    url: `account-credentials/connect/`,
  });
}
