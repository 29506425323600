import { Plural, Trans } from "@lingui/macro";
import LinkMaterial from "@mui/material/Link";
import { FC, useCallback, useMemo } from "react";
import TopNotice from "src/components/TopNotice";
import { useAppContext } from "src/context/app/appContext";
import { useBillingContext } from "src/context/billing/billingContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import useSubscriptionTimeLeft from "src/utils/hoooks/useSubscriptionTimeLeft";
import useQueryMe from "src/utils/queries/user/useQueryMe";

const MIN_TARIFF_EXPIRE_DAYS = 3;

const TariffExpireNotice: FC = () => {
  const {
    actions: { renewSubscription, openModalSubscriptionOrder },
  } = useBillingContext();
  const { data: user } = useQueryMe();
  const {
    state: { context },
  } = useAppContext();

  const handleBuyStart = useCallback(() => {
    openModalSubscriptionOrder({
      renewalWithFreeDays: false,
      tariffSign: ETariffOnPeriodSign.START,
    });
  }, [openModalSubscriptionOrder]);

  const handleRenewSubscription = useCallback(() => {
    renewSubscription({});
  }, [renewSubscription]);

  const timeLeftDays = useSubscriptionTimeLeft();

  const displayTariffExpireNotice = useMemo(
    () =>
      context !== "printer" && user?.billing_enabled && (!user?.active_subscription || timeLeftDays <= MIN_TARIFF_EXPIRE_DAYS),
    [context, timeLeftDays, user?.active_subscription, user?.billing_enabled],
  );

  const Component = useCallback(() => {
    if (!user || user.isElamaUser) {
      return null;
    }

    if (!user?.active_subscription) {
      if (context === "oauth2") {
        return <Trans>Your tariff plan is over.</Trans>;
      } else if (user.isTrialUser) {
        return (
          <Trans>
            Your Trial period has ended.{" "}
            <LinkMaterial component="button" variant="body1" underline="always" onClick={handleBuyStart}>
              Pay the subscription
            </LinkMaterial>{" "}
            to continue using the service.
          </Trans>
        );
      } else {
        return (
          <Trans>
            Your tariff plan is over, please{" "}
            <LinkMaterial component="button" variant="body1" underline="always" onClick={handleRenewSubscription}>
              renew it
            </LinkMaterial>
            .
          </Trans>
        );
      }
    } else {
      if (context === "oauth2") {
        return (
          <Trans>
            Your tariff plan will end{" "}
            <Plural value={timeLeftDays} _0="today" one="in # day" few="in # days" many="in # days" other="in # days" />, data
            will be unavailable.
          </Trans>
        );
      } else if (user.isTrialUser) {
        return (
          <Trans>
            Your Trial period is about to ended.{" "}
            <LinkMaterial component="button" variant="body1" underline="always" onClick={handleBuyStart}>
              Pay the subscription
            </LinkMaterial>{" "}
            to continue using the service
          </Trans>
        );
      } else {
        return (
          <Trans>
            Your tariff plan is about to ended.{" "}
            <LinkMaterial component="button" variant="body1" underline="always" onClick={handleRenewSubscription}>
              Renew it
            </LinkMaterial>
            .
          </Trans>
        );
      }
    }
  }, [context, handleBuyStart, handleRenewSubscription, timeLeftDays, user]);

  if (!user || user.isElamaUser) {
    return null;
  }

  return (
    <TopNotice variant="warning" open={displayTariffExpireNotice}>
      <Component />
    </TopNotice>
  );
};

export default TariffExpireNotice;
