import { Trans } from "@lingui/macro";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { FC } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import { ECurrency } from "src/redux";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";
import useQueryBillingInvoiceGet from "src/utils/queries/billing/useQueryBillingInvoiceGet";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import useStyles from "./index.css";

const Thanks: FC = () => {
  const classes = useStyles();
  const location = useLocation<{ next?: string }>();
  const { data: user, isLoading: userIsLoading } = useQueryMe();
  const { data: invoice, isLoading: invoiceIsLoading } = useQueryBillingInvoiceGet({ limit: 1, status: 2 });

  const subscription = user?.active_subscription;

  if (userIsLoading || invoiceIsLoading) {
    return <Loader />;
  }

  if (!subscription || !invoice) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="xs" disableGutters>
      <Paper className={classes.paper}>
        <Box textAlign="center" mb={2}>
          <Avatar className={classes.avatar}>
            <CheckIcon />
          </Avatar>
        </Box>

        <Box textAlign="center" mb={4}>
          <Typography variant="h2" gutterBottom>
            <Trans>Thank you for buying!</Trans>
          </Typography>
        </Box>

        {subscription && (
          <Box mb={2}>
            <dl className={classes.dl}>
              <dt>
                <Typography variant="subtitle2">
                  <Trans>Tariff plan</Trans>
                </Typography>
              </dt>
              <dd>{subscription.tariff_on_period.tariff.name}</dd>
            </dl>

            <dl className={classes.dl}>
              <dt>
                <Typography variant="subtitle2">
                  <Trans>Price</Trans>
                </Typography>
              </dt>
              <dd>
                {parseInt(invoice.total || subscription.total_price, 10)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {getCurrencyDisplayName(subscription.currency as ECurrency)}
              </dd>
            </dl>

            <dl className={classes.dl}>
              <dt>
                <Typography variant="subtitle2">
                  <Trans>Validity period</Trans>
                </Typography>
              </dt>
              <dd>
                <Trans>until</Trans> {moment(subscription.date_finish).format("D MMMM YYYY")}
              </dd>
            </dl>
          </Box>
        )}

        <Box textAlign="center" mt={4} mb={2}>
          <Button component={Link} to={location.state?.next || "/"} variant="contained" color="primary">
            <Trans>Continue working</Trans>
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Thanks;
