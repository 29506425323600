import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const btnDelete = css`
  color: #757575;

  :hover {
    color: #000000;
  }
`;

export const actions = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    text-align: left;
    margin-bottom: -20px;
  }
`;
