import { css } from "@emotion/react/macro";

export const code = css`
  display: inline-flex;
  background: #e5f6fd;
  color: #014361;
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #a6c5d7;
  align-items: center;
  cursor: pointer;
`;

export const code__value = css`
  color: inherit;
  font-size: 12px;
  font-weight: 700;
`;

export const code__icn = css`
  color: inherit;
  position: relative;
  right: -5px;
  bottom: -2px;
  opacity: 0.2;
  transition: opacity 150ms;

  .css-${code.name}:hover & {
    opacity: 1;
  }
`;
