import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme: Theme) => ({
  container: {
    "& .MuiPaper-root": {
      width: "100%",
    },

    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    padding: theme.spacing(2),
  },
}));
