import { t } from "@lingui/macro";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { FC, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

import Wrapper from "./components/Wrapper";
import { classes } from "./index.css";

export interface UiDatePickerProps
  extends Omit<StaticDatePickerProps<Moment>, "date" | "rawValue" | "openPicker" | "onChange" | "renderInput" | "value"> {
  value?: Moment | null;
  open?: boolean;
  closeOnAccept?: boolean;
  onChange: (date: Moment | null) => void;
  isStatic?: boolean;
  hideHead?: boolean;
}

const UiDatePicker: FC<UiDatePickerProps> = ({
  value,
  open: defaultOpen,
  onChange,
  closeOnAccept,
  isStatic,
  hideHead = false,
  ...datePickerProps
}) => {
  const [currentView, setCurrentView] = useState<"day" | "month" | "year">("day");
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(defaultOpen);

  const momentValue = useMemo(() => value || null, [value]);

  const momentValueToday = useMemo(() => moment(new Date()), []);

  const weekDays = useMemo(() => [t`Monday`, t`Tuesday`, t`Wednesday`, t`Thursday`, t`Friday`, t`Saturday`, t`Sunday`], []);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleAccept = useCallback(() => {
    if (closeOnAccept) {
      setOpen(false);
    }
  }, [closeOnAccept]);

  const handleChange = useCallback(
    (date: Moment | null) => {
      setCurrentView("day");
      onChange(date);
    },
    [onChange],
  );

  const handleToggleView = useCallback(
    (e: Event) => {
      if (containerRef) {
        const [monthDiv, yearDiv] = containerRef.querySelectorAll("[aria-live=polite]");
        const isTargetMonth = monthDiv === e.target;
        const isTargetYear = yearDiv === e.target;

        const targetView = (() => {
          if (isTargetMonth) {
            return "month";
          }

          if (isTargetYear) {
            return "year";
          }

          return null;
        })();

        if (targetView) {
          setCurrentView((currentView) => (targetView === currentView ? "day" : targetView));
        }
      }
    },
    [containerRef],
  );

  const renderInput = useCallback((params) => <TextField {...params} />, []);

  useUpdateEffect(() => {
    if (containerRef) {
      const presentationDiv = containerRef.querySelector("[role=presentation]");

      presentationDiv?.addEventListener("click", handleToggleView);
    }
  }, [containerRef]);

  return (
    <div css={classes.wrapper}>
      <Wrapper open={Boolean(open)} value={momentValue} onClose={handleClose} onOpen={handleOpen} isStatic={Boolean(isStatic)}>
        {!hideHead && (
          <div css={classes.head}>
            <Typography css={classes.head__year}>{(momentValue || momentValueToday).format("YYYY")}</Typography>
            <Typography css={classes.head__date}>{(momentValue || momentValueToday).format("dd, MMM D")}</Typography>
          </div>
        )}

        <div css={classes.picker(weekDays)} ref={setContainerRef}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={handleChange}
            onAccept={handleAccept}
            renderInput={renderInput}
            /*// @ts-ignore */
            view={currentView}
            components={{
              SwitchViewButton: () => null,
            }}
            {...datePickerProps}
          />
        </div>
      </Wrapper>
    </div>
  );
};

export default UiDatePicker;
