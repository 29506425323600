import { t, Trans } from "@lingui/macro";
import { Grid } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import UiSelect from "src/components/UiSelect";
import { EPipelineMedia, EPipelineType } from "src/enums/pipeline";
import groupPipelineTypes from "src/utils/groupPipelineTypes";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryTmpls from "src/utils/queries/tmpls/useQueryTmpls";
import useQueryMe from "src/utils/queries/user/useQueryMe";

export interface IFilterForm {
  media: EPipelineMedia[] | null;
  source: EPipelineType[] | null;
}

interface IProps {
  onChange: <K extends keyof IFilterForm>(key: K, val: IFilterForm[K]) => void;
  values: IFilterForm;
}

const Filter: FC<IProps> = ({ onChange, values }) => {
  const { data: user } = useQueryMe();
  const { data: tmpls } = useQueryTmpls();
  const { data: pipelineTypes } = useQueryPipelineTypes();

  const availableSources = useMemo(() => {
    const sources = tmpls ? [...new Set(tmpls.map(({ pipeline_source }) => pipeline_source))] : [];

    if (sources.includes(null) && pipelineTypes) {
      Object.values(pipelineTypes).forEach((pipelineType) => {
        if ((!pipelineType.onlyStaff || user?.is_staff) && !sources.includes(pipelineType.id)) {
          sources.push(pipelineType.id);
        }
      });
    }

    return sources;
  }, [pipelineTypes, tmpls, user?.is_staff]);

  const pipelineTypesArray = useMemo(
    () =>
      (pipelineTypes ? Object.values(pipelineTypes) : []).filter((pipelineType) => availableSources.includes(pipelineType.id)),
    [availableSources, pipelineTypes],
  );

  const mediaOptions = useMemo(
    () =>
      Object.values(
        pipelineTypesArray.reduce(
          (acc, pipelineType) => {
            if (
              (!values.source?.length || values.source.includes(pipelineType.id)) &&
              pipelineType.media !== EPipelineMedia.DATAFAN
            ) {
              acc[pipelineType.media] = {
                label: {
                  [EPipelineMedia.VK]: t`VK`,
                  [EPipelineMedia.FB]: t`Facebook`,
                  [EPipelineMedia.GOOGLE]: t`Google`,
                  [EPipelineMedia.OK]: t`OK`,
                  [EPipelineMedia.INSTAGRAM]: t`Instagram`,
                  [EPipelineMedia.MYTARGET]: t`myTarget`,
                  [EPipelineMedia.YA]: t`Yandex.Metrika`,
                  [EPipelineMedia.TIKTOK]: t`TikTok`,
                  [EPipelineMedia.TELEGRAM]: t`Telegram`,
                }[pipelineType.media],
                value: pipelineType.media,
              };
            }

            return acc;
          },
          {} as {
            [key in Exclude<EPipelineMedia, EPipelineMedia.DATAFAN>]: {
              label: string;
              value: EPipelineMedia;
            };
          },
        ),
      ),
    [pipelineTypesArray, values.source],
  );

  const sourceOptions = useMemo(() => {
    const grouped = groupPipelineTypes(
      !values.media || values.media.length === 0
        ? pipelineTypesArray
        : pipelineTypesArray.filter((pt) => values.media?.includes(pt.media)),
    );

    return (
      grouped &&
      Object.values(grouped).map((group) => ({
        label: group.shortTitle,
        options: group.items.map((pt) => ({
          label: pt.shortTitle,
          value: pt.id,
        })),
      }))
    );
  }, [pipelineTypesArray, values.media]);

  const handleChangeSelect = useCallback(
    <K extends keyof IFilterForm>(key: K) =>
      (value: IFilterForm[K]) => {
        onChange(key, value);
      },
    [onChange],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <UiSelect
          fullWidth
          isMulti
          value={values.source}
          onChange={handleChangeSelect("source")}
          isDisabled={!sourceOptions?.length}
          options={sourceOptions}
          maxMenuHeight={600}
          placeholder={<Trans>Publics and ad accounts</Trans>}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <UiSelect
          fullWidth
          isMulti
          value={values.media}
          onChange={handleChangeSelect("media")}
          options={mediaOptions}
          maxMenuHeight={600}
          placeholder={<Trans>Social media</Trans>}
        />
      </Grid>
    </Grid>
  );
};

export default Filter;
