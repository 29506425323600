import { Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import { useMount, useUpdateEffect } from "react-use";
import AddProvider from "src/components/AddProvider";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipeline } from "src/types/pipeline";
import { IStreamConfig } from "src/types/stream";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryCredentialVkGroup from "src/utils/queries/credentials/useQueryCredentialVkGroup";

type IGroupCredentialId = IStreamConfig["account_public_credentials_id"];

interface IProps {
  enabled: boolean;
  groupCredentialId: IGroupCredentialId;
  groupId: number;
  onToggle: (checked: boolean) => void;
  onChange: (credId: IGroupCredentialId) => void;
  persistState?: {
    [key: string]: any;
  };
  pipeline: IPipeline;
  isOwner: boolean;
  credentialId: ICredential["id"];
}

const VkGroupToken: FC<IProps> = ({
  enabled,
  groupCredentialId,
  groupId,
  onChange,
  onToggle,
  persistState,
  pipeline,
  isOwner,
  credentialId,
}) => {
  const {
    mutate: getVkGroup,
    data: vkGroupData,
    isSuccess: vkGroupIsLoaded,
  } = useQueryCredentialVkGroup({
    onSuccess: (data) => {
      if (data.account_credential_id) {
        onChange(data.account_credential_id);
      }
    },
  });

  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onToggle(checked);
    },
    [onToggle],
  );

  const isCredLost = useMemo(
    () =>
      Boolean(
        vkGroupIsLoaded && (!vkGroupData?.account_credential_id || vkGroupData?.account_credential_id !== groupCredentialId),
      ),
    [groupCredentialId, vkGroupData?.account_credential_id, vkGroupIsLoaded],
  );

  const { data: groups } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.VK_GROUPS,
      projectId: pipeline.project,
    },
    {
      enabled: isOwner,
    },
  );

  const isAdmin = useMemo(() => groups?.find((group) => group.id === groupId)?.admin_level === 3, [groupId, groups]);

  useUpdateEffect(() => {
    if (enabled && isOwner) {
      getVkGroup({ vkGroupId: groupId });
    }
  }, [enabled, isOwner]);

  useMount(() => {
    if (enabled && isOwner) {
      getVkGroup({ vkGroupId: groupId });
    }
  });

  return (
    <>
      <Box mt={2} mb={2}>
        <FormControlLabel
          control={<Checkbox checked={enabled} onChange={handleToggle} disabled={!isOwner || !isAdmin} />}
          label={<Trans>grant_vk_permissions_edit</Trans>}
        />
      </Box>

      {enabled && isOwner && isAdmin && (
        <>
          <Box mb={3}>
            <AddProvider
              source={pipeline.source}
              ButtonProps={{
                disabled: !isCredLost,
                fullWidth: false,
                loading: !vkGroupIsLoaded,
              }}
              text={<Trans>Collect story data</Trans>}
              persistState={persistState}
              extraParams={{
                group_ids: [groupId],
                scope: ["stories"],
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default VkGroupToken;
