import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  accordion: {
    "& .MuiAccordionSummary-content": {
      margin: "20px 0",
    },

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(45deg)",
    },

    "&.Mui-expanded + &:before": {
      display: "none",
    },

    boxShadow: "0px 10px 10px #00000024",
  },

  accordionHeader: {
    "& .MuiTypography-root": {
      transition: "all 300ms",
    },

    "&.Mui-expanded": {
      "& .MuiTypography-root": {
        fontSize: "1.6rem",
        lineHeight: 1.2,
      },
    },
  },

  content: {
    "& img": {
      display: "block",
      height: "auto !important",
      margin: "10px auto",
      maxWidth: "640px",
      width: "100% !important",
    },
  },

  error: {
    color: theme.palette.error.main,
    display: "block",
    margin: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));
