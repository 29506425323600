export function arrayAssert<I>(itemOrItems: I | I[]): I[] {
  return itemOrItems ? (Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems]) : [];
}

export function arraySortAsc<T>(a: T, b: T): number {
  return a > b ? 1 : a < b ? -1 : 0;
}

export function arrayEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  } else {
    arr1.sort(arraySortAsc);
    arr2.sort(arraySortAsc);
    for (let idx = 0; idx < arr1.length; idx++) {
      if (arr1[idx] !== arr2[idx]) {
        return false;
      }
    }
    return true;
  }
}

export function arrayStringForSort<T>(arr1: T[] | undefined) {
  let ordering;
  const orderingString: any[] = [];
  if (arr1 !== undefined) {
    ordering = arr1;
    ordering.forEach((element: any) => {
      if (element["direction"] === "asc") {
        orderingString.push(element["columnName"]);
      } else {
        orderingString.push("-" + element["columnName"]);
      }
    });
  }
  return orderingString.join();
}

export const arraySieve = <T>(array: Array<T | undefined | null>): T[] =>
  array.filter((item: T | undefined | null): item is T => !!item);
