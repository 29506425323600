import { AxiosPromise } from "axios";
import { apiRequest } from "src/utils";

export interface IUsersGetApiRequestOptions {
  accessToken: string;
  filter?: string;
}

export interface IUsersPutApiRequestOptions {
  accessToken: string;
  currentPassword?: string;
  language?: string;
  newPassword?: string;
  projectId?: number;
}

export function usersGetApi({ accessToken, filter }: IUsersGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      filter,
    },
    url: "users/",
  });
}

export function userSendConfirmPostApi({ accessToken }: IUsersGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "POST",
    url: "user/me/send_confirm/",
  });
}

export function userGetApi({ accessToken }: IUsersGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: "user/me/",
  });
}

export function userEmailPatchApi({
  accessToken,
  email,
  password,
}: {
  accessToken: string;
  email: string;
  password: string;
}): AxiosPromise {
  return apiRequest({
    accessToken,
    data: {
      email,
      password,
    },
    method: "PATCH",
    url: "user/change-email/",
  });
}

export function userPatchApi({
  accessToken,
  currentPassword,
  language,
  newPassword,
  projectId,
}: IUsersPutApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    data: {
      confirm_password: currentPassword,
      language,
      new_password: newPassword,
      selected_project: projectId,
    },
    method: "PATCH",
    url: "user/me/",
  });
}

export function userMetaGetApi({ accessToken, sign }: { accessToken: string; sign: string }): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `user/meta/${sign}/`,
  });
}
