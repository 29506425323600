import { ICredential } from "src/types/credential";
import { IPipeline } from "src/types/pipeline";
import { IProject } from "src/types/project";
import { useQueryProjectCredentialsInvalidate } from "src/utils/queries/project/useQueryProjectCredentialsGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  projectId: IProject["id"];
  account_credentials_ids: ICredential["id"][];
}

export default function useQueryProjectCredentialsPost(queryOptions?: IUseQueryMutationOptions<IPipeline, IInput>) {
  const invalidateCreds = useQueryProjectCredentialsInvalidate();

  return useMutationBase<IPipeline, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateCreds(args[1].projectId);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      params: ({ projectId, ...params }) => params,
      url: ({ projectId }) => `project/${projectId}/shared-credentials/`,
    },
  );
}
