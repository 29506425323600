import { useQueryClient } from "react-query";
import { ICredential } from "src/types/credential";
import { IProject } from "src/types/project";
import { IResponseBasePagination } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "project-credentials-get";

export default function useQueryProjectCredentialsGet(
  projectId: IProject["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<ICredential>, ICredential[]>,
) {
  return useQueryBase<IResponseBasePagination<ICredential>, ICredential[]>(
    [QUERY_KEY, projectId],
    {
      enabled: typeof projectId !== "undefined",
      select: (data) => data.results,
      ...queryOptions,
    },
    {
      method: "GET",
      url: `project/${projectId}/shared-credentials/`,
    },
  );
}

export function useQueryProjectCredentialsInvalidate() {
  const clientQuery = useQueryClient();

  return (projectId: IProject["id"]) => clientQuery.invalidateQueries([QUERY_KEY, projectId]);
}
