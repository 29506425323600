import { Trans } from "@lingui/macro";
import { Box, Grid, GridProps, Typography } from "@mui/material";
import { ComponentProps, FC, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import AddProvider from "src/components/AddProvider";
import Loader from "src/components/Loader";
import { UiButtonProps } from "src/components/UiButton";
import UiSelect, { UiSelectProps } from "src/components/UiSelect";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IProject } from "src/types/project";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

interface IProps {
  value: ICredential["id"] | undefined;
  projectId: IProject["id"];
  source: IPipelineType["id"];
  onChange: (credential: ICredential | undefined) => void;
  persistState: ComponentProps<typeof AddProvider>["persistState"];
  ButtonProps?: UiButtonProps;
  justifyContent?: GridProps["justifyContent"];
  label?: UiSelectProps<any>["label"];
}

const SelectAccountCredential: FC<IProps> = ({
  value,
  projectId,
  source,
  onChange,
  persistState,
  ButtonProps,
  justifyContent,
  label,
}) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const location = useLocation<{ newCredential?: ICredential } | undefined>();
  const pipelineType = useMemo(() => pipelineTypes?.[source], [pipelineTypes, source]);

  const { data: credentials } = useQueryCredentialsGet(
    {
      enabled: typeof pipelineType?.streams[0].id !== "undefined",
    },
    {
      projects: [projectId],
      stream: pipelineType?.streams[0].id,
    },
  );

  const newCredential = useMemo(
    () =>
      location.state?.newCredential && location.state.newCredential.medium === pipelineType?.media
        ? location.state.newCredential
        : undefined,
    [location.state?.newCredential, pipelineType?.media],
  );

  const options = useMemo(
    () =>
      credentials?.map((cred) => ({
        label: `${cred.name} (${cred.id})`,
        value: cred.id,
      })),
    [credentials],
  );

  const handleChange = useCallback(
    (value: ICredential["id"] | null) => {
      onChange(credentials?.find(({ id }) => id === value));
    },
    [credentials, onChange],
  );

  useUpdateEffect(() => {
    if (newCredential || credentials?.length) {
      onChange(newCredential || credentials?.[0]);
    }
  }, [credentials]);

  useMount(() => {
    if (newCredential || (credentials?.length && !value)) {
      onChange(newCredential || credentials?.[0]);
    }
  });

  if (typeof credentials === "undefined") {
    return (
      <Box sx={{ height: 46 }}>
        <Loader autoHeight />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} alignItems="flex-end" justifyContent={justifyContent || "center"}>
      {options && options.length > 0 && (
        <>
          <Grid item xs>
            <UiSelect
              isDisabled={options.length === 1}
              value={value}
              options={options}
              onChange={handleChange}
              fullWidth
              label={label}
            />
          </Grid>

          <Grid item pb={1.8}>
            <Typography sx={{ color: "neutral.main" }}>
              <Trans>or</Trans>
            </Typography>
          </Grid>
        </>
      )}

      <Grid item>
        <AddProvider
          source={source}
          persistState={persistState}
          ButtonProps={
            ButtonProps || {
              disableElevation: Boolean(options?.length),
              variant: options?.length ? "outlined" : "contained",
            }
          }
        />
      </Grid>
    </Grid>
  );
};

export default SelectAccountCredential;
