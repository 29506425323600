import { Backdrop } from "@mui/material";
import { IconArrowsMaximize } from "@tabler/icons";
import { FC, HTMLProps, useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useMount, useUpdateEffect } from "react-use";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

export interface ZoomImgProps extends HTMLProps<HTMLDivElement> {
  src: string | undefined;
  alt: string;
}

enum EState {
  PENDING,
  ZOOM_START,
  ZOOM_PROCESS,
  ZOOM_FINISH,
  UNZOOM_START,
  UNZOOM_PROCESS,
}

const ZoomImg: FC<ZoomImgProps> = ({ src, alt, ...props }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const windowRation = window.innerWidth / window.innerHeight;
  const imgRatio = imageRef.current && imageRef.current.naturalWidth / imageRef.current.naturalHeight;

  const [isImageLoaded, setImageLoaded] = useState(false);
  const [state, setState] = useState(EState.PENDING);

  const endSizes = useMemo(() => {
    if (!imgRatio || !imageRef.current) {
      return null;
    }

    if (windowRation <= imgRatio) {
      return {
        height: Math.min(window.innerWidth / imageRef.current.naturalWidth, 1) * imageRef.current.naturalHeight,
        width: Math.min(imageRef.current.naturalWidth, window.innerWidth),
      };
    } else {
      return {
        height: Math.min(imageRef.current.naturalHeight, window.innerHeight),
        width: Math.min(window.innerHeight / imageRef.current.naturalHeight, 1) * imageRef.current.naturalWidth,
      };
    }
  }, [imgRatio, windowRation]);

  const endPosition = useMemo(
    () =>
      endSizes && {
        left: (window.innerWidth - endSizes.width) / 2,
        top: (window.innerHeight - endSizes.height) / 2,
      },
    [endSizes],
  );

  const containerStyles = useMemo(() => {
    const imageRects = imageRef.current?.getBoundingClientRect();

    if (!endSizes || !endPosition || !imageRects) {
      return undefined;
    }

    if ([EState.ZOOM_START, EState.UNZOOM_PROCESS].includes(state)) {
      return {
        height: imageRects.height,
        left: imageRects.x,
        top: imageRects.y,
        width: imageRects.width,
      };
    } else if ([EState.ZOOM_PROCESS, EState.UNZOOM_START].includes(state)) {
      return {
        height: endSizes.height,
        left: endPosition.left,
        top: endPosition.top,
        width: endSizes.width,
      };
    }
  }, [endPosition, endSizes, state]);

  const displayZoomImage = useMemo(() => state !== EState.PENDING && endSizes && endPosition, [endPosition, endSizes, state]);

  const handleZoom = useCallback(() => {
    setState(EState.ZOOM_START);
  }, []);

  const handleUnZoom = useCallback(() => {
    setState(EState.UNZOOM_START);
  }, []);

  const handleTransitionEnd = useCallback(() => {
    if (state === EState.ZOOM_PROCESS) {
      setState(EState.ZOOM_FINISH);
    } else if (state === EState.UNZOOM_PROCESS) {
      setState(EState.PENDING);
    }
  }, [state]);

  useUpdateEffect(() => {
    if (state === EState.ZOOM_START) {
      setState(EState.ZOOM_PROCESS);
      document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + "px";
      document.body.style.overflow = "hidden";
    }

    if (state === EState.UNZOOM_START) {
      setState(EState.UNZOOM_PROCESS);
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }
  }, [state]);

  useMount(() => {
    imageRef.current?.addEventListener("load", () => {
      setImageLoaded(true);
    });
  });

  return (
    <>
      <div css={classes.preview} {...props}>
        {src && (
          <>
            <img css={classes.image} ref={imageRef} src={src} alt={alt} />

            {isImageLoaded && (
              <div onClick={handleZoom} css={classes.preview__icon}>
                <div css={classes.preview__icon__inner}>
                  <IconArrowsMaximize />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {displayZoomImage && (
        <>
          {createPortal(
            <div css={classes.wrapper} onClick={handleUnZoom}>
              <Backdrop open />

              <div
                css={cssc([
                  classes.container,
                  state === EState.ZOOM_FINISH && classes.container_visible,
                  state === EState.UNZOOM_START && classes.container_transition_disabled,
                ])}
                style={containerStyles}
                onTransitionEnd={handleTransitionEnd}
                onClick={handleUnZoom}
              >
                <img src={src} alt={alt} />
              </div>
            </div>,
            document.body,
          )}
        </>
      )}
    </>
  );
};

export default ZoomImg;
