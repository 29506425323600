import { forwardRef } from "react";
import Slider from "react-slick";
import Carousel, { CarouselProps } from "src/components/Carousel";
import { useAppContext } from "src/context/app/appContext";

const TariffCarousel = forwardRef<Slider, CarouselProps>(({ children, ...props }, ref) => {
  const { state } = useAppContext();

  return (
    <Carousel
      variableWidth={true}
      dots={false}
      slidesToShow={5}
      slidesToScroll={1}
      infinite={false}
      responsive={[
        {
          breakpoint: 1458,
          settings: {
            slidesToShow: state.sidebar.expanded ? 3 : 5,
          },
        },
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 894,
          settings: {
            slidesToShow: 1,
          },
        },
      ]}
      ref={ref}
      {...props}
    >
      {children}
    </Carousel>
  );
});

export default TariffCarousel;
