import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BellIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        id="Color"
        d="M12,21.5c-1.1,0-2-0.9-2-2h4C14,20.6,13.1,21.5,12,21.5z M20,18.5H4v-1l2-2v-5c0-1.4,0.4-2.9,1.2-4.1
	c0.8-1.1,2-2,3.3-2.3V3.5C10.5,2.7,11.2,2,12,2s1.5,0.7,1.5,1.5v0.7c1.4,0.3,2.5,1.1,3.3,2.3c0.8,1.2,1.2,2.6,1.2,4.1v5l2,2V18.5z"
      />
    </SvgIcon>
  );
}
