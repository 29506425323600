import { Trans } from "@lingui/macro";
import { FC, useMemo } from "react";
import { ICredential } from "src/types/credential";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

const CellHealth: FC<ICredential> = ({ lost_at }) =>
  useMemo(
    () => (
      <>
        {lost_at ? (
          <div css={cssc([classes.health, classes.health_bad])}>
            <Trans>Access lost</Trans>
          </div>
        ) : (
          <div css={cssc([classes.health, classes.health_good])}>
            <Trans>Good</Trans>
          </div>
        )}
      </>
    ),
    [lost_at],
  );

export default CellHealth;
