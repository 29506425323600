import { IExport } from "src/types/export";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  status: IExport["status"];
  url: string;
}

const QUERY_KEY = "export-get-url";

export default function useQueryExportUrl(exportId?: number | string, queryOptions?: IUseQueryBaseOptions<IResponse, string>) {
  return useQueryBase<IResponse, string>(
    [QUERY_KEY, exportId],
    {
      enabled: typeof exportId !== "undefined",
      select: (data) => data.url,
      ...queryOptions,
    },
    {
      method: "GET",
      url: `export/${exportId}/url/`,
    },
  );
}
