import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const cellColor = (color?: string) => (theme: Theme) =>
  css`
    width: 24px;
    height: 24px;
    background-color: ${color || "#ffffff"};
    border-radius: 8px;
    border: 1px solid ${color || theme.palette.primary.main};
    display: inline-block;
  `;

export const cellLink = css`
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
`;
