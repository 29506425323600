import { t } from "@lingui/macro";
import axios, { AxiosPromise, AxiosResponse, CancelToken } from "axios";

export interface IFiltered {
  columnName: string;
  value: string | string[];
}

type IApiMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface IApiData {
  [key: string]: any;
}
interface IApiHeaders {
  [key: string]: string;
}
interface IApiParams {
  [key: string]: any;
}

export interface IApiRequestParams {
  accessToken?: string;
  data?: IApiData | string;
  headers?: IApiHeaders;
  method: IApiMethod;
  params?: IApiParams;
  token?: string;
  url: string;
  withCredentials?: boolean;
  meta?: any;
  cancelToken?: CancelToken;
  signal?: AbortSignal;
}

const API_URL = process.env.REACT_APP__API_BASE_URL || "http://127.0.0.1:8080/api";
const API_SMP_URL = process.env.REACT_APP__API_SMP_BASE_URL || "https://api.smp.io";
const API_VERSION = process.env.REACT_APP__API_VERSION || 0;

// TODO: rename current functions to apiRequest, apiRequestDF, apiRequestSMP
export async function apiRequestBase<IApiRequestResult>({
  data,
  headers,
  method,
  params,
  url,
  withCredentials,
  meta,
  cancelToken,
  signal,
}: IApiRequestParams): Promise<AxiosResponse<IApiRequestResult>> {
  try {
    return await axios({
      cancelToken,
      data,
      headers: {
        ...headers,
      },
      method,
      params,
      signal,
      url,
      withCredentials,
    });
  } catch (r) {
    const error = r as any;

    if (!error.response && error.code !== "ERR_CANCELED") {
      error.response = {
        data: {
          message: t`Please check network connection`,
        },
      };
    } else if ([403, 500].includes(error.response.status)) {
      error.response.data = {
        message: t`Something went wrong. Please contact technical support and we will help you`,
      };
    }

    error.meta = meta;

    throw error;
  }
}

export function apiRequest<IApiRequestResult>({
  accessToken,
  headers = {},
  url,
  ...rest
}: IApiRequestParams): AxiosPromise<IApiRequestResult> {
  if (accessToken) {
    headers.Authorization = `JWT ${accessToken}`;
  }
  return apiRequestBase({
    headers: {
      "Content-type": "application/json",
      ...headers,
    },
    url: `${API_URL}/${API_VERSION}/${url}`,
    ...rest,
  });
}

export function apiSMPRequest<IApiRequestResult>({
  accessToken,
  headers = {},
  url,
  ...rest
}: IApiRequestParams): AxiosPromise<IApiRequestResult> {
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return apiRequestBase({
    headers: {
      ...headers,
    },
    url: `${API_SMP_URL}/${url}`,
    ...rest,
  });
}

export function prepareErrorMessage({ detail, extra, message }: AxiosResponse["data"]): string {
  if (extra && typeof extra === "object") {
    const firstSing = Object.keys(extra)[0];
    return firstSing === "message" ? extra.message : extra[firstSing].message;
  }
  return detail || message || t`Something went wrong`;
}
