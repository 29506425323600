import { TableHeaderRow, TableHeaderRowProps } from "@devexpress/dx-react-grid-material-ui";
import { t } from "@lingui/macro";
import { FC, useCallback } from "react";

const UiDxTableHeaderRow: FC<TableHeaderRowProps> = ({ ...props }) => {
  const renderCell = useCallback(
    (props) => <TableHeaderRow.Cell css={props.column.css} {...props} style={{ fontWeight: "bold" }} />,
    [],
  );

  return (
    <TableHeaderRow
      cellComponent={renderCell}
      css={(props as any)?.column?.css}
      messages={{
        sortingHint: t`Sort`,
      }}
      {...props}
    />
  );
};

export default UiDxTableHeaderRow;
