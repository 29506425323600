import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { FC, useCallback } from "react";
import { EExportStatus } from "src/enums/export";
import { IExport } from "src/types/export";
import useQueryExportDelete from "src/utils/queries/export/useQueryExportDelete";

interface IProps {
  report: IExport;
  onSuccess: (exportId: IExport["id"]) => void;
}

const CellDelete: FC<IProps> = ({ report, onSuccess }) => {
  const { mutate, isLoading } = useQueryExportDelete({
    onSuccess: () => onSuccess(report.id),
  });

  const handleClick = useCallback(() => {
    mutate(report.id);
  }, [mutate, report.id]);

  if (report.status === EExportStatus.STARTED) {
    return null;
  }

  return (
    <IconButton disabled={isLoading} onClick={handleClick}>
      <DeleteIcon />
    </IconButton>
  );
};

export default CellDelete;
