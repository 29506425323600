import { t, Trans } from "@lingui/macro";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Stack } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Page404 from "src/app/Page404/Page404";
import Loader from "src/components/Loader";
import Message from "src/components/Message";
import ModalAccountCredentialsShare from "src/components/ModalAccountCredentialsShare";
import UiButton from "src/components/UiButton";
import { EProjectRole } from "src/enums/project";
import { popupAdd } from "src/redux";
import useQueryProjectAcceptInvite from "src/utils/queries/project/useQueryProjectAcceptInvite";
import useQueryProjectDeclineInvite from "src/utils/queries/project/useQueryProjectDeclineInvite";
import useQueryProjectGet from "src/utils/queries/project/useQueryProjectGet";

const ProjectsAcceptInvite: FC = () => {
  const params = useParams<{ projectId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);
  const { data: project, isLoading: projectIsLoading } = useQueryProjectGet(Number(params.projectId), {
    silent: true,
  });
  const { mutate: accept, isLoading: acceptIsLoading } = useQueryProjectAcceptInvite({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Invite accepted`,
        }),
      );
    },
  });
  const { mutate: decline, isLoading: declineIsLoading } = useQueryProjectDeclineInvite({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Invite declined`,
        }),
      );

      history.replace("/");
    },
  });

  const handleAccept = useCallback(async () => {
    if (project?.role === EProjectRole.ADMIN) {
      setIsOpenShareModal(true);
    } else {
      if (project) {
        accept({ project_id: project.id });
      }
    }
  }, [accept, project]);

  const handleDecline = useCallback(async () => {
    if (project) {
      decline({ project_id: project.id });
    }
  }, [decline, project]);

  const handleCloseShareModal = useCallback(async () => {
    setIsOpenShareModal(false);

    if (project) {
      accept({ project_id: project.id });
    }
  }, [accept, project]);

  if (projectIsLoading) {
    return <Loader />;
  }

  if (!project) {
    return <Page404 />;
  }

  if (project.is_approved) {
    return <Redirect to={{ pathname: "/", state: { projectId: Number(params.projectId) } }} />;
  }

  return (
    <>
      <Message
        icon={<CompareArrowsIcon />}
        title={<Trans id="Accept the invitation" />}
        message={
          <Stack direction="row" spacing={2} justifyContent="center">
            <UiButton loading={declineIsLoading} disabled={acceptIsLoading} color="secondary" onClick={handleDecline}>
              <Trans>Decline</Trans>
            </UiButton>

            <UiButton loading={acceptIsLoading} disabled={declineIsLoading} color="primary" onClick={handleAccept}>
              <Trans>Accept</Trans>
            </UiButton>
          </Stack>
        }
      />

      <ModalAccountCredentialsShare onClose={handleCloseShareModal} projectId={project.id} open={isOpenShareModal} />
    </>
  );
};

export default ProjectsAcceptInvite;
