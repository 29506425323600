import { plural, t, Trans } from "@lingui/macro";
import { Box, Grid, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { IconInfoCircle } from "@tabler/icons";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigFbAds } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

import YandexCounter from "../YandexCounter";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  config: IPipelineConfigFbAds | null;
  onChange: (config: IPipelineConfigFbAds | null) => void;
  onChangeValidation: (isValid: boolean) => void;
  persistState?: {
    [key: string]: any;
  };
}

const ConfigFbAds: FC<IProps> = ({
  projectId,
  credentialId,
  pipelineType,
  onChange,
  onChangeValidation,
  config,
  persistState,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(config?.facebook_ad_account);
  const [isFbAttributesEnabled, setFbAttributesEnabled] = useState(config?.fb_attributes_enabled || false);
  const [isYaCounterEnabled, setYaCounterEnabled] = useState(config?.ya_counter_enabled || false);
  const [actionReportTime, setActionReportTime] = useState<string | null>(config?.action_report_time || "");
  const [actionAttWindows, setActionAttWindows] = useState<string | null>(config?.action_attribution_windows?.join("+") || "");
  const [yaCredential, setYaCredential] = useState(
    config?.extra_settings?.yandex_metrika.account_credentials_id
      ? {
          id: config?.extra_settings?.yandex_metrika.account_credentials_id,
          name: config?.extra_settings?.yandex_metrika.account_credentials_name,
        }
      : undefined,
  );
  const [yaCounter, setYaCounter] = useState(
    config?.extra_settings?.yandex_metrika.counter
      ? {
          id: config?.extra_settings?.yandex_metrika.counter,
          name: config?.extra_settings?.yandex_metrika.counter_name,
        }
      : undefined,
  );

  const { data: pipelineTypes } = useQueryPipelineTypes();

  const {
    data: accounts,
    isFetched: accountsFetched,
    isFetching: accountsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.FACEBOOK_ADS_ACCOUNTS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedAccount) {
          setSelectedAccount(data[0] || null);
        }
      },
    },
  );

  const accountOptions = useMemo(
    () =>
      accounts?.map((account) => ({
        data: account,
        label: `${account.name} (${account.id})`,
        value: account.id,
      })) || [],
    [accounts],
  );

  const getLabelClick = useCallback(
    (value: number) =>
      plural(value, {
        few: "# days",
        many: "# days",
        one: "# day",
        other: "# days",
      }) +
      " " +
      t`after click`,
    [],
  );

  const getLabelView = useCallback(
    (value: number) =>
      plural(value, {
        few: "# days",
        many: "# days",
        one: "# day",
        other: "# days",
      }) +
      " " +
      t`after view ads`,
    [],
  );

  const FB_ATTRIBUTE_WINDOWS_OPTIONS = [
    { label: t`Take from the settings of the advertising account`, value: "" },
    { label: `${getLabelClick(1)} + ${getLabelView(1)}`, value: "1d_view+1d_click" },
    { label: `${getLabelClick(1)} + ${getLabelView(7)}`, value: "7d_view+1d_click" },
    { label: `${getLabelClick(1)} + ${getLabelView(28)}`, value: "28d_view+1d_click" },
    { label: `${getLabelClick(7)} + ${getLabelView(1)}`, value: "1d_view+7d_click" },
    { label: `${getLabelClick(7)} + ${getLabelView(7)}`, value: "7d_view+7d_click" },
    { label: `${getLabelClick(7)} + ${getLabelView(28)}`, value: "28d_view+7d_click" },
    { label: `${getLabelClick(28)} + ${getLabelView(1)}`, value: "1d_view+28d_click" },
    { label: `${getLabelClick(28)} + ${getLabelView(7)}`, value: "7d_view+28d_click" },
    { label: `${getLabelClick(28)} + ${getLabelView(28)}`, value: "28d_view+28d_click" },
  ];

  const FB_REPORT_TIME_OPTIONS = [
    { label: t`Take from the settings of the advertising account`, value: "" },
    { label: t`Impression`, value: "impression" },
    { label: t`Conversion`, value: "conversion" },
    { label: t`Mixed`, value: "mixed" },
  ];

  const handleSelectPage = useCallback(
    (value: typeof accountOptions[number]["value"] | null, data: typeof accountOptions[number]["data"] | null) => {
      if (data) {
        setSelectedAccount(data);
      }
    },
    [],
  );

  const handleToggleFbAttributes = useCallback(() => {
    setFbAttributesEnabled((state) => !state);
  }, []);

  useEffect(() => {
    onChangeValidation(
      (() => {
        if (isYaCounterEnabled && !yaCounter?.id) {
          return false;
        }

        return Boolean(selectedAccount);
      })(),
    );

    onChange({
      ...(isFbAttributesEnabled
        ? {
            action_attribution_windows: actionAttWindows
              ? (actionAttWindows?.split("+") as IPipelineConfigFbAds["action_attribution_windows"])
              : undefined,
            action_report_time: (actionReportTime as IPipelineConfigFbAds["action_report_time"]) || undefined,
          }
        : {}),
      extra_settings: isYaCounterEnabled
        ? {
            yandex_metrika: {
              account_credentials_id: yaCredential?.id,
              account_credentials_name: yaCredential?.name,
              counter: yaCounter?.id,
              counter_name: yaCounter?.name,
            },
          }
        : undefined,
      facebook_ad_account: selectedAccount || undefined,
      facebook_ad_account_id: selectedAccount?.["id"],
      fb_attributes_enabled: isFbAttributesEnabled,
      ya_counter_enabled: isYaCounterEnabled,
    });
  }, [
    onChangeValidation,
    onChange,
    selectedAccount,
    isFbAttributesEnabled,
    actionReportTime,
    actionAttWindows,
    yaCredential,
    yaCounter,
    isYaCounterEnabled,
  ]);

  return (
    <>
      {accountOptions.length === 0 && accountsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            <UiSelect
              isSearchable={accountOptions.length > 7}
              isLoading={accountsIsFetching}
              value={selectedAccount?.id}
              onChange={handleSelectPage}
              options={accountOptions}
              placeholder={<Trans>Ad account</Trans>}
              fullWidth
            />

            <Tooltip
              title={
                <Trans>
                  If you don't see the page you need in the list of available pages, please check your access level. Your role
                  in the community should be Editor, Moderator or Administrator
                </Trans>
              }
            >
              <span>
                <IconInfoCircle />
              </span>
            </Tooltip>
          </Stack>

          <Box mt={3}>
            <Box mb={1}>
              <FormControlLabel
                control={<Checkbox checked={isFbAttributesEnabled} onChange={handleToggleFbAttributes} color="primary" />}
                label={
                  <Trans>
                    Specify the Facebook attribution parameters (if you do not select it, the settings from the advertising
                    account will be used)
                  </Trans>
                }
              />
            </Box>

            {isFbAttributesEnabled && (
              <Grid container spacing={2}>
                <Grid item xs>
                  <UiSelect
                    label={<Trans>Attribution Windows</Trans>}
                    value={actionAttWindows}
                    onChange={setActionAttWindows}
                    options={FB_ATTRIBUTE_WINDOWS_OPTIONS}
                    fullWidth
                  />
                </Grid>

                <Grid item xs>
                  <UiSelect
                    label={<Trans>Action Report Time</Trans>}
                    value={actionReportTime}
                    onChange={setActionReportTime}
                    options={FB_REPORT_TIME_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Box>

          {pipelineTypes?.[EPipelineType.YA_METRIKA] && (
            <YandexCounter
              enabled={isYaCounterEnabled}
              credential={yaCredential}
              counter={yaCounter}
              projectId={projectId}
              onToggle={setYaCounterEnabled}
              onChangeCred={setYaCredential}
              onChangeCounter={setYaCounter}
              persistState={{
                ...persistState,
                config,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConfigFbAds;
