import { Trans } from "@lingui/macro";
import { Alert, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import Loader from "src/components/Loader";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { ICredential, ICredentialMeta } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigInstaPublic } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import ModalFacebookInstruction from "./components/ModalFacebookInstruction";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigInstaPublic | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigInstaPublic: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: user } = useQueryMe();
  const [selectedInstaAccount, setSelectedInstaAccount] = useState<IPipelineConfigInstaPublic["instagram_account"] | null>(
    null,
  );
  const [selectedFbPage, setSelectedFbPage] = useState<IPipelineConfigInstaPublic["facebook_page"] | null>(null);
  const [selectedAdsAccount, setSelectedAdsAccount] = useState<
    ICredentialMeta[ECredentialMetaObject.FACEBOOK_ADS_ACCOUNTS][number] | null
  >(null);

  const [isOpenManualModal, setOpenManualModal] = useState(false);
  const [isFbAdsChecked, setFbAdsChecked] = useState(false);
  const {
    data: instagramAccounts,
    isFetched: instagramAccountsFetched,
    isFetching: instagramAccountsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.INSTAGRAM_ACCOUNTS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (data[0]) {
          if (!selectedInstaAccount) {
            setSelectedInstaAccount(data[0].instagram_account || null);
          }

          if (!selectedFbPage) {
            setSelectedFbPage(data[0].facebook_page || null);
          }
        }
      },
    },
  );

  const {
    data: adsAccounts,
    isFetched: adsAccountsFetched,
    isFetching: adsAccountsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.FACEBOOK_ADS_ACCOUNTS,
      projectId,
    },
    {
      enabled: isFbAdsChecked,
      onSuccess: (data) => {
        if (!selectedAdsAccount) {
          setSelectedAdsAccount(data[0] || null);
        }
      },
    },
  );

  const {
    data: fbPermissionWebhook,
    isFetching: fbPermissionWebhookFetching,
    isFetched: fbPermissionWebhookFetched,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      extraParams: {
        facebook_page_id: selectedFbPage?.id,
      },
      object: ECredentialMetaObject.FACEBOOK_PAGE_PERMISSION_WEBHOOK,
      projectId,
    },
    {
      enabled: Boolean(selectedFbPage),
    },
  );

  const instagramAccountOptions = useMemo(
    () =>
      instagramAccounts?.map(({ instagram_account, facebook_page }) => ({
        data: {
          facebook_page,
          instagram_account,
        },
        label: `${instagram_account.instagram_business_account_name} (${instagram_account.instagram_business_account_id})`,
        value: instagram_account.instagram_business_account_id,
      })) || [],
    [instagramAccounts],
  );

  const adsAccountOptions = useMemo(
    () =>
      adsAccounts?.map((account) => ({
        data: account,
        label: `${account.name} (${account.id})`,
        value: account.id,
      })) || [],
    [adsAccounts],
  );

  const handleSelectInstaAccount = useCallback(
    (value: string | null, data: typeof instagramAccountOptions[number]["data"] | null) => {
      if (data) {
        setSelectedInstaAccount(data.instagram_account);
        setSelectedFbPage(data.facebook_page);
      }
    },
    [],
  );

  const handleSelectAdsAccount = useCallback((value: string | null, data: typeof adsAccountOptions[number]["data"] | null) => {
    if (data) {
      setSelectedAdsAccount(data);
    }
  }, []);

  const handleCloseManualModal = useCallback(() => {
    setOpenManualModal(false);
  }, []);

  const handleOpenManualModal = useCallback(() => {
    setOpenManualModal(true);
  }, []);

  const handleToggleFbAdsChecked = useCallback(() => {
    setFbAdsChecked((state) => !state);
  }, []);

  useEffect(() => {
    onChangeValidation(Boolean(selectedFbPage && selectedInstaAccount && (!isFbAdsChecked || selectedAdsAccount)));

    onChange({
      extra_settings:
        isFbAdsChecked && selectedAdsAccount
          ? {
              facebook_ad_account_id: selectedAdsAccount.id,
              facebook_ad_account_name: selectedAdsAccount.name,
            }
          : undefined,
      facebook_page: selectedFbPage || undefined,
      facebook_page_id: selectedFbPage?.id,
      instagram_account: selectedInstaAccount || undefined,
      instagram_account_id: selectedInstaAccount?.instagram_business_account_id,
    });
  }, [selectedFbPage, isFbAdsChecked, selectedAdsAccount, onChangeValidation, selectedInstaAccount, onChange]);

  return (
    <>
      {instagramAccountOptions.length === 0 && instagramAccountsFetched ? (
        <>
          <Alert severity="info">
            <Trans>
              You may not have a business account or an Instagram author account enabled. If this is not the case, you need to
              update your Facebook connection, as shown in the{" "}
              <Link underline="always" color="inherit" href="#" onClick={handleOpenManualModal}>
                video instructions
              </Link>
              .
            </Trans>
          </Alert>

          {(!pipelineTypes?.[EPipelineType.INST_OPEN_ACCOUNT].onlyStaff || user?.is_staff) && (
            <UiButton
              sx={{ mt: 3 }}
              component={LinkRouter}
              to={{
                pathname: `/dataset-create/${EPipelineType.INST_OPEN_ACCOUNT}/`,
                state: {
                  activeStep: 2,
                  newCredential: {
                    id: credentialId,
                  },
                  projectId: projectId,
                },
              }}
            >
              <Trans>Perform an express account analysis</Trans>
            </UiButton>
          )}
        </>
      ) : (
        <UiSelect
          isSearchable={instagramAccountOptions.length > 7}
          isLoading={instagramAccountsIsFetching}
          value={selectedInstaAccount?.instagram_business_account_id}
          onChange={handleSelectInstaAccount}
          options={instagramAccountOptions}
          placeholder={<Trans>Instagram account</Trans>}
          fullWidth
        />
      )}

      {selectedInstaAccount && (
        <Box mt={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={isFbAdsChecked} onChange={handleToggleFbAdsChecked} color="primary" />}
              label={<Trans>Specify your Facebook advertising account to collect statistics from Instagram ad posts</Trans>}
            />
          </Grid>
        </Box>
      )}

      {isFbAdsChecked && (
        <Box mt={3}>
          {adsAccountOptions.length === 0 && adsAccountsFetched ? (
            <NoDataAlert pipelineType={pipelineType} />
          ) : (
            <UiSelect
              isLoading={adsAccountsIsFetching}
              value={selectedAdsAccount?.id}
              onChange={handleSelectAdsAccount}
              options={adsAccountOptions}
              placeholder={<Trans>Ad account</Trans>}
              fullWidth
            />
          )}
        </Box>
      )}

      {selectedFbPage &&
        (fbPermissionWebhookFetching || !fbPermissionWebhookFetched ? (
          <Box mt={2}>
            <Loader autoHeight />
          </Box>
        ) : (
          <Box mt={3}>
            {fbPermissionWebhook && !fbPermissionWebhook.is_can_subscribe ? (
              <Alert severity="info">{fbPermissionWebhook.message}</Alert>
            ) : (
              <Alert severity="info">
                <Trans>
                  DataFan saves data for you on Instagram Stories. You will have access to all history data from the moment this
                  dataset is created
                </Trans>
              </Alert>
            )}
          </Box>
        ))}

      <ModalFacebookInstruction isOpen={isOpenManualModal} onClose={handleCloseManualModal} />
    </>
  );
};

export default ConfigInstaPublic;
