import { Trans } from "@lingui/macro";
import { DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";

interface IProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DialogRestart: FC<IProps> = ({ isOpen, isLoading, onClose, onSubmit }) => (
  <UiDialog open={isOpen} title={<Trans>Full restart</Trans>} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogContent>
      <Typography variant="body2">
        <Trans>Data will be re-collected for the days when the error occurred.</Trans>
      </Typography>
    </DialogContent>

    <DialogActions>
      <UiButton disabled={isLoading} onClick={onClose} variant="text" color="primary">
        <Trans>Cancel</Trans>
      </UiButton>

      <UiButton loading={isLoading} onClick={onSubmit} color="primary">
        <Trans>Ok</Trans>
      </UiButton>
    </DialogActions>
  </UiDialog>
);

export default DialogRestart;
