import { Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { useAppContext } from "src/context/app/appContext";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import AccountMenu from "./components/AccountMenu";
import DynamicContent from "./components/DynamicContent";
import Notifications from "./components/Notifications";
import SidebarButton from "./components/SidebarButton";
import * as classes from "./index.css";

export const HEADER_DYNAMIC_ID = "header-dynamic-content";

const Header: FC = () => {
  const { data: user } = useQueryMe();
  const {
    state: { context },
  } = useAppContext();

  return (
    <>
      <AppBar css={classes.appBar}>
        <Grid container css={classes.toolbar}>
          {context !== "oauth2" && user?.id && (
            <Grid item>
              <SidebarButton />
            </Grid>
          )}

          <Grid item xs id={HEADER_DYNAMIC_ID} css={classes.dynamicSection} />

          <Grid item>
            <Stack direction="row" css={classes.stack}>
              {context !== "oauth2" && user && <Notifications />}

              <AccountMenu />
            </Stack>
          </Grid>
        </Grid>
      </AppBar>

      <div css={classes.toolbar} />
    </>
  );
};

export default Header;

export { DynamicContent as HeaderDynamicContent };
