import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DatasetSingleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M20,7H9A1,1,0,0,1,9,5H20a1,1,0,0,1,0,2Z" />
      <path d="M20,13H9a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z" />
      <path d="M20,19H9a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z" />
      <path d="M5,7A1,1,0,1,1,6,6H6A1,1,0,0,1,5,7Z" />
      <path d="M5,13a1,1,0,1,1,1-1H6A1,1,0,0,1,5,13Z" />
      <path d="M5,19a1,1,0,1,1,1-1H6A1,1,0,0,1,5,19Z" />
    </SvgIcon>
  );
}
