import { ITmpl } from "src/types/tmpl";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

export default function useQueryTmplGet(tmplId: ITmpl["id"] | undefined, queryOptions?: IUseQueryBaseOptions<ITmpl>) {
  return useQueryBase<ITmpl>(
    ["tmpl", tmplId],
    {
      enabled: typeof tmplId !== "undefined",
      ...queryOptions,
    },
    {
      method: "GET",
      url: `tmpls/${tmplId}/`,
    },
  );
}
