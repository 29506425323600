import { Trans } from "@lingui/macro";
import { Box, CircularProgress, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { saveAs } from "file-saver";
import { FC, useCallback, useState } from "react";
import { EExportStatus } from "src/enums/export";
import { IExport } from "src/types/export";
import useQueryExportUrl from "src/utils/queries/export/useQueryExportUrl";

interface IProps {
  report: IExport;
}

const CellDownload: FC<IProps> = ({ report }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const { isFetching: exportUrlFetching, remove: exportUrlRemove } = useQueryExportUrl(report.id, {
    enabled: downloadEnabled,
    onSuccess: (exportUrl) => {
      if (downloadEnabled) {
        setDownloadEnabled(false);
        saveAs(exportUrl, report.name);
        exportUrlRemove();
      }
    },
    queryHash: "cell-download",
  });

  const handleClick = useCallback(() => {
    setDownloadEnabled(true);
  }, []);

  if (report.status === EExportStatus.STARTED) {
    return (
      <Box pt={0.8} pb={0.8}>
        <CircularProgress size={19} />
      </Box>
    );
  } else if (report.status === EExportStatus.FAILURE) {
    return <Typography color="neutral">{report.name}</Typography>;
  } else {
    return (
      <Link
        component="span"
        sx={{
          cursor: "pointer",
          pointerEvents: exportUrlFetching ? "none" : "inherit",
        }}
        onClick={handleClick}
      >
        {isMobile ? <Trans>Download</Trans> : report.name}
      </Link>
    );
  }
};

export default CellDownload;
