import { t, Trans } from "@lingui/macro";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WarningIcon from "@mui/icons-material/Warning";
import { MobileStepper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { detect } from "detect-browser";
import { FC, useCallback, useMemo, useState } from "react";
import { UiSocialButton } from "src/components/UiSocialButton";
import { useAppContext } from "src/context/app/appContext";

import useStyles from "./index.css";

const translatorTutorial: {
  [browser: string]: string;
} = {
  chrome: "/manuals/translate_chrome_{lang}.jpg",
  yandexbrowser: "/manuals/translate_yandex_{lang}.jpg",
};

const cacheTutorialSteps: {
  [browser: string]: string[];
} = {
  chrome: ["/manuals/cache_chrome_{lang}_1.jpg", "/manuals/cache_chrome_{lang}_2.jpg"],
  edge: ["/manuals/cache_edge_{lang}_1.jpg", "/manuals/cache_edge_{lang}_2.jpg", "/manuals/cache_edge_{lang}_3.jpg"],
  firefox: [
    "/manuals/cache_firefox_{lang}_1.jpg",
    "/manuals/cache_firefox_{lang}_2.jpg",
    "/manuals/cache_firefox_{lang}_3.jpg",
  ],
  ie: ["/manuals/cache_ie_{lang}_1.jpg", "/manuals/cache_ie_{lang}_2.jpg"],
  opera: ["/manuals/cache_opera_{lang}_1.jpg", "/manuals/cache_opera_{lang}_2.jpg"],
  safari: ["/manuals/cache_safari_{lang}_1.jpg", "/manuals/cache_safari_{lang}_2.jpg", "/manuals/cache_safari_{lang}_3.jpg"],
  yandexbrowser: ["/manuals/cache_ya_{lang}_1.jpg", "/manuals/cache_ya_{lang}_2.jpg"],
};

const ErrorBoundary: FC = () => {
  const classes = useStyles();
  const {
    state: { language },
  } = useAppContext();
  const browser = detect();

  const [displayTranslator, setDisplayTranslator] = useState(false);
  const [displayCache, setDisplayCache] = useState(false);
  const [activeTutorialStep, setActiveTutorialStep] = useState(0);

  const handleNextTutorialStep = useCallback(() => {
    setActiveTutorialStep((step) => step + 1);
  }, []);

  const handlePrevTutorialStep = useCallback(() => {
    setActiveTutorialStep((step) => step - 1);
  }, []);

  const handleToggleTranslator = useCallback(() => {
    setDisplayTranslator((step) => !step);
  }, []);

  const handleToggleCache = useCallback(() => {
    setActiveTutorialStep(0);
    setDisplayCache((step) => !step);
  }, []);

  const hasTranslatorTutorial = useMemo(() => browser?.name && ["chrome", "yandexbrowser"].includes(browser.name), [browser]);

  const hasCacheTutorial = useMemo(
    () => browser?.name && ["chrome", "yandexbrowser", "edge", "firefox", "ie", "opera", "safari"].includes(browser.name),
    [browser],
  );

  const cacheSteps = browser?.name && cacheTutorialSteps[browser.name];

  return (
    <Container maxWidth="sm" className={classes.container} disableGutters>
      <Paper elevation={3} className={classes.paper}>
        <Box textAlign="center" mb={2}>
          <Avatar className={classes.avatar}>
            <WarningIcon />
          </Avatar>
        </Box>

        <Box textAlign="center" mb={2}>
          <Typography variant="h6">
            <Trans>Something went wrong. Do not worry!</Trans>
          </Typography>
        </Box>

        <ol className={classes.list}>
          <li>
            <Trans>Turn Off translation in your browser.</Trans>{" "}
            {hasTranslatorTutorial && (
              <Link component="div" onClick={handleToggleTranslator} className={classes.link}>
                <Trans>How-to</Trans>.
              </Link>
            )}
            {displayTranslator && browser?.name && (
              <Box mt={2} className={classes.tutorial}>
                <div className={classes.image}>
                  <img
                    src={
                      process.env.REACT_APP__BASE_BUCKET_STORAGE + translatorTutorial[browser.name].replace("{lang}", language)
                    }
                    alt={t`Switch off translator`}
                  />
                </div>
              </Box>
            )}
          </li>
          <li>
            <Trans>Clear cache in your browser.</Trans>{" "}
            {hasCacheTutorial && (
              <Link component="div" onClick={handleToggleCache} className={classes.link}>
                <Trans>How-to</Trans>.
              </Link>
            )}
            {displayCache && cacheSteps && (
              <Box mt={2} className={classes.tutorial}>
                <div className={classes.image}>
                  <img
                    src={
                      process.env.REACT_APP__BASE_BUCKET_STORAGE + cacheSteps[activeTutorialStep].replace("{lang}", language)
                    }
                    alt={t`Step` + " " + activeTutorialStep}
                  />
                </div>

                <MobileStepper
                  className={classes.stepper}
                  steps={cacheSteps.length}
                  position="static"
                  variant="text"
                  activeStep={activeTutorialStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNextTutorialStep}
                      disabled={activeTutorialStep === cacheSteps.length - 1}
                    >
                      <Trans>Next Step</Trans>
                      <KeyboardArrowRightIcon />
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handlePrevTutorialStep} disabled={activeTutorialStep === 0}>
                      <KeyboardArrowLeftIcon />
                      <Trans>Previous Step</Trans>
                    </Button>
                  }
                />
              </Box>
            )}
          </li>
        </ol>

        <Box mt={2}>
          <Typography>
            <Trans>
              If this error persists, please write to us in the technical support{" "}
              <Link href="mailto:support@datafan.pro">support@datafan.pro</Link>, and we will definitely help you.
            </Trans>
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography>
            <Trans>Or you can contact us on social networks</Trans>
          </Typography>

          <Grid container spacing={2}>
            <Grid item>
              <UiSocialButton href="https://vk.com/datafan" socialMedium="vk" socialLabel={<Trans>VK</Trans>} />
            </Grid>
            <Grid item>
              <UiSocialButton href="https://t.me/datafan" socialMedium="telegram" socialLabel={<Trans>Telegram</Trans>} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default ErrorBoundary;
