import { Trans } from "@lingui/macro";
import { Alert, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FC, useCallback, useMemo, useState } from "react";
import SelectAccountCredential from "src/components/SelectAccountCredential";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  enabled: boolean;
  credential:
    | {
        id: number;
        name: string | undefined;
      }
    | undefined;
  counter:
    | {
        id: number;
        name: string | undefined;
      }
    | undefined;
  goals?: Array<{
    id: number;
    name: string | undefined;
    prev_goal_id: number;
    type: string;
    is_retargeting: 0 | 1;
  }>;
  projectId: IProject["id"];
  onToggle: (enabled: boolean) => void;
  onChangeCred: (cred: IProps["credential"]) => void;
  onChangeCounter: (counter: IProps["counter"]) => void;
  onChangeGoals?: (goals: IProps["goals"]) => void;
  persistState?: {
    [key: string]: any;
  };
}

const YandexCounter: FC<IProps> = ({
  enabled,
  projectId,
  credential,
  counter,
  onChangeCred,
  onChangeCounter,
  onToggle,
  persistState,
  onChangeGoals,
  goals,
}) => {
  const [isGoalFetchingEnabled, setGoalFetchingEnabled] = useState(false);
  const {
    data: yaCounters,
    isFetched: yaCountersFetched,
    isFetching: yaCountersIsLoading,
  } = useQueryCredentialMetaGet(
    {
      credentialId: credential?.id || 0,
      object: ECredentialMetaObject.YA_METRIKA_COUNTERS,
      projectId,
    },
    {
      enabled: Boolean(credential?.id),
      onSuccess: (data) => {
        setGoalFetchingEnabled(true);

        if (data[0]) {
          onChangeCounter({
            id: data[0].id,
            name: data[0].name,
          });
        }
      },
    },
  );

  const {
    data: allGoals,
    isFetched: goalsFetched,
    isFetching: goalsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId: credential?.id || 0,
      extraParams: {
        counter_id: counter?.id,
      },
      object: ECredentialMetaObject.YA_METRIKA_GOALS,
      projectId,
    },
    {
      enabled: Boolean(counter?.id) && isGoalFetchingEnabled,
      onSuccess: (data) => {
        if (data[0] && onChangeGoals) {
          onChangeGoals([data[0]]);
        }
      },
    },
  );

  const yaCounterOptions = useMemo(
    () =>
      yaCounters?.map((counter) => ({
        data: counter,
        label: counter.name,
        value: counter.id,
      })) || [],
    [yaCounters],
  );

  const goalOptions = useMemo(
    () =>
      allGoals?.map((goal) => ({
        data: goal,
        label: goal.name,
        value: goal.id,
      })) || [],
    [allGoals],
  );

  const handleToggleYaCounter = useCallback(() => {
    onToggle(!enabled);
  }, [enabled, onToggle]);

  const handleChangeYaCredential = useCallback(
    (cred?: ICredential) => {
      setGoalFetchingEnabled(false);

      onChangeCred(
        cred
          ? {
              id: cred.id,
              name: cred.name,
            }
          : undefined,
      );
    },
    [onChangeCred],
  );

  const handleChangeYaCounter = useCallback(
    (value: typeof yaCounterOptions[number]["value"] | null, data: typeof yaCounterOptions[number]["data"] | null) => {
      if (data) {
        onChangeCounter({
          id: data.id,
          name: data.name,
        });
      }
    },
    [onChangeCounter],
  );

  const handleChangeGoal = useCallback(
    (value: typeof goalOptions[number]["value"] | null, data: typeof goalOptions[number]["data"] | null) => {
      if (data && onChangeGoals) {
        onChangeGoals([data]);
      }
    },
    [onChangeGoals],
  );

  return (
    <Box mt={3}>
      <Box mb={1}>
        <FormControlLabel
          control={<Checkbox checked={enabled} onChange={handleToggleYaCounter} color="primary" />}
          label={<Trans>Specify a Yandex.Metrika counter to see the traffic metrics that led to your ad campaigns</Trans>}
        />
      </Box>

      {enabled && (
        <>
          <Alert severity="info" sx={{ mb: 2 }}>
            <Trans>Yandex.Metrika data are available for the last 3 months</Trans>
          </Alert>

          <SelectAccountCredential
            justifyContent="flex-start"
            value={credential?.id}
            projectId={projectId}
            source={EPipelineType.YA_METRIKA}
            onChange={handleChangeYaCredential}
            persistState={persistState}
            label={<Trans>Select Yandex account</Trans>}
          />
        </>
      )}

      {enabled && credential && (
        <Box mt={2}>
          {yaCounterOptions.length === 0 && yaCountersFetched ? (
            <Alert severity="info">
              <Trans>There are no counters</Trans>
            </Alert>
          ) : (
            <UiSelect
              isSearchable={yaCounterOptions.length > 7}
              isLoading={yaCountersIsLoading}
              value={counter?.id}
              onChange={handleChangeYaCounter}
              options={yaCounterOptions}
              placeholder={<Trans>Counter</Trans>}
              fullWidth
              label={<Trans>Select counter</Trans>}
            />
          )}
        </Box>
      )}

      {enabled && credential && onChangeGoals && (
        <Box mt={2}>
          {goalsFetched && (
            <UiSelect
              isLoading={goalsIsFetching}
              value={goals?.[0]?.id}
              onChange={handleChangeGoal}
              options={goalOptions}
              placeholder={<Trans>Goal</Trans>}
              label={<Trans>Select a goal</Trans>}
              fullWidth
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default YandexCounter;
