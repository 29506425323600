import { ICredentialVkGroup } from "src/types/credential";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  vkGroupId: number;
}

export default function useQueryCredentialVkGroup(queryOptions?: IUseQueryMutationOptions<ICredentialVkGroup, IInput>) {
  return useMutationBase<ICredentialVkGroup, IInput>(queryOptions, {
    method: "GET",
    params: () => undefined,
    url: ({ vkGroupId }) => `account-credentials/vk-group-token/${vkGroupId}/`,
  });
}
