import { Stack } from "@mui/material";
import { FC } from "react";
import { components } from "react-select";
import MediaFavicon from "src/components/MediaFavicon";
import { IProject } from "src/types/project";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

const SelectboxChip: FC<any> = (props) => {
  const pipeline = props.data.data as IProject["pipelines"][number];
  const { data: pipelineTypes } = useQueryPipelineTypes();

  return (
    <components.MultiValueContainer {...props}>
      <Stack direction="row" spacing={1}>
        {pipelineTypes?.[pipeline.source] && <MediaFavicon media={pipelineTypes[pipeline.source].media} />}
        <div>{pipeline.name}</div>
      </Stack>
    </components.MultiValueContainer>
  );
};

export default SelectboxChip;
