import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const form = (theme: Theme) => css`
  margin-top: ${theme.spacing(1)};
  width: 100%;
`;

export const link = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
`;
