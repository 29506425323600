import { useQueryClient } from "react-query";
import { InvalidateQueryFilters } from "react-query/types/core/types";
import { IResponseBasePagination } from "src/types/response";
import { ITelegramNotificationEntity } from "src/types/telegramNotification";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "telegram-notifications-get";

export default function useQueryTelegramNotification(
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<ITelegramNotificationEntity>>,
) {
  return useQueryBase<IResponseBasePagination<ITelegramNotificationEntity>>(
    [QUERY_KEY],
    {
      ...queryOptions,
    },
    {
      fetchAllPages: true,
      method: "GET",
      params: {
        limit: 1000,
        offset: 0,
      },
      url: "telegram_notifications/status/",
    },
  );
}

export function useQueryTelegramNotificationInvalidate() {
  const queryClient = useQueryClient();

  return (filters?: InvalidateQueryFilters) => queryClient.invalidateQueries(QUERY_KEY, filters);
}
