import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const paper = (theme: Theme) => css`
  background: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(4)};
  text-align: center;

  a {
    color: ${theme.palette.primary.contrastText};
  }
`;
