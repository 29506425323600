import { Trans } from "@lingui/macro";
import { Link, useTheme } from "@mui/material";
import { IconLockAccess } from "@tabler/icons";
import { FC } from "react";
import Message from "src/components/Message";

const Index: FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Message
      icon={<IconLockAccess size={64} color={theme.palette.secondary.main} />}
      title={403}
      message={
        <>
          {children || (
            <>
              <Trans>Go back to the</Trans>{" "}
              <Link href="/" style={{ textDecoration: "none" }}>
                <Trans>Reports</Trans>
              </Link>
            </>
          )}
        </>
      }
    />
  );
};

export default Index;
