import { css } from "@emotion/react/macro";

export const block = css`
  background: #f6f6f6;
  border-radius: 8px;
  color: #ffffff;
  position: relative;
  font-weight: 600;
  padding-top: 100%;
`;

export const block_size_large = css`
  width: 160px;
  font-size: 90px;
`;

export const block_responsive = css`
  width: 100%;
`;

export const block_size_small = css`
  width: 55px;
  font-size: 30px;
`;

export const block_size_extraSmall = css`
  width: 40px;
  font-size: 24px;
`;

export const block_media_vk = css`
  background: #2787f5;
`;

export const block_media_facebook = css`
  background: #3b5999;
`;

export const block_media_google = css`
  background: #f6f6f6;
  color: #000000;
`;

export const block_media_ok = css`
  background: #ff9800;
`;

export const block_media_instagram = css`
  background: #dd2a7b;
`;

export const block_media_mytarget = css`
  background: #fa2c38;
`;

export const block_media_yandex = css`
  background: #fc3f1d;
`;

export const block_media_datafan = css`
  background: #f1f6f8;
  color: #000000;
`;

export const block_media_tiktok = css`
  background: #000000;
`;

export const block_media_smmplanner = css`
  background: #172b3e;
`;

export const block_media_telegram = css`
  background: #229ed9;
`;

export const img = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const placeholder = css`
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const favicon = css`
  position: absolute;
  width: 24px;
  height: 24px;

  > img {
    display: block;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #ffffff;
  }
`;

export const favicon_size_large = css`
  margin: auto;
  bottom: -12px;
  left: 0;
  right: 0;
`;

export const favicon_size_small = css`
  bottom: -2px;
  right: -2px;
`;
