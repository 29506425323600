import { IStream, IStreamMapping } from "src/types/stream";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

export default function useQueryStreamMapping(
  streamId: IStream["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IStreamMapping[]>,
) {
  return useQueryBase<IStreamMapping[]>(
    ["stream-mapping", streamId],
    {
      enabled: typeof streamId !== "undefined",
      ...queryOptions,
    },
    {
      method: "GET",
      url: `stream/${streamId}/mapping/0/`,
    },
  );
}
