import { Sorting } from "@devexpress/dx-react-grid";
import { EPipelineType } from "src/enums/pipeline";
import { EStreamReportStatus } from "src/enums/stream";
import { IPipelineMappingItem, IPipelineMeta, IPipelineType } from "src/types/pipeline";
import { IProgress } from "src/types/progress";
import { IProject } from "src/types/project";
import { IStream } from "src/types/stream";
import { COUNT, EDITING, FILTERED, IFiltered, LOADING, PAGE, PAGE_SIZE, SORTED, UPDATING } from "src/utils";

export enum EPipelineStatus {
  DATA_COLLECTION = 0,
  IS_COMPLETED = 1,
  DELETED = 2,
  ARCHIVED = 3,
  CREDENTIAL_LOST = 4,
}

export enum EPipelineExportStatus {
  STARTED = 1,
  SUCCESS = 2,
  FAILURE = 3,
  REPEAT = 4,
}

export enum EPipelineSubType {
  ADS = "ADS",
  SINGLEPIPELINE = "SINGLEPIPELINE",
  MULTIPIPELINE = "MULTIPIPELINE",
  MERGE = "MERGE",
}

export interface IStreamReportStatus {
  id: number;
  status: null | EStreamReportStatus;
  first_date: string | null;
  last_date: string | null;
  first_date_request: string | null;
  progress?: IProgress;
}

export interface IPipeline {
  id: number;
  name: string;
  project: IProject["id"];
  source: EPipelineType;
  report_status: IStreamReportStatus[];
  run_delta: number;
  total_problem: number;
  streams: IStream[];
  streams_merge?: IStream[];
  status: EPipelineStatus;
  backend_datetime: string;
  update_at: string;
  user: number;
  is_first_run: boolean;
  is_use_in_merge: boolean;
  pipeline_meta: Array<IPipelineMeta>;
}

export interface IPipelineExport {
  id: number;
  status: EPipelineExportStatus;
  name: string;
  _type: number;
  [LOADING]?: boolean;
}

export interface IPipelineReport {
  id: number;
  created_at: any;
  sync_datetime: any;
  status: EStreamReportStatus;
  row_count: number;
  update_count: number;
  stream_source: EPipelineType;
}

export interface IPipelinesState {
  pipelinesCurrentState: {
    [LOADING]: boolean;
    [EDITING]: boolean;
    model?: IPipeline;
  };
  pipelinesDashboardState: {
    [COUNT]?: number;
    [LOADING]: boolean;
    [UPDATING]: boolean;
    [PAGE]: number;
    [PAGE_SIZE]: number;
    [SORTED]?: Sorting[];
    [FILTERED]?: IFiltered[];
    models?: IPipeline[];
  };
  pipelinesExportsState: {
    [COUNT]?: number;
    [LOADING]: boolean;
    [UPDATING]: boolean;
    [PAGE]: number;
    [PAGE_SIZE]: number;
    [SORTED]?: Sorting[];
    models?: IPipelineExport[];
  };
  pipelinesItemsState: {
    [COUNT]?: number;
    [LOADING]: boolean;
    [UPDATING]: boolean;
    [PAGE]: number;
    [PAGE_SIZE]: number;
    [SORTED]?: Sorting[];
    [FILTERED]?: IFiltered[];
    models?: IPipeline[];
  };
  pipelinesMappingState: {
    [LOADING]: boolean;
    models?: IPipelineMappingItem[];
    modelsNormalized?: {
      [key in IPipelineMappingItem["name"]]: IPipelineMappingItem;
    };
  };
  pipelinesMergeState: {
    [key in EPipelineSubType.ADS | EPipelineSubType.MULTIPIPELINE]: {
      [LOADING]: boolean;
      [COUNT]: number;
      models: IPipeline[];
    };
  };
  pipelinesReportsState: {
    [COUNT]?: number;
    [LOADING]: boolean;
    [PAGE]: number;
    [PAGE_SIZE]: number;
    [SORTED]?: Sorting[];
    models: IPipelineReport[];
  };
  pipelinesSamesState: {
    [COUNT]?: number;
    [LOADING]: boolean;
    [PAGE]: number;
    [PAGE_SIZE]: number;
    [SORTED]?: Sorting[];
    models?: IPipeline[];
  };
  pipelinesTypesState: {
    [LOADING]: boolean;
    models?: IPipelineType[];
    modelsNormalized?: {
      [key in EPipelineType]: IPipelineType;
    };
  };
}

export const pipelinesState: IPipelinesState = {
  pipelinesCurrentState: {
    [EDITING]: false,
    [LOADING]: false,
    model: undefined,
  },
  pipelinesDashboardState: {
    [COUNT]: undefined,
    [FILTERED]: [],
    [LOADING]: true,
    [PAGE]: 0,
    [PAGE_SIZE]: 10,
    [SORTED]: [{ columnName: "id", direction: "desc" }],
    [UPDATING]: false,
    models: [],
  },
  pipelinesExportsState: {
    [COUNT]: undefined,
    [LOADING]: true,
    [PAGE]: 0,
    [PAGE_SIZE]: 10,
    [SORTED]: [{ columnName: "id", direction: "desc" }],
    [UPDATING]: false,
    models: undefined,
  },
  pipelinesItemsState: {
    [COUNT]: undefined,
    [FILTERED]: [],
    [LOADING]: true,
    [PAGE]: 0,
    [PAGE_SIZE]: 10,
    [SORTED]: [{ columnName: "id", direction: "desc" }],
    [UPDATING]: false,
    models: [],
  },
  pipelinesMappingState: {
    [LOADING]: false,
    models: undefined,
    modelsNormalized: undefined,
  },
  pipelinesMergeState: {
    [EPipelineSubType.ADS]: {
      [COUNT]: 0,
      [LOADING]: false,
      models: [],
    },
    [EPipelineSubType.MULTIPIPELINE]: {
      [COUNT]: 0,
      [LOADING]: false,
      models: [],
    },
  },
  pipelinesReportsState: {
    [COUNT]: undefined,
    [LOADING]: false,
    [PAGE]: 0,
    [PAGE_SIZE]: 10,
    models: [],
  },
  pipelinesSamesState: {
    [COUNT]: undefined,
    [LOADING]: true,
    [PAGE]: 0,
    [PAGE_SIZE]: 10,
    [SORTED]: [{ columnName: "id", direction: "desc" }],
    models: undefined,
  },
  pipelinesTypesState: {
    [LOADING]: false,
    models: undefined,
    modelsNormalized: undefined,
  },
};
