export enum EStreamReportStatus {
  ERROR = 0,
  REPEAT = 1,
  SUCCESS = 2,
  LOCK = 3,
  FORCE_REPEAT = 4,
}

export enum EStreamStatus {
  DATA_COLLECTION = 0,
  IS_COMPLETED = 1,
  DELETED = 2,
  ARCHIVED = 3,
  CREDENTIAL_LOST = 4,
  CREDENTIAL_NOT_PERMISSION = 5,
  CREDENTIAL_NOT_ACCOUNT_PERMISSION = 6,
  CREDENTIAL_LOST_SHARED = 7,
}

export enum EStreamSubType {
  ADS = "ADS",
  COMMUNITY = "COMMUNITY",
  POST = "POST",
}
