import { all } from "redux-saga/effects";
import { IModulesActionMap } from "src/redux/modules/index";
import { sagaCompile } from "src/utils";
import { v4 as uuidv4 } from "uuid";

import { IPopupAddR } from "./action";

const { takeEveryRequest } = sagaCompile<IModulesActionMap>();

export function* popupAddSaga() {
  yield takeEveryRequest(["POPUP_ADD__R", "POPUP_ADD__S", "POPUP_ADD__F"], {
    *onR(action: IPopupAddR) {
      const { importance, text } = action.payload;
      const popup = { id: uuidv4(), importance, text };
      return yield { popup };
    },
  });
}

export function* popupsSaga() {
  yield all([popupAddSaga()]);
}
