import { ClickAwayListener, MenuItem, MenuList, Paper, Popover, PopoverProps } from "@mui/material";
import React, { Children, cloneElement, FC, MouseEvent, ReactNode, useCallback } from "react";

import * as classes from "./index.css";

export interface UiMenuProps extends Omit<PopoverProps, "open" | "title" | "hideBackdrop"> {
  onClose: () => void;
  keepOnClick?: boolean;
  withBackdrop?: boolean;
  title?: string | ReactNode;
}

const UiMenu: FC<UiMenuProps> = ({
  onClose,
  anchorEl,
  children,
  keepOnClick,
  withBackdrop,
  anchorOrigin,
  transformOrigin,
  title,
  ...props
}) => {
  const open = Boolean(anchorEl);

  const handleClickMenuItem = useCallback(() => {
    if (!keepOnClick) {
      onClose();
    }
  }, [keepOnClick, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={
        anchorOrigin || {
          horizontal: "center",
          vertical: "bottom",
        }
      }
      transformOrigin={
        transformOrigin || {
          horizontal: "center",
          vertical: "top",
        }
      }
      hideBackdrop={!withBackdrop}
      {...props}
    >
      <Paper>
        <ClickAwayListener onClickAway={onClose}>
          <MenuList autoFocusItem={open}>
            {title && (
              <MenuItem css={classes.title} disableRipple>
                {title}
              </MenuItem>
            )}

            {Children.map(children, (child: any) =>
              child !== null
                ? cloneElement(child as any, {
                    onClick: (e: MouseEvent<any>) => {
                      handleClickMenuItem();

                      if (typeof child?.props?.onClick === "function") {
                        child?.props?.onClick(e);
                      }
                    },
                  })
                : null,
            )}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
};

export default UiMenu;
