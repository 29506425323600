import { css } from "@emotion/react/macro";

export const dialog = css`
  .MuiPaper-root {
    max-width: 510px;
    width: 100%;
  }
`;

export const dialog__email = css`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  padding: 20px 30px;
  background: #f1f6f8;
  border-radius: 8px;
  word-break: break-all;
  margin-top: 20px;
`;
