import { IProject, IProjectInvite } from "src/types/project";
import { useQueryProjectInvitesInvalidate } from "src/utils/queries/project/useQueryProjectInvitesGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  id: IProjectInvite["id"];
  projectId?: IProject["id"];
}

export default function useQueryProjectInviteDelete(queryOptions?: IUseQueryMutationOptions<unknown, IInput>) {
  const invalidateInvites = useQueryProjectInvitesInvalidate();

  return useMutationBase<unknown, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateInvites(args[1].projectId);

        queryOptions?.onSuccess?.apply(queryOptions, args);
      },
    },
    {
      method: "DELETE",
      params: () => null,
      url: (params) => `invitation/${params.id}/`,
    },
  );
}
