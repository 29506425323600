import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "user-me-meta";

export default function useQueryMeMetaGet<IResp>(sign: string, queryOptions?: IUseQueryBaseOptions<{ user_meta: IResp }>) {
  return useQueryBase<{ user_meta: IResp }>([QUERY_KEY, sign], queryOptions, {
    method: "GET",
    url: `user/meta/${sign}/`,
  });
}
