import { t, Trans } from "@lingui/macro";
import { LockOpen } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import AuthForm from "src/app/Auth/components/AuthForm";
import AuthTemplate from "src/app/Auth/components/AuthTemplate";
import { parseQueryString } from "src/utils";
import useQueryAuthSetPassword from "src/utils/queries/auth/useQueryAuthSetPassword";

interface IForm {
  password1: string;
  password2: string;
  recovery_token: string;
}

const SetPassword: FC = () => {
  const token = parseQueryString().get("token");
  const [form, setForm] = useState({
    password1: "",
    password2: "",
    recovery_token: token || "",
  });

  const { isSuccess, isFetching, refetch: submitForm } = useQueryAuthSetPassword(form);

  const handleChangeForm = useCallback(
    (key: keyof IForm) => (value: string) => {
      setForm((state) => ({
        ...state,
        [key]: value,
      }));
    },
    [],
  );

  if (isSuccess) {
    return <Redirect to="/auth/signin/" />;
  }

  return (
    <AuthTemplate title={<Trans>Reset Password</Trans>} icon={<LockOpen />}>
      <AuthForm
        onSubmit={submitForm}
        fields={[
          {
            name: "password",
            onChange: handleChangeForm("password1"),
            title: t`Password`,
            type: "password",
            value: form.password1,
          },
          {
            name: "password2",
            onChange: handleChangeForm("password2"),
            title: t`Repeat password`,
            type: "password",
            value: form.password2,
          },
        ]}
        button={{ disabled: isFetching, title: <Trans>Reset</Trans> }}
        links={[
          { title: <Trans>SignIn</Trans>, to: "/auth/signin/" },
          { title: <Trans>SignUp</Trans>, to: "/auth/signup/" },
        ]}
      />
    </AuthTemplate>
  );
};

export default SetPassword;
