export let accessToken = window.localStorage.getItem("access_token");
export let refreshToken = window.localStorage.getItem("refresh_token");

export function updateTokens(newAccessToken: string, newRefreshToken: string) {
  accessToken = newAccessToken;
  refreshToken = newRefreshToken;

  window.localStorage.setItem("access_token", accessToken);
  window.localStorage.setItem("refresh_token", refreshToken);
}

export function removeTokens() {
  accessToken = null;
  refreshToken = null;

  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
}

export function setAccessToken(newAccessToken: string) {
  accessToken = newAccessToken;
}

export function setRefreshToken(newRefreshToken: string) {
  refreshToken = newRefreshToken;
}
