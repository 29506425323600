import { AxiosError } from "axios";
import { useMemo } from "react";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { QueryKey } from "react-query/types/core/types";
import { apiSMPRequest, IApiRequestParams } from "src/utils/api";
import { EFailureCodes, Failure } from "src/utils/failure";
import { accessToken } from "src/utils/tokens";

const API_URL = process.env.REACT_APP__API_BASE_URL || "http://127.0.0.1:8080/api";
const API_VERSION = process.env.REACT_APP__API_VERSION || 0;

const getSmpToken = async (sootherAuth?: boolean) => {
  try {
    return await fetch(`${API_URL}/${API_VERSION}/${sootherAuth ? "soother-auth" : "soother"}/`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    }).then((resp) => resp.headers.get("X-SMP-JWT"));
  } catch (e) {
    return null;
  }
};

export type IUseQueryBaseSmpOptions<IOutput, IOutputSelect = IOutput> =
  | (UseQueryOptions<IOutput, any, IOutputSelect> & {
      silent?: boolean;
    })
  | undefined;

export interface IUseQueryBaseSmpParams {
  method?: IApiRequestParams["method"];
  params?: IApiRequestParams["params"];
  url: IApiRequestParams["url"];
}

export default function useQueryBaseSmp<IOutput, IOutputSelect = IOutput>(
  queryKey: QueryKey,
  queryOptions: IUseQueryBaseSmpOptions<IOutput, IOutputSelect>,
  { method = "GET", params, url }: IUseQueryBaseSmpParams,
  sootherAuth?: boolean,
) {
  const apiRequestParams = useMemo(
    () => ({
      data: method === "GET" ? {} : params,
      method,
      params: method !== "GET" ? {} : params,
      url,
    }),
    [method, params, url],
  );

  return useQuery<IOutput, any, IOutputSelect>(
    queryKey,
    async () => {
      if (!accessToken) {
        throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
      }

      const smpToken = await getSmpToken(sootherAuth);

      if (!smpToken) {
        throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
      }

      return apiSMPRequest<IOutput>({
        ...apiRequestParams,
        accessToken: smpToken,
      }).then((result) => result.data);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      ...queryOptions,
    },
  );
}

export type IUseQueryMutationSmpOptions<IOutput, IInput = undefined> =
  | (UseMutationOptions<IOutput, AxiosError, IInput, any> & {
      silent?: boolean;
      mutationFn?: undefined;
    })
  | undefined;

interface IUseQueryMutationBaseParams<IInput> {
  method?: IApiRequestParams["method"];
  url: IApiRequestParams["url"] | ((params: IInput) => string);
  params?:
    | {
        [key: string]: any;
      }
    | ((params: IInput) => {
        [key: string]: any;
      });
}

export function useMutationBaseSmp<IOutput, IInput = undefined>(
  queryOptions: IUseQueryMutationSmpOptions<IOutput, IInput>,
  { method = "POST", url, params }: IUseQueryMutationBaseParams<IInput>,
  sootherAuth?: boolean,
) {
  return useMutation<IOutput, AxiosError, IInput>(async (apiParams) => {
    if (!accessToken) {
      throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
    }

    const smpToken = await getSmpToken(sootherAuth);

    if (!smpToken) {
      throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
    }

    return apiSMPRequest<IOutput>({
      accessToken: smpToken,
      data: method === "GET" ? {} : typeof params === "function" ? params(apiParams) : params || apiParams,
      method,
      params: method !== "GET" ? {} : typeof params === "function" ? params(apiParams) : params || apiParams,
      url: typeof url === "function" ? url(apiParams) : url,
    }).then((result) => result.data);
  }, queryOptions);
}
