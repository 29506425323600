import { t, Trans } from "@lingui/macro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Confirm from "src/components/UiDialogConfirm";
import { EProjectRole } from "src/enums/project";
import { popupAdd } from "src/redux";
import { IProjectExtended } from "src/types/project";
import useQueryProjectDelete from "src/utils/queries/project/useQueryProjectDelete";
import useQueryProjectMemberDelete from "src/utils/queries/project/useQueryProjectMemberDelete";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

import * as classes from "./index.css";

interface IProps {
  project: IProjectExtended;
  ownProjectsNum: number;
}

const CellActions: FC<IProps> = ({ project, ownProjectsNum }) => {
  const dispatch = useDispatch();
  const [modalRemoveIsVisible, setModalRemoveIsVisible] = useState(false);
  const [modalLeaveIsVisible, setModalLeaveIsVisible] = useState(false);
  const invalidateProjects = useQueryProjectsInvalidate();
  const invalidateMe = useQueryMeInvalidate();
  const { mutate: projectDelete, isLoading: projectDeleteIsLoading } = useQueryProjectDelete({
    onSuccess: () => {
      handleCloseModalRemove();

      dispatch(
        popupAdd({
          text: t`Project successfully deleted`,
        }),
      );
    },
  });
  const { mutate: projectLeave, isLoading: projectLeaveIsLoading } = useQueryProjectMemberDelete({
    onSuccess: async () => {
      await Promise.all([invalidateProjects(), invalidateMe()]);

      handleCloseModalLeave();

      dispatch(
        popupAdd({
          text: t`You left the project`,
        }),
      );
    },
  });

  const handleDelete = useCallback(() => {
    projectDelete({ projectId: project.id });
  }, [project.id, projectDelete]);

  const handleLeave = useCallback(() => {
    projectLeave({ id: project.current_member_id, projectId: project.id });
  }, [project.current_member_id, project.id, projectLeave]);

  const handleOpenModalLeave = useCallback(() => {
    setModalLeaveIsVisible(true);
  }, []);

  const handleCloseModalLeave = useCallback(() => {
    setModalLeaveIsVisible(false);
  }, []);

  const handleOpenModalRemove = useCallback(() => {
    setModalRemoveIsVisible(true);
  }, []);

  const handleCloseModalRemove = useCallback(() => {
    setModalRemoveIsVisible(false);
  }, []);

  return (
    <>
      <Stack spacing={3} direction="row" css={classes.stack} justifyContent="right">
        <IconButton component={NavLink} disabled={!project.is_approved} to={`/projects/${project.id}`} size="large">
          <EditIcon fontSize="medium" />
        </IconButton>

        {project.role !== EProjectRole.OWNER ? (
          <IconButton onClick={handleOpenModalLeave} size="large" color="secondary">
            <LogoutIcon />
          </IconButton>
        ) : ownProjectsNum > 1 ? (
          <IconButton onClick={handleOpenModalRemove} size="large" color="secondary">
            <DeleteIcon fontSize="medium" />
          </IconButton>
        ) : (
          <Tooltip title={<Trans>You cannot delete your last project</Trans>}>
            <span>
              <IconButton disabled size="large">
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>

      <Confirm
        open={modalRemoveIsVisible}
        isLoaded={projectDeleteIsLoading}
        handleClose={handleCloseModalRemove}
        handleAction={handleDelete}
        maxWidth="xs"
        text={t`All publics/ads accounts will be removed, are you sure you want to delete?`}
      />

      <Confirm
        open={modalLeaveIsVisible}
        isLoaded={projectLeaveIsLoading}
        handleClose={handleCloseModalLeave}
        handleAction={handleLeave}
        maxWidth="xs"
        text={t`Are you sure you want to leave project?`}
      />
    </>
  );
};

export default CellActions;
