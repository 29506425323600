import { css } from "@emotion/react/macro";

export const container = css`
  .MuiPaper-root {
    max-width: 500px;
    width: 100%;
  }

  .MuiDialogActions-root {
    justify-content: center;
  }
`;
