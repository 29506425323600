import { plural, t } from "@lingui/macro";
import moment from "moment";
import { useCallback } from "react";
import { EPipelineStatus, EPipelineType } from "src/enums/pipeline";
import { EProgressType } from "src/enums/progress";
import { EStreamReportStatus, EStreamStatus } from "src/enums/stream";
import { IPipeline } from "src/types/pipeline";
import aggregateReportStatusProgress from "src/utils/aggregateReportStatusProgress";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

enum EColor {
  INFO = "#505050",
  ERROR = "#ce383a",
  PROCESS = "#46a0d4",
}

export interface IAggregatedPipelineStatus {
  color: EColor;
  description?: string;
  title: string;
  to?: string;
}

const usePipelineStatus = (): ((pipeline: IPipeline) => IAggregatedPipelineStatus) => {
  const { data: projects } = useQueryProjects();
  const getWorkStatus = useCallback((pipeline: IPipeline) => {
    const seconds = (() => {
      const result = moment.utc(pipeline.backend_datetime).diff(pipeline.data_update_at, "second");

      return Math.max(1, Number.isNaN(result) ? 1 : result);
    })();
    const d = Math.floor(seconds / 3600 / 24);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds / 60);
    const time = (d && t`${d}d.`) || (h && t`${h}h.`) || t`${m || 1}m.`;

    return {
      color: EColor.INFO,
      description: pipeline.streams[0].next_update_at
        ? moment.utc(pipeline.update_at).local().format("D MMMM, HH:mm")
        : undefined,
      title: pipeline.data_update_at ? t`Updated ${time} ago` : t`Updated`,
    } as IAggregatedPipelineStatus;
  }, []);

  return useCallback(
    (pipeline) => {
      const project = projects?.find((p) => p.id === pipeline.project);
      const accountName = pipeline.streams[0].config_source.entity_account_name;

      if ([EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source)) {
        if (pipeline.report_status.some(({ status }) => status !== EStreamReportStatus.SUCCESS) && pipeline.is_first_run) {
          return {
            color: EColor.INFO,
            title: t`Data of the publics and/or advertising accounts included in the Merge is still being collected`,
          };
        }

        return getWorkStatus(pipeline);
      } else if (pipeline.status === EPipelineStatus.ARCHIVED) {
        return {
          color: EColor.ERROR,
          description: t`In this status, data is no longer collected. But the old data is available.`,
          title: t`Archived`,
        };
      }

      for (const element of pipeline.streams) {
        if (element.status === EStreamStatus.CREDENTIAL_LOST) {
          if (project?.role === "owner") {
            return {
              color: EColor.ERROR,
              description: t`Please update your data source connection.`,
              title: t`Source lost`,
              to: "/credentials/",
            };
          } else {
            return {
              color: EColor.ERROR,
              description: t`The credentials ${accountName || ""} were removed from the project ${
                project?.name || ""
              }. Contact the owner of the credentials.`,
              title: t`Access denied`,
            };
          }
        } else if (
          [EStreamStatus.CREDENTIAL_NOT_PERMISSION, EStreamStatus.CREDENTIAL_NOT_ACCOUNT_PERMISSION].includes(element.status)
        ) {
          return {
            color: EColor.ERROR,
            description: t`Your social account access level is insufficient for data collection. Please request appropriate access level (Admin level of editor level) from Admin.`,
            title: t`Access denied`,
            to: "/credentials/",
          };
        } else if (element.status === EStreamStatus.CREDENTIAL_LOST_SHARED) {
          if (project?.role === "owner") {
            return {
              color: EColor.ERROR,
              description:
                t`Go to the project settings and return the shared access to the credentials` +
                `${(accountName && ": " + accountName) + "."}`,
              title: t`Credentials revoked`,
            };
          } else {
            return {
              color: EColor.ERROR,
              description:
                t`Credentials revoked. Please contact credential owner` + `${(accountName && ": " + accountName) + "."}`,
              title: t`Credentials revoked`,
            };
          }
        }
      }

      if (pipeline.report_status.some((rs) => rs.status !== EStreamReportStatus.SUCCESS && rs.retry_count >= 5)) {
        return {
          color: EColor.ERROR,
          description: t`Contact us for support`,
          title: t`Data collection error`,
        };
      }

      let repeatActive = false;
      for (const element of pipeline.report_status) {
        if (element.status === EStreamReportStatus.REPEAT || element.status === EStreamReportStatus.FORCE_REPEAT) {
          repeatActive = true;
          break;
        }
      }

      if (
        repeatActive ||
        (!pipeline.report_status.some(({ status }) => status !== null) && pipeline.status === EPipelineStatus.DATA_COLLECTION)
      ) {
        return {
          color: EColor.PROCESS,
          description: t`Data collection will start within 2-3 minutes`,
          title: t`Awaiting launch`,
        };
      }

      const completedStreamsLength = pipeline.report_status.filter(
        ({ status }) => status === EStreamReportStatus.SUCCESS,
      ).length;

      if (completedStreamsLength < pipeline.streams.length) {
        const progress = aggregateReportStatusProgress(pipeline.report_status);
        const progressStringValue = (() => {
          if (!progress.stepTotal) {
            return `${
              pipeline.streams.length > 1
                ? completedStreamsLength + " " + t`of` + " " + pipeline.streams.length + " " + t`completed`
                : ""
            }`;
          }

          if (progress.type === EProgressType.TIMER && typeof progress.value === "number") {
            const mm = Math.floor(progress.value / 60);
            const ss = progress.value - mm * 60;

            if (progress.sign === "fast_run") {
              const mmStr = plural(mm, {
                few: "# minutes",
                many: "# minutes",
                one: "# minute",
                other: "# minutes",
              });
              const ssStr = plural(ss, {
                few: "# seconds",
                many: "# seconds",
                one: "# second",
                other: "# seconds",
              });
              const timerString = `${mm ? mmStr + " " : ""}${ssStr}`;

              return t`The first stage of data collection.` + progress.value ? " " + t`Remained` + ": " + timerString : "";
            }

            return `${progress.name ? progress.name + ": " : ""}${mm <= 9 ? "0" + mm : mm}:${ss}`;
          }

          if (progress.name !== "" && progress.value !== "") {
            progress.name += ": ";
          }

          return `${progress.name}${progress.value}`;
        })();

        const progressLabel = (() => {
          let str = t`Data collection`;

          if (progress.stepTotal > 1) {
            str += `. ${t`Step`}: ${progress.stepCurrent} ${t`of`} ${progress.stepTotal}`;
          }

          return str;
        })();

        return {
          color: EColor.PROCESS,
          description: progressStringValue,
          title: progressLabel,
        };
      }

      return getWorkStatus(pipeline);
    },
    [getWorkStatus, projects],
  );
};

export default usePipelineStatus;
