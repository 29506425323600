import { Action, Dispatch } from "redux";

export type IActionType<IActionMap> = keyof IActionMap;

export type IActionTPayload<IActionMap, T extends IActionType<IActionMap>> = IActionMap[T];

export interface IActionMeta {
  id?: number;
  reject?: (error: Error) => void;
  resolve?: (payload: any) => void;
}

export type IActionError = Error;

export interface IActionT<IActionMap, T extends IActionType<IActionMap>> extends Action {
  type: T;
  payload: IActionTPayload<IActionMap, T>;
  meta: IActionMeta;
  error?: IActionError;
}

export type IAction<IActionMap> = {
  [T in IActionType<IActionMap>]: IActionT<IActionMap, T>;
}[IActionType<IActionMap>];

type IActionTActionCreator<IActionMap, T extends IActionType<IActionMap>> = (
  payload: IActionTPayload<IActionMap, T>,
  meta?: IActionMeta,
  error?: IActionError,
) => IActionT<IActionMap, T>;

export type IActionTPromiseCreator<IActionMap, R extends IActionType<IActionMap>, S extends IActionType<IActionMap>> = (
  payloadR: IActionTPayload<IActionMap, R>,
) => Promise<IActionTPayload<IActionMap, S>>;

const createActionTActionCreator =
  <IActionMap, T extends IActionType<IActionMap>>(type: T): IActionTActionCreator<IActionMap, T> =>
  (payload: IActionTPayload<IActionMap, T>, meta: IActionMeta = {}, error?: IActionError): IActionT<IActionMap, T> => ({
    error,
    meta,
    payload,
    type,
  });

const createActionTPromiseCreator =
  <IActionMap, R extends IActionType<IActionMap>, S extends IActionType<IActionMap>>(
    actionRActionCreator: IActionTActionCreator<IActionMap, R>,
  ) =>
  (dispatch: Dispatch<any>): IActionTPromiseCreator<IActionMap, R, S> =>
  (payloadR: IActionTPayload<IActionMap, R>): Promise<IActionTPayload<IActionMap, S>> =>
    new Promise((resolve, reject) => dispatch(actionRActionCreator(payloadR, { reject, resolve })));

type IReducerCases<IActionMap, IState> = {
  [T in IActionType<IActionMap>]: (state: IState, action: IActionT<IActionMap, T>) => IState;
};

const createReducer =
  <IActionMap, IState>(cases: Partial<IReducerCases<IActionMap, IState>>, initialState: IState) =>
  (state: IState = initialState, action: IAction<IActionMap>): Readonly<IState> => {
    const fn = cases[action.type];
    return fn ? fn(state, action) : state;
  };

export function actionCompile<IActionMap, IState>() {
  return {
    createActionCreator: <T extends IActionType<IActionMap>>(type: T) => createActionTActionCreator<IActionMap, T>(type),

    createPromiseCreator: <R extends IActionType<IActionMap>, S extends IActionType<IActionMap>>(
      actionRActionCreator: IActionTActionCreator<IActionMap, R>,
    ) => createActionTPromiseCreator<IActionMap, R, S>(actionRActionCreator),

    createReducer: (cases: Partial<IReducerCases<IActionMap, IState>>, initialState: IState) =>
      createReducer<IActionMap, IState>(cases, initialState),
  };
}

export const createIds = (items: any) => {
  const ids: number[] = [];

  items.forEach((item: { id: number }, index: number) => {
    ids[item.id] = index;
  });

  return ids;
};
