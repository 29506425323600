import { AxiosPromise } from "axios";
import { EExportType } from "src/enums/export";
import { ELanguage } from "src/enums/language";
import { EPipelineStatus, EPipelineType } from "src/enums/pipeline";
import { IPipeline } from "src/types/pipeline";
import { ITmpl } from "src/types/tmpl";
import { apiRequest } from "src/utils";

export interface IPipelinePostApiRequestOptions {
  accessToken: string;
  pipeline: {
    id?: IPipeline["id"];
    name: IPipeline["name"];
    source: EPipelineType;
    disabledNext: boolean;
    config: any;
    account?: any;
    account_id?: number;
    account_type?: string;
    account_credentials_id?: any;
    entity_account_name?: string;
    entity_target_name?: string;
    client?: {
      all_limit: string;
      day_limit: string;
      id: number;
      name: string;
    };
  };
  selectedProjectId: number;
}

export interface IPipelinePutApiRequestOptions {
  accessToken: string;
  name?: IPipeline["name"];
  pipelineId: IPipeline["id"];
  project?: number;
  run_delta?: number;
  config?: any;
}

export interface IPipelineGetApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelinesGetApiRequestOptions {
  accessToken: string;
  limit?: number;
  offset?: number;
  ordering?: string;
  selectedProjectId: number;
  status?: EPipelineStatus;
  filtered?: string;
  name?: string;
  id__in?: string;
}

export interface IPipelineExportsGetApiRequestOptions {
  accessToken: string;
  limit?: number;
  offset?: number;
  ordering?: string;
  pipelineId: IPipeline["id"];
  status?: EPipelineStatus;
  id__in?: string;
}

export interface IPipelineExportsStatusGetApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
  id__in: string;
}

export interface IPipelineExportUrlGetApiRequestOptions {
  accessToken: string;
  exportId: number;
}

export interface IPipelineReportGetApiRequestOptions {
  accessToken: string;
  limit?: number;
  offset?: number;
  ordering?: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelineDeleteApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelineRestartApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelineMappingGetApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelineTmplGetApiRequestOptions {
  accessToken: string;
  pipelineId: IPipeline["id"];
}

export interface IPipelineReportRestartApiRequestOptions {
  accessToken: string;
  reportId: number;
}

export interface IPipelineUnarchiveApiRequestOptions {
  accessToken: string;
  pipelineIds: IPipeline["id"][];
}

export function pipelinesGetApi({
  accessToken,
  limit,
  offset,
  ordering,
  selectedProjectId,
  id__in,
  filtered,
  name,
}: IPipelinesGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      filtered,
      id__in,
      limit,
      name,
      offset,
      ordering,
    },
    url: `project/${selectedProjectId}/pipelines/`,
  });
}

export function pipelineExportsGetApi({
  accessToken,
  limit,
  offset,
  ordering,
  pipelineId,
  id__in,
}: IPipelineExportsGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      id__in,
      limit,
      offset,
      ordering,
    },
    url: `pipeline/${pipelineId}/export/`,
  });
}

export function pipelineExportsStatusGetApi({
  accessToken,
  id__in,
  pipelineId,
}: IPipelineExportsStatusGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      id__in,
    },
    url: `pipeline/${pipelineId}/export/`,
  });
}

export function pipelineExportsPostApi({
  accessToken,
  dateTo,
  dateFrom,
  pipelineId,
  tmplId,
  unionByField,
  type,
  report_url,
  pages,
  lang,
  settings,
}: {
  accessToken: string;
  dateTo: Date;
  dateFrom: Date;
  pipelineId: IPipeline["id"];
  tmplId: ITmpl["id"];
  unionByField: any;
  type?: EExportType;
  report_url?: string;
  pages?: number[];
  lang?: ELanguage;
  settings?: {
    [key: string]: any;
  };
}): AxiosPromise {
  return apiRequest({
    accessToken,
    data: {
      _type: type || EExportType.EXCEL,
      config: {
        dataVisualizerSettings: settings && encodeURIComponent(JSON.stringify(settings)),
        date_from: dateFrom,
        date_to: dateTo,
        extra_params:
          type === EExportType.PDF
            ? {
                goto: {
                  timeout: 0,
                },
                pdf: {
                  fullPage: true,
                  width: "1280px",
                },
                scrollPage: false,
                viewport: {
                  width: 1280,
                },
                waitFor: "#report-ready",
              }
            : undefined,
        lang,
        pages: pages ? pages.toString() : undefined,
        template_id: tmplId,
        union_by_fields: unionByField,
      },
      pipeline: pipelineId,
      report_url,
    },
    method: "POST",
    url: `pipeline/${pipelineId}/export/`,
  });
}

export function pipelineExportDeleteApi({ accessToken, exportId }: { accessToken: string; exportId: number }): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "DELETE",
    url: `export/${exportId}/`,
  });
}

export function pipelineExportUrlGetApi({ accessToken, exportId }: IPipelineExportUrlGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `export/${exportId}/url/`,
  });
}

export function pipelineGetApi({ accessToken, pipelineId }: IPipelineGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `pipeline/${pipelineId}/`,
  });
}

export function pipelineReportGetApi({
  accessToken,
  limit,
  offset,
  ordering,
  pipelineId,
}: IPipelineReportGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      limit,
      offset,
      ordering,
    },
    url: `pipeline/${pipelineId}/report/`,
  });
}

export function pipelineSocialGetApi({
  accessToken,
  limit,
  offset,
  ordering,
  selectedProjectId,
  status,
}: IPipelinesGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      limit,
      offset,
      ordering,
      status,
    },
    url: `project/${selectedProjectId}/pipelines/`,
  });
}

export function pipelinePostApi({ accessToken, pipeline, selectedProjectId }: IPipelinePostApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    data: { ...pipeline, project_id: selectedProjectId },
    method: "POST",
    url: `pipeline/`,
  });
}

export function pipelinePutApi({
  accessToken,
  pipelineId,
  name,
  project,
  run_delta,
  config,
}: IPipelinePutApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    data: { config, name, project, run_delta },
    method: "PATCH",
    url: `pipeline/${pipelineId}/`,
  });
}

export function pipelineDeleteApi({ accessToken, pipelineId }: IPipelineDeleteApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    data: {
      remove_from_merge: 1,
    },
    method: "DELETE",
    url: `pipeline/${pipelineId}/`,
  });
}

export function pipelineRestartApi({ accessToken, pipelineId }: IPipelineRestartApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "POST",
    url: `pipeline/${pipelineId}/restart/`,
  });
}

export function pipelineArchiveApi({ accessToken, pipelineId }: IPipelineRestartApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "POST",
    url: `pipeline/${pipelineId}/archive/`,
  });
}

export function pipelineMappingGetApi({ accessToken, pipelineId }: IPipelineMappingGetApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    params: {
      connector: "inhouse",
    },
    url: `pipeline/${pipelineId}/mapping/0/`,
  });
}

export function pipelineReportRestartGetApi({ accessToken, reportId }: IPipelineReportRestartApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `report/${reportId}/restart/`,
  });
}

export function pipelineUnarchiveApi({ accessToken, pipelineIds }: IPipelineUnarchiveApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    data: {
      pipeline_ids: pipelineIds,
    },
    method: "POST",
    url: `pipeline/unarchive/`,
  });
}

export function pipelinesTypesGetApi({ accessToken }: IPipelineUnarchiveApiRequestOptions): AxiosPromise {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `types/pipelines/`,
  });
}
