import { Trans } from "@lingui/macro";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Box, DialogContent, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IconBell, IconInfoCircle } from "@tabler/icons";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import AppsIcon from "src/icons/AppsIcon";
import logoImage from "src/statics/telegram-logo.svg";
import { ITelegramNotificationEntity } from "src/types/telegramNotification";
import useQueryTelegramNotification, {
  useQueryTelegramNotificationInvalidate,
} from "src/utils/queries/remainder/useQueryTelegramNotification";
import useQueryTelegramNotificationDelete from "src/utils/queries/remainder/useQueryTelegramNotificationDelete";
import useQueryTelegramNotificationGetCode from "src/utils/queries/remainder/useQueryTelegramNotificationGetCode";

import NotificationsTelegramCode from "./components/NotificationsTelegramCode";
import * as classes from "./index.css";

const FETCH_DELAY = 3000;

const NotificationsTelegram: FC = () => {
  const invalidateAccounts = useQueryTelegramNotificationInvalidate();
  const [currentCode, setCurrentCode] = useState<string | undefined>();
  const [isRefetchIntervalEnabled, setRefetchIntervalEnabled] = useState<boolean>(false);
  const {
    data: entitiesResp,
    isLoading: entitiesLoading,
    refetch: entitiesRefetch,
  } = useQueryTelegramNotification({
    onSuccess: (data) => {
      setRefetchIntervalEnabled(data.results.some((entity) => !entity.is_active));
    },
    refetchInterval: isRefetchIntervalEnabled ? FETCH_DELAY : false,
  });
  const {
    mutate: deleteCode,
    isLoading: deleteCodeLoading,
    variables: deleteCodeVar,
  } = useQueryTelegramNotificationDelete({
    onSuccess: () => {
      entitiesRefetch();
    },
  });
  const { mutate: getCode, isLoading: getCodeLoading } = useQueryTelegramNotificationGetCode({
    onSuccess: ({ auth_code, deeplink }) => {
      invalidateAccounts();

      if (deeplink) {
        window.open(deeplink, "_blank");
      } else {
        setCurrentCode(auth_code);
      }
    },
  });

  const entities = useMemo(
    () => entitiesResp?.results && [...entitiesResp.results].filter((entity) => entity.is_active),
    [entitiesResp?.results],
  );

  const handleOpenDialog = useCallback(
    (code: ITelegramNotificationEntity["auth_code"]) => () => {
      setCurrentCode(code);
    },
    [],
  );

  const handleCloseDialog = useCallback(() => {
    setCurrentCode(undefined);
  }, []);

  const handleDeleteCode = useCallback(
    (code: ITelegramNotificationEntity["auth_code"]) => () => {
      deleteCode(code);
    },
    [deleteCode],
  );

  const handleGetCode = useCallback(() => {
    getCode(undefined);
  }, [getCode]);

  useUpdateEffect(() => {
    if (currentCode) {
      entities?.forEach((entity) => {
        if (entity.is_active && entity.auth_code === currentCode) {
          setCurrentCode(undefined);
        }
      });
    }
  }, [entities, currentCode]);

  return useMemo(() => {
    if (entitiesLoading) {
      return <Loader />;
    }

    return (
      <>
        <Typography variant="h3" component="div" mt={5}>
          <Stack direction="row" spacing={0.8} alignItems="center">
            <IconBell />
            <div>
              <Trans>Notifications to Telegram</Trans>
            </div>

            {entities && entities.length > 0 && (
              <UiButton
                loading={getCodeLoading}
                onClick={handleGetCode}
                css={classes.btnDeleteConnect}
                startIcon={<AppsIcon />}
              >
                <Trans>Connect</Trans>
              </UiButton>
            )}
          </Stack>
        </Typography>

        <Box mt={3}>
          {entities?.length ? (
            entities.map((entity) => (
              <Stack key={entity.auth_code} direction="row" spacing={1} alignItems="center" css={classes.entity}>
                <div css={classes.entity__colIcn}>
                  {!entity.is_active ? (
                    <CircularProgress size={16} />
                  ) : (
                    <img css={classes.logo} src={logoImage} alt="Telegram" />
                  )}
                </div>

                <div>
                  {!entity.is_active ? (
                    <NotificationsTelegramCode code={entity.auth_code} />
                  ) : (
                    <Typography>{entity.account_name}</Typography>
                  )}
                </div>

                <div css={classes.entity__btns}>
                  {!entity.is_active && (
                    <UiButton
                      onClick={handleOpenDialog(entity.auth_code)}
                      variant="text"
                      startIcon={<IconInfoCircle />}
                      css={classes.btnInfo}
                    />
                  )}

                  <UiButton
                    disabled={deleteCodeLoading}
                    loading={deleteCodeLoading && deleteCodeVar === entity.auth_code}
                    onClick={handleDeleteCode(entity.auth_code)}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    css={classes.btnDelete}
                  />
                </div>
              </Stack>
            ))
          ) : (
            <Alert severity="info">
              <Trans>
                The <strong>datafan_notifier</strong> bot will send system notifications about the status of data collection
                from your publics or ad accounts
              </Trans>

              <Box mt={2} mb={2}>
                <UiButton loading={getCodeLoading} onClick={handleGetCode} variant="outlined" disableElevation>
                  <Trans>Enable notifications</Trans>
                </UiButton>
              </Box>
            </Alert>
          )}
        </Box>

        <UiDialog open={Boolean(currentCode)} hideCross onClose={handleCloseDialog}>
          <DialogContent css={classes.dialog}>
            <Typography variant="body2">
              <Trans>Copy the code below and write it to the bot</Trans>
            </Typography>
            {currentCode && (
              <Box mt={2} mb={2}>
                <NotificationsTelegramCode code={currentCode} />
              </Box>
            )}

            {process.env.REACT_APP__TELEGRAM_BOT_URI && (
              <UiButton component="a" target="_blank" href={process.env.REACT_APP__TELEGRAM_BOT_URI}>
                <Trans>Write to the bot</Trans>
              </UiButton>
            )}
          </DialogContent>
        </UiDialog>
      </>
    );
  }, [
    currentCode,
    deleteCodeLoading,
    deleteCodeVar,
    entities,
    entitiesLoading,
    getCodeLoading,
    handleCloseDialog,
    handleDeleteCode,
    handleGetCode,
    handleOpenDialog,
  ]);
};

export default NotificationsTelegram;
