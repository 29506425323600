import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { FC } from "react";

const EmotionCacheProvider: FC = ({ children }) => {
  const cache = createCache({
    key: "css",
    speedy: false,
  });

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export default EmotionCacheProvider;
