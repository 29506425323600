import { useMemo } from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Page403 from "src/app/Page403";
import Page404 from "src/app/Page404/Page404";
import Page500 from "src/app/Page500/Page500";
import Loader from "src/components/Loader";
import { PrivateFC } from "src/types/helpers";
import useQueryPipelineGet from "src/utils/queries/pipeline/useQueryPipelineGet";

const ReportRouter: PrivateFC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const { data: pipeline, isLoading: pipelineIsLoading, error } = useQueryPipelineGet(Number(pipelineId), { silent: true });

  const redirectTo = useMemo(() => pipeline && pipeline.report_links?.main, [pipeline]);

  if (error?.response?.status === 404) {
    return <Page404 />;
  } else if (error?.response?.status === 403) {
    return <Page403 />;
  } else if (error) {
    return <Page500 />;
  } else if (pipelineIsLoading || !pipeline?.report_links) {
    return <Loader />;
  } else {
    return <Redirect to={redirectTo || "/home"} />;
  }
};

export default ReportRouter;
