import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import { HeaderDynamicContent } from "src/components/Header";
import PipelineTypeList from "src/components/PipelineTypeList";
import ProviderWithIcon from "src/components/ProviderWithIcon";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import UiInfinityTable from "src/components/UiInfinityTable";
import { EPipelineMedia } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";

import CellActions from "./components/Cells/CellActions";
import CellHealth from "./components/Cells/CellHealth";
import SourceListModal from "./components/SourceListModal";

const Credentials: FC = () => {
  const { data: credentials, isLoading: credentialsIsLoading } = useQueryCredentialsGet({}, { own: true });
  const [isSourceListOpen, setSourceListOpen] = useState(false);
  const [isOpenPipelinesModal, setOpenPipelinesModal] = useState(false);
  const [newCredential, setNewCredential] = useState<ICredential | null>(null);

  const handleShowSourceList = useCallback(() => {
    setSourceListOpen(true);
  }, []);

  const handleCloseSourceList = useCallback(() => {
    setSourceListOpen(false);
  }, []);

  const handleClosePipelinesModal = useCallback(() => {
    setNewCredential(null);
  }, []);

  const pipelineTypeListFilter = useCallback(
    (pt: IPipelineType) => {
      const availableMediums = [newCredential?.medium];

      if (newCredential?.medium === EPipelineMedia.FB) {
        availableMediums.push(EPipelineMedia.INSTAGRAM);
      }

      return availableMediums.includes(pt.media);
    },
    [newCredential?.medium],
  );

  useUpdateEffect(() => {
    if (newCredential && credentials) {
      credentials.find(({ id }) => newCredential.id === id);
      setOpenPipelinesModal(credentials.find(({ id }) => newCredential.id === id)?.used_by_streams === 0);
    }
  }, [newCredential, credentials]);

  return useMemo(
    () => (
      <>
        <HeaderDynamicContent>
          <UiButton
            startIcon={<AddIcon />}
            onClick={handleShowSourceList}
            HiddenTextProps={{
              smDown: true,
            }}
            sx={{ ml: "auto" }}
          >
            <Trans>Add source</Trans>
          </UiButton>
        </HeaderDynamicContent>

        <Section>
          <SectionHead title={<Trans>Data sources</Trans>} />

          <UiInfinityTable
            rows={credentials || []}
            columns={[
              { name: "external_id", title: "ID" },
              {
                name: "name",
                title: t`Name`,
              },
              {
                getCellValue: (row: ICredential) => <ProviderWithIcon provider={row.medium} size={24} />,
                masonryRowSx: { alignItems: "center !important" },
                name: "medium",
                title: t`Provider`,
              },
              {
                name: "used_by_streams",
                title: t`Publics/ad accounts`,
              },
              {
                getCellValue: (row: ICredential) => <CellHealth {...row} />,
                name: "lost_at",
                title: t`Health`,
              },
              {
                getCellValue: (row: ICredential) => <CellActions {...row} />,
                masonryFullWidth: true,
                name: "",
                title: "",
              },
            ]}
            loading={credentialsIsLoading}
            enableSorting={true}
            totalRows={credentials?.length || 0}
            tableProps={{
              columnExtensions: [{ align: "right", columnName: "", width: 280 }],
            }}
            sortingStateProps={{
              columnExtensions: [{ columnName: "", sortingEnabled: false }],
            }}
            masonryBreakPoints={{
              sm: 6,
              xs: 12,
            }}
          />

          <SourceListModal open={isSourceListOpen} onClose={handleCloseSourceList} onSuccess={setNewCredential} />

          <PipelineTypeList
            open={isOpenPipelinesModal}
            handleClose={handleClosePipelinesModal}
            filter={pipelineTypeListFilter}
          />
        </Section>
      </>
    ),
    [
      credentials,
      credentialsIsLoading,
      handleClosePipelinesModal,
      handleCloseSourceList,
      handleShowSourceList,
      isOpenPipelinesModal,
      isSourceListOpen,
      pipelineTypeListFilter,
    ],
  );
};

export default Credentials;
