import { t, Trans } from "@lingui/macro";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AuthForm from "src/app/Auth/components/AuthForm";
import AuthTemplate from "src/app/Auth/components/AuthTemplate";
import SocialAuth from "src/app/Auth/components/SocialAuth";
import { useAppContext } from "src/context/app/appContext";
import { popupAdd } from "src/redux";
import { arraySieve } from "src/utils";
import useQueryAuthSignUp from "src/utils/queries/auth/useQueryAuthSignUp";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IForm {
  email: string;
  password: string;
  password2: string;
  checkbox: boolean;
  phone: string;
}

const SignUp: FC = () => {
  const dispatch = useDispatch();
  const { state } = useAppContext();
  const [form, setForm] = useState<IForm>({
    checkbox: true,
    email: "",
    password: "",
    password2: "",
    phone: "",
  });

  const { refetch: submitForm, isFetching: isFetchingSignUp } = useQueryAuthSignUp(form);
  const { isFetching: isFetchingMe } = useQueryMe({ enabled: false });

  const handleChangeForm = useCallback(
    (key: keyof IForm) => (value: string) => {
      setForm((state) => ({
        ...state,
        [key]: value,
      }));
    },
    [],
  );

  const handleSubmitForm = useCallback(() => {
    const { password, password2, checkbox, phone } = form;
    if (password !== password2) {
      dispatch(
        popupAdd({
          importance: "error",
          text: t`Passwords do not match`,
        }),
      );
    } else if (!checkbox) {
      dispatch(
        popupAdd({
          importance: "error",
          text: t`You must agree to the Privacy Policy and Terms of Service`,
        }),
      );
    } else if (!state.isIoZone && phone.length !== 11) {
      dispatch(
        popupAdd({
          importance: "error",
          text: t`Invalid phone number`,
        }),
      );
    } else {
      submitForm();
    }
  }, [dispatch, form, state.isIoZone, submitForm]);

  if (process.env.REACT_APP__SIGNUP_DISABLED) {
    return (
      <AuthTemplate title={<Trans>Sign up</Trans>} icon={<AssignmentInd />}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={440}>
          <Typography variant="h4" align="center">
            <div>
              <Trans>Signup isn't available at the moment.</Trans>
            </div>
            <div>
              <Trans>Use an existing account to login to DataFan.</Trans>
            </div>
          </Typography>

          <Box mt={2}>
            <Button fullWidth variant="contained" component={Link} to="/auth/signin/">
              <Trans>Sign in</Trans>
            </Button>
          </Box>
        </Box>
      </AuthTemplate>
    );
  }

  return (
    <AuthTemplate title={<Trans>Sign up</Trans>} icon={<AssignmentInd />}>
      <AuthForm
        onSubmit={handleSubmitForm}
        fields={arraySieve([
          {
            name: "email",
            onChange: handleChangeForm("email"),
            title: t`Email Address`,
            type: "text",
            value: form.email,
          },
          {
            name: "password",
            onChange: handleChangeForm("password"),
            title: t`Password`,
            type: "password",
            value: form.password,
          },
          {
            name: "password2",
            onChange: handleChangeForm("password2"),
            title: t`Repeat password`,
            type: "password",
            value: form.password2,
          },
          !state.isIoZone
            ? {
                name: "phone",
                onChange: handleChangeForm("phone"),
                title: t`Phone number`,
                type: "phone",
                value: form.phone,
              }
            : undefined,
        ])}
        checkbox={{
          onChange: handleChangeForm("checkbox"),
          title: (
            <>
              <Trans>I'm agree with</Trans>{" "}
              <a href="/privacy-policy/" target="_blank">
                <Trans>DIF::Privacy policy</Trans>
              </a>{" "}
              <Trans>and</Trans>{" "}
              <a href="/terms-of-use/" target="_blank">
                <Trans>DIF::Terms of Service</Trans>
              </a>
            </>
          ),
          value: form.checkbox,
        }}
        button={{ disabled: isFetchingSignUp || isFetchingMe, title: <Trans>Sign up</Trans> }}
        links={[{ title: <Trans>SignIn</Trans>, to: "/auth/signin/" }]}
      />
      <SocialAuth termsAgreed={form.checkbox} signUp signIn={false} />
    </AuthTemplate>
  );
};

export default SignUp;
