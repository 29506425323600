import { Sorting } from "@devexpress/dx-react-grid";
import produce from "immer";
import { EExportType } from "src/enums/export";
import {
  EPipelineStatus,
  IModulesActionMap,
  IModulesState,
  IPipelineExport,
  IPipelinesExportsGetSagaResponse,
  IPipelinesExportsPostSagaResponse,
} from "src/redux/modules/index";
import { IPipeline } from "src/redux/modules/pipelines/data";
import { actionCompile, COUNT, IActionT, IActionTPromiseCreator, LOADING, PAGE, PAGE_SIZE, SORTED, UPDATING } from "src/utils";
import { normalizeObjectsArray } from "src/utils/normalizeObjectsArray";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesExportsActionMap {
  PIPELINES_EXPORTS_STATUS_GET__F: {};
  PIPELINES_EXPORTS_STATUS_GET__R: {
    id__in: string;
    pipelineId: IPipeline["id"];
  };
  PIPELINES_EXPORTS_STATUS_GET__S: IPipelinesExportsGetSagaResponse;

  PIPELINES_EXPORT_DELETE__F: {};
  PIPELINES_EXPORT_DELETE__R: {
    exportId: IPipelineExport["id"];
  };
  PIPELINES_EXPORT_DELETE__S: {};

  PIPELINES_EXPORT_URL__F: {
    exportId: number;
  };
  PIPELINES_EXPORT_URL__R: {
    exportId: number;
    name: string;
  };
  PIPELINES_EXPORT_URL__S: {
    exportId: number;
    url: string;
  };

  PIPELINES_EXPORTS_GET__F: {};
  PIPELINES_EXPORTS_GET__R: {
    limit?: number;
    page?: number;
    ordering?: Sorting[];
    status?: EPipelineStatus;
    id__in?: string;
    pipelineId: IPipeline["id"];
    mode?: "append" | "reload";
  };
  PIPELINES_EXPORTS_GET__S: IPipelinesExportsGetSagaResponse;

  PIPELINES_EXPORTS_POST__F: {};
  PIPELINES_EXPORTS_POST__R: {
    dateFrom: string;
    dateTo: string;
    pipelineId: IPipeline["id"];
    unionByField?: string[];
    pdfReportUrl?: string;
    callback?: any;
    type?: EExportType;
    pages?: number[];
    settings?: {
      [key: string]: any;
    };
  };
  PIPELINES_EXPORTS_POST__S: IPipelinesExportsPostSagaResponse;
}

export const pipelinesExportsStatusGet = createActionCreator("PIPELINES_EXPORTS_STATUS_GET__R");
export type IPipelinesExportsStatusGetF = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_STATUS_GET__F">;
export type IPipelinesExportsStatusGetR = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_STATUS_GET__R">;
export type IPipelinesExportsStatusGetS = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_STATUS_GET__S">;
export const pipelinesExportsStatusGetPromise = createPromiseCreator<
  "PIPELINES_EXPORTS_STATUS_GET__R",
  "PIPELINES_EXPORTS_STATUS_GET__S"
>(pipelinesExportsStatusGet);

export type IPipelinesExportStatusGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_EXPORTS_STATUS_GET__R",
  "PIPELINES_EXPORTS_STATUS_GET__S"
>;

export const pipelinesExportDelete = createActionCreator("PIPELINES_EXPORT_DELETE__R");
export type IPipelinesExportDeleteF = IActionT<IModulesActionMap, "PIPELINES_EXPORT_DELETE__F">;
export type IPipelinesExportDeleteR = IActionT<IModulesActionMap, "PIPELINES_EXPORT_DELETE__R">;
export type IPipelinesExportDeleteS = IActionT<IModulesActionMap, "PIPELINES_EXPORT_DELETE__S">;
export const pipelinesExportDeletePromise = createPromiseCreator<"PIPELINES_EXPORT_DELETE__R", "PIPELINES_EXPORT_DELETE__S">(
  pipelinesExportDelete,
);
export type IPipelinesExportDeletePromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_EXPORT_DELETE__R",
  "PIPELINES_EXPORT_DELETE__S"
>;

export const pipelinesExportUrlGet = createActionCreator("PIPELINES_EXPORT_URL__R");
export type IPipelinesExportUrlGetF = IActionT<IModulesActionMap, "PIPELINES_EXPORT_URL__F">;
export type IPipelinesExportUrlGetR = IActionT<IModulesActionMap, "PIPELINES_EXPORT_URL__R">;
export type IPipelinesExportUrlGetS = IActionT<IModulesActionMap, "PIPELINES_EXPORT_URL__S">;
export const pipelinesExportUrlGetPromise = createPromiseCreator<"PIPELINES_EXPORT_URL__R", "PIPELINES_EXPORT_URL__S">(
  pipelinesExportUrlGet,
);
export type IPipelinesExportUrlGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_EXPORT_URL__R",
  "PIPELINES_EXPORT_URL__S"
>;

export const pipelinesExportsGet = createActionCreator("PIPELINES_EXPORTS_GET__R");
export type IPipelinesExportsGetF = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_GET__F">;
export type IPipelinesExportsGetR = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_GET__R">;
export type IPipelinesExportsGetS = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_GET__S">;
export const pipelinesExportsGetPromise = createPromiseCreator<"PIPELINES_EXPORTS_GET__R", "PIPELINES_EXPORTS_GET__S">(
  pipelinesExportsGet,
);

export type IPipelinesExportGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_EXPORTS_GET__R",
  "PIPELINES_EXPORTS_GET__S"
>;

export const pipelinesExportsPost = createActionCreator("PIPELINES_EXPORTS_POST__R");
export type IPipelinesExportsPostF = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_POST__F">;
export type IPipelinesExportsPostR = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_POST__R">;
export type IPipelinesExportsPostS = IActionT<IModulesActionMap, "PIPELINES_EXPORTS_POST__S">;
export const pipelinesExportsPostPromise = createPromiseCreator<"PIPELINES_EXPORTS_POST__R", "PIPELINES_EXPORTS_POST__S">(
  pipelinesExportsPost,
);

export const pipelinesExportsReducers = {
  PIPELINES_EXPORTS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[LOADING] = false;
    }),

  PIPELINES_EXPORTS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[LOADING] = true;
    }),

  PIPELINES_EXPORTS_GET__S: (modulesState: IModulesState, action: IPipelinesExportsGetS): IModulesState => {
    const { results, meta } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState[COUNT] = meta.count;
      draft.pipelinesExportsState[LOADING] = false;
      draft.pipelinesExportsState[PAGE] = meta.page;
      draft.pipelinesExportsState[PAGE_SIZE] = meta.limit;
      draft.pipelinesExportsState[SORTED] = meta.ordering;
      if (meta.mode === "append") {
        draft.pipelinesExportsState.models = [...(modulesState.pipelinesExportsState.models || []), ...results];
      } else {
        draft.pipelinesExportsState.models = results;
      }
    });
  },

  PIPELINES_EXPORTS_POST__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = false;
    }),

  PIPELINES_EXPORTS_POST__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = true;
    }),

  PIPELINES_EXPORTS_POST__S: (modulesState: IModulesState, action: IPipelinesExportsPostS): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = false;
      draft.pipelinesExportsState.models = [action.payload.results, ...(draft.pipelinesExportsState.models || [])];
    }),

  PIPELINES_EXPORTS_STATUS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = false;
    }),

  PIPELINES_EXPORTS_STATUS_GET__R: (modulesState: IModulesState, action: IPipelinesExportsStatusGetR): IModulesState => {
    const { id__in } = action.payload;
    const idIn = id__in.split(",");

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = true;
      draft.pipelinesExportsState.models = draft.pipelinesExportsState.models?.map((item) => ({
        ...item,
        [LOADING]: idIn.includes(String(item.id)),
      }));
    });
  },

  PIPELINES_EXPORTS_STATUS_GET__S: (modulesState: IModulesState, action: IPipelinesExportsStatusGetS): IModulesState => {
    const { results } = action.payload;
    const resultsNormalized = normalizeObjectsArray(results, "id");

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState[UPDATING] = false;
      draft.pipelinesExportsState.models?.forEach((model) => {
        model.status = resultsNormalized[model.id]?.status || model.status;
        model[LOADING] = false;
      });
    });
  },

  PIPELINES_EXPORT_URL__F: (modulesState: IModulesState, action: IPipelinesExportUrlGetF): IModulesState => {
    const { exportId } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState.models?.forEach((item) => {
        if (item.id === exportId) {
          item[LOADING] = false;
        }
      });
    });
  },

  PIPELINES_EXPORT_URL__R: (modulesState: IModulesState, action: IPipelinesExportUrlGetR): IModulesState => {
    const { exportId } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState.models?.forEach((item) => {
        if (item.id === exportId) {
          item[LOADING] = true;
        }
      });
    });
  },

  PIPELINES_EXPORT_URL__S: (modulesState: IModulesState, action: IPipelinesExportUrlGetS): IModulesState => {
    const { exportId } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesExportsState.models?.forEach((item) => {
        if (item.id === exportId) {
          item[LOADING] = false;
        }
      });
    });
  },
};
