import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  check: {
    color: theme.palette.secondary.main,
    left: 10,
    position: "absolute",
    top: 10,
    zIndex: 1,
  },

  wrapper: {
    "& .rccs__card--front": {
      border: `2px solid ${theme.palette.grey.A100}`,
    },

    cursor: "pointer",
    position: "relative",
  },

  wrapper_selected: {
    "& .rccs__card--front": {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0 0 10px ${theme.palette.secondary.light}`,
    },

    cursor: "default",
  },
}));
