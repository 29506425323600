import { Plural, Trans } from "@lingui/macro";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import { useUpdateEffect } from "react-use";
import Page404 from "src/app/Page404/Page404";
import Loader from "src/components/Loader";
import { useAppContext } from "src/context/app/appContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { getTariffsTable } from "src/fixtures/tariffsTable";
import cssc from "src/utils/emotionComposition";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";
import useQueryBillingTariffsGet from "src/utils/queries/billing/useQueryBillingTariffsGet";

import SelectTariffButton from "./components/SelectTariffButton";
import TariffCarousel from "./components/TariffCarousel";
import TariffOptionValue from "./components/TariffOptionValue";
import * as classes from "./index.css";

const TariffsDesktop: FC = () => {
  const { state } = useAppContext();
  const { data: tariffsResp, isLoading: tariffsIsLoading } = useQueryBillingTariffsGet({
    refetchOnMount: true,
  });
  const sliderRefs = useRef<Slider[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const tariffsTable = getTariffsTable();

  const tariffs = useMemo(() => tariffsResp?.filter(({ sign }) => sign !== ETariffOnPeriodSign.TRIAL_OUTDATED), [tariffsResp]);

  const sliderRefCb = useCallback((slider: Slider) => {
    sliderRefs.current.push(slider);
  }, []);

  const handleChangeSlide = useCallback((current: number, next: number) => {
    setCurrentSlide(next);
  }, []);

  useUpdateEffect(() => {
    sliderRefs.current.forEach((slider) => {
      slider.slickGoTo(currentSlide);
    });
  }, [currentSlide]);

  if (tariffsIsLoading) {
    return <Loader />;
  }

  if (!tariffs) {
    return <Page404 />;
  }

  return (
    <div css={classes.container} className={state.sidebar.expanded ? "m-with-sidebar" : undefined}>
      <div css={classes.row}>
        <div css={classes.row__sidebar} />

        <div css={classes.row__content}>
          <div css={cssc([classes.carousel, classes.carousel_tariff])}>
            <TariffCarousel beforeChange={handleChangeSlide} ref={sliderRefCb}>
              {tariffs.map((t) => (
                <div key={t.name} css={classes.carousel__itemWrap}>
                  <div css={classes.carousel__item}>
                    <div css={classes.paperTariff}>
                      <Typography variant="h3" color="inherit">
                        {t.name}
                      </Typography>

                      {t.is_negotiable_price ? (
                        <Typography variant="h3" sx={{ mt: 1.6 }} color="inherit">
                          <Trans>
                            On <br />
                            request
                          </Trans>
                        </Typography>
                      ) : (
                        <Typography variant="h2" sx={{ mt: 1.6 }} color="inherit">
                          {t.price}
                          {getCurrencyDisplayName(t.currency)}
                        </Typography>
                      )}

                      {!t.is_negotiable_price && (
                        <Typography sx={{ mt: 0.6 }} color="inherit">
                          {t.sign !== ETariffOnPeriodSign.TRIAL ? (
                            <Trans>per month</Trans>
                          ) : (
                            <Plural value={7} one="# day" few="# days" many="# days" other="# days" />
                          )}
                        </Typography>
                      )}
                    </div>

                    <Box sx={{ mt: "auto", pt: 3 }}>
                      <SelectTariffButton tariff={t} ButtonProps={{ fullWidth: true }} />
                    </Box>
                  </div>
                </div>
              ))}
            </TariffCarousel>
          </div>
        </div>
      </div>

      {tariffsTable.map((group, idx) => (
        <div css={classes.row} key={idx}>
          <div css={classes.row__sidebar}>
            {group.title && (
              <Typography variant="h4" component="div" css={classes.sidebarTitle}>
                {group.title}
              </Typography>
            )}

            {group.options.map(({ label, subLabel, description }, idx) => (
              <Tooltip
                key={idx}
                title={description || ""}
                disableHoverListener={!Boolean(description)}
                disableTouchListener={!Boolean(description)}
              >
                <div
                  css={cssc([classes.option, classes.option_sidebar, Boolean(description) && classes.option_sidebar_tooltip])}
                >
                  <Typography variant="body1" color="inherit">
                    {label}
                  </Typography>
                  <Typography variant="subtitle2" color="inherit">
                    {subLabel}
                  </Typography>
                </div>
              </Tooltip>
            ))}
          </div>

          <div css={classes.row__content}>
            <div css={classes.carousel}>
              <TariffCarousel beforeChange={handleChangeSlide} ref={sliderRefCb}>
                {tariffs.map((t) => (
                  <div key={t.name} css={classes.carousel__itemWrap}>
                    <div css={classes.carousel__item}>
                      {group.title && <div css={classes.tariffName}>{t.name}</div>}

                      <div css={classes.paperOptions}>
                        {group.options.map((option, idx) => (
                          <TariffOptionValue key={idx} tariff={t} option={option} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </TariffCarousel>
            </div>
          </div>
        </div>
      ))}

      <div css={classes.row}>
        <div css={classes.row__sidebar}>
          <Typography variant="h4" component="div" css={classes.sidebarTitle}>
            <Trans>Tariff price</Trans>
          </Typography>
        </div>

        <div css={classes.row__content}>
          <div css={cssc([classes.carousel, classes.carousel_price])}>
            <TariffCarousel beforeChange={handleChangeSlide} ref={sliderRefCb}>
              {tariffs.map((t) => (
                <div key={t.name} css={classes.carousel__itemWrap}>
                  <div css={classes.carousel__item}>
                    <div css={classes.tariffName}>{t.name}</div>

                    <div css={classes.paperOptions}>
                      <Typography component="div" variant="h3" css={cssc([classes.option, classes.option_price])}>
                        {t.is_negotiable_price ? (
                          <Trans>
                            On <br />
                            request
                          </Trans>
                        ) : (
                          <>
                            <div>
                              {t.price}
                              {getCurrencyDisplayName(t.currency)}{" "}
                            </div>
                            <Typography variant="body1">
                              {t.sign !== ETariffOnPeriodSign.TRIAL ? (
                                <Trans>per month</Trans>
                              ) : (
                                <Plural value={7} one="# day" few="# days" many="# days" other="# days" />
                              )}
                            </Typography>
                          </>
                        )}
                      </Typography>
                    </div>

                    <Box sx={{ pt: 3 }}>
                      <SelectTariffButton tariff={t} ButtonProps={{ fullWidth: true }} />
                    </Box>
                  </div>
                </div>
              ))}
            </TariffCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TariffsDesktop;
