import { Table } from "@devexpress/dx-react-grid-material-ui";
import { FC, PropsWithChildren } from "react";
import { IProject } from "src/types/project";

interface IProps extends PropsWithChildren<Table.DataCellProps> {
  projects: IProject[];
}

const TableProjectsCellComponent: FC<IProps> = ({ projects, ...props }) => {
  if (props.column.name === "pipelines") {
    const rowIndex = Number(props.tableRow.rowId);

    const previousProject = projects && rowIndex > 0 ? projects[rowIndex - 1] : null;
    const nextProject = projects && rowIndex < projects.length - 1 ? projects[rowIndex + 1] : null;
    const currentProject = props.row;

    if (previousProject && previousProject.creator.user_id === currentProject.creator.id) {
      return null;
    }

    if (nextProject && nextProject.creator.user_id === currentProject.creator.id) {
      const rowSpan =
        projects &&
        projects.slice(rowIndex).filter((project) => project.creator.user_id === currentProject.creator.user_id).length;

      return <Table.Cell rowSpan={rowSpan} {...props} />;
    }
  }

  return <Table.Cell {...props} />;
};

export default TableProjectsCellComponent;
