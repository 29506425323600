import { EPipelineType } from "src/enums/pipeline";
import { EStreamSubType } from "src/enums/stream";
import { IPipeline, IPipelineType } from "src/types/pipeline";
import { IProjectExtended } from "src/types/project";

interface IConfigSrc {
  mapping: { [key: string]: object };
  fields: { [media in IPipelineType["media"]]?: object };
  prefix: "a" | "b" | "c";
  aggregation?: { [key: string]: object };
}

const adsConfig: IConfigSrc = {
  fields: {
    facebook: {
      actions_lead: "actions_lead",
      clicks: "clicks",
      impressions: "impressions",
      network_name: "network_name",
      spend: "spent",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
      unique_reach_from_api: "unique_reach_from_api",
    },
    vk: {
      ads_lead_form_count: "actions_lead",
      network_name: "network_name",
      stats_clicks: "clicks",
      stats_day: "stats_day",
      stats_impressions: "impressions",
      stats_month: "stats_month",
      stats_reach: "unique_reach_from_api",
      stats_spent: "spent",
      stats_year: "stats_year",
    },
  },
  mapping: {
    actions_lead: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "ads",
        label:
          "\u041a\u043e\u043b-\u0432\u043e \u043f\u0440\u0438\u0432\u043b\u0435\u0447\u0435\u043d\u043d\u044b\u0445 \u043b\u0438\u0434\u043e\u0432 (ads)",
      },
      order_api: 994,
    },
    clicks: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "ads",
        label: "\u041a\u043b\u0438\u043a\u0438 (ads)",
      },
      order_api: 999,
    },
    impressions: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "ads",
        label: "\u041f\u043e\u043a\u0430\u0437\u044b (ads)",
      },
      order_api: 996,
    },
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "",
        group: "ads",
        label:
          "\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0441\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0439 \u0441\u0435\u0442\u0438 (ads)",
      },
      order: 2,
      order_api: 993,
    },
    spent: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "ads",
        label: "\u041f\u043e\u0442\u0440\u0430\u0447\u0435\u043d\u043e (ads)",
      },
      order_api: 1000,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "",
        group: "ads",
        label: "\u0414\u0430\u0442\u0430 (ads)",
      },
      order: 1,
      order_api: 998,
    },
    stats_month: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label: "\u041d\u043e\u043c\u0435\u0440 \u043c\u0435\u0441\u044f\u0446\u0430 (ads)",
      },
      order_api: 995,
    },
    stats_year: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label: "\u0413\u043e\u0434 (ads)",
      },
      order_api: 997,
    },
    unique_reach_from_api: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "ads",
        label: "\u041e\u0445\u0432\u0430\u0442 (ads)",
      },
      order_api: 992,
    },
  },
  prefix: "a",
};

const communityConfig: IConfigSrc = {
  fields: {
    facebook: {
      network_name: "network_name",
      page_fan_adds: "subscribed",
      page_fan_removes: "unsubscribed",
      page_fans: "members_count",
      page_impressions_unique: "reach",
      page_positive_feedback_by_type_comment: "comments",
      page_positive_feedback_by_type_like: "likes",
      page_positive_feedback_by_type_link: "reposts",
      per_day_post_count: "posts_count",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
    },
    instagram: {
      followers_count: "members_count",
      instagram_account_name: "page_name",
      network_name: "network_name",
      new_followers_count: "subscribed",
      per_day_comments_count: "comments",
      per_day_like_count: "likes",
      per_day_post_count: "posts_count",
      reach: "reach",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
      unsubscribe_day_count: "unsubscribed",
    },
    vk: {
      activity_comments: "comments",
      activity_copies: "reposts",
      activity_likes: "likes",
      activity_subscribed: "subscribed",
      activity_unsubscribed: "unsubscribed",
      group_members_count: "members_count",
      network_name: "network_name",
      reach_reach: "reach",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
      vkg_per_day_post_count: "posts_count",
    },
  },
  mapping: {
    comments: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "page",
        label:
          "\u041a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 997,
    },
    likes: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Likes per day.",
        group: "page",
        label: "\u041b\u0430\u0439\u043a\u0438 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 1000,
    },
    members_count: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Members count.",
        group: "page",
        label: "\u0423\u0447\u0430\u0441\u0442\u043d\u0438\u043a\u0438 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 989,
    },
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "Social Network Name",
        group: "page",
        label:
          "\u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0435\u0442\u044c(\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order: 2,
      order_api: 991,
    },
    posts_count: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Posts count per day.",
        group: "page",
        label:
          "\u041f\u043e\u0441\u0442\u043e\u0432 \u0432 \u0434\u0435\u043d\u044c (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 993,
    },
    reach: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Reach per day.",
        group: "page",
        label: "\u041e\u0445\u0432\u0430\u0442 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 999,
    },
    reposts: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Reposts per day.",
        group: "page",
        label: "\u0420\u0435\u043f\u043e\u0441\u0442\u044b (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 998,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "Date in format YYYY-MM-DD",
        group: "page",
        label: "\u0414\u0430\u0442\u0430 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order: 1,
      order_api: 996,
    },
    stats_month: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label:
          "\u041d\u043e\u043c\u0435\u0440 \u043c\u0435\u0441\u044f\u0446\u0430 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 992,
    },
    stats_year: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label: "\u0413\u043e\u0434 (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 995,
    },
    subscribed: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Subscribed per day.",
        group: "page",
        label: "Subscribed (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 994,
    },
    unsubscribed: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Unsubscribed per day.",
        group: "page",
        label: "Unsubscribed (\u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430)",
      },
      order_api: 990,
    },
  },
  prefix: "b",
};

const postConfig: IConfigSrc = {
  aggregation: {
    fb: {
      post_sum_actions: {
        default: 0,
        fields: ["fbp_post_activity_by_action_type_comment", "fbp_post_reactions_by_type_total", "fbp_shares"],
        type: "ROW_SUM",
      },
    },
    inst: {
      post_sum_actions: {
        default: 0,
        fields: ["instagram_media_comments_count", "instagram_media_like_count", "saved"],
        type: "ROW_SUM",
      },
    },
    vk: {
      post_sum_actions: {
        default: 0,
        fields: ["calculate_comments", "calculate_likes", "calculate_reposts"],
        type: "ROW_SUM",
      },
    },
  },
  fields: {
    facebook: {
      fbp_id: "post_id",
      fbp_network_name: "network_name",
      fbp_page_fans: "post_members_count",
      fbp_post_activity_by_action_type_comment: "post_comments",
      fbp_post_reactions_by_type_total: "post_like",
      fbp_shares: "post_reposts",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
    },
    instagram: {
      followers_count: "post_members_count",
      instagram_media_comments_count: "post_comments",
      instagram_media_id: "post_id",
      instagram_media_like_count: "post_like",
      instagram_media_type: "post_type",
      network_name: "network_name",
      reach: "post_reach",
      saved: "post_saved",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
    },
    vk: {
      calculate_comments: "post_comments",
      calculate_likes: "post_like",
      calculate_reposts: "post_reposts",
      group_members_count_on_day_post: "post_members_count",
      network_name: "network_name",
      post_id: "post_id",
      stats_day: "stats_day",
      stats_month: "stats_month",
      stats_year: "stats_year",
    },
  },
  mapping: {
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "",
        group: "page",
        label:
          "\u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0435\u0442\u044c (\u043f\u043e\u0441\u0442)",
      },
      order: 2,
      order_api: 993,
    },
    post_comments: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "\u041a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 991,
    },
    post_id: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "id \u043f\u043e\u0441\u0442\u0430",
      },
      order_api: 1000,
    },
    post_like: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "\u041b\u0430\u0439\u043a\u0438 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 999,
    },
    post_members_count: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "\u041f\u043e\u0434\u043f\u0438\u0441\u0447\u0438\u043a\u0438 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 989,
    },
    post_reach: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "Охват поста",
      },
      order_api: 990,
    },
    post_reposts: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "\u0420\u0435\u043f\u043e\u0441\u0442\u044b (\u043f\u043e\u0441\u0442)",
      },
      order_api: 992,
    },
    post_saved: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label: "\u0421\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u044f (\u043f\u043e\u0441\u0442)",
      },
      order_api: 996,
    },
    post_sum_actions: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "post",
        label:
          "C\u0443\u043c\u043c\u0430 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0439 \u043f\u043e \u043f\u043e\u0441\u0442\u0443",
      },
      order_api: 990,
    },
    post_type: {
      class: "String",
      kwargs: {
        default: "",
        description: "",
        group: "page",
        label: "\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0430 (\u043f\u043e\u0441\u0442)",
      },
      order: 2,
      order_api: 998,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "",
        group: "post",
        label: "\u0414\u0430\u0442\u0430 (\u043f\u043e\u0441\u0442)",
      },
      order: 1,
      order_api: 997,
    },
    stats_month: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label: "\u041d\u043e\u043c\u0435\u0440 \u043c\u0435\u0441\u044f\u0446\u0430 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 994,
    },
    stats_year: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "",
        group: "Date",
        label: "\u0413\u043e\u0434 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 995,
    },
  },
  prefix: "c",
};

export default function generateMergePlanFactConfig(
  pipelines: IPipeline[] | IProjectExtended["pipelines"],
  pipelinesTypes: {
    [id in IPipelineType["id"]]: IPipelineType;
  },
): any[] {
  const configStreams: {
    [targetType: string]: any;
  } = {
    ads: {},
    community: {},
    post: {},
  };

  const groupedAvailableStreams = pipelinesTypes?.[EPipelineType.MERGE_PLAN_FACT].available_streams;

  pipelines.forEach((pipeline) => {
    pipeline.streams.forEach(({ id, source }) => {
      const subType = pipelinesTypes?.[source].streams.find((stream) => stream.id === source)?.subType;
      const provider = pipelinesTypes?.[source].media;
      const subTypeString = subType
        ? ({
            [EStreamSubType.ADS]: "ads",
            [EStreamSubType.COMMUNITY]: "community",
            [EStreamSubType.POST]: "post",
          }[subType] as "ads" | "community" | "post")
        : undefined;
      const availableStreams = subTypeString && groupedAvailableStreams?.[subTypeString];

      if (provider && subType && subTypeString && availableStreams?.includes(source)) {
        const streamConfig = {
          [EStreamSubType.ADS]: adsConfig,
          [EStreamSubType.COMMUNITY]: communityConfig,
          [EStreamSubType.POST]: postConfig,
        }[subType];

        configStreams[subTypeString][id] = {
          aggregation: (subType === EStreamSubType.POST && postConfig.aggregation?.[provider.toLowerCase()]) || undefined,
          fields: streamConfig.fields[provider],
          pipelineId: pipeline.id,
          pipelineName: pipeline.name,
          pipelineSource: pipeline.source,
          source,
        };
      }
    });
  });

  return Object.keys(configStreams).reduce((acc: any[], targetType) => {
    const hasStreams = Object.keys(configStreams[targetType]).length > 0;
    const config = {
      ads: adsConfig,
      community: communityConfig,
      post: postConfig,
    }[targetType];

    acc.push({
      group: targetType,
      mapping: config?.mapping,
      prefix: config?.prefix,
      source: EPipelineType.MERGE_PLAN_FACT,
      streams: hasStreams ? configStreams[targetType] : undefined,
    });

    return acc;
  }, []);
}
