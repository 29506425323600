import { StylesConfig } from "react-select";

export const selectStyles: StylesConfig<any, boolean> = {
  container: (base, props) => {
    const isFocused: boolean = (props as any).isFocused;
    const styles = {
      ...base,
      "&:hover": {
        boxShadow: "0 0 0 1px #505050",
      },

      background: "#ffffff",
      borderRadius: 8,
      boxShadow: "0 0 0 1px inset #CCCCCC",
    };

    if (props.isDisabled) {
      styles.boxShadow = "0px 0 0 1px #E2E2E2 inset !important";
      styles.backgroundColor = "#f6f6f6 !important";
    }

    if (isFocused) {
      styles.boxShadow = "0px 10px 40px #00000024 !important";
    }

    return styles;
  },
  control: (base, props) => ({
    ...base,
    background: props.isFocused && !props.isMulti ? "#E8F0F3" : "transparent",
    border: "none !important",
    borderRadius: "8px 8px 0 0",
    boxShadow: "none",
    fontSize: "0.875rem",
    minHeight: 46,
  }),
  group: (base) => ({
    ...base,
    padding: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    color: "#505050",
    fontSize: "0.875rem",
    fontWeight: 600,
    padding: "13px 16px",
    textTransform: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    alignItems: "flex-start",
    paddingTop: 14,
  }),
  menu: (base) => ({
    ...base,
    background: "transparent",
    boxShadow: "none",
    margin: 0,
    position: "static",
  }),
  menuList: (base) => ({
    ...base,
    border: "none",
    borderRadius: "0 0 8px 8px",
    padding: 0,
  }),
  multiValue: (base) => ({
    ...base,
    alignItems: "center",
    background: "#F1F6F8",
    borderRadius: 8,
    margin: "5px 2px",
    paddingRight: 6,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#000000",
    fontSize: 14,
    padding: "6px 0 7px 6px",
  }),
  multiValueRemove: (base) => ({
    ...base,

    "& svg": {
      height: 16,
      width: 16,
    },
    background: "transparent !important",
  }),
  option: (base, props) => ({
    ...base,
    ":hover": {
      background: props.isDisabled ? "transparent" : "#f1f6f8",
    },
    background: "transparent",
    color: props.isDisabled ? "#E2E2E2" : "#000000",
    display: props.isSelected ? "none" : "block",
    fontSize: "0.875rem",
    padding: "13px 16px",
    wordBreak: "break-word",
  }),
  valueContainer: (base, props) => ({
    "& > input": {
      height: 0,
    },

    ...base,
    alignItems: "center",
    maxWidth: "calc(100% - 40px)",
    minHeight: 38,
    overflow: "visible",
    paddingLeft: 16,
    paddingRight: props.isMulti && props.hasValue ? 2 : 16,
  }),
};
