import { t, Trans } from "@lingui/macro";
import { LockOpen } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthForm from "src/app/Auth/components/AuthForm";
import AuthTemplate from "src/app/Auth/components/AuthTemplate";
import useQueryAuthResetPassword from "src/utils/queries/auth/useQueryAuthResetPassword";

import * as classes from "./index.css";

const ResetPassword: FC = () => {
  const [email, setEmail] = useState("");
  const { isSuccess, isFetching, refetch: submitForm } = useQueryAuthResetPassword(email);

  return (
    <AuthTemplate title={<Trans>Reset Password</Trans>} icon={<LockOpen />}>
      {isSuccess ? (
        <Paper css={classes.paper} elevation={0}>
          <Typography variant="h3" color="inherit" gutterBottom>
            <Trans>Check your email</Trans>
          </Typography>

          <Typography variant="body1" color="inherit">
            <>
              <Trans>You will receive an email from us with instructions for resetting your password</Trans>.
              <br />
              <br />
              <NavLink to="/auth/signin/">
                <Trans>Back</Trans>
              </NavLink>
            </>
          </Typography>
        </Paper>
      ) : (
        <AuthForm
          onSubmit={submitForm}
          fields={[
            {
              name: "email",
              onChange: setEmail,
              title: t`Email Address`,
              type: "text",
              value: email,
            },
          ]}
          button={{ disabled: isFetching, title: <Trans>Reset</Trans> }}
          links={[
            { title: <Trans>SignIn</Trans>, to: "/auth/signin/" },
            { title: <Trans>SignUp</Trans>, to: "/auth/signup/" },
          ]}
        />
      )}
    </AuthTemplate>
  );
};

export default ResetPassword;
