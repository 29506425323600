import { useQuery, UseQueryOptions } from "react-query";

const API_URL = process.env.REACT_APP__API_BASE_URL || "http://127.0.0.1:8080/api";
const API_VERSION = process.env.REACT_APP__API_VERSION || 0;

export default function useQuerySmpToken(queryKey: string, queryOptions?: UseQueryOptions<any>) {
  return useQuery<string | null, any>(
    queryKey,
    async () =>
      (await fetch(`${API_URL}/${API_VERSION}/soother/`, {
        credentials: "include",
        method: "GET",
      }).then((resp) => resp.headers.get("X-SMP-JWT"))) || null,
    {
      refetchOnWindowFocus: false,
      retry: false,
      ...queryOptions,
    },
  );
}
