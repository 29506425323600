import { useQueryClient } from "react-query";
import { InvalidateQueryFilters } from "react-query/types/core/types";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import usePipelineAggregation from "src/utils/hoooks/usePipelineAggregation";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "pipelines-get-all";

interface IResponse {
  results: IPipeline[];
}

export default function useQueryPipelinesAllGet(queryOptions?: IUseQueryBaseOptions<IResponse, IPipelineExtended[]>) {
  const aggregatePipeline = usePipelineAggregation();

  return useQueryBase<IResponse, IPipelineExtended[]>(
    [QUERY_KEY],
    {
      select: (data) => data.results.map(aggregatePipeline),
      ...queryOptions,
    },
    {
      fetchAllPages: true,
      method: "GET",
      params: {
        limit: 1000,
        offset: 0,
      },
      url: "pipelines/",
    },
  );
}

export function useQueryPipelinesAllInvalidate() {
  const queryClient = useQueryClient();

  return (filters?: InvalidateQueryFilters) => queryClient.invalidateQueries(QUERY_KEY, filters);
}
