import { EProjectRole } from "src/enums/project";
import { IProject, IProjectMember } from "src/types/project";
import { IUser } from "src/types/user";
import { useQueryProjectMembersInvalidate } from "src/utils/queries/project/useQueryProjectMembersGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  id: IProjectMember["id"];
  project: IProject["id"];
  user: IUser["id"];
  role: EProjectRole;
}

export default function useQueryProjectMemberPatch(queryOptions?: IUseQueryMutationOptions<unknown, IInput>) {
  const invalidateMembers = useQueryProjectMembersInvalidate();

  return useMutationBase<unknown, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateMembers(args[1].project);
        queryOptions?.onSuccess?.apply(queryOptions, args);
      },
    },
    {
      method: "PATCH",
      url: (params) => `project/${params.project}/owners/${params.id}/`,
    },
  );
}
