import { t } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, Typography } from "@mui/material";
import { PropsWithChildren, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import Select, { components, OptionsType, Props as SelectProps, ValueContainerProps } from "react-select";
import { useMount, useUpdateEffect } from "react-use";
import UiButton from "src/components/UiButton";
import ChevronDown from "src/icons/ChevronDownIcon";
import CrossIcon from "src/icons/CrossIcon";
import cssc from "src/utils/emotionComposition";

import { classes } from "./index.css";
import { selectStyles } from "./reactSelectStyles.css";

export interface UiSelectOption<OptionValueType> {
  label: string;
  value: OptionValueType;
  data?: any;
  isDisabled?: boolean;
  isDropdownIndicatorDisabled?: boolean;
  desc?: string;
  displayAddButton?: boolean;
}

type UiSelectOptionValue<OptionValueType> = Pick<UiSelectOption<OptionValueType>, "label" | "value">;

export interface UiSelectPropsBase<OptionValueType> extends Omit<SelectProps, "isClearable" | "closeMenuOnSelect"> {
  options?: Array<
    | UiSelectOption<OptionValueType>
    | {
        label: string;
        options: UiSelectOption<OptionValueType>[];
      }
  >;
  fullWidth?: boolean;
  value?: OptionValueType | OptionValueType[] | null;
  label?: ReactNode;
}

export type UiSelectProps<OptionValueType> = UiSelectPropsBase<OptionValueType> &
  (
    | {
        isMulti: true;
        onChange?: (value: OptionValueType[] | null, data: any) => void;
      }
    | {
        isMulti?: false;
        onChange?: (value: OptionValueType | null, data: any) => void;
      }
  );

const UiSelect = <OptionType extends string | number>({
  isLoading,
  fullWidth,
  options,
  onChange = () => {},
  value,
  isMulti,
  isDropdownIndicatorDisabled,
  isSearchable = false,
  placeholder,
  onMenuOpen,
  onMenuClose,
  noOptionsMessage,
  label,
  displayAddButton,
  components: componentsProp,
  ...props
}: PropsWithChildren<UiSelectProps<OptionType>>) => {
  const ref = useRef<Select<UiSelectOption<OptionType>, boolean, any, any> | undefined>();
  const defaultPlaceholder = useRef(placeholder);
  const [maxHeight, setMaxHeight] = useState<number | undefined>();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(Boolean(props.menuIsOpen));

  const optionsNormalized = useMemo(
    () =>
      options?.reduce((acc: { [key: string]: UiSelectOption<OptionType> }, option) => {
        if ("options" in option) {
          option.options.forEach((subOption) => {
            acc[subOption.value] = subOption;
          });
        } else {
          acc[option.value] = option;
        }

        return acc;
      }, {}),
    [options],
  );

  const optionsArray = useMemo(() => optionsNormalized && Object.values(optionsNormalized), [optionsNormalized]);

  const valueOption = useMemo(() => {
    if (typeof value === "undefined" || value === null || !optionsNormalized) {
      return null;
    }

    if (isMulti && value instanceof Array) {
      return optionsArray?.filter((option) => value.includes(option.value));
    } else if (!(value instanceof Array)) {
      if (optionsNormalized[value]) {
        return optionsNormalized[value] || null;
      }
    }

    return null;
  }, [isMulti, optionsArray, optionsNormalized, value]);

  const checkOptionDisabled = useCallback((option: UiSelectOption<OptionType>) => Boolean(option.isDisabled), []);

  const handleChangeOption = useCallback(
    (option: UiSelectOptionValue<OptionType> | OptionsType<UiSelectOptionValue<OptionType>> | null) => {
      !isMulti && ref.current?.select.blur();

      if (isMulti && isSearchable) {
        setTimeout(() => {
          ref.current?.select.inputRef.focus();
        });
      }

      if (option === null) {
        onChange(null, null);
        return;
      }

      (onChange as (v: any, o: any) => void)(
        option instanceof Array ? option.map(({ value }) => value) : option.value,
        option instanceof Array
          ? option.map(({ value }) => optionsNormalized?.[value].data)
          : optionsNormalized?.[option.value].data || null,
      );
    },
    [isMulti, isSearchable, onChange, optionsNormalized],
  );

  const handleOpenMenu = useCallback(() => {
    onMenuOpen && onMenuOpen();
    setMenuOpen(true);
  }, [onMenuOpen]);

  const handleCloseMenu = useCallback(() => {
    ref.current?.select.blur();
    onMenuClose && onMenuClose();
    setMenuOpen(false);
  }, [onMenuClose]);

  const renderNoOptionsMessage = useCallback(() => noOptionsMessage || t`Nothing was found`, [noOptionsMessage]);

  const CustomValueContainer = useCallback(
    ({ children, ...valueContainerProps }: ValueContainerProps<any, any>) => {
      const value = valueContainerProps.getValue();

      return (
        <components.ValueContainer {...valueContainerProps} css={classes.valueContainer}>
          {!valueContainerProps.isMulti && value.length > 0 && <div css={classes.singleValue}>{value[0].label}</div>}

          {!valueContainerProps.hasValue && !displayAddButton && (
            <div css={classes.placeholder({ disabled: props.isDisabled })}>{defaultPlaceholder.current}</div>
          )}

          {children}

          {displayAddButton && !isMenuOpen && (
            <UiButton variant="outlined" disableElevation css={classes.addButton(isSearchable, valueContainerProps.hasValue)}>
              <AddIcon />
            </UiButton>
          )}
        </components.ValueContainer>
      );
    },
    [displayAddButton, isMenuOpen, isSearchable, props.isDisabled],
  );

  useUpdateEffect(() => {
    setMaxHeight(ref.current?.select.controlRef.clientHeight);
  }, [value]);

  useMount(() => {
    setMaxHeight(ref.current?.select.controlRef.clientHeight);
  });

  return (
    <>
      {label && (
        <Typography mb={1.6} component="div">
          {label}
        </Typography>
      )}

      <div css={classes.wrapper(fullWidth, isMenuOpen)} style={{ maxHeight }}>
        <Select
          ref={ref as any}
          isDisabled={isLoading || props.isDisabled}
          isLoading={isLoading}
          closeMenuOnSelect={!isMulti}
          isMulti={isMulti}
          components={{
            DropdownIndicator: (props) =>
              !isLoading && !props.isDisabled && !isDropdownIndicatorDisabled ? (
                <ChevronDown css={classes.chevron(props)} />
              ) : null,
            IndicatorSeparator: null,
            Input: (props) =>
              isSearchable ? (
                <div css={cssc([classes.control__input, isMenuOpen && classes.control__input_open])}>
                  <SearchIcon css={classes.control__input__icn} />
                  <components.Input isDisabled={true} css={classes.control__input__input} {...props} />
                </div>
              ) : null,
            LoadingIndicator: () => <CircularProgress css={classes.spinner} />,
            MenuList: ({ children, ...MenuListProps }) => (
              <components.MenuList {...MenuListProps}>{children}</components.MenuList>
            ),
            MultiValueRemove: (props) => (
              <components.MultiValueRemove {...props}>
                <CrossIcon />
              </components.MultiValueRemove>
            ),
            Option: ({ children, ...props }) => (
              <components.Option {...props}>
                {children}
                {props.data.desc && (
                  <Typography variant="caption" component="div" whiteSpace="normal">
                    {props.data.desc}
                  </Typography>
                )}
              </components.Option>
            ),
            Placeholder: () => null,
            SingleValue: () => null,
            ValueContainer: CustomValueContainer,
            ...componentsProp,
          }}
          isClearable={false}
          isSearchable={isSearchable}
          value={valueOption}
          onChange={handleChangeOption}
          options={options}
          styles={selectStyles}
          isOptionDisabled={checkOptionDisabled}
          placeholder={""}
          onMenuOpen={handleOpenMenu}
          onMenuClose={handleCloseMenu}
          noOptionsMessage={renderNoOptionsMessage}
          {...props}
        />
      </div>
    </>
  );
};

export default UiSelect;
