import { IResponseBasePagination, IResponseUser } from "src/types/response";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryUsersSearch(
  queryOptions?: IUseQueryMutationOptions<IResponseBasePagination<IResponseUser>, string>,
) {
  return useMutationBase<IResponseBasePagination<IResponseUser>, string>(queryOptions, {
    method: "GET",
    params: (q) => ({
      filter: q,
    }),
    url: "users/",
  });
}
