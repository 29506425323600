import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DataSourcesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M18,22H6a3,3,0,0,1-3-3V17a1,1,0,0,1,2,0v2a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V17a1,1,0,0,1,2,0v2A3,3,0,0,1,18,22Z" />
      <path d="M17,10a1,1,0,0,1-.71-.29L12,5.41,7.71,9.71A1,1,0,0,1,6.29,8.29l5-5a1,1,0,0,1,1.42,0l5,5a1,1,0,0,1,0,1.42A1,1,0,0,1,17,10Z" />
      <path d="M12,17a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V16A1,1,0,0,1,12,17Z" />
    </SvgIcon>
  );
}
