import { Trans } from "@lingui/macro";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import { IconRefresh, IconRefreshAlert } from "@tabler/icons";
import { FC, useCallback, useMemo, useState } from "react";
import UiButton from "src/components/UiButton";
import Confirm from "src/components/UiDialogConfirm";
import { ICredential } from "src/types/credential";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";
import useQueryCredentialDelete from "src/utils/queries/credentials/useQueryCredentialDelete";
import { useQueryCredentialsInvalidate } from "src/utils/queries/credentials/useQueryCredentialsGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

import * as classes from "./index.css";

const CellActions: FC<ICredential> = ({ id, name, used_by_streams, medium, lost_at }) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const credentialsInvalidate = useQueryCredentialsInvalidate();
  const { auth, status } = useSocialConnect();
  const { mutateAsync: deleteCred, isLoading: isDeleting } = useQueryCredentialDelete({
    onSuccess: () => {
      credentialsInvalidate();
    },
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const pipelineTypesArray = useMemo(() => pipelineTypes && Object.values(pipelineTypes), [pipelineTypes]);
  const authIndex = useMemo(
    () => pipelineTypesArray?.find(({ media }) => media === medium)?.authIndex,
    [medium, pipelineTypesArray],
  );

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleAccept = useCallback(async () => {
    try {
      await deleteCred({ id });
    } finally {
      handleCloseModal();
    }
  }, [deleteCred, handleCloseModal, id]);

  const handleAuthorize = useCallback(() => {
    if (authIndex) {
      auth({
        action: "connect",
        providerId: Number(authIndex),
      });
    }
  }, [auth, authIndex]);

  return (
    <>
      <Stack direction="row" spacing={5} css={classes.actions}>
        <UiButton
          loading={status === "loading"}
          onClick={handleAuthorize}
          color={lost_at ? "secondary" : "primary"}
          variant="outlined"
          disableElevation
          startIcon={lost_at ? <IconRefreshAlert /> : <IconRefresh />}
        >
          {lost_at ? <Trans>Authorize</Trans> : <Trans>Refresh</Trans>}
        </UiButton>

        <UiButton onClick={handleOpenModal} variant="text" startIcon={<DeleteIcon />} css={classes.btnDelete} />
      </Stack>

      {isModalOpen && (
        <Confirm
          open
          isLoaded={isDeleting}
          handleClose={handleCloseModal}
          handleAction={handleAccept}
          text={
            used_by_streams ? (
              <Trans>
                All active streams that use account <b>{name}</b> will be stopped, are you sure you want to delete?
              </Trans>
            ) : (
              <Trans>
                Are you sure you want to delete account <b>{name}</b>?
              </Trans>
            )
          }
          maxWidth="xs"
        />
      )}
    </>
  );
};

export default CellActions;
