import { EPipelineMedia, EPipelineType } from "src/enums/pipeline";
import { EStreamSubType } from "src/enums/stream";
import { IPipeline, IPipelineType } from "src/types/pipeline";
import { IProjectExtended } from "src/types/project";

type IConfigFieldsKey = Exclude<EPipelineMedia, EPipelineMedia.YA> | "yandex_direct_ad" | "yandex_direct_campaign";

interface IConfigSrc {
  mapping: { [key: string]: object };
  fields: { [key in IConfigFieldsKey]?: object };
  prefix: "a" | "b" | "c";
}

const adsConfig: IConfigSrc = {
  fields: {
    facebook: {
      account_currency: "account_currency",
      account_id: "account_id",
      ad_id: "ad_id",
      ad_utm_campaign: "utm_campaign",
      ad_utm_content: "utm_content",
      ad_utm_medium: "utm_medium",
      ad_utm_source: "utm_source",
      ad_utm_term: "utm_term",
      campaign_name: "campaign_name",
      clicks: "clicks",
      conversions_lead: "conversions_lead",
      impressions: "impressions",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      reach: "unique_reach_from_api",
      spend: "spent",
      stats_day: "stats_day",
      unique_actions_like: "join_rate",
      unique_actions_onsite_conversion_messaging_first_reply: "action_lead_chat",
      unique_inline_link_clicks: "link_clicks",
    },
    mytarget: {
      account_name: "account_id",
      ad_id: "ad_id",
      ad_utm_campaign: "utm_campaign",
      ad_utm_content: "utm_content",
      ad_utm_medium: "utm_medium",
      ad_utm_source: "utm_source",
      ad_utm_term: "utm_term",
      base_clicks: "clicks",
      base_shows: "impressions",
      base_spent: "spent",
      campaign_name: "campaign_name",
      currency: "account_currency",
      events_clicks_on_external_url: "link_clicks",
      events_joinings: "join_rate",
      leads: "conversions_lead",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
      uniques_reach: "unique_reach_from_api",
    },
    tiktok: {
      account_currency: "account_currency",
      account_id: "account_id",
      action_lead_chat: "action_lead_chat",
      campaign_name: "campaign_name",
      clicks: "clicks",
      conversions_lead: "conversions_lead",
      impressions: "impressions",
      join_rate: "join_rate",
      link_clicks: "link_clicks",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      reach: "unique_reach_from_api",
      spend: "spent",
      stats_day: "stats_day",
    },
    vk: {
      account_currency: "account_currency",
      ad_account_id: "account_id",
      ads_campaign_name: "campaign_name",
      ads_id: "ad_id",
      ads_lead_form_count: "conversions_lead",
      ads_utm_campaign: "utm_campaign",
      ads_utm_content: "utm_content",
      ads_utm_medium: "utm_medium",
      ads_utm_source: "utm_source",
      ads_utm_term: "utm_term",
      calculate_join_group: "join_rate",
      calculate_links: "link_clicks",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_clicks: "clicks",
      stats_day: "stats_day",
      stats_impressions: "impressions",
      stats_reach: "unique_reach_from_api",
      stats_spent: "spent",
    },
    yandex_direct_ad: {
      ad_id: "ad_id",
      campaign_name: "campaign_name",
      clicks: "clicks",
      conversions: "conversions_lead",
      cost: "spent",
      impressions: "impressions",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
      utm_campaign: "utm_campaign",
      utm_content: "utm_content",
      utm_medium: "utm_medium",
      utm_source: "utm_source",
      utm_term: "utm_term",
    },
    yandex_direct_campaign: {
      campaign_name: "campaign_name",
      clicks: "clicks",
      conversions: "conversions_lead",
      cost: "spent",
      impressions: "impressions",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
    },
  },
  mapping: {
    account_currency: {
      class: "String",
      kwargs: {
        default: "RUB",
        description: "Currency that is used by your ad account.",
        group: "ads",
        label: "\u0412\u0430\u043b\u044e\u0442\u0430",
      },
      order_api: 992,
    },
    account_id: {
      class: "String",
      kwargs: {
        default: "",
        description: "ID кабинета",
        group: "page",
        label: "ID кабинета",
      },
      order_api: 499,
    },
    action_lead_chat: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0439",
        group: "ads",
        label: "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u0439",
      },
      order_api: 991,
    },
    ad_id: {
      class: "String",
      kwargs: {
        default: "",
        description: "ID объявления",
        group: "ad",
        label: "ID объявления",
      },
      order_api: 501,
    },
    campaign_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "The name of the ad campaign you're viewing in reporting. Your campaign contains ad sets and ads.",
        group: "ads",
        label: "\u0418\u043c\u044f \u043a\u0430\u043c\u043f\u0430\u043d\u0438\u0438",
      },
      order_api: 993,
    },
    clicks: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "The number of clicks on your ads.",
        group: "ads",
        label: "\u041f\u0435\u0440\u0435\u0445\u043e\u0434\u044b",
      },
      order_api: 999,
    },
    conversions_lead: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041b\u0438\u0434 \u0444\u043e\u0440\u043c\u0430",
        group: "ads",
        label: "\u041b\u0438\u0434 \u0444\u043e\u0440\u043c\u0430",
      },
      order_api: 990,
    },
    impressions: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "The number of times your ads were on screen.",
        group: "ads",
        label: "\u041f\u043e\u043a\u0430\u0437\u044b",
      },
      order_api: 996,
    },
    join_rate: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "\u041f\u043e\u0434\u043f\u0438\u0441\u043a\u0438 \u043d\u0430 \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e",
        group: "ads",
        label: "\u041f\u043e\u0434\u043f\u0438\u0441\u043a\u0438",
      },
      order_api: 998,
    },
    link_clicks: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041f\u0435\u0440\u0435\u0445\u043e\u0434\u044b \u043f\u043e \u0441\u0441\u044b\u043b\u043a\u0435",
        group: "ads",
        label: "\u041f\u0435\u0440\u0435\u0445\u043e\u0434\u044b \u043f\u043e \u0441\u0441\u044b\u043b\u043a\u0435",
      },
      order_api: 995,
    },
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "Social Network Name",
        group: "ads",
        label: "\u0421\u043e\u0446\u0438\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0435\u0442\u044c",
      },
      order: 2,
      order_api: 994,
    },
    pipeline_id: {
      class: "Number",
      from_memory: true,
      kwargs: {
        default: 0,
        description: "ID набора данных",
        group: "page",
        label: "ID набора данных",
      },
      order: 1001,
      order_api: 498,
    },
    pipeline_name: {
      class: "String",
      from_memory: true,
      kwargs: {
        default: "",
        description: "Название набора данных",
        group: "page",
        label: "Название набора данных",
      },
      order: 1002,
      order_api: 497,
    },
    spent: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule. This metric is estimated.",
        group: "ads",
        label: "\u041f\u043e\u0442\u0440\u0430\u0447\u0435\u043d\u043e",
      },
      order_api: 1000,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "Date in format YYYY-MM-DD",
        group: "ads",
        label: "Date",
      },
      order: 1,
      order_api: 997,
    },
    unique_reach_from_api: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Audience reach.",
        group: "ads",
        label: "\u041e\u0445\u0432\u0430\u0442",
      },
      order_api: 989,
    },
    utm_campaign: {
      class: "String",
      kwargs: {
        default: "",
        description: "Utm campaign",
        group: "ads",
        label: "Utm campaign",
      },
      order: 62,
      order_api: 1018,
    },
    utm_content: {
      class: "String",
      kwargs: {
        default: "",
        description: "Utm content",
        group: "ads",
        label: "Utm content",
      },
      order: 64,
      order_api: 1016,
    },
    utm_medium: {
      class: "String",
      kwargs: {
        default: "",
        description: "Utm medium",
        group: "ads",
        label: "Utm medium",
      },
      order: 61,
      order_api: 1019,
    },
    utm_source: {
      class: "String",
      kwargs: {
        default: "",
        description: "Utm source",
        group: "ads",
        label: "Utm source",
      },
      order: 60,
      order_api: 1020,
    },
    utm_term: {
      class: "String",
      kwargs: {
        default: "",
        description: "Utm term",
        group: "ads",
        label: "Utm term",
      },
      order: 63,
      order_api: 1017,
    },
  },
  prefix: "a",
};

const communityConfig: IConfigSrc = {
  fields: {
    facebook: {
      network_name: "network_name",
      page_fan_adds_by_paid_non_paid_unique_total: "subscribed",
      page_fan_removes: "unsubscribed",
      page_fans: "members_count",
      page_id: "page_id",
      page_impressions_unique: "reach",
      page_name: "page_name",
      page_positive_feedback_by_type_comment: "comments",
      page_positive_feedback_by_type_like: "likes",
      page_positive_feedback_by_type_link: "reposts",
      per_day_post_count: "posts_count",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
    },
    instagram: {
      followers_count: "members_count",
      instagram_account_id: "page_id",
      instagram_account_name: "page_name",
      network_name: "network_name",
      new_followers_count: "subscribed",
      per_day_comments_count: "comments",
      per_day_like_count: "likes",
      per_day_post_count: "posts_count",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      reach: "reach",
      stats_day: "stats_day",
      unsubscribe_day_count: "unsubscribed",
    },
    ok: {
      okg_comments: "comments",
      okg_group_id: "page_id",
      okg_group_name: "page_name",
      okg_left_members: "unsubscribed",
      okg_likes: "likes",
      okg_members_count: "members_count",
      okg_network_name: "network_name",
      okg_new_members: "subscribed",
      okg_per_day_post_count: "posts_count",
      okg_reach_earned: "reach",
      okg_reshares: "reposts",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
    },
    vk: {
      activity_comments: "comments",
      activity_copies: "reposts",
      activity_likes: "likes",
      activity_subscribed: "subscribed",
      activity_unsubscribed: "unsubscribed",
      group_id: "page_id",
      group_members_count: "members_count",
      group_name: "page_name",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      reach_reach: "reach",
      stats_day: "stats_day",
      vkg_per_day_post_count: "posts_count",
    },
  },
  mapping: {
    comments: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Comments per day.",
        group: "page",
        label: "Comments",
      },
      order_api: 997,
    },
    likes: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Likes per day.",
        group: "page",
        label: "Likes",
      },
      order_api: 1000,
    },
    members_count: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Members count.",
        group: "page",
        label: "Members count",
      },
      order_api: 990,
    },
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "Social Network Name",
        group: "page",
        label: "C\u0435\u0442\u044c",
      },
      order: 2,
      order_api: 992,
    },
    page_id: {
      class: "String",
      kwargs: {
        default: "",
        description: "ID страницы",
        group: "page",
        label: "ID страницы",
      },
      order: 50,
      order_api: 499,
    },
    page_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "\u0418\u043c\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0430.",
        group: "page",
        label: "\u0418\u043c\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0430",
      },
      order_api: 996,
    },
    pipeline_id: {
      class: "Number",
      from_memory: true,
      kwargs: {
        default: 0,
        description: "ID набора данных",
        group: "page",
        label: "ID набора данных",
      },
      order: 1001,
      order_api: 498,
    },
    pipeline_name: {
      class: "String",
      from_memory: true,
      kwargs: {
        default: "",
        description: "Название набора данных",
        group: "page",
        label: "Название набора данных",
      },
      order: 1002,
      order_api: 497,
    },
    posts_count: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Posts count per day.",
        group: "page",
        label: "Posts count per day",
      },
      order_api: 993,
    },
    reach: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Reach per day.",
        group: "page",
        label: "Reach",
      },
      order_api: 999,
    },
    reposts: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Reposts per day.",
        group: "page",
        label: "Reposts",
      },
      order_api: 998,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "Date in format YYYY-MM-DD",
        group: "page",
        label: "Date",
      },
      order: 1,
      order_api: 995,
    },
    subscribed: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Subscribed per day.",
        group: "page",
        label: "Subscribed",
      },
      order_api: 994,
    },
    unsubscribed: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "Unsubscribed per day.",
        group: "page",
        label: "Unsubscribed",
      },
      order_api: 991,
    },
  },
  prefix: "b",
};

const postConfig: IConfigSrc = {
  fields: {
    facebook: {
      fbp_comments: "post_comments",
      fbp_created_time: "post_date",
      fbp_full_picture: "post_full_picture",
      fbp_id: "page_id",
      fbp_likes: "post_like",
      fbp_message: "post_message",
      fbp_network_name: "network_name",
      fbp_page_name: "post_account_name",
      fbp_permalink_url: "post_permalink_url",
      fbp_post_impressions: "post_reach",
      fbp_post_impressions_fan: "post_reach_subscribe",
      fbp_post_impressions_paid: "post_reach_ads",
      fbp_post_impressions_viral: "post_reach_viral",
      fbp_post_negative_feedback: "post_negativ",
      fbp_shares: "post_reposts",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
    },
    instagram: {
      instagram_account_id: "page_id",
      instagram_account_name: "post_account_name",
      instagram_media_caption: "post_message",
      instagram_media_comments_count: "post_comments",
      instagram_media_date: "post_date",
      instagram_media_like_count: "post_like",
      instagram_media_media_url: "post_full_picture",
      instagram_media_permalink: "post_permalink_url",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      reach: "post_reach",
      saved: "post_saved",
      stats_day: "stats_day",
    },
    ok: {
      network_name: "network_name",
      okp_comments: "post_comments",
      okp_group_id: "page_id",
      okp_group_name: "post_account_name",
      okp_likes: "post_like",
      okp_negatives: "post_negativ",
      okp_post_date: "post_date",
      okp_post_image: "post_full_picture",
      okp_post_link: "post_permalink_url",
      okp_post_text: "post_message",
      okp_reach: "post_reach",
      okp_reach_earned: "post_reach_subscribe",
      okp_reach_own: "post_reach_viral",
      okp_reshares: "post_reposts",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      stats_day: "stats_day",
    },
    vk: {
      calculate_attachment_image: "post_full_picture",
      calculate_comments: "post_comments",
      calculate_likes: "post_like",
      calculate_negative: "post_negativ",
      calculate_reposts: "post_reposts",
      network_name: "network_name",
      pipeline_id: "pipeline_id",
      pipeline_name: "pipeline_name",
      post_date: "post_date",
      post_group_id: "page_id",
      post_group_name: "post_account_name",
      post_link: "post_permalink_url",
      post_text: "post_message",
      reach_reach_ads: "post_reach_ads",
      reach_reach_subscribers: "post_reach_subscribe",
      reach_reach_total: "post_reach",
      reach_reach_viral: "post_reach_viral",
      stats_day: "stats_day",
    },
  },
  mapping: {
    network_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "Social Network Name",
        group: "page",
        label: "C\u0435\u0442\u044c",
      },
      order: 2,
      order_api: 993,
    },
    page_id: {
      class: "String",
      kwargs: {
        default: "",
        description: "ID страницы",
        group: "page",
        label: "ID страницы",
      },
      order: 50,
      order_api: 499,
    },
    pipeline_id: {
      class: "Number",
      from_memory: true,
      kwargs: {
        default: 0,
        description: "ID набора данных",
        group: "page",
        label: "ID набора данных",
      },
      order: 1001,
      order_api: 498,
    },
    pipeline_name: {
      class: "String",
      from_memory: true,
      kwargs: {
        default: "",
        description: "Название набора данных",
        group: "page",
        label: "Название набора данных",
      },
      order: 1002,
      order_api: 497,
    },
    post_account_name: {
      class: "String",
      kwargs: {
        default: "",
        description: "\u0418\u043c\u044f \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0430",
        group: "page",
        label: "\u0421\u0442\u0440\u0430\u043d\u0438\u0446\u0430",
      },
      order_api: 986,
    },
    post_chat: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f \u0432 \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e",
        group: "post",
        label:
          "\u0421\u043e\u043e\u0431\u0449\u0435\u043d\u0438\u044f \u0432 \u0441\u043e\u043e\u0431\u0449\u0435\u0441\u0442\u0432\u043e",
      },
      order_api: 1000,
    },
    post_comments: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u041a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 989,
    },
    post_date: {
      class: "DateHour",
      kwargs: {
        default: "",
        description: "Date in format YYYY-MM-DD H",
        group: "post",
        label: "\u0414\u0430\u0442\u0430 \u0432\u044b\u0445\u043e\u0434\u0430 \u043f\u043e\u0441\u0442\u0430",
      },
      order_api: 999,
    },
    post_direct: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041e\u0442\u043f\u0440\u0430\u0432\u043a\u0430 \u0432 \u0434\u0438\u0440\u0435\u043a\u0442",
        group: "post",
        label: "\u041e\u0442\u043f\u0440\u0430\u0432\u043a\u0430 \u0432 \u0434\u0438\u0440\u0435\u043a\u0442",
      },
      order_api: 994,
    },
    post_full_picture: {
      class: "Image",
      kwargs: {
        default: "",
        description: "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u043e\u0441\u0442\u0430",
        group: "post",
        label: "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u043f\u043e\u0441\u0442\u0430",
      },
      order_api: 985,
    },
    post_like: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041b\u0430\u0439\u043a\u0438 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u041b\u0430\u0439\u043a\u0438 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 998,
    },
    post_message: {
      class: "String",
      kwargs: {
        default: "",
        description: "\u0422\u0435\u043a\u0441\u0442 \u043f\u043e\u0441\u0442\u0430",
        group: "post",
        label: "\u0422\u0435\u043a\u0441\u0442 \u043f\u043e\u0441\u0442\u0430",
      },
      order_api: 992,
    },
    post_negativ: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041d\u0435\u0433\u0430\u0442\u0438\u0432 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u041d\u0435\u0433\u0430\u0442\u0438\u0432 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 991,
    },
    post_permalink_url: {
      class: "URL",
      kwargs: {
        default: "",
        description: "\u0421\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u043f\u043e\u0441\u0442",
        group: "post",
        label: "\u0421\u0441\u044b\u043b\u043a\u0430 \u043d\u0430 \u043f\u043e\u0441\u0442",
      },
      order_api: 984,
    },
    post_reach: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u041e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u041e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 996,
    },
    post_reach_ads: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "\u0420\u0435\u043a\u043b\u0430\u043c\u043d\u044b\u0439 \u043e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label:
          "\u0420\u0435\u043a\u043b\u0430\u043c\u043d\u044b\u0439 \u043e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 988,
    },
    post_reach_subscribe: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "\u041e\u0445\u0432\u0430\u0442 \u043f\u043e\u0434\u043f\u0438\u0441\u0447\u0438\u043a\u043e\u0432 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label:
          "\u041e\u0445\u0432\u0430\u0442 \u043f\u043e\u0434\u043f\u0438\u0441\u0447\u0438\u043a\u043e\u0432 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 983,
    },
    post_reach_viral: {
      class: "Number",
      kwargs: {
        default: 0,
        description:
          "\u0412\u0438\u0440\u0430\u043b\u044c\u043d\u044b\u0439 \u043e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
        group: "post",
        label:
          "\u0412\u0438\u0440\u0430\u043b\u044c\u043d\u044b\u0439 \u043e\u0445\u0432\u0430\u0442 (\u043f\u043e\u0441\u0442)",
      },
      order_api: 987,
    },
    post_reposts: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u0420\u0435\u043f\u043e\u0441\u0442\u044b (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u0420\u0435\u043f\u043e\u0441\u0442\u044b (\u043f\u043e\u0441\u0442)",
      },
      order_api: 990,
    },
    post_saved: {
      class: "Number",
      kwargs: {
        default: 0,
        description: "\u0421\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u044f (\u043f\u043e\u0441\u0442)",
        group: "post",
        label: "\u0421\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u044f (\u043f\u043e\u0441\u0442)",
      },
      order_api: 995,
    },
    stats_day: {
      class: "Date",
      kwargs: {
        default: "",
        description: "Date in format YYYY-MM-DD",
        group: "post",
        label: "\u0414\u0430\u0442\u0430",
      },
      order: 1,
      order_api: 997,
    },
  },
  prefix: "c",
};

export default function generateMergeConfig(
  pipelines: IPipeline[] | IProjectExtended["pipelines"],
  pipelinesTypes: {
    [id in IPipelineType["id"]]: IPipelineType;
  },
): any[] {
  const configStreams: {
    [targetType: string]: any;
  } = {
    ads: {},
    community: {},
    post: {},
  };
  const groupedAvailableStreams = pipelinesTypes?.[EPipelineType.MERGE].available_streams;

  pipelines.forEach((pipeline) => {
    pipeline.streams.forEach(({ id, source }) => {
      const subType = pipelinesTypes?.[source].streams.find((stream) => stream.id === source)?.subType;
      const subTypeString = subType
        ? ({
            [EStreamSubType.ADS]: "ads",
            [EStreamSubType.COMMUNITY]: "community",
            [EStreamSubType.POST]: "post",
          }[subType] as "ads" | "community" | "post")
        : undefined;
      const configFieldsKey = (() => {
        if (pipelinesTypes?.[source].media === EPipelineMedia.YA) {
          if (pipelinesTypes?.[source].id === EPipelineType.YA_DIRECT) {
            return "yandex_direct_ad";
          }

          if (pipelinesTypes?.[source].id === EPipelineType.YA_DIRECT_CAMPAIGN) {
            return "yandex_direct_campaign";
          }
        }

        return pipelinesTypes?.[source].media as Exclude<EPipelineMedia, EPipelineMedia.YA>;
      })();
      const availableStreams = subTypeString && groupedAvailableStreams?.[subTypeString];

      if (configFieldsKey && subType && subTypeString && availableStreams?.includes(source)) {
        const streamConfig = {
          [EStreamSubType.ADS]: adsConfig,
          [EStreamSubType.COMMUNITY]: communityConfig,
          [EStreamSubType.POST]: postConfig,
        }[subType];

        configStreams[subTypeString][id] = {
          fields: streamConfig.fields[configFieldsKey],
          pipelineId: pipeline.id,
          pipelineName: pipeline.name,
          pipelineSource: pipeline.source,
          source,
        };
      }
    });
  });

  return Object.keys(configStreams).reduce((acc: any[], targetType) => {
    const hasStreams = Object.keys(configStreams[targetType]).length > 0;
    const config = {
      ads: adsConfig,
      community: communityConfig,
      post: postConfig,
    }[targetType];

    acc.push({
      group: targetType,
      mapping: config?.mapping,
      prefix: config?.prefix,
      source: EPipelineType.MERGE,
      streams: hasStreams ? configStreams[targetType] : undefined,
    });

    return acc;
  }, []);
}
