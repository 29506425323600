import produce from "immer";
import { EPipelineStatus, IModulesActionMap, IModulesState, IPipelinesGetSagaResponse } from "src/redux/modules/index";
import { actionCompile, COUNT, IActionT, IActionTPromiseCreator, LOADING } from "src/utils";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesSamesActionMap {
  PIPELINES_SAMES_GET__F: {};
  PIPELINES_SAMES_GET__R: {
    limit?: number;
    offset?: number;
    ordering?: string;
    status?: EPipelineStatus;
    filtered?: any;
    name?: string;
  };
  PIPELINES_SAMES_GET__S: IPipelinesGetSagaResponse;
}

export const pipelinesSamesGet = createActionCreator("PIPELINES_SAMES_GET__R");
export type IPipelinesSameGetF = IActionT<IModulesActionMap, "PIPELINES_SAMES_GET__F">;
export type IPipelinesSamesGetR = IActionT<IModulesActionMap, "PIPELINES_SAMES_GET__R">;
export type IPipelinesSamesGetS = IActionT<IModulesActionMap, "PIPELINES_SAMES_GET__S">;
export const pipelinesSamesGetPromise = createPromiseCreator<"PIPELINES_SAMES_GET__R", "PIPELINES_SAMES_GET__S">(
  pipelinesSamesGet,
);
export type IPipelinesSamesGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_SAMES_GET__R",
  "PIPELINES_SAMES_GET__S"
>;

export const pipelinesSamesReducers = {
  PIPELINES_SAMES_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesSamesState[LOADING] = false;
    }),

  PIPELINES_SAMES_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesSamesState[LOADING] = true;
    }),

  PIPELINES_SAMES_GET__S: (modulesState: IModulesState, action: IPipelinesSamesGetS): IModulesState => {
    const { meta, results } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesSamesState[LOADING] = true;
      draft.pipelinesSamesState[COUNT] = meta.count;
      draft.pipelinesSamesState.models = results;
    });
  },
};
