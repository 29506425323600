import {
  Column,
  IntegratedSorting,
  SelectionState,
  SelectionStateProps,
  SortingState,
  SortingStateProps,
} from "@devexpress/dx-react-grid";
import { Grid, TableSelection } from "@devexpress/dx-react-grid-material-ui";
import { InterpolationWithTheme } from "@emotion/core";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useUpdateEffect } from "react-use";
import UiDxTable from "src/components/UiDxTable";
import UiDxTableHeaderRow from "src/components/UiDxTableHeaderRow";
import useTableMasonry from "src/utils/hoooks/useTableMasonry";

const generateClassName = createGenerateClassName({
  seed: "devexpress-table",
});

interface IProps {
  rows: ReadonlyArray<any>;
  columns: ReadonlyArray<
    Column & {
      masonryFullWidth?: boolean;
      masonryRowSx?: BoxProps["sx"];
      css?: InterpolationWithTheme<any>;
    }
  >;
  sortingStateProps?: SortingStateProps;
  selectionStateProps?: SelectionStateProps;
  loading: boolean;
  enableSorting: boolean;
  paperElevation?: number;
  getRows?: () => Promise<any>;
  perPage?: number;
  totalRows: undefined | number;
  tableProps?: ComponentProps<typeof UiDxTable>;
  masonryBreakPoints?: ComponentProps<typeof UiDxTable>["masonryBreakPoints"];
  masonryContainerSx?: ComponentProps<typeof UiDxTable>["masonryContainerSx"];
}

const UiInfinityTable: FC<IProps> = ({
  rows,
  columns,
  sortingStateProps,
  selectionStateProps,
  loading,
  enableSorting,
  paperElevation,
  getRows,
  totalRows,
  tableProps,
  perPage,
  masonryBreakPoints,
  masonryContainerSx,
}) => {
  const { ref, inView, entry } = useInView();
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [forceLoadNextPage, setForceLoadNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const isMasonry = useTableMasonry(masonryBreakPoints);

  const data = useMemo(
    () => (typeof perPage === "number" ? rows.slice(0, currentPage * perPage) : rows),
    [currentPage, perPage, rows],
  );

  const paginationEnabled = useMemo(
    () => (getRows || typeof perPage === "number") && data.length > 0 && (totalRows || data.length) > data.length,
    [data.length, getRows, perPage, totalRows],
  );

  const renderSelectionCell = useCallback(
    (props) => {
      if (isMasonry) {
        return <TableSelection.Cell component="div" {...props} />;
      }

      return <TableSelection.Cell {...props} />;
    },
    [isMasonry],
  );

  useUpdateEffect(() => {
    if (!loading && inView && paginationEnabled) {
      if (getRows) {
        setNextPageLoading(true);

        getRows().finally(() => {
          setForceLoadNextPage(false);

          if ((entry as any)?.isVisible) {
            setForceLoadNextPage(true);
          }
        });
      } else if (typeof perPage === "number") {
        setTimeout(() => {
          setForceLoadNextPage(false);
          setCurrentPage(currentPage + 1);
          setForceLoadNextPage(true);
        }, 0);
      }
    }

    if (!inView) {
      setNextPageLoading(false);
    }
  }, [inView, forceLoadNextPage]);

  return useMemo(
    () => (
      <>
        <Paper elevation={paperElevation} sx={{ p: isMasonry ? "30px" : "0 0 60px" }}>
          <StylesProvider generateClassName={generateClassName}>
            <Grid rows={data} columns={columns}>
              {enableSorting && <SortingState {...sortingStateProps} />}
              {enableSorting && <IntegratedSorting />}

              <UiDxTable
                {...tableProps}
                masonryBreakPoints={masonryBreakPoints}
                masonryContainerSx={masonryContainerSx}
                loading={loading && !nextPageLoading}
              />
              <UiDxTableHeaderRow showSortingControls={enableSorting} />

              {selectionStateProps && <SelectionState {...selectionStateProps} />}
              {selectionStateProps && <TableSelection cellComponent={renderSelectionCell} />}
            </Grid>
          </StylesProvider>
        </Paper>

        {paginationEnabled && (
          <Box mt={2} mb={2} textAlign="center">
            <CircularProgress size={24} ref={ref} />
          </Box>
        )}
      </>
    ),
    [
      columns,
      data,
      enableSorting,
      isMasonry,
      loading,
      masonryBreakPoints,
      masonryContainerSx,
      nextPageLoading,
      paginationEnabled,
      paperElevation,
      ref,
      renderSelectionCell,
      selectionStateProps,
      sortingStateProps,
      tableProps,
    ],
  );
};

export default UiInfinityTable;
