const colors = ["#FFB900", "#D83B01", "#B50E0E", "#E81123", "#B4009E", "#5C2D91", "#0078D7", "#00B4FF", "#008272", "#107C10"];

export function calculateColor(email: string) {
  let sum = 0;
  email.split("").forEach((element: any) => {
    sum += email.charCodeAt(element);
  });
  const index = sum % colors.length;
  return colors[index];
}
