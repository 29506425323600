import { FC, useMemo } from "react";
import { useAppContext } from "src/context/app/appContext";
import { EPipelineMedia } from "src/enums/pipeline";
import { ISocialMedium } from "src/types/socialAuth";

import useStyles from "./index.css";
import FaviconDatafan from "./statics/datafan.svg";
import FaviconElama from "./statics/elama.svg";
import FaviconFb from "./statics/fb.svg";
import FaviconFbDangerous from "./statics/fb_dangerous.svg";
import FaviconGgl from "./statics/ggl.svg";
import FaviconInst from "./statics/inst.svg";
import FaviconInstDangerous from "./statics/inst_dangerous.svg";
import FaviconMyTarget from "./statics/mytarget.svg";
import FaviconOk from "./statics/ok.svg";
import FaviconPlanner from "./statics/smmplanner.svg";
import FaviconTelegram from "./statics/telegram.svg";
import FaviconTiktok from "./statics/tiktok.svg";
import FaviconVk from "./statics/vk.svg";
import FaviconYa from "./statics/ya.svg";

interface IProps {
  media: EPipelineMedia | ISocialMedium;
  fullSize?: boolean;
  size?: number;
  borderRadius?: number | string;
}

const MediaFavicon: FC<IProps> = ({ media, fullSize, size, borderRadius, ...props }) => {
  const {
    state: { isIoZone },
  } = useAppContext();
  const classes = useStyles({ borderRadius, fullSize, size });

  return useMemo(
    () => (
      <div className={classes.container} {...props}>
        <img
          src={
            {
              [EPipelineMedia.FB]: isIoZone ? FaviconFb : FaviconFbDangerous,
              [EPipelineMedia.DATAFAN]: FaviconDatafan,
              [EPipelineMedia.GOOGLE]: FaviconGgl,
              [EPipelineMedia.INSTAGRAM]: isIoZone ? FaviconInst : FaviconInstDangerous,
              [EPipelineMedia.MYTARGET]: FaviconMyTarget,
              [EPipelineMedia.OK]: FaviconOk,
              [EPipelineMedia.VK]: FaviconVk,
              [EPipelineMedia.TIKTOK]: FaviconTiktok,
              [EPipelineMedia.TELEGRAM]: FaviconTelegram,
              elama: FaviconElama,
              smmplanner: FaviconPlanner,
              yandex: FaviconYa,
            }[media]
          }
          alt={media}
        />
      </div>
    ),
    [classes.container, isIoZone, media, props],
  );
};

export default MediaFavicon;
