import { Box, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import { FC, ReactNode } from "react";
import Loader from "src/components/Loader";
import * as classes from "src/components/Section/components/Head/index.css";

import Head from "./components/Head";

const Section: FC<{
  breadcrumbs?: any;
  title?: string | ReactNode;
  TitleProps?: TypographyProps;
  isLoading?: boolean;
}> = ({ title, TitleProps, children, isLoading }) => (
  <>
    {title && (
      <Typography variant={TitleProps?.variant || "h1"} css={classes.title} {...TitleProps}>
        {title}
      </Typography>
    )}

    {isLoading && <Loader />}

    <Box sx={{ display: isLoading ? "none" : "block" }}>{children}</Box>
  </>
);

export { Head as SectionHead };

export default Section;
