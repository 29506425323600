import "./index.css";

import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import * as ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import MainRouter from "src/app/MainRouter";
import AppContextProvider from "src/context/app/appContextProvider";
import { BillingContextProvider } from "src/context/billing/billingContext";
import EmotionCacheProvider from "src/providers/EmotionCacheProvider";
import I18nLoader from "src/providers/I18nLoader";

import store, { history2 } from "./redux";
import { materialTheme } from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

(async function loadPolyfills() {
  if (typeof window.IntersectionObserver === "undefined") {
    // @ts-ignore
    await import("intersection-observer");
  }
})();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={materialTheme}>
              <EmotionCacheProvider>
                <SnackbarProvider>
                  <ConnectedRouter history={history2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <I18nLoader>
                        <BillingContextProvider>
                          <CssBaseline />
                          <div className="adBanner" id="adBanner" style={{ height: "1px", width: "1px" }} />
                          <MainRouter />
                        </BillingContextProvider>
                      </I18nLoader>
                      <ReactQueryDevtools />
                    </LocalizationProvider>
                  </ConnectedRouter>
                </SnackbarProvider>
              </EmotionCacheProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </AppContextProvider>
    </Provider>,
    document.getElementById("root") as HTMLElement,
  );
};

render();

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./app/MainRouter", render);
}
