import { css } from "@emotion/react/macro";

export const classes = {
  content: (withoutPadding?: boolean) => css`
    .MuiDialogContent-root {
      padding: ${withoutPadding ? 0 : "12px 30px"};
    }

    .MuiDialogActions-root {
      .MuiButton-root {
        box-shadow: none;
      }

      padding: 14px 30px;
      border-top: 1px solid #e2e2e2;
      flex-wrap: nowrap;
    }
  `,
  cross: css`
    margin-right: -4px;
    line-height: 0;
  `,
  head: (fullScreen?: boolean) => css`
    padding: 14px 30px;
    border-bottom: 1px solid #e2e2e2;
    flex-wrap: nowrap;
    min-height: ${fullScreen ? "82px" : "63px"};
    background: #ffffff;
    position: sticky;
    top: 0;
    border-radius: 8px 8px 0 0;
    z-index: 2;
  `,
};
