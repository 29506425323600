import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { FC, useCallback } from "react";
import { useAppContext } from "src/context/app/appContext";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import { classes } from "./index.css";

interface IProps {
  expanded: boolean;
}

const Content: FC<IProps> = ({ expanded, children }) => {
  const { data: user } = useQueryMe();
  const {
    state: { context },
  } = useAppContext();

  const gridStyles = useCallback(
    (theme: Theme) => [
      classes.container(theme),
      context !== "oauth2" && expanded && classes.container_expanded(theme),
      (!user || context === "oauth2" || context === "printer") && classes.container_without_sidebar,
    ],
    [context, expanded, user],
  );

  return (
    <Grid css={gridStyles} id="content">
      <div id="top-notices" css={classes.topNotice} />

      <main css={context !== "printer" ? classes.content : undefined}>{children}</main>
    </Grid>
  );
};

export default Content;
