import { t } from "@lingui/macro";
import { useDispatch } from "react-redux";
import { popupAdd } from "src/redux";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

export default function useQueryBillingFreeRenewal(queryOptions?: IUseQueryMutationOptions<unknown>) {
  const invalidateMe = useQueryMeInvalidate();
  const invalidateProjects = useQueryProjectsInvalidate();
  const dispatch = useDispatch();

  return useMutationBase<unknown>(
    {
      ...queryOptions,
      onSuccess: (...args) => {
        invalidateMe();
        invalidateProjects();

        dispatch(
          popupAdd({
            text: t`Subscription successfully renewal`,
          }),
        );

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      params: () => undefined,
      url: "billing/free-renewal-subscription/",
    },
  );
}
