import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = (theme: Theme) => css`
  ${"&.m-with-sidebar"} {
    ${theme.breakpoints.down(1458)} {
      width: 800px;
    }
  }

  ${theme.breakpoints.down(1280)} {
    width: 800px;
  }

  ${theme.breakpoints.down(894)} {
    width: 468px;
  }

  width: 1020px;
  margin: 0 auto;
`;

export const row = css`
  display: flex;
  width: 100%;
`;

export const row__sidebar = (theme: Theme) => css`
  width: 186px;
  flex-shrink: 0;
  padding-top: 20px;
`;

export const row__content = (theme: Theme) => css`
  position: relative;
  flex-grow: 1;
  width: calc(100% - 310px);

  ${theme.breakpoints.down(1764)} {
    width: calc(100% - 200px);
  }

  ${theme.breakpoints.down(851)} {
    width: calc(100% - 300px);
  }
`;

export const paperTariff = css`
  display: flex !important;
  flex-direction: column;
  box-shadow: 0 2px 12px #0000001a;
  padding: 30px;
  border-radius: 8px;
  height: 156px;
  color: #000000;
  text-align: center;
`;

export const paperOptions = css`
  width: auto !important;
  display: block !important;
  padding: 32px 16px 16px;
  border-radius: 8px;
  background: #f1f6f8;
  text-align: center;
`;

export const paperMobile = css`
  padding: 30px;
  margin: 0 -34px 30px;
`;

export const carousel = css`
  .slick-list {
    padding: 20px 0;
  }
`;

export const carousel_tariff = css`
  .slick-arrow {
    margin-top: -76px;
  }
`;

export const carousel_price = css`
  .slick-arrow {
    margin-top: -36px;
  }
`;

export const carousel__itemWrap = css`
  width: 148px !important;
  margin: 0 10px;
  height: 100%;
`;

export const carousel__item = css`
  height: 100%;
`;

export const option = css`
  height: 95px;
  white-space: pre-line;
`;

export const option_mobile = css`
  height: auto;
  white-space: nowrap;
`;

export const option_price = css`
  height: auto;
  padding-bottom: 20px;
`;

export const option_sidebar = css`
  padding-right: 20px;

  :first-of-type {
    margin-top: 30px;
  }
`;

export const option_sidebar_tooltip = css`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #000000;
  }
`;

export const favicon = css`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border-radius: 6px;
  overflow: hidden;
`;

export const tariffName = css`
  font-weight: 600;
  padding: 0 40px 20px;
`;

export const sidebarTitle = css`
  font-weight: 600;
  padding-bottom: 50px;
`;

export const mobileTariffName = css`
  margin-bottom: 30px;
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  color: #000000;
`;

export const mobileStack = css`
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;

  .MuiTypography-root {
    :first-of-type {
      text-align: left;
    }

    :last-of-type {
      text-align: right;
    }
  }
`;

export const mobileStack__value = css`
  flex-shrink: 1;
`;

export const mobilePrice = css`
  padding: 16px;
  border-radius: 8px;
  background: #f1f6f8;
  text-align: center;
  margin-top: 30px;
`;

export const mobilePrice__value = css`
  font-size: 36px;
  font-weight: 600;
`;
