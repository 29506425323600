import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { en, ru } from "make-plural";
import { FC, useState } from "react";
import { useMount, useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import { useAppContext } from "src/context/app/appContext";

i18n.loadLocaleData({
  en: { plurals: en },
  ru: { plurals: ru },
});

const I18nLoader: FC = ({ children }) => {
  const {
    state: { language, isIoZone },
  } = useAppContext();
  const [pending, setPending] = useState(true);
  const dir = !isIoZone ? `${language}_dangerous` : language;

  useUpdateEffect(() => {
    (async () => {
      const { messages } = await import(`src/locale/${dir}/messages.js`);
      i18n.load(language, messages);
      i18n.activate(language);
    })();
  }, [language]);

  useMount(async () => {
    const { messages } = await import(`src/locale/${dir}/messages.js`);

    i18n.load(language, messages);
    i18n.activate(language);
    setPending(false);
  });

  return !pending ? (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      {children}
    </I18nProvider>
  ) : (
    <Loader />
  );
};

export default I18nLoader;
