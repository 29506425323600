import { useAppContext } from "src/context/app/appContext";
import { EExportType } from "src/enums/export";
import { ELanguage } from "src/enums/language";
import { IPipeline } from "src/redux";
import { IExport } from "src/types/export";
import { ITmpl } from "src/types/tmpl";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IParams {
  dateTo: string;
  dateFrom: string;
  tmplId?: ITmpl["id"];
  type?: EExportType;
  report_url?: string;
  pages?: number[];
  lang?: ELanguage;
  settings?: {
    [key: string]: any;
  };
  invisibleWidgets?: string[];
}

export default function useQueryExportCreate(
  pipelineId: IPipeline["id"] | undefined,
  queryOptions?: IUseQueryMutationOptions<IExport, IParams>,
) {
  const {
    state: { language },
  } = useAppContext();

  return useMutationBase<IExport, IParams>(queryOptions, {
    method: "POST",
    params: ({ type, settings, dateFrom, dateTo, lang, pages, tmplId, report_url, invisibleWidgets }) => ({
      _type: type || EExportType.EXCEL,
      config: {
        dataVisualizerInvisibleWidgets: invisibleWidgets?.length ? invisibleWidgets : undefined,
        dataVisualizerSettings: settings && encodeURIComponent(JSON.stringify(settings)),
        date_from: dateFrom,
        date_to: dateTo,
        extra_params:
          type === EExportType.PDF
            ? {
                goto: {
                  timeout: 0,
                },
                pdf: {
                  fullPage: true,
                  width: "1280px",
                },
                scrollPage: false,
                viewport: {
                  width: 1280,
                },
                waitFor: "#report-ready",
              }
            : undefined,
        lang: lang || language || ELanguage.RU,
        pages: pages ? pages.toString() : undefined,
        template_id: tmplId,
      },
      pipeline: pipelineId,
      report_url,
    }),
    url: `pipeline/${pipelineId}/export/`,
  });
}
