import { Trans } from "@lingui/macro";
import { IconButton, Paper, PaperProps, Typography } from "@mui/material";
import { IconDots } from "@tabler/icons";
import React, { FC } from "react";
import UiButton from "src/components/UiButton";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

interface IProps extends PaperProps {
  view: "grid" | "list";
}

const CreateCard: FC<IProps> = ({ view, ...props }) => (
  <Paper css={classes.paper} {...props}>
    <div
      css={
        {
          grid: classes.card_grid,
          list: classes.card_list,
        }[view]
      }
    >
      <div css={cssc([classes.card__group, classes.card__group_main])}>
        <div css={cssc([classes.card__box, classes.card__box_picture])}>
          <div css={classes.card__icn} />
        </div>

        <div css={cssc([classes.card__group, classes.card__group_type_titles])}>
          <Typography component="div" variant="body2" css={cssc([classes.card__box, classes.card__box_type])}>
            <Trans>Create new report</Trans>
          </Typography>
        </div>
      </div>

      <div css={cssc([classes.card__group, classes.card__group_second])}>
        <div css={cssc([classes.card__group, classes.card__group_status_link])}>
          <Typography component="div" variant="body2" css={cssc([classes.card__box, classes.card__box_link])}>
            <UiButton disableElevation variant="contained" css={classes.card__btn}>
              <Trans>Create report</Trans>
            </UiButton>
          </Typography>
        </div>

        <div css={classes.card__box}>
          <IconButton disabled>
            <IconDots />
          </IconButton>
        </div>
      </div>
    </div>
  </Paper>
);

export default CreateCard;
