import { VariantType } from "notistack";

export type IPopupId = number;

export interface IPopup {
  id: IPopupId;
  importance?: VariantType;
  text: string;
}

export interface IPopups {
  [popupId: number]: IPopup;
}

export interface IPopupsState {
  popups: IPopups;
}

export const popupsState: IPopupsState = {
  popups: {},
};
