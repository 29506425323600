import { t, Trans } from "@lingui/macro";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import MediaFavicon from "src/components/MediaFavicon";
import Section from "src/components/Section";
import UiButton from "src/components/UiButton";
import { EPipelineMedia, EPipelineType } from "src/enums/pipeline";
import { EPipelineSubType, IPipeline } from "src/redux";
import logoImage from "src/statics/logo_light.svg";
import { PrivateFC } from "src/types/helpers";
import { IPipelineType } from "src/types/pipeline";
import animatedScroll from "src/utils/animatedScroll";
import cssc from "src/utils/emotionComposition";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

import * as classes from "./index.css";

type IMediaList = {
  [media in IPipelineType["media"]]?: {
    icon: string;
    label: string;
    pipelines: Array<{
      label: string;
      sourceId: IPipeline["source"];
      next: string;
    }>;
  };
};

interface ILocationState {
  isNewUser?: boolean;
}

const Welcome: PrivateFC = ({ user }) => {
  const location = useLocation<ILocationState | undefined>();
  const step2BlockRef = useRef<HTMLDivElement | null>(null);
  const { data: pipelineTypes, isLoading: pipelineTypesIsLoading } = useQueryPipelineTypes();
  const [activeMedia, setActiveMedia] = useState<IPipelineType["media"] | undefined>(undefined);

  const handleSelectMedia = useCallback(
    (media: IPipelineType["media"]) => () => {
      setActiveMedia(media);
    },
    [],
  );

  const mediaList = useMemo(
    () =>
      pipelineTypes &&
      Object.values(pipelineTypes).reduce((acc: IMediaList, { media, subType, id, onlyStaff }) => {
        if (![EPipelineSubType.MULTIPIPELINE, EPipelineSubType.ADS].includes(subType) || (onlyStaff && !user.is_staff)) {
          return acc;
        }

        acc = {
          ...acc,
          [media]: {
            ...acc[media],
            label: (
              {
                facebook: t`Facebook`,
                instagram: t`Instagram`,
                mytarget: t`MyTarget`,
                ok: t`OK.RU`,
                vk: t`VK`,
                yandex: t`Yandex`,
              } as any
            )[media],
            pipelines: [
              ...(acc[media]?.pipelines || []),
              {
                label: (() => {
                  if (id === EPipelineType.YA_DIRECT_CAMPAIGN) {
                    return t`Master campaigns`;
                  } else if (id === EPipelineType.YA_DIRECT) {
                    return t`Search and display campaigns`;
                  } else if (id === EPipelineType.INST_BA_MEDIA) {
                    return t`Advanced statistics`;
                  } else if (id === EPipelineType.INST_OPEN_ACCOUNT) {
                    return t`Competitor analysis`;
                  } else if (subType === EPipelineSubType.ADS) {
                    return t`Ad account`;
                  }

                  return (
                    {
                      facebook: t`Page`,
                      instagram: t`Instagram account`,
                      ok: t`Community`,
                      tiktok: t`TikTok`,
                      vk: t`Community`,
                    } as any
                  )[media];
                })(),
                sourceId: id,
              },
            ],
          },
        };

        return acc;
      }, {}),
    [pipelineTypes, user.is_staff],
  );

  useUpdateEffect(() => {
    if (activeMedia && step2BlockRef.current) {
      animatedScroll(undefined, step2BlockRef.current?.offsetTop);
    }
  }, [activeMedia]);

  if (pipelineTypesIsLoading) {
    return <Loader />;
  }

  if (!location.state?.isNewUser) {
    return <Redirect to="/" />;
  }

  return (
    <Section>
      <Container css={classes.container} disableGutters>
        <Paper css={classes.paper}>
          <img alt="Datafan" src={logoImage} css={classes.logo} />

          <Typography variant="h2" css={classes.title} color="primary">
            <Trans>Welcome to DataFan!</Trans>
          </Typography>

          <Typography variant="h2" css={classes.title}>
            <Trans>
              Select the data source and create
              <br /> your first report
            </Trans>
          </Typography>

          {mediaList && (
            <>
              <Grid css={classes.buttons} container spacing={2.4}>
                {(["instagram", "facebook", "vk", "ok", "mytarget", "tiktok", "yandex"] as EPipelineMedia[]).map((media) => {
                  const item = mediaList[media];

                  return item ? (
                    <Grid item xs="auto" key={media}>
                      <UiButton
                        fullWidth
                        disableElevation
                        variant="outlined"
                        startIcon={<MediaFavicon media={media} css={classes.btn__icon} />}
                        css={cssc([classes.btn, activeMedia === media && classes.btn_active])}
                        onClick={handleSelectMedia(media)}
                      />
                    </Grid>
                  ) : null;
                })}
              </Grid>

              <Grid container spacing={1.6} mt={2} alignItems="center" justifyContent="center">
                {activeMedia &&
                  mediaList[activeMedia]?.pipelines.map(({ label, sourceId, next }, index) => (
                    <Fragment key={sourceId}>
                      <div ref={step2BlockRef} />

                      {index > 0 && (
                        <Grid item xs={12} sm="auto">
                          <Typography textAlign="center" sx={{ color: "neutral.main" }}>
                            <Trans>or</Trans>
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12} sm="auto">
                        <UiButton fullWidth component={Link} to={`/dataset-create/${sourceId}/`}>
                          {label}
                        </UiButton>
                      </Grid>
                    </Fragment>
                  ))}
              </Grid>
            </>
          )}

          {!activeMedia && (
            <Typography css={classes.desc}>
              <Trans>
                Connect a social network account or an advertising cabinet
                <br /> to start collecting data for the report
              </Trans>
            </Typography>
          )}
        </Paper>
      </Container>
    </Section>
  );
};

export default Welcome;
