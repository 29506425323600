import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useMemo, useState } from "react";
import { useMount, useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import { useAppContext } from "src/context/app/appContext";
import {
  apiCarrotKnowledgeGetArticleItem,
  apiCarrotKnowledgeGetArticles,
  ICarrotKnowledgeArticleItem,
  ICarrotKnowledgeArticleListItem,
} from "src/utils/apiCarrotKnowledge";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import SmmSchoolLogo from "./assets/smm-school.svg";
import YoutubeLogo from "./assets/youtube.svg";
import useStyles from "./index.css";

const Support: FC = () => {
  const classes = useStyles();
  const {
    state: { language },
  } = useAppContext();
  const { data: user } = useQueryMe();
  const [useStaticArticles, setUseStaticArticles] = useState(!process.env.REACT_APP__API_CARROT_KNOWLEDGE_URL);
  const [isPending, setPending] = useState(false);

  const [dynamicArticles, setDynamicArticles] = useState<ICarrotKnowledgeArticleListItem[] | undefined>();

  const [currentDynamicArticleId, setCurrentDynamicArticleId] = useState<ICarrotKnowledgeArticleItem["id"] | undefined>();

  const [dynamicArticlesContent, setDynamicArticlesContent] = useState<
    {
      [key in ICarrotKnowledgeArticleItem["id"]]: ICarrotKnowledgeArticleItem["content"] | undefined | null;
    }
  >({});

  const staticArticles = useMemo(
    () => [
      [
        t`What are dynamic reports?`,
        t`These are reports that update data automatically. The frequency of data updates in the report is from one time per day to once per hour, depending on the tariff.`,
      ],
      [
        t`What does means an unlimited number of reports?`,
        t`You can create as many reports as you need. DataFan does not impose restrictions on the number of reports you create on any tariff.`,
      ],
      [
        t`What is a dataset?`,
        t`This is the data of your public or advertising account that you will use in the report. The dataset is updated, i.e. it collects new data from your public.`,
      ],
      [t`What social networks are available?`, t`VK, Facebook, Instagram, Odnoklassniki.`],
      [t`What advertising platforms are available?`, "VK Business, Facebook Business Manager, MyTarget."],
    ],
    [],
  );

  const handleChangeDynamic = useCallback(
    (id: ICarrotKnowledgeArticleItem["id"]) => (e: any, isExpanded: boolean) => {
      setCurrentDynamicArticleId(isExpanded ? id : undefined);
    },
    [],
  );

  useMount(async () => {
    if (!useStaticArticles) {
      try {
        setPending(true);

        const { error, payload } = await apiCarrotKnowledgeGetArticles(language);

        setUseStaticArticles(error);

        if (!error && payload) {
          const articles = payload.filter((article) => article.public);

          setUseStaticArticles(!articles.length);

          if (articles.length) {
            setDynamicArticles(articles);
          }
        }
      } finally {
        setPending(false);
      }
    }
  });

  useUpdateEffect(() => {
    (async () => {
      if (currentDynamicArticleId !== undefined && !dynamicArticlesContent[currentDynamicArticleId]) {
        const { error, payload } = await apiCarrotKnowledgeGetArticleItem(currentDynamicArticleId);

        setDynamicArticlesContent((state) => ({
          ...state,
          [currentDynamicArticleId]: error || !payload ? null : payload.content,
        }));
      }
    })();
  }, [currentDynamicArticleId]);

  const emailAddress = useMemo(
    () =>
      user?.isElamaUser && process.env.REACT_APP__SUPPORT_ELAMA_MAIL
        ? process.env.REACT_APP__SUPPORT_ELAMA_MAIL
        : process.env.REACT_APP__SUPPORT_MAIL,
    [user?.isElamaUser],
  );

  const dynamicContent = useMemo(() => {
    const content = currentDynamicArticleId !== undefined ? dynamicArticlesContent[currentDynamicArticleId] : undefined;

    if (currentDynamicArticleId === undefined) {
      return null;
    } else if (content === undefined) {
      return <Loader />;
    } else if (content === null) {
      return (
        <div className={classes.error}>
          <Trans>Something went wrong. Please contact technical support and we will help you</Trans>
        </div>
      );
    } else {
      return <div dangerouslySetInnerHTML={{ __html: content }} className={classes.content} />;
    }
  }, [classes.content, classes.error, currentDynamicArticleId, dynamicArticlesContent]);

  if (isPending) {
    return <Loader />;
  }

  return (
    <>
      {user?.isElamaUser && emailAddress && (
        <Box component={Paper} p={2} mb={3}>
          <Trans>
            In case of any questions please refer to{" "}
            <Link component="a" href={`mailto:${emailAddress}`}>
              {emailAddress}
            </Link>
            .
          </Trans>
        </Box>
      )}

      <Paper>
        <Grid container>
          {emailAddress && (
            <Grid item xs={12} md={4}>
              <List disablePadding>
                <ListItem button component="a" href={`mailto:${emailAddress}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={<Trans>Support by email</Trans>} />
                </ListItem>
              </List>
            </Grid>
          )}

          {!user?.isElamaUser && (
            <>
              <Grid item xs={12} md={4}>
                <List disablePadding>
                  <ListItem button component="a" target="_blank" href="https://vk.me/datafan?ref=widget">
                    <ListItemAvatar>
                      <Avatar>
                        <ChatIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Trans>Chat VK</Trans>} />
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} md={4}>
                <List disablePadding>
                  <ListItem button component="a" target="_blank" href="https://t.me/datafan">
                    <ListItemAvatar>
                      <Avatar>
                        <ChatIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Trans>Chat Telegram</Trans>} />
                  </ListItem>
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      <Box mt={3}>
        {useStaticArticles ? (
          staticArticles.map(([question, answer], idx) => (
            <Accordion key={idx} className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <IconButton size="large">
                    <AddIcon />
                  </IconButton>
                }
                aria-controls={`${idx}-content`}
                id={`${idx}-header`}
              >
                <Typography className={classes.heading} variant="subtitle2">
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : dynamicArticles === undefined ? (
          <Loader />
        ) : (
          dynamicArticles.map(({ name, id }) => (
            <Accordion
              key={id}
              className={classes.accordion}
              onChange={handleChangeDynamic(id)}
              expanded={currentDynamicArticleId === id}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton size="large">
                    <AddIcon />
                  </IconButton>
                }
                aria-controls={`${id}-content`}
                id={`${id}-header`}
                className={classes.accordionHeader}
              >
                <Typography className={classes.heading} variant="subtitle2">
                  {name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{dynamicContent}</AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>

      <Box mt={5}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <a href="https://www.youtube.com/channel/UCVcCvWXVOkxJabaJDxTZ-jQ" target="_blank" rel="noopener noreferrer">
              <img src={YoutubeLogo} alt="Youtube" height={30} />
            </a>
          </Grid>

          <Grid item>
            <a href="https://smm.school/kurs-google-data-studio" target="_blank" rel="noopener noreferrer">
              <img src={SmmSchoolLogo} alt="Smm.School" height={30} />
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Support;
