import { Trans } from "@lingui/macro";
import { DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import { IPipeline } from "src/types/pipeline";
import { pipelineMetaIsSpreadsheet } from "src/utils/guards/pipelineMeta";

interface IProps {
  pipeline: IPipeline;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DialogRemove: FC<IProps> = ({ pipeline, isOpen, isLoading, onClose, onSubmit }) => (
  <UiDialog open={isOpen} title={<Trans>Delete</Trans>} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogContent>
      <Typography component="div" variant="body2">
        {pipeline.is_use_in_merge && (
          <Box mt={1} mb={1}>
            <Trans>This dataset is involved in the Merge dataset.</Trans>
          </Box>
        )}

        {!!pipeline.pipeline_meta?.length && (
          <Box mt={1} mb={1}>
            <Trans>This dataset is used in the Google Sheets:</Trans>
            {pipeline.pipeline_meta.map(
              (meta, idx) =>
                pipelineMetaIsSpreadsheet(meta) && (
                  <div key={idx}>
                    <Link component="a" href={meta.pipeline_meta.spreadsheet.url} target="_blank">
                      {meta.pipeline_meta.spreadsheet.name}
                    </Link>
                  </div>
                ),
            )}
          </Box>
        )}
        <Trans>Are you sure you want to delete this entry?</Trans>
      </Typography>
    </DialogContent>

    <DialogActions>
      <UiButton disabled={isLoading} onClick={onClose}>
        <Trans>Cancel</Trans>
      </UiButton>

      <UiButton loading={isLoading} onClick={onSubmit} variant="outlined">
        <Trans>Ok</Trans>
      </UiButton>
    </DialogActions>
  </UiDialog>
);

export default DialogRemove;
