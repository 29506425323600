import React, { FC, useMemo } from "react";
import { useMount } from "react-use";
import { useBillingContext } from "src/context/billing/billingContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IUser } from "src/types/user";
import { parseQueryString } from "src/utils";

import OfferWithActiveSubscription from "./components/OfferWithActiveSubscription";
import OfferWithoutActiveSubscription from "./components/OfferWithoutActiveSubscription";

interface IProps {
  user: IUser;
}

const Offer: FC<IProps> = ({ user }) => {
  const {
    actions: { openModalSubscriptionOrder },
  } = useBillingContext();

  const activeSubscription = useMemo(() => user?.active_subscription, [user?.active_subscription]);

  const prevSubscription = useMemo(() => user?.previous_subscription, [user?.previous_subscription]);

  useMount(() => {
    const queryParams = parseQueryString();
    const queryParamsBuy = queryParams.get("buy");
    const tariffSignByLink = queryParamsBuy
      ? {
          agent: ETariffOnPeriodSign.AGENT,
          professional: ETariffOnPeriodSign.PROFESSIONAL,
          start: ETariffOnPeriodSign.START,
        }[queryParamsBuy]
      : undefined;

    if (tariffSignByLink) {
      openModalSubscriptionOrder({
        onClose: () => {
          window.history.replaceState(null, "", window.location.pathname);
        },
        renewalWithFreeDays: false,
        tariffSign: tariffSignByLink,
      });
    }
  });

  if (activeSubscription) {
    return <OfferWithActiveSubscription subscription={activeSubscription} user={user} />;
  } else if (prevSubscription) {
    return <OfferWithoutActiveSubscription subscription={prevSubscription} user={user} />;
  } else {
    return null;
  }
};

export default Offer;
