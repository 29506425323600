import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ConnectorPowerBiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.34887 18.3895C8.34683 18.7363 8.20819 19.0682 7.963 19.3134C7.71781 19.5586 7.38585 19.6973 7.03911 19.6993C6.86583 19.7038 6.69345 19.6729 6.53245 19.6087C6.37145 19.5445 6.22519 19.4482 6.10255 19.3257C5.97992 19.2032 5.88347 19.057 5.81907 18.8961C5.75467 18.7352 5.72366 18.5628 5.72793 18.3895V12.7778C5.7296 12.4305 5.86828 12.098 6.11381 11.8525C6.35934 11.6069 6.69188 11.4683 7.03911 11.4666C7.21239 11.4623 7.38474 11.4933 7.54567 11.5577C7.7066 11.6221 7.85275 11.7186 7.97526 11.8412C8.09776 11.9638 8.19405 12.1101 8.25828 12.2711C8.3225 12.4321 8.35333 12.6045 8.34887 12.7778V18.3895Z"
        fill="black"
      />
      <path
        d="M12.0953 19.8878C12.0933 20.2348 11.9545 20.5669 11.709 20.8122C11.4635 21.0574 11.1312 21.1959 10.7842 21.1975C10.6142 21.2004 10.4455 21.1681 10.2887 21.1024C10.1319 21.0368 9.99041 20.9394 9.87318 20.8163C9.75596 20.6932 9.66555 20.5471 9.60766 20.3873C9.54977 20.2275 9.52567 20.0574 9.53687 19.8878V11.3339C9.539 10.9868 9.67784 10.6546 9.92328 10.4092C10.1687 10.1638 10.501 10.0251 10.848 10.0231C11.0214 10.0188 11.1938 10.0498 11.3548 10.1142C11.5158 10.1786 11.6621 10.275 11.7847 10.3976C11.9073 10.5202 12.0037 10.6665 12.0681 10.8275C12.1325 10.9885 12.1635 11.1609 12.1592 11.3342V19.8878H12.0953Z"
        fill="black"
      />
      <path
        d="M4.54099 17.1394C4.53849 17.4864 4.39955 17.8184 4.1542 18.0638C3.90884 18.3092 3.57679 18.4481 3.22981 18.4506C3.05649 18.4548 2.88412 18.4238 2.72316 18.3594C2.5622 18.2949 2.41599 18.1985 2.29342 18.0759C2.17084 17.9533 2.07445 17.8071 2.01009 17.6461C1.94572 17.4851 1.91473 17.3127 1.91899 17.1394V14.0812C1.92066 13.734 2.05929 13.4015 2.30474 13.156C2.5502 12.9105 2.88264 12.7718 3.22981 12.77C3.57693 12.772 3.90925 12.9108 4.1547 13.1563C4.40015 13.4017 4.53895 13.7341 4.54099 14.0812V17.1394Z"
        fill="black"
      />
      <path
        d="M15.8422 21.2607C15.8398 21.6076 15.7008 21.9395 15.4554 22.1847C15.21 22.4298 14.8779 22.5684 14.531 22.5705C14.1942 22.5554 13.8762 22.4107 13.6437 22.1666C13.4111 21.9225 13.282 21.5979 13.2833 21.2607V9.896C13.2833 9.54821 13.4215 9.21466 13.6674 8.96874C13.9133 8.72281 14.2469 8.58465 14.5947 8.58465C14.9425 8.58465 15.276 8.72281 15.522 8.96874C15.7679 9.21466 15.906 9.54821 15.906 9.896V21.2607H15.8422Z"
        fill="black"
      />
      <path
        d="M19.9631 20.6985C19.7294 20.6875 19.4983 20.6456 19.2756 20.5739L17.7152 20.0748C17.5987 20.0277 17.5045 19.9379 17.4519 19.8238C17.3992 19.7096 17.3921 19.5797 17.4318 19.4605C17.4716 19.3412 17.5553 19.2416 17.6659 19.182C17.7765 19.1223 17.9057 19.107 18.0272 19.1392L19.6508 19.6382C19.8165 19.6871 19.9913 19.697 20.1615 19.667C20.3317 19.637 20.4927 19.5681 20.6318 19.4655C20.7709 19.363 20.8844 19.2296 20.9633 19.0759C21.0423 18.9221 21.0846 18.7522 21.0869 18.5794V8.14859C21.0917 7.91525 21.0216 7.68651 20.8869 7.49594C20.7522 7.30537 20.5599 7.16301 20.3383 7.08977L5.03687 2.46836C4.87186 2.41115 4.6959 2.39263 4.52259 2.41423C4.34929 2.43583 4.18326 2.49698 4.03734 2.59294C3.90573 2.7118 3.79928 2.85585 3.72429 3.01655C3.6493 3.17726 3.6073 3.35137 3.60075 3.52859V10.7088C3.60436 10.7767 3.59411 10.8446 3.57062 10.9084C3.54714 10.9721 3.51091 11.0304 3.46415 11.0798C3.41739 11.1291 3.36107 11.1683 3.29863 11.1952C3.2362 11.222 3.16895 11.2359 3.10099 11.2359C3.03303 11.2359 2.96578 11.222 2.90334 11.1952C2.84091 11.1683 2.78459 11.1291 2.73783 11.0798C2.69107 11.0304 2.65484 10.9721 2.63135 10.9084C2.60787 10.8446 2.59761 10.7767 2.60122 10.7088V3.53636C2.59401 3.20322 2.67044 2.87359 2.82354 2.57763C2.97664 2.28168 3.20151 2.02883 3.47758 1.84224C3.74073 1.6425 4.0485 1.50974 4.37437 1.45539C4.70023 1.40104 5.03443 1.42672 5.34816 1.53024L20.5832 6.15024C21.0198 6.2898 21.4005 6.56494 21.67 6.93568C21.9395 7.30641 22.0838 7.75343 22.0818 8.21177V18.6387C22.0821 18.9783 22.0032 19.3132 21.8513 19.617C21.6994 19.9207 21.4787 20.1848 21.2069 20.3882C20.8103 20.5574 20.3894 20.6626 19.9599 20.6999"
        fill="black"
      />
    </SvgIcon>
  );
}
