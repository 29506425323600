import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/styles";
import { useCallback, useMemo, useState, VFC } from "react";
import { Link } from "react-router-dom";
import { HeaderDynamicContent } from "src/components/Header";
import Loader from "src/components/Loader";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import UiInfinityTable from "src/components/UiInfinityTable";
import { useBillingContext } from "src/context/billing/billingContext";
import { EProjectRole } from "src/enums/project";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IProjectExtended } from "src/types/project";
import getRoleDescription from "src/utils/getRoleDescription";
import getRoleDisplayName from "src/utils/getRoleDisplayName";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import CellActions from "./components/CellActions";
import ModalProjectCreate from "./components/ModalProjectCreate";
import * as classes from "./index.css";

const ProjectsHome: VFC = () => {
  const {
    actions: { openModalSubscriptionAlert, isSuitableSubscription },
  } = useBillingContext();
  const { data: user } = useQueryMe();
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const projectsArray = useMemo(
    () =>
      projects
        ? [...projects].sort((p1, p2) => {
            if (p1.is_approved !== p2.is_approved) {
              return p1.is_approved ? 1 : -1;
            }

            return p1.id < p2.id ? 1 : -1;
          })
        : [],
    [projects],
  );

  const ownProjectsNum = useMemo(
    () => projectsArray.filter((project) => project.role === EProjectRole.OWNER).length,
    [projectsArray],
  );

  const handleCloseCreateModal = useCallback(() => {
    setCreateModalIsOpen(false);
  }, []);

  const handleClickCreateProject = useCallback(() => {
    if (
      user?.billing_enabled === false ||
      isSuitableSubscription(ETariffOnPeriodSign.PROFESSIONAL) ||
      user?.active_subscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA
    ) {
      setCreateModalIsOpen(true);
    } else {
      openModalSubscriptionAlert({
        action: "upgrade",
        text: (
          <Trans>
            1 project only is available. <br />
            If you want to create another project, upgrade you tariff to Pro or Agent
          </Trans>
        ),
        title: <Trans>New project</Trans>,
      });
    }
  }, [
    isSuitableSubscription,
    openModalSubscriptionAlert,
    user?.active_subscription?.tariff_on_period.sign,
    user?.billing_enabled,
  ]);

  if (projectsIsLoading) {
    return <Loader />;
  }

  return (
    <>
      <HeaderDynamicContent>
        <UiButton startIcon={<AddIcon />} onClick={handleClickCreateProject} sx={{ ml: "auto" }}>
          <Trans>Add</Trans>
        </UiButton>
      </HeaderDynamicContent>

      <Section>
        <SectionHead title={<Trans>Projects and teamwork</Trans>} />

        <UiInfinityTable
          rows={projectsArray}
          columns={[
            { name: "id", title: "ID" },
            {
              getCellValue: ({ color }: IProjectExtended) => <div css={classes.cellColor(color)} />,
              name: "color",
              title: t`Color`,
            },
            {
              getCellValue: ({ name, id }: IProjectExtended) => (
                <Link
                  to={{
                    pathname: "/",
                    state: { projectId: id },
                  }}
                  css={classes.cellLink}
                >
                  {name}
                </Link>
              ),
              name: "name",
              title: t`Name`,
            },
            {
              getCellValue: ({ role }: IProjectExtended) => (
                <Tooltip title={getRoleDescription(role)} enterDelay={500} leaveDelay={200}>
                  <div>{getRoleDisplayName(role)}</div>
                </Tooltip>
              ),
              name: "role",
              title: t`Role`,
            },
            {
              getCellValue: ({ creator, role }: IProjectExtended) =>
                role === EProjectRole.OWNER ? <Trans>You</Trans> : creator.humand_name,
              name: "owner",
              title: t`Owner`,
            },
            {
              getCellValue: (project: IProjectExtended) => <CellActions project={project} ownProjectsNum={ownProjectsNum} />,
              name: "actions",
              title: " ",
            },
          ]}
          loading={false}
          enableSorting={true}
          totalRows={projectsArray.length}
          perPage={20}
          paperElevation={8}
          tableProps={{
            columnExtensions: [
              { columnName: "id", width: 84 },
              { columnName: "color", width: 76 },
              { columnName: "name", width: isMobile ? 110 : undefined },
              { columnName: "role" },
              { columnName: "owner", width: isMobile ? 116 : undefined },
              { align: "right", columnName: "actions", width: 162 },
            ],
          }}
          sortingStateProps={{
            columnExtensions: [{ columnName: "color", sortingEnabled: false }],
          }}
          masonryBreakPoints={{
            xs: 12,
          }}
        />

        <ModalProjectCreate isOpened={createModalIsOpen || projectsArray.length === 0} onClose={handleCloseCreateModal} />
      </Section>
    </>
  );
};

export default ProjectsHome;
