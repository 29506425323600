import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = css`
  position: relative;
`;

export const dialog = css`
  .MuiPaper-root {
    max-width: 845px;
  }

  .MuiDialogContent-root {
    padding-bottom: 60px;
  }
`;

export const btn = css`
  width: 240px;
`;

export const menuItem = css`
  width: 240px;
`;

export const status = css`
  height: 4.5em;
  margin-top: 16px;
`;

export const status_loading = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
`;

export const status_error = (theme: Theme) => css`
  color: ${theme.palette.secondary.main};
`;
