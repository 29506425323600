import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = css`
  max-width: 476px;
  width: 100%;
  margin: 0 auto;
`;

export const error = (theme: Theme) => css`
  color: ${theme.palette.secondary.main};
  height: 0;
  margin-top: 20px;
  margin-bottom: -20px;
`;
