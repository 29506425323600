import { css } from "@emotion/react/macro";

export const link = css`
  text-decoration: none;
  display: block;
  margin: 0 -15px;
`;

export const icon = css`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 2px;

  img {
    width: 100%;
    border-radius: 6px;
  }
`;
