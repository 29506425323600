import { Trans } from "@lingui/macro";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC, Fragment, useMemo } from "react";
import parseFeatures from "src/app/ConnectorGDS/utils/parseFeatures";
import { useAppContext } from "src/context/app/appContext";
import { IAggTmpl } from "src/types/tmpl";

import * as classes from "./index.css";

interface IProps {
  tmpl: IAggTmpl;
}

const TmplDescription: FC<IProps> = ({ tmpl }) => {
  const {
    state: { language },
  } = useAppContext();

  const image = useMemo(() => tmpl.tmpl_image?.find((img) => img.language === language)?.image, [language, tmpl.tmpl_image]);

  const info = useMemo(
    () => tmpl.tmpl_description?.find((desc) => desc.language === language),
    [language, tmpl.tmpl_description],
  );

  const features = useMemo(
    () => (!!info?.short_description ? parseFeatures(info.short_description) : undefined),
    [info?.short_description],
  );

  return (
    <Paper variant="filled" sx={{ minHeight: "100%", padding: 3, width: "100%" }}>
      <Grid container spacing={3}>
        {image && (
          <Grid item css={classes.col}>
            <img css={classes.preview} src={image} alt={tmpl.name} />
          </Grid>
        )}

        <Grid item css={classes.col} xs>
          {info?.description && (
            <>
              <Typography gutterBottom variant="h3">
                <Trans>What's in this report?</Trans>
              </Typography>

              <Typography whiteSpace="pre-line">{info.description}</Typography>
            </>
          )}

          {features && (
            <>
              <Typography gutterBottom variant="h4" mt={info?.description ? 3 : 0}>
                <Trans>Key indicators</Trans>
              </Typography>

              {features.map((feature, idx) => (
                <Fragment key={idx}>
                  {feature.title && <Typography variant="h5">{feature.title}</Typography>}

                  <ul>
                    {feature.lines.map((line, idx) => (
                      <li key={idx}>
                        <Typography>{line}</Typography>
                      </li>
                    ))}
                  </ul>
                </Fragment>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TmplDescription;
