import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { FC, ReactChild, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "src/context/app/appContext";

interface IProps {
  to: string;
  title: string | ReactChild;
  icon: ReactChild;
  exact?: boolean;
}

const MenuItem: FC<IProps> = ({ to, exact, title, icon }) => {
  const { state } = useAppContext();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipMouseEnter = useCallback(() => {
    setShowTooltip(!state.sidebar.expanded);
  }, [state.sidebar.expanded]);

  const handleTooltipMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <ListItem button component={NavLink} to={to} exact={exact}>
      <ListItemIcon>
        <Tooltip
          open={showTooltip}
          followCursor={false}
          disableHoverListener={true}
          disableTouchListener={true}
          placement="right"
          title={title}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <span>{icon}</span>
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          color: "inherit",
          variant: "subtitle1",
        }}
        primary={title}
      />
    </ListItem>
  );
};

export default MenuItem;
