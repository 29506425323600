import { Sorting } from "@devexpress/dx-react-grid";

export const COUNT = "COUNT";
export const EDITING = "EDITING";
export const FAILURE = "FAILURE";
export const SUCCESS = "SUCCESS";
export const FILTERED = "FILTERED";
export const LOADING = "LOADING";
export const PAGE = "PAGE";
export const PAGE_SIZE = "PAGE_SIZE";
export const TEMP_ID = "TEMP_ID";
export const SORTED = "SORTED";
export const OPEN = "OPEN";
export const WINDOW_URL = "WINDOW_URL";
export const DELETING = "DELETING";
export const UPDATING = "UPDATING";
export const ITEMS = "ITEMS";
export const INITED = "INITED";

export interface IEssenceList {
  [COUNT]?: number;
  [EDITING]?: boolean;
  [FAILURE]: boolean;
  [FILTERED]: any[];
  [LOADING]: boolean;
  [PAGE]: number;
  [PAGE_SIZE]: number;
  [SORTED]?: Sorting[];
  [DELETING]?: boolean;
}
