import { plural, Trans } from "@lingui/macro";
import { Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { FC, HTMLAttributes, useCallback, useMemo, useState } from "react";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IPipelineExtended } from "src/types/pipeline";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface DataPeriodCollectionProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  pipeline: IPipelineExtended;
  onChange?: (date: string | undefined) => void;
}

const PeriodDataCollection: FC<DataPeriodCollectionProps> = ({ pipeline, onChange, ...props }) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: me } = useQueryMe();
  const [showSelector, setShowSelector] = useState(false);
  const [newPeriod, setNewPeriod] = useState(3);
  const pipelineType = useMemo(() => pipelineTypes?.[pipeline.source], [pipeline.source, pipelineTypes]);
  const tariffSign = useMemo(
    () => me?.active_subscription?.tariff_on_period.sign,
    [me?.active_subscription?.tariff_on_period.sign],
  );

  const currentPeriod = useMemo(
    () =>
      pipeline.streams[0].config_source.date_from
        ? moment().diff(pipeline.streams[0].config_source.date_from, "month")
        : undefined,
    [pipeline],
  );

  const availablePeriodsInMonths = useMemo(() => {
    if (
      !tariffSign ||
      tariffSign === ETariffOnPeriodSign.TRIAL ||
      tariffSign === ETariffOnPeriodSign.TRIAL_OUTDATED ||
      tariffSign === ETariffOnPeriodSign.TRIAL_SINGLE ||
      pipelineType?.group !== "ads"
    ) {
      return [];
    }

    return [3, 6, 9, 12, 18].filter(
      (v) =>
        v <=
        {
          [ETariffOnPeriodSign.ENTERPRISE]: 18,
          [ETariffOnPeriodSign.AGENT]: 18,
          [ETariffOnPeriodSign.PROFESSIONAL]: 12,
          [ETariffOnPeriodSign.START]: 6,
          [ETariffOnPeriodSign.ELAMA]: 6,
        }[tariffSign],
    );
  }, [pipelineType?.group, tariffSign]);

  const handleShowSelector = useCallback(() => {
    setShowSelector(true);
    onChange && onChange(moment().subtract(newPeriod, "month").format("YYYY-MM-DD"));
  }, [newPeriod, onChange]);

  const handleChangePeriod = useCallback(
    (value) => {
      setNewPeriod(value);
      onChange && onChange(moment().subtract(value, "month").format("YYYY-MM-DD"));
    },
    [onChange],
  );

  const handleCancel = useCallback(() => {
    setShowSelector(false);
    setNewPeriod(3);
    onChange && onChange(pipeline.streams[0].config_source.date_from);
  }, [onChange, pipeline.streams]);

  if (!currentPeriod) {
    return null;
  }

  return (
    <div {...props}>
      <Stack spacing={2}>
        <Typography>
          <Trans>Data collection period</Trans>
        </Typography>

        <div>
          {!showSelector ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <UiTextField
                  fullWidth
                  disabled
                  value={plural(currentPeriod, {
                    few: "# months",
                    many: "# months",
                    one: "# month",
                    other: "# months",
                  })}
                />
              </Grid>

              {availablePeriodsInMonths.length > 1 && (
                <Grid item>
                  <UiButton onClick={handleShowSelector}>
                    <Trans>Change data period</Trans>
                  </UiButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <UiSelect
                  value={newPeriod}
                  fullWidth
                  options={availablePeriodsInMonths.map((value) => ({
                    label: plural(value, {
                      few: "# months",
                      many: "# months",
                      one: "# month",
                      other: "# months",
                    }),
                    value,
                  }))}
                  onChange={handleChangePeriod}
                />
              </Grid>

              {availablePeriodsInMonths.length > 1 && (
                <Grid item>
                  <UiButton onClick={handleCancel} variant="outlined" disableElevation>
                    <Trans>Cancel</Trans>
                  </UiButton>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default PeriodDataCollection;
