import { useQueryClient } from "react-query";
import { InvalidateQueryFilters } from "react-query/types/core/types";
import { IProject, IProjectExtended } from "src/types/project";
import useProjectAggregation from "src/utils/hoooks/useProjectAggregation";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  count: number;
  results: IProject[] | undefined;
}

const QUERY_KEY = "all-projects";

export default function useQueryProjects(queryOptions?: IUseQueryBaseOptions<IResponse, IProjectExtended[] | undefined>) {
  const aggregateProject = useProjectAggregation();

  return useQueryBase<IResponse, IProjectExtended[] | undefined>(
    QUERY_KEY,
    {
      refetchOnMount: false,
      select: (data: IResponse) => data?.results?.map((project) => aggregateProject(project)),
      ...queryOptions,
    },
    {
      method: "GET",
      url: `project/?is_special=false`,
    },
  );
}

export function useQueryProjectsInvalidate() {
  const queryClient = useQueryClient();

  return (filters?: InvalidateQueryFilters) => queryClient.invalidateQueries(QUERY_KEY, filters);
}
