import { IProject } from "src/types/project";
import { useQueryProjectUpdateData } from "src/utils/queries/project/useQueryProjectGet";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  projectId: IProject["id"];
  name: string;
  color?: string;
}

export default function useQueryProjectPatch(queryOptions?: IUseQueryMutationOptions<IProject, IInput>) {
  const invalidateProjects = useQueryProjectsInvalidate();
  const updateProject = useQueryProjectUpdateData();

  return useMutationBase<IProject, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateProjects({ refetchInactive: true });

        updateProject(args[0]);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "PUT",
      params: ({ projectId, ...params }) => params,
      url: (params) => `project/${params.projectId}/`,
    },
  );
}
