import moment, { Moment } from "moment";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import usePipelineDataStatus from "src/utils/hoooks/usePipelineDataStatus";
import usePipelineReportLinks from "src/utils/hoooks/usePipelineReportLinks";
import usePipelineStatus from "src/utils/hoooks/usePipelineStatus";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

export default function usePipelineAggregation() {
  const aggregatePipelineDataStatus = usePipelineDataStatus();
  const aggregateReportLinks = usePipelineReportLinks();
  const aggregatePipelineStatus = usePipelineStatus();
  const { data: pipelineTypes } = useQueryPipelineTypes();

  return (pipeline: IPipeline): IPipelineExtended => {
    const { firstDate, lastDate, firstDateRequest } = pipeline.report_status.reduce(
      (acc, { first_date, last_date, first_date_request }) => {
        if (first_date_request) {
          const firstDateRequest = moment(first_date_request);

          if (!acc.firstDateRequest || acc.firstDateRequest > firstDateRequest) {
            acc.firstDateRequest = firstDateRequest;
          }
        }

        if (first_date) {
          const firstDate = moment(first_date);

          if (!acc.firstDate || acc.firstDate > firstDate) {
            acc.firstDate = firstDate;
          }
        }

        if (last_date) {
          const lastDate = moment(last_date);

          if (!acc.lastDate || acc.lastDate < lastDate) {
            acc.lastDate = lastDate;
          }
        }

        return acc;
      },
      {
        firstDate: null,
        firstDateRequest: null,
        lastDate: null,
      } as {
        firstDate: Moment | null;
        firstDateRequest: Moment | null;
        lastDate: Moment | null;
      },
    );

    const profile_picture = pipeline.streams.find(({ profile_picture }) => !!profile_picture)?.profile_picture;
    const media = pipelineTypes?.[pipeline.source]?.media;

    return {
      ...pipeline,
      aggregated_status: aggregatePipelineStatus(pipeline),
      data_first_date: firstDate?.toISOString() || null,
      data_last_date: lastDate?.toISOString() || null,
      data_status: aggregatePipelineDataStatus(pipeline),
      enable_fast_run: Boolean(pipelineTypes?.[pipeline.source].enable_fast_run),
      first_date_request: firstDateRequest?.toISOString() || null,
      media,
      profile_picture,
      report_links: aggregateReportLinks(pipeline),
    };
  };
}
