import { Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FC, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { useMount } from "react-use";
import { ISocialSmpAccountData } from "src/types/socialAuth";
import { parseQueryString } from "src/utils";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";

import IconDf from "./assets/datafan.svg";
import IconElama from "./assets/elama.svg";
import IconFb from "./assets/facebook.svg";
import IconGl from "./assets/google.svg";
import IconIn from "./assets/instagram.svg";
import IconMy from "./assets/mytarget.svg";
import IconOk from "./assets/ok.svg";
import IconPl from "./assets/planner.svg";
import IconTiktok from "./assets/tiktok.svg";
import IconVk from "./assets/vk.svg";
import IconYa from "./assets/yandex.svg";
import useStyles from "./index.css";

const OAuthSmpComplete: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = parseQueryString();
  const queryParamsMessage = queryParams.get("message");
  const [isInvalidQuery, setInvalidQuery] = useState(false);
  const [forceRedirect, setForceRedirect] = useState<string | null>(null);

  const { connect, signIn, signUp, signInAndUp, status, error, storedData } = useSocialConnect();

  const [smpResult, setSmpResult] = useState<ISocialSmpAccountData | undefined>();

  const isError = useMemo(() => status === "error" || isInvalidQuery, [isInvalidQuery, status]);

  const errorMessage = useMemo(() => (typeof error === "string" ? error : error?.response?.data?.message), [error]);

  useMount(() => {
    if (!queryParamsMessage) {
      setForceRedirect("/");
    }

    const result = (() => {
      if (queryParamsMessage) {
        try {
          const message = JSON.parse(queryParamsMessage);

          if (message.type !== "smp_oauth_client") {
            setInvalidQuery(true);
            return null;
          }

          return message.result;
        } catch (e) {
          setInvalidQuery(true);
          return null;
        }
      }
    })();

    window.history.replaceState(null, "OAuthSmpComplete", window.location.pathname);

    if (result) {
      setSmpResult(result);

      if (!storedData) {
        signInAndUp(result, {
          onError: () => {
            history.replace("/");
          },
        });
      } else if (storedData?.action === "connect") {
        connect(result);
      } else if (storedData?.action === "signin") {
        signIn(result);
      } else if (storedData?.action === "signup") {
        signUp(result);
      } else if (storedData?.action === "getCredential" && storedData?.nextUrl) {
        history.replace({
          pathname: storedData.nextUrl,
          state: {
            ...storedData?.nextState,
            authResult: result,
          },
        });
      }
    } else {
      history.replace({
        pathname: storedData?.nextUrl || "/",
        state: {
          ...storedData?.nextState,
        },
      });
    }
  });

  return useMemo(() => {
    if (forceRedirect) {
      return <Redirect to={forceRedirect || "/"} />;
    }

    if (isError && storedData?.action === "signin") {
      return <Redirect to="/auth/signin/" />;
    }

    if (isError && storedData?.action === "signup") {
      return <Redirect to="/auth/signup/" />;
    }

    if (storedData?.action === "getCredential") {
      return null;
    }

    return (
      <Container className={classes.container} component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          {!isError && <LinearProgress className={classes.spinner} color="secondary" />}

          {smpResult?.medium && (
            <div className={classes.icn}>
              <img
                src={
                  {
                    datafan: IconDf,
                    elama: IconElama,
                    facebook: IconFb,
                    google: IconGl,
                    instagram: IconIn,
                    mytarget: IconMy,
                    ok: IconOk,
                    smmplanner: IconPl,
                    tiktok: IconTiktok,
                    vk: IconVk,
                    yandex: IconYa,
                  }[smpResult.medium]
                }
                alt={smpResult.medium}
              />
            </div>
          )}

          {isError ? (
            <>
              <Typography color="secondary" variant="h3">
                <Trans>Connection error</Trans>
              </Typography>

              <Box mt={2}>
                {typeof errorMessage === "string" ? (
                  errorMessage.replace(/(facebook|instagram)/gim, "$1*")
                ) : (
                  <Trans>Something went wrong. Please contact technical support and we will help you</Trans>
                )}
              </Box>

              <Box mt={3}>
                {storedData?.action === "connect" && (
                  <Button component={Link} variant="outlined" to={storedData?.nextUrl || "/"}>
                    <Trans>Continue</Trans>
                  </Button>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h3" color="primary">
                <Trans>Please wait</Trans>
              </Typography>

              <Box mt={2}>
                <Trans>We connect your social network account to the system.</Trans>
              </Box>
            </>
          )}
        </Paper>
      </Container>
    );
  }, [
    classes.container,
    classes.icn,
    classes.paper,
    classes.spinner,
    errorMessage,
    forceRedirect,
    isError,
    smpResult,
    storedData?.action,
    storedData?.nextUrl,
  ]);
};

export default OAuthSmpComplete;
