import { FC, useMemo } from "react";
import { useMount } from "react-use";
import UiSelect from "src/components/UiSelect";
import { EProjectRole } from "src/enums/project";
import { IProject } from "src/types/project";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

interface IProps {
  value: IProject["id"] | null;
  onChange: (id: IProject["id"] | null) => void;
}

const SelectProject: FC<IProps> = ({ value, onChange }) => {
  const { data: projects, isFetching: projectsIsFetching } = useQueryProjects();

  const defaultProject = useMemo(() => projects?.find((project) => project.role === EProjectRole.OWNER), [projects]);

  const options = useMemo(
    () =>
      projects?.map((project) => ({
        label: project.name,
        value: project.id,
      })),
    [projects],
  );

  useMount(() => {
    if (defaultProject && value === null) {
      onChange(defaultProject.id);
    }
  });

  return <UiSelect isLoading={projectsIsFetching} value={value} options={options} onChange={onChange} fullWidth />;
};

export default SelectProject;
