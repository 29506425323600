import { Trans } from "@lingui/macro";
import { Alert, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import AddProvider from "src/components/AddProvider";
import Loader from "src/components/Loader";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { IStreamConfig } from "src/types/stream";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

type IAdAccountId = NonNullable<IStreamConfig["extra_settings"]>["facebook_ad_account_id"];
type IAdAccountName = NonNullable<IStreamConfig["extra_settings"]>["facebook_ad_account_name"];

interface IProps {
  credentialId: IStreamConfig["account_credentials_id"] | undefined;
  enabled: boolean;
  adAccountId: IAdAccountId;
  projectId: number;
  onToggle: (checked: boolean) => void;
  onChange: (params: { id: IAdAccountId; name: IAdAccountName }) => void;
  persistState?: {
    [key: string]: any;
  };
  isOwner: boolean;
}

const InstFbAds: FC<IProps> = ({
  enabled,
  adAccountId,
  onChange,
  onToggle,
  projectId,
  persistState,
  credentialId,
  isOwner,
}) => {
  const { data: adAccounts, isFetching: adAccountsIsLoading } = useQueryCredentialMetaGet(
    {
      credentialId: credentialId || 0,
      object: ECredentialMetaObject.FACEBOOK_ADS_ACCOUNTS,
      projectId,
    },
    {
      enabled: Boolean(credentialId && isOwner),
      onSuccess: (data) => {
        if (!adAccountId && data[0]) {
          onChange({
            id: data[0].id,
            name: data[0].name,
          });
        }
      },
    },
  );

  const options = useMemo(
    () =>
      adAccounts &&
      adAccounts.map((account) => ({
        data: account,
        label: account.name,
        value: account.id,
      })),
    [adAccounts],
  );

  const handleChangeValue = useCallback(
    (newId: string | null) => {
      const account = adAccounts?.find(({ id }) => id === newId);

      if (account) {
        onChange(account);
      }
    },
    [adAccounts, onChange],
  );

  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onToggle(checked);
    },
    [onToggle],
  );

  const isCredLost = useMemo(
    () => Boolean(adAccountId && !adAccountsIsLoading && !adAccounts?.some(({ id }) => id === adAccountId)),
    [adAccountId, adAccounts, adAccountsIsLoading],
  );

  return (
    <>
      <Box mt={2} mb={2}>
        <FormControlLabel
          control={<Checkbox disabled={!isOwner} checked={enabled} onChange={handleToggle} />}
          label={<Trans>Specify your Facebook advertising account to collect statistics from Instagram ad posts</Trans>}
        />
      </Box>

      {enabled && (
        <>
          <Box mb={3}>
            {adAccountsIsLoading ? (
              <Box sx={{ height: 46 }}>
                <Loader autoHeight />
              </Box>
            ) : (
              <>
                {isCredLost && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    <Trans>
                      Access to the specified Facebook account is not available in DataFan. Reconnect the specified account or
                      connect a new one.
                    </Trans>
                  </Alert>
                )}

                <Grid container spacing={3} alignItems="center">
                  {options && options.length > 0 && (
                    <>
                      <Grid item xs>
                        <UiSelect
                          value={adAccountId}
                          options={options}
                          onChange={handleChangeValue}
                          placeholdsr={<Trans>Source</Trans>}
                          fullWidth
                        />
                      </Grid>

                      <Grid item>
                        <Typography sx={{ color: "neutral.main" }}>
                          <Trans>or</Trans>
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    <AddProvider
                      source={EPipelineType.FB_ADS}
                      persistState={persistState}
                      ButtonProps={{ disableElevation: true, variant: "outlined" }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default InstFbAds;
