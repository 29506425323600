import { t, Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import SelectAccountCredential from "src/components/SelectAccountCredential";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import UiTextField from "src/components/UiTextField";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineMedia } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigOpenAccount } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import SelectProject from "../SelectProject";

interface IProps {
  pipelineType: IPipelineType;
  onConfigReady: (config: IPipelineConfigOpenAccount) => void;
  isLoading: boolean;
  startStepNum: number;
  onClickBack?: () => void;
  persistState?: {
    [key: string]: any;
  };
}

type IStepId = "project" | "dataSource" | "link";

interface IPersistState {
  newCredential?: ICredential | undefined;
  projectId?: IProject["id"] | null;
}

const INST_REGEXP = new RegExp(
  "(?:https?:)?\\/\\/(?:www\\.)?(?:instagram\\.com|instagr\\.am)\\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)",
  "gi",
);

const generateSourceStepLabel = (pipelineType: IPipelineType, creds: ICredential[] | undefined) => {
  if (pipelineType.media === EPipelineMedia.INSTAGRAM) {
    return !creds?.length ? t`Sign in Facebook` : t`Select data source`;
  }

  return t`Specify data collection parameters`;
};

const CreateOpenAccount: FC<IProps> = ({
  pipelineType,
  onConfigReady,
  isLoading,
  startStepNum,
  onClickBack,
  persistState: initialPersistState,
}) => {
  const { auth, status } = useSocialConnect();
  const [tempAccountLink, setTempAccountLink] = useState<string>("");
  const [accountLink, setAccountLink] = useState<string | null>(null);
  const history = useHistory();
  const location = useLocation<IPersistState | undefined>();
  const [activeStep, setActiveStep] = useState(0);
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const [projectId, setProjectId] = useState<IProject["id"] | null>(
    location.state?.projectId || (projects && projects.length === 1 && projects[0].id) || null,
  );
  const [credential, setCredential] = useState<ICredential | undefined>(location.state?.newCredential);
  const { data: existsCreds } = useQueryCredentialsGet(undefined, {
    own: true,
    stream: pipelineType.streams[0].id,
  });
  const steps = useMemo(
    () =>
      [
        projects && projects.length > 1 ? { id: "project", label: t`Select project` } : undefined,
        { id: "dataSource", label: generateSourceStepLabel(pipelineType, existsCreds) },
        { id: "link", label: t`Enter the link for express analysis` },
      ].filter((step) => typeof step !== "undefined") as Array<{
        id: IStepId;
        label: string;
      }>,
    [existsCreds, pipelineType, projects],
  );

  const persistState = useMemo(
    () => ({
      ...initialPersistState,
      credential: credential,
      projectId: projectId,
    }),
    [credential, initialPersistState, projectId],
  );

  const { isFetching: metaIsFetching } = useQueryCredentialMetaGet(
    {
      credentialId: credential?.id || 0,
      extraParams: {
        account: accountLink,
      },
      object: ECredentialMetaObject.INSTAGRAM_OPEN_ACCOUNT,
      projectId: projectId || 0,
    },
    {
      enabled: Boolean(credential?.id && projectId && accountLink),
      onSettled: () => {
        setAccountLink(null);
      },
      onSuccess: (data) => {
        if (credential && projectId) {
          onConfigReady({
            config: pipelineType.streams.map((stream) => ({
              account_credentials_id: credential.id,
              instagram_business_account_id: data.instagram_account.instagram_business_account_id,
              instagram_business_account_name: data.instagram_account.instagram_business_account_name,
              search_account_username: [data.instagram_account.instagram_business_account_username],
              source: stream.id,
            })),
            name: data.instagram_account.instagram_business_account_username,
            project_id: projectId,
            source: pipelineType.id,
          });
        }
      },
    },
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      if (onClickBack) {
        onClickBack();
      } else {
        history.goBack();
      }
    } else {
      setActiveStep((step) => step - 1);
    }
  }, [activeStep, history, onClickBack]);

  const handleNext = useCallback(() => {
    if (steps[activeStep].id === "dataSource") {
      if (!existsCreds?.length) {
        auth({
          action: "connect",
          nextState: persistState,
          nextUrl: location.pathname,
          providerId: Number(pipelineType.authIndex),
        });
      } else {
        setActiveStep((step) => step + 1);
      }
    } else if (steps[activeStep].id === "link" && tempAccountLink) {
      setAccountLink(tempAccountLink);
    } else {
      setActiveStep((step) => step + 1);
    }
  }, [activeStep, auth, existsCreds?.length, location.pathname, persistState, pipelineType.authIndex, steps, tempAccountLink]);

  const isNextButtonEnabled = useMemo(() => {
    if (steps[activeStep]?.id === "project") {
      return projectId !== null;
    }

    if (steps[activeStep]?.id === "dataSource") {
      return !existsCreds?.length || Boolean(credential);
    }

    if (steps[activeStep]?.id === "link") {
      return Boolean(tempAccountLink && tempAccountLink.match(INST_REGEXP));
    }
  }, [activeStep, credential, existsCreds?.length, projectId, steps, tempAccountLink]);

  useUpdateEffect(() => {
    setCredential(undefined);
  }, [projectId]);

  useUpdateEffect(() => {
    if (!projectsIsLoading && !projectId && projects && projects.length === 1) {
      setProjectId(projects[0].id);
    }
  }, [projectsIsLoading]);

  useUpdateEffect(() => {
    if (location.state?.projectId && location.state?.newCredential) {
      setActiveStep(steps.length - 1);
    }
  }, [steps]);

  useMount(() => {
    if (location.state?.projectId && location.state?.newCredential) {
      setActiveStep(steps.length - 1);
    }
  });

  if (projectsIsLoading) {
    return <Loader />;
  }

  return (
    <UiSimpleStepper
      num={startStepNum + activeStep}
      title={steps[activeStep]?.label}
      onClickPrev={handleBack}
      onClickNext={handleNext}
      isDisabledNextBtn={!isNextButtonEnabled}
      isLoadingNextBtn={
        isLoading ||
        (steps[activeStep].id === "dataSource" && status === "loading") ||
        (steps[activeStep].id === "link" && metaIsFetching)
      }
      textNextBtn={
        activeStep >= steps.length - 1 ? (
          <Trans>Finish</Trans>
        ) : steps[activeStep]?.id === "dataSource" && !existsCreds?.length ? (
          pipelineType?.authTitle || <Trans>Create</Trans>
        ) : (
          <Trans>Next</Trans>
        )
      }
    >
      {steps[activeStep]?.id === "project" && <SelectProject value={projectId} onChange={setProjectId} />}

      {steps[activeStep]?.id === "dataSource" &&
        projectId !== null &&
        (!existsCreds?.length ? (
          <Typography>
            <Trans>
              To start the express analysis by the link, you need to connect <strong>any</strong> existing Facebook account.
            </Trans>
          </Typography>
        ) : (
          <SelectAccountCredential
            projectId={projectId}
            source={pipelineType.id}
            value={credential?.id}
            onChange={setCredential}
            persistState={persistState}
          />
        ))}

      {steps[activeStep]?.id === "link" && credential && projectId && (
        <UiTextField
          fullWidth
          placeholder="https://instagram.com/username"
          value={tempAccountLink}
          onChange={setTempAccountLink}
        />
      )}
    </UiSimpleStepper>
  );
};

export default CreateOpenAccount;
