import { IProject } from "src/types/project";
import { useQueryProjectInvitesInvalidate } from "src/utils/queries/project/useQueryProjectInvitesGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  id: string;
  target_project: IProject["id"];
}

export default function useQueryProjectInviteResendPost(queryOptions?: IUseQueryMutationOptions<IProject, IInput>) {
  const invalidateInvites = useQueryProjectInvitesInvalidate();

  return useMutationBase<IProject, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateInvites(args[1].target_project);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      params: () => null,
      url: (params) => `invitation/${params.id}/resend/`,
    },
  );
}
