import { Typography } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { IExport } from "src/types/export";

interface IProps {
  report: IExport;
}

const CellCreatedAt: FC<IProps> = ({ report }) => (
  <Typography>{moment.utc(report.created_at).local().format("DD.MM.YYYY HH:mm")}</Typography>
);

export default CellCreatedAt;
