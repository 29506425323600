import { createElement, FC, useCallback } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import { accessToken } from "src/utils/tokens";

const PrivateRoute: FC<RouteProps> = ({ component, ...props }) => {
  const location = useLocation();
  const { data: user, isFetched } = useQueryMe();

  const render = useCallback(
    (props) =>
      component &&
      user &&
      createElement(component, {
        user,
        ...props,
      }),
    [component, user],
  );

  if (!user && !isFetched) {
    return <Loader />;
  }

  return !accessToken || (!user && isFetched) ? (
    <Redirect
      to={{
        pathname: "/auth/signin/",
        state: { next: location.pathname + location.search },
      }}
    />
  ) : (
    <Route {...props} render={render} />
  );
};

export default PrivateRoute;
