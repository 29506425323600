export default function parseFeatures(sourceString: string) {
  const lines = sourceString.split("\n");
  const groups: Array<{ title: string | undefined; lines: string[] }> = [];

  lines?.forEach((line, index) => {
    const title = line.match(/^\[(.*)?]/)?.[1];

    if (index === 0 || title) {
      groups.push({
        lines: [],
        title,
      });
    }

    const lastGroup = groups.at(-1);

    if (lastGroup && !title) {
      lastGroup.lines.push(line.trim());
    }
  });

  return groups;
}
