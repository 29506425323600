import { useLingui } from "@lingui/react";
import { FC } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { parseQueryString } from "src/utils";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import { accessToken } from "src/utils/tokens";

import AuthPlanner from "./pages/AuthPlanner";
import Page404 from "./pages/Page404";
import ResetPassword from "./pages/ResetPassword";
import SetPassword from "./pages/SetPassword";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

const Auth: FC = () => {
  const lingui = useLingui();
  const location = useLocation<{ next?: string } | undefined>();
  const isAuthPlanner = /^\/auth\/smmplanner/.test(location.pathname);
  const nextUrl = parseQueryString().get("next");
  const { data: user, isFetched } = useQueryMe({ enabled: Boolean(accessToken) });

  if (isFetched && user && !isAuthPlanner && Boolean(accessToken)) {
    if (nextUrl) {
      window.location.href = nextUrl;
      return null;
    }

    return <Redirect to={location.state?.next || "/"} />;
  }

  return (
    <Switch key={lingui.i18n.locale}>
      <Route path={"/auth/signin/"} component={SignIn} />
      <Route path={"/auth/signup/"} component={SignUp} />
      <Route path={"/auth/reset-password/"} component={ResetPassword} />
      <Route path={"/auth/set-password/"} component={SetPassword} />
      <Route path={"/auth/smmplanner/"} component={AuthPlanner} />
      <Route component={Page404} />
    </Switch>
  );
};

export default Auth;
