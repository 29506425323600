import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { t, Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { FC, useMemo } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import UiDxGrid from "src/components/UiDxGrid";
import UiDxPagingPanel from "src/components/UiDxPagingPanel";
import UiDxTable from "src/components/UiDxTable";
import { IPipelineExtended, IPipelineType } from "src/types/pipeline";

import PipelineAction from "./components/PipelineAction";
import PipelineName from "./components/PipelineName";

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pipelines: IPipelineExtended[];
  pipelineType: IPipelineType;
  isLoading?: boolean;
}

const ModalSamePipeline: FC<IProps> = ({ open, onClose, onConfirm, pipelines, pipelineType, isLoading }) =>
  useMemo(
    () => (
      <UiDialog open={open} fullWidth maxWidth="sm" title={<Trans>Attention!</Trans>} onClose={onClose}>
        <DialogContent>
          <Alert severity="info">
            {pipelineType.group
              ? {
                  ads: t`This ad account is already connected`,
                  merge: t`This merge is already connected`,
                  open_account: t`This account is already connected`,
                  public: t`This public is already connected`,
                }[pipelineType.group]
              : t`This dataset is already connected`}
            {". "}
            <Trans>Do you want to connect it again?</Trans>
          </Alert>

          <UiDxGrid
            rows={pipelines}
            columns={[
              {
                getCellValue: (pipeline) => <PipelineName pipeline={pipeline} />,
                name: "name",
                title: t`Name`,
              },
              {
                getCellValue: (pipeline) => <PipelineAction pipeline={pipeline} />,
                name: "action",
                title: "",
              },
            ]}
          >
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />

            <UiDxTable
              columnExtensions={[
                { columnName: "name", width: "auto" },
                { align: "right", columnName: "action", width: 180 },
              ]}
            />
            <UiDxPagingPanel />
          </UiDxGrid>
        </DialogContent>

        <DialogActions>
          <UiButton disabled={isLoading} onClick={onClose} variant="outlined">
            <Trans>Cancel</Trans>
          </UiButton>
          <UiButton loading={isLoading} variant="outlined" onClick={onConfirm}>
            <Trans>
              Connect the{" "}
              {pipelineType.group
                ? {
                    ads: t`ad account`,
                    merge: t`merge`,
                    open_account: t`account`,
                    public: t`public`,
                  }[pipelineType.group]
                : t`dataset`}{" "}
              again
            </Trans>
          </UiButton>
        </DialogActions>
      </UiDialog>
    ),
    [isLoading, onClose, onConfirm, open, pipelineType.group, pipelines],
  );

export default ModalSamePipeline;
