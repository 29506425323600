import { ETmplType } from "src/enums/tmpl";
import { IPipeline } from "src/types/pipeline";
import { IProjectPipeline } from "src/types/project";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryTmpls from "src/utils/queries/tmpls/useQueryTmpls";

export default function usePipelineReportLinks() {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: mainTmpls } = useQueryTmpls(
    {
      refetchOnMount: false,
    },
    { tmplUseOnWelcomePage: true },
  );

  return (pipeline: IPipeline | IProjectPipeline) => {
    const pipelineType = pipelineTypes?.[pipeline.source];

    if (!pipelineType) {
      return undefined;
    }

    const tmplInhouse = pipelineType.tmpls.find((tmpl) => tmpl.tmpl_type === ETmplType.DATAFAN_DATA_TMPL);
    const tmplsGDS = pipelineType.tmpls.filter((tmpl) => tmpl.tmpl_type === ETmplType.GOOGLE_DATA_TMPL);
    const tmplMain = mainTmpls?.find((tmpl) => tmpl.pipeline_source === pipeline.source);
    const emptyTmplIds = process.env.REACT_APP__GDS_EMPTY_TMPLS?.split(",")?.map((id) => Number(id));

    const gdsLink = (() => {
      if (tmplsGDS.length > 1) {
        return {
          pathname: "/connector/gds/",
          state: { sources: [pipeline.source] },
        };
      } else if (tmplsGDS.length) {
        return {
          pathname: `/connector/gds/create/${tmplsGDS[0].id}/`,
          state: { pipelineId: pipeline.id },
        };
      } else if (emptyTmplIds) {
        return {
          pathname: `/connector/gds/create/${emptyTmplIds[0]}/`,
          state: { pipelineId: pipeline.id },
        };
      } else {
        return undefined;
      }
    })();

    const inhouseLink = (() => {
      if (tmplInhouse) {
        return `/report/${tmplInhouse.id}/${pipeline.id}/`;
      } else {
        return undefined;
      }
    })();

    const excelLink = {
      pathname: "/connector/excel/create/",
      state: { pipelineId: pipeline.id },
    };

    const mainLink = (() => {
      if (tmplMain) {
        return tmplMain.tmpl_type === ETmplType.DATAFAN_DATA_TMPL
          ? `/report/${tmplMain.id}/${pipeline.id}/`
          : {
              pathname: `/connector/gds/create/${tmplsGDS[0].id}/`,
              state: { pipelineId: pipeline.id },
            };
      } else {
        return inhouseLink || gdsLink || excelLink;
      }
    })();

    return {
      excel: excelLink,
      gds: gdsLink,
      inhouse: inhouseLink,
      main: mainLink,
    };
  };
}
