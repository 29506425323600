import { Plural, t, Trans } from "@lingui/macro";
import { Tooltip, Typography } from "@mui/material";
import { IconInfinity } from "@tabler/icons";
import { FC } from "react";
import OptionCheckbox from "src/app/Tariffs/components/OptionCheckbox";
import MediaFavicon from "src/components/MediaFavicon";
import { EPipelineMedia } from "src/enums/pipeline";
import { IFixtureTariffsTableOption } from "src/fixtures/tariffsTable";
import { ITariff } from "src/types/tariff";
import cssc from "src/utils/emotionComposition";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";

import * as classes from "../../index.css";

interface IProps {
  tariff: ITariff;
  option: IFixtureTariffsTableOption;
  isMobile?: boolean;
}

const TariffOptionValue: FC<IProps> = ({
  tariff,
  option: { limitKey, defaultValue, isPipelinePrice, label, subLabel },
  isMobile,
}) => {
  const value = (() => {
    if (defaultValue) {
      if (defaultValue === Infinity) {
        return isMobile ? <Trans>unlimited</Trans> : <IconInfinity />;
      }

      if (typeof defaultValue === "boolean") {
        return isMobile ? (
          <Trans>No</Trans>
        ) : (
          <Tooltip title={`${label}${subLabel ? ". " + subLabel : ""}`}>
            <div>
              <OptionCheckbox checked={defaultValue} />
            </div>
          </Tooltip>
        );
      }

      return defaultValue;
    } else if (limitKey || isPipelinePrice) {
      if (isPipelinePrice) {
        if (tariff.pipeline_price === null) {
          return isMobile ? (
            <Trans>No</Trans>
          ) : (
            <Tooltip title={`${label}${subLabel ? ". " + subLabel : ""}`}>
              <div>
                <OptionCheckbox checked={false} />
              </div>
            </Tooltip>
          );
        } else {
          return isMobile ? (
            <Trans>
              <div>
                +{tariff.pipeline_price}
                {getCurrencyDisplayName(tariff.currency)}/month
              </div>
            </Trans>
          ) : (
            <Trans>
              <div>+{tariff.pipeline_price}</div>
              <Typography variant="body1">{getCurrencyDisplayName(tariff.currency)}/month</Typography>
            </Trans>
          );
        }
      } else if (limitKey === "pipelines") {
        return `${tariff.limits[limitKey]} `;
      } else if (limitKey === "first_start_day_public" || limitKey === "first_start_day_ads") {
        return !isMobile ? (
          <>
            <div>{Math.round(tariff.limits[limitKey] / 30)}</div>
            <Typography variant="body1">
              <Trans>mon.</Trans>
            </Typography>
          </>
        ) : (
          <>
            <div>
              {Math.round(tariff.limits[limitKey] / 30)} <Trans>mon.</Trans>
            </div>
          </>
        );
      } else if (limitKey === "update_frequency_seconds") {
        const times = (24 / tariff.limits[limitKey]) * 3600;

        return (
          <>
            <Plural value={times} one="# time" few="# times" many="# times" other="# times" />
            <Typography variant="body1">
              <Trans>per day</Trans>
            </Typography>
          </>
        );
      } else if (limitKey === "media_public" || limitKey === "media_ads") {
        return (
          <>
            {tariff.limits[limitKey].map((media) => (
              <Tooltip
                key={media}
                title={
                  {
                    media_ads: {
                      [EPipelineMedia.FB]: t`Facebook ADS`,
                      [EPipelineMedia.VK]: "VK Business",
                      [EPipelineMedia.GOOGLE]: "Google",
                      [EPipelineMedia.INSTAGRAM]: t`Instagram ADS`,
                      [EPipelineMedia.MYTARGET]: "MyTarget",
                      [EPipelineMedia.YA]: "Yandex",
                      [EPipelineMedia.TIKTOK]: "TikTok",
                      [EPipelineMedia.DATAFAN]: "DataFan",
                      [EPipelineMedia.OK]: "OK.ru",
                      [EPipelineMedia.TELEGRAM]: "Telegram",
                    },
                    media_public: {
                      [EPipelineMedia.FB]: t`Facebook`,
                      [EPipelineMedia.VK]: "VKontakte",
                      [EPipelineMedia.GOOGLE]: "Google",
                      [EPipelineMedia.INSTAGRAM]: t`Instagram`,
                      [EPipelineMedia.MYTARGET]: "MyTarget",
                      [EPipelineMedia.YA]: "Yandex",
                      [EPipelineMedia.TIKTOK]: "TikTok",
                      [EPipelineMedia.DATAFAN]: "DataFan",
                      [EPipelineMedia.OK]: "OK.ru",
                      [EPipelineMedia.TELEGRAM]: "Telegram",
                    },
                  }[limitKey][media]
                }
              >
                <span>
                  <MediaFavicon media={media} css={classes.favicon} />
                </span>
              </Tooltip>
            ))}
          </>
        );
      } else if (limitKey === "archive_pipelines") {
        if (!tariff.limits.archive_pipelines) {
          return (
            <Tooltip title={`${label}${subLabel ? ". " + subLabel : ""}`}>
              <div>
                <OptionCheckbox checked={false} />
              </div>
            </Tooltip>
          );
        } else {
          return <Trans>from {tariff.limits.archive_pipelines}</Trans>;
        }
      } else if (limitKey && typeof tariff.limits[limitKey] === "boolean") {
        return (
          <Tooltip title={`${label}${subLabel ? ". " + subLabel : ""}`}>
            <div>
              <OptionCheckbox checked={Boolean(tariff.limits[limitKey])} />
            </div>
          </Tooltip>
        );
      } else if (limitKey && tariff.limits[limitKey] === 1000) {
        return isMobile ? <Trans>unlimited</Trans> : <IconInfinity />;
      } else {
        return limitKey ? tariff.limits[limitKey] : null;
      }
    }
  })();

  return value ? (
    <Typography component="div" variant="h4" css={cssc([classes.option, isMobile && classes.option_mobile])}>
      {value}
    </Typography>
  ) : null;
};

export default TariffOptionValue;
