import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = css`
  width: 100%;
  max-width: 920px !important;
`;

export const paper = (theme: Theme) => css`
  padding: 100px 30px;
  text-align: center;

  ${theme.breakpoints.down("lg")} {
    padding: 70px 30px;
  }

  ${theme.breakpoints.down("md")} {
    padding: 50px 30px;
  }
`;

export const title = (theme: Theme) => css`
  margin-top: 20px;

  ${theme.breakpoints.down("md")} {
    margin-top: 15px;
    font-size: 1.475rem;

    > br {
      display: none;
    }
  }
`;

export const logo = (theme: Theme) => css`
  width: 54px;
  height: 54px;

  ${theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const buttons = css`
  justify-content: center;
  padding-top: 40px;
`;

export const btn = css`
  padding: 0 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 0 0 8px #f1f6f8 !important;
  }
`;

export const btn_active = css`
  box-shadow: 0 0 0 8px #55ade0 !important;

  &:hover {
    box-shadow: 0 0 0 8px #55ade0 !important;
  }
`;

export const btn__icon = css`
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 8px;
`;

export const desc = css`
  text-align: center;

  > br {
    display: none;
  }
`;
