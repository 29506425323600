import { t, Trans } from "@lingui/macro";
import { DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import React, { FC, useCallback, useMemo, useState } from "react";
import { ColorResult } from "react-color";
import { useDispatch } from "react-redux";
import ProjectColorPicker from "src/components/ProjectColorPicker";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import UiTextField from "src/components/UiTextField";
import { popupAdd } from "src/redux";
import { IProjectExtended } from "src/types/project";
import useQueryProjectPatch from "src/utils/queries/project/useQueryProjectPatch";

import * as classes from "./index.css";

interface IProps {
  isOpen: boolean;
  project: IProjectExtended;
  onClose: () => void;
}

const ModalEdit: FC<IProps> = ({ isOpen, project, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(project.name);
  const [color, setColor] = useState(project.color);

  const { mutate: patchProject, isLoading: patchProjectIsLoading } = useQueryProjectPatch({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Project successfully updated`,
        }),
      );
      onClose();
    },
  });

  const handleChangeColor = useCallback((color: ColorResult) => {
    setColor(color.hex);
  }, []);

  const handleSave = useCallback(() => {
    patchProject({
      color,
      name,
      projectId: project.id,
    });
  }, [color, name, patchProject, project.id]);

  const hasChanges = useMemo(
    () => project.color !== color || project.name !== name,
    [color, name, project.color, project.name],
  );

  return (
    <UiDialog
      open={isOpen}
      onClose={onClose}
      title={<Trans>Change the name and color of the project</Trans>}
      css={classes.dialog}
    >
      <DialogContent>
        <Box mt={2}>
          <UiTextField
            value={name}
            onChange={setName}
            inputProps={{
              placeholder: t`Project name`,
            }}
            fullWidth
          />
        </Box>

        <Box mt={3}>
          <ProjectColorPicker color={color} onChange={handleChangeColor} />
        </Box>

        <Box mt={6} mb={5} textAlign="right">
          <UiButton onClick={handleSave} loading={patchProjectIsLoading} disabled={!hasChanges}>
            <Trans>Save changes</Trans>
          </UiButton>
        </Box>
      </DialogContent>
    </UiDialog>
  );
};

export default ModalEdit;
