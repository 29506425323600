import { Trans } from "@lingui/macro";
import { Box, Grid, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC, useCallback } from "react";
import AutoPayment from "src/app/Billing/components/Offer/components/AutoPayment";
import UiButton from "src/components/UiButton";
import { useBillingContext } from "src/context/billing/billingContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { ISubscription } from "src/types/subscription";
import { IUser } from "src/types/user";

import PaperInfo from "../PaperInfo";

const OfferWithoutActiveSubscription: FC<{
  subscription: ISubscription;
  user: IUser;
}> = ({ subscription, user }) => {
  const {
    state: { isTariffsPending },
    actions: { openModalSubscriptionOrder, getTariffName, renewSubscription, openModalSubscriptionAlert },
  } = useBillingContext();

  const handleBuyStart = useCallback(
    () =>
      openModalSubscriptionOrder({
        renewalWithFreeDays: false,
        tariffSign: ETariffOnPeriodSign.START,
      }),
    [openModalSubscriptionOrder],
  );

  const handleRenewElama = useCallback(
    () =>
      openModalSubscriptionAlert({
        action: undefined,
        text: (
          <Trans>
            Please follow{" "}
            <Link component="a" target="_blank" href="https://new.elama.ru/tools-external/datafan">
              https://new.elama.ru/tools-external/datafan
            </Link>{" "}
            for subscription activation. <br />
            In case of any trouble please contact us at{" "}
            <Link component="a" href={`mailto:${process.env.REACT_APP__SUPPORT_ELAMA_MAIL}`}>
              {process.env.REACT_APP__SUPPORT_ELAMA_MAIL}
            </Link>
          </Trans>
        ),
      }),
    [openModalSubscriptionAlert],
  );

  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" color="secondary.dark" gutterBottom>
            <Trans>The tariff has expired</Trans>
          </Typography>

          <Typography variant="h2">{subscription.tariff_on_period.tariff.name}</Typography>

          {user.isElamaUser ? (
            <UiButton sx={{ mt: 2 }} onClick={handleRenewElama}>
              <Trans>Choose a tariff</Trans>
            </UiButton>
          ) : user.isTrialUser ? (
            <>
              <Box mt={2.2}>
                <Link href="/tariffs/">
                  <Trans>Observe subscriptions</Trans>
                </Link>
              </Box>

              <UiButton sx={{ mt: 2 }} onClick={handleBuyStart} loading={isTariffsPending}>
                <Trans>Pay {getTariffName(ETariffOnPeriodSign.START)} subscription</Trans>
              </UiButton>
            </>
          ) : (
            <>
              <Box mt={2.2}>
                <Link href="/tariffs/">
                  <Trans>Choose a tariff</Trans>
                </Link>
              </Box>

              <UiButton sx={{ mt: 2 }} onClick={renewSubscription}>
                <Trans>Renew tariff</Trans>
              </UiButton>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <PaperInfo subscription={subscription} user={user} isTrial={user.isTrialUser} />
        </Grid>
      </Grid>

      {user.can_auto_pay && <AutoPayment />}
    </>
  );
};

export default OfferWithoutActiveSubscription;
