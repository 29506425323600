import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const btnDeleteConnect = css`
  margin-left: auto !important;
  padding: 6px 4px !important;
  font-size: 14px;
  box-shadow: none;
`;

export const entity = css`
  margin: 10px 0;
`;

export const entity__colIcn = css`
  width: 24px;
  text-align: center;
`;

export const entity__btns = css`
  margin-left: auto !important;
  padding-left: 10px;

  .MuiButton-root {
    padding: 0 !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 0 !important;
  }
`;

export const btnInfo = css`
  color: #757575;

  :hover {
    color: #000000;
  }
`;

export const btnDelete = (theme: Theme) => css`
  color: ${theme.palette.secondary.light};

  :hover {
    color: ${theme.palette.secondary.main};
  }
`;

export const dialog = css`
  text-align: center;
  padding: 50px !important;
`;

export const logo = css`
  width: 24px;
  height: 24px;
  display: block;
`;
