import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
  facebook: {
    "&:hover": {
      backgroundColor: "#3C5A99",
    },
    backgroundColor: "#3C5A99",
  },
  fillContainer: {
    height: "100%",
    width: "100%",
  },
  iconBack: {
    "&:hover": {
      opacity: 1,
    },
    alignItems: "center",
    borderRadius: 2,
    boxShadow: "none",
    display: "inline-flex",
    height: 38,
    justifyContent: "center",
    opacity: 0.65,
    width: 38,
  },
  iconBackActive: {
    opacity: 1,
  },
  instagram: {
    "&:hover": {
      backgroundColor: "#DD2A7B",
    },
    backgroundColor: "#DD2A7B",
  },
  link: {
    bottom: 0,
    left: 0,
    opacity: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },
  ok: {
    "&:hover": {
      backgroundColor: "#FF9E00",
    },
    backgroundColor: "#FF9E00",
  },
  pinterest: {
    "&:hover": {
      backgroundColor: "#BD071D",
    },
    backgroundColor: "#BD071D",
  },
  telegram: {
    "&:hover": {
      backgroundColor: "#0088CC",
    },
    backgroundColor: "#0088CC",
  },
  twitter: {
    "&:hover": {
      backgroundColor: "#55ACEE",
    },
    backgroundColor: "#55ACEE",
  },
  viber: {
    "&:hover": {
      backgroundColor: "#8F5DB7",
    },
    backgroundColor: "#8F5DB7",
  },
  vk: {
    "&:hover": {
      backgroundColor: "#5181B8",
    },
    backgroundColor: "#5181B8",
  },
  youtube: {
    "&:hover": {
      backgroundColor: "#FE0102",
    },
    backgroundColor: "#FE0102",
  },
}));
