import { IPipeline } from "src/redux";
import { IPipelineConfig } from "src/types/pipelineConfig";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export interface IUseQueryPipelinePatchParams {
  name?: IPipeline["name"];
  project?: IPipeline["project"];
  run_delta?: IPipeline["run_delta"];
  config?: IPipelineConfig;
  is_new_ya_settings?: boolean;
}

export default function useQueryPipelinePatch(
  pipelineId: IPipeline["id"],
  queryOptions?: IUseQueryMutationOptions<unknown, IUseQueryPipelinePatchParams>,
) {
  return useMutationBase(queryOptions, {
    method: "PATCH",
    url: `pipeline/${pipelineId}/`,
  });
}
