import { t, Trans } from "@lingui/macro";
import { Alert, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import SelectPipelineSource from "src/app/ConnectorGDS/pages/CreateReport/components/SelectPipelineSource";
import PipelineCreateForm from "src/components/PipelineCreateForm";
import Section, { SectionHead } from "src/components/Section";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import { EPipelineType } from "src/enums/pipeline";
import ConnectorGdsIcon from "src/icons/ConnectorGdsIcon";
import { ICredential } from "src/types/credential";
import { IPipeline } from "src/types/pipeline";
import { IProject } from "src/types/project";
import { generateOldGDS } from "src/utils";
import cssc from "src/utils/emotionComposition";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryPipelineGet from "src/utils/queries/pipeline/useQueryPipelineGet";
import useQueryTmpls from "src/utils/queries/tmpls/useQueryTmpls";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import SelectMode from "./components/SelectMode";
import SelectPipeline from "./components/SelectPipeline";
import TmplDescription from "./components/TmplDescription";
import * as classes from "./index.css";

interface ILocationState {
  pipelineId?: number;
  newCredential?: ICredential;
  projectId?: IProject["id"];
  newPipelineSource?: EPipelineType;
}

const ConnectorGDSCreateReport: FC = () => {
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  const [mode, setMode] = useState<ComponentProps<typeof SelectMode>["value"]>(
    (location.state?.projectId && location.state?.newCredential) || location.state?.pipelineId ? "create" : undefined,
  );

  const { data: user } = useQueryMe();
  const { data: tmpls } = useQueryTmpls();
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { tmplId } = useParams<{ tmplId: string }>();
  const [pipelineId, setPipelineId] = useState<IPipeline["id"] | null>(location.state?.pipelineId || null);
  const [refetchPipeline, setRefetchPipeline] = useState(false);
  const [activeStep, setActiveStep] = useState(location.state?.projectId ? 1 : 0);
  const { data: pipeline } = useQueryPipelineGet(pipelineId || undefined, {
    enabled: Boolean(pipelineId) && activeStep === 2,
    onSuccess: (data) => {
      setRefetchPipeline(["in_progress", "in_progress_first_run", "ready_partly"].includes(data.data_status));
    },
    refetchInterval: refetchPipeline ? 3000 : false,
  });

  const tmpl = useMemo(() => tmpls?.find(({ id }) => id === +tmplId), [tmplId, tmpls]);

  const isMerge = useMemo(
    () => Boolean(tmpl?.pipeline_source && [EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(tmpl.pipeline_source)),
    [tmpl],
  );

  const pipelineTypeGroup = useMemo(
    () => pipeline && pipelineTypes && pipelineTypes[pipeline.source].group,
    [pipeline, pipelineTypes],
  );

  const [newPipelineSource, setNewPipelineSource] = useState(
    location.state?.newPipelineSource || tmpl?.pipeline_source || null,
  );

  const isNextEnable = useMemo(() => {
    if (activeStep === 0) {
      return Boolean(mode);
    }

    if (activeStep === 1) {
      return Boolean(pipelineId);
    }
  }, [activeStep, mode, pipelineId]);

  const handleCreatePipeline = useCallback((pipeline: IPipeline) => {
    setPipelineId(pipeline.id);
    setActiveStep(2);
  }, []);

  const handleOpenReport = useCallback(() => {
    if (tmpl) {
      window.open(generateOldGDS(tmpl.tmpl_data[0], Number(pipelineId), undefined), "_blank");
    }
  }, [pipelineId, tmpl]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      history.goBack();
    } else {
      if (!tmpl?.pipeline_source && newPipelineSource && activeStep === 1 && mode === "create") {
        setNewPipelineSource(null);
      } else {
        setActiveStep((state) => state - 1);
      }
    }
  }, [activeStep, history, mode, newPipelineSource, tmpl?.pipeline_source]);

  const handleNext = useCallback(() => {
    setActiveStep((state) => state + 1);
  }, []);

  const isEmptyTemplate = useMemo(
    () =>
      process.env.REACT_APP__GDS_EMPTY_TMPLS?.split(",")
        ?.map((id) => Number(id))
        .includes(Number(tmplId)),
    [tmplId],
  );

  useUpdateEffect(() => {
    setNewPipelineSource(location.state?.newPipelineSource || tmpl?.pipeline_source || null);
  }, [tmpl]);

  useMount(() => {
    if (location.state?.pipelineId) {
      setActiveStep(2);
    }
  });

  return (
    <Section>
      <SectionHead
        title={<Trans>Report creation (dashboard)</Trans>}
        subTitle={
          tmpl?.pipeline_source && pipelineTypes?.[tmpl.pipeline_source] ? pipelineTypes[tmpl.pipeline_source].title : undefined
        }
        icon={<ConnectorGdsIcon />}
      />

      {tmpl && (
        <Grid container spacing={3} alignItems="stretch">
          <Grid css={cssc([classes.createForm, isEmptyTemplate && classes.createForm_empty])} item xl={6} xs={12}>
            <Paper sx={{ minHeight: "100%", padding: 3 }}>
              {activeStep === 0 && (
                <UiSimpleStepper
                  num={1}
                  title={isMerge ? <Trans>DataFan Merge</Trans> : <Trans>Choosing a public/ad account</Trans>}
                  onClickPrev={handleBack}
                  onClickNext={handleNext}
                  isDisabledNextBtn={!isNextEnable}
                  info={<Trans>The data of your page or advertising account that you will use in the report</Trans>}
                >
                  <SelectMode value={mode} onChange={setMode} source={tmpl.pipeline_source} isMerge={isMerge} />
                </UiSimpleStepper>
              )}

              {activeStep === 1 &&
                (mode === "select" ? (
                  <UiSimpleStepper
                    num={2}
                    title={
                      isMerge ? <Trans>Select DataFan Merge</Trans> : <Trans>Select the connected public/ad cabinet</Trans>
                    }
                    onClickPrev={handleBack}
                    onClickNext={handleNext}
                    isDisabledNextBtn={!isNextEnable}
                  >
                    <SelectPipeline pipelineId={pipelineId} onSelect={setPipelineId} source={tmpl.pipeline_source} />
                  </UiSimpleStepper>
                ) : newPipelineSource && pipelineTypes?.[newPipelineSource] ? (
                  <PipelineCreateForm
                    startStepNum={tmpl.pipeline_source ? 2 : 3}
                    pipelineType={pipelineTypes?.[newPipelineSource]}
                    onSuccess={handleCreatePipeline}
                    onClickBack={handleBack}
                    persistState={{
                      newPipelineSource: newPipelineSource,
                    }}
                  />
                ) : (
                  <SelectPipelineSource value={newPipelineSource} onSelect={setNewPipelineSource} onClickBack={handleBack} />
                ))}

              {activeStep === 2 && (
                <UiSimpleStepper
                  title={<Trans>Data collection</Trans>}
                  onClickPrev={handleBack}
                  onClickNext={handleOpenReport}
                  isDisabledNextBtn={!pipeline || !["ready", "archived"].includes(pipeline.data_status)}
                  textNextBtn={<Trans>Open report in Google Data Studio</Trans>}
                  textPrevBtn={
                    mode === "create" && pipelineTypeGroup && pipelineTypeGroup !== "open_account"
                      ? {
                          ads: t`Connect one more ad account`,
                          merge: t`Create one more Merge`,
                          public: t`Connect one more public`,
                        }[pipelineTypeGroup]
                      : undefined
                  }
                >
                  {pipeline && (
                    <Typography variant="body2" color={pipeline.aggregated_status.color} component="div">
                      <div>{pipeline.aggregated_status.title}</div>
                      <div>{pipeline.aggregated_status.description}</div>
                    </Typography>
                  )}
                </UiSimpleStepper>
              )}

              {activeStep === 2 && user?.meta?.gmail && (
                <Alert severity="info" sx={{ marginTop: 2 }}>
                  <Trans>
                    The report can only be opened under a Google account <b>{user.meta.gmail}</b>.
                  </Trans>
                </Alert>
              )}
            </Paper>
          </Grid>

          {!isEmptyTemplate && (
            <Grid css={classes.description} item xl={6} xs={12}>
              <TmplDescription tmpl={tmpl} />
            </Grid>
          )}
        </Grid>
      )}
    </Section>
  );
};

export default ConnectorGDSCreateReport;
