import { Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { FC, useCallback, useMemo } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onBuyOptions: () => void;
}

const ModalPurchasePipelineAlert: FC<IProps> = ({ isOpen, onClose, onBuyOptions }) => {
  const { data: user } = useQueryMe();

  const handleBuy = useCallback(() => {
    onClose();
    onBuyOptions();
  }, [onBuyOptions, onClose]);

  const subscription = user?.active_subscription;

  return useMemo(
    () => (
      <UiDialog open={isOpen} fullWidth maxWidth="xs" onClose={onClose}>
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="subtitle2">
              <Trans>
                You pay for the connection of a public/ad account, the collection of data on which will be active until the day
                the tariff expires
              </Trans>
              <br />
              {subscription ? `(${moment(subscription?.date_finish).format("D MMMM YYYY")})` : null}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <UiButton onClick={onClose} variant="text">
            <Trans>Cancel</Trans>
          </UiButton>

          <UiButton onClick={handleBuy}>
            <Trans>Ok</Trans>
          </UiButton>
        </DialogActions>
      </UiDialog>
    ),
    [handleBuy, isOpen, onClose, subscription],
  );
};

export default ModalPurchasePipelineAlert;
