import { t, Trans } from "@lingui/macro";
import { Divider, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { IconDots } from "@tabler/icons";
import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import ModalPipelineExport from "src/components/ModalPipelineExport";
import UiMenu from "src/components/UiMenu";
import { useBillingContext } from "src/context/billing/billingContext";
import { EPipelineStatus, EPipelineType } from "src/enums/pipeline";
import { EProjectRole } from "src/enums/project";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import useQueryPipelineArchive from "src/utils/queries/pipeline/useQueryPipelineArchive";
import useQueryPipelineDelete from "src/utils/queries/pipeline/useQueryPipelineDelete";
import useQueryPipelineRestart from "src/utils/queries/pipeline/useQueryPipelineRestart";
import useQueryPipelineUnarchive from "src/utils/queries/pipeline/useQueryPipelineUnarchive";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import DialogArchive from "./components/DialogArchive";
import DialogDescriptions from "./components/DialogDescriptions";
import DialogRemove from "./components/DialogRemove";
import DialogRestart from "./components/DialogRestart";

interface IProps {
  pipeline: IPipelineExtended;
  onActionSuccess: (action: IAction, id: IPipelineExtended["id"]) => void;
  onEditPipeline: (id: IPipelineExtended["id"]) => void;
  view: "grid" | "list";
  currentAction: IAction | undefined;
}

type IAction = "archive" | "unarchive" | "restart" | "export" | "descriptions" | "delete";

const Actions: FC<IProps> = ({ pipeline, onActionSuccess, onEditPipeline, view, currentAction: initialAction }) => {
  const {
    actions: { isSuitableSubscription },
  } = useBillingContext();
  const { data: projects } = useQueryProjects();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [currentAction, setCurrentAction] = useState<IAction | null>(initialAction || null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget), []);

  const handleCloseMenu = useCallback(() => setMenuAnchor(null), []);

  const handleSelectAction = useCallback(
    (action: typeof currentAction) => () => {
      setCurrentAction(action);
    },
    [],
  );

  const handleEditPipeline = useCallback(
    (id: IPipeline["id"]) => () => {
      onEditPipeline(id);
    },
    [onEditPipeline],
  );

  const project = useMemo(() => projects?.find(({ id }) => id === pipeline.project), [pipeline.project, projects]);

  const cannotArchiveReason = useMemo(() => {
    if (!project) {
      return undefined;
    }

    if (![EProjectRole.ADMIN, EProjectRole.OWNER].includes(project.role)) {
      return t`Available only to the owner and admins`;
    } else if (project.creator.isElamaUser) {
      return t`Not available on your pricing plan`;
    } else if (!project.creator.active_subscription || !isSuitableSubscription(ETariffOnPeriodSign.START)) {
      return t`Available on the Start tariff plan and above`;
    } else {
      return undefined;
    }
  }, [isSuitableSubscription, project]);

  const cannotDeleteReason = useMemo(() => {
    if (!project) {
      return undefined;
    }

    if (![EProjectRole.ADMIN, EProjectRole.OWNER].includes(project.role)) {
      return t`Available only to the owner and admins`;
    } else {
      return undefined;
    }
  }, [project]);

  const getActionOptions = useCallback(
    (action: IAction) => ({
      enabled: false,
      onSettled: () => {
        setCurrentAction(null);
      },
      onSuccess: () => {
        onActionSuccess(action, pipeline.id);
      },
    }),
    [onActionSuccess, pipeline.id],
  );

  const { refetch: handleArchive, isFetching: isPendingArchive } = useQueryPipelineArchive(
    ["pipeline-archive", pipeline.id],
    pipeline.id,
    getActionOptions("archive"),
  );

  const { refetch: handleUnarchive, isFetching: isPendingUnarchive } = useQueryPipelineUnarchive(
    ["pipeline-unarchive", pipeline.id],
    pipeline.id,
    getActionOptions("unarchive"),
  );

  const { refetch: handleRestart, isFetching: isPendingRestart } = useQueryPipelineRestart(
    ["pipeline-restart", pipeline.id],
    pipeline.id,
    getActionOptions("restart"),
  );

  const { refetch: handleDelete, isFetching: isPendingDelete } = useQueryPipelineDelete(
    ["pipeline-delete", pipeline.id],
    pipeline.id,
    getActionOptions("delete"),
  );

  useUpdateEffect(() => {
    if (currentAction === "unarchive") {
      handleUnarchive();
    }
  }, [currentAction]);

  useUpdateEffect(() => {
    if (initialAction) {
      setCurrentAction(initialAction);
    }
  }, [initialAction]);

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <IconDots color="black" />
      </IconButton>

      <UiMenu
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        withBackdrop
        anchorOrigin={
          view === "list"
            ? {
                horizontal: "left",
                vertical: "center",
              }
            : {
                horizontal: "center",
                vertical: "top",
              }
        }
        transformOrigin={
          view === "list"
            ? {
                horizontal: "right",
                vertical: "center",
              }
            : {
                horizontal: "center",
                vertical: "bottom",
              }
        }
      >
        <MenuItem onClick={handleEditPipeline(pipeline.id)}>
          <Trans>Settings and status</Trans>
        </MenuItem>

        <Divider />

        {![EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source) &&
          (pipeline.status === EPipelineStatus.ARCHIVED ? (
            !cannotArchiveReason ? (
              <MenuItem onClick={handleSelectAction("unarchive")} disabled={isPendingUnarchive}>
                <Trans>Unarchive</Trans>
              </MenuItem>
            ) : (
              <Tooltip title={cannotArchiveReason}>
                <div>
                  <MenuItem disabled>
                    <Trans>Unarchive</Trans>
                  </MenuItem>
                </div>
              </Tooltip>
            )
          ) : !cannotArchiveReason ? (
            <MenuItem onClick={handleSelectAction("archive")}>
              <Trans>Archive</Trans>
            </MenuItem>
          ) : (
            <Tooltip title={cannotArchiveReason}>
              <div>
                <MenuItem disabled>
                  <Trans>Archive</Trans>
                </MenuItem>
              </div>
            </Tooltip>
          ))}

        {![EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source) && (
          <MenuItem disabled={pipeline.status === EPipelineStatus.ARCHIVED} onClick={handleSelectAction("restart")}>
            <Trans>Full restart</Trans>
          </MenuItem>
        )}

        {pipeline.data_status === "ready" ? (
          <MenuItem onClick={handleSelectAction("export")}>
            <Trans>Export</Trans>
          </MenuItem>
        ) : (
          <Tooltip
            title={
              <Trans>
                The data is not fully collected yet, wait a couple of minutes and order the Excel generation again. The status
                of data collection can be viewed in the list of datasets.
              </Trans>
            }
          >
            <div>
              <MenuItem disabled>
                <Trans>Export</Trans>
              </MenuItem>
            </div>
          </Tooltip>
        )}

        <MenuItem onClick={handleSelectAction("descriptions")}>
          <Trans>Field descriptions</Trans>
        </MenuItem>

        <Divider />

        {!cannotDeleteReason ? (
          <MenuItem onClick={handleSelectAction("delete")}>
            <Trans>Delete</Trans>
          </MenuItem>
        ) : (
          <Tooltip title={cannotDeleteReason}>
            <div>
              <MenuItem disabled>
                <Trans>Delete</Trans>
              </MenuItem>
            </div>
          </Tooltip>
        )}
      </UiMenu>

      <DialogArchive
        pipeline={pipeline}
        isOpen={currentAction === "archive"}
        isLoading={isPendingArchive}
        onClose={handleSelectAction(null)}
        onSubmit={handleArchive}
      />

      <DialogRestart
        isOpen={currentAction === "restart"}
        isLoading={isPendingRestart}
        onClose={handleSelectAction(null)}
        onSubmit={handleRestart}
      />

      <ModalPipelineExport pipeline={pipeline} isOpen={currentAction === "export"} onClose={handleSelectAction(null)} />

      <DialogRemove
        pipeline={pipeline}
        isOpen={currentAction === "delete"}
        isLoading={isPendingDelete}
        onClose={handleSelectAction(null)}
        onSubmit={handleDelete}
      />

      <DialogDescriptions pipeline={pipeline} isOpen={currentAction === "descriptions"} onClose={handleSelectAction(null)} />
    </>
  );
};

export default Actions;
