import React, { FC } from "react";
import { CirclePicker, ColorResult } from "react-color";

import * as classes from "./index.css";

interface IProps {
  color: string | undefined;
  onChange: (color: ColorResult) => void;
}

const ProjectColorPicker: FC<IProps> = ({ color, onChange }) => (
  <CirclePicker
    css={classes.container}
    circleSpacing={8}
    width="100%"
    circleSize={24}
    color={color}
    onChangeComplete={onChange}
    colors={[
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#00bcd4",
      "#CDDC39",
      "#FFEB3B",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#607D8B",
    ]}
  />
);

export default ProjectColorPicker;
