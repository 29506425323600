import { Trans } from "@lingui/macro";
import { Link } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import ModalSamePipeline from "src/components/ModalSamePipeline";
import { useBillingContext } from "src/context/billing/billingContext";
import { EPipelineType } from "src/enums/pipeline";
import { IPipelineExtended, IPipelineType } from "src/types/pipeline";
import { IPipelineConfig } from "src/types/pipelineConfig";
import { getSamePipelines } from "src/utils/getSamePipelines";
import usePipelineAggregation from "src/utils/hoooks/usePipelineAggregation";
import isSubscriptionTrial from "src/utils/isSubscriptionTrial";
import useQueryPipelineCreate from "src/utils/queries/pipeline/useQueryPipelineCreate";
import useQueryPipelines from "src/utils/queries/pipeline/useQueryPipelines";
import { useQueryPipelinesAllInvalidate } from "src/utils/queries/pipeline/useQueryPipelinesAllGet";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import CreateMerge from "./components/CreateMerge";
import CreateOpenAccount from "./components/CreateOpenAccount";
import CreatePublicAds from "./components/CreatePublicAds";
import CreateTelegram from "./components/CreateTelegram";

interface IProps {
  pipelineType: IPipelineType;
  startStepNum?: number;
  onSuccess: (pipeline: IPipelineExtended) => void;
  onClickBack?: () => void;
  persistState?: {
    [key: string]: any;
  };
}

const PipelineCreateForm: FC<IProps> = ({ pipelineType, onSuccess, startStepNum = 1, onClickBack, persistState }) => {
  const aggregatePipeline = usePipelineAggregation();
  const pipelinesAllInvalidate = useQueryPipelinesAllInvalidate();
  const {
    actions: { openModalSubscriptionAlert },
  } = useBillingContext();
  const { data: projects } = useQueryProjects();
  const [initialPipelineType, setInitialPipelineType] = useState(pipelineType);
  const [samePipelines, setSamePipelines] = useState<IPipelineExtended[]>([]);
  const [relativePipelinesFetchEnabled, setRelativePipelinesFetchEnabled] = useState(false);
  const [config, setConfig] = useState<IPipelineConfig | null>(null);
  const { mutate: createPipeline, isLoading: createPipelineLoading } = useQueryPipelineCreate({
    onSuccess: (pipeline) => {
      setConfig(null);
      onSuccess(aggregatePipeline(pipeline));
      pipelinesAllInvalidate();
    },
  });

  const pipelineGroup = useMemo(
    () =>
      ([EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipelineType.id) && "merge") ||
      ([EPipelineType.INST_OPEN_ACCOUNT].includes(pipelineType.id) && "openAccount") ||
      ([EPipelineType.TELEGRAM_CHANNEL, EPipelineType.TELEGRAM_POST, EPipelineType.TELEGRAM_CHANNEL_POST].includes(
        pipelineType.id,
      ) &&
        "telegram") ||
      "publicOrAds",
    [pipelineType.id],
  );

  const handleCloseSameModal = useCallback(() => {
    setRelativePipelinesFetchEnabled(true);
    setSamePipelines([]);
  }, []);

  const handleSubmitForm = useCallback(() => {
    if (config) {
      setSamePipelines([]);
      createPipeline(config);
    }
  }, [config, createPipeline]);

  const { isFetching: relativePipelinesFetching, refetch: relativePipelinesRefetch } = useQueryPipelines(
    {
      enabled: relativePipelinesFetchEnabled && initialPipelineType.id === pipelineType.id,
      onSuccess: (resp) => {
        const relativePipelines = resp.pages.reduce((acc, page) => {
          acc = [...acc, ...page.results.map((pipeline) => pipeline)];

          return acc;
        }, [] as IPipelineExtended[]);

        if (config && config.config) {
          const samePipelines = getSamePipelines(relativePipelines, config.config, pipelineType.id);

          setSamePipelines(samePipelines);

          if (!samePipelines?.length) {
            handleSubmitForm();
          }
        }
      },
    },
    {
      filtered: {
        project: config?.project_id ? [config?.project_id] : undefined,
        source: [pipelineType.id],
      },
      limit: 1000,
      offset: 0,
    },
  );

  const handleConfigSubmit = useCallback(
    (newConfig: IPipelineConfig) => {
      setRelativePipelinesFetchEnabled(true);
      setConfig(newConfig);
      relativePipelinesRefetch();
    },
    [relativePipelinesRefetch],
  );

  const handleCreatePreflight = useCallback(
    (newConfig: IPipelineConfig) => {
      const project = projects && newConfig && projects.find(({ id }) => id === newConfig.project_id);

      if (!project) {
        return;
      }

      const activeSubscription = project.creator.active_subscription;
      const previousSubscription = project.creator.previous_subscription;
      const lastSubscription = activeSubscription || previousSubscription;
      const isLimitReached = lastSubscription && lastSubscription.limits.pipelines <= lastSubscription.limits_usage.pipelines;
      const isTrial = lastSubscription && isSubscriptionTrial(lastSubscription);
      const isOwnProject = project.role === "owner";

      if (!project.creator.billing_enabled || (activeSubscription && !isLimitReached)) {
        return handleConfigSubmit(newConfig);
      }

      openModalSubscriptionAlert({
        action: (() => {
          if (!isOwnProject || (project.creator.isElamaUser && !isLimitReached)) {
            return undefined;
          }

          return isLimitReached && !isTrial ? "buy_option" : "renew";
        })(),
        extraParams: {
          additionalOptionsNum: isLimitReached ? 1 : 0,
        },
        onSuccess: () => handleConfigSubmit(newConfig),
        text: (() => {
          if (isOwnProject) {
            if (isLimitReached) {
              if (isTrial) {
                return (
                  <Trans>
                    You reached the limit of datasets on the Trial pricing plan. <br />
                    Pay a tariff to create a report
                  </Trans>
                );
              } else {
                return (
                  <Trans>
                    You have reached your paid connection limit. <br />
                    Add an additional connection to create a report
                  </Trans>
                );
              }
            } else if (isTrial) {
              return (
                <Trans>
                  Trial period ended. <br />
                  Pay a tariff to create a report
                </Trans>
              );
            } else {
              return (
                <Trans>
                  Your tariff is over. <br />
                  Renew the tariff to create a report
                </Trans>
              );
            }
          } else {
            return (
              <>
                {isLimitReached ? (
                  <Trans>Project owner reached the limit of datasets on the pricing plan.</Trans>
                ) : (
                  <Trans>Project owner have ended the period of tariff.</Trans>
                )}
                <br />
                <Trans>Please contact the project owner</Trans>
                {": "}
                <Link component="a" href={`mailto:${project.creator.email}`}>
                  {project.creator.email}
                </Link>
              </>
            );
          }
        })(),
        title: <Trans>The report is almost ready!</Trans>,
      });
    },
    [handleConfigSubmit, openModalSubscriptionAlert, projects],
  );

  useUpdateEffect(() => {
    setRelativePipelinesFetchEnabled(false);
    setSamePipelines([]);
    setInitialPipelineType(pipelineType);
  }, [pipelineType]);

  const createComponentProps = {
    isLoading: relativePipelinesFetching || createPipelineLoading,
    onClickBack,
    onConfigReady: handleCreatePreflight,
    persistState,
    pipelineType,
  };

  if (initialPipelineType.id !== pipelineType.id) {
    return null;
  }

  return (
    <>
      {pipelineGroup === "merge" && <CreateMerge {...createComponentProps} startStepNum={startStepNum} />}

      {pipelineGroup === "publicOrAds" && <CreatePublicAds {...createComponentProps} startStepNum={startStepNum} />}

      {pipelineGroup === "openAccount" && <CreateOpenAccount {...createComponentProps} startStepNum={startStepNum} />}

      {pipelineGroup === "telegram" && <CreateTelegram {...createComponentProps} startStepNum={startStepNum} />}

      <ModalSamePipeline
        open={Boolean(samePipelines.length)}
        onClose={handleCloseSameModal}
        onConfirm={handleSubmitForm}
        pipelines={samePipelines || []}
        isLoading={relativePipelinesFetching || createPipelineLoading}
        pipelineType={pipelineType}
      />
    </>
  );
};

export default PipelineCreateForm;
