import { Trans } from "@lingui/macro";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IconInfoCircle } from "@tabler/icons";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigVkPublic } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigVkPublic | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigVkPublic: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedGroup, setSelectedGroup] = useState<IPipelineConfigVkPublic["group_data"] | null>(null);

  const {
    data: groups,
    isFetched: groupsFetched,
    isFetching: groupsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.VK_GROUPS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedGroup) {
          setSelectedGroup(data[0] || null);
        }
      },
    },
  );

  const groupOptions = useMemo(
    () =>
      groups?.map((group) => ({
        data: group,
        label: `${group.name} (${group.id})`,
        value: group.id,
      })) || [],
    [groups],
  );

  const handleSelectGroup = useCallback(
    (value: typeof groupOptions[number]["value"] | null, data: typeof groupOptions[number]["data"] | null) => {
      if (data) {
        setSelectedGroup(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedGroup));

    onChange({
      group_data: selectedGroup || undefined,
      group_id: selectedGroup?.["id"],
    });
  }, [onChangeValidation, onChange, selectedGroup]);

  return (
    <>
      {groupOptions.length === 0 && groupsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <UiSelect
            isSearchable={groupOptions.length > 7}
            isLoading={groupsIsFetching}
            value={selectedGroup?.id}
            onChange={handleSelectGroup}
            options={groupOptions}
            placeholder={<Trans>Community</Trans>}
            fullWidth
          />

          <Tooltip
            title={
              <Trans>
                If you don't see the community you need in the list, please check your access level. Your role in the community
                should be Editor or Administrator
              </Trans>
            }
          >
            <span>
              <IconInfoCircle />
            </span>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};

export default ConfigVkPublic;
