import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<Theme, { fullSize?: boolean; size?: number; borderRadius?: number | string }>({
  container: ({ fullSize, size, borderRadius }) => ({
    "& img": {
      width: "100%",
    },

    borderRadius: borderRadius || 6,
    display: "inline-block",
    height: fullSize ? "100%" : typeof size === "undefined" ? 16 : size,
    lineHeight: 0,
    margin: "3px 0 0",
    overflow: "hidden !important",
    width: fullSize ? "100%" : typeof size === "undefined" ? 16 : size,
  }),
});
