import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useUpdateEffect } from "react-use";
import { IPopup, IRootState, popupRemove } from "src/redux";

import * as classes from "./index.css";

const displayed: { [key: string]: boolean } = {};

const Snackbars: FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const popupsArray = useSelector((state: IRootState) => Object.values(state.modules.popups) as IPopup[]);

  const handleClose = useCallback(
    (id: SnackbarKey) => () => {
      closeSnackbar(id);
    },
    [closeSnackbar],
  );

  const getOptions = useCallback(
    (notification: IPopup): OptionsObject => ({
      action: (key) => (
        <IconButton onClick={handleClose(key)} color="inherit" css={classes.button}>
          <CloseIcon />
        </IconButton>
      ),
      autoHideDuration: 5000,
      key: notification.id + "",
      variant: notification.importance || "info",
    }),
    [handleClose],
  );

  useUpdateEffect(() => {
    popupsArray.forEach((notification) => {
      if (!displayed[notification.id]) {
        enqueueSnackbar(notification.text, getOptions(notification));
        displayed[notification.id] = true;
        dispatch(popupRemove({ popupId: notification.id }));
      }
    });
  }, [popupsArray]);

  useMount(() => {
    popupsArray.forEach((notification) => {
      if (!displayed[notification.id]) {
        enqueueSnackbar(notification.text, getOptions(notification));
        displayed[notification.id] = true;
        dispatch(popupRemove({ popupId: notification.id }));
      }
    });
  });

  return null;
};

export default Snackbars;
