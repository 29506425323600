import { Trans } from "@lingui/macro";
import { Box, Link, Typography } from "@mui/material";
import { FC } from "react";
import ConnectorInstruction from "src/components/ConnectorInstruction";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import ConnectorGoogleSheetsIcon from "src/icons/ConnectorGoogleSheetsIcon";

import * as classes from "./index.css";

const ConnectorSpreadsheet: FC = () => (
  <Section>
    <SectionHead
      title={<Trans>Connector DataFan — Google Sheets</Trans>}
      subTitle={<Trans>Regular automatic uploading of data from your publics and ad accounts to Google Spreadsheets</Trans>}
      icon={<ConnectorGoogleSheetsIcon />}
    />

    <Typography variant="body2" mt={5} textAlign="center">
      <UiButton target="_blank" component="a" href="https://docs.google.com/spreadsheets">
        <Trans>Create report</Trans>
      </UiButton>
    </Typography>

    <div css={classes.instruction}>
      <Typography variant="h2" mb={6}>
        <Trans>How to create a Google Sheets report via DataFan</Trans>
      </Typography>

      <ConnectorInstruction
        steps={[
          {
            text: <Trans>Nothing complicated. Enter your email and come up with a password</Trans>,
            title: <Trans>Registration in the service</Trans>,
          },
          {
            text: <Trans>Connect a public or ad account</Trans>,
            title: <Trans>Data collection</Trans>,
          },
          {
            text: (
              <Trans>
                <Link target="_blank" href="https://workspace.google.com/marketplace/app/datafan/547755081017" component="a">
                  Install the DataFan add-on
                </Link>{" "}
                from the Google marketplace and run it in the table into which you plan to upload data.{" "}
                <Link target="_blank" href="https://www.youtube.com/watch?v=RUXxq_5lGsA&ab_channel=Datafan" component="a">
                  Video instruction here
                </Link>
              </Trans>
            ),
            title: <Trans>Installing the datafan™ add-on in Google Sheets</Trans>,
          },
          {
            text: (
              <Trans>
                In the window that opens on the right, select the dataset you need, the data period and the metrics and
                indicators you need. Click the "Upload data to table" button and start working with the data.
              </Trans>
            ),
            title: <Trans>Configuring the upload</Trans>,
          },
        ]}
      />

      <Box textAlign="center" mt={6}>
        <UiButton target="_blank" component="a" href="https://docs.google.com/spreadsheets">
          <Trans>Create report</Trans>
        </UiButton>
      </Box>
    </div>
  </Section>
);

export default ConnectorSpreadsheet;
