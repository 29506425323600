import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { FC } from "react";
import { components, MenuProps } from "react-select";
import UiButton from "src/components/UiButton";

interface IProps extends MenuProps<any, any> {
  onClickCreateNew: () => void;
}

const SelectboxMenu: FC<IProps> = ({ children, onClickCreateNew, ...props }) => (
  <components.Menu {...(props as any)}>
    <Box p={1}>
      <UiButton size="small" onMouseDown={onClickCreateNew} disableElevation fullWidth>
        <Trans>Connect public / ad account</Trans>
      </UiButton>
    </Box>

    {children}
  </components.Menu>
);

export default (onClickCreateNew: () => void) => (props: MenuProps<any, any>) =>
  <SelectboxMenu onClickCreateNew={onClickCreateNew} {...props} />;
