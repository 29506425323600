import { t } from "@lingui/macro";
import { Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { IconCopy, IconEye, IconEyeOff } from "@tabler/icons";
import copy from "copy-to-clipboard";
import * as React from "react";
import { ChangeEvent, FC, MutableRefObject, ReactNode, useCallback, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";

export interface UiTextFieldProps
  extends Omit<OutlinedInputProps, "endAdornment" | "startAdornment" | "onChange" | "onBlur" | "label"> {
  inputRef?: ((node: HTMLInputElement) => void) | MutableRefObject<HTMLInputElement | null>;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  label?: ReactNode;
  onChange?: (value: string) => void;
  delay?: number;
  minValue?: number;
  isCopyable?: boolean;
  copyText?: string;
}

const UiTextField: FC<UiTextFieldProps> = ({
  inputRef,
  endIcon,
  startIcon,
  onChange,
  value,
  delay = 0,
  type,
  label,
  minValue,
  isCopyable,
  copyText,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState(typeof value === "undefined" ? "" : value);
  const inputNode = useRef<HTMLInputElement>();
  const changeTimeoutId = useRef(0);
  const [copyTooltipIsOpen, setCopyTooltipOpen] = useState(false);

  const handleClickTogglePasswordVisibility = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const inputRefCb = useCallback(
    (node) => {
      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(node);
        } else {
          inputRef.current = node;
        }
      }

      inputNode.current = node;
    },
    [inputRef],
  );

  const handleClickIcon = useCallback(() => {
    inputNode.current?.focus();
  }, []);

  const handleChangeCurrent = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleBlurCurrent = useCallback(() => {
    const isNumber = !Number.isNaN(currentValue);

    if (typeof minValue !== "undefined" && isNumber) {
      setCurrentValue(Math.max(Number(currentValue), minValue));
    } else {
      setCurrentValue(currentValue);
    }
  }, [currentValue, minValue]);

  const handleCopy = useCallback(() => {
    if (typeof value === "string") {
      copy(value);

      setCopyTooltipOpen(true);
      setTimeout(() => {
        setCopyTooltipOpen(false);
      }, 2000);
    }
  }, [value]);

  useUpdateEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useUpdateEffect(() => {
    window.clearTimeout(changeTimeoutId.current);

    changeTimeoutId.current = window.setTimeout(() => {
      onChange && onChange(currentValue as string);
    }, delay);
  }, [currentValue]);

  return (
    <>
      {label && <Typography mb={1.6}>{label}</Typography>}

      <OutlinedInput
        inputRef={inputRefCb}
        endAdornment={(() => {
          if (isCopyable) {
            return (
              <InputAdornment position="end">
                <Tooltip
                  followCursor={false}
                  open={copyTooltipIsOpen}
                  title={copyText || t`Text copied to clipboard`}
                  placement="bottom"
                >
                  <IconButton onClick={handleCopy} size="small">
                    <IconCopy />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            );
          }

          if (type === "password") {
            return (
              <InputAdornment position="end">
                <IconButton onClick={handleClickTogglePasswordVisibility} size="small">
                  {showPassword ? <IconEyeOff /> : <IconEye />}
                </IconButton>
              </InputAdornment>
            );
          }

          if (endIcon) {
            return (
              <InputAdornment position="end" onClick={handleClickIcon}>
                {endIcon}
              </InputAdornment>
            );
          }

          return undefined;
        })()}
        type={showPassword ? "text" : type}
        startAdornment={
          startIcon ? (
            <InputAdornment position="start" onClick={handleClickIcon}>
              {startIcon}
            </InputAdornment>
          ) : undefined
        }
        value={typeof currentValue !== undefined && currentValue !== null ? currentValue : ""}
        onChange={handleChangeCurrent}
        onBlur={handleBlurCurrent}
        {...props}
      />
    </>
  );
};

export default UiTextField;
