import { ISocialSmpAccountData } from "src/types/socialAuth";

import useQuerySocialSignIn from "./useQuerySocialSignIn";
import useQuerySocialSignUp from "./useQuerySocialSignUp";

interface IOutput {
  token: string;
  refresh_token: string;
}

export default function useQuerySocialSignInAndUp() {
  const { mutate: signIn, status: signInStatus } = useQuerySocialSignIn({ silent: true });
  const { mutate: signUp, status: signUpStatus, error: signUpError } = useQuerySocialSignUp();

  return {
    error: signUpError,
    mutate: (
      params: ISocialSmpAccountData,
      options?: {
        onSuccess?: (result: IOutput) => void;
        onError?: () => void;
      },
    ) => {
      signIn(params, {
        onError: () => {
          signUp(params, {
            onError: options?.onError,
            onSuccess: options?.onSuccess,
          });
        },
        onSuccess: options?.onSuccess,
      });
    },
    status: signInStatus === "loading" ? signInStatus : signUpStatus,
  };
}
