import { Trans } from "@lingui/macro";
import React, { FC, useCallback, useMemo, useState } from "react";
import UiSelect from "src/components/UiSelect";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import { IPipelineType } from "src/types/pipeline";
import groupPipelineTypes from "src/utils/groupPipelineTypes";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IProps {
  value: IPipelineType["id"] | null;
  onSelect: (value: IPipelineType["id"] | null) => void;
  onClickBack: () => void;
}

const SelectPipelineSource: FC<IProps> = ({ value, onSelect, onClickBack }) => {
  const [tempValue, setTempValue] = useState(value);
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: user } = useQueryMe();

  const sourceOptions = useMemo(() => {
    const grouped = pipelineTypes && groupPipelineTypes(Object.values(pipelineTypes));

    return (
      grouped &&
      Object.values(grouped).map((group) => ({
        label: group.shortTitle,
        options: group.items
          .filter((pt) => !pt.onlyStaff || user?.is_staff)
          .map((pt) => ({
            label: pt.shortTitle,
            value: pt.id,
          })),
      }))
    );
  }, [pipelineTypes, user?.is_staff]);

  const handleNext = useCallback(() => {
    onSelect(tempValue);
  }, [onSelect, tempValue]);

  return (
    <UiSimpleStepper
      num={2}
      title={<Trans>Select the type of public / ad account</Trans>}
      onClickPrev={onClickBack}
      onClickNext={handleNext}
      isDisabledNextBtn={!tempValue}
    >
      <UiSelect
        fullWidth
        value={tempValue}
        onChange={setTempValue}
        isDisabled={!sourceOptions?.length}
        options={sourceOptions}
      />
    </UiSimpleStepper>
  );
};

export default SelectPipelineSource;
