import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import Loader from "src/components/Loader";
import SelectAccountCredential from "src/components/SelectAccountCredential";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IPipeline } from "src/types/pipeline";
import { IStreamConfig } from "src/types/stream";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

type ISettings = NonNullable<IStreamConfig["extra_settings"]>["yandex_metrika"];

interface IProps {
  enabled: boolean;
  source: EPipelineType.FB_ADS | EPipelineType.VK_ADS;
  settings: ISettings;
  onToggle: (checked: boolean) => void;
  onChange: (settings: ISettings) => void;
  pipeline: IPipeline;
  persistState?: {
    [key: string]: any;
  };
  isOwner: boolean;
}

const AdsYaCounter: FC<IProps> = ({ enabled, settings, onChange, onToggle, pipeline, persistState, source, isOwner }) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: accounts, isFetching: accountsIsLoading } = useQueryCredentialsGet(undefined, {
    projects: [pipeline.project],
    stream: EPipelineType.YA_METRIKA,
  });
  const { data: counters, isFetching: countersIsLoading } = useQueryCredentialMetaGet(
    {
      credentialId: settings?.account_credentials_id || 0,
      object: ECredentialMetaObject.YA_METRIKA_COUNTERS,
      projectId: pipeline.project,
    },
    {
      enabled: Boolean(settings?.account_credentials_id && isOwner),
      onSuccess: (data) => {
        if ((!settings?.counter || !settings?.counter_name) && data[0]) {
          onChange({
            ...settings,
            counter: data[0].id,
            counter_name: data[0].name,
          });
        }
      },
    },
  );
  const { data: goals } = useQueryCredentialMetaGet(
    {
      credentialId: settings?.account_credentials_id || 0,
      extraParams: {
        counter_id: settings?.counter,
      },
      object: ECredentialMetaObject.YA_METRIKA_GOALS,
      projectId: pipeline.project,
    },
    {
      enabled: Boolean(settings?.counter && isOwner),
      onSuccess: (data) => {
        if (!settings?.goals && data[0]) {
          onChange({
            ...settings,
            goals: [data[0]],
          });
        }
      },
    },
  );

  const handleToggleYandex = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onToggle(checked);

      if (!checked) {
        onChange(undefined);
      }
    },
    [onChange, onToggle],
  );

  const counterOptions = useMemo(() => {
    const result =
      counters?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [];

    if (settings?.counter && settings?.counter_name && !result.some(({ value }) => value === settings.counter)) {
      result.push({
        label: settings.counter_name,
        value: settings.counter,
      });
    }

    return result;
  }, [counters, settings]);

  const goalOptions = useMemo(
    () =>
      goals?.map((goal) => ({
        data: goal,
        label: goal.name,
        value: goal.id,
      })) || [],
    [goals],
  );

  const handleChangeCredential = useCallback(
    (credential?: ICredential) => {
      if (credential) {
        onChange({
          account_credentials_id: credential.id,
          account_credentials_name: credential.name,
          counter: settings?.account_credentials_id === credential.id ? settings?.counter : undefined,
          counter_name: settings?.account_credentials_id === credential.id ? settings?.counter_name : undefined,
        });
      }
    },
    [onChange, settings?.account_credentials_id, settings?.counter, settings?.counter_name],
  );

  const handleChangeCounter = useCallback(
    (counterId: number | null) => {
      if (counterId) {
        onChange({
          ...settings,
          counter: counterId,
          counter_name: counterOptions.find(({ value }) => value === counterId)?.label,
        });
      }
    },
    [counterOptions, onChange, settings],
  );

  const handleChangeGoal = useCallback(
    (value: typeof goalOptions[number]["value"] | null, data: typeof goalOptions[number]["data"] | null) => {
      if (data) {
        onChange({
          ...settings,
          goals: [data],
        });
      }
    },
    [onChange, settings],
  );

  const isCredLost = useMemo(
    () =>
      Boolean(
        settings?.account_credentials_id &&
          !accountsIsLoading &&
          !accounts?.some(({ id, lost_at }) => id === settings.account_credentials_id && !lost_at),
      ),
    [accounts, accountsIsLoading, settings?.account_credentials_id],
  );

  const isCounterLost = useMemo(
    () => Boolean(settings?.counter && !countersIsLoading && !counters?.some(({ id }) => id === settings.counter)),
    [counters, countersIsLoading, settings?.counter],
  );

  return (
    <>
      <Box mt={2} mb={2}>
        <FormControlLabel
          control={<Checkbox disabled={!isOwner} checked={enabled} onChange={handleToggleYandex} />}
          label={<Trans>Specify a Yandex.Metrika counter to see the traffic metrics that led to your ad campaigns</Trans>}
        />
      </Box>

      {enabled && (
        <>
          <Box mb={3}>
            {accountsIsLoading ? (
              <Box sx={{ height: 46 }}>
                <Loader autoHeight />
              </Box>
            ) : (
              <>
                {isCredLost || isCounterLost ? (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    <Trans>
                      Access to the specified Yandex.Metrika tag is not available in DataFan. Reconnect the specified counter or
                      connect a new one.
                    </Trans>
                  </Alert>
                ) : (
                  <Alert severity="info" sx={{ mb: 3 }}>
                    <Trans>Yandex.Metrika data are available for the last 3 months</Trans>
                  </Alert>
                )}

                <SelectAccountCredential
                  value={settings?.account_credentials_id}
                  projectId={pipeline.project}
                  source={EPipelineType.YA_METRIKA}
                  onChange={handleChangeCredential}
                  persistState={persistState}
                  ButtonProps={{ disableElevation: true, variant: "outlined" }}
                />
              </>
            )}
          </Box>

          {settings?.account_credentials_id && !accountsIsLoading && !isCredLost && (
            <Box mb={3}>
              {countersIsLoading ? (
                <Box sx={{ height: 46 }}>
                  <Loader autoHeight />
                </Box>
              ) : (
                <>
                  {!counterOptions?.length ? (
                    <Alert severity="error">
                      {pipelineTypes?.[EPipelineType.YA_METRIKA].noDataText || (
                        <Trans>
                          You don't have an available object for data collection. Contact support, we will help you.
                        </Trans>
                      )}
                    </Alert>
                  ) : (
                    <>
                      <UiSelect
                        fullWidth
                        maxMenuHeight={140}
                        value={settings?.counter}
                        placeholder={<Trans>Counter</Trans>}
                        options={counterOptions}
                        onChange={handleChangeCounter}
                      />

                      {EPipelineType.VK_ADS === source && (
                        <Box mt={3}>
                          <UiSelect
                            fullWidth
                            maxMenuHeight={140}
                            value={settings?.goals?.[0].id}
                            placeholder={<Trans>Goal name</Trans>}
                            options={goalOptions}
                            onChange={handleChangeGoal}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AdsYaCounter;
