import { css } from "@emotion/react/macro";

export const purchase = css`
  padding-top: 16px;
`;

export const purchaseTextField = css`
  input {
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  width: 70px;
`;
