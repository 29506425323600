import { FC } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "src/components/PrivateRoute";

import ConnectorGDSCreateReport from "./pages/CreateReport";
import ConnectorGDSHome from "./pages/Home";

const ConnectorGDS: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ConnectorGDSHome} />
      <PrivateRoute exact path={`${path}create/:tmplId/`} component={ConnectorGDSCreateReport} />
    </Switch>
  );
};

export default ConnectorGDS;
