import { Trans } from "@lingui/macro";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { FC } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalFacebookInstruction: FC<IProps> = ({ isOpen, onClose }) => (
  <UiDialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
    <DialogContent>
      <div>
        <iframe
          title="Reconnect Facebook Account"
          src="https://www.youtube.com/embed/Tldm8Nx5gTg"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </DialogContent>

    <DialogActions>
      <UiButton onClick={onClose} color="primary">
        <Trans>Ok</Trans>
      </UiButton>
    </DialogActions>
  </UiDialog>
);

export default ModalFacebookInstruction;
