import { Trans } from "@lingui/macro";
import { FC } from "react";
import { Link } from "react-router-dom";
import UiButton from "src/components/UiButton";
import { IPipelineExtended } from "src/types/pipeline";

interface IProps {
  pipeline: IPipelineExtended;
}

const PipelineAction: FC<IProps> = ({ pipeline }) => {
  const link = pipeline.report_links?.inhouse || pipeline.report_links?.gds;

  return link ? (
    <UiButton component={Link} to={link} disableElevation={true}>
      <Trans>Open report</Trans>
    </UiButton>
  ) : null;
};

export default PipelineAction;
