import {
  CustomPaging,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import { Grid as DxGrid, Toolbar as DxGridToolbar } from "@devexpress/dx-react-grid-material-ui";
import { t, Trans } from "@lingui/macro";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { DialogContent, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { FC, useRef, useState } from "react";
import Loader from "src/components/Loader";
import UiDialog from "src/components/UiDialog";
import UiDxSearchPanel from "src/components/UiDxSearchPanel";
import UiDxTable from "src/components/UiDxTable";
import UiDxTableHeaderRow from "src/components/UiDxTableHeaderRow";
import UiTabs from "src/components/UiTabs";
import { IPipeline } from "src/types/pipeline";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryStreamMapping from "src/utils/queries/stream/useQueryStreamMapping";

import * as classes from "./index.css";

interface IProps {
  pipeline: IPipeline;
  isOpen: boolean;
  onClose: () => void;
}

const generateClassName = createGenerateClassName({
  seed: "devexpress-table",
});

const DialogDescriptions: FC<IProps> = ({ pipeline, isOpen, onClose }) => {
  const { data: pipelinesTypes } = useQueryPipelineTypes();
  const [activeStream, setActiveStream] = useState(pipeline.streams[0].id);
  const filterRef = useRef<HTMLDivElement | null>(null);

  const { data: streamMappingData, isFetching: streamMappingIsFetching } = useQueryStreamMapping(activeStream, {
    enabled: isOpen,
  });

  return (
    <UiDialog
      open={isOpen}
      title={<Trans>Field descriptions {pipeline.name}</Trans>}
      onClose={onClose}
      css={classes.dialog}
      fullScreen
    >
      <DialogContent>
        <>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm>
              <UiTabs value={activeStream} onChange={setActiveStream} variant="fullWidth" selectMode={1024}>
                {pipeline.streams.map((item) => (
                  <Tab key={item.id} label={pipelinesTypes?.[item.source].title || ""} value={item.id} css={classes.tab} />
                ))}
              </UiTabs>
            </Grid>

            <Grid item xs={12} sm={6} lg={3} xl={2} ref={filterRef} />
          </Grid>

          {pipeline.streams.map(
            (stream) =>
              activeStream === stream.id && (
                <Box pt={3} key={stream.id} css={classes.tableWrapper}>
                  {streamMappingIsFetching || !streamMappingData ? (
                    <Loader />
                  ) : (
                    <StylesProvider generateClassName={generateClassName}>
                      <DxGrid
                        rows={streamMappingData}
                        columns={[
                          {
                            name: "group",
                            title: t`Group`,
                          },
                          {
                            name: "label",
                            title: t`Field`,
                          },
                          {
                            name: "dataType",
                            title: t`Type`,
                          },
                          {
                            name: "description",
                            title: t`Description`,
                          },
                          {
                            name: "name",
                            title: t`Name`,
                          },
                        ]}
                      >
                        <SearchState />
                        <SortingState />
                        <IntegratedFiltering />
                        <IntegratedSorting />
                        <PagingState pageSize={streamMappingData.length} />
                        <CustomPaging totalCount={streamMappingData.length} />
                        <UiDxTable columnExtensions={[{ columnName: "description", wordWrapEnabled: true }]} />
                        <UiDxTableHeaderRow showSortingControls />
                        <DxGridToolbar />
                        <UiDxSearchPanel portal={filterRef.current || undefined} />
                      </DxGrid>
                    </StylesProvider>
                  )}
                </Box>
              ),
          )}
        </>
      </DialogContent>
    </UiDialog>
  );
};

export default DialogDescriptions;
