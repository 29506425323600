import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";

interface IProps {
  autoHeight?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: ({ autoHeight }: IProps) => ({
      height: autoHeight ? "auto" : "100px",
    }),
  }),
);

const Loader: FC<IProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Grid item xs={12}>
      <Grid container className={classes.container} justifyContent="center" alignItems="center">
        <CircularProgress size={24} />
      </Grid>
    </Grid>
  );
};

export default Loader;
