export function parseQueryString(queryString?: string): Map<string, string> {
  // if the query string is NULL
  if (queryString == null) {
    queryString = window.location.search.substring(1);
  }

  const params = new Map<string, string>();

  const queries = queryString.split("&");

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split("=");

    const queryKey = decodeURIComponent(indexPair[0]);
    const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");
    if (queryValue.length >= 1) {
      params.set(queryKey, queryValue);
    }
  });

  return params;
}

export function generateReportGDS(reportTemplateId: string, connectorId: string, config: object): string {
  const params = [];
  const jsonString = JSON.stringify(config);
  const encoded = encodeURIComponent(jsonString);
  params.push(`connectorConfig=${encoded}`, `connectorId=${connectorId}`);
  if (reportTemplateId) {
    params.push(`reportTemplateId=${reportTemplateId}`);
  }
  return `${process.env.REACT_APP__GDS_BASE_URL}/datasources/create?${params.join("&")}`;
}

export function generateOldGDS(tmpl: any, pipelineId: number, streams: any[] | undefined) {
  let url = generateReportGDS(tmpl.gds_report_id, tmpl.gds_deploy_id, {
    project_id: pipelineId + "",
  });
  if (pipelineId && pipelineId <= 607 && streams) {
    url = generateReportGDS(tmpl.gds_report_id, tmpl.gds_deploy_id, {
      project_id: streams[0].id + "",
    });
  }
  return url;
}
