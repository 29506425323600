import { IVisualizationScheme } from "lib-data-visualizer";
import { useMemo } from "react";
import { useAsync } from "react-use";
import { EPipelineType } from "src/enums/pipeline";
import { IPipelineExtended } from "src/types/pipeline";
import useQueryProjectGet from "src/utils/queries/project/useQueryProjectGet";

const SCHEMES_EP = {
  [EPipelineType.FB_POST]: "EP_FB_POST",
  [EPipelineType.VK_POST]: "EP_VK_POST",
  [EPipelineType.OK_POST]: "EP_OK_POST",
  [EPipelineType.INST_MEDIA]: "EP_INST_MEDIA",
  [EPipelineType.INST_BA_MEDIA]: "EP_INST_BA_MEDIA",
  [EPipelineType.VK_COMMUNITY_POST]: "EP_VK_COMMUNITY_POST",
  [EPipelineType.OK_COMMUNITY_POST]: "EP_OK_COMMUNITY_POST",
  [EPipelineType.FB_PAGE_POST]: "EP_FB_PAGE_POST",
};

const SCHEMES_DF = {
  [EPipelineType.FB_ADS]: "DF_FB_ADS",
  [EPipelineType.FB_PAGE_POST]: "DF_FB_PAGE_POST",
  [EPipelineType.INST_BA_MEDIA]: "DF_INST_BA_MEDIA",
  [EPipelineType.INST_OPEN_ACCOUNT]: "DF_INST_OPEN_ACCOUNT",
  [EPipelineType.MYTARGET]: "DF_MYTARGET",
  [EPipelineType.OK_COMMUNITY_POST]: "DF_OK_COMMUNITY_POST",
  [EPipelineType.TELEGRAM_CHANNEL_POST]: "DF_TELEGRAM_CHANNEL_POST",
  [EPipelineType.TIKTOK]: "DF_TIKTOK",
  [EPipelineType.VK_ADS]: "DF_VK_ADS",
  [EPipelineType.VK_COMMUNITY_POST]: "DF_VK_COMMUNITY_POST",
  [EPipelineType.YA_DIRECT]: "DF_YA_DIRECT",
  [EPipelineType.YA_DIRECT_CAMPAIGN]: "DF_YA_DIRECT_CAMPAIGN",
};

const isSchemeEpExists = (pipelineSource: EPipelineType): pipelineSource is keyof typeof SCHEMES_EP =>
  pipelineSource in SCHEMES_EP;

const isSchemeDfExists = (pipelineSource: EPipelineType): pipelineSource is keyof typeof SCHEMES_DF =>
  pipelineSource in SCHEMES_DF;

export const useReportScheme = (pipeline: IPipelineExtended | undefined) => {
  const { data: project } = useQueryProjectGet(pipeline?.project);

  const chunkPath = useMemo(() => {
    if (!project || !pipeline) {
      return undefined;
    }

    if (project.special_subowner === "smmplanner" && isSchemeEpExists(pipeline?.source)) {
      return SCHEMES_EP[pipeline.source];
    } else if (isSchemeDfExists(pipeline?.source)) {
      return SCHEMES_DF[pipeline.source];
    }
  }, [pipeline, project]);

  return useAsync(
    async () =>
      chunkPath
        ? ((await import(`lib-data-visualizer/build/templates/${chunkPath}.js`)).default as IVisualizationScheme)
        : undefined,
    [chunkPath],
  );
};
