import merge from "deepmerge";
import { ComponentProps, FC, useCallback, useReducer } from "react";
import { ELanguage } from "src/enums/language";
import { IProject } from "src/types/project";

import { ContextApp, contextAppReducer } from "./appContext";

const LOCAL_STORAGE_KEY = "df-context";

type IUpdate = ComponentProps<typeof ContextApp["Provider"]>["value"]["updateState"];

export interface IAppState {
  context: "default" | "oauth2" | "printer" | "tutorial";
  dashboard: {
    view: "grid" | "list";
    currentProjects?: IProject["id"][];
  };
  sidebar: {
    expanded: boolean;
  };
  isIoZone: boolean;
  language: ELanguage;
  snackbarTimestampNotifications: {
    adBlock?: number;
    emailConfirmation?: number;
  };
  client_server_datetime_diff: number;
  report_tutorial_progress: {
    [key: string]: number;
  };
}

const initialState: IAppState = {
  client_server_datetime_diff: 0,
  context: "default",
  dashboard: {
    view: "grid",
  },
  isIoZone: process.env.REACT_APP__ZONE_IO === "true",
  language: ELanguage.RU,
  report_tutorial_progress: {},
  sidebar: {
    expanded: true,
  },
  snackbarTimestampNotifications: {},
};

const getLocalStorageState = () => {
  const json = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!json) {
    return {};
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};

const AppContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(contextAppReducer, merge(initialState, getLocalStorageState()));

  const updateState: IUpdate = useCallback((value, storage) => {
    dispatch({ payload: value, type: "update" });

    if (storage === "ls") {
      const updatedState = merge(getLocalStorageState(), value);

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedState));
    }
  }, []);

  return <ContextApp.Provider value={{ state: state as IAppState, updateState }}>{children}</ContextApp.Provider>;
};

export default AppContextProvider;
