import "moment/locale/ru";
import "moment/locale/en-gb";

import { t } from "@lingui/macro";
import * as moment from "moment";
import { FC, useMemo } from "react";
import Loadable from "react-loadable";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import Billing from "src/app/Billing";
import ConfirmEmail from "src/app/ConfirmEmail";
import ConnectorExcel from "src/app/ConnectorExcel";
import ConnectorGDS from "src/app/ConnectorGDS";
import ConnectorPowerBI from "src/app/ConnectorPowerBI";
import ConnectorSpreadsheet from "src/app/ConnectorSpreadsheet";
import Credentials from "src/app/Credentials";
import Dashboard from "src/app/Dashboard";
import OAuth2 from "src/app/OAuth2";
import Page404 from "src/app/Page404/Page404";
import PipelineCreate from "src/app/PipelineCreate";
import Profile from "src/app/Profile";
import Projects from "src/app/Projects";
import ReportRouter from "src/app/ReportRouter";
import Support from "src/app/Support";
import Tariffs from "src/app/Tariffs";
import Thanks from "src/app/Thanks";
import Welcome from "src/app/Welcome";
import ComponentLoader from "src/components/ComponentLoader/ComponentLoader";
import EmailConfirmationNotice from "src/components/EmailConfirmationNotice";
import Layout from "src/components/Layout";
import Loader from "src/components/Loader";
import PrivateRoute from "src/components/PrivateRoute";
import Snackbars from "src/components/Snackbars";
import TariffExpireNotice from "src/components/TariffExpireNotice";
import { useAppContext } from "src/context/app/appContext";
import { ELanguage } from "src/enums/language";
import { popupAdd } from "src/redux";
import { getSupportedBrowserLang, parseQueryString } from "src/utils";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import { setAccessToken } from "src/utils/tokens";

const AsyncReport = Loadable({
  loader: () => import("src/app/Report"),
  loading: ComponentLoader,
});

const AsyncReportExport = Loadable({
  loader: () => import("src/app/ReportExport"),
  loading: ComponentLoader,
});

const AsyncPublicDoc = Loadable({
  loader: () => import("src/app/PublicDoc"),
  loading: ComponentLoader,
});

const AsyncAffiliate = Loadable({
  loader: () => import("src/app/Affiliate"),
  loading: ComponentLoader,
});

const ADBLOCK_SNACKBAR_DELAY = 12 * 60 * 60 * 1000;
const EMAIL_SNACKBAR_DELAY = 60 * 60 * 1000;

const App: FC = () => {
  const dispatch = useDispatch();
  const { state, updateState } = useAppContext();
  const location = useLocation<{ disableScrollTop?: boolean } | undefined>();
  const isOAuth2 = location.pathname === "/o/authorize/";
  const isReportExport = /^\/report-export/.test(location.pathname);

  const { data: user, isFetched: userIsFetched } = useQueryMe({
    enabled: !isReportExport,
    onSuccess: (data) => {
      if (data.carrot_quest && typeof carrotquest !== "undefined") {
        carrotquest.auth(data.id, data.carrot_quest);

        if (data.active_subscription?.id) {
          carrotquest.identify({
            Тариф: data.active_subscription.tariff_on_period.tariff.name,
          });
        }
      }
    },
  });

  const emailConfirmationNotice = useMemo(() => {
    if (!user || user?.email_confirmed || state.context !== "default") {
      return;
    }

    if (moment.utc().diff(moment.utc(user.date_joined), "hour") >= 24) {
      return "topNotice";
    } else if (
      !state.snackbarTimestampNotifications.emailConfirmation ||
      Date.now() - (state.snackbarTimestampNotifications.emailConfirmation || 0) >= EMAIL_SNACKBAR_DELAY
    ) {
      return "snackbar";
    }
  }, [state.context, state.snackbarTimestampNotifications.emailConfirmation, user]);

  useUpdateEffect(() => {
    if (user) {
      if (emailConfirmationNotice === "snackbar") {
        dispatch(
          popupAdd({
            text: t`Confirm your e-mail within 24 hours so that the data in your report is up-to-date`,
          }),
        );

        updateState({ snackbarTimestampNotifications: { emailConfirmation: Date.now() } }, "ls");
      }
    }
  }, [user]);

  useUpdateEffect(() => {
    if (!location.state?.disableScrollTop) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useMount(() => {
    updateState({
      language: (parseQueryString().get("lang") as ELanguage) || state.language || getSupportedBrowserLang(),
    });

    if (isReportExport) {
      const token = parseQueryString().get("access_token");

      if (token) {
        setAccessToken(token);
      }
    }

    if (isOAuth2) {
      updateState({ context: "oauth2" });
    }

    moment.locale(state.language);

    const adBlockDetector: HTMLElement | null = document.getElementById("adBanner");

    if (adBlockDetector?.offsetHeight === 0 && !isOAuth2 && !isReportExport) {
      if (
        !state.snackbarTimestampNotifications.adBlock ||
        Date.now() - (state.snackbarTimestampNotifications.adBlock || 0) >= ADBLOCK_SNACKBAR_DELAY
      ) {
        dispatch(
          popupAdd({
            importance: "error",
            text: t`You have an ad blocker running, it can affect the correct display of the contents of the datafan.pro website. Please disable the blocker or add datafan.pro to the list of allowed sites.`,
          }),
        );

        updateState({ snackbarTimestampNotifications: { adBlock: Date.now() } }, "ls");
      }
    }
  });

  if (!isReportExport && !userIsFetched) {
    return <Loader />;
  }

  if (isReportExport) {
    return <Route path="/report-export/:tmplId(\d+)/:pipelineId(\d+)/" component={AsyncReportExport} />;
  }

  return (
    <Layout>
      <Switch>
        <Route path="/connector/gds/" component={ConnectorGDS} />
        <Route path="/connector/spreadsheet/" component={ConnectorSpreadsheet} />
        <Route path="/connector/pbi/" component={ConnectorPowerBI} />
        <Route path="/connector/excel/" component={ConnectorExcel} />
        <Route path="/support/" component={Support} />
        <Route path="/tariffs/" component={Tariffs} />
        <Route path="/:doc(offer-for-service|terms-of-use|privacy-policy)/" component={AsyncPublicDoc} />
        <Route path="/report/:tmplId(\d+)/:pipelineId(\d+)/:specialAccessToken?" component={AsyncReport} />
        <PrivateRoute path="/profile/" component={Profile} />
        <PrivateRoute path="/report-router/:pipelineId(\d+)/" component={ReportRouter} />
        <PrivateRoute path="/buy-success/" component={Thanks} />
        <PrivateRoute path="/projects/" component={Projects} />
        <PrivateRoute path="/billing/" component={Billing} />
        <PrivateRoute path="/credentials/" component={Credentials} />
        <PrivateRoute exact path="/dataset-create/:source(\d+)/" component={PipelineCreate} />
        <PrivateRoute exact path="/o/authorize" component={OAuth2} />
        <PrivateRoute path="/confirm-email/:code/" component={ConfirmEmail} />
        <PrivateRoute path="/welcome/" component={Welcome} />
        <PrivateRoute path="/affiliate/" component={AsyncAffiliate} />
        <PrivateRoute exact path=":screen(/|/connect)" component={Dashboard} />
        <Route component={Page404} />
      </Switch>
      <Snackbars />
      <TariffExpireNotice />
      {emailConfirmationNotice === "topNotice" && <EmailConfirmationNotice />}
    </Layout>
  );
};

export default App;
