import * as Sentry from "@sentry/browser";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useAppContext } from "src/context/app/appContext";
import { ELanguage } from "src/enums/language";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IResponseUser } from "src/types/response";
import { ISubscription } from "src/types/subscription";
import { IUser } from "src/types/user";
import isSubscriptionTrial from "src/utils/isSubscriptionTrial";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";
import useQueryMeMetaGet from "src/utils/queries/user/useQueryMeMetaGet";
import { parseQueryString } from "src/utils/url";

const RAVEN_ENABLE = process.env.REACT_APP_RAVEN_ENABLE;
const QUERY_KEY_USER_ME_META = "user-me-meta";
const QUERY_KEY_USER_ME = "user-me";

export default function useQueryMe(queryOptions?: IUseQueryBaseOptions<IResponseUser, IUser>) {
  const { updateState } = useAppContext();
  const { data: meta, isFetched: metaIsFetched } = useQueryMeMetaGet<{ gmail: string }>("gds", {
    enabled: queryOptions?.enabled,
    refetchOnMount: false,
    retryOnMount: false,
    silent: true,
  });

  return useQueryBase<IResponseUser, IUser>(
    QUERY_KEY_USER_ME,
    {
      enabled: queryOptions?.enabled && metaIsFetched,
      refetchOnMount: false,
      select: (data) => {
        const activeSubscription = (data.active_subscription?.id ? data.active_subscription : undefined) as
          | ISubscription
          | undefined;
        const prevSubscription = (data.previous_subscription?.id ? data.previous_subscription : undefined) as
          | ISubscription
          | undefined;

        return {
          ...data,
          active_subscription: activeSubscription,
          isElamaUser:
            activeSubscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA ||
            (!activeSubscription && prevSubscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA),
          isTrialUser:
            (activeSubscription && isSubscriptionTrial(activeSubscription)) ||
            (!activeSubscription && (!prevSubscription || (prevSubscription && isSubscriptionTrial(prevSubscription)))),
          meta: meta?.user_meta,
          previous_subscription: prevSubscription,
        };
      },
      ...queryOptions,
      onSuccess: (data) => {
        updateState({
          client_server_datetime_diff: moment(data.user_local_datetime).diff(Date.now(), "seconds"),
        });

        const queryLang = parseQueryString().get("lang") as ELanguage;

        if (queryLang) {
          updateState({ language: queryLang });
        } else {
          updateState({ language: data.language }, "ls");
        }

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess(data);
        }

        if (RAVEN_ENABLE === "true") {
          Sentry.setUser({ email: data.email, id: String(data.id) });
        }

        if (
          data.active_subscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA ||
          (!data.active_subscription && data.previous_subscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA)
        ) {
          document.body.classList.add("carrotquest-hidden");
        } else {
          document.body.classList.remove("carrotquest-hidden");
        }
      },
    },
    {
      method: "GET",
      url: "user/me/",
    },
  );
}

export function useQueryMeInvalidate() {
  const queryClient = useQueryClient();

  return () =>
    Promise.all([
      queryClient.invalidateQueries(QUERY_KEY_USER_ME_META, { refetchInactive: true }),
      queryClient.invalidateQueries(QUERY_KEY_USER_ME, { refetchInactive: true }),
    ]);
}
