import { t } from "@lingui/macro";
import { useDispatch } from "react-redux";
import { popupAdd } from "src/redux";
import { ITelegramNotificationEntity } from "src/types/telegramNotification";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryTelegramNotificationDelete(
  queryOptions?: IUseQueryMutationOptions<unknown, ITelegramNotificationEntity["auth_code"]>,
) {
  const dispatch = useDispatch();

  return useMutationBase<unknown, ITelegramNotificationEntity["auth_code"]>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        dispatch(
          popupAdd({
            text: t`Account successfully unlinked`,
          }),
        );

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "DELETE",
      url: (code) => `telegram_notifications/status/${code}/`,
    },
  );
}
