import { Typography } from "@mui/material";
import { FC } from "react";
import { EExportType } from "src/enums/export";
import { IExport } from "src/types/export";

interface IProps {
  report: IExport;
}

const CellType: FC<IProps> = ({ report }) => (
  <Typography>
    {report._type === EExportType.EXCEL && "Excel"}
    {report._type === EExportType.PDF && "PDF"}
  </Typography>
);

export default CellType;
