import { Trans } from "@lingui/macro";
import { DialogActions, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { FC, useCallback, useMemo } from "react";
import UiDialog from "src/components/UiDialog";

interface IProps {
  open: boolean;
  onClose: (save: boolean) => void;
}

const SaveModalPaymentMethod: FC<IProps> = ({ open, onClose }) => {
  const handleClose = useCallback(
    (save: boolean) => () => {
      onClose(save);
    },
    [onClose],
  );

  return useMemo(
    () => (
      <UiDialog open={open} keepMounted fullWidth maxWidth="xs" onClose={handleClose(false)}>
        <Typography variant="h4" textAlign="center" m={2}>
          <Trans>Save the payment method for further autopayments?</Trans>
        </Typography>

        <DialogActions>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={4}>
              <Button fullWidth onClick={handleClose(false)} color="primary" variant="outlined">
                <Trans>No</Trans>
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button fullWidth onClick={handleClose(true)} color="primary" variant="contained">
                <Trans>Yes</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </UiDialog>
    ),
    [handleClose, open],
  );
};

export default SaveModalPaymentMethod;
