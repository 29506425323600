import { useAppContext } from "src/context/app/appContext";
import { IProject } from "src/types/project";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

interface IInput {
  projectId: IProject["id"];
}

export default function useQueryProjectDelete(queryOptions?: IUseQueryMutationOptions<string, IInput>) {
  const invalidateProjects = useQueryProjectsInvalidate();
  const invalidateMe = useQueryMeInvalidate();
  const { state, updateState } = useAppContext();

  return useMutationBase<string, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await Promise.all([invalidateProjects(), invalidateMe()]);

        if (state.dashboard.currentProjects) {
          updateState({
            dashboard: {
              currentProjects: state.dashboard.currentProjects.filter((id) => id !== args[1].projectId),
            },
          });
        }

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "DELETE",
      url: ({ projectId }) => `project/${projectId}/`,
    },
  );
}
