import produce from "immer";
import { EStreamReportStatus } from "src/enums/stream";
import { EPipelineStatus, IModulesActionMap, IModulesState, IPipeline } from "src/redux/modules/index";
import { actionCompile, EDITING, IActionT, IActionTPromiseCreator, LOADING } from "src/utils";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesItemActionMap {
  PIPELINES_ITEM_ARCHIVE__F: {};
  PIPELINES_ITEM_ARCHIVE__R: {
    pipelineId?: IPipeline["id"];
  };
  PIPELINES_ITEM_ARCHIVE__S: {
    pipelineId?: IPipeline["id"];
  };

  PIPELINES_ITEM_POST__F: {};
  PIPELINES_ITEM_POST__R: {
    pipeline: any;
    urlAfterCreate?: string;
  };
  PIPELINES_ITEM_POST__S: {
    pipeline: IPipeline;
    urlAfterCreate?: string;
  };

  PIPELINES_ITEM_PUT__F: {};
  PIPELINES_ITEM_PUT__R: {
    name?: IPipeline["name"];
    pipelineId: IPipeline["id"];
    run_delta?: number;
    project?: number;
    config?: any;
  };
  PIPELINES_ITEM_PUT__S: {
    pipeline: IPipeline;
  };

  PIPELINES_ITEM_GET__F: {};
  PIPELINES_ITEM_GET__R: {
    pipelineId: IPipeline["id"];
  };
  PIPELINES_ITEM_GET__S: {
    pipeline: IPipeline;
  };

  PIPELINES_ITEM_DELETE__F: {};
  PIPELINES_ITEM_DELETE__R: {
    pipelineId: IPipeline["id"];
  };
  PIPELINES_ITEM_DELETE__S: {
    pipelineId: IPipeline["id"];
  };

  PIPELINES_ITEM_RESTART__F: {};
  PIPELINES_ITEM_RESTART__R: {
    pipelineId: IPipeline["id"];
  };
  PIPELINES_ITEM_RESTART__S: {
    pipelineId: IPipeline["id"];
  };

  PIPELINES_ITEM_UNARCHIVE__F: {};
  PIPELINES_ITEM_UNARCHIVE__R: {
    pipelineIds: IPipeline["id"][];
  };
  PIPELINES_ITEM_UNARCHIVE__S: {
    pipelineIds: IPipeline["id"][];
  };

  PIPELINES_CURRENT_CLEAR: {};
}

export const pipelinesItemPost = createActionCreator("PIPELINES_ITEM_POST__R");
export type IPipelinesItemPostF = IActionT<IModulesActionMap, "PIPELINES_ITEM_POST__F">;
export type IPipelinesItemPostR = IActionT<IModulesActionMap, "PIPELINES_ITEM_POST__R">;
export type IPipelinesItemPostS = IActionT<IModulesActionMap, "PIPELINES_ITEM_POST__S">;
export const pipelinesItemPostPromise = createPromiseCreator<"PIPELINES_ITEM_POST__R", "PIPELINES_ITEM_POST__S">(
  pipelinesItemPost,
);
export type IPipelinesItemPostPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_ITEM_POST__R",
  "PIPELINES_ITEM_POST__S"
>;

export const pipelinesItemPut = createActionCreator("PIPELINES_ITEM_PUT__R");
export type IPipelinesItemPutF = IActionT<IModulesActionMap, "PIPELINES_ITEM_PUT__F">;
export type IPipelinesItemPutR = IActionT<IModulesActionMap, "PIPELINES_ITEM_PUT__R">;
export type IPipelinesItemPutS = IActionT<IModulesActionMap, "PIPELINES_ITEM_PUT__S">;
export const pipelinesItemPutPromise = createPromiseCreator<"PIPELINES_ITEM_PUT__R", "PIPELINES_ITEM_PUT__S">(pipelinesItemPut);

export const pipelinesItemGet = createActionCreator("PIPELINES_ITEM_GET__R");
export type IPipelinesItemGetF = IActionT<IModulesActionMap, "PIPELINES_ITEM_GET__F">;
export type IPipelinesItemGetR = IActionT<IModulesActionMap, "PIPELINES_ITEM_GET__R">;
export type IPipelinesItemGetS = IActionT<IModulesActionMap, "PIPELINES_ITEM_GET__S">;
export const pipelinesItemGetPromise = createPromiseCreator<"PIPELINES_ITEM_GET__R", "PIPELINES_ITEM_GET__S">(pipelinesItemGet);

export const pipelinesItemDelete = createActionCreator("PIPELINES_ITEM_DELETE__R");
export type IPipelinesItemDeleteF = IActionT<IModulesActionMap, "PIPELINES_ITEM_DELETE__F">;
export type IPipelinesItemDeleteR = IActionT<IModulesActionMap, "PIPELINES_ITEM_DELETE__R">;
export type IPipelinesItemDeleteS = IActionT<IModulesActionMap, "PIPELINES_ITEM_DELETE__S">;
export const pipelinesItemDeletePromise = createPromiseCreator<"PIPELINES_ITEM_DELETE__R", "PIPELINES_ITEM_DELETE__S">(
  pipelinesItemDelete,
);
export type IPipelinesItemDeletePromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_ITEM_DELETE__R",
  "PIPELINES_ITEM_DELETE__S"
>;

export const pipelinesItemRestart = createActionCreator("PIPELINES_ITEM_RESTART__R");
export type IPipelinesItemRestartF = IActionT<IModulesActionMap, "PIPELINES_ITEM_RESTART__F">;
export type IPipelinesItemRestartR = IActionT<IModulesActionMap, "PIPELINES_ITEM_RESTART__R">;
export type IPipelinesItemRestartS = IActionT<IModulesActionMap, "PIPELINES_ITEM_RESTART__S">;
export const pipelinesItemRestartPromise = createPromiseCreator<"PIPELINES_ITEM_RESTART__R", "PIPELINES_ITEM_RESTART__S">(
  pipelinesItemRestart,
);

export const pipelinesItemArchive = createActionCreator("PIPELINES_ITEM_ARCHIVE__R");
export type IPipelinesItemArchiveF = IActionT<IModulesActionMap, "PIPELINES_ITEM_ARCHIVE__F">;
export type IPipelinesItemArchiveR = IActionT<IModulesActionMap, "PIPELINES_ITEM_ARCHIVE__R">;
export type IPipelinesItemArchiveS = IActionT<IModulesActionMap, "PIPELINES_ITEM_ARCHIVE__S">;
export const pipelinesItemArchivePromise = createPromiseCreator<"PIPELINES_ITEM_ARCHIVE__R", "PIPELINES_ITEM_ARCHIVE__S">(
  pipelinesItemArchive,
);

export const pipelinesItemUnarchive = createActionCreator("PIPELINES_ITEM_UNARCHIVE__R");
export type IPipelinesItemUnarchiveF = IActionT<IModulesActionMap, "PIPELINES_ITEM_UNARCHIVE__F">;
export type IPipelinesItemUnarchiveR = IActionT<IModulesActionMap, "PIPELINES_ITEM_UNARCHIVE__R">;
export type IPipelinesItemUnarchiveS = IActionT<IModulesActionMap, "PIPELINES_ITEM_UNARCHIVE__S">;
export const pipelinesItemUnarchivePromise = createPromiseCreator<"PIPELINES_ITEM_UNARCHIVE__R", "PIPELINES_ITEM_UNARCHIVE__S">(
  pipelinesItemUnarchive,
);

export const clearCurrentPipeline = createActionCreator("PIPELINES_CURRENT_CLEAR");

export const pipelinesItemReducers = {
  PIPELINES_CURRENT_CLEAR: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState.model = undefined;
    }),
  PIPELINES_ITEM_ARCHIVE__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
    }),
  PIPELINES_ITEM_ARCHIVE__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = true;
    }),

  PIPELINES_ITEM_ARCHIVE__S: (modulesState: IModulesState, action: IPipelinesItemArchiveS): IModulesState => {
    const { pipelineId } = action.payload;
    if (!pipelineId) {
      return modulesState;
    } else {
      return produce(modulesState, (draft) => {
        draft.pipelinesCurrentState[LOADING] = false;
        draft.pipelinesItemsState.models = draft.pipelinesItemsState.models?.map((pipeline) => ({
          ...pipeline,
          status: pipelineId === pipeline.id ? EPipelineStatus.ARCHIVED : pipeline.status,
        }));
      });
    }
  },
  PIPELINES_ITEM_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
    }),

  PIPELINES_ITEM_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = true;
    }),

  PIPELINES_ITEM_GET__S: (modulesState: IModulesState, action: IPipelinesItemGetS): IModulesState => {
    const { pipeline } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
      draft.pipelinesCurrentState.model = pipeline;
    });
  },
  PIPELINES_ITEM_PUT__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[EDITING] = false;
    }),
  PIPELINES_ITEM_PUT__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[EDITING] = true;
    }),

  PIPELINES_ITEM_PUT__S: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[EDITING] = false;
    }),
  PIPELINES_ITEM_RESTART__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
    }),
  PIPELINES_ITEM_RESTART__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = true;
    }),

  PIPELINES_ITEM_RESTART__S: (modulesState: IModulesState, action: IPipelinesItemRestartS): IModulesState => {
    const { pipelineId } = action.payload;
    if (!pipelineId) {
      return modulesState;
    } else {
      return produce(modulesState, (draft) => {
        draft.pipelinesCurrentState[LOADING] = false;
        draft.pipelinesItemsState.models = draft.pipelinesItemsState.models?.map((pipeline) => ({
          ...pipeline,
          report_status:
            pipelineId === pipeline.id
              ? pipeline.report_status.map((report) => ({
                  ...report,
                  status: EStreamReportStatus.REPEAT,
                }))
              : pipeline.report_status,
        }));
      });
    }
  },

  PIPELINES_ITEM_UNARCHIVE__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
    }),

  PIPELINES_ITEM_UNARCHIVE__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = true;
    }),

  PIPELINES_ITEM_UNARCHIVE__S: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesCurrentState[LOADING] = false;
    }),
};
