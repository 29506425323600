import { t } from "@lingui/macro";
import moment from "moment/moment";
import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import ModalSubscriptionAlert, { ModalSubscriptionAlertProps } from "src/components/ModalSubscriptionAlert";
import usePayment from "src/context/billing/hooks/usePayment";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { popupAdd } from "src/redux";
import useQueryBillingFreeRenewal from "src/utils/queries/billing/useQueryBillingFreeRenewal";
import useQueryBillingTariffsGet from "src/utils/queries/billing/useQueryBillingTariffsGet";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import { tariffWeights } from "./billingContext.static";
import { IBillingContext, IModalSubscriptionOrderProps } from "./billingContext.types";
import ModalBillingDisabledAlert from "./components/ModalBillingDisabledAlert";
import ModalConfirmSubscriptionOrder from "./components/ModalConfirmSubscriptionOrder";
import ModalTrialMigrate from "./components/ModalTrialMigrate";

export const BillingContext = createContext<IBillingContext>(null as unknown as IBillingContext);

const isBillingEnabled = process.env.REACT_APP__BILLING_DISABLED !== "true";

export const BillingContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data: user } = useQueryMe();
  const { data: tariffs, isFetching: isTariffsFetching } = useQueryBillingTariffsGet({
    enabled: !!user,
  });
  const { mutate: freeRenew } = useQueryBillingFreeRenewal();

  const { isPaymentPending, payOrder } = usePayment();

  const [modalSubscriptionOrderProps, setModalSubscriptionOrderProps] = useState<null | IModalSubscriptionOrderProps>(null);

  const [modalSubscriptionAlertProps, setModalSubscriptionAlertProps] = useState<null | ModalSubscriptionAlertProps>(null);

  const openModalSubscriptionOrder = useCallback<IBillingContext["actions"]["openModalSubscriptionOrder"]>(
    ({ tariffSign, onClose, onSuccess, ...props }) => {
      const tariff = tariffs?.find(({ sign }) => sign === tariffSign);

      if (!tariff) {
        return dispatch(
          popupAdd({
            importance: "error",
            text: t`Tariff not found`,
          }),
        );
      }

      setModalSubscriptionOrderProps({
        onClose: () => {
          onClose && onClose();
          setModalSubscriptionOrderProps(null);
        },
        onSuccess:
          onSuccess ||
          (() => {
            history.push("/buy-success", {
              next: location.pathname,
            });
          }),
        tariffId: tariff.id,
        ...props,
      });
    },
    [dispatch, history, location.pathname, tariffs],
  );

  const getTariffName = useCallback<IBillingContext["actions"]["getTariffName"]>(
    (tariffSign) => tariffs?.find(({ sign }) => sign === tariffSign)?.name,
    [tariffs],
  );

  const openModalSubscriptionAlert = useCallback<IBillingContext["actions"]["openModalSubscriptionAlert"]>(
    ({ onClose, onSuccess, ...props }) => {
      setModalSubscriptionAlertProps({
        isOpen: true,
        onClose: () => {
          setModalSubscriptionAlertProps(null);
          onClose && onClose();
        },
        onSuccess: () => {
          setModalSubscriptionAlertProps(null);
          onSuccess && onSuccess();
        },
        ...props,
      });
    },
    [],
  );

  const renewSubscription = useCallback<IBillingContext["actions"]["renewSubscription"]>(
    ({ additionalOptionsNum, onSuccess, onClose }) => {
      const subscription = user?.active_subscription || user?.previous_subscription;

      if (
        user?.can_free_renew &&
        (!subscription ||
          moment(subscription.date_finish).diff(moment(), "days") <= (subscription.limits.renewal_max_days || Infinity))
      ) {
        freeRenew(undefined, {
          onError: onClose,
          onSuccess,
        });
      } else {
        openModalSubscriptionOrder({
          additionalOptionsNum,
          onClose,
          onSuccess,
          renewalWithFreeDays: false,
          tariffSign: user?.isTrialUser || !subscription ? ETariffOnPeriodSign.START : subscription.tariff_on_period.sign,
        });
      }
    },
    [
      openModalSubscriptionOrder,
      freeRenew,
      user?.active_subscription,
      user?.can_free_renew,
      user?.isTrialUser,
      user?.previous_subscription,
    ],
  );

  const isSuitableSubscription = useCallback<IBillingContext["actions"]["isSuitableSubscription"]>(
    (minimalSub) => {
      if (!user?.active_subscription) {
        return false;
      }

      const needWeight = tariffWeights[minimalSub];
      const curWeight = tariffWeights[user.active_subscription.tariff_on_period.sign];

      return curWeight >= needWeight;
    },
    [user?.active_subscription],
  );

  const handleCloseModalBillingDisabledAlert = useCallback(() => {
    setModalSubscriptionOrderProps(null);
  }, []);

  return (
    <BillingContext.Provider
      value={{
        actions: {
          getTariffName,
          isSuitableSubscription,
          openModalSubscriptionAlert,
          openModalSubscriptionOrder,
          payOrder,
          renewSubscription,
        },
        state: {
          isPaymentPending,
          isTariffsPending: isTariffsFetching,
        },
      }}
    >
      {children}

      {isBillingEnabled ? (
        modalSubscriptionOrderProps && <ModalConfirmSubscriptionOrder {...modalSubscriptionOrderProps} />
      ) : (
        <ModalBillingDisabledAlert open={!!modalSubscriptionOrderProps} onClose={handleCloseModalBillingDisabledAlert} />
      )}

      {modalSubscriptionAlertProps && <ModalSubscriptionAlert {...modalSubscriptionAlertProps} />}

      <ModalTrialMigrate open={!!user?.is_have_old_trial} />
    </BillingContext.Provider>
  );
};

export const useBillingContext = () => useContext(BillingContext);
