import { IPipeline, IPipelineReportToken } from "src/types/pipeline";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "report-token";

export default function useQueryReportTokenGet(
  pipelineId: IPipeline["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IPipelineReportToken>,
) {
  return useQueryBase<IPipelineReportToken>(
    [QUERY_KEY, pipelineId],
    {
      enabled: typeof pipelineId !== "undefined",
      ...queryOptions,
    },
    {
      method: "GET",
      url: `share/pipeline/pipeline/${pipelineId}/`,
    },
  );
}
