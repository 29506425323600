import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
  iframe: {
    border: "none",
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: -1,
  },

  loader: {
    alignItems: "center",
    background: alpha("#ffffff", 0.5),
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },

  wrapper: {
    flexGrow: 1,
    position: "relative",
  },
}));
