import { EProjectRole } from "src/enums/project";
import { IProject } from "src/types/project";
import { useQueryProjectInvitesInvalidate } from "src/utils/queries/project/useQueryProjectInvitesGet";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";
import useQueryMe from "src/utils/queries/user/useQueryMe";

interface IInput {
  invited_email: string;
  target_project: IProject["id"];
  target_role: EProjectRole;
}

export default function useQueryProjectInvitePost(queryOptions?: IUseQueryMutationOptions<IProject, IInput>) {
  const invalidateInvites = useQueryProjectInvitesInvalidate();
  const { data: me } = useQueryMe();

  return useMutationBase<IProject, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateInvites(args[1].target_project);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      params: (params) => ({
        creator: me?.id,
        ...params,
      }),
      url: "invitation/",
    },
  );
}
