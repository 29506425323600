import produce from "immer";
import { EPipelineSubType } from "src/enums/pipeline";
import { IModulesActionMap, IModulesState, IPipelinesMergeGetSagaResponse } from "src/redux/modules/index";
import { actionCompile, COUNT, IActionT, IActionTPromiseCreator, LOADING } from "src/utils";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesMergeActionMap {
  PIPELINES_MERGE_GET__R: {
    type: EPipelineSubType.ADS | EPipelineSubType.MULTIPIPELINE;
    limit?: number;
    offset?: number;
    ordering?: string;
    filtered?: any;
  };
  PIPELINES_MERGE_GET__F: {
    type: EPipelineSubType.ADS | EPipelineSubType.MULTIPIPELINE;
  };
  PIPELINES_MERGE_GET__S: IPipelinesMergeGetSagaResponse;
}

export const pipelinesMergeGet = createActionCreator("PIPELINES_MERGE_GET__R");
export type IPipelinesMergeGetF = IActionT<IModulesActionMap, "PIPELINES_MERGE_GET__F">;
export type IPipelinesMergeGetR = IActionT<IModulesActionMap, "PIPELINES_MERGE_GET__R">;
export type IPipelinesMergeGetS = IActionT<IModulesActionMap, "PIPELINES_MERGE_GET__S">;
export const pipelinesMergeGetPromise = createPromiseCreator<"PIPELINES_MERGE_GET__R", "PIPELINES_MERGE_GET__S">(
  pipelinesMergeGet,
);
export type IPipelinesMergeGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_MERGE_GET__R",
  "PIPELINES_MERGE_GET__S"
>;

export const pipelinesMergeReducers = {
  PIPELINES_MERGE_GET__F: (modulesState: IModulesState, action: IPipelinesMergeGetF): IModulesState => {
    const { type } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesMergeState[type][LOADING] = true;
    });
  },

  PIPELINES_MERGE_GET__R: (modulesState: IModulesState, action: IPipelinesMergeGetR): IModulesState => {
    const { type } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesMergeState[type][LOADING] = true;
    });
  },

  PIPELINES_MERGE_GET__S: (modulesState: IModulesState, action: IPipelinesMergeGetS): IModulesState => {
    const { meta, results, type } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesMergeState[type][LOADING] = false;
      draft.pipelinesMergeState[type][COUNT] = meta.count;
      draft.pipelinesMergeState[type].models = results;
    });
  },
};
