import { ELanguage } from "src/enums/language";

const ALTER_RUSSIAN_LANGUAGES = ["uk", "az", "hy", "be", "ka", "kk", "ky", "tg", "tk", "uz"];

const SUPPORTED_LANGUAGES = [ELanguage.EN, ELanguage.RU];
const DEFAULT_LANGUAGE = ELanguage.EN;

export const getCurrentBrowserLang = () => {
  const lang = navigator.language;
  const splittedLangMark = lang.split("-");
  return splittedLangMark[0] as ELanguage;
};

export const getSupportedBrowserLang = () => {
  const currentLanguage = getCurrentBrowserLang();

  if (ALTER_RUSSIAN_LANGUAGES.includes(currentLanguage)) {
    return ELanguage.RU;
  }

  return SUPPORTED_LANGUAGES.includes(currentLanguage) ? currentLanguage : DEFAULT_LANGUAGE;
};
