import { Trans } from "@lingui/macro";
import { Box, Grid, Link, Typography } from "@mui/material";
import { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import ConnectorInstruction from "src/components/ConnectorInstruction";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import { useAppContext } from "src/context/app/appContext";
import { ELanguage } from "src/enums/language";
import { EPipelineType } from "src/enums/pipeline";
import { ETmplType } from "src/enums/tmpl";
import ConnectorGdsIcon from "src/icons/ConnectorGdsIcon";
import useQueryTmpls from "src/utils/queries/tmpls/useQueryTmpls";

import ImgStep1En from "./assets/step1_en.jpg";
import ImgStep1Ru from "./assets/step1_ru.jpg";
import ImgStep2En from "./assets/step2_en.jpg";
import ImgStep2Ru from "./assets/step2_ru.jpg";
import ImgStep3En from "./assets/step3_en.jpg";
import ImgStep3Ru from "./assets/step3_ru.jpg";
import ImgStep4En from "./assets/step4_en.jpg";
import ImgStep4Ru from "./assets/step4_ru.jpg";
import Card from "./components/Card";
import Filter, { IFilterForm } from "./components/Filter";
import * as classes from "./index.css";

const PER_PAGE = 12;

const ConnectorGDSHome: FC = () => {
  const location = useLocation<{ sources?: EPipelineType[] } | undefined>();
  const {
    state: { language },
  } = useAppContext();
  const { data: tmpls, isFetching } = useQueryTmpls();
  const [page, setPage] = useState(1);
  const [filterForm, setFilterForm] = useState<IFilterForm>({
    media: null,
    source: location.state?.sources || null,
  });

  const handleChangeFilter = useCallback<ComponentProps<typeof Filter>["onChange"]>((key, value) => {
    setFilterForm((state) => ({
      ...state,
      [key]: value,
    }));
    setPage(1);
  }, []);

  const instructionLink = useMemo(
    () =>
      language === ELanguage.EN
        ? "https://datafan.carrotquest.help/article/4877"
        : "https://datafan.carrotquest.help/article/4856",
    [language],
  );

  const filteredTmpls = useMemo(
    () =>
      tmpls?.filter((tmpl) => {
        if (tmpl.tmpl_type !== ETmplType.GOOGLE_DATA_TMPL) {
          return false;
        }

        if (filterForm.source?.length && tmpl.pipeline_source) {
          return filterForm.source.includes(tmpl.pipeline_source);
        }

        if (filterForm.media?.length) {
          return tmpl.source.some((source) => filterForm.media?.includes(source));
        }

        return true;
      }),
    [filterForm.media, filterForm.source, tmpls],
  );

  const handleNextPage = useCallback(() => {
    setPage((state) => state + 1);
  }, []);

  const handleScrollTop = useCallback(() => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
  }, []);

  return (
    <Section isLoading={isFetching}>
      <SectionHead
        title={<Trans>Connector DataFan — Google Data Studio</Trans>}
        subTitle={<Trans>One of the best tools for social media performance dashboard creation</Trans>}
        icon={<ConnectorGdsIcon />}
      >
        <Box maxWidth={900} m="auto">
          <Filter values={filterForm} onChange={handleChangeFilter} />
        </Box>
      </SectionHead>

      <div css={classes.list}>
        <Grid container spacing={3}>
          {filteredTmpls?.slice(0, page * PER_PAGE).map((tmpl) => (
            <Grid item key={tmpl.id} css={classes.list__item}>
              <Card tmpl={tmpl} />
            </Grid>
          ))}
        </Grid>
      </div>

      {filteredTmpls && filteredTmpls.length > page * PER_PAGE && (
        <Typography variant="body2" mt={5} textAlign="center">
          <Link component="button" onClick={handleNextPage}>
            <Trans>Load more</Trans>
          </Link>
        </Typography>
      )}

      <div css={classes.instruction}>
        <Typography variant="h2" mb={6}>
          <Trans>How to create a Google Data Studio report via DataFan</Trans>
        </Typography>

        <ConnectorInstruction
          steps={[
            {
              picture: language === ELanguage.RU ? ImgStep1Ru : ImgStep1En,
              text: (
                <Trans>
                  Pick a suitable template from a variaty oth templates in the gallery by applying social media or public/ad
                  account filters
                </Trans>
              ),
              title: <Trans>Choose a template</Trans>,
            },
            {
              picture: language === ELanguage.RU ? ImgStep2Ru : ImgStep2En,
              text: (
                <Trans>
                  Connect your public/ad account to DataFan to collect its data to be further inserted into the template. Follow
                  the steps at template generation form
                </Trans>
              ),
              title: <Trans>Data collection</Trans>,
            },
            {
              picture: language === ELanguage.RU ? ImgStep3Ru : ImgStep3En,
              text: <Trans>Click "Open report in Google Data Studio" button</Trans>,
              title: <Trans>Jump to Google Data Studio</Trans>,
            },
            {
              picture: language === ELanguage.RU ? ImgStep4Ru : ImgStep4En,
              text: (
                <Trans>
                  In the Google Data Studio interface, grant permission to the DataFan connector, click "Link", and then the
                  "Generate Report" button in the upper right corner.
                  <br />
                  <Link target="_blank" href={instructionLink} component="a">
                    Click
                  </Link>{" "}
                  for details
                </Trans>
              ),
              title: <Trans>Generating report at Google Data Studio</Trans>,
            },
          ]}
        />

        <Box textAlign="center" mt={6}>
          <UiButton onClick={handleScrollTop}>
            <Trans>Create report</Trans>
          </UiButton>
        </Box>
      </div>
    </Section>
  );
};

export default ConnectorGDSHome;
