import { Trans } from "@lingui/macro";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import PaymentMethod from "src/components/ModalPaymentMethods/components/PaymentMethod";
import UiDialog from "src/components/UiDialog";
import { billingPaymentMethodsGet, IRootState } from "src/redux";
import { IPaymentMethod } from "src/types/paymentMethod";
import { LOADING } from "src/utils";

interface IProps {
  open: boolean;
  onSelect: (id: undefined | IPaymentMethod["id"]) => void;
}

const ModalPaymentMethod: FC<IProps> = ({ open, onSelect }) => {
  const dispatch = useDispatch();
  const { loading, methods } = useSelector((state: IRootState) => ({
    loading: state.modules.billing.paymentMethods[LOADING],
    methods: state.modules.billing.paymentMethods.items,
  }));
  const [selectedMethodId, setSelectedMethodId] = useState<undefined | IPaymentMethod["id"]>();

  const handleClose = useCallback(() => {
    onSelect(undefined);
  }, [onSelect]);

  const handlePay = useCallback(() => {
    onSelect(selectedMethodId);
  }, [onSelect, selectedMethodId]);

  const handleChange = useCallback((id: IPaymentMethod["id"]) => {
    setSelectedMethodId(id);
  }, []);

  useUpdateEffect(() => {
    if (methods?.length) {
      setSelectedMethodId(methods[0].id);
    }
  }, [methods]);

  useUpdateEffect(() => {
    if (open) {
      dispatch(billingPaymentMethodsGet({}));
    }
  }, [open]);

  return useMemo(
    () => (
      <UiDialog
        open={open}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        title={<Trans>Select payment method</Trans>}
      >
        <DialogContent>
          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2} justifyContent="center">
              {methods?.map((method) => (
                <Grid item key={method.id}>
                  <PaymentMethod selected={selectedMethodId === method.id} method={method} onChange={handleChange} />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={5}>
              <Button fullWidth onClick={handleClose} color="primary" variant="outlined">
                <Trans>New method</Trans>
              </Button>
            </Grid>

            <Grid item xs={12} md={5}>
              <Button fullWidth onClick={handlePay} color="primary" variant="contained">
                <Trans>Pay</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </UiDialog>
    ),
    [handleChange, handleClose, handlePay, loading, methods, open, selectedMethodId],
  );
};

export default ModalPaymentMethod;
