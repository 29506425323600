import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M18,22H6c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h12c1.7,0,3,1.3,3,3v12C21,20.7,19.7,22,18,22z M6,6C5.4,6,5,6.4,5,7v12 c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1H6z" />
      <path d="M16,8c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1s1,0.4,1,1v4C17,7.6,16.6,8,16,8z" />
      <path d="M8,8C7.4,8,7,7.6,7,7V3c0-0.6,0.4-1,1-1s1,0.4,1,1v4C9,7.6,8.6,8,8,8z" />
      <path d="M20,12H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h16c0.6,0,1,0.4,1,1S20.6,12,20,12z" />
    </SvgIcon>
  );
}
