import { IInvoice } from "src/types/billing";
import { IResponseBasePagination } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "billing-invoice-get";

interface IParams {
  secret?: string;
  status?: number;
  limit?: number;
}

export default function useQueryBillingInvoiceGet(
  { secret, status, limit }: IParams,
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<IInvoice>, IInvoice>,
) {
  return useQueryBase<IResponseBasePagination<IInvoice>, IInvoice>(
    QUERY_KEY,
    {
      enabled: Boolean(secret || status),
      select: (data) => data.results[0],
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        limit,
        secret,
        status,
      },
      url: "billing/invoices/",
    },
  );
}
