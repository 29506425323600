import { call, put, take, takeEvery } from "redux-saga/effects";

import { IActionError, IActionT, IActionTPayload, IActionType } from "./action";

const ACTION_EMPTY = {
  meta: { id: undefined },
  payload: {},
  type: "",
};

function getPutTake<IActionMap>() {
  return function* putTake<
    IActionTypeR extends IActionType<IActionMap>,
    IActionTypeS extends IActionType<IActionMap>,
    IActionTypeF extends IActionType<IActionMap>,
  >(
    actionR: IActionT<IActionMap, IActionTypeR>,
    [actionTypeS, actionTypeF]: [IActionTypeS, IActionTypeF],
  ): IterableIterator<any> {
    const id = Date.now();

    // put
    actionR.meta.id = id;
    yield put(actionR);

    // take
    let actionSF: any = ACTION_EMPTY;
    while (actionSF.meta.id !== id) {
      actionSF = yield take([actionTypeS, actionTypeF]);
    }

    return actionSF;
  };
}

// TODO: remove on eslint appears
// prettier-ignore
function getTakeEveryRequest<IActionMap>() {
  return function* takeEveryRequest<
    IActionTypeR extends IActionType<IActionMap>,
    IActionTypeS extends IActionType<IActionMap>,
    IActionTypeF extends IActionType<IActionMap>
  >(
    [actionTypeR, actionTypeS, actionTypeF]: [
      IActionTypeR,
      IActionTypeS,
      IActionTypeF
    ],
    {
      onR,
      onS,
      onF,
    }: {
      onR: (
        actionR: IActionT<IActionMap, IActionTypeR>,
      ) => IterableIterator<any>;
      onS?: (
        actionS: IActionT<IActionMap, IActionTypeS>,
      ) => IterableIterator<any>;
      onF?: (
        actionF: IActionT<IActionMap, IActionTypeF>,
      ) => IterableIterator<any>;
    },
  ): IterableIterator<any> {
    yield takeEvery(actionTypeR, function*(
      actionR: IActionT<IActionMap, IActionTypeR>,
    ) {
      const {
        payload: payloadR,
        meta: metaR,
        meta: { resolve, reject },
      } = actionR;

      try {
        // data
        const payloadS: IActionTPayload<IActionMap, IActionTypeS> = yield call(
          onR,
          actionR,
        );
        const actionS: IActionT<IActionMap, IActionTypeS> = {
          meta: metaR,
          payload: payloadS,
          type: actionTypeS,
        };

        // action
        yield put(actionS);

        // handlers
        if (onS) {
          yield call(onS, actionS);
        }
        if (resolve) {
          resolve(payloadS);
        }
      } catch (error /*: IActionError*/) {
        // data
        const actionF: IActionT<IActionMap, IActionTypeF> = {
          error: error as IActionError,
          meta: metaR,
          payload: (payloadR as any) as IActionTPayload<
            IActionMap,
            IActionTypeF
          >,
          type: actionTypeF,
        };

        // action
        yield put(actionF);

        // handlers
        if (onF) {
          yield call(onF, actionF);
        }
        if (reject) {
          reject(error as IActionError);
        }
      }
    });
  };
}
// prettier-ignore-end

export function sagaCompile<IActionMap>() {
  return {
    putTake: getPutTake<IActionMap>(),
    takeEveryRequest: getTakeEveryRequest<IActionMap>(),
  };
}
