import { t, Trans } from "@lingui/macro";
import { DialogContent, Typography } from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import * as classes from "src/components/ModalPipelineEdit/index.css";
import UiDialog from "src/components/UiDialog";
import { EPipelineType } from "src/enums/pipeline";
import { EProjectRole } from "src/enums/project";
import { popupAdd } from "src/redux";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import useQueryPipelineGet from "src/utils/queries/pipeline/useQueryPipelineGet";
import useQueryPipelinePatch from "src/utils/queries/pipeline/useQueryPipelinePatch";
import useQueryProjectMembersGet from "src/utils/queries/project/useQueryProjectMembersGet";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import ModalPipelineEditCommon from "./ModalPipelineEditCommon";
import ModalPipelineEditMerge from "./ModalPipelineEditMerge";

interface IProps {
  pipelineId: IPipeline["id"] | null;
  onClose: () => void;
  onSuccess?: () => void;
  pipelineModel?: IPipelineExtended;
}

const ModalPipelineEdit: FC<IProps> = ({ pipelineId, onClose, onSuccess, pipelineModel }) => {
  const dispatch = useDispatch();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const location = useLocation();
  const history = useHistory();
  const { data: user, isFetching: userIsLoading, isFetched: userIsFetched } = useQueryMe();
  const {
    data: pipelineData,
    isFetching: pipelineIsLoading,
    isFetched: pipelineIsFetched,
  } = useQueryPipelineGet(pipelineId || undefined);
  const pipeline = useMemo(() => pipelineData || pipelineModel, [pipelineData, pipelineModel]);
  const { data: membersData } = useQueryProjectMembersGet(pipeline?.project);

  const { mutate: handleEdit, isLoading: isPendingEdit } = useQueryPipelinePatch(pipelineId || pipelineModel?.id || 0, {
    onSuccess: (data, variables) => {
      let text = t`Settings successfully updated`;

      if (variables.is_new_ya_settings) {
        text += ". " + t`Yandex Metrika are available within 2 hours`;
      }

      dispatch(popupAdd({ text: text }));

      onSuccess && onSuccess();
    },
  });

  const handleClose = useCallback(() => {
    history.replace({ pathname: location.pathname, state: null });
    onClose();
  }, [history, location.pathname, onClose]);

  const owner = useMemo(() => membersData?.find((member) => member.role === EProjectRole.OWNER), [membersData]);

  useUpdateEffect(() => {
    if (pipelineIsFetched && userIsFetched && (!user || !pipeline)) {
      handleClose();
    }
  }, [userIsFetched, pipelineIsFetched]);

  return (
    <UiDialog
      ref={setRef}
      open={Boolean(pipelineId || pipelineModel)}
      fullWidth
      onClose={handleClose}
      css={classes.dialog}
      title={<Trans>Settings and status</Trans>}
    >
      <DialogContent>
        {userIsLoading || pipelineIsLoading ? (
          <Loader />
        ) : !pipeline || !user ? null : (
          <>
            <Typography component="div" css={classes.pipelineId}>
              <Typography component="div" css={classes.pipelineId__inn}>
                DataFan ID {pipeline.id}
              </Typography>
            </Typography>

            {[EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source) ? (
              <ModalPipelineEditMerge
                pipeline={pipeline}
                user={user}
                owner={owner?.user}
                onSubmit={handleEdit}
                isLoading={isPendingEdit}
              />
            ) : (
              <ModalPipelineEditCommon
                pipeline={pipeline}
                user={user}
                owner={owner?.user}
                onSubmit={handleEdit}
                isLoading={isPendingEdit}
                dialogRef={ref}
              />
            )}
          </>
        )}
      </DialogContent>
    </UiDialog>
  );
};

export default ModalPipelineEdit;
