export enum EExportStatus {
  STARTED = 1,
  SUCCESS = 2,
  FAILURE = 3,
  REPEAT = 4,
}

export enum EExportType {
  EXCEL = 1,
  PDF = 3,
}
