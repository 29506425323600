import { InfiniteData } from "react-query";
import { IPipelineExtended } from "src/types/pipeline";
import { IUseQueryInfiniteOutputWrapper } from "src/utils/queries/useQueryBase";

export default function getPipelineIdsForUpdate(data: InfiniteData<IUseQueryInfiniteOutputWrapper<IPipelineExtended>>) {
  const needUpdateIds: IPipelineExtended["id"][] = [];

  data.pages.forEach((page) => {
    page.results.forEach((pipeline) => {
      if (
        ["in_progress", "in_progress_first_run", "ready_partly"].includes(pipeline.data_status) ||
        pipeline.public_message.is_first_collect_yandex ||
        pipeline.public_message.is_first_collect_vk_stories
      ) {
        needUpdateIds.push(pipeline.id);
      }
    });
  });

  return needUpdateIds;
}
