import { t } from "@lingui/macro";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { EPipelineSubType } from "src/enums/pipeline";
import DatasetAdsIcon from "src/icons/DatasetAdsIcon";
import DatasetMergeIcon from "src/icons/DatasetMergeIcon";
import DatasetSingleIcon from "src/icons/DatasetSingleIcon";
import { IPipelineType } from "src/types/pipeline";

export default function groupPipelineTypes(pipelineTypes: IPipelineType[]) {
  return pipelineTypes.reduce(
    (
      acc: {
        [key in EPipelineSubType]: {
          icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
          title: string;
          shortTitle: string;
          url: string;
          items: IPipelineType[];
        };
      },
      pipelineType,
    ) => {
      acc[pipelineType.subType].items.push(pipelineType);

      return acc;
    },
    {
      [EPipelineSubType.MULTIPIPELINE]: {
        icon: PeopleOutlineIcon,
        items: [],
        shortTitle: t`Publics`,
        title: t`Social networking service`,
        url: "/pipelines/public",
      },
      [EPipelineSubType.ADS]: {
        icon: DatasetAdsIcon,
        items: [],
        shortTitle: t`Ad accounts`,
        title: t`Ad accounts`,
        url: "/pipelines/ads",
      },
      [EPipelineSubType.SINGLEPIPELINE]: {
        icon: DatasetSingleIcon,
        items: [],
        shortTitle: t`Single datasets`,
        title: t`Single datasets`,
        url: "/pipelines/single",
      },
      [EPipelineSubType.MERGE]: {
        icon: DatasetMergeIcon,
        items: [],
        shortTitle: t`DataFan Merge`,
        title: t`DataFan Merge`,
        url: "/pipelines/merge",
      },
    },
  );
}
