import { Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigGgl } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigGgl | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigGgl: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedProfile, setSelectedProfile] = useState<IPipelineConfigGgl["profile"] | null>(null);

  const {
    data: profiles,
    isFetched: profilesFetched,
    isFetching: profilesIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.GGL_ANALYTICS_PROFILES,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedProfile) {
          setSelectedProfile(data[0] || null);
        }
      },
    },
  );

  const profileOptions = useMemo(
    () =>
      profiles?.map((profile) => ({
        data: profile,
        label: `${profile.name} (${profile.id})`,
        value: profile.id,
      })) || [],
    [profiles],
  );

  const handleSelectProfile = useCallback(
    (value: typeof profileOptions[number]["value"] | null, data: typeof profileOptions[number]["data"] | null) => {
      if (data) {
        setSelectedProfile(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedProfile));

    onChange({
      profile: selectedProfile || undefined,
      profile_id: selectedProfile?.id,
    });
  }, [onChangeValidation, onChange, selectedProfile]);

  return (
    <>
      {profileOptions.length === 0 && profilesFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={profileOptions.length > 7}
          isLoading={profilesIsFetching}
          value={selectedProfile?.id}
          onChange={handleSelectProfile}
          options={profileOptions}
          placeholder={<Trans>Account</Trans>}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigGgl;
