import { t, Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import UiButton from "src/components/UiButton";
import { popupAdd } from "src/redux";
import useQueryBillingPaymentMethodDelete from "src/utils/queries/billing/useQueryBillingPaymentMethodDelete";
import useQueryBillingPaymentMethodsGet, {
  useQueryBillingPaymentMethodsInvalidate,
} from "src/utils/queries/billing/useQueryBillingPaymentMethodsGet";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

const AutoPayment: FC = () => {
  const dispatch = useDispatch();
  const paymentMethodsInvalidate = useQueryBillingPaymentMethodsInvalidate();
  const meInvalidate = useQueryMeInvalidate();
  const { data: methods, isLoading: methodsIsLoading } = useQueryBillingPaymentMethodsGet();
  const { mutate: methodDelete, isLoading: isDeleting } = useQueryBillingPaymentMethodDelete({
    onSuccess: () => {
      paymentMethodsInvalidate();
      meInvalidate();

      dispatch(
        popupAdd({
          text: t`Autopayment disabled`,
        }),
      );
    },
  });

  const handleDisable = useCallback(() => {
    if (methods?.[0].id) {
      methodDelete(methods[0].id);
    }
  }, [methodDelete, methods]);

  return (
    <Box mt={3}>
      {methodsIsLoading ? (
        <CircularProgress size={24} color="secondary" />
      ) : (
        methods?.[0] && (
          <>
            <Typography variant="body2" gutterBottom>
              <Trans>Autopayment</Trans>
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h4">{methods[0].about_payment_method.title}</Typography>
              </Grid>

              <Grid item>
                <UiButton disableElevation size="small" variant="outlined" loading={isDeleting} onClick={handleDisable}>
                  <Trans>Disable</Trans>
                </UiButton>
              </Grid>
            </Grid>
          </>
        )
      )}
    </Box>
  );
};

export default AutoPayment;
