import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const classes = {
  container: (theme: Theme) => css`
    ${theme.breakpoints.down("lg")} {
      padding-left: 0;
    }

    box-sizing: border-box;
    min-height: calc(100vh - 83px);
    padding-left: 111px;
    transition: padding 300ms;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  container_expanded: (theme: Theme) => css`
    ${theme.breakpoints.down("lg")} {
      padding-left: 0;
    }

    padding-left: 305px;
  `,

  container_without_sidebar: css`
    padding-left: 0;
  `,

  content: (theme: Theme) => css`
    ${theme.breakpoints.down("lg")} {
      padding: 25px 40px;
    }

    ${theme.breakpoints.down("sm")} {
      padding: 20px 6px;
    }

    padding: 40px 60px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  topNotice: css`
    position: sticky;
    top: 82px;
    z-index: 11;
  `,
};
