import { Trans } from "@lingui/macro";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { VFC } from "react";
import { Redirect } from "react-router-dom";
import Section, { SectionHead } from "src/components/Section";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import TariffsDesktop from "./TariffsDesktop";
import TariffsMobile from "./TariffsMobile";

const Tariffs: VFC = () => {
  const theme = useTheme();
  const { data: user } = useQueryMe();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  if (user?.isElamaUser) {
    return <Redirect to="/" />;
  }

  return (
    <Section>
      <SectionHead title={<Trans>DataFan tariffs</Trans>}>{isXs ? <TariffsMobile /> : <TariffsDesktop />}</SectionHead>
    </Section>
  );
};

export default Tariffs;
