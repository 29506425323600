import { Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { FC, ReactNode, useCallback, useState } from "react";
import { useMount } from "react-use";
import Snackbars from "src/components/Snackbars";
import { useAppContext } from "src/context/app/appContext";
import { ELanguage } from "src/enums/language";
import { parseQueryString } from "src/utils";

import flagEnIcn from "../../assets/flag-en.svg";
import flagRuIcn from "../../assets/flag-ru.svg";
import * as classes from "./index.css";

interface IProps {
  title: ReactNode;
  icon: ReactNode;
  children: ReactNode;
}

const AuthTemplate: FC<IProps> = ({ title, icon, children }) => {
  const {
    state: { language },
    updateState,
  } = useAppContext();
  const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);

  const handleLangOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setLangAnchorEl(e.currentTarget);
  }, []);

  const handleLangClose = useCallback(() => {
    setLangAnchorEl(null);
  }, []);

  const handleSelectLang = (language: ELanguage) => () => {
    updateState({ language }, "ls");
    handleLangClose();
  };

  useMount(() => {
    const lang = parseQueryString().get("lang") as undefined | ELanguage;

    if (lang) {
      updateState({ language: lang });
    }
  });

  return (
    <Container component="main">
      <Paper css={classes.paper}>
        <div css={classes.langSelector}>
          <IconButton onClick={handleLangOpen} size="large">
            {language === ELanguage.RU ? (
              <img css={classes.langSelectorFlag} src={flagRuIcn} alt="Ru" />
            ) : (
              <img css={classes.langSelectorFlag} src={flagEnIcn} alt="En" />
            )}
          </IconButton>
          <Menu anchorEl={langAnchorEl} keepMounted open={Boolean(langAnchorEl)} onClose={handleLangClose}>
            <MenuItem onClick={handleSelectLang(ELanguage.EN)}>
              <img css={classes.langSelectorFlag} src={flagEnIcn} alt="En" />
            </MenuItem>
            <MenuItem onClick={handleSelectLang(ELanguage.RU)}>
              <img css={classes.langSelectorFlag} src={flagRuIcn} alt="Ru" />
            </MenuItem>
          </Menu>
        </div>

        <Avatar css={classes.avatar}>{icon}</Avatar>

        <Typography variant="h5">{title}</Typography>
        {children}
      </Paper>
      <Snackbars />
    </Container>
  );
};

export default AuthTemplate;
