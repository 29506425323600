import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, ReactNode, useMemo } from "react";
import Sidebar from "src/components/Sidebar";
import { useAppContext } from "src/context/app/appContext";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import Content from "../Content";
import Footer from "../Footer";
import Header from "../Header";
import useStyles from "./index.css";

interface IProps {
  children: ReactNode;
}

const Layout: FC<IProps> = ({ children }) => {
  const classes = useStyles();
  const { data: user } = useQueryMe();
  const { state } = useAppContext();
  const {
    state: { context },
  } = useAppContext();
  const lingui = useLingui();

  return useMemo(
    () => (
      <div key={lingui.i18n._locale}>
        {context !== "printer" && <Header />}

        {!["oauth2", "printer"].includes(context) && user?.id && <Sidebar expanded={state.sidebar.expanded} />}

        <Content expanded={state.sidebar.expanded}>
          <div className={classes.content}>{children}</div>

          {context !== "printer" && <Footer />}
        </Content>

        <div className={classes.html2canvasOverlay}>
          <CircularProgress variant="indeterminate" size={60} thickness={4} color="inherit" />
          <Typography variant="h4" color="white">
            <Trans>Saving...</Trans>
          </Typography>
        </div>
      </div>
    ),
    [children, classes.content, classes.html2canvasOverlay, context, lingui.i18n._locale, state.sidebar.expanded, user?.id],
  );
};

export default Layout;
