import { IPaymentMethod } from "src/types/paymentMethod";
import { apiRequest } from "src/utils";

export function billingTariffsGetApi({ accessToken }: { accessToken?: string }) {
  return apiRequest({
    accessToken,
    method: "GET",
    url: "billing/tariffs/",
  });
}

export function billingPaymentHistoryGetApi({
  accessToken,
  limit,
  offset,
  ordering,
}: {
  accessToken: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}) {
  return apiRequest({
    accessToken,
    method: "GET",
    params: { limit, offset, ordering },
    url: "billing/invoices/",
  });
}

export function billingPaymentMethodsGetApi({ accessToken }: { accessToken: string }) {
  return apiRequest({
    accessToken,
    method: "GET",
    url: "billing/recurring-payments/",
  });
}

export function billingPaymentMethodsDeleteApi({ accessToken, id }: { accessToken: string; id: IPaymentMethod["id"] }) {
  return apiRequest({
    accessToken,
    method: "DELETE",
    url: `billing/recurring-payments/${id}/`,
  });
}

export function billingInternalTransactionsGetApi({
  accessToken,
  limit,
  offset,
  ordering,
}: {
  accessToken: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}) {
  return apiRequest({
    accessToken,
    method: "GET",
    params: { limit, offset, ordering },
    url: "billing/transactions/",
  });
}

export function billingInvoiceGetApi({ accessToken, secret }: { accessToken: string; secret: string }) {
  return apiRequest({
    accessToken,
    method: "GET",
    params: { secret },
    url: "billing/invoices/",
  });
}

export function billingTariffsOptionsGetApi() {
  return apiRequest({
    method: "GET",
    url: "billing/options/",
  });
}

export function billingSubscriptionsGetApi({ accessToken }: { accessToken: string }) {
  return apiRequest({
    accessToken,
    method: "GET",
    url: "billing/subscriptions/",
  });
}

export function billingSubscriptionGetApi({ accessToken, id }: { accessToken: string; id: string }) {
  return apiRequest({
    accessToken,
    method: "GET",
    url: `billing/subscriptions/${id}/`,
  });
}

export function billingInvoicePostApi({
  accessToken,
  id,
  system,
  options,
  promocode,
  renewalWithFreeDays,
  autoRenewalId,
  savePaymentMethod,
}: {
  accessToken: string;
  id?: string;
  system: "yandex" | "cloudpayments";
  options: object[];
  promocode?: string;
  renewalWithFreeDays: boolean;
  autoRenewalId?: IPaymentMethod["id"];
  savePaymentMethod?: boolean;
}) {
  return apiRequest({
    accessToken,
    data: {
      auto_renewal_id: autoRenewalId,
      options,
      promo_code: promocode,
      renewal_with_free_days: renewalWithFreeDays,
      save_payment_method: savePaymentMethod,
      tariff_on_period_id: id,
    },
    method: "POST",
    url: `billing/create-invoice/${system}/`,
  });
}

export function billingCalculateSubscriptionPostApi({
  accessToken,
  tariffId,
  options,
  currency,
  promocode,
  renewalWithFreeDays,
}: {
  accessToken: string;
  tariffId: string;
  options?: object[];
  currency: "RUB" | "EUR";
  promocode?: string;
  renewalWithFreeDays: boolean;
}) {
  return apiRequest({
    accessToken,
    data: {
      currency,
      options,
      promo_code: promocode,
      renewal_with_free_days: renewalWithFreeDays,
      tariff_on_period_id: tariffId,
    },
    method: "POST",
    url: "billing/calculate-subscription/",
  });
}

export function billingActivetePromocodePostApi({
  accessToken,
  promocode,
  currency,
}: {
  accessToken: string;
  promocode: string;
  currency: "RUB" | "EUR";
}) {
  return apiRequest({
    accessToken,
    data: {
      currency,
      promo_code: promocode,
    },
    method: "POST",
    url: "billing/activate-promo-code/",
  });
}

export function billingFreeRenewalSubscriptionPostApi({ accessToken, id }: { accessToken: string; id: string }) {
  return apiRequest({
    accessToken,
    data: {
      subscription_id: id,
    },
    method: "POST",
    url: "billing/free-renewal-subscription/",
  });
}

export function billingPaypalTransactionCompletePostApi({
  accessToken,
  order_id,
  is,
}: {
  accessToken: string;
  order_id: string;
  is: string;
}) {
  return apiRequest({
    accessToken,
    data: {
      is,
      order_id,
    },
    method: "POST",
    url: "billing/paypal-transaction-complete/",
  });
}

export function billingTrialMigratePostApi({ accessToken }: { accessToken: string }) {
  return apiRequest({
    accessToken,
    method: "POST",
    url: "billing/update-trial/",
  });
}
