import { css } from "@emotion/react/macro";

export const wrapper = css`
  display: inline-block;

  .form-control {
    height: 46px;
    border-radius: 8px;
    font-family: "Open Sans", sans-serif;
    color: #000000;
    padding: 14px 16px 16px 54px;
    border: 1px solid #cccccc;

    &:hover {
      border-color: #000000;

      & + .flag-dropdown {
        border-color: #000000;
      }
    }

    &::placeholder {
      color: #505050;
      opacity: 1;
    }
  }

  .flag-dropdown {
    border-width: 0 1px 0 0;
  }

  .flag-dropdown,
  .selected-flag {
    border-radius: 8px 0 0 8px;
  }

  &:active .flag-dropdown {
    display: block;
  }

  &:active .form-control {
    &::placeholder {
      opacity: 0;
    }
  }
`;

export const wrapper_empty = css`
  .flag-dropdown {
    display: none;
  }

  .form-control:focus + .flag-dropdown,
  .flag-dropdown.open {
    display: block;
  }

  .form-control:not(:focus):not(.open) {
    padding-left: 16px;
  }

  .form-control:focus,
  .form-control.open {
    &::placeholder {
      opacity: 0;
    }
  }
`;

export const wrapper_full = css`
  .form-control {
    width: 100%;
  }
`;

export const wrapper_unselectable = css`
  .form-control {
    width: 100%;
  }

  .selected-flag {
    pointer-events: none;
    background: transparent !important;
    padding-left: 10px;

    .arrow {
      display: none;
    }
  }
`;
