import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const container = (theme: Theme) => css`
  padding-top: 1px;
  margin: -40px -60px 60px;
  background: #ffffff;
  border-bottom: 1px solid #e2e2e2;

  ${theme.breakpoints.down("lg")} {
    margin: -25px -40px 25px;
  }

  ${theme.breakpoints.down("sm")} {
    margin: -20px -6px 20px;
  }
`;

export const container__inner = (theme: Theme) => css`
  margin-top: 40px;
  padding: 0 60px 35px;

  ${theme.breakpoints.down("lg")} {
    padding: 0 40px 35px;
  }
`;

export const icon = (theme: Theme) => css`
  width: 48px;
  height: 48px;
  text-align: center;
  margin: 0 auto;

  svg {
    font-size: 48px;
  }

  img {
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 32px;
    height: 32px;

    svg {
      font-size: 32px;
    }
  }
`;

export const title = css`
  margin-top: 50px;
  margin-bottom: 20px;

  .css-${icon.name} ~ & {
    margin-top: 16px;
  }
`;

export const subTitle = css`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const content = css`
  margin-top: 55px;
`;
