import { DetailedHTMLProps, FC, HTMLAttributes, useState } from "react";
import { useMount } from "react-use";
import MediaFavicon from "src/components/MediaFavicon";
import { EPipelineMedia } from "src/enums/pipeline";
import { ISocialMedium } from "src/types/socialAuth";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

export interface PipelinePictureProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string;
  media?: EPipelineMedia | ISocialMedium;
  size?: "small" | "large" | "extraSmall";
  picture?: string;
  hideFavicon?: boolean;
  responsive?: boolean;
}

const PipelinePicture: FC<PipelinePictureProps> = ({ size = "large", media, picture, name, hideFavicon, responsive }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(!picture);
  const [showImg, setShowImg] = useState(false);

  useMount(() => {
    if (picture) {
      const img = document.createElement("img");

      img.addEventListener("load", () => {
        setShowImg(true);
      });

      img.addEventListener("error", () => {
        setShowPlaceholder(true);
      });

      img.src = picture;
    }
  });

  return (
    <div
      css={cssc([
        classes.block,
        size === "large" && classes.block_size_large,
        size === "small" && classes.block_size_small,
        size === "extraSmall" && classes.block_size_extraSmall,
        responsive && classes.block_responsive,
        media === "vk" && classes.block_media_vk,
        media === "facebook" && classes.block_media_facebook,
        media === "google" && classes.block_media_google,
        media === "ok" && classes.block_media_ok,
        media === "instagram" && classes.block_media_instagram,
        media === "mytarget" && classes.block_media_mytarget,
        media === "yandex" && classes.block_media_yandex,
        media === "datafan" && classes.block_media_datafan,
        media === "tiktok" && classes.block_media_tiktok,
        media === "smmplanner" && classes.block_media_smmplanner,
        media === "telegram" && classes.block_media_telegram,
      ])}
    >
      {picture && showImg && <img alt={name} src={picture} css={classes.img} />}

      {showPlaceholder && (
        <div css={classes.placeholder}>
          {String.fromCodePoint(
            name
              .replace(/\[(.*?)\]/gi, "")
              .trim()
              .codePointAt(0) || 0,
          )}
        </div>
      )}

      {!hideFavicon && media && (
        <MediaFavicon
          media={media}
          css={cssc([
            classes.favicon,
            size === "large" && classes.favicon_size_large,
            ["small", "extraSmall"].includes(size) && classes.favicon_size_small,
          ])}
        />
      )}
    </div>
  );
};

export default PipelinePicture;
