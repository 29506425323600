export enum ETariffPeriodMeasure {
  WEEK = 1,
  MONTH = 2,
  DAY = 3,
}

export enum ETariffOnPeriodSign {
  TRIAL_OUTDATED = "trial1month",
  TRIAL_SINGLE = "trial1month_single_all_free",
  TRIAL = "trial1week",
  START = "start1month",
  PROFESSIONAL = "profi1month",
  AGENT = "agent1month",
  ENTERPRISE = "enterprise1month",
  ELAMA = "elama1month",
}
