import { ELanguage } from "src/enums/language";
import { ECurrency, ISubscription } from "src/redux/modules/billing";
import { getSupportedBrowserLang, ITEMS, LOADING, parseQueryString } from "src/utils";

export type IUserId = number;

export interface IUserMeta {
  gmail: string;
}

export interface IUser {
  billing_enabled?: boolean;
  can_auto_pay?: boolean;
  can_free_renew?: boolean;
  date_joined?: string;
  email_confirmed?: boolean;
  email_confirmation_required?: boolean;
  has_password?: boolean;
  humand_name?: string;
  internal_account_total?: string;
  email?: string;
  id?: IUserId;
  initials?: string;
  language: ELanguage;
  geoip_currency?: ECurrency;
  counters: {
    data_collection: number;
    archived: number;
  };
  is_staff?: boolean;
  carrot_quest?: string;
  selected_project?: number;
  active_subscription?: ISubscription;
  meta?: IUserMeta;
  is_have_old_trial?: boolean;
}

export interface IUsersState {
  user: IUser;
  userMeta: {
    [LOADING]: boolean;
  };
  users: {
    [ITEMS]: IUser[];
    [LOADING]: boolean;
  };
}

export const usersState: IUsersState = {
  user: {
    counters: {
      archived: 0,
      data_collection: 0,
    },
    language: (parseQueryString().get("lang") ||
      window.localStorage.getItem("language") ||
      getSupportedBrowserLang()) as ELanguage,
  },
  userMeta: {
    [LOADING]: false,
  },
  users: {
    [ITEMS]: [],
    [LOADING]: false,
  },
};
