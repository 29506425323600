import { t, Trans } from "@lingui/macro";
import { Link, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import UiTextField from "src/components/UiTextField";
import { useAppContext } from "src/context/app/appContext";
import { ECredentialMetaObject } from "src/enums/credential";
import { ELanguage } from "src/enums/language";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigTelegram } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import SelectProject from "../SelectProject";

interface IProps {
  pipelineType: IPipelineType;
  onConfigReady: (config: IPipelineConfigTelegram) => void;
  isLoading: boolean;
  startStepNum: number;
  onClickBack?: () => void;
}

type IStepId = "project" | "collection" | "link";

const TG_REGEXP = new RegExp("https?:\\/\\/(t(elegram)?\\.me|telegram\\.org)\\/([a-zA-Z0-9_]{5,32})\\/?");

const CreateTelegram: FC<IProps> = ({ onConfigReady, isLoading, startStepNum, onClickBack, pipelineType }) => {
  const {
    state: { language },
  } = useAppContext();
  const [link, setLink] = useState<string>("");
  const [channel, setChannel] = useState<string | null>(null);
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const [projectId, setProjectId] = useState<IProject["id"] | null>(
    (projects && projects.length === 1 && projects[0].id) || null,
  );
  const steps = useMemo(
    () =>
      [
        projects && projects.length > 1 ? { id: "project", label: t`Select project` } : undefined,
        { id: "link", label: t`Collecting statistics of channel posts` },
        { id: "collection", label: t`Collecting channel statistics` },
      ].filter((step) => typeof step !== "undefined") as Array<{
        id: IStepId;
        label: string;
      }>,
    [projects],
  );

  const manualLink = useMemo(
    () =>
      language === ELanguage.RU
        ? "https://datafan.carrotquest.help/article/8322"
        : "https://datafan.carrotquest.help/article/8323",
    [language],
  );

  const { data: metaData, isFetching: metaIsFetching } = useQueryCredentialMetaGet(
    {
      credentialId: -1,
      extraParams: {
        search_account_username: channel,
      },
      object: ECredentialMetaObject.TELEGRAM_CHANEL,
      projectId: projectId || 0,
    },
    {
      enabled: Boolean(channel),
      onSuccess: () => {
        setActiveStep((state) => state + 1);
      },
    },
  );

  const { data: channelInfo } = useQueryCredentialMetaGet(
    {
      credentialId: -1,
      extraParams: {
        search_account_username: channel,
      },
      object: ECredentialMetaObject.TELEGRAM_CHANEL_INFO,
      projectId: projectId || 0,
    },
    {
      enabled: Boolean(channel),
    },
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      if (onClickBack) {
        onClickBack();
      } else {
        history.goBack();
      }
    } else {
      setActiveStep((step) => {
        if (steps[step - 1].id) {
          setChannel(null);
        }

        return step - 1;
      });
    }
  }, [activeStep, history, onClickBack, steps]);

  const handleNext = useCallback(() => {
    if (steps[activeStep].id === "link" && link) {
      setChannel(link.match(TG_REGEXP)?.[3] || null);
    } else if (steps[activeStep].id === "collection" && channel && projectId) {
      onConfigReady({
        config: pipelineType.streams.map((stream) => ({
          search_account_username: [channel],
          source: stream.id,
        })),
        name: channelInfo?.title || channel,
        project_id: projectId,
        source: pipelineType.id,
      });
    } else {
      setActiveStep((step) => step + 1);
    }
  }, [activeStep, channel, channelInfo?.title, link, onConfigReady, pipelineType.id, pipelineType.streams, projectId, steps]);

  const isNextButtonEnabled = useMemo(() => {
    if (steps[activeStep]?.id === "project") {
      return projectId !== null;
    }

    if (steps[activeStep]?.id === "link") {
      return Boolean(link && link.match(TG_REGEXP));
    }

    if (steps[activeStep]?.id === "collection") {
      return Boolean(metaData);
    }
  }, [activeStep, link, metaData, projectId, steps]);

  const PERMISSIONS = useMemo(
    () => [t`Post messages`, t`Edit messages of other`, t`Delete messages of other`, t`tg.add_members`],
    [],
  );

  useUpdateEffect(() => {
    if (!projectsIsLoading && !projectId && projects && projects.length === 1) {
      setProjectId(projects[0].id);
    }
  }, [projectsIsLoading]);

  if (projectsIsLoading) {
    return <Loader />;
  }

  return (
    <UiSimpleStepper
      num={startStepNum + activeStep}
      title={steps[activeStep]?.label}
      onClickPrev={handleBack}
      onClickNext={handleNext}
      isDisabledNextBtn={!isNextButtonEnabled}
      isLoadingNextBtn={isLoading || metaIsFetching}
      textNextBtn={activeStep >= steps.length - 1 ? <Trans>Finish</Trans> : <Trans>Next</Trans>}
    >
      {steps[activeStep]?.id === "project" && <SelectProject value={projectId} onChange={setProjectId} />}

      {steps[activeStep]?.id === "link" && projectId && (
        <>
          <Typography gutterBottom>
            <Trans>To collect post statistics, please enter a link to the public</Trans>
          </Typography>
          <UiTextField fullWidth placeholder="https://t.me/channel" value={link} onChange={setLink} />
        </>
      )}

      {steps[activeStep]?.id === "collection" && metaData?.data.account && (
        <>
          <Typography gutterBottom>
            <Trans>
              The subscriber <strong>{metaData.data.account}</strong> has just subscribed to your channel. To collect account
              statistics, please assign a subscriber <strong>{metaData.data.account}</strong> as an account administrator. The
              following permissions must be granted:
            </Trans>
            <ul>
              {PERMISSIONS.map((str) => (
                <li>{str}</li>
              ))}
            </ul>
            <Trans>
              <Link component="a" href={manualLink} target="_blank" rel="noreferrer">
                Here
              </Link>{" "}
              is an instruction on how to set up channel statistics collection.
            </Trans>
          </Typography>
        </>
      )}
    </UiSimpleStepper>
  );
};

export default CreateTelegram;
