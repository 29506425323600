import { useMemo } from "react";
import { useAppContext } from "src/context/app/appContext";
import { ECurrency } from "src/enums/currency";
import { ELanguage } from "src/enums/language";
import { IResponseBasePagination, IResponseTariff, IResponseTariffOption } from "src/types/response";
import { ITariff } from "src/types/tariff";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";
import useQueryMe from "src/utils/queries/user/useQueryMe";

const QUERY_KEY = "billing-tariffs";
const QUERY_KEY_OPTIONS = "billing-tariff-options";

export default function useQueryBillingTariffsGet(
  queryOptions?: IUseQueryBaseOptions<IResponseBasePagination<IResponseTariff>, ITariff[]>,
) {
  const {
    state: { language },
  } = useAppContext();
  const { data: user } = useQueryMe();
  const { data: tariffOptions } = useQueryBase<IResponseTariffOption[]>(
    QUERY_KEY_OPTIONS,
    {
      enabled: queryOptions?.enabled,
      refetchOnMount: false,
    },
    {
      method: "GET",
      params: {
        lang: language,
      },
      url: "billing/options/",
    },
  );

  const tariffOptionsNormalized = useMemo(
    () =>
      tariffOptions?.reduce((acc, option) => {
        if (option.tariff_on_period) {
          acc[option.tariff_on_period] = option;
        }

        return acc;
      }, {} as { [key: number]: IResponseTariffOption }),
    [tariffOptions],
  );

  return useQueryBase<IResponseBasePagination<IResponseTariff>, ITariff[]>(
    QUERY_KEY,
    {
      refetchOnMount: false,
      select: (data) =>
        data.results
          .map((tariff) => {
            const currency = user?.geoip_currency || (language === ELanguage.EN ? ECurrency.EUR : ECurrency.RUB);
            const option = tariffOptionsNormalized?.[tariff.id];
            const price = currency === ECurrency.RUB ? tariff.rub_price : tariff.eur_price;
            const priceDiscount = currency === ECurrency.RUB ? tariff.rub_discount_price : tariff.eur_discount_price;
            const pricePipeline = currency === ECurrency.RUB ? option?.rub_price : option?.eur_price;

            return {
              currency,
              discount_price: priceDiscount === null ? null : parseFloat(priceDiscount),
              free_renewal: tariff.tariff.free_renewal,
              id: tariff.tariff.id,
              is_negotiable_price: tariff.is_negotiable_price,
              limits: tariff.tariff.limits,
              name: tariff.tariff.name,
              option_id: option?.id,
              pipeline_price: pricePipeline ? parseFloat(pricePipeline) : null,
              price: parseFloat(price),
              sign: tariff.sign,
            };
          })
          .sort((t1, t2) => {
            if (t1.is_negotiable_price === t2.is_negotiable_price) {
              return t1.price > t2.price ? 1 : -1;
            }

            return t1.is_negotiable_price > t2.is_negotiable_price ? 1 : -1;
          }),
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        lang: language,
      },
      url: "billing/full_tariffs/",
    },
  );
}
