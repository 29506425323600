import { IPaymentMethod } from "src/types/paymentMethod";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryBillingPaymentMethodDelete(
  queryOptions?: IUseQueryMutationOptions<unknown, IPaymentMethod["id"]>,
) {
  return useMutationBase<unknown, IPaymentMethod["id"]>(queryOptions, {
    method: "DELETE",
    params: () => undefined,
    url: (id) => `billing/recurring-payments/${id}/`,
  });
}
