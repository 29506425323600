import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const dialog = css`
  .MuiPaper-root {
    max-width: 700px;
  }
`;

export const status = css`
  border: 1px solid currentColor;
  border-radius: 8px;
  padding: 24px 30px;
  margin: 18px 0;
`;

export const status__title = css`
  color: inherit;

  :before {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    background: currentColor;
    position: relative;
    top: -2px;
  }
`;

export const status__desc = css`
  color: #505050;
  margin-top: 16px;
`;

export const form__row = css`
  margin-top: 30px;
`;

export const saveBtn = css`
  margin: 50px 0;
  text-align: right;
`;

export const pipelineId = css`
  text-align: right;
  margin-top: 20px;
  margin-bottom: 14px;
`;

export const pipelineId__inn = (theme: Theme) => css`
  display: inline-block;
  background: ${theme.palette.cyan.main};
  padding: 5px 15px;
  border-radius: 40px;
`;
