import axios from "axios";
import { call, put } from "redux-saga/effects";
import apiRefreshToken from "src/redux/modules/shared/apiRefreshToken";
import { EFailureCodes, Failure } from "src/utils";
import { accessToken } from "src/utils/tokens";

const API_URL = process.env.REACT_APP__API_BASE_URL || "http://127.0.0.1:8080/api";
const API_VERSION = process.env.REACT_APP__API_VERSION || 0;

export type CallEffect<F extends Function> = F | [any, F] | { context: any; fn: F };

const apiRequestAuthorizedBase = function* (apiRequest: (apiMethodInput: any) => Promise<any>, params: any): any {
  try {
    return yield call(apiRequest, params);
  } catch (failure) {
    if (axios.isAxiosError(failure)) {
      if (failure.response?.status === 401) {
        const refreshTokenResp = yield apiRefreshToken();

        if (!refreshTokenResp) {
          return false;
        }

        const { token, refresh_token } = refreshTokenResp;

        if (token && refresh_token) {
          window.localStorage.setItem("access_token", token);
          window.localStorage.setItem("refresh_token", refresh_token);
        } else {
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
        }

        yield put({
          payload: {
            refresh_token,
            token,
          },
          type: "AUTH_SIGN_IN__S",
        });

        return yield call(apiRequest, {
          ...params,
          accessToken: token,
        });
      }

      if (failure.code !== EFailureCodes.USER__TOKEN_STALE) {
        throw failure;
      }
    }
  }
};

export const apiRequestAuthorized: any = function* <IInput extends object, IOutput>(
  apiMethod: (apiMethodInput: { accessToken: string } & IInput) => Promise<IOutput>,
  apiMethodInput: IInput,
): any {
  if (!accessToken) {
    throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
  }

  return yield apiRequestAuthorizedBase(apiMethod, {
    accessToken,
    ...apiMethodInput,
  });
};

export const apiRequestSMPAuthorized: any = function* <IInput extends object, IOutput>(
  apiMethod: (apiMethodInput: { accessToken: string } & IInput) => Promise<IOutput>,
  apiMethodInput: IInput,
): any {
  if (!accessToken) {
    throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
  }

  const response: Response = yield apiRequestAuthorizedBase(
    ({ accessToken }) =>
      fetch(`${API_URL}/${API_VERSION}/soother-auth/`, {
        credentials: "include",
        headers: { Authorization: `JWT ${accessToken}` },
        method: "GET",
      }).then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response;
      }),
    { accessToken },
  );

  const accessTokenSMP = response.headers.get("X-SMP-JWT");

  if (!accessTokenSMP) {
    throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
  }

  return yield apiRequestAuthorizedBase(apiMethod, {
    accessToken: accessTokenSMP,
    ...apiMethodInput,
  });
};

export const apiRequestSMP: any = function* <IInput extends object, IOutput>(
  apiMethod: (apiMethodInput: { accessToken: string } & IInput) => Promise<IOutput>,
  apiMethodInput: IInput,
): any {
  const response: Response = yield fetch(`${API_URL}/${API_VERSION}/soother/`, {
    credentials: "include",
    method: "GET",
  });

  const accessTokenSMP = response.headers.get("X-SMP-JWT");

  if (!accessTokenSMP) {
    throw new Failure(EFailureCodes.USER__LOGIN_REQUIRED);
  }

  return yield apiRequestAuthorizedBase(apiMethod, {
    accessToken: accessTokenSMP,
    ...apiMethodInput,
  });
};
