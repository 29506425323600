import { darken } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles(() => ({
  progress: {
    left: "50%",
    marginLeft: -12,
    marginTop: -12,
    position: "absolute",
    top: "50%",
    zIndex: 2,
  },
  socialBtn: {
    "& .MuiButton-startIcon > div": {
      margin: 0,
    },

    "&:hover": {
      background: darken("#4285F4", 0.2),
    },

    backgroundColor: "#4285F4",
    color: "#fff",
    width: "100%",
  },

  socialBtnRoot: {
    width: "100%",
  },
}));
