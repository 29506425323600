import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const preview = css`
  display: block;
  margin: auto auto 30px;
  width: 100%;
`;

export const col = (theme: Theme) => css`
  flex-basis: 100%;

  ${theme.breakpoints.up("sm")} {
    flex-basis: 50%;
    width: 50%;
  }
`;
