export function isArray<T>(value: any): value is T[] {
  return Array.isArray(value);
}

export function isFunction<T>(value: any): value is T {
  return typeof value === "function";
}

export function isObject(value: any): value is object {
  return typeof value === "object" && value !== null;
}

export function isString(value: any): value is string {
  return typeof value === "string";
}
