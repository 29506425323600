export interface ISocialAuthApps {
  url: string;
  medium: "google" | "smmplanner" | "elama";
  id: number;
  socialTitleIn: {
    id: string;
  };
  socialTitleUp: {
    id: string;
  };
  isEnabled: boolean;
}

export const socialAuthApps: ISocialAuthApps[] = [
  {
    id: parseInt(process.env.REACT_APP__AUTH_GOOGLE_APP_ID || "26", 10),
    isEnabled: process.env.REACT_APP__AUTH_GOOGLE_ENABLED === "true",
    medium: "google",
    socialTitleIn: /*i18n*/ { id: "Sign in with Google" },
    socialTitleUp: /*i18n*/ { id: "Sign up with Google" },
    url: `https://api.smp.io/oauth-client/v1/begin/${parseInt(process.env.REACT_APP__AUTH_GOOGLE_APP_ID || "26", 10)}`,
  },
  {
    id: parseInt(process.env.REACT_APP__AUTH_SMMPLANNER_APP_ID || "56", 10),
    isEnabled: process.env.REACT_APP__AUTH_SMMPLANNER_ENABLED === "true",
    medium: "smmplanner",
    socialTitleIn: /*i18n*/ { id: "Sign in with SMMplanner" },
    socialTitleUp: /*i18n*/ { id: "Sign up with SMMplanner" },
    url: `https://api.smp.io/oauth-client/v1/begin/${parseInt(process.env.REACT_APP__AUTH_SMMPLANNER_APP_ID || "56", 10)}`,
  },
  {
    id: parseInt(process.env.REACT_APP__AUTH_ELAMA_APP_ID || "112", 10),
    isEnabled: process.env.REACT_APP__AUTH_ELAMA_ENABLED === "true",
    medium: "elama",
    socialTitleIn: /*i18n*/ { id: "Sign in with eLama" },
    socialTitleUp: /*i18n*/ { id: "Sign up with eLama" },
    url: `https://api.smp.io/oauth-client/v1/begin/${parseInt(process.env.REACT_APP__AUTH_ELAMA_APP_ID || "56", 10)}`,
  },
];
