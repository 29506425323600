import { t } from "@lingui/macro";
import { ITariffLimits } from "src/types/tariff";

export interface IFixtureTariffsTableOption {
  label: string;
  subLabel?: string;
  limitKey?: keyof ITariffLimits;
  description?: string;
  defaultValue?: string | number | boolean;
  isPipelinePrice?: boolean;
}

export interface IFixtureTariffsTableItem {
  title: string | undefined;
  options: IFixtureTariffsTableOption[];
}

export const getTariffsTable = (): IFixtureTariffsTableItem[] => [
  {
    options: [
      {
        defaultValue: true,
        description: t`Public or advertising account of a social network available for connection. Public is an Instagram account, VKontakte community, Facebook business page, Odnoklassniki group.`,
        label: t`1 free public/ad account`,
      },
      {
        description: t`Each tariff includes one free public or advertising account that you received upon registration.`,
        label: t`Public/advertising accounts`,
        limitKey: "pipelines",
      },
      {
        defaultValue: true,
        description: t`Instagram accounts for which you can upload open data without access to the account itself.`,
        label: t`Competitor analysis`,
        subLabel: t`(competitor analysis)`,
      },
      {
        description: t`Instagram account available for connection, VKontakte community, Facebook business page, Odnoklassniki group.`,
        label: t`Social media`,
        limitKey: "media_public",
      },
      {
        description: t`Ad accounts available for connection.`,
        label: t`Ad accounts`,
        limitKey: "media_ads",
      },
      {
        description: t`Additional public on the social network. Public is an Instagram account, a VKontakte community, a Facebook business page, a Odnoklassniki group.`,
        isPipelinePrice: true,
        label: t`The cost of an additional public`,
      },
      {
        description: t`Additional advertising account FB ADS, Insta ADS, TikTok ADS, VK Business, myTarget.`,
        isPipelinePrice: true,
        label: t`The cost of an additional ad account`,
      },
    ],
    title: undefined,
  },

  {
    options: [
      {
        description: t`The period of time in months for which public social media data is available.`,
        label: t`Public date range`,
        limitKey: "first_start_day_public",
      },

      {
        description: t`The period of time in months for which the data of the advertising account is available.`,
        label: t`Ad account date range`,
        limitKey: "first_start_day_ads",
      },

      {
        description: t`How many times a day is the public data updated.`,
        label: t`Public data update`,
        limitKey: "update_frequency_seconds",
      },

      {
        description: t`How many times a day the data of the advertising account is updated.`,
        label: t`Ad account data update`,
        limitKey: "update_frequency_seconds",
      },
    ],
    title: t`Date range`,
  },

  {
    options: [
      {
        defaultValue: Infinity,
        description: t`Number of report openings and downloads.`,
        label: t`Unlimited report uploads`,
      },
      {
        description: t`Likes, comments, bookmarks, video views, reach and post impressions that were obtained as a result of paid promotion.`,
        label: t`Promoted posts statistics`,
        limitKey: "media_public",
      },
      {
        defaultValue: true,
        description: t`In the report, you can see if the indicators have increased compared to the previous period. If the report is built over the last 30 days, the comparison will be made with a period of 30-60 days.`,
        label: t`Previous period comparison`,
      },
      {
        defaultValue: ".pdf .xlsx .png .jpg",
        description: t`Choose which format for downloading the report suits you best.`,
        label: t`Report export`,
      },
      {
        description: t`BI systems to which you can send data from DataFan and independently work with them.`,
        label: t`Data export`,
        limitKey: "export_bi",
        subLabel: "Google Data Studio\nGoogle Sheets\nPower BI",
      },
    ],
    title: t`Reports`,
  },

  {
    options: [
      {
        description: t`You can invite colleagues to the project and collaborate with public /ad account data. Possibility of different levels of data access.`,
        label: t`Access level`,
        limitKey: "access_level",
      },
      {
        description: t`The number of participants in the project who have access to public/ad account data.`,
        label: t`Users`,
        limitKey: "users",
      },
      {
        description: t`The maximum number of projects available.`,
        label: t`Number of projects`,
        limitKey: "projects",
      },
    ],
    title: t`Team and projects`,
  },

  {
    options: [
      {
        description: t`Editing and customizing the report, adding a logo, corporate identity.`,
        label: t`Report customisation`,
        limitKey: "report_edit",
      },
      {
        description: t`Uploading data from DataFan using your API requests.`,
        label: "API",
        limitKey: "access_to_api",
      },
      {
        description: t`Public/Ad account data are not being updated, but are available for uploading and building a report.`,
        label: t`Archiving of public/ad account data`,
        limitKey: "archive_pipelines",
      },
    ],
    title: t`Features`,
  },
];

export const getTariffsTableMobile = (): IFixtureTariffsTableOption[] => [
  {
    description: t`Instagram account available for connection, VKontakte community, Facebook business page, Odnoklassniki group.`,
    label: t`Social media`,
    limitKey: "media_public",
  },
  {
    description: t`Ad accounts available for connection.`,
    label: t`Ad accounts`,
    limitKey: "media_ads",
  },
  {
    description: t`Each tariff includes one free public or advertising account that you received upon registration.`,
    label: t`Public/advertising accounts`,
    limitKey: "pipelines",
  },
  {
    isPipelinePrice: true,
    label: t`Add. public/ad account`,
  },
  {
    description: t`The period of time for which the data of the social media account are available`,
    label: t`Date range: publics`,
    limitKey: "first_start_day_public",
  },
  {
    description: t`The period of time for which the data of the ad account are available`,
    label: t`Date range: ad accounts`,
    limitKey: "first_start_day_ads",
  },
  {
    description: t`Public/Ad account data are not being updated, but are available for uploading and building a report.`,
    label: t`Data archive`,
    limitKey: "archive_pipelines",
  },
  {
    description: t`The maximum number of projects available for creation`,
    label: t`Number of projects`,
    limitKey: "projects",
  },
  {
    description: t`Invite colleagues into projects for joint work with public/ad account data. Different data access level is available`,
    label: t`Access level`,
    limitKey: "access_level",
  },
  {
    description: t`Uploading data from DataFan using your API requests.`,
    label: "API",
    limitKey: "access_to_api",
  },
];
