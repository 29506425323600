import { css } from "@emotion/react/macro";
import { alpha } from "@mui/material";

export const preview = css`
  position: relative;
`;

export const preview__icon = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: ${alpha("#000000", 0.2)};
  cursor: zoom-in;
  transition: opacity 150ms;

  &:hover {
    opacity: 1;
  }
`;

export const preview__icon__inner = css`
  width: 46px;
  height: 46px;
  background: ${alpha("#ffffff", 0.8)};
  color: #505050;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const image = css`
  display: block;
`;

export const wrapper = css`
  z-index: 10000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: zoom-out;
`;

export const container = css`
  position: fixed;
  transition: all 500ms;

  img {
    width: 100%;
  }
`;

export const container_visible = css`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const container_transition_disabled = css`
  transition: none;
`;
