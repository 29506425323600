import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme: Theme) => ({
  avatar: {
    "& .MuiSvgIcon-root": {
      height: "2em",
      width: "2em",
    },

    "&.MuiAvatar-root": {
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(7),
      margin: "auto",
      width: theme.spacing(7),
    },
  },

  dl: {
    "& dd": {
      "& > a": {
        color: "inherit",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },

      "&::before": {
        borderBottomColor: theme.palette.grey.A100,
        borderBottomStyle: "dotted",
        borderBottomWidth: 2,
        content: '""',
        display: "block",
        flexGrow: 1,
        height: "1em",
        margin: theme.spacing(0, 1),
      },

      color: theme.palette.primary.main,
      display: "flex",
      flexGrow: 1,
      fontSize: "1.3em",
      lineHeight: 1,
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },

    display: "flex",
    justifyContent: "space-between",
  },

  paper: {
    padding: theme.spacing(2),
  },
}));
