import { t, Trans } from "@lingui/macro";
import { Add, Remove } from "@mui/icons-material";
import { Alert, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useMemo, useState, VFC } from "react";
import { useDispatch } from "react-redux";
import { useUpdateEffect } from "react-use";
import ModalPaymentMethod from "src/components/ModalPaymentMethods";
import ModalPurchasePipelineAlert from "src/components/ModalPurchasePipelineAlert";
import ModalSavePaymentMethod from "src/components/ModalSavePaymentMethod";
import ModalSubscriptionFreeDaysRenewal from "src/components/ModalSubscriptionFreeDaysRenewal";
import UiButton, { UiButtonProps } from "src/components/UiButton";
import UiTextField, { UiTextFieldProps } from "src/components/UiTextField";
import { useBillingContext } from "src/context/billing/billingContext";
import { ECurrency } from "src/enums/currency";
import { ETariffPeriodMeasure } from "src/enums/tariff";
import { popupAdd } from "src/redux";
import { IPaymentMethod } from "src/types/paymentMethod";
import { IResponseTariffOption } from "src/types/response";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";
import useSubscriptionTimeLeft from "src/utils/hoooks/useSubscriptionTimeLeft";
import useQueryBillingCalculatePost from "src/utils/queries/billing/useQueryBillingCalculatePost";
import useQueryBillingTariffOptionsGet from "src/utils/queries/billing/useQueryBillingTariffOptionsGet";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import useQueryInfoByIp from "src/utils/queries/user/useQueryInfoByIp";
import useQueryMe, { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

export interface PurchaseOptionWidgetProps {
  disableFullWidth?: boolean;
  initialPipelineNum?: number;
  initialOption?: IResponseTariffOption;
  onSuccess?: () => void;
  withoutSnackbar?: boolean;
  NumButtonProps?: UiButtonProps;
  BuyButtonProps?: UiButtonProps;
  TextFieldProps?: UiTextFieldProps;
}

const PurchaseOptionWidget: VFC<PurchaseOptionWidgetProps> = ({
  disableFullWidth,
  initialOption,
  initialPipelineNum = 1,
  onSuccess = () => {},
  BuyButtonProps,
  NumButtonProps,
  TextFieldProps,
  withoutSnackbar,
}) => {
  const dispatch = useDispatch();
  const {
    state: { isPaymentPending },
    actions: { payOrder },
  } = useBillingContext();

  const { data: user } = useQueryMe();
  const { data: userInfo, isLoading: userInfoIsLoading } = useQueryInfoByIp();
  const invalidateUser = useQueryMeInvalidate();
  const invalidateProjects = useQueryProjectsInvalidate();
  const [pipelinesNum, setPipelinesNum] = useState<number>(initialPipelineNum);
  const timeLeftDays = useSubscriptionTimeLeft();
  const [displayAlertModal, setDisplayAlertModal] = useState<boolean>(false);
  const [displayModalPaymentMethod, setDisplayModalPaymentMethod] = useState<boolean>(false);
  const [displayModalSavePaymentMethod, setDisplayModalSavePaymentMethod] = useState<boolean>(false);
  const [displayFreeDaysModal, setDisplayFreeDaysModal] = useState<boolean>(false);

  const { data: tariffOptions } = useQueryBillingTariffOptionsGet({
    enabled: !initialOption,
  });

  const [currency, setCurrency] = useState(() => (userInfo?.domain === "datafan.pro" ? ECurrency.RUB : undefined));

  const subscription = useMemo(() => user?.active_subscription, [user?.active_subscription]);

  const tariffOption = useMemo(
    () =>
      initialOption || tariffOptions?.find(({ tariff_on_period }) => tariff_on_period === subscription?.tariff_on_period.id),
    [initialOption, subscription?.tariff_on_period.id, tariffOptions],
  );

  const { data: calculation, isLoading: calculationIsLoading } = useQueryBillingCalculatePost(
    currency && tariffOption
      ? {
          currency,
          options: [{ count: pipelinesNum, option_id: tariffOption.id }],
          renewal_with_free_days: false,
        }
      : undefined,
    {
      keepPreviousData: true,
    },
  );

  const canAutoPay = Boolean(user?.can_auto_pay);

  const tariffBillingPeriodDays = useMemo(() => {
    if (!subscription) {
      return Infinity;
    }

    const daysInPeriod = {
      [ETariffPeriodMeasure.MONTH]: 31,
      [ETariffPeriodMeasure.WEEK]: 7,
      [ETariffPeriodMeasure.DAY]: 1,
    }[subscription.tariff_on_period.period.measure];

    return subscription.tariff_on_period.period.units * daysInPeriod;
  }, [subscription]);

  const handleSetPipelinesNum = useCallback((value: string | number) => {
    setPipelinesNum(Math.max(Number(value), 1));
  }, []);

  const handleIncreasePipelinesNum = useCallback(() => {
    handleSetPipelinesNum(pipelinesNum + 1);
  }, [handleSetPipelinesNum, pipelinesNum]);

  const handleDecreasePipelinesNum = useCallback(() => {
    handleSetPipelinesNum(pipelinesNum - 1);
  }, [handleSetPipelinesNum, pipelinesNum]);

  const handleCreateInvoice = useCallback(
    async (autoRenewalId?: IPaymentMethod["id"], saveMethod?: boolean) => {
      if (tariffOption) {
        await payOrder({
          autoRenewalId,
          callback: (success) => {
            if (success) {
              invalidateUser();
              invalidateProjects();
              setPipelinesNum(1);
              onSuccess();

              if (!withoutSnackbar) {
                dispatch(
                  popupAdd({
                    text: t`Additional dataset purchased`,
                  }),
                );
              }
            }
          },
          order: {
            options: [{ count: pipelinesNum, option_id: tariffOption.id }],
            renewalWithFreeDays: false,
            system: currency === "EUR" ? "maib" : currency === "RUB" ? "yandex" : "cloudpayments",
          },
          savePaymentMethod: saveMethod,
        });
      }
    },
    [tariffOption, payOrder, pipelinesNum, currency, invalidateUser, invalidateProjects, onSuccess, withoutSnackbar, dispatch],
  );

  const handleCloseFreeDaysModal = useCallback(() => {
    setDisplayFreeDaysModal(false);
  }, []);

  const handleCloseAlertModal = useCallback(() => {
    setDisplayAlertModal(false);
  }, []);

  const handleSelectPaymentMethod = useCallback(
    async (id: IPaymentMethod["id"] | undefined) => {
      setDisplayModalPaymentMethod(false);

      if (!id) {
        setDisplayModalSavePaymentMethod(true);
      } else {
        await handleCreateInvoice(id);
      }
    },
    [handleCreateInvoice],
  );

  const handleCloseModalSavePaymentMethod = useCallback(
    async (save: boolean) => {
      setDisplayModalSavePaymentMethod(false);

      await handleCreateInvoice(undefined, save);
    },
    [handleCreateInvoice],
  );

  const handleCreateInvoicePreflight = useCallback(async () => {
    if (calculation?.internal_flags.full_paid_internally || currency === "EUR") {
      handleCloseModalSavePaymentMethod(false);
    } else if (canAutoPay) {
      return setDisplayModalPaymentMethod(true);
    } else {
      setDisplayModalSavePaymentMethod(true);
    }
  }, [calculation?.internal_flags.full_paid_internally, canAutoPay, currency, handleCloseModalSavePaymentMethod]);

  const handleClickBuy = useCallback(async () => {
    if (subscription?.can_renewal_with_free_days) {
      setDisplayFreeDaysModal(true);
    } else if (timeLeftDays < tariffBillingPeriodDays) {
      setDisplayAlertModal(true);
    } else {
      await handleCreateInvoicePreflight();
    }
  }, [handleCreateInvoicePreflight, subscription?.can_renewal_with_free_days, tariffBillingPeriodDays, timeLeftDays]);

  const handleSelectCurrency = useCallback((currency: ECurrency) => () => setCurrency(currency), []);

  const price = useMemo(
    () => calculation?.current_discount_price || calculation?.next_price,
    [calculation?.current_discount_price, calculation?.next_price],
  );

  useUpdateEffect(() => {
    if (userInfo?.domain === "datafan.pro") {
      setCurrency(ECurrency.RUB);
    }
  }, [userInfo]);

  useEffect(() => {
    if (tariffOption && initialPipelineNum) {
      handleSetPipelinesNum(initialPipelineNum);
    }
  }, [initialPipelineNum, handleSetPipelinesNum, tariffOption]);

  return useMemo(
    () =>
      tariffOption && subscription ? (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <UiButton
                disabled={pipelinesNum <= 1}
                onClick={handleDecreasePipelinesNum}
                startIcon={<Remove />}
                {...NumButtonProps}
              />
            </Grid>
            <Grid item xs={!disableFullWidth}>
              <UiTextField
                fullWidth
                type="number"
                value={pipelinesNum}
                onChange={handleSetPipelinesNum}
                minValue={1}
                {...TextFieldProps}
              />
            </Grid>
            <Grid item>
              <UiButton onClick={handleIncreasePipelinesNum} startIcon={<Add />} {...NumButtonProps} />
            </Grid>

            {pipelinesNum > 0 && (
              <Grid item xs={12} sm="auto">
                {userInfoIsLoading || calculationIsLoading ? (
                  <CircularProgress size={24} />
                ) : !currency ? (
                  <Stack direction="row" spacing={1}>
                    <UiButton
                      disabled={isPaymentPending}
                      color="secondary"
                      onClick={handleSelectCurrency(ECurrency.RUB)}
                      {...BuyButtonProps}
                    >
                      <Trans>Pay in ₽</Trans>
                    </UiButton>

                    <UiButton
                      disabled={isPaymentPending}
                      color="secondary"
                      onClick={handleSelectCurrency(ECurrency.EUR)}
                      {...BuyButtonProps}
                    >
                      <Trans>Pay in €</Trans>
                    </UiButton>
                  </Stack>
                ) : (
                  <UiButton fullWidth loading={isPaymentPending} color="secondary" onClick={handleClickBuy} {...BuyButtonProps}>
                    <Trans>Buy for {price + getCurrencyDisplayName(currency)}</Trans>
                    {calculation?.internal_account_withdrawal_amount ? "*" : ""}
                  </UiButton>
                )}
              </Grid>
            )}
          </Grid>

          {pipelinesNum > 0 && calculation && calculation?.internal_account_withdrawal_amount !== 0 && (
            <Alert severity="info" sx={{ mt: 2 }}>
              <Trans
                id="Taking into account funds of the bonus account ({amount} {currency})"
                values={{
                  amount: Math.abs(calculation.internal_account_withdrawal_amount),
                  currency: getCurrencyDisplayName(subscription.currency),
                }}
              />
            </Alert>
          )}

          <ModalPurchasePipelineAlert
            isOpen={displayAlertModal}
            onClose={handleCloseAlertModal}
            onBuyOptions={handleCreateInvoicePreflight}
          />

          <ModalSubscriptionFreeDaysRenewal
            optionsNum={pipelinesNum}
            isOpen={displayFreeDaysModal}
            onClose={handleCloseFreeDaysModal}
            onBuyOptions={handleCreateInvoicePreflight}
          />

          <ModalSavePaymentMethod open={displayModalSavePaymentMethod} onClose={handleCloseModalSavePaymentMethod} />

          <ModalPaymentMethod open={displayModalPaymentMethod} onSelect={handleSelectPaymentMethod} />
        </>
      ) : null,
    [
      tariffOption,
      subscription,
      pipelinesNum,
      handleDecreasePipelinesNum,
      NumButtonProps,
      disableFullWidth,
      handleSetPipelinesNum,
      TextFieldProps,
      handleIncreasePipelinesNum,
      userInfoIsLoading,
      calculationIsLoading,
      currency,
      handleSelectCurrency,
      BuyButtonProps,
      isPaymentPending,
      handleClickBuy,
      price,
      calculation,
      displayAlertModal,
      handleCloseAlertModal,
      handleCreateInvoicePreflight,
      displayFreeDaysModal,
      handleCloseFreeDaysModal,
      displayModalSavePaymentMethod,
      handleCloseModalSavePaymentMethod,
      displayModalPaymentMethod,
      handleSelectPaymentMethod,
    ],
  );
};

export default PurchaseOptionWidget;
