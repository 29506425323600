import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const steps = (theme: Theme) => css`
  margin: 0 0 0 -30px;
  display: flex;
  counter-reset: steps;
  flex-wrap: wrap;

  ${theme.breakpoints.down(1720)} {
    margin: 0 auto;
    display: block;
    max-width: 720px;
  }
`;

export const step = (theme: Theme) => css`
  margin: 0 0 0 30px;
  padding: 30px;
  width: calc(25% - 30px);
  text-align: left;

  ${theme.breakpoints.down(1720)} {
    display: flex;
    margin: 0 0 60px;
    padding: 0;
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    display: block;
  }

  :before {
    counter-increment: steps;
    content: counter(steps);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #20a8a6;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    font-size: 1.125rem;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 30px;

    ${theme.breakpoints.down(1720)} {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 30px;
    }
  }
`;

export const step__content = (theme: Theme) => css`
  display: block;

  ${theme.breakpoints.down(1720)} {
    width: 305px;
    margin-right: 30px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    margin-right: 0;
  }
`;

export const step__pic = (theme: Theme) => css`
  display: block;
  border-radius: 8px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${theme.breakpoints.down(1720)} {
    max-width: 305px;
    margin: 0 auto;
  }
`;
