import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential, ICredentialMeta } from "src/types/credential";
import { IProject } from "src/types/project";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IParams {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  object: ECredentialMetaObject;
  extraParams?: { [key: string]: any };
}

const QUERY_KEY = "credential-meta-get";

export default function useQueryCredentialMetaGet<TParams extends IParams>(
  params: TParams,
  queryOptions?: IUseQueryBaseOptions<ICredentialMeta[TParams["object"]]>,
) {
  const { credentialId, projectId, object, extraParams } = params;

  return useQueryBase<ICredentialMeta[TParams["object"]]>(
    [QUERY_KEY, params],
    {
      ...queryOptions,
    },
    {
      method: "GET",
      params: {
        object,
        params: extraParams,
        project_id: projectId,
      },
      url: `account-credentials/meta/${credentialId}/`,
    },
  );
}
