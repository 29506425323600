declare global {
  interface Window {
    dataLayer: object[];
  }
}

export default function dataLayerPush(obj: object) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
}
