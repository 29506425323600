import { Trans } from "@lingui/macro";
import { Box, BoxProps, Stack, Tooltip, Typography } from "@mui/material";
import { IconCheck, IconInfoCircle } from "@tabler/icons";
import { FC, ReactNode } from "react";
import UiButton from "src/components/UiButton";

import * as classes from "./index.css";

export interface UiSimpleStepperProps extends Omit<BoxProps, "title"> {
  title: string | ReactNode;
  info?: string | ReactNode;
  num?: number;
  isDisabledPrevBtn?: boolean;
  isDisabledNextBtn?: boolean;
  isLoadingPrevBtn?: boolean;
  isLoadingNextBtn?: boolean;
  textNextBtn?: string | ReactNode;
  textPrevBtn?: string | ReactNode;
  onClickPrev?: () => void;
  onClickNext?: () => void;
}

const UiSimpleStepper: FC<UiSimpleStepperProps> = ({
  num,
  title,
  info,
  isDisabledNextBtn,
  isDisabledPrevBtn,
  isLoadingPrevBtn,
  isLoadingNextBtn,
  textNextBtn,
  textPrevBtn,
  onClickNext,
  onClickPrev,
  children,
  ...props
}) => (
  <Box {...props}>
    <div css={classes.head}>
      <div css={classes.num}>{typeof num !== "undefined" ? num : <IconCheck />}</div>
      <Typography variant="h4" component="span">
        {title}
      </Typography>

      {info && (
        <Tooltip title={info}>
          <div css={classes.info}>
            <IconInfoCircle />
          </div>
        </Tooltip>
      )}
    </div>

    <div css={classes.content}>{children}</div>

    <Stack css={classes.buttons} direction="row" spacing={1.6}>
      <UiButton
        variant="outlined"
        disableElevation
        disabled={isDisabledPrevBtn || isLoadingNextBtn}
        loading={isLoadingPrevBtn}
        onClick={onClickPrev}
      >
        {textPrevBtn || <Trans>Back</Trans>}
      </UiButton>

      <UiButton disabled={isDisabledNextBtn || isLoadingPrevBtn} loading={isLoadingNextBtn} onClick={onClickNext}>
        {textNextBtn || <Trans>Next</Trans>}
      </UiButton>
    </Stack>
  </Box>
);

export default UiSimpleStepper;
