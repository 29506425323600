import { FC, useMemo } from "react";
import { useMount } from "react-use";
import Loader from "src/components/Loader";
import UiSelect from "src/components/UiSelect";
import { IPipeline } from "src/types/pipeline";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

interface IProps {
  pipelineId: IPipeline["id"] | null;
  onSelect: (pipeline: IPipeline["id"] | null) => void;
  source: IPipeline["source"] | null;
}

const SelectPipeline: FC<IProps> = ({ pipelineId, onSelect, source }) => {
  const { data: projects, isFetched: isProjectsFetched } = useQueryProjects();

  const pipelineOptions = useMemo(
    () =>
      projects
        ?.map(({ pipelines }) =>
          (source === null ? pipelines : pipelines.filter((pipeline) => pipeline.source === source)).map(({ id, name }) => ({
            label: `[${id}] ${name}`,
            value: id,
          })),
        )
        ?.flat()
        .sort((p1, p2) => (p1.value > p2.value ? -1 : 1)),
    [projects, source],
  );

  useMount(() => {
    onSelect(pipelineId || pipelineOptions?.[0]?.value || null);
  });

  if (!projects && !isProjectsFetched) {
    return <Loader />;
  }

  return <UiSelect onChange={onSelect} value={pipelineId} options={pipelineOptions} fullWidth />;
};

export default SelectPipeline;
