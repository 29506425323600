import { css } from "@emotion/react/macro";
import { t, Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useMount, useUpdateEffect } from "react-use";
import SelectProject from "src/components/PipelineCreateForm/components/SelectProject";
import PipelineTypeList, { IItemProps } from "src/components/PipelineTypeList";
import UiSelect from "src/components/UiSelect";
import UiSimpleStepper from "src/components/UiSimpleStepper";
import UiTextField from "src/components/UiTextField";
import { EPipelineMedia, EPipelineSubType, EPipelineType } from "src/enums/pipeline";
import { IPipeline, IPipelineType } from "src/types/pipeline";
import { IPipelineConfigMerge } from "src/types/pipelineConfig";
import { IProject, IProjectExtended } from "src/types/project";
import generateMergeConfig from "src/utils/generateMergeConfig";
import generateMergePlanFactConfig from "src/utils/generateMergePlanFactConfig";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import SelectboxChip from "./components/SelectboxChip";
import SelectboxMenu from "./components/SelectboxMenu";

interface IProps {
  pipelineType: IPipelineType;
  onConfigReady: (config: IPipelineConfigMerge) => void;
  isLoading: boolean;
  onClickBack?: () => void;
  startStepNum: number;
  persistState?: {
    [key: string]: any;
  };
}

interface IPersistState {
  activeStep?: number;
  projectId?: IProject["id"] | null;
  newPipeline?: IPipeline;
  selectedAds?: number[];
  selectedPublics?: number[];
}

const CreateMerge: FC<IProps> = ({ isLoading, pipelineType, onConfigReady, onClickBack, startStepNum, persistState }) => {
  const history = useHistory();
  const location = useLocation<IPersistState | undefined>();
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const [newPipelineGroup, setNewPipelineGroup] = useState<undefined | "ads" | "public">();
  const [activeStep, setActiveStep] = useState(location.state?.activeStep || 0);
  const [projectId, setProjectId] = useState<IProject["id"] | null>(
    location.state?.projectId || (projects && projects.length === 1 && projects[0].id) || null,
  );
  const [selectedAds, setSelectedAds] = useState<number[] | null>(location.state?.selectedAds || null);
  const [selectedPublics, setSelectedPublics] = useState<number[] | null>(location.state?.selectedPublics || null);
  const steps = [
    projects && projects.length > 1 ? { id: "project", label: t`Select project` } : undefined,
    { id: "dataSource", label: t`Choose public or ad account` },
    { id: "name", label: t`Name DataFan Merge` },
  ].filter((step) => typeof step !== "undefined");
  const [pipelineName, setPipelineName] = useState("");

  const { data: pipelineTypes } = useQueryPipelineTypes();

  const availablePipelineTypeIds = useMemo(
    () =>
      pipelineTypes &&
      Object.values(pipelineTypes)
        .filter((pt) =>
          pt.streams.some(
            (stream) =>
              pipelineType.available_streams?.ads.includes(stream.id) ||
              pipelineType.available_streams?.post.includes(stream.id) ||
              pipelineType.available_streams?.community.includes(stream.id),
          ),
        )
        .map(({ id }) => id),
    [
      pipelineType.available_streams?.ads,
      pipelineType.available_streams?.community,
      pipelineType.available_streams?.post,
      pipelineTypes,
    ],
  );

  const project = useMemo(() => projects?.find(({ id }) => id === projectId), [projectId, projects]);

  const pipelines = useMemo(
    () =>
      projects
        ?.map(({ pipelines }) => pipelines)
        .flat()
        .filter(({ source }) => availablePipelineTypeIds?.includes(source)),
    [availablePipelineTypeIds, projects],
  );

  const pipelinesNormalized = useMemo(
    () =>
      pipelines?.reduce((acc, pipeline) => {
        acc[pipeline.id] = pipeline;
        return acc;
      }, {} as { [key in IPipeline["id"]]: IProjectExtended["pipelines"][number] }),
    [pipelines],
  );

  const adsOptions = useMemo(
    () =>
      (project &&
        pipelineTypes &&
        pipelines
          ?.filter(({ source }) => pipelineTypes[source].subType === EPipelineSubType.ADS)
          .map((pipeline) => ({
            data: pipeline,
            label: pipeline.name,
            value: pipeline.id,
          }))) ||
      [],
    [pipelineTypes, pipelines, project],
  );

  const publicOptions = useMemo(
    () =>
      (project &&
        pipelineTypes &&
        pipelines
          ?.filter(({ source }) => pipelineTypes[source].subType === EPipelineSubType.MULTIPIPELINE)
          .map((pipeline) => ({
            data: pipeline,
            label: pipeline.name,
            value: pipeline.id,
          }))) ||
      [],
    [pipelineTypes, pipelines, project],
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      if (onClickBack) {
        onClickBack();
      } else {
        history.goBack();
      }
    } else {
      setActiveStep((step) => step - 1);
    }
  }, [activeStep, history, onClickBack]);

  const handleNext = useCallback(() => {
    setActiveStep((step) => step + 1);
  }, []);

  const isNextButtonEnabled = useMemo(() => {
    if (steps[activeStep]?.id === "project") {
      return projectId !== null;
    }

    if (steps[activeStep]?.id === "dataSource") {
      return (selectedAds?.length || 0) + (selectedPublics?.length || 0) >= 2;
    }

    if (steps[activeStep]?.id === "name") {
      return !!pipelineName;
    }
  }, [activeStep, pipelineName, projectId, selectedAds?.length, selectedPublics?.length, steps]);

  const selectedPipelines = useMemo(() => {
    const selectedAdsPipelines = (pipelinesNormalized && selectedAds?.map((id) => pipelinesNormalized[id])) || [];
    const selectedPublicPipelines = (pipelinesNormalized && selectedPublics?.map((id) => pipelinesNormalized[id])) || [];

    return [...selectedAdsPipelines, ...selectedPublicPipelines];
  }, [pipelinesNormalized, selectedAds, selectedPublics]);

  const selectedPipelinesNormalized = useMemo(
    () =>
      selectedPipelines.reduce((acc, pipeline) => {
        if (pipeline.media) {
          acc[pipeline.media] = [...(acc[pipeline.media] || []), pipeline];
        }

        return acc;
      }, {} as { [key in EPipelineMedia]: IProjectExtended["pipelines"][number][] }),
    [selectedPipelines],
  );

  const pipelineNameGenerated = useMemo(
    () =>
      (Object.keys(selectedPipelinesNormalized) as EPipelineMedia[])
        .map((media) => `[${media}] ${selectedPipelinesNormalized[media].map(({ name }) => name).join(", ")}`)
        .join("; "),
    [selectedPipelinesNormalized],
  );

  const pipelineTypeListFilter = useCallback(
    (pt: IPipelineType) => Boolean(pt.group === newPipelineGroup && availablePipelineTypeIds?.includes(pt.id)),
    [availablePipelineTypeIds, newPipelineGroup],
  );

  const renderListItemComponent = useCallback(
    ({ sourceId, children }: IItemProps) => (
      <Link
        to={{
          pathname: `/dataset-create/${sourceId}/`,
          state: {
            activeStep,
            nextState: {
              pathname: location.pathname,
              state: {
                ...persistState,
                activeStep,
                projectId,
                selectedAds,
                selectedPublics,
              },
            },
            projectId,
          },
        }}
        css={css`
          text-decoration: none;
        `}
      >
        {children}
      </Link>
    ),
    [activeStep, location.pathname, persistState, projectId, selectedAds, selectedPublics],
  );

  const handleOpenPipelinesModal = useCallback(
    (group: "ads" | "public") => () => {
      setNewPipelineGroup(group);
    },
    [],
  );

  const handleClosePipelinesModal = useCallback(() => {
    setNewPipelineGroup(undefined);
  }, []);

  const handleSubmitConfig = useCallback(() => {
    if (pipelineTypes && projectId) {
      const generator = pipelineType.id === EPipelineType.MERGE ? generateMergeConfig : generateMergePlanFactConfig;

      const config: any = pipelineTypes && selectedPipelines && generator(selectedPipelines, pipelineTypes);

      onConfigReady({
        config,
        name: pipelineName,
        project_id: projectId,
        source: pipelineType.id,
      });
    }
  }, [onConfigReady, pipelineName, pipelineType.id, pipelineTypes, projectId, selectedPipelines]);

  useUpdateEffect(() => {
    if (!projectsIsLoading && !projectId && projects && projects.length === 1) {
      setProjectId(projects[0].id);
    }
  }, [projectsIsLoading]);

  useUpdateEffect(() => {
    if (pipelineNameGenerated) {
      setPipelineName(pipelineNameGenerated);
    }
  }, [pipelineNameGenerated]);

  useMount(() => {
    const newPipeline = location.state?.newPipeline;
    const newPipelineType = newPipeline ? pipelineTypes?.[newPipeline.source] : undefined;

    if (newPipeline && newPipelineType) {
      if (newPipelineType.group === "ads") {
        setSelectedAds((state) => [...(state || []), newPipeline.id]);
      }

      if (newPipelineType.group === "public") {
        setSelectedPublics((state) => [...(state || []), newPipeline.id]);
      }
    }
  });

  return (
    <>
      <UiSimpleStepper
        num={activeStep + (startStepNum || 1)}
        title={steps[activeStep]?.label}
        onClickPrev={handleBack}
        onClickNext={activeStep >= steps.length - 1 ? handleSubmitConfig : handleNext}
        isDisabledNextBtn={!isNextButtonEnabled}
        isLoadingNextBtn={isLoading}
        textNextBtn={activeStep >= steps.length - 1 ? <Trans>Finish</Trans> : <Trans>Next</Trans>}
      >
        {steps[activeStep]?.id === "project" && <SelectProject value={projectId} onChange={setProjectId} />}

        {steps[activeStep]?.id === "dataSource" && projectId !== null && (
          <>
            <Box mb={3}>
              <UiSelect
                fullWidth
                isMulti
                isDropdownIndicatorDisabled
                isSearchable
                displayAddButton
                value={selectedAds}
                options={adsOptions}
                onChange={setSelectedAds}
                label={<Trans>Ads</Trans>}
                components={{
                  Menu: SelectboxMenu(handleOpenPipelinesModal("ads")),
                  MultiValueLabel: SelectboxChip,
                }}
              />
            </Box>

            <Box mb={3}>
              <UiSelect
                fullWidth
                isMulti
                isDropdownIndicatorDisabled
                isSearchable
                displayAddButton
                value={selectedPublics}
                options={publicOptions}
                onChange={setSelectedPublics}
                label={<Trans>Public</Trans>}
                components={{
                  Menu: SelectboxMenu(handleOpenPipelinesModal("public")),
                  MultiValueLabel: SelectboxChip,
                }}
              />
            </Box>
          </>
        )}

        {steps[activeStep]?.id === "name" && <UiTextField value={pipelineName} onChange={setPipelineName} fullWidth />}
      </UiSimpleStepper>

      <PipelineTypeList
        open={Boolean(newPipelineGroup)}
        handleClose={handleClosePipelinesModal}
        filter={pipelineTypeListFilter}
        ListItemComponent={renderListItemComponent}
      />
    </>
  );
};

export default CreateMerge;
