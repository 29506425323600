import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = css`
  display: block;
`;

export const container__inner_list = css`
  display: block;
`;

export const container__inner_grid = css`
  margin: -15px;
  display: flex;
  flex-wrap: wrap;

  :before {
    content: "";
    display: block;
    flex-grow: 1;
  }

  :after {
    content: "";
    display: block;
    flex-grow: 1;
  }
`;

export const item = (theme: Theme) => css`
  .css-${container__inner_list.name} & {
    margin-bottom: 30px;

    :last-child {
      margin-bottom: 0;
    }
  }

  .css-${container__inner_grid.name} & {
    padding: 15px;
    width: ${100 / 6}%;
    min-width: 230px;
    margin-left: auto;
    margin-right: auto;

    ${theme.breakpoints.down(1792)} {
      width: ${100 / 4}%;
    }

    ${theme.breakpoints.down(1332)} {
      width: ${100 / 3}%;
    }

    ${theme.breakpoints.down(1280)} {
      width: ${100 / 4}%;
    }

    ${theme.breakpoints.down(992)} {
      width: ${100 / 3}%;
    }

    ${theme.breakpoints.down(768)} {
      width: ${100 / 2}%;
    }

    ${theme.breakpoints.down(528)} {
      width: 100%;
    }

    .MuiPaper-root {
      height: 100%;
    }
  }
`;

export const item_create = css`
  justify-self: center;
  margin: 0 auto;
`;

export const loader = css`
  margin-bottom: -52px;
`;

export const loader_hidden = css`
  opacity: 0;
`;
