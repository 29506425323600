import { Trans } from "@lingui/macro";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { IconBolt, IconChevronRight, IconCreditCard, IconSubtask, IconUsers } from "@tabler/icons";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useMount, useUpdateEffect } from "react-use";
import { useAppContext } from "src/context/app/appContext";
import { EPipelineType } from "src/enums/pipeline";
import BillingIcon from "src/icons/BillingIcon";
import DataSourcesIcon from "src/icons/DataSourcesIcon";
import NavigationPanelIcon from "src/icons/NavigationPanelIcon";
import ProjectsIcon from "src/icons/ProjectsIcon";
import SupportIcon from "src/icons/SupportIcon";
import logoImage from "src/statics/logo.svg";
import cssc from "src/utils/emotionComposition";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import MenuItem from "./components/MenuItem";
import SubMenuItem from "./components/SubMenuItem";
import { classes } from "./index.css";

interface IProps {
  expanded: boolean;
}

const Sidebar: FC<IProps> = ({ expanded }) => {
  const location = useLocation<{ isNewUser?: boolean } | undefined>();
  const { state, updateState } = useAppContext();
  const theme = useTheme();
  const { data: user } = useQueryMe();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [sidebarMenuPrevOpened, setSidebarMenuPrevOpened] = useState(state.sidebar.expanded);
  const { data: pipelineTypes } = useQueryPipelineTypes();

  const handleOpen = useCallback(() => {
    updateState({ sidebar: { expanded: true } }, "ls");
  }, [updateState]);

  const handleClose = useCallback(() => {
    setSidebarMenuPrevOpened(state.sidebar.expanded);
    updateState({ sidebar: { expanded: false } }, "ls");
  }, [state.sidebar.expanded, updateState]);

  const expressAnalysisEnabled = useMemo(
    () =>
      pipelineTypes?.[EPipelineType.INST_OPEN_ACCOUNT] &&
      (!pipelineTypes[EPipelineType.INST_OPEN_ACCOUNT].onlyStaff || user?.is_staff),
    [pipelineTypes, user?.is_staff],
  );

  const handleClickChild = useCallback(() => {
    if (isMd) {
      handleClose();
    }
  }, [handleClose, isMd]);

  const handleToggleExpanded = useCallback(() => {
    updateState({ sidebar: { expanded: !state.sidebar.expanded } }, "ls");
  }, [state.sidebar.expanded, updateState]);

  useMount(() => {
    if (isMd) {
      handleClose();
    }
  });

  useUpdateEffect(() => {
    if (isMd) {
      handleClose();
    } else if (sidebarMenuPrevOpened) {
      handleOpen();
    }
  }, [isMd]);

  return (
    <>
      <Backdrop open={expanded} onClick={handleToggleExpanded} css={classes.backdrop} transitionDuration={500} />

      <SwipeableDrawer
        variant={isMd ? undefined : "permanent"}
        open={expanded}
        onClose={handleToggleExpanded}
        onOpen={handleToggleExpanded}
        css={cssc([classes.drawer, !expanded && classes.drawer_collapsed])}
      >
        <div onClick={handleClickChild}>
          {location.state?.isNewUser && <div css={classes.locker} />}

          <Grid container css={classes.head}>
            <Grid item xs>
              <img alt="DataFan" src={logoImage} css={classes.logo} />
            </Grid>

            <Grid item>
              <IconButton>
                <IconChevronRight />
              </IconButton>
            </Grid>
          </Grid>

          <List>
            <MenuItem exact to="/" title={<Trans>Reports</Trans>} icon={<NavigationPanelIcon />} />
          </List>

          <List>
            {expressAnalysisEnabled && (
              <MenuItem
                exact
                to={`/dataset-create/${EPipelineType.INST_OPEN_ACCOUNT}/`}
                title={<Trans>Competitor analysis</Trans>}
                icon={<IconBolt />}
              />
            )}

            <SubMenuItem
              title={<Trans>Connectors</Trans>}
              icon={<IconSubtask />}
              items={[
                { title: "Google Data Studio", to: "/connector/gds/" },
                { title: "Google Sheets", to: "/connector/spreadsheet/" },
                { title: "Power BI", to: "/connector/pbi/" },
                { title: "Excel", to: "/connector/excel/" },
              ]}
            />
          </List>

          <List>
            <MenuItem to="/credentials/" title={<Trans>Data sources</Trans>} icon={<DataSourcesIcon />} />
            <MenuItem to="/projects/" title={<Trans>Projects and teamwork</Trans>} icon={<ProjectsIcon />} />

            {user?.billing_enabled && <MenuItem to="/billing/" title={<Trans>My billing</Trans>} icon={<BillingIcon />} />}

            <MenuItem to="/affiliate/" title={<Trans>Affiliate program</Trans>} icon={<IconUsers />} />
          </List>

          <List>
            {!user?.isElamaUser && <MenuItem to="/tariffs/" title={<Trans>Tariffs</Trans>} icon={<IconCreditCard />} />}
            {!state.isIoZone && <MenuItem to="/support/" title={<Trans>Support</Trans>} icon={<SupportIcon />} />}
          </List>
        </div>

        <Typography variant="body1" component="div" css={[classes.foot, !expanded && classes.foot_collapsed]}>
          <div css={classes.foot__copyright}>
            &copy; {state.isIoZone ? "DataFan.io" : "DataFan.pro"} 2019 &mdash; {new Date().getFullYear()}
          </div>

          {!state.isIoZone && (
            <div css={classes.foot__disc}>
              Meta Platforms Inc. (соц.сети Facebook, Instagram) признана экстремистской, её деятельность запрещена на
              территории России.
            </div>
          )}
        </Typography>
      </SwipeableDrawer>
    </>
  );
};

export default Sidebar;
