import { t, Trans } from "@lingui/macro";
import {
  ClickAwayListener,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IconCheck, IconInfoCircle, IconUser } from "@tabler/icons";
import moment, { Moment } from "moment";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { InfiniteData } from "react-query";
import { Link as LinkRouter } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import UiButton from "src/components/UiButton";
import { EPipelineType } from "src/enums/pipeline";
import BellIcon from "src/icons/BellIcon";
import CrossIcon from "src/icons/CrossIcon";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import cssc from "src/utils/emotionComposition";
import useLogout from "src/utils/hoooks/useLogout";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryPipelines from "src/utils/queries/pipeline/useQueryPipelines";
import useQueryPipelinesAllGet from "src/utils/queries/pipeline/useQueryPipelinesAllGet";
import { IUseQueryInfiniteOutputWrapper } from "src/utils/queries/useQueryBase";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import * as classes from "./index.css";

const DELAY_FRESH_PIPELINES = 10000;

interface INotice {
  severity: "info" | "error" | "success";
  closable: boolean;
  title: string;
  message: string | ReactNode;
  time: Moment;
  id: string | number;
}

const Notifications: FC = () => {
  const theme = useTheme();
  const isReport = /^\/app\/report/.test(window.location.pathname);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const logout = useLogout();
  const [notices, setNotices] = useState<INotice[]>([]);
  const [needFreshIds, setNeedFreshIds] = useState<IPipeline["id"][]>([]);
  const [isOpen, setOpen] = useState(false);
  const [lastSeen, setLastSeen] = useState(moment());

  const { data: user } = useQueryMe();

  const { data: pipelineTypes } = useQueryPipelineTypes();

  const handleQueryPipelines = useCallback(
    (result: InfiniteData<IUseQueryInfiniteOutputWrapper<IPipelineExtended>>) => {
      const readyIds: IPipeline["id"][] = [];
      const newNotices: INotice[] = [];

      result.pages.forEach((page) => {
        page.results.forEach((pipeline) => {
          const pipelineType = pipelineTypes?.[pipeline.source];
          const entityTargetName = pipeline.streams[0].config_source.entity_target_name;
          const link = pipeline.report_links?.inhouse || pipeline.report_links?.gds;

          if (!pipelineType || [EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipelineType.id)) {
            return;
          }

          if (pipeline.data_status === "ready") {
            readyIds.push(pipeline.id);

            newNotices.push({
              closable: false,
              id: pipeline.id,
              message: (
                <>
                  {pipelineType.group === "ads" ? (
                    <Trans>Data collection for ad account</Trans>
                  ) : (
                    <Trans>Data collection for public</Trans>
                  )}{" "}
                  <Trans>
                    <strong>{entityTargetName}</strong> completed.
                  </Trans>
                  {link && (
                    <>
                      {" "}
                      <Trans>
                        The report can be viewed <Link href={link as string}>here</Link>
                      </Trans>
                    </>
                  )}
                </>
              ),
              severity: "success",
              time: moment(),
              title: t`Data collected`,
            });
          } else if (pipeline.data_status === "ready_partly") {
            if (!notices.some(({ id }) => id === pipeline.id)) {
              newNotices.push({
                closable: false,
                id: pipeline.id,
                message: (
                  <>
                    {pipelineType.group === "ads" ? (
                      <Trans>The first data of ad account</Trans>
                    ) : (
                      <Trans>The first data of public</Trans>
                    )}{" "}
                    <Trans>
                      <strong>{entityTargetName}</strong> has been collected!
                    </Trans>{" "}
                    <Trans>
                      The report can be viewed <Link href={link as string}>here</Link>
                    </Trans>
                  </>
                ),
                severity: "success",
                time: moment(),
                title: t`Data collected`,
              });
            }
          }
        });
      });

      setNotices([...newNotices, ...notices]);

      if (newNotices.length > 0 && !isMobile) {
        setOpen(true);
      }

      setNeedFreshIds((state) => state.filter((id) => !readyIds.includes(id)));
    },
    [isMobile, notices, pipelineTypes],
  );

  useQueryPipelines(
    {
      enabled: needFreshIds.length > 0,
      onSuccess: handleQueryPipelines,
      refetchInterval: needFreshIds.length > 0 ? DELAY_FRESH_PIPELINES : false,
    },
    {
      id__in: needFreshIds,
      ordering: "-id",
    },
  );

  useQueryPipelinesAllGet({
    onSuccess: (pipelines) => {
      const inProgressIds: IPipeline["id"][] = [];

      pipelines.forEach((pipeline) => {
        if (["ready_partly", "in_progress_first_run"].includes(pipeline.data_status)) {
          inProgressIds.push(pipeline.id);
        }
      });

      setNeedFreshIds(inProgressIds);
    },
  });

  const newNoticesNum = useMemo(() => notices.filter(({ time }) => time > lastSeen).length, [lastSeen, notices]);

  const handleToggle = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useUpdateEffect(() => {
    if (!isOpen) {
      setLastSeen(moment());
    }
  }, [isOpen]);

  if (isReport) {
    return null;
  }

  return (
    <>
      <div css={classes.btnWrapper}>
        {isMobile ? (
          <IconButton css={classes.avatar} onClick={handleToggle}>
            <IconUser />
          </IconButton>
        ) : (
          <UiButton
            variant="outlined"
            color="neutral"
            disableElevation={true}
            css={cssc([isOpen && classes.btn_active, notices.length === 0 && classes.btn_disabled])}
            startIcon={<BellIcon css={cssc([classes.btn__icn, newNoticesNum > 0 && classes.btn__icn_animated])} />}
            onClick={handleToggle}
            disabled={!notices.length}
          />
        )}

        {newNoticesNum > 0 && <div css={classes.num}>{newNoticesNum}</div>}

        {isOpen && (
          <ClickAwayListener onClickAway={handleClose}>
            <div css={classes.popover}>
              <div css={classes.popover__inn(isReport)}>
                <Paper css={classes.popover__paper}>
                  {isMobile && (
                    <div css={classes.mobileHead}>
                      <IconButton onClick={handleClose}>
                        <CrossIcon />
                      </IconButton>

                      {user?.email && (
                        <Typography variant="body2" css={classes.mobileHead__email}>
                          {user.email}
                        </Typography>
                      )}
                    </div>
                  )}

                  {notices.map((notice, idx) => (
                    <div
                      key={idx}
                      css={cssc([
                        classes.notice,
                        notice.severity === "success" && classes.notice_success,
                        notice.severity === "error" && classes.notice_error,
                      ])}
                      onClick={handleClose}
                    >
                      <div css={classes.notice__head}>
                        <div css={classes.notice__head__icn}>
                          {notice.severity === "success" && <IconCheck />}
                          {notice.severity === "error" && <IconInfoCircle />}
                          {notice.severity === "info" && <IconInfoCircle />}
                        </div>
                        <div css={classes.notice__head__title}>{notice.title}</div>
                      </div>

                      <Stack css={classes.notice__top} direction="row" spacing={0.8}>
                        {notice.time > lastSeen && <div css={classes.notice__sticker}>new</div>}

                        {notice.closable && (
                          <div css={classes.notice__cross}>
                            <CrossIcon fontSize="inherit" />
                          </div>
                        )}
                      </Stack>

                      <Typography css={classes.notice__text} variant="body2">
                        {notice.message}
                      </Typography>
                    </div>
                  ))}

                  {isMobile && (
                    <List css={classes.mobileMenu} onClick={handleClose}>
                      <ListItem disablePadding>
                        <ListItemButton component={LinkRouter} to="/profile/">
                          <ListItemText primary={<Trans>User settings</Trans>} />
                        </ListItemButton>
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemButton onClick={logout}>
                          <ListItemText primary={<Trans>Sign Out</Trans>} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  )}
                </Paper>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
};

export default Notifications;
