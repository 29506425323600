import { t, Trans } from "@lingui/macro";
import { Stack, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { IconCircleX, IconMailForward } from "@tabler/icons";
import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Loader from "src/components/Loader";
import UiButton from "src/components/UiButton";
import UiInfinityTable from "src/components/UiInfinityTable";
import UiSelect from "src/components/UiSelect";
import { EProjectRole } from "src/enums/project";
import { popupAdd } from "src/redux";
import { IProjectExtended, IProjectInvite, IProjectMember } from "src/types/project";
import { IUser } from "src/types/user";
import { calculateColor } from "src/utils";
import cssc from "src/utils/emotionComposition";
import getRoleDescription from "src/utils/getRoleDescription";
import useQueryProjectInviteDelete from "src/utils/queries/project/useQueryProjectInviteDelete";
import useQueryProjectInviteResendPost from "src/utils/queries/project/useQueryProjectInviteResendPost";
import useQueryProjectInvitesGet from "src/utils/queries/project/useQueryProjectInvitesGet";
import useQueryProjectMemberDelete from "src/utils/queries/project/useQueryProjectMemberDelete";
import useQueryProjectMemberPatch from "src/utils/queries/project/useQueryProjectMemberPatch";
import useQueryProjectMembersGet from "src/utils/queries/project/useQueryProjectMembersGet";

import * as classes from "./index.css";

interface IProps {
  project: IProjectExtended;
}

type ITableRowInvite = {
  id: IProjectInvite["id"];
  email: string;
  isInvite: true;
  role: EProjectRole;
};

type ITableRowMember = {
  email: string | undefined;
  isInvite: false;
  member_id: number;
  role: EProjectRole;
  user_id: number | undefined;
};

const Members: FC<IProps> = ({ project }) => {
  const dispatch = useDispatch();
  const {
    data: members,
    isLoading: membersIsLoading,
    isSuccess: membersIsLoaded,
  } = useQueryProjectMembersGet(project.id, {
    refetchOnMount: false,
  });
  const { data: invites, isLoading: invitesIsLoading, isSuccess: invitesIsLoaded } = useQueryProjectInvitesGet(project.id);
  const {
    mutate: memberPatch,
    isLoading: memberPatchIsLoading,
    variables: memberPatchVars,
  } = useQueryProjectMemberPatch({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Member successfully edited`,
        }),
      );
    },
  });
  const {
    mutate: memberDelete,
    isLoading: memberDeleteIsLoading,
    variables: memberDeleteVars,
  } = useQueryProjectMemberDelete({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Member successfully deleted`,
        }),
      );
    },
  });
  const {
    mutate: inviteResend,
    isLoading: inviteResendIsLoading,
    variables: inviteResendVars,
  } = useQueryProjectInviteResendPost({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`The invitation has been sent successfully`,
        }),
      );
    },
  });
  const {
    mutate: inviteWithdraw,
    isLoading: inviteWithdrawIsLoading,
    variables: inviteWithdrawVars,
  } = useQueryProjectInviteDelete({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Invitation withdrawn`,
        }),
      );
    },
  });

  const roleOptions = useMemo(
    () => [
      { desc: getRoleDescription(EProjectRole.ADMIN), label: t`Admin`, value: EProjectRole.ADMIN },
      { desc: getRoleDescription(EProjectRole.USER), label: t`User`, value: EProjectRole.USER },
    ],
    [],
  );

  const handleChangeRole = useCallback(
    (user_id: IUser["id"], member_id: IProjectMember["id"]) => (role: EProjectRole | null) => {
      if (role) {
        memberPatch({
          id: member_id,
          project: project.id,
          role,
          user: user_id,
        });
      }
    },
    [memberPatch, project.id],
  );

  const handleDeleteMember = useCallback(
    (memberId: IProjectMember["id"]) => () => {
      memberDelete({
        id: memberId,
        projectId: project.id,
      });
    },
    [memberDelete, project],
  );

  const handleWithdrawInvite = useCallback(
    (id: IProjectInvite["id"]) => () => {
      inviteWithdraw({ id: id, projectId: project.id });
    },
    [inviteWithdraw, project.id],
  );

  const handleResendInvite = useCallback(
    (invite: ITableRowInvite) => () => {
      inviteResend({
        id: invite.id,
        target_project: project.id,
      });
    },
    [inviteResend, project.id],
  );

  const concatMembers = useMemo(
    () => [
      ...(invites || []).map(
        (invite) =>
          ({
            email: invite.invited_email,
            id: invite.id,
            isInvite: true,
            member_id: undefined,
            role: invite.target_role,
            user_id: undefined,
          } as ITableRowInvite),
      ),
      ...(members || []).map(
        (member) =>
          ({
            email: member.user.email,
            isInvite: false,
            member_id: member.id,
            role: member.role,
            user_id: member.user.id,
          } as ITableRowMember),
      ),
    ],
    [invites, members],
  );

  return useMemo(() => {
    if (!membersIsLoaded || !invitesIsLoaded) {
      return <Loader />;
    }

    return (
      <div css={classes.table}>
        <UiInfinityTable
          rows={concatMembers}
          columns={[
            {
              css: classes.table__cell,
              getCellValue: ({ email, isInvite, role }: typeof concatMembers[number]) => (
                <Stack direction="row" spacing={1.6} alignItems="center">
                  <Avatar
                    style={{
                      backgroundColor: calculateColor((email || "guest").substring(0, 2)),
                    }}
                    css={classes.avatar}
                  >
                    {(email || "guest").substring(0, 2)}
                  </Avatar>

                  <div css={classes.emailStatus}>
                    {email && <Typography css={classes.email}>{email}</Typography>}

                    {(isInvite || role === EProjectRole.OWNER) && (
                      <Typography
                        css={cssc([
                          classes.status,
                          role === EProjectRole.OWNER && classes.status_me,
                          isInvite && classes.status_invite,
                        ])}
                      >
                        {role === EProjectRole.OWNER && <Trans>It is you</Trans>}
                        {isInvite && <Trans>The invitation to DataFan has been sent</Trans>}
                      </Typography>
                    )}
                  </div>
                </Stack>
              ),
              name: "user",
              title: t`User`,
            },

            {
              css: cssc([classes.table__cell, classes.table__cell_role]),
              getCellValue: (row: typeof concatMembers[number]) => (
                <div css={classes.select}>
                  {row.role === EProjectRole.OWNER || row.isInvite ? (
                    <UiSelect
                      fullWidth
                      value={row.role}
                      options={[...roleOptions, { label: t`Owner`, value: EProjectRole.OWNER }]}
                      isDisabled={true}
                    />
                  ) : (
                    <UiSelect
                      fullWidth
                      value={row.role}
                      options={roleOptions}
                      onChange={handleChangeRole(row.user_id, row.member_id)}
                      isLoading={memberPatchIsLoading && memberPatchVars?.id === row.member_id}
                    />
                  )}
                </div>
              ),
              name: "role",
              title: t`Role`,
            },

            {
              css: classes.table__cell,
              getCellValue: (row: typeof concatMembers[number]) => {
                if (row.role === EProjectRole.OWNER) {
                  return null;
                }

                return (
                  <Stack direction="row" spacing={2} justifyContent="flex-end" css={classes.actionsStack}>
                    <div>
                      {row.isInvite && (
                        <Tooltip title={t`Resend the invitation`}>
                          <div>
                            <UiButton
                              disableElevation
                              variant="outlined"
                              startIcon={<IconMailForward />}
                              onClick={handleResendInvite(row)}
                              loading={inviteResendIsLoading && inviteResendVars?.id === row.id}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    {!row.isInvite ? (
                      <UiButton
                        onClick={handleDeleteMember(row.member_id)}
                        variant="text"
                        startIcon={<IconCircleX />}
                        css={classes.btnDelete}
                        loading={memberDeleteIsLoading && memberDeleteVars?.id === row.member_id}
                      />
                    ) : (
                      <UiButton
                        onClick={handleWithdrawInvite(row.id)}
                        variant="text"
                        startIcon={<IconCircleX />}
                        css={classes.btnDelete}
                        loading={inviteWithdrawIsLoading && inviteWithdrawVars?.id === row.id}
                      />
                    )}
                  </Stack>
                );
              },
              name: "actions",
              title: " ",
            },
          ]}
          loading={membersIsLoading || invitesIsLoading}
          enableSorting={true}
          totalRows={concatMembers?.length}
          paperElevation={0}
          tableProps={{
            columnExtensions: [{ columnName: "role" }, { align: "right", columnName: "actions", width: 160 }],
            rowCss: classes.table__row,
          }}
        />
      </div>
    );
  }, [
    concatMembers,
    handleChangeRole,
    handleDeleteMember,
    handleResendInvite,
    handleWithdrawInvite,
    inviteResendIsLoading,
    inviteResendVars?.id,
    inviteWithdrawIsLoading,
    inviteWithdrawVars?.id,
    invitesIsLoaded,
    invitesIsLoading,
    memberDeleteIsLoading,
    memberDeleteVars?.id,
    memberPatchIsLoading,
    memberPatchVars?.id,
    membersIsLoaded,
    membersIsLoading,
    roleOptions,
  ]);
};

export default Members;
