import { Collapse, MenuItem } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { FC, MouseEvent, ReactChild, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import UiMenu from "src/components/UiMenu";
import { useAppContext } from "src/context/app/appContext";

import * as classes from "./index.css";

interface IProps {
  title: string | ReactChild;
  icon: ReactChild;
  items: Array<{
    title: string | ReactChild;
    to: string;
  }>;
}

const SubMenuItem: FC<IProps> = ({ title, icon, items }) => {
  const { state } = useAppContext();
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLLIElement | null>();
  const [isOpen, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleOpen = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();

      if (!state.sidebar.expanded) {
        setDropdownAnchorEl(e.currentTarget);
      } else {
        setOpen((state) => !state);
      }
    },
    [state.sidebar.expanded],
  );

  const handleCloseDropdown = useCallback(() => {
    setDropdownAnchorEl(null);
  }, []);

  const handleTooltipMouseEnter = useCallback(() => {
    setShowTooltip(!state.sidebar.expanded);
  }, [state.sidebar.expanded]);

  const handleTooltipMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <>
      <ListItem button={true as any} onClick={handleToggleOpen}>
        <Tooltip
          open={showTooltip}
          followCursor={false}
          disableHoverListener={true}
          disableTouchListener={true}
          placement="right"
          title={title}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <ListItemIcon>{icon}</ListItemIcon>
        </Tooltip>

        <ListItemText
          primaryTypographyProps={{
            color: "inherit",
            variant: "subtitle1",
          }}
          primary={title}
        />
        {state.sidebar.expanded && (isOpen ? <IconChevronUp /> : <IconChevronDown />)}
      </ListItem>

      <UiMenu
        anchorEl={dropdownAnchorEl}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        PaperProps={{
          sx: { marginLeft: 2 },
        }}
        title={title}
      >
        {items.map(({ to, title }) => (
          <MenuItem component={NavLink} to={to} css={classes.ddItem} key={to}>
            {title}
          </MenuItem>
        ))}
      </UiMenu>

      <Collapse in={state.sidebar.expanded && isOpen}>
        {items.map(({ to, title }) => (
          <ListItem button component={NavLink} to={to} key={to}>
            <ListItemText
              primaryTypographyProps={{
                color: "inherit",
                variant: "body2",
              }}
              primary={title}
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

export default SubMenuItem;
