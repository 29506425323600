import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import ZoomImg from "src/components/ZoomImg";

import * as classes from "./index.css";

interface IProps {
  steps: Array<{
    title?: string | ReactNode;
    text?: string | ReactNode;
    picture?: string;
  }>;
}

const ConnectorInstruction: FC<IProps> = ({ steps }) => (
  <div css={classes.steps}>
    {steps.map((step, idx) => (
      <div css={classes.step} key={idx}>
        <div css={classes.step__content}>
          {step.title && (
            <Typography variant="h3" mb={2}>
              {step.title}
            </Typography>
          )}

          {step.text && <Typography mb={3}>{step.text}</Typography>}
        </div>

        {step.picture && (
          <div>
            <ZoomImg css={classes.step__pic} src={step.picture} alt="" />
          </div>
        )}
      </div>
    ))}
  </div>
);

export default ConnectorInstruction;
