import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { removeTokens } from "src/utils/tokens";

export default function useLogout() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const history = useHistory();

  return () => {
    removeTokens();
    queryClient.clear();
    history.replace({
      pathname: "/auth/signin/",
      state: {
        next: location.pathname + location.search,
      },
    });
  };
}
