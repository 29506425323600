export enum EPipelineType {
  VK_ADS = 1,
  VK_POST = 2,
  VK_COMMUNITY = 3,
  FB_PAGE = 4,
  INST_BA = 5,
  FB_ADS = 6,
  INST_MEDIA = 7,
  GGL = 9,
  OK_COMMUNITY = 10,
  OK_POST = 11,
  FB_POST = 12,
  VK_COMMUNITY_POST = 13,
  FB_ADS_SMALL = 14,
  VK_ADS_SMALL = 15,
  MERGE = 16,
  FB_PAGE_POST = 17,
  INST_BA_MEDIA = 18,
  MYTARGET = 19,
  YA_METRIKA = 20,
  YA_DIRECT = 30,
  OK_COMMUNITY_POST = 21,
  MERGE_PLAN_FACT = 22,
  INST_OPEN_ACCOUNT = 26,
  TIKTOK = 29,
  TELEGRAM_CHANNEL = 31,
  TELEGRAM_POST = 32,
  TELEGRAM_CHANNEL_POST = 33,
  YA_DIRECT_CAMPAIGN = 34,
}

export enum EPipelineSubType {
  ADS = "ADS",
  SINGLEPIPELINE = "SINGLEPIPELINE",
  MULTIPIPELINE = "MULTIPIPELINE",
  MERGE = "MERGE",
}

export enum EPipelineStatus {
  DATA_COLLECTION = 0,
  IS_COMPLETED = 1,
  DELETED = 2,
  ARCHIVED = 3,
  CREDENTIAL_LOST = 4,
}

export enum EPipelineMedia {
  VK = "vk",
  FB = "facebook",
  GOOGLE = "google",
  OK = "ok",
  INSTAGRAM = "instagram",
  MYTARGET = "mytarget",
  YA = "yandex",
  TIKTOK = "tiktok",
  DATAFAN = "datafan",
  TELEGRAM = "telegram",
}

export enum EPipelineReportTokenStatus {
  DISABLE = 0,
  ENABLE = 1,
}
