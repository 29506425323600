import { t, Trans } from "@lingui/macro";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import getPipelineIdsForUpdate from "src/app/Dashboard/utils/getPipelineIdsForUpdate";
import { HeaderDynamicContent } from "src/components/Header";
import Loader from "src/components/Loader";
import ModalPipelineEdit from "src/components/ModalPipelineEdit";
import PipelineTypeList from "src/components/PipelineTypeList";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import { useAppContext } from "src/context/app/appContext";
import { IAppState } from "src/context/app/appContextProvider";
import AppsIcon from "src/icons/AppsIcon";
import { popupAdd } from "src/redux";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import { IProject } from "src/types/project";
import cssc from "src/utils/emotionComposition";
import { normalizeObjectsArray } from "src/utils/normalizeObjectsArray";
import useQueryPipelines from "src/utils/queries/pipeline/useQueryPipelines";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import Card from "./components/Card";
import CreateCard from "./components/CreateCard";
import Filter, { IFilterForm } from "./components/Filter";
import ViewControls from "./components/ViewControls";
import * as classes from "./index.css";

const PIPELINES_LIMIT = 12;

interface ILocationState {
  pipelineEditId?: IPipeline["id"];
  projectId?: IProject["id"];
}

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const { state, updateState } = useAppContext();
  const theme = useTheme();
  const location = useLocation<ILocationState | undefined>();
  const [editPipeline, setEditPipeline] = useState<IPipeline["id"] | null>(location.state?.pipelineEditId || null);
  const { refetch: refetchMe } = useQueryMe({ enabled: false });
  const { data: projects, refetch: refetchProjects } = useQueryProjects({ enabled: false });
  const { ref: loaderRef, inView: loaderInView } = useInView();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams<{ screen?: string | undefined }>();
  const history = useHistory();
  const [filterForm, setFilterForm] = useState<IFilterForm>({
    name: null,
    project_id: location.state?.projectId ? [location.state.projectId] : state.dashboard.currentProjects || null,
    source: null,
  });

  const handleChangeView = useCallback(
    (view: IAppState["dashboard"]["view"]) => {
      updateState({ dashboard: { view } }, "ls");
    },
    [updateState],
  );

  const handleChangeFilter = useCallback<ComponentProps<typeof Filter>["onChange"]>(
    (key, value) => {
      if (key === "project_id") {
        updateState({ dashboard: { currentProjects: value as number[] } });
      }

      setFilterForm((state) => ({
        ...state,
        [key]: value,
      }));
    },
    [updateState],
  );

  const [fetchParams, setFetchParams] = useState({
    filtered: filterForm,
    limit: PIPELINES_LIMIT - 1,
    offset: 0,
  });

  const [needUpdateIds, setNeedUpdateIds] = useState<number[]>([]);

  const [isOpenPipelinesModal, setOpenPipelinesModal] = useState(params?.screen === "/connect");

  const {
    data: pipelinesData,
    refetch,
    fetchNextPage,
    remove,
    hasNextPage,
  } = useQueryPipelines(
    {
      getNextPageParam: ({ next }) => (next ? next.replace(/limit=(\d*)/g, `limit=${PIPELINES_LIMIT}`) : false),
      keepPreviousData: true,
      onSuccess: (data) => {
        setNeedUpdateIds(getPipelineIdsForUpdate(data));
      },
    },
    fetchParams,
  );

  const { data: pipelinesUpdatedData } = useQueryPipelines(
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setNeedUpdateIds(getPipelineIdsForUpdate(data));
      },
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
    },
    {
      id__in: needUpdateIds,
    },
  );

  const pipelinesUpdatedNormalized = useMemo(
    () =>
      pipelinesUpdatedData?.pages?.reduce(
        (acc, page) => {
          page.results.forEach((pipeline) => {
            acc[pipeline.id] = pipeline;
          });

          return acc;
        },
        {} as {
          [key in IPipelineExtended["id"]]: IPipelineExtended;
        },
      ),
    [pipelinesUpdatedData?.pages],
  );

  const pipelines = useMemo(
    () =>
      pipelinesData?.pages?.reduce((acc, page) => {
        acc = [...acc, ...page.results.map((pipeline) => pipelinesUpdatedNormalized?.[pipeline.id] || pipeline)];

        return acc;
      }, [] as IPipelineExtended[]),
    [pipelinesData?.pages, pipelinesUpdatedNormalized],
  );

  const projectsNormalized = useMemo(() => projects && normalizeObjectsArray(projects, "id"), [projects]);

  useUpdateEffect(() => {
    if (loaderInView && hasNextPage) {
      fetchNextPage();
    }
  }, [loaderInView]);

  useUpdateEffect(() => {
    setFetchParams((state) => ({
      ...state,
      filtered: filterForm,
      offset: 0,
    }));
  }, [filterForm]);

  useUpdateEffect(() => {
    remove();
    refetch();
  }, [fetchParams.filtered]);

  useUpdateEffect(() => {
    handleChangeView("list");
  }, [isXs]);

  const handleActionSuccess = useCallback<ComponentProps<typeof Card>["onActionSuccess"]>(
    (action, pipelineId) => {
      if (["archive", "unarchive", "delete"].includes(action)) {
        refetchMe();
        refetchProjects();
      }

      if (["archive", "unarchive", "delete"].includes(action)) {
        refetch({
          refetchPage: (lastPage, index) =>
            Boolean(pipelinesData?.pages[index].results.some((pipeline) => pipeline.id === pipelineId)),
        });
      }

      if (action === "restart") {
        dispatch(
          popupAdd({
            text: t`Pipeline successfully restarted`,
          }),
        );
      }

      if (action === "delete") {
        dispatch(
          popupAdd({
            text: t`Pipeline successfully deleted`,
          }),
        );
      }

      setNeedUpdateIds((state) => [...state, pipelineId]);
    },
    [dispatch, pipelinesData?.pages, refetch, refetchMe, refetchProjects],
  );

  const handleOpenPipelinesModal = useCallback(() => {
    setOpenPipelinesModal(true);
  }, []);

  const handleClosePipelinesModal = useCallback(() => {
    history.replace("/");
    setOpenPipelinesModal(false);
  }, [history]);

  const handleOpenEditPipeline = useCallback((pipelineId: IPipeline["id"]) => {
    setEditPipeline(pipelineId);
  }, []);

  const handleCloseEditPipeline = useCallback(() => {
    setEditPipeline(null);
  }, []);

  const handleEditSuccess = useCallback(() => {
    if (editPipeline) {
      setEditPipeline(null);

      refetch({
        refetchPage: (lastPage, index) =>
          Boolean(pipelinesData?.pages[index].results.some((pipeline) => pipeline.id === editPipeline)),
      });
    }
  }, [editPipeline, pipelinesData?.pages, refetch]);

  return (
    <>
      <HeaderDynamicContent>
        <UiButton
          onClick={handleOpenPipelinesModal}
          startIcon={<AppsIcon />}
          HiddenTextProps={{
            smDown: true,
          }}
          sx={{ ml: "auto" }}
        >
          <Trans>Connect Public/Ads</Trans>
        </UiButton>
      </HeaderDynamicContent>

      <Section>
        <SectionHead title={<Trans>My publics and ad accounts</Trans>}>
          <Box maxWidth={1464} position="relative" zIndex={3} m="auto">
            <Grid container spacing={2}>
              <Grid item xs>
                <Filter values={filterForm} onChange={handleChangeFilter} />
              </Grid>

              <Grid item hidden={isXs}>
                <ViewControls value={state.dashboard.view} onChange={handleChangeView} />
              </Grid>
            </Grid>
          </Box>
        </SectionHead>

        <div css={classes.container}>
          <div
            css={
              (state.dashboard.view === "list" && classes.container__inner_list) ||
              (state.dashboard.view === "grid" && classes.container__inner_grid)
            }
          >
            <div css={cssc([classes.item, classes.item_create])}>
              <CreateCard view={state.dashboard.view} onClick={handleOpenPipelinesModal} />
            </div>

            {pipelines?.map((pipeline) => (
              <div css={classes.item} key={pipeline.id}>
                <Card
                  elevation={5}
                  view={state.dashboard.view}
                  pipeline={pipeline}
                  project={projectsNormalized?.[pipeline.project]}
                  onActionSuccess={handleActionSuccess}
                  onEditPipeline={handleOpenEditPipeline}
                  showProjectName={Boolean(projects && projects.length > 1)}
                />
              </div>
            ))}
          </div>
        </div>

        <div ref={loaderRef} css={[classes.loader, !hasNextPage && classes.loader_hidden]}>
          <Loader />
        </div>
      </Section>

      <PipelineTypeList open={isOpenPipelinesModal} handleClose={handleClosePipelinesModal} />

      <ModalPipelineEdit pipelineId={editPipeline} onClose={handleCloseEditPipeline} onSuccess={handleEditSuccess} />
    </>
  );
};

export default Dashboard;
