import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const createForm = (theme: Theme) => css`
  ${theme.breakpoints.down("lg")} {
    order: 1;
  }
`;

export const createForm_empty = css`
  max-width: 800px;
  margin: 0 auto;
`;

export const description = (theme: Theme) => css`
  ${theme.breakpoints.down("lg")} {
    order: 2;
  }
`;
