import { t } from "@lingui/macro";
import { EProgressType } from "src/enums/progress";
import { IProgress } from "src/types/progress";

export default function aggregateProgressValue(progress: IProgress) {
  const item = progress.items[progress.current_item_sign];

  if (item.type === EProgressType.PERCENT) {
    return `${item.total ? Math.round((100 / item.total) * item.current) : 100}%`;
  } else if (item.type === EProgressType.COUNT) {
    return `${item.current}\xa0${t`of`}\xa0${item.total}`;
  } else if (item.type === EProgressType.TIMER) {
    return item.total - item.current;
  } else if (item.type === EProgressType.SIMPLE_COUNT) {
    return `${item.current}`;
  } else {
    return "";
  }
}
