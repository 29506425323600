import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const classes = {
  avatar: css`
    background: #e2e2e2;
    cursor: pointer;
    width: 46px;
    height: 46px;
    color: #000000;
    position: static;

    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `,
  container: css`
    position: relative;
  `,
  email: (theme: Theme) => css`
    max-width: 300px;
    overflow: hidden;
    transition: max-width 150ms;

    .MuiTypography-root {
      padding-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }

    ${theme.breakpoints.down("lg")} {
      max-width: 0;
    }
  `,
  email_hidden: css`
    max-width: 0;
  `,
  mail: css`
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  menu: css`
    .MuiPaper-root {
      max-width: 300px;
    }

    margin-top: -5px;
  `,
  menuEmail: css`
    border-bottom: 1px solid #e3e3e3;
    opacity: 0.6 !important;
    font-weight: 400 !important;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  `,
};
