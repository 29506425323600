import { useCallback, useRef, useState } from "react";
import { useUnmount, useUpdateEffect } from "react-use";

interface IParams<Message> {
  single?: boolean;
  onMessage?: (data: Message) => void;
  onClose?: (popup: Window) => void;
  onOpen?: () => void;
  windowProps?: {
    target?: "_self" | "_blank" | "_parent" | "_top";
    width?: number;
    height?: number;
  };
}

const CHECK_DELAY = 300;

export default function useNativePopup<Message = any>(params?: IParams<Message>) {
  const [openedPopups, setOpenedPopups] = useState<Window[]>([]);
  const messageListeners = useRef(new Map());
  const checkerId = useRef(0);

  const handleMessage = useCallback(
    (popup: Window) => (event: MessageEvent<Message>) => {
      if (params?.onMessage && event.source === popup) {
        params.onMessage(event.data);
      }
    },
    [params],
  );

  const handleClose = useCallback(
    (popup: Window) => {
      setOpenedPopups((state) => state.filter((p) => p !== popup));

      window.removeEventListener("message", messageListeners.current.get(popup));
      messageListeners.current.delete(popup);
      params?.onClose?.(popup);
    },
    [params],
  );

  const handleCheckPopup = useCallback(
    (popups: Window[]) => () => {
      const opened: Window[] = [];

      popups.forEach((popup) => {
        if (popup.closed) {
          handleClose(popup);
        } else {
          opened.push(popup);
        }
      });

      checkerId.current = window.setTimeout(handleCheckPopup(opened), CHECK_DELAY);
    },
    [handleClose],
  );

  const open = useCallback(
    (url?: string) => {
      if (params?.onOpen) {
        params.onOpen();
      }

      if (params?.single && openedPopups.length) {
        return openedPopups[0].focus();
      }

      const { target = "_blank", width, height } = params?.windowProps || {};

      const windowFeatures = typeof width !== "undefined" &&
        typeof height !== "undefined" && {
          height,
          left: window.screen.width / 2 - width / 2,
          top: window.screen.height / 2 - height / 2,
          width,
        };

      const windowFeaturesStr = (Object.keys(windowFeatures) as Array<keyof typeof windowFeatures>)
        .reduce((acc, key) => {
          acc.push(`${key}=${windowFeatures[key]}`);

          return acc;
        }, [] as string[])
        .join(",");

      const popup = window.open(url, target, windowFeaturesStr);

      if (popup) {
        const listener = handleMessage(popup);

        window.addEventListener("message", listener);

        messageListeners.current.set(popup, listener);

        setOpenedPopups((state) => [...state, popup]);
      }

      return popup;
    },
    [handleMessage, openedPopups, params],
  );

  useUpdateEffect(() => {
    window.clearTimeout(checkerId.current);

    if (openedPopups.length) {
      checkerId.current = window.setTimeout(handleCheckPopup(openedPopups), CHECK_DELAY);
    } else {
      checkerId.current = 0;
    }
  }, [openedPopups]);

  useUnmount(() => {
    openedPopups.forEach((popup) => {
      popup.close();
    });
  });

  return { open, openedPopups };
}
