import { Trans } from "@lingui/macro";
import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { useMount, useUpdateEffect } from "react-use";
import Loader from "src/components/Loader";
import { IPipeline } from "src/types/pipeline";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

interface IProps {
  value?: "create" | "select";
  onChange: (value: "create" | "select") => void;
  source: IPipeline["source"] | null;
  isMerge?: boolean;
}

const SelectMode: FC<IProps> = ({ value, onChange, source, isMerge }) => {
  const { data: projects, isLoading: projectsIsLoading, isFetched: projectsIsFetched } = useQueryProjects();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === "create" || value === "select") {
        onChange(value);
      }
    },
    [onChange],
  );

  const hasAvailablePipelines = useMemo(
    () =>
      Boolean(
        projects
          ?.map(({ pipelines }) => (source === null ? pipelines : pipelines.filter((pipeline) => pipeline.source === source)))
          ?.flat()?.length,
      ),
    [projects, source],
  );

  useUpdateEffect(() => {
    if (projectsIsFetched && !value) {
      onChange(hasAvailablePipelines ? "select" : "create");
    }
  }, [hasAvailablePipelines, projectsIsFetched]);

  useMount(() => {
    if (projectsIsFetched && !value) {
      onChange(hasAvailablePipelines ? "select" : "create");
    }
  });

  if (projectsIsLoading || !value) {
    return <Loader />;
  }

  return (
    <RadioGroup onChange={handleChange} value={value}>
      <FormControlLabel
        value="select"
        disabled={!hasAvailablePipelines}
        control={<Radio />}
        label={
          isMerge ? (
            <Trans>Apply already created Merge DataSet</Trans>
          ) : (
            <Trans>Use previously connected public / ad account</Trans>
          )
        }
      />

      <FormControlLabel
        value="create"
        control={<Radio />}
        label={isMerge ? <Trans>Compile new Merge DataSet</Trans> : <Trans>Connect a new public / ad account</Trans>}
      />
    </RadioGroup>
  );
};

export default SelectMode;
