import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const list = css`
  max-width: 1420px;
  margin: auto;
`;

export const list__item = (theme: Theme) => css`
  display: flex;
  flex-grow: 1;
  flex-basis: ${100 / 4}%;
  max-width: ${100 / 4}%;

  ${theme.breakpoints.down(1792)} {
    flex-basis: ${100 / 3}%;
    max-width: ${100 / 3}%;
  }

  ${theme.breakpoints.down(1500)} {
    flex-basis: ${100 / 2}%;
    max-width: ${100 / 2}%;
  }

  ${theme.breakpoints.down(1280)} {
    flex-basis: ${100 / 3}%;
    max-width: ${100 / 3}%;
  }

  ${theme.breakpoints.down(1160)} {
    flex-basis: ${100 / 2}%;
    max-width: ${100 / 2}%;
  }

  ${theme.breakpoints.down(768)} {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const instruction = (theme: Theme) => css`
  border-radius: 8px;
  background: ${theme.palette.cyan.main};
  margin-top: 60px;
  padding: 60px;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
