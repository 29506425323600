import { FC, useState } from "react";
import { createPortal } from "react-dom";
import { useMount } from "react-use";
import { HEADER_DYNAMIC_ID } from "src/components/Header/index";

const DynamicContent: FC = ({ children }) => {
  const [node, setNode] = useState<HTMLElement | null>();

  useMount(() => {
    setNode(document.getElementById(HEADER_DYNAMIC_ID));
  });

  return node ? createPortal(children, node) : null;
};

export default DynamicContent;
