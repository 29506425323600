import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DatasetAdsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.5,0c-0.3-0.1-0.6,0-0.8,0.2c-2.8,3.4-7,5.5-11.4,5.7H3.8C3.3,6,3,6.3,3,6.7v0.8H0.8C0.3,7.5,0,7.8,0,8.2v4.5
			c0,0.4,0.3,0.8,0.8,0.8H3v0.8C3,14.7,3.3,15,3.8,15h0.5l3,8c0.2,0.5,0.6,0.8,1.1,0.9c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.2,1.1-0.4
			l1.8-1.8c0.5-0.5,0.6-1.2,0.3-1.7l-2.5-5h2c4.4,0.2,8.6,2.3,11.4,5.7c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3,0
			c0.3-0.1,0.5-0.4,0.5-0.7V0.7C24,0.4,23.8,0.2,23.5,0z M3,12H1.5V9H3V12z M10.4,20.7l-1.8,1.8L5.8,15h1.8L10.4,20.7z M10.5,13.5
			h-6v-6h6V13.5z M22.5,18.3c-2.8-2.7-6.6-4.4-10.5-4.8V7.4c3.9-0.3,7.7-2,10.5-4.8V18.3z"
      />
    </SvgIcon>
  );
}
