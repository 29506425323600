import { Sorting } from "@devexpress/dx-react-grid";
import produce from "immer";
import { IModulesActionMap, IModulesState, IPipelinesGetSagaResponse } from "src/redux/modules/index";
import {
  actionCompile,
  COUNT,
  FILTERED,
  IActionT,
  IActionTPromiseCreator,
  IFiltered,
  LOADING,
  PAGE,
  PAGE_SIZE,
  SORTED,
  UPDATING,
} from "src/utils";
import { normalizeObjectsArray } from "src/utils/normalizeObjectsArray";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesItemsActionMap {
  PIPELINES_ITEMS_GET__F: {};
  PIPELINES_ITEMS_GET__R: {
    limit?: number;
    page?: number;
    ordering?: Sorting[];
    filtered?: IFiltered[];
    name?: string;
    mode?: "append" | "reload";
  };
  PIPELINES_ITEMS_GET__S: IPipelinesGetSagaResponse;

  PIPELINES_STATUS_GET__F: {};
  PIPELINES_STATUS_GET__R: {
    id__in: string;
  };
  PIPELINES_STATUS_GET__S: IPipelinesGetSagaResponse;

  PIPELINES_ITEMS_CLEAR_FILTER: {};

  PIPELINES_ITEMS_CLEAR: {};
}

export const pipelinesItemsGet = createActionCreator("PIPELINES_ITEMS_GET__R");
export type IPipelinesItemsGetF = IActionT<IModulesActionMap, "PIPELINES_ITEMS_GET__F">;
export type IPipelinesItemsGetR = IActionT<IModulesActionMap, "PIPELINES_ITEMS_GET__R">;
export type IPipelinesItemsGetS = IActionT<IModulesActionMap, "PIPELINES_ITEMS_GET__S">;
export const pipelinesItemsGetPromise = createPromiseCreator<"PIPELINES_ITEMS_GET__R", "PIPELINES_ITEMS_GET__S">(
  pipelinesItemsGet,
);
export type IPipelinesGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_ITEMS_GET__R",
  "PIPELINES_ITEMS_GET__S"
>;

export const pipelinesStatusGet = createActionCreator("PIPELINES_STATUS_GET__R");
export type IPipelinesStatusGetF = IActionT<IModulesActionMap, "PIPELINES_STATUS_GET__F">;
export type IPipelinesStatusGetR = IActionT<IModulesActionMap, "PIPELINES_STATUS_GET__R">;
export type IPipelinesStatusGetS = IActionT<IModulesActionMap, "PIPELINES_STATUS_GET__S">;
export const pipelinesStatusGetPromise = createPromiseCreator<"PIPELINES_STATUS_GET__R", "PIPELINES_STATUS_GET__S">(
  pipelinesStatusGet,
);

export type IPipelinesStatusGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_STATUS_GET__R",
  "PIPELINES_STATUS_GET__S"
>;

export const pipelinesClearFilter = createActionCreator("PIPELINES_ITEMS_CLEAR_FILTER");
export const pipelinesClear = createActionCreator("PIPELINES_ITEMS_CLEAR");

export const pipelinesItemsReducers = {
  PIPELINES_ITEMS_CLEAR: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[LOADING] = true;
      draft.pipelinesItemsState.models = undefined;
    }),

  PIPELINES_ITEMS_CLEAR_FILTER: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[FILTERED] = undefined;
    }),

  PIPELINES_ITEMS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[LOADING] = false;
    }),

  PIPELINES_ITEMS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[LOADING] = true;
    }),

  PIPELINES_ITEMS_GET__S: (modulesState: IModulesState, action: IPipelinesItemsGetS): IModulesState => {
    const { meta, results } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesItemsState[LOADING] = false;
      draft.pipelinesItemsState[COUNT] = meta.count;
      draft.pipelinesItemsState[PAGE] = meta.page;
      draft.pipelinesItemsState[PAGE_SIZE] = meta.limit;
      draft.pipelinesItemsState[SORTED] = meta.ordering;
      draft.pipelinesItemsState[FILTERED] = meta.filtered;

      if (meta.mode === "append") {
        draft.pipelinesItemsState.models = [...(modulesState.pipelinesItemsState.models || []), ...results];
      } else {
        draft.pipelinesItemsState.models = results;
      }
    });
  },
  PIPELINES_STATUS_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[UPDATING] = false;
    }),
  PIPELINES_STATUS_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesItemsState[UPDATING] = true;
    }),

  PIPELINES_STATUS_GET__S: (modulesState: IModulesState, action: IPipelinesStatusGetS): IModulesState => {
    const resultsNormalized = normalizeObjectsArray(action.payload.results, "id");

    return produce(modulesState, (draft) => {
      const currentPipeline = draft.pipelinesCurrentState.model;
      const updatedCurrentPipeline = currentPipeline && resultsNormalized[currentPipeline.id];

      draft.pipelinesItemsState[UPDATING] = false;
      draft.pipelinesItemsState.models = draft.pipelinesItemsState.models?.map(
        (pipeline) => resultsNormalized[pipeline.id] || pipeline,
      );

      if (updatedCurrentPipeline) {
        draft.pipelinesCurrentState.model = updatedCurrentPipeline;
      }
    });
  },
};
