import { FC, PropsWithChildren } from "react";

const TableTitleComponent: FC<PropsWithChildren<object>> = ({ children }) => (
  <b
    style={{
      whiteSpace: "normal",
    }}
  >
    {children}
  </b>
);

export default TableTitleComponent;
