import { Trans } from "@lingui/macro";
import { Alert, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useMount } from "react-use";
import AddProvider from "src/components/AddProvider";
import Loader from "src/components/Loader";
import { ICredentialVkGroup } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import useQueryCredentialVkGroup from "src/utils/queries/credentials/useQueryCredentialVkGroup";

interface IProps {
  groupId: number;
  pipelineType: IPipelineType;
  persistState?: {
    [key: string]: any;
  };
  onSelect: (credential: ICredentialVkGroup) => void;
  isAdmin: boolean;
  isOwner: boolean;
}

const VkGroupPermissions: FC<IProps> = ({ groupId, pipelineType, persistState, onSelect, isAdmin, isOwner }) => {
  const {
    mutate: getVkGroup,
    data: vkGroupData,
    isSuccess: vkGroupIsLoaded,
  } = useQueryCredentialVkGroup({
    onSuccess: onSelect,
  });

  useMount(() => {
    getVkGroup({ vkGroupId: groupId });
  });

  return useMemo(() => {
    if (!vkGroupIsLoaded) {
      return <Loader autoHeight />;
    }

    if (!isOwner) {
      return (
        <>
          <Alert>
            <Typography>
              <Trans>
                Shared data sources are not sufficient to collect story statistics. Contact the owner of the data source to
                connect story statistics
              </Trans>
            </Typography>
          </Alert>
        </>
      );
    }

    if (!isAdmin) {
      return (
        <>
          <Alert>
            <Typography>
              <Trans>
                Your access level is not sufficient to collect story statistics. Please upgrade your community role to
                Administrator and try connecting again in Settings & Status
              </Trans>
            </Typography>
          </Alert>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          <Trans>grant_vk_permissions_create</Trans>
        </Typography>

        <AddProvider
          source={pipelineType.id}
          ButtonProps={{
            disabled: Boolean(vkGroupData),
            fullWidth: false,
          }}
          text={<Trans>Collect story data</Trans>}
          persistState={persistState}
          extraParams={{
            group_ids: [groupId],
            scope: ["stories"],
          }}
        />
      </>
    );
  }, [groupId, isAdmin, isOwner, persistState, pipelineType.id, vkGroupData, vkGroupIsLoaded]);
};

export default VkGroupPermissions;
