import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function NavigationPanelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M9,11H5A2,2,0,0,1,3,9V5A2,2,0,0,1,5,3H9a2,2,0,0,1,2,2V9A2,2,0,0,1,9,11ZM5,5H5V9H9V5Z" />
      <path d="M19,11H15a2,2,0,0,1-2-2V5a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V9A2,2,0,0,1,19,11ZM15,5h0V9h4V5Z" />
      <path d="M9,21H5a2,2,0,0,1-2-2V15a2,2,0,0,1,2-2H9a2,2,0,0,1,2,2v4A2,2,0,0,1,9,21ZM5,15H5v4H9V15Z" />
      <path d="M19,21H15a2,2,0,0,1-2-2V15a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4A2,2,0,0,1,19,21Zm-4-6h0v4h4V15Z" />
    </SvgIcon>
  );
}
