import produce from "immer";
import { EPipelineType } from "src/enums/pipeline";
import { IModulesActionMap, IModulesState } from "src/redux/modules/index";
import { IPipelineType } from "src/types/pipeline";
import { actionCompile, IActionT, IActionTPromiseCreator, LOADING } from "src/utils";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesTypesActionMap {
  PIPELINES_TYPES_GET__F: {};
  PIPELINES_TYPES_GET__R: {};
  PIPELINES_TYPES_GET__S: {
    results: {
      [key in EPipelineType]: IPipelineType;
    };
  };
}

export const pipelinesTypesGet = createActionCreator("PIPELINES_TYPES_GET__R");
export type IPipelinesTypesGetF = IActionT<IModulesActionMap, "PIPELINES_TYPES_GET__F">;
export type IPipelinesTypesGetR = IActionT<IModulesActionMap, "PIPELINES_TYPES_GET__R">;
export type IPipelinesTypesGetS = IActionT<IModulesActionMap, "PIPELINES_TYPES_GET__S">;
export const pipelinesTypesGetPromise = createPromiseCreator<"PIPELINES_TYPES_GET__R", "PIPELINES_TYPES_GET__S">(
  pipelinesTypesGet,
);
export type IPipelinesTypesGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_TYPES_GET__R",
  "PIPELINES_TYPES_GET__S"
>;

export const pipelinesTypesReducers = {
  PIPELINES_TYPES_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesTypesState[LOADING] = false;
    }),

  PIPELINES_TYPES_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesTypesState[LOADING] = true;
    }),

  PIPELINES_TYPES_GET__S: (modulesState: IModulesState, action: IPipelinesTypesGetS): IModulesState => {
    const { results } = action.payload;
    return produce(modulesState, (draft) => {
      draft.pipelinesTypesState[LOADING] = true;
      draft.pipelinesTypesState.modelsNormalized = results;
      draft.pipelinesTypesState.models = Object.values(results);
    });
  },
};
