import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const instruction = (theme: Theme) => css`
  border-radius: 8px;
  background: ${theme.palette.cyan.main};
  margin-top: 60px;
  padding: 60px;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
