import { EPipelineType } from "src/enums/pipeline";
import { IStreamConfig } from "src/types/stream";

export default function getTargetName(pipelineType: EPipelineType, config: IStreamConfig): string | undefined {
  if (!pipelineType || !config) {
    return undefined;
  }

  switch (pipelineType) {
    case EPipelineType.VK_ADS:
    case EPipelineType.VK_ADS_SMALL:
      return config.client?.name || config.account?.account_name;

    case EPipelineType.VK_POST:
    case EPipelineType.VK_COMMUNITY:
    case EPipelineType.VK_COMMUNITY_POST:
      return config.group_data?.name;

    case EPipelineType.FB_PAGE:
    case EPipelineType.FB_POST:
    case EPipelineType.FB_PAGE_POST:
      return config.page?.name;

    case EPipelineType.INST_BA:
    case EPipelineType.INST_MEDIA:
    case EPipelineType.INST_BA_MEDIA:
      return config.facebook_page?.name;

    case EPipelineType.FB_ADS:
    case EPipelineType.FB_ADS_SMALL:
      return config.facebook_ad_account?.name;

    case EPipelineType.OK_COMMUNITY:
    case EPipelineType.OK_POST:
    case EPipelineType.OK_COMMUNITY_POST:
      return config.ok_group_data?.name;

    case EPipelineType.YA_METRIKA:
      return config.counters?.[0]?.name;

    case EPipelineType.YA_DIRECT:
    case EPipelineType.YA_DIRECT_CAMPAIGN:
      return config.client_login;

    case EPipelineType.MYTARGET:
      return config.entity_account_name;

    case EPipelineType.TIKTOK:
      return config.tiktok_ads_account?.advertiser_name;

    default:
      return undefined;
  }
}
