import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const avatar = (theme: Theme) => css`
  background-color: ${theme.palette.secondary.main};
  margin: ${theme.spacing(1)};
`;

export const langSelector = css`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const langSelectorFlag = css`
  width: 24px;
`;

export const paper = (theme: Theme) => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${theme.spacing(8)};
  max-width: 460px;
  padding: ${theme.spacing(4)};
  position: relative;
`;
