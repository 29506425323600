import "react-credit-cards/es/styles-compiled.css";
import "./rccs.css";

import { t } from "@lingui/macro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import classNames from "classnames";
import { FC } from "react";
import Cards from "react-credit-cards";
import { EPaymentMethodType } from "src/enums/paymentMethod";
import { IPaymentMethod } from "src/types/paymentMethod";

import useStyles from "./index.css";

interface IProps {
  method: IPaymentMethod;
  selected?: boolean;
  onChange: (id: IPaymentMethod["id"]) => void;
}

const PaymentMethod: FC<IProps> = ({ method, selected, onChange }) => {
  const classes = useStyles();

  const expiry = method.about_payment_method.expiry_month + method.about_payment_method.expiry_year;

  const number = `${method.about_payment_method.first6 || "******"}******${method.about_payment_method.last4 || "****"}`;

  const handleChange = (id: IPaymentMethod["id"]) => () => {
    onChange(id);
  };

  return (
    <div className={classNames(classes.wrapper, selected && classes.wrapper_selected)} onClick={handleChange(method.id)}>
      {selected && <CheckCircleIcon className={classes.check} />}

      {method.about_payment_method.type === EPaymentMethodType.BANK_CARD ? (
        <Cards
          cvc=""
          expiry={expiry}
          name=" "
          number={number}
          locale={{
            valid: t`valid thru`,
          }}
        />
      ) : (
        <div className="rccs">
          <div className={`rccs__card rccs__card--${method.about_payment_method.type}`}>
            <div className="rccs__card--front">
              <div className="rccs__card__background" />
              <div className="rccs__issuer rccs__issuer--other">{method.about_payment_method.type.replace("_", " ")}</div>
              <div className="rccs__cvc__front" />
              <div className="rccs__number rccs--filled">{number.replace(/(?=(.{4})+(?!.))/g, " ")}</div>
              <div className="rccs__name  rccs--filled" />
            </div>
            <div className="rccs__card--back">
              <div className="rccs__card__background" />
              <div className="rccs__stripe" />
              <div className="rccs__signature" />
              <div className="rccs__cvc" />
              <div className="rccs__issuer" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
