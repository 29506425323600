import { Trans } from "@lingui/macro";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import React, { useMemo, VFC } from "react";
import { ISubscription } from "src/types/subscription";
import { IUser } from "src/types/user";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";

import * as classes from "./index.css";

const OfferWithoutActiveSubscription: VFC<{
  subscription: ISubscription;
  user: IUser;
  isTrial: boolean;
}> = ({ subscription, user, isTrial }) => {
  const isExpired = useMemo(() => !user.active_subscription, [user.active_subscription]);

  const subscriptionDateFinish = useMemo(
    () => moment.utc(subscription.date_finish).format("DD.MM.YYYY"),
    [subscription.date_finish],
  );

  return (
    <Paper variant="filled" css={classes.paper}>
      <div css={classes.paper__row}>
        <Typography gutterBottom>
          <Trans>Amount of next payment</Trans>
        </Typography>
        <Typography variant="h4">
          {isTrial || isExpired ? "-" : subscription.total_price + getCurrencyDisplayName(subscription.currency)}
        </Typography>
      </div>

      <div css={classes.paper__row}>
        <Typography gutterBottom>
          <Trans>Next payment day</Trans>
        </Typography>
        <Typography variant="h4">{isTrial || isExpired ? "-" : subscriptionDateFinish}</Typography>
      </div>

      <div css={classes.paper__row}>
        <Typography gutterBottom>
          <Trans>Your bonus account</Trans>
        </Typography>
        <Typography variant="h4">{user.internal_account_total + getCurrencyDisplayName(user.geoip_currency)}</Typography>
      </div>
    </Paper>
  );
};

export default OfferWithoutActiveSubscription;
