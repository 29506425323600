import { FC, useState } from "react";
import { LoadingComponentProps } from "react-loadable";
import { useUpdateEffect } from "react-use";
import Page500 from "src/app/Page500/Page500";
import Loader from "src/components/Loader";

const MAX_RETRY_NUM = 5;
const RETRY_DELAY = 500;

const ComponentLoader: FC<LoadingComponentProps> = ({ isLoading, error, retry }) => {
  const [stateError, setStateError] = useState(error);
  const [autoRetryNum, setAutoRetryNum] = useState(0);

  useUpdateEffect(() => {
    setStateError(error);
  }, [error]);

  useUpdateEffect(() => {
    if (stateError && autoRetryNum < MAX_RETRY_NUM) {
      setTimeout(() => {
        setAutoRetryNum((state) => state + 1);
        setStateError(null);
        retry();
      }, RETRY_DELAY);
    }
  }, [stateError]);

  if (isLoading || (error && autoRetryNum < MAX_RETRY_NUM)) {
    return <Loader />;
  } else if (error && autoRetryNum >= MAX_RETRY_NUM) {
    return <Page500 />;
  } else {
    return null;
  }
};

export default ComponentLoader;
