import merge from "deepmerge";
import { createContext, useContext } from "react";
import { DeepPartial } from "src/types/helpers";

import { IAppState } from "./appContextProvider";

export const ContextApp = createContext<{
  state: IAppState;
  updateState: (value: DeepPartial<IAppState>, storage?: "ls") => void;
}>({
  state: {} as any,
  updateState: () => {},
});

export const contextAppReducer = (
  state: Partial<IAppState>,
  action: {
    type: string;
    payload: DeepPartial<IAppState>;
  },
) => {
  if (action.type === "update") {
    return merge(state, action.payload, {
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  } else {
    return state;
  }
};

export const useAppContext = () => useContext(ContextApp);
