import { t } from "@lingui/macro";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { popupAdd } from "src/redux";
import { ISocialProvider, ISocialSmpAccountData } from "src/types/socialAuth";
import useQuerySocialSignIn from "src/utils/queries/auth/useQuerySocialSignIn";
import useQuerySocialSignInAndUp from "src/utils/queries/auth/useQuerySocialSignInAndUp";
import useQuerySocialSignUp from "src/utils/queries/auth/useQuerySocialSignUp";
import useQuerySocialConnect from "src/utils/queries/credentials/useQueryCredentialConnect";
import useQuerySocialCredExists from "src/utils/queries/credentials/useQueryCredentialExists";
import useQuerySocialProviders from "src/utils/queries/credentials/useQueryCredentialProviders";
import useQuerySmpToken from "src/utils/queries/remainder/useQuerySmpToken";
import { accessToken, updateTokens } from "src/utils/tokens";
import { RequireAtLeastOne } from "src/utils/types";

const COMPLETE_REDIRECT_URL = `${process.env.REACT_APP__SMP_OAUTH_COMPLETE_URI || window.location.origin}`;
const STORAGE_KEY = "@SocialConnectStorage";

interface IAuthParamsBase {
  providerId: ISocialProvider["id"];
  action: "connect" | "signin" | "signup" | "getCredential";
  nextUrl?: string;
  nextState?: {
    [key: string]: any;
  };
  appId: number;
  extraParams?: {
    [key: string]: any;
  };
}

export type IAuthParams = RequireAtLeastOne<IAuthParamsBase, "appId" | "providerId">;

type ISocialConnectStorage = Pick<IAuthParams, "action" | "nextState" | NonNullable<"nextUrl">>;

export default function useSocialConnect() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [appId, setAppId] = useState<number | null>(null);
  const [isAuthPending, setAuthPending] = useState<boolean>(false);
  const [providerId, setProviderId] = useState<ISocialProvider["id"] | null>(null);
  const [extraParams, setExtraParams] = useState<IAuthParams["extraParams"]>(undefined);
  const [criticalError, setCriticalError] = useState<string | null>(null);
  const { data: providers, refetch: fetchProviders } = useQuerySocialProviders({
    enabled: false,
  });
  const { data: smpToken, refetch: fetchToken } = useQuerySmpToken("smp-token", {
    enabled: false,
  });
  const { mutate: checkExists, status: existsStatus, error: existsError } = useQuerySocialCredExists();
  const {
    mutate: postConnection,
    status: connectStatus,
    error: connectError,
  } = useQuerySocialConnect({
    silent: true,
  });
  const { mutate: signIn, status: signInStatus, error: signInError } = useQuerySocialSignIn();
  const { mutate: signUp, status: signUpStatus, error: signUpError } = useQuerySocialSignUp();
  const { mutate: signInAndUp } = useQuerySocialSignInAndUp();

  const storedData: ISocialConnectStorage | null = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY) || "");
    } catch (e) {
      return null;
    }
  }, []);

  const writeToStorage = useCallback((data: ISocialConnectStorage) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }, []);

  const clearStorage = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
  }, []);

  const nextUrl = useMemo(() => storedData?.nextUrl || "/", [storedData?.nextUrl]);

  const authUrl = useMemo(() => {
    const final_window_option = JSON.stringify({
      option: "redirect",
      redirect_uri: COMPLETE_REDIRECT_URL,
    });

    const provider = providers?.find((provider) => provider.id === providerId);

    const baseUrl = appId ? `https://api.smp.io/oauth-client/v1/begin/${appId}` : provider?.auth_url;

    if (!baseUrl || !smpToken) {
      return null;
    }

    let fullUrl = `${baseUrl}?exchange_type=internal&access_token=${smpToken}&final_window_option=${final_window_option}`;

    if (provider?.medium === "mytarget" && accessToken) {
      fullUrl += `&jwt=${accessToken}`;
    }

    if (extraParams) {
      fullUrl += `&${new URLSearchParams(extraParams).toString()}`;
    }

    return fullUrl;
  }, [appId, extraParams, providerId, providers, smpToken]);

  useUpdateEffect(() => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  }, [authUrl]);

  return {
    auth: ({ providerId, action, nextUrl, nextState, appId, extraParams }: IAuthParams) => {
      setAuthPending(true);
      writeToStorage({
        action,
        nextState,
        nextUrl: nextUrl || location.pathname + location.search,
      });

      if (extraParams) {
        setExtraParams(extraParams);
      }

      if (appId) {
        setAppId(appId);
        fetchToken();
      } else if (providerId) {
        setProviderId(providerId);
        fetchProviders();
        fetchToken();
      }
    },

    connect: (params: ISocialSmpAccountData) => {
      checkExists(params, {
        onSuccess: ({ exists, is_owner, message }) => {
          if (!exists || is_owner) {
            postConnection(params, {
              onSuccess: (newCredential) => {
                dispatch(
                  popupAdd({
                    text: t`Source added`,
                  }),
                );

                history.push({
                  pathname: nextUrl,
                  state: {
                    ...storedData?.nextState,
                    newCredential,
                  },
                });

                clearStorage();
              },
            });
          } else {
            setCriticalError(message);
          }
        },
      });
    },

    error: existsError || connectError || criticalError || signInError || signUpError,

    signIn: (
      params: ISocialSmpAccountData,
      options?: {
        onError?: () => void;
        onSettled?: () => void;
      },
    ) => {
      signIn(params, {
        ...options,
        onSuccess: ({ token, refresh_token }) => {
          clearStorage();
          updateTokens(token, refresh_token);
          history.push({
            pathname: nextUrl,
            state: storedData?.nextState,
          });
        },
      });
    },

    signInAndUp: (
      params: ISocialSmpAccountData,
      options?: {
        onError?: () => void;
      },
    ) => {
      signInAndUp(params, {
        ...options,
        onSuccess: ({ token, refresh_token }) => {
          clearStorage();
          updateTokens(token, refresh_token);
          history.push({
            pathname: nextUrl,
            state: storedData?.nextState,
          });
        },
      });
    },

    signUp: (
      params: ISocialSmpAccountData,
      options?: {
        onError?: () => void;
        onSettled?: () => void;
      },
    ) => {
      signUp(params, {
        ...options,
        onSuccess: ({ token, refresh_token }) => {
          clearStorage();
          updateTokens(token, refresh_token);
          history.push({
            pathname: nextUrl,
            state: storedData?.nextState,
          });
        },
      });
    },

    status: (() => {
      if (isAuthPending) {
        return "loading";
      }

      if (!storedData || storedData?.action === "getCredential") {
        return "idle";
      }

      const actionStatus = {
        connect: connectStatus,
        signin: signInStatus,
        signup: signUpStatus,
      }[storedData?.action];

      if ([connectStatus, existsStatus, actionStatus].includes("error") || criticalError) {
        return "error";
      }

      if ([connectStatus, existsStatus, actionStatus].includes("loading")) {
        return "loading";
      }

      return actionStatus;
    })(),
    storedData,
  };
}
