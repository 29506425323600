import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const head = css``;

export const head__color = (color?: string) => (theme: Theme) =>
  css`
    width: 24px;
    height: 24px;
    background-color: ${color || "#ffffff"};
    border-radius: 8px;
    border: 1px solid ${color || theme.palette.primary.main};
    flex-shrink: 0;
  `;
