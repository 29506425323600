import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import { FC, useCallback } from "react";
import { components, MenuProps } from "react-select";

interface IProps extends MenuProps<any, any> {
  onClickCreateNew: () => void;
}

const SelectboxMenu: FC<IProps> = ({ children, onClickCreateNew, ...props }) => {
  const handleTouchStart = useCallback(
    (e: any) => {
      e.preventDefault();
      onClickCreateNew();
    },
    [onClickCreateNew],
  );

  return (
    <components.Menu {...(props as any)}>
      <Box p={1} pl={1.5} pr={1.5}>
        <Link href="#" onMouseDown={onClickCreateNew} onTouchEnd={handleTouchStart}>
          <Trans>Add another public / ad account</Trans>
        </Link>
      </Box>

      {children}
    </components.Menu>
  );
};

export default (onClickCreateNew: () => void) => (props: MenuProps<any, any>) =>
  <SelectboxMenu onClickCreateNew={onClickCreateNew} {...props} />;
