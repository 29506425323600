import { Trans } from "@lingui/macro";
import { Grid, MenuItem, Stack, Typography } from "@mui/material";
import { IconArrowBarToDown, IconHistory } from "@tabler/icons";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment";
import React, { ComponentProps, FC, MouseEvent, useCallback, useMemo, useState } from "react";
import * as classes from "src/components/ModalPipelineExport/index.css";
import PipelineExportProgress from "src/components/PipelineExportProgress";
import UiButton from "src/components/UiButton";
import UiDateRangePicker from "src/components/UiDateRangePicker";
import UiMenu from "src/components/UiMenu";
import { EExportStatus, EExportType } from "src/enums/export";
import { ETmplType } from "src/enums/tmpl";
import { IExport } from "src/types/export";
import { IPipelineExtended } from "src/types/pipeline";
import cssc from "src/utils/emotionComposition";
import { useReportScheme } from "src/utils/hoooks/useReportScheme";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryExportCreate from "src/utils/queries/export/useQueryExportCreate";
import useQueryExportUrl from "src/utils/queries/export/useQueryExportUrl";
import useQueryTmplGet from "src/utils/queries/tmpls/useQueryTmplGet";

interface IProps {
  updatedExports: { [key: IExport["id"]]: IExport };
  pipeline: IPipelineExtended;
  initialPeriod?: [Moment, Moment];
  onCreateStart?: (result: IExport) => void;
}

const CreateForm: FC<IProps> = ({ updatedExports, pipeline, initialPeriod, onCreateStart }) => {
  const [newExport, setNewExport] = useState<IExport | null>(null);

  const currentExport = useMemo(() => newExport && (updatedExports[newExport.id] || newExport), [newExport, updatedExports]);

  const { data: pipelineTypes } = useQueryPipelineTypes();

  const pipelineType = useMemo(() => pipelineTypes?.[pipeline.source], [pipeline.source, pipelineTypes]);

  const inhouseTmplId = useMemo(
    () => pipelineType?.tmpls.find(({ tmpl_type }) => tmpl_type === ETmplType.DATAFAN_DATA_TMPL)?.id,
    [pipelineType?.tmpls],
  );

  const { data: inhouseTmpl } = useQueryTmplGet(inhouseTmplId, {
    enabled: typeof inhouseTmplId !== "undefined",
  });

  const defaultPeriod = useMemo<[Moment, Moment]>(
    () => [
      initialPeriod?.[0]
        ? moment.max(initialPeriod?.[0], moment(pipeline.data_first_date))
        : pipeline.data_first_date
        ? moment(pipeline.data_first_date)
        : moment().subtract(31, "days"),
      initialPeriod?.[1]
        ? moment.max(initialPeriod?.[1], moment(pipeline.data_last_date))
        : pipeline.data_last_date
        ? moment(pipeline.data_last_date)
        : moment(),
    ],
    [initialPeriod, pipeline.data_first_date, pipeline.data_last_date],
  );

  const { value: scheme } = useReportScheme(pipeline);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

  const { data: exportUrl } = useQueryExportUrl(currentExport?.id, {
    enabled: typeof currentExport?.id !== "undefined" && currentExport.status === EExportStatus.SUCCESS,
  });

  const {
    mutate: createExport,
    isLoading: createExportIsLoading,
    error: createExportError,
  } = useQueryExportCreate(pipeline.id, {
    onSuccess: (resp) => {
      setNewExport(resp);
      onCreateStart && onCreateStart(resp);
    },
    silent: true,
  });

  const [period, setPeriod] = useState(defaultPeriod);

  const handleCreate = useCallback(
    (type: EExportType) => () => {
      createExport({
        dateFrom: period[0].format("YYYY-MM-DD"),
        dateTo: period[1].format("YYYY-MM-DD"),
        pages:
          type === EExportType.PDF && scheme
            ? scheme.type === "tabs"
              ? scheme.tabs.map((tab, index) => index + 1)
              : [1]
            : undefined,
        report_url:
          type === EExportType.PDF && scheme ? `${process.env.REACT_APP__BASE_URL}app/report-export/${pipeline.id}` : undefined,
        type,
      });
    },
    [createExport, scheme, period, pipeline.id],
  );

  const handleChangePeriod = useCallback<ComponentProps<typeof UiDateRangePicker>["onChange"]>((newPeriod) => {
    if (moment.isMoment(newPeriod[0]) && moment.isMoment(newPeriod[1])) {
      setPeriod(newPeriod as [Moment, Moment]);
    }
  }, []);

  const handleClickCreateBtn = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (scheme) {
        setAnchorEl(e.currentTarget);
      } else {
        handleCreate(EExportType.EXCEL)();
      }
    },
    [handleCreate, scheme],
  );

  const handleCloseTypeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDownloadNewExport = useCallback(() => {
    if (currentExport && exportUrl) {
      saveAs(exportUrl, currentExport.name);
      setNewExport(null);
    }
  }, [currentExport, exportUrl]);

  const isLoading = useMemo(
    () =>
      createExportIsLoading ||
      Boolean(currentExport && ![EExportStatus.SUCCESS, EExportStatus.FAILURE].includes(currentExport.status)),
    [createExportIsLoading, currentExport],
  );

  return useMemo(
    () => (
      <>
        <Typography mb={3} mt={2} variant="h4">
          <Trans>Creating a report export</Trans>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <UiDateRangePicker onChange={handleChangePeriod} value={period} maxDate={moment()} fullWidth />
          </Grid>

          <Grid item xs>
            {exportUrl ? (
              <UiButton css={classes.btn} startIcon={<IconArrowBarToDown />} onClick={handleDownloadNewExport}>
                <Trans>Download file</Trans>
              </UiButton>
            ) : (
              <UiButton onClick={handleClickCreateBtn} css={classes.btn} loading={isLoading}>
                <Trans>Create report export</Trans>
              </UiButton>
            )}

            <UiMenu
              anchorEl={anchorEl}
              onClose={handleCloseTypeMenu}
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
            >
              <MenuItem css={classes.menuItem} onClick={handleCreate(EExportType.EXCEL)}>
                <Trans>Create Excel</Trans>
              </MenuItem>

              <MenuItem css={classes.menuItem} onClick={handleCreate(EExportType.PDF)}>
                <Trans>Create PDF</Trans>
              </MenuItem>
            </UiMenu>
          </Grid>
        </Grid>

        <Typography
          component="div"
          css={cssc([classes.status, isLoading && classes.status_loading, Boolean(createExportError) && classes.status_error])}
        >
          {isLoading && currentExport && <PipelineExportProgress pipelineExport={currentExport} />}
          {createExportError &&
            (createExportError?.response?.data?.extra.message ? (
              createExportError.response?.data.extra.message
            ) : (
              <Trans>Export error. Try to choose a different period and re-create the export or contact support.</Trans>
            ))}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 3 }}>
          <IconHistory size={20} />
          <Typography variant="h4">
            <Trans>Export history</Trans>
          </Typography>
        </Stack>
      </>
    ),
    [
      anchorEl,
      createExportError,
      currentExport,
      exportUrl,
      handleChangePeriod,
      handleClickCreateBtn,
      handleCloseTypeMenu,
      handleCreate,
      handleDownloadNewExport,
      isLoading,
      period,
    ],
  );
};

export default CreateForm;
