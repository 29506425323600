import { SerializedStyles } from "@emotion/react";
import { plural, t, Trans } from "@lingui/macro";
import React, { FC, useMemo } from "react";
import UiTextField from "src/components/UiTextField";
import { IPipeline } from "src/redux";
import { IStreamConfig } from "src/types/stream";

interface IProps {
  pipeline: IPipeline;
  rowCss: SerializedStyles;
}

const FbAdsAttributes: FC<IProps> = ({ pipeline, rowCss }) => {
  const attributes = useMemo(
    () =>
      pipeline.streams.reduce(
        (
          acc: {
            action_attribution_windows?: IStreamConfig["action_attribution_windows"];
            action_report_time?: IStreamConfig["action_report_time"];
          },
          stream,
        ) => {
          acc.action_attribution_windows = stream.config_source.action_attribution_windows
            ? stream.config_source.action_attribution_windows
            : acc.action_attribution_windows;

          acc.action_report_time = stream.config_source.action_report_time
            ? stream.config_source.action_report_time
            : acc.action_report_time;

          return acc;
        },
        {},
      ),
    [pipeline.streams],
  );

  return (
    <>
      <div css={rowCss}>
        <UiTextField
          fullWidth
          disabled
          value={
            attributes?.action_attribution_windows
              ? plural(parseInt(attributes.action_attribution_windows[1].replace(/[^0-9.]/g, ""), 10), {
                  few: "# days",
                  many: "# days",
                  one: "# day",
                  other: "# days",
                }) +
                " " +
                t`after click` +
                " + " +
                plural(parseInt(attributes.action_attribution_windows[0].replace(/[^0-9.]/g, ""), 10), {
                  few: "# days",
                  many: "# days",
                  one: "# day",
                  other: "# days",
                }) +
                " " +
                t`after view ads`
              : t`Used from the settings of the advertising cabinet`
          }
          label={<Trans>Attribution Windows</Trans>}
        />
      </div>

      <div css={rowCss}>
        <UiTextField
          fullWidth
          disabled
          value={
            attributes?.action_report_time
              ? {
                  conversion: t`Conversion`,
                  impression: t`Impression`,
                  mixed: t`Mixed`,
                }[attributes.action_report_time]
              : t`Used from the settings of the advertising cabinet`
          }
          label={<Trans>Action Report Time</Trans>}
        />
      </div>
    </>
  );
};

export default FbAdsAttributes;
