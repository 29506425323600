import { Trans } from "@lingui/macro";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, DialogContent } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React, { FC, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import MediaFavicon from "src/components/MediaFavicon";
import UiDialog from "src/components/UiDialog";
import { EPipelineSubType } from "src/redux";
import { IPipelineType } from "src/types/pipeline";
import groupPipelineTypes from "src/utils/groupPipelineTypes";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import * as classes from "./index.css";

export interface IItemProps {
  className?: string;
  children?: React.ReactNode;
  sourceId: number;
}

interface IProps {
  open: boolean;
  handleClose: any;
  filter?: (pipelineType: IPipelineType) => boolean;
  ListItemComponent?: any;
}

const PipelineTypeList: FC<IProps> = ({ open, handleClose, filter, ListItemComponent }) => {
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: user } = useQueryMe();
  const beforeOpenPathname = useRef(window.location.pathname);

  const pipelineTypesFiltered = useMemo(() => {
    const pipelineTypesArray = pipelineTypes && Object.values(pipelineTypes);

    return pipelineTypesArray
      ? (filter ? pipelineTypesArray.filter(filter) : pipelineTypesArray).filter(
          (pipelineType) => !pipelineType.onlyStaff || user?.is_staff,
        )
      : [];
  }, [filter, pipelineTypes, user?.is_staff]);

  const pipelineTypesSorted = [...pipelineTypesFiltered].sort((pt1, pt2) => (pt2.order > pt1.order ? -1 : 1));

  const pipelineTypesGroped = pipelineTypesSorted.length > 0 && groupPipelineTypes(pipelineTypesSorted);

  const ItemComponent = useMemo(
    () =>
      ({ children, sourceId, ...props }: IItemProps) => {
        if (!ListItemComponent) {
          return (
            <Link to={`/dataset-create/${sourceId}/`} {...props}>
              {children}
            </Link>
          );
        }

        return (
          <ListItemComponent sourceId={sourceId} {...props}>
            {children}
          </ListItemComponent>
        );
      },
    [ListItemComponent],
  );

  useUpdateEffect(() => {
    if (open) {
      beforeOpenPathname.current = window.location.pathname;
      window.history.pushState("", "", "/app/connect");
    } else {
      window.history.pushState("", "", window.location.pathname);
    }
  }, [open]);

  return useMemo(
    () => (
      <UiDialog
        fullScreen
        open={Boolean(open && pipelineTypesGroped)}
        keepMounted
        onClose={handleClose}
        title={<Trans>Connect</Trans>}
      >
        <DialogContent>
          <List>
            {pipelineTypesGroped &&
              (Object.keys(pipelineTypesGroped) as EPipelineSubType[]).map((group) => {
                const title = pipelineTypesGroped[group].title;
                const list = pipelineTypesGroped[group].items;

                if (!list.length) {
                  return null;
                }

                return (
                  <React.Fragment key={group}>
                    <Box mt={2} mb={1}>
                      <Typography variant="h6">{title}</Typography>
                    </Box>

                    {list.map((pipelineType) => (
                      <React.Fragment key={pipelineType.id}>
                        <ItemComponent css={classes.link} sourceId={pipelineType.id}>
                          <ListItem button>
                            <ListItemText
                              primary={
                                <>
                                  {pipelineType.onlyStaff && (
                                    <VisibilityOffIcon titleAccess={"hide"} style={{ fontSize: 14, marginRight: "5px" }} />
                                  )}
                                  <MediaFavicon media={pipelineType.media} css={classes.icon} />
                                  <span>{pipelineType.title}</span>
                                </>
                              }
                            />
                          </ListItem>
                        </ItemComponent>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              })}
          </List>
        </DialogContent>
      </UiDialog>
    ),
    [ItemComponent, handleClose, open, pipelineTypesGroped],
  );
};

export default PipelineTypeList;
