import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  backButton: {
    color: "inherit",
    justifyContent: "flex-start",
    maxWidth: 200,
    whiteSpace: "nowrap",
  },
  backButtonInner: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  content: {
    position: "relative",
    zIndex: 10,
  },
  html2canvasOverlay: {
    "& .MuiTypography-root": {
      marginTop: theme.spacing(2),
    },

    ".html2canvas-processing &": {
      display: "flex",
    },

    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "none",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 10000000000,
  },
  pipelines: {
    marginLeft: "20px",
  },
}));
