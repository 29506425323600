import { Trans } from "@lingui/macro";
import LockOutlined from "@mui/icons-material/LockOutlined";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Link, useRouteMatch } from "react-router-dom";
import { useMount } from "react-use";
import AuthTemplate from "src/app/Auth/components/AuthTemplate";
import Loader from "src/components/Loader";
import UiButton from "src/components/UiButton";
import { socialAuthApps } from "src/fixtures/socialAuthApps";
import { ISocialSmpAccountData } from "src/types/socialAuth";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";
import useQuerySocialSignIn from "src/utils/queries/auth/useQuerySocialSignIn";
import useQuerySocialSignUp from "src/utils/queries/auth/useQuerySocialSignUp";
import useQueryCredentialConnect from "src/utils/queries/credentials/useQueryCredentialConnect";
import useQueryCredentialExists from "src/utils/queries/credentials/useQueryCredentialExists";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import useStyles from "./index.css";

interface IMessageEventData {
  error?: string;
  type?: string;
}

const postMessage = (data: IMessageEventData) => {
  if (window.opener) {
    window.opener.postMessage(
      {
        ...data,
        type: "datafan_oauth_client",
      },
      "*",
    );
  } else {
    console.log("Post message:", data);
  }
};

const AuthPlanner: FC = () => {
  const classes = useStyles();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const location = useLocation<undefined | { authResult: ISocialSmpAccountData; isDatafanUser: boolean }>();
  const { mutate: signIn, isLoading: isSignInLoading } = useQuerySocialSignIn({ silent: true });
  const { mutate: signUp, isLoading: isSignUpLoading } = useQuerySocialSignUp({ silent: true });
  const { mutate: checkCredential } = useQueryCredentialExists({ silent: true });
  const { mutate: connect } = useQueryCredentialConnect({ silent: true });
  const { auth, status } = useSocialConnect();
  const app = socialAuthApps.find(({ medium }) => medium === "smmplanner");
  const { data: user, isFetched: userIsFetched } = useQueryMe();

  const closeWindow = useCallback(() => {
    if (window.opener) {
      window.close();
    }
  }, []);

  const handleAuth = useCallback(() => {
    if (app) {
      auth({
        action: "getCredential",
        appId: app.id,
        nextState: {
          isDatafanUser: Boolean(user),
        },
      });
    }
  }, [app, auth, user]);

  useMount(() => {
    if (routeMatch.url !== routeMatch.path) {
      history.replace(`${routeMatch.path}${window.location.search || ""}`);
    }

    if (location.state?.authResult) {
      const data = location.state.authResult;
      const isDatafanUser = location.state.isDatafanUser;

      if (isDatafanUser) {
        checkCredential(data, {
          onSuccess: ({ exists, message }) => {
            if (exists) {
              postMessage({ error: message });
            } else {
              connect(data, {
                onError: (e) => {
                  postMessage({
                    error: Array.isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message,
                  });
                },
                onSettled: () => {
                  closeWindow();
                },
                onSuccess: () => {
                  postMessage({ error: undefined });
                },
              });
            }
          },
        });
      } else {
        signIn(data, {
          onError: () => {
            signUp(data, {
              onError: (e) => {
                postMessage({
                  error: Array.isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message,
                });
              },
              onSettled: () => {
                closeWindow();
              },
              onSuccess: () => {
                postMessage({ error: undefined });
              },
            });
          },
          onSuccess: () => {
            postMessage({ error: undefined });
            closeWindow();
          },
        });
      }

      window.history.replaceState({}, document.title);
    }
  });

  return app ? (
    <AuthTemplate title={<Trans>Sign in</Trans>} icon={<LockOutlined />}>
      {location.state?.authResult || (!user && !userIsFetched) || isSignInLoading || isSignUpLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={4} className={classes.container}>
          {user?.id ? (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                <Trans>You are logged in to DataFan.</Trans>
                <br />
                <Trans>Your account</Trans>
                {": "}
                <b>{user.humand_name}</b>
              </Typography>

              <UiButton fullWidth variant="contained" color="primary" onClick={handleAuth} loading={status === "loading"}>
                <Trans>Connect my account to SMMplanner</Trans>
              </UiButton>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <UiButton variant="contained" color="primary" loading={status === "loading"} onClick={handleAuth}>
                  <Trans>Sign in</Trans> / <Trans>Sign up</Trans>
                </UiButton>
              </Grid>

              <Grid item xs={12}>
                <UiButton
                  variant="text"
                  component={Link}
                  to={{
                    pathname: "/auth/signin/",
                    state: {
                      next: location.pathname + location.search,
                    },
                  }}
                >
                  <Trans>I already have an account in DataFan</Trans>
                </UiButton>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </AuthTemplate>
  ) : null;
};

export default AuthPlanner;
