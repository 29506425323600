import { IResponseUserInfoByIp } from "src/types/response";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "user-info-by-ip";

export default function useQueryInfoByIp(queryOptions?: IUseQueryBaseOptions<IResponseUserInfoByIp>) {
  return useQueryBase<IResponseUserInfoByIp>(
    QUERY_KEY,
    {
      refetchOnMount: false,
      silent: true,
      ...queryOptions,
    },
    {
      method: "GET",
      url: "user-info-by-ip",
    },
  );
}
