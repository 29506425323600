import { ISocialSmpAccountData } from "src/types/socialAuth";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IOutput {
  exists: boolean;
  is_owner: boolean;
  message: string;
}

export default function useQueryCredentialExists(queryOptions?: IUseQueryMutationOptions<IOutput, ISocialSmpAccountData>) {
  return useMutationBase<IOutput, ISocialSmpAccountData>(queryOptions, {
    method: "POST",
    url: `account-credential/exists/`,
  });
}
