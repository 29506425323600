import { css, keyframes } from "@emotion/react/macro";
import { Theme } from "@mui/material";

const ring = keyframes`
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
`;

export const btnWrapper = css`
  position: relative;
`;

export const num = css`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #df5759;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  border: 1px solid #ffffff;
`;

export const btn_active = css`
  background: #e8f0f3 !important;
  border-color: transparent !important;
  color: #000000 !important;
`;

export const btn_disabled = css`
  pointer-events: none;
  color: #e2e2e2 !important;
  border-color: #e2e2e2 !important;
  cursor: default !important;
  background: transparent !important;
`;

export const btn__icn = css`
  font-size: 24px;
  transform-origin: 50% 4px;
`;

export const btn__icn_animated = css`
  animation: ${ring} 4s 0.7s ease-in-out infinite;
`;

export const popover = (theme: Theme) => css`
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;

  ${theme.breakpoints.down("sm")} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1201;
    width: auto;
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    padding: 6px;
  }
`;

export const popover__inn = (isReport: boolean) => (theme: Theme) =>
  css`
    width: 420px;
    margin-top: 30px;
    flex-shrink: 0;
    position: relative;
    margin-left: ${isReport ? "-120px" : 0};

    :before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-bottom: 8px solid #ffffff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: -8px;
      left: 50%;
      margin-left: ${isReport ? "52px" : "-8px"};

      ${theme.breakpoints.down("lg")} {
        margin-left: 52px;
      }

      ${theme.breakpoints.down("sm")} {
        display: none;
      }
    }

    ${theme.breakpoints.down("lg")} {
      margin-left: -120px;
    }

    ${theme.breakpoints.down("sm")} {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
      height: 100%;
    }
  `;

export const popover__paper = (theme: Theme) => css`
  max-height: 500px;
  overflow: auto;
  padding: 0 30px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }

  ${theme.breakpoints.down("sm")} {
    max-height: 100%;
    padding-bottom: 120px;
  }
`;

export const notice = css`
  border-bottom: 1px solid #e2e2e2;
  padding: 30px 0;
  position: relative;

  :last-of-type {
    border-bottom: none;
  }
`;

export const notice_success = css`
  color: #20a8a6;
`;

export const notice_error = css`
  color: #df5759;
`;

export const notice__head = css`
  padding-right: 60px;
`;

export const notice__head__icn = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: top;
`;

export const notice__head__title = css`
  display: inline;
  font-size: 1rem;
  font-weight: 600;
`;

export const notice__top = css`
  position: absolute;
  right: 0;
  top: 30px;
  align-items: center;
`;

export const notice__sticker = css`
  text-transform: uppercase;
  background: #df5759;
  border-radius: 8px;
  color: #ffffff;
  font-size: 10px;
  padding: 1px 5px;
  line-height: 14px;
`;

export const notice__cross = css`
  font-size: 16px;
  height: 20px;
  width: 16px;
  color: #505050;

  &:hover {
    color: #000000;
  }
`;

export const notice__text = css`
  margin-top: 10px;
  padding-left: 32px;

  strong {
    font-weight: 600;
  }
`;

export const mobileHead = css`
  display: flex;
  align-items: center;
  height: 62px;
  border-bottom: 1px solid #e2e2e2;
  padding: 0 8px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
`;

export const mobileHead__email = css`
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const mobileMenu = css`
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;

  .MuiListItemText-root {
    padding-left: 30px;
  }
`;

export const avatar = css`
  background: #e2e2e2;
  cursor: pointer;
  width: 46px;
  height: 46px;
  color: #000000;
`;
