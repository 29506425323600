import { Trans } from "@lingui/macro";
import { OfflineBolt } from "@mui/icons-material";
import Container from "@mui/material/Container";
import { FC } from "react";
import { Link } from "react-router-dom";
import Message from "src/components/Message";

import useStyles from "./index.css";

const Page404: FC = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm" className={classes.container} disableGutters>
      <Message
        icon={<OfflineBolt />}
        title={404}
        message={
          <Trans>
            Go to the <Link to="/auth/signin/">Sign In</Link> or <Link to="/auth/signup/">Sign Up</Link> page
          </Trans>
        }
      />
    </Container>
  );
};

export default Page404;
