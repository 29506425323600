import axios, { AxiosError } from "axios";
import { Dispatch } from "react";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { QueryKey } from "react-query/types/core/types";
import { useDispatch } from "react-redux";
import { popupAdd } from "src/redux";
import apiRefreshToken from "src/redux/modules/shared/apiRefreshToken";
import { apiRequest, IApiRequestParams, prepareErrorMessage } from "src/utils/api";
import { accessToken, removeTokens, updateTokens } from "src/utils/tokens";
import { parseQueryString } from "src/utils/url";

interface IRequestParams<IOutput> extends Pick<IApiRequestParams, "method" | "url" | "params" | "cancelToken" | "signal"> {
  fetchAllPages?: boolean;
  onFetch?: (data: { params: IApiRequestParams["params"]; data: IOutput; url: string }) => void;
}

type IUseQueryOptionsGeneric<T> = T & {
  silent?: boolean;
  specialAccessToken?: string;
  withoutToken?: boolean;
};

const request = <IOutput>(
  { params, method, url, fetchAllPages, cancelToken, onFetch }: IRequestParams<IOutput>,
  dispatch: Dispatch<any>,
  silent: boolean | undefined,
  specialAccessToken: string | undefined,
  withoutToken: boolean | undefined,
) => {
  const processedParams = {
    accessToken: withoutToken ? undefined : specialAccessToken || accessToken || undefined,
    cancelToken,
    data: method !== "GET" ? params : {},
    method,
    params: method === "GET" ? params : {},
    url,
  };

  return apiRequest<IOutput>(processedParams)
    .then(async (result) => {
      const data = result.data as any;

      if (onFetch) {
        onFetch({
          data,
          params,
          url,
        });
      }

      if (fetchAllPages && data.next) {
        const nextParams = Object.fromEntries(parseQueryString(data.next?.split("?")[1]));

        const nextData: any = await request(
          {
            cancelToken,
            fetchAllPages,
            method,
            onFetch,
            params: {
              ...params,
              ...nextParams,
            },
            url,
          },
          dispatch,
          silent,
          specialAccessToken,
          withoutToken,
        );

        return {
          ...data,
          next: nextData.next,
          results: [...data.results, ...nextData.results],
        };
      }

      return result.data;
    })
    .catch(async (failure) => {
      if (failure instanceof axios.Cancel) {
        console.log(failure.message);
      }

      const message = prepareErrorMessage(failure.response?.data);

      if (failure.response.status === 401) {
        const refreshTokenResp = await apiRefreshToken();

        if (!refreshTokenResp || !refreshTokenResp.token || !refreshTokenResp.refresh_token) {
          removeTokens();

          throw failure;
        }

        const { token, refresh_token } = refreshTokenResp;

        updateTokens(token, refresh_token);
        processedParams.accessToken = token;

        dispatch({
          payload: {
            refresh_token,
            token,
          },
          type: "AUTH_SET_TOKENS",
        });

        return await apiRequest<IOutput>(processedParams).then((result) => result.data);
      } else if (message && !silent) {
        dispatch(
          popupAdd({
            importance: "error",
            text: message,
          }),
        );
      }

      throw failure;
    });
};

export type IUseQueryBaseOptions<IOutput, IOutputSelect = IOutput> =
  | IUseQueryOptionsGeneric<UseQueryOptions<IOutput, AxiosError<any>, IOutputSelect>>
  | undefined;

export default function useQueryBase<IOutput, IOutputSelect = IOutput>(
  queryKey: QueryKey,
  queryOptions: IUseQueryBaseOptions<IOutput, IOutputSelect>,
  { method = "GET", params, url, fetchAllPages, onFetch }: IRequestParams<IOutput>,
) {
  const dispatch = useDispatch();

  return useQuery<IOutput, AxiosError<any>, IOutputSelect>(
    queryKey,
    () =>
      request(
        {
          fetchAllPages,
          method,
          onFetch,
          params,
          url,
        },
        dispatch,
        queryOptions?.silent,
        queryOptions?.specialAccessToken,
        queryOptions?.withoutToken,
      ),
    queryOptions,
  );
}

export interface IUseQueriesBaseParams<IOutput> extends Omit<IRequestParams<IOutput>, "url" | "params"> {
  url: IRequestParams<IOutput>["url"] | ((queryKey: QueryKey) => IRequestParams<IOutput>["url"]);
  params: IRequestParams<IOutput>["params"] | ((queryKey: QueryKey) => IRequestParams<IOutput>["params"]);
}

export function useQueriesBase<IOutput, IOutputSelect = IOutput>(
  queryKeys: QueryKey[],
  queryOptions:
    | (Omit<NonNullable<IUseQueryBaseOptions<IOutput, IOutputSelect>>, "queryFn" | "queryKey" | "select"> & {
        select?: (data: IOutput, queryKey: QueryKey) => IOutputSelect;
      })
    | undefined,
  { method = "GET", params, url, fetchAllPages, onFetch }: IUseQueriesBaseParams<IOutput>,
) {
  const dispatch = useDispatch();
  const { silent, select, specialAccessToken, withoutToken, ...queryOptionsSpread } = queryOptions || {};

  return useQueries(
    queryKeys.map((queryKey) => ({
      queryFn: () => {
        const source = axios.CancelToken.source();

        const promise = request(
          {
            cancelToken: source.token,
            fetchAllPages,
            method,
            onFetch,
            params: typeof params === "function" ? params(queryKey) : params,
            url: typeof url === "function" ? url(queryKey) : url,
          },
          dispatch,
          silent,
          specialAccessToken,
          withoutToken,
        );

        (promise as any).cancel = () => {
          source.cancel("Query was cancelled by react-query");
        };

        return promise;
      },
      queryKey: queryKey,
      select: (data: IOutput) => select?.(data, queryKey) || data,
      ...(queryOptionsSpread as any),
    })),
  ) as UseQueryResult<IOutputSelect, AxiosError<any>>[];
}

export type IUseQueryMutationOptions<IOutput, IInput = undefined> =
  | (UseMutationOptions<IOutput, AxiosError<any>, IInput, any> & {
      silent?: boolean;
      mutationFn?: undefined;
      specialAccessToken?: string;
      withoutToken?: boolean;
    })
  | undefined;

interface IUseQueryMutationParams<IInput> {
  method?: IApiRequestParams["method"];
  url: IApiRequestParams["url"] | ((params: IInput) => string);
  params?:
    | {
        [key: string]: any;
      }
    | ((params: IInput) => {
        [key: string]: any;
      });
}

export function useMutationBase<IOutput, IInput = undefined>(
  queryOptions: IUseQueryMutationOptions<IOutput, IInput>,
  { method = "POST", url, params }: IUseQueryMutationParams<IInput>,
) {
  const dispatch = useDispatch();

  return useMutation<IOutput, AxiosError<any>, IInput>(
    (apiParams) =>
      request(
        {
          method,
          params: typeof params === "function" ? params(apiParams) : params || apiParams,
          url: typeof url === "function" ? url(apiParams) : url,
        },
        dispatch,
        queryOptions?.silent,
        queryOptions?.specialAccessToken,
        queryOptions?.withoutToken,
      ),
    queryOptions,
  );
}

export type IUseQueryInfiniteOutputWrapper<IEntity> = {
  next: string | null;
  previous: string | null;
  count: number;
  results: IEntity[];
};

export type IUseQueryInfiniteOptions<IOutput, IOutputSelect = IOutput> =
  | IUseQueryOptionsGeneric<
      UseInfiniteQueryOptions<
        IUseQueryInfiniteOutputWrapper<IOutput>,
        AxiosError<any>,
        IUseQueryInfiniteOutputWrapper<IOutputSelect>
      >
    >
  | undefined;

interface IUseQueryInfiniteParams {
  method?: IApiRequestParams["method"];
  params: {
    limit?: number | string;
    offset?: number | string;
    [key: string]: any;
  };
  url: IApiRequestParams["url"];
}

export function useQueryInfinite<IOutput, IOutputSelect = IOutput>(
  queryKey: QueryKey,
  queryOptions: IUseQueryInfiniteOptions<IOutput, IOutputSelect>,
  { method = "GET", params, url }: IUseQueryInfiniteParams,
) {
  const dispatch = useDispatch();

  return useInfiniteQuery<IUseQueryInfiniteOutputWrapper<IOutput>, AxiosError, IUseQueryInfiniteOutputWrapper<IOutputSelect>>(
    queryKey,
    ({ pageParam }) => {
      if (typeof pageParam === "string") {
        params = Object.fromEntries(parseQueryString(pageParam?.split("?")[1]));
      } else if (pageParam === false) {
        return Promise.reject(new Error("There are no next page"));
      }

      return request(
        {
          method,
          params,
          url,
        },
        dispatch,
        queryOptions?.silent,
        queryOptions?.specialAccessToken,
        queryOptions?.withoutToken,
      );
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next || false,
      refetchOnWindowFocus: false,
      retry: false,
      ...queryOptions,
    },
  );
}
