import "react-phone-input-2/lib/plain.css";

import { forwardRef, useCallback, useMemo, useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

export interface UiPhoneInputProps extends Omit<PhoneInputProps, "inputProps"> {
  fullWidth?: boolean;
  readOnly?: boolean;
  inputProps?: Omit<PhoneInputProps["inputProps"], "ref">;
}

const UiPhoneInput = forwardRef<HTMLInputElement, UiPhoneInputProps>(({ fullWidth, readOnly, ...props }, ref) => {
  const [localRef, setLocalRef] = useState<HTMLInputElement>();

  const setRefs = useCallback(
    (node: HTMLInputElement) => {
      setLocalRef(node);

      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [ref],
  );

  const isEmpty = useMemo(
    () => !localRef?.value || localRef?.value === props.prefix || localRef?.value === "+",
    [localRef?.value, props.prefix],
  );

  return (
    <PhoneInput
      css={cssc([
        classes.wrapper,
        isEmpty && classes.wrapper_empty,
        fullWidth && classes.wrapper_full,
        props.onlyCountries?.length === 1 && classes.wrapper_unselectable,
      ])}
      buttonClass="button"
      {...props}
      placeholder={props.placeholder || ""}
      inputProps={{
        ...props.inputProps,
        ref: setRefs,
      }}
    />
  );
});

export default UiPhoneInput;
