import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const table = (theme: Theme) => css`
  margin: 0 -30px -13px;

  ${theme.breakpoints.down("sm")} {
    margin: 0;

    .devexpress-table-MuiTableCell-paddingCheckbox {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
    }
  }
`;
