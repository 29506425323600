import { VirtualTableState, VirtualTableStateProps } from "@devexpress/dx-react-grid";
import { Grid, GridProps, VirtualTable, VirtualTableProps } from "@devexpress/dx-react-grid-material-ui";
import { t } from "@lingui/macro";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useCallback, useRef, useState } from "react";
import { useMount, useUnmount } from "react-use";
import TableTitleComponent from "src/app/Billing/components/TableComponents/TableTitleComponent";
import UiDxTableHeaderRow from "src/components/UiDxTableHeaderRow";

import useStyles from "./index.css";

const generateClassName = createGenerateClassName({
  seed: "devexpress-virtual-table",
});

interface IProps {
  gridProps: GridProps;
  tableStateProps: VirtualTableStateProps;
  tableProps?: Exclude<VirtualTableProps, "messages" | "stubRowComponent"> & {
    minHeight?: number;
  };
}

const UiVirtualTable: FC<IProps> = ({ gridProps, tableStateProps, tableProps }) => {
  const classes = useStyles();
  const iframe = useRef<HTMLIFrameElement | null>(null);
  const [height, setHeight] = useState(tableProps?.height === "inherit" ? 0 : tableProps?.height);
  const renderContainer = useCallback(
    (props) => {
      if (tableStateProps.loading && gridProps.rows.length === 0) {
        return (
          <tr>
            <td>
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            </td>
          </tr>
        );
      }

      return <VirtualTable.StubRow {...props} />;
    },
    [classes.loader, gridProps.rows.length, tableStateProps.loading],
  );

  const handleResize = useCallback(() => {
    if (iframe.current) {
      setHeight(Math.max(iframe.current?.offsetHeight, tableProps?.minHeight || 0));
    }
  }, [tableProps?.minHeight]);

  useMount(() => {
    if (tableProps?.height === "inherit" && iframe.current) {
      iframe.current?.contentWindow?.addEventListener("resize", handleResize);
      handleResize();
    }
  });

  useUnmount(() => {
    iframe.current?.contentWindow?.removeEventListener("resize", handleResize);
  });

  return (
    <div className={classes.wrapper}>
      <iframe title="virtual-table" className={classes.iframe} ref={iframe} />

      <StylesProvider generateClassName={generateClassName}>
        <Grid {...gridProps}>
          <VirtualTableState {...tableStateProps} />

          <VirtualTable
            {...tableProps}
            height={height}
            messages={{
              noData: t`No Data`,
            }}
            stubRowComponent={renderContainer}
          />

          <UiDxTableHeaderRow titleComponent={TableTitleComponent} />
        </Grid>
      </StylesProvider>
    </div>
  );
};

export default UiVirtualTable;
