import produce from "immer";
import update from "immutability-helper";
import { VariantType } from "notistack";
import { IModulesActionMap, IModulesState } from "src/redux";
import { actionCompile, IActionT } from "src/utils";

import { IPopup, IPopupId } from "./data";

const { createActionCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPopupsActionMap {
  POPUP_ADD__F: {};
  POPUP_ADD__R: {
    importance?: VariantType;
    text: string;
  };
  POPUP_ADD__S: {
    popup: IPopup;
  };

  POPUP_REMOVE: {
    popupId: IPopupId;
  };
}

export const popupAdd = createActionCreator("POPUP_ADD__R");
export type IPopupAddF = IActionT<IModulesActionMap, "POPUP_ADD__F">;
export type IPopupAddR = IActionT<IModulesActionMap, "POPUP_ADD__R">;
export type IPopupAddS = IActionT<IModulesActionMap, "POPUP_ADD__S">;

export const popupRemove = createActionCreator("POPUP_REMOVE");
export type IPopupRemove = IActionT<IModulesActionMap, "POPUP_REMOVE">;

export const popupsReducers = {
  POPUP_ADD__S: (modulesState: IModulesState, action: IPopupAddS): IModulesState => {
    const { popup } = action.payload;
    return update(modulesState, {
      popups: {
        $merge: {
          [popup.id]: popup,
        },
      },
    });
  },
  POPUP_REMOVE: (modulesState: IModulesState, action: IPopupRemove): IModulesState => {
    const { popupId } = action.payload;
    return produce(modulesState, (draft) => {
      delete draft.popups[popupId];
    });
  },
};
