import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import YandexCounter from "src/components/PipelineCreateForm/components/CreatePublicAds/components/YandexCounter";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { EPipelineType } from "src/enums/pipeline";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigVkAds } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigVkAds | null) => void;
  onChangeValidation: (isValid: boolean) => void;
  config: IPipelineConfigVkAds | null;
  persistState?: {
    [key: string]: any;
  };
}

const ConfigVkAds: FC<IProps> = ({
  projectId,
  credentialId,
  pipelineType,
  onChange,
  onChangeValidation,
  config,
  persistState,
}) => {
  const [selectedAccount, setSelectedAccount] = useState<IPipelineConfigVkAds["account"] | null>(config?.account || null);
  const [selectedClient, setSelectedClient] = useState<IPipelineConfigVkAds["client"] | null>(config?.client || null);
  const [isYaCounterEnabled, setYaCounterEnabled] = useState(config?.ya_counter_enabled || false);
  const [yaCredential, setYaCredential] = useState(
    config?.extra_settings?.yandex_metrika.account_credentials_id
      ? {
          id: config?.extra_settings?.yandex_metrika.account_credentials_id,
          name: config?.extra_settings?.yandex_metrika.account_credentials_name,
        }
      : undefined,
  );
  const [yaCounter, setYaCounter] = useState(
    config?.extra_settings?.yandex_metrika.counter
      ? {
          id: config?.extra_settings?.yandex_metrika.counter,
          name: config?.extra_settings?.yandex_metrika.counter_name,
        }
      : undefined,
  );
  const [yaGoals, setYaGoals] = useState(config?.extra_settings?.yandex_metrika.goals || undefined);

  const { data: pipelineTypes } = useQueryPipelineTypes();

  const {
    data: accounts,
    isFetched: accountsFetched,
    isFetching: accountsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.VK_ADS_ACCOUNTS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedAccount) {
          setSelectedAccount(data[0] || null);
        }
      },
    },
  );

  const { data: clients, isFetching: clientsIsFetching } = useQueryCredentialMetaGet(
    {
      credentialId,
      extraParams: {
        account_id: selectedAccount?.account_id,
      },
      object: ECredentialMetaObject.VK_ADS_CLIENTS,
      projectId,
    },
    {
      enabled: selectedAccount?.account_type === "agency",
      onSuccess: (data) => {
        if (!selectedClient) {
          setSelectedClient(data[0] || null);
        }
      },
    },
  );

  const accountOptions = useMemo(
    () =>
      accounts?.map((account) => ({
        data: account,
        label: `${account.account_name} (${account.account_id})`,
        value: account.account_id,
      })) || [],
    [accounts],
  );

  const clientOptions = useMemo(
    () =>
      clients?.map((client) => ({
        data: client,
        label: `${client.name} (${client.id})`,
        value: client.id,
      })) || [],
    [clients],
  );

  const handleSelectAccount = useCallback(
    (value: typeof accountOptions[number]["value"] | null, data: typeof accountOptions[number]["data"] | null) => {
      if (data) {
        setSelectedAccount(data);
        setSelectedClient(null);
      }
    },
    [],
  );

  const handleSelectClient = useCallback(
    (value: typeof clientOptions[number]["value"] | null, data: typeof clientOptions[number]["data"] | null) => {
      if (data) {
        setSelectedClient(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(
      (() => {
        if (isYaCounterEnabled && !yaCounter?.id) {
          return false;
        }

        return Boolean(selectedAccount && (selectedAccount.account_type === "general" || selectedClient));
      })(),
    );

    onChange({
      account: selectedAccount || undefined,
      account_id: selectedAccount?.["account_id"],
      client: selectedClient || undefined,
      client_id: selectedClient?.["id"],
      extra_settings: isYaCounterEnabled
        ? {
            yandex_metrika: {
              account_credentials_id: yaCredential?.id,
              account_credentials_name: yaCredential?.name,
              counter: yaCounter?.id,
              counter_name: yaCounter?.name,
              goals: yaGoals,
            },
          }
        : undefined,
      ya_counter_enabled: isYaCounterEnabled,
    });
  }, [onChangeValidation, onChange, selectedAccount, selectedClient, isYaCounterEnabled, yaCredential, yaCounter, yaGoals]);

  return (
    <>
      {accountOptions.length === 0 && accountsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={accountOptions.length > 7}
          isLoading={accountsIsFetching}
          value={selectedAccount?.account_id}
          onChange={handleSelectAccount}
          options={accountOptions}
          placeholder={<Trans>Ad account</Trans>}
          fullWidth
        />
      )}

      {selectedAccount?.account_type === "agency" && (
        <Box mt={3}>
          <UiSelect
            isLoading={clientsIsFetching}
            value={selectedClient?.id}
            onChange={handleSelectClient}
            options={clientOptions}
            fullWidth
          />
        </Box>
      )}

      {pipelineTypes?.[EPipelineType.YA_METRIKA] && (
        <YandexCounter
          enabled={isYaCounterEnabled}
          credential={yaCredential}
          counter={yaCounter}
          projectId={projectId}
          onToggle={setYaCounterEnabled}
          onChangeCred={setYaCredential}
          onChangeCounter={setYaCounter}
          onChangeGoals={setYaGoals}
          goals={yaGoals}
          persistState={{
            ...persistState,
            config,
          }}
        />
      )}
    </>
  );
};

export default ConfigVkAds;
