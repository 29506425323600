import { Trans } from "@lingui/macro";
import { DialogActions, DialogContent } from "@mui/material";
import { FC } from "react";
import UiButton from "src/components/UiButton";
import UiDialog, { UiDialogProps } from "src/components/UiDialog";
import useQueryBillingUpdateTrial from "src/utils/queries/billing/useQueryBillingUpdateTrial";

const ModalTrialMigrate: FC<UiDialogProps> = (props) => {
  const { mutate, isLoading } = useQueryBillingUpdateTrial();

  return (
    <UiDialog
      keepMounted
      fullWidth
      maxWidth="xs"
      hideCross
      title={<Trans>Upgrade to a new &laquo;Trial&raquo; tariff</Trans>}
      {...props}
    >
      <DialogContent>
        <Trans>
          Now the data of 1 public or advertising cabinet for 1 year for public and 6 months for advertising cabinets will be
          available on the tariff.
        </Trans>
      </DialogContent>

      <DialogActions>
        <UiButton fullWidth onClick={mutate} color="primary" variant="contained" loading={isLoading}>
          <Trans>Ok</Trans>
        </UiButton>
      </DialogActions>
    </UiDialog>
  );
};

export default ModalTrialMigrate;
