import { IOAuthDataRequestPost, IOAuthDataResponsePost } from "src/types/oAuth";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryOAuthPost(
  queryOptions?: IUseQueryMutationOptions<IOAuthDataResponsePost, IOAuthDataRequestPost>,
) {
  return useMutationBase<IOAuthDataResponsePost, IOAuthDataRequestPost>(queryOptions, {
    method: "POST",
    url: `o/authorize/`,
  });
}
