import { t, Trans } from "@lingui/macro";
import { Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import TopNotice from "src/components/TopNotice";
import UiButton from "src/components/UiButton";
import { popupAdd } from "src/redux";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import useQueryMeEmailConfirmSend from "src/utils/queries/user/useQueryMeEmailConfirmSend";

import * as classes from "./index.css";

const EmailConfirmationNotice: FC = () => {
  const { data: user } = useQueryMe();
  const dispatch = useDispatch();

  const { mutate: sendConfirm, isLoading: sendConfirmIsLoading } = useQueryMeEmailConfirmSend({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Email sent, please follow the instructions in the email.`,
        }),
      );
    },
  });

  const handleSendConfirm = useCallback(() => {
    sendConfirm(undefined);
  }, [sendConfirm]);

  if (!user) {
    return null;
  }

  return (
    <>
      <TopNotice variant="warning" open={true}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} flexWrap="wrap" css={classes.stack}>
          <div>
            <Trans>Please confirm your email. Follow the link in email sent once you signed up to DataFan.</Trans>
          </div>
          <UiButton
            loading={sendConfirmIsLoading}
            onClick={handleSendConfirm}
            variant="outlined"
            disableElevation
            css={classes.button}
            size="small"
          >
            <Trans>Send confirmation letter</Trans>
          </UiButton>
        </Stack>
      </TopNotice>
    </>
  );
};

export default EmailConfirmationNotice;
