import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import ConnectorInstruction from "src/components/ConnectorInstruction";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import ConnectorExcelIcon from "src/icons/ConnectorExcelIcon";

import * as classes from "./index.css";

const ConnectorExcelHome: FC = () => (
  <Section>
    <SectionHead
      title={<Trans>Connector DataFan — Excel</Trans>}
      subTitle={<Trans>Export data from your publics and ad accounts to Microsoft Excel</Trans>}
      icon={<ConnectorExcelIcon />}
    />

    <Typography variant="body2" mt={5} textAlign="center">
      <UiButton component={RouterLink} to="create/">
        <Trans>Create report</Trans>
      </UiButton>
    </Typography>

    <div css={classes.instruction}>
      <Typography variant="h2" mb={6}>
        <Trans>How to create a Google Data Studio report via DataFan</Trans>
      </Typography>

      <ConnectorInstruction
        steps={[
          {
            text: <Trans>Connect public or ad account or select from those connected to DataFan</Trans>,
            title: <Trans>Choose public or ad account</Trans>,
          },
          {
            text: <Trans>It could take several minutes to collect data. Please wait.</Trans>,
            title: <Trans>Collect data</Trans>,
          },
          {
            text: <Trans>Select appropriate date range for your data</Trans>,
            title: <Trans>Select date range</Trans>,
          },
          {
            text: <Trans>Please wait till excel report is generated</Trans>,
            title: <Trans>Generate excel report</Trans>,
          },
          {
            text: <Trans>Report is available. Enjoy your data</Trans>,
            title: <Trans>Download the report</Trans>,
          },
        ]}
      />

      <Box textAlign="center" mt={6}>
        <UiButton component={RouterLink} to="create/">
          <Trans>Create report</Trans>
        </UiButton>
      </Box>
    </div>
  </Section>
);

export default ConnectorExcelHome;
