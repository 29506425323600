import { QueryKey } from "react-query/types/core/types";
import { IPipeline } from "src/redux";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

export default function useQueryPipelineRestart(
  queryKey: QueryKey,
  pipelineId: IPipeline["id"],
  queryOptions?: IUseQueryBaseOptions<unknown>,
) {
  return useQueryBase(queryKey, queryOptions, {
    method: "POST",
    url: `pipeline/${pipelineId}/restart/`,
  });
}
