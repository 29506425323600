import { Trans } from "@lingui/macro";
import { OfflineBolt } from "@mui/icons-material";
import { Link } from "@mui/material";
import { FC } from "react";
import Message from "src/components/Message";

const Page404: FC = ({ children }) => (
  <Message
    icon={<OfflineBolt />}
    title={404}
    message={
      <>
        <Trans>Go back to the</Trans>
        &nbsp;
        {children || (
          <Link href="/" style={{ textDecoration: "none" }}>
            <Trans>Reports</Trans>
          </Link>
        )}
      </>
    }
  />
);

export default Page404;
