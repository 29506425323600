import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import { FC } from "react";
import { EExportStatus } from "src/enums/export";
import { IExport } from "src/types/export";

interface IProps {
  report: IExport;
}

const CellStatus: FC<IProps> = ({ report }) => {
  if (report.status === EExportStatus.STARTED) {
    return (
      <Typography color="primary">
        <Trans>Is created</Trans>
      </Typography>
    );
  } else if (report.status === EExportStatus.FAILURE) {
    return (
      <Typography color="secondary">
        <Trans>Failure</Trans>
      </Typography>
    );
  } else {
    return (
      <Typography>
        <Trans>Completed</Trans>
      </Typography>
    );
  }
};

export default CellStatus;
