import { Trans } from "@lingui/macro";
import { DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { FC, useMemo } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import { IPipeline } from "src/types/pipeline";
import { pipelineMetaIsSpreadsheet } from "src/utils/guards/pipelineMeta";

interface IProps {
  pipeline: IPipeline;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DialogArchive: FC<IProps> = ({ pipeline, isOpen, isLoading, onClose, onSubmit }) => {
  const googleSheetsAutoUpDocs = useMemo(
    () =>
      pipeline.pipeline_meta.filter(
        (meta) => pipelineMetaIsSpreadsheet(meta) && meta.pipeline_meta.spreadsheet.is_autoupdate_enabled,
      ),
    [pipeline.pipeline_meta],
  );

  return (
    <UiDialog open={isOpen} title={<Trans>Archive</Trans>} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography variant="body2">
          <Trans>
            Attention! After moving to the archive, data collection will stop. In this case, the collected data will be
            available.
          </Trans>

          {googleSheetsAutoUpDocs && googleSheetsAutoUpDocs.length > 0 && (
            <Box mt={2}>
              <Trans>Also note that auto-updating of data in the following Google Sheets will be suspended</Trans>
              {": "}
              {googleSheetsAutoUpDocs.map(
                (meta, idx) =>
                  pipelineMetaIsSpreadsheet(meta) && (
                    <div key={idx}>
                      <Link component="a" href={meta.pipeline_meta.spreadsheet.url} target="_blank">
                        {meta.pipeline_meta.spreadsheet.name}
                      </Link>
                    </div>
                  ),
              )}
            </Box>
          )}
        </Typography>
      </DialogContent>

      <DialogActions>
        <UiButton disabled={isLoading} onClick={onClose} variant="text" color="primary">
          <Trans>Cancel</Trans>
        </UiButton>

        <UiButton loading={isLoading} onClick={onSubmit} color="primary">
          <Trans>Ok</Trans>
        </UiButton>
      </DialogActions>
    </UiDialog>
  );
};

export default DialogArchive;
