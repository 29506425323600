import { css } from "@emotion/react";
import { Theme } from "@mui/material/styles";

export const paper = css`
  height: 100%;
`;

export const card_list = (theme: Theme) => css`
  display: flex;
  padding: 18px 15px;
  align-items: center;
  position: relative;
  text-decoration: none;

  .MuiTypography-body2 {
    font-size: 1rem;
  }

  ${theme.breakpoints.down("sm")} {
    display: block;
    padding: 18px 26px;
  }
`;

export const card_grid = css`
  display: block;
  padding: 30px 35px;
  text-align: center;
  position: relative;
  text-decoration: none;

  .MuiTypography-body2 {
    font-size: 0.875rem;
  }
`;

export const card__group = css`
  display: flex;
  align-items: center;
  overflow: hidden;

  .css-${card_grid.name} & {
    display: block;
    overflow: visible;
  }
`;

export const card__group_main = (theme: Theme) => css`
  flex-grow: 1;

  ${theme.breakpoints.down("sm")} {
    margin: 0 -10px;
  }
`;

export const card__group_second = (theme: Theme) => css`
  flex-shrink: 0;

  .css-${card_grid.name} & {
    padding: 0;
    margin: 0;

    :before {
      display: none;
    }
  }

  ${theme.breakpoints.down("sm")} {
    padding-top: 18px;
    justify-content: space-between;
    margin: 18px -10px 0;
    position: relative;

    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      display: block;
      height: 1px;
      background: #e2e2e2;
    }
  }
`;

export const card__group_type_titles = (theme: Theme) => css`
  flex-grow: 1;

  ${theme.breakpoints.down("xl")} {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .css-${card_grid.name} & {
    margin: 0 -35px;
    padding: 0 10px;
  }
`;

export const card__group_status_link = (theme: Theme) => css`
  display: flex;
  margin: 0;
  z-index: 1;

  ${theme.breakpoints.down("md")} {
    display: block;
  }

  .css-${card_grid.name} & {
    display: block;
    width: auto;
    border-top: 1px solid #e2e2e2;
    margin: 20px -35px;
    padding: 16px 5px 0;
  }
`;

export const card__box = (theme: Theme) => css`
  margin: 0 15px;
  flex-shrink: 0;

  .css-${card_grid.name} & {
    display: block;
    width: auto;
    margin: 0;
  }

  ${theme.breakpoints.down("lg")} {
    margin: 0 10px;
  }
`;

export const card__box_picture = css`
  width: 55px;
`;

export const card__icn = css`
  :before {
    content: "+";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
  }

  padding-top: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  color: #55ade0;
  border: 1px solid;
  font-size: 24px;

  .css-${card_grid.name} & {
    font-size: 72px;
  }
`;

export const card__box_type = css`
  .css-${card_grid.name} & {
    padding-top: 22px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  color: #55ade0;
  width: 170px;
`;

export const card__box_link = (theme: Theme) => css`
  width: 160px;
  text-align: right;

  ${theme.breakpoints.down("xl")} {
    margin-top: 10px;
  }

  .css-${card_grid.name} & {
    text-align: center;
    margin-top: ${theme.spacing(1.6)};
  }
`;

export const favicon = css`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  border: 3px solid #ffffff;
  position: absolute;
  bottom: -3px;
  right: -3px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  .css-${card_grid.name} & {
    bottom: -12px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const status = (color: string) => css`
  color: ${color};
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color === "#505050" ? "#20a8a6" : color};
    margin: 0 8px 0 0;
    position: relative;
    top: -2px;

    .css-${card_grid.name} & {
      display: none;
    }
  }
`;

export const name = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #000000;

  .css-${card_grid.name} & {
    white-space: normal;
    display: -webkit-box;
    height: 2.86em;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const picture = css`
  width: 100%;
  height: 100%;
  object-fit: cover;

  .css-${card_grid.name} & {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
`;
export const card__btn = css`
  position: static;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .css-${card_grid.name} & {
    margin-top: 21px;
  }
`;
