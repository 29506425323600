import { Trans } from "@lingui/macro";
import { FC, ReactNode, useCallback, useMemo } from "react";
import UiButton, { UiButtonProps } from "src/components/UiButton";
import { EPipelineType } from "src/enums/pipeline";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

interface IProps {
  source: EPipelineType;
  persistState?: {
    [key: string]: any;
  };
  persistUrl?: string;
  extraParams?: {
    [key: string]: any;
  };
  ButtonProps?: UiButtonProps;
  text?: string | ReactNode;
}

const AddProvider: FC<IProps> = ({ source, persistState, persistUrl, ButtonProps, extraParams, text }) => {
  const { auth, status } = useSocialConnect();
  const { data: pipelineTypes } = useQueryPipelineTypes();

  const pipelineType = useMemo(() => pipelineTypes?.[source], [pipelineTypes, source]);

  const handleClick = useCallback(() => {
    if (pipelineType) {
      auth({
        action: "connect",
        extraParams,
        nextState: persistState,
        nextUrl: persistUrl,
        providerId: Number(pipelineType.authIndex),
      });
    }
  }, [auth, extraParams, persistState, persistUrl, pipelineType]);

  return useMemo(
    () => (
      <UiButton onClick={handleClick} loading={ButtonProps?.loading || status === "loading"} fullWidth {...ButtonProps}>
        {text || pipelineType?.authTitle || <Trans>Create</Trans>}
      </UiButton>
    ),
    [ButtonProps, handleClick, pipelineType?.authTitle, status, text],
  );
};

export default AddProvider;
