import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const dialog = css`
  .MuiPaper-root {
    padding: 30px;
    max-width: 850px;
  }
`;

export const paper = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
  height: 400px;

  ${theme.breakpoints.down("sm")} {
    padding: 30px;
  }
`;

export const icon = (theme: Theme) => css`
  text-align: center;
  padding-bottom: 20px;
  color: ${theme.palette.secondary.main};

  .icon {
    width: 60px;
    height: 60px;
    transform: rotate(-45deg);
  }
`;

export const icon_success = (theme: Theme) => css`
  color: ${theme.palette.aqua.main};

  .icon {
    transform: rotate(0);
  }
`;

export const pp = css`
  display: inline-flex;
  margin: 40px auto 0;
`;

export const pp__input = (theme: Theme) => css`
  max-width: 70px;
  width: 70px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    max-width: 100%;
  }
`;
