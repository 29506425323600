import { Trans } from "@lingui/macro";
import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import { IconInfoCircle } from "@tabler/icons";
import { FC, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import parseFeatures from "src/app/ConnectorGDS/utils/parseFeatures";
import MediaFavicon from "src/components/MediaFavicon";
import UiButton from "src/components/UiButton";
import ZoomImg from "src/components/ZoomImg";
import { useAppContext } from "src/context/app/appContext";
import CrossIcon from "src/icons/CrossIcon";
import { IAggTmpl } from "src/types/tmpl";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

interface IProps {
  tmpl: IAggTmpl;
}

const Card: FC<IProps> = ({ tmpl }) => {
  const {
    state: { language },
  } = useAppContext();
  const [isOpenDetails, setOpenDetails] = useState<boolean>(false);
  const image = useMemo(() => tmpl.tmpl_image?.find((i) => i.language === language)?.image, [language, tmpl.tmpl_image]);

  const data = useMemo(() => tmpl.tmpl_description?.find((d) => d.language === language), [language, tmpl.tmpl_description]);

  const handleToggleDetails = useCallback(() => {
    setOpenDetails((state) => !state);
  }, []);

  const emptyTmplIds = useMemo(() => process.env.REACT_APP__GDS_EMPTY_TMPLS?.split(",")?.map((id) => Number(id)), []);

  const features = useMemo(
    () => (!!data?.short_description ? parseFeatures(data.short_description) : undefined),
    [data?.short_description],
  );

  return (
    <Paper css={classes.paper}>
      <div css={cssc([classes.head, emptyTmplIds?.includes(tmpl.id) && classes.head_empty])}>
        {tmpl.source
          .filter((source, index, self) => self.indexOf(source) === index)
          .map((media) => (
            <MediaFavicon media={media} key={media} css={classes.head__icon} />
          ))}

        {!emptyTmplIds?.includes(tmpl.id) && (
          <>
            <Typography variant="body2" css={classes.head__name}>
              {data?.name || tmpl.name}
            </Typography>

            {data && (
              <IconButton css={classes.head__info} size="extraSmall" onClick={handleToggleDetails}>
                {isOpenDetails ? <CrossIcon /> : <IconInfoCircle />}
              </IconButton>
            )}
          </>
        )}
      </div>

      {emptyTmplIds?.includes(tmpl.id) ? (
        <div css={classes.emptyTmplDesc}>
          <Typography css={classes.emptyTmplDesc__inn}>
            <Trans>Export raw data to Google&nbsp;Data&nbsp;Studio</Trans>
          </Typography>
        </div>
      ) : (
        <>
          {!isOpenDetails && <div css={classes.spacer} />}

          <Collapse in={!isOpenDetails}>
            <ZoomImg css={classes.image} src={image} alt={tmpl.name} />
          </Collapse>

          <Collapse in={isOpenDetails}>
            <div css={classes.description}>{data?.description}</div>
          </Collapse>

          <ul css={classes.benefits}>
            {features?.map(({ lines }, idx) =>
              lines.map((line, idx2) => (
                <Typography key={`${idx}-${idx2}`} component="li" css={classes.benefits__item}>
                  {line}
                </Typography>
              )),
            )}
          </ul>
        </>
      )}

      <div css={classes.foot}>
        <UiButton component={Link} to={`/connector/gds/create/${tmpl.id}/`}>
          <Trans>Create report</Trans>
        </UiButton>
      </div>
    </Paper>
  );
};

export default Card;
