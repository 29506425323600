export default function animatedScroll(x: number | undefined, y: number | undefined) {
  if (typeof window.scrollBy === "function") {
    window.scrollBy({
      behavior: "smooth",
      left: x,
      top: y,
    });
  } else {
    window.scrollTo(x || 0, y || 0);
  }
}
