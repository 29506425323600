import { FC, ReactElement } from "react";
import MediaFavicon from "src/components/MediaFavicon";
import UiButton from "src/components/UiButton";
import { ISocialAuthApps } from "src/fixtures/socialAuthApps";

import useStyles from "./index.css";

interface IProps {
  medium: ISocialAuthApps["medium"];
  onClick: () => void;
  isLoading: boolean;
  label: ReactElement;
}

const SocialSignBtn: FC<IProps> = ({ medium, onClick, isLoading, label }) => {
  const classes = useStyles();

  return (
    <UiButton
      disabled={isLoading}
      onClick={onClick}
      className={classes.socialBtn}
      variant="contained"
      startIcon={<MediaFavicon media={medium} />}
    >
      <div className={classes.socialBtnRoot}>{label}</div>
    </UiButton>
  );
};

export default SocialSignBtn;
