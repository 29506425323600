import { isString } from "./is";

// translations
// --------------

enum EFailureTranslations {
  EN = "EN",
  RU = "RU",
}

type IFailureTranslations = { [language in EFailureTranslations]: string };

// codes
// -------

export enum EFailureCodes {
  UNKNOWN = "UNKNOWN",

  NET__INVALID = "NET__INVALID",
  NET__FORBIDDEN = "NET__FORBIDDEN",
  NET__NOT_FOUND = "NET__NOT_FOUND",
  NET__CONFLICT = "NET__CONFLICT",
  NET__PRECONDITION_FAILED = "NET__PRECONDITION_FAILED",
  NET__SERVER_ERROR = "NET__SERVER_ERROR",

  USER__PASSWORD_INVALID = "USER__PASSWORD_INVALID",
  USER__REGISTRATION_REQUIRED = "USER__REGISTRATION_REQUIRED",
  USER__ALREADY_REGISTERED = "USER__ALREADY_REGISTERED",
  USER__EMAIL_NOT_EXISTS = "USER__EMAIL_NOT_EXISTS",
  USER__LOGIN_REQUIRED = "USER__LOGIN_REQUIRED",
  USER__TOKEN_INVALID = "USER__TOKEN_INVALID",
  USER__TOKEN_STALE = "USER__TOKEN_STALE",
  USER__OWNER_CANNOT_MANAGE = "USER__OWNER_CANNOT_MANAGE",

  PAGE__ALREADY_CONNECTED = "PAGE__ALREADY_CONNECTED",
  PAGE__TYPE_NOT_SUPPORTED = "PAGE__TYPE_NOT_SUPPORTED",
  PAGE__TOKEN_INVALID = "PAGE__TOKEN_INVALID",
}

type IFailureCodeTranslations = {
  [code in EFailureCodes]: IFailureTranslations;
};

const FailureCodeTranslations: IFailureCodeTranslations = {
  NET__CONFLICT: { EN: "Conflict", RU: "Конфликт" },

  NET__FORBIDDEN: { EN: "Forbidden", RU: "Запрещено" },
  NET__INVALID: { EN: "Invalid request", RU: "Неверный запрос" },
  NET__NOT_FOUND: { EN: "Not found", RU: "Не найдено" },
  NET__PRECONDITION_FAILED: {
    EN: "Precondition failed",
    RU: "Нарушение ограничений",
  },
  NET__SERVER_ERROR: { EN: "Server error", RU: "Серверная ошибка" },
  PAGE__ALREADY_CONNECTED: {
    EN: "Page already connected",
    RU: "Страница уже подключена",
  },

  PAGE__TOKEN_INVALID: {
    EN: "Invalid page access token",
    RU: "Неверный токен страницы",
  },
  PAGE__TYPE_NOT_SUPPORTED: {
    EN: "Page type not supported",
    RU: "Тип страницы не поддерживается",
  },
  UNKNOWN: { EN: "Unknown error", RU: "Неизвестная ошибка" },
  USER__ALREADY_REGISTERED: {
    EN: "Already registered",
    RU: "Адрес уже зарегистрирован",
  },
  USER__EMAIL_NOT_EXISTS: {
    EN: "Email is not registered",
    RU: "Email не зарегистрирован",
  },
  USER__LOGIN_REQUIRED: {
    EN: "Login required",
    RU: "Необходимо авторизоваться",
  },
  USER__OWNER_CANNOT_MANAGE: {
    EN: "Owner cannot be manager",
    RU: "Владелец не может быть менеджером",
  },
  USER__PASSWORD_INVALID: { EN: "Invalid password", RU: "Неверный пароль" },

  USER__REGISTRATION_REQUIRED: {
    EN: "Registration required",
    RU: "Необходимо зарегистрироваться",
  },
  USER__TOKEN_INVALID: { EN: "Invalid token", RU: "Поврежденный токен" },
  USER__TOKEN_STALE: { EN: "Stale token", RU: "Истекший токен" },
};

// types
// -------

export enum EFailureTypes {
  API = "API",
  NET = "NET",
}

type IFailureTypeIndexCodes = {
  [type in EFailureTypes]: {
    [index: number]: EFailureCodes;
  };
};

const FailureTypeIndexCodes: IFailureTypeIndexCodes = {
  API: {
    1: EFailureCodes.UNKNOWN,
    101: EFailureCodes.USER__PASSWORD_INVALID,
    102: EFailureCodes.USER__ALREADY_REGISTERED,
    103: EFailureCodes.USER__EMAIL_NOT_EXISTS,
    141: EFailureCodes.NET__INVALID,
    142: EFailureCodes.USER__TOKEN_STALE,
    143: EFailureCodes.USER__TOKEN_INVALID,
    144: EFailureCodes.USER__TOKEN_INVALID,
    145: EFailureCodes.USER__TOKEN_STALE,
    2: EFailureCodes.NET__INVALID,
    3: EFailureCodes.NET__FORBIDDEN,
    301: EFailureCodes.USER__OWNER_CANNOT_MANAGE,
    4: EFailureCodes.NET__CONFLICT,
    401: EFailureCodes.PAGE__ALREADY_CONNECTED,
    402: EFailureCodes.PAGE__TYPE_NOT_SUPPORTED,
    403: EFailureCodes.PAGE__TOKEN_INVALID,
    5: EFailureCodes.NET__PRECONDITION_FAILED,
  },

  NET: {
    400: EFailureCodes.NET__INVALID,
    403: EFailureCodes.NET__FORBIDDEN,
    404: EFailureCodes.NET__NOT_FOUND,
    409: EFailureCodes.NET__CONFLICT,
    412: EFailureCodes.NET__PRECONDITION_FAILED,
    500: EFailureCodes.NET__SERVER_ERROR,
  },
};

// class
// -------

function inFailureCodes(value: any): value is EFailureCodes {
  const values: string[] = Object.values(EFailureCodes);
  return isString(value) && values.includes(value);
}

interface IFailureConstructor {
  type: EFailureTypes;
  index?: number;
  originalMessage?: string;
  extraData?: object;
}

// tslint:disable-next-line:max-line-length
// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
export class Failure /* extends Error */ {
  public code: EFailureCodes;
  public message: string;
  public translations: IFailureTranslations;
  public originalMessage?: string;
  public extraData?: object;

  constructor(input: EFailureCodes | IFailureConstructor) {
    let code: EFailureCodes;
    let translations: IFailureTranslations;

    if (inFailureCodes(input)) {
      code = input;
    } else {
      const { type, index = 0, originalMessage, extraData } = input;
      code = FailureTypeIndexCodes[type][index];
      this.originalMessage = originalMessage;
      this.extraData = extraData;
    }
    this.code = code;

    translations = FailureCodeTranslations[code] || FailureCodeTranslations.UNKNOWN;
    this.translations = translations;
    this.message = translations.RU;
  }
}
