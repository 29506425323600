import {
  IPipelineMeta,
  IPipelineMetaReportSettings,
  IPipelineMetaReportVariables,
  IPipelineMetaSpreadsheet,
} from "src/types/pipeline";

export function pipelineMetaIsSpreadsheet(meta: IPipelineMeta): meta is Omit<IPipelineMeta, "pipeline_meta"> & {
  pipeline_meta: IPipelineMetaSpreadsheet;
} {
  return (meta.pipeline_meta as IPipelineMetaSpreadsheet).spreadsheet !== undefined;
}

export function pipelineMetaIsReportSettings(meta: IPipelineMeta): meta is Omit<IPipelineMeta, "pipeline_meta"> & {
  pipeline_meta: IPipelineMetaReportSettings;
} {
  return meta.sign === "report_settings";
}

export function pipelineMetaIsReportVariables(meta: IPipelineMeta): meta is Omit<IPipelineMeta, "pipeline_meta"> & {
  pipeline_meta: IPipelineMetaReportVariables;
} {
  return meta.sign === "report_variables";
}
