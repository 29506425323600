import { css } from "@emotion/react/macro";
import { Popper, Theme } from "@mui/material";
import { alpha, createTheme } from "@mui/material/styles";
import { IconCheck } from "@tabler/icons";
import { forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const LinkBehavior = forwardRef<any, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>((props, ref) => {
  const { href, ...other } = props;

  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    aqua: Palette["primary"];
    neutral: Palette["primary"];
    cyan: Palette["primary"];
  }

  interface PaletteOptions {
    aqua: PaletteOptions["primary"];
    neutral: PaletteOptions["primary"];
    cyan: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    filled: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    paragraph: true;
  }
}

// Hack for puppeteer (print-report) viewport
const isReportExport = /^\/app\/report-export/.test(window.location.pathname);
const breakPointRatio = isReportExport ? 0.75 : 1;

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1280 * breakPointRatio,
      md: 1024 * breakPointRatio,
      sm: 768 * breakPointRatio,
      xl: 1536 * breakPointRatio,
      xs: 0,
    },
  },
});

const checkbox = css`
  ${".MuiCheckbox-root.Mui-disabled &"} {
    border-color: #bebebe !important;
    background: #f6f6f6 !important;
    color: #bebebe !important;
  }

  ${".MuiCheckbox-root.Mui-checked &"} {
    border-color: #55ade0;
    color: #000000;
  }

  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #505050;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const materialTheme = createTheme({
  ...defaultTheme,

  components: {
    MuiAlert: {
      defaultProps: {
        security: "info",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },

        standardError: {
          color: "#505050",
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: alpha("#000000", 0.1),
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "#e2e2e2",
            borderColor: "transparent",
            color: "#ffffff",
          },

          border: "1px solid transparent",
        },

        disableElevation: {
          boxShadow: "none !important",
        },

        endIcon: {
          margin: 0,
        },

        outlined: {
          "&.Mui-disabled": {
            boxShadow: "none !important",
          },
        },

        root: {
          "& .icon-tabler": {
            margin: -2,
          },

          "& > div": {
            padding: "0 7px",
          },

          "&.MuiButton-containedNeutral": {
            "&:hover": {
              background: "#cccccc",
              color: "#000000",
            },

            color: "#505050",
          },

          "&.MuiButton-outlinedNeutral": {
            "& .MuiTouchRipple-root": {
              color: "#c2d8dd",
            },

            "&:hover": {
              color: "#000000",
            },

            borderColor: "#cccccc",
            color: "#505050",
          },

          "&.MuiButton-textNeutral": {
            "& .MuiTouchRipple-root": {
              color: "#c2d8dd",
            },

            "&:hover": {
              color: "#000000",
            },

            color: "#505050",
          },

          "&:hover": {
            boxShadow: "0px 4px 10px #00000029",
          },
          borderRadius: 8,
          boxShadow: "0px 4px 10px #00000029",
          fontSize: "1rem",
          lineHeight: "20px",
          minWidth: 46,
          padding: "12px 11px !important",
          textTransform: "none",
        },

        sizeLarge: {
          lineHeight: "24px",
          padding: "13px 20px !important",
        },

        sizeSmall: {
          lineHeight: "16px",
        },

        startIcon: {
          margin: 0,
        },

        text: {
          border: "1px solid transparent",
          boxShadow: "none !important",
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <div css={checkbox}>
            <IconCheck />
          </div>
        ),
        disableRipple: true,
        icon: <div css={checkbox} />,
      },
      styleOverrides: {
        root: {
          margin: 0,
          padding: 6,
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          "&.MuiDialog-paperScrollBody": {
            overflow: "visible",
          },

          borderRadius: 8,
          boxShadow: "0px 10px 30px #0000001A",

          [defaultTheme.breakpoints.down("sm")]: {
            margin: "0 5px",
            width: "calc(100% - 10px)",
          },
        },

        paperFullScreen: {
          borderRadius: 0,
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: "visible",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontSize: "1.125rem",
          fontWeight: 600,
          padding: 0,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginTop: 4,
        },

        root: {
          "& .MuiCheckbox-root": {
            marginLeft: 4,
          },

          alignItems: "flex-start",
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          "&:hover": {
            color: "#CE383A",
          },

          color: "#DF5759",
        },

        root: {
          "& .MuiTouchRipple-root": {
            color: "#c2d8dd",
          },

          "&.MuiIconButton-sizeExtraSmall": {
            "&:hover": {
              background: "transparent",
              color: "#000000",
            },

            padding: 0,
            transition: "color 150ms",
          },

          "&:hover": {
            background: "#f1f6f8",
          },
        },

        sizeLarge: {
          padding: 11,
        },
      },
    },

    MuiLink: {
      defaultProps: {
        // @ts-ignore
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          fontSize: "inherit",
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          "& .MuiTouchRipple-root": {
            color: "#c2d8dd",
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "#F1F6F8",
          },
          padding: "10px 16px",
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: "#000000",
          fontSize: "1rem",
          fontWeight: 600,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiListItemText-root .MuiTypography-root": {
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
          },

          "&.Mui-focusVisible": {
            background: "#f1f6f8",
          },

          "&:hover": {
            background: "#f1f6f8",
          },

          ".MuiTouchRipple-root": {
            color: "#c2d8dd",
          },

          color: "#000000",
          fontSize: "1rem",
          fontWeight: 600,
          padding: "10px 13px",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#505050",
            lineHeight: "24px",
            opacity: 1,
          },

          color: "#000000",
          height: "1rem",
          lineHeight: 1,
          padding: "14px 16px 16px",
        },

        inputAdornedEnd: {
          paddingRight: 0,
        },

        inputAdornedStart: {
          paddingLeft: 0,
        },

        inputMultiline: {
          padding: 0,
        },

        multiline: {
          "& .MuiInputAdornment-root": {
            height: "24px",
            marginLeft: "auto",
          },

          flexDirection: "column",
        },

        notchedOutline: {
          ".MuiOutlinedInput-root.Mui-disabled &": {
            borderColor: "#E2E2E2 !important",
          },

          ".MuiOutlinedInput-root.Mui-disabled:hover &": {
            borderColor: "#E2E2E2 !important",
          },

          ".MuiOutlinedInput-root.Mui-focused &": {
            borderColor: "#505050 !important",
            borderWidth: "1px !important",
          },

          ".MuiOutlinedInput-root:hover &": {
            borderColor: "#505050",
          },

          borderColor: "#cccccc",
          borderRadius: 8,
        },

        root: {
          "& .MuiInputAdornment-root .icon-tabler-copy": {
            "&:hover": {
              color: "#000000  !important",
            },

            color: "#505050 !important",
          },

          "& input[type=password] + .MuiInputAdornment-positionEnd": {
            visibility: "hidden",
          },

          "& input[type=password]:disabled + .MuiInputAdornment-positionEnd": {
            visibility: "hidden !important",
          },

          "&.Mui-disabled": {
            backgroundColor: "#F6F6F6 !important",
          },

          "&.Mui-disabled .MuiInputAdornment-root svg": {
            color: "#e2e2e2",
          },

          "&.Mui-disabled:hover .MuiInputAdornment-root svg": {
            color: "#e2e2e2",
          },

          "&.Mui-focused .MuiInputAdornment-root svg": {
            color: "#000000",
          },

          "&.Mui-focused input[type=password] + .MuiInputAdornment-positionEnd": {
            visibility: "visible",
          },

          "&.MuiInputBase-fullWidth": {
            maxWidth: "100%",
          },

          "&:hover .MuiInputAdornment-root svg": {
            color: "#000000",
          },

          "&:hover input[type=password] + .MuiInputAdornment-positionEnd": {
            visibility: "visible",
          },

          maxWidth: 300,
          width: "100%",
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        ellipsis: {
          lineHeight: 2.5,
          userSelect: "none",
        },

        root: {
          "& .MuiTouchRipple-root": {
            display: "none",
          },

          "&.Mui-selected": {
            background: "#E8F0F3 !important",
            color: "#000000 !important",
            cursor: "default",
          },

          "&:hover": {
            background: "transparent",
            color: "#000000",
          },

          borderRadius: 8,
          color: "#505050",
          fontWeight: 600,
          height: 36,
          lineHeight: 0,
          margin: 0,
          minWidth: 36,
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 10,
      },
      styleOverrides: {
        elevation0: {
          boxShadow: "none",
        },
        elevation1: {
          boxShadow: "0px 10px 4px #00000024",
        },
        elevation10: {
          boxShadow: "0px 10px 40px #00000024",
        },
        elevation11: {
          boxShadow: "0px 10px 44px #00000024",
        },
        elevation12: {
          boxShadow: "0px 10px 48px #00000024",
        },
        elevation13: {
          boxShadow: "0px 10px 52px #00000024",
        },
        elevation14: {
          boxShadow: "0px 10px 56px #00000024",
        },
        elevation15: {
          boxShadow: "0px 10px 60px #00000024",
        },
        elevation16: {
          boxShadow: "0px 10px 64px #00000024",
        },
        elevation2: {
          boxShadow: "0px 10px 8px #00000024",
        },
        elevation3: {
          boxShadow: "0px 10px 12px #00000024",
        },
        elevation4: {
          boxShadow: "0px 10px 16px #00000024",
        },
        elevation5: {
          boxShadow: "0px 10px 20px #00000024",
        },
        elevation6: {
          boxShadow: "0px 10px 24px #00000024",
        },
        elevation7: {
          boxShadow: "0px 10px 28px #00000024",
        },
        elevation8: {
          boxShadow: "0px 10px 32px #00000024",
        },
        elevation9: {
          boxShadow: "0px 10px 36px #00000024",
        },
        root: {
          borderRadius: 8,
          boxShadow: "inherit",
        },
      },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            background: "#f1f6f8",
            boxShadow: "none",
          },
        },
      ],
    },

    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          marginLeft: 4,
          padding: 5,
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        select: {
          boxSizing: "border-box",
          minHeight: 46,
          paddingBottom: 14,
          paddingTop: 16,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          "& .MuiTouchRipple-root": {
            color: "#c2d8dd",
          },

          "&.Mui-selected": {
            color: "#000000",
          },

          "&:hover": {
            color: "#000000",
          },

          color: "#505050",
          fontSize: "1.125rem",
          fontWeight: 600,
          padding: "18px 30px",
          textTransform: "none",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "red",
          fontWeight: "bold",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 6,
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&:first-of-type": {
            borderRadius: "8px 0 0 8px",
          },

          "&:last-of-type": {
            borderRadius: "0 8px 8px 0",
          },

          borderColor: "#cccccc",
          paddingBottom: 10,
          paddingTop: 10,
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        PopperComponent: (props) => {
          // fix tooltip position without mousemove
          if (!(props.anchorEl as any)?.getBoundingClientRect().top) {
            return null;
          }

          return <Popper {...props} />;
        },
        enterTouchDelay: 0,
        followCursor: true,
        placement: "bottom-start",
      },

      styleOverrides: {
        tooltip: {
          background: "#505050",
          borderRadius: 8,
          fontSize: "0.875rem",
          padding: "8px 13px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: "0.5em",
        },
      },

      variants: [
        {
          props: {
            variant: "paragraph",
          },
          style: {
            "& a": {
              "&:hover": {
                color: "#46a0d4",
              },

              color: "#55ade0",
            },

            "& strong": {
              fontWeight: 600,
            },

            display: "block",

            margin: "20px 0",
          },
        },
      ],
    },
  },

  palette: {
    aqua: {
      contrastText: "#ffffff",
      main: "#20a8a6",
    },
    background: {
      default: "#f9fbfc",
    },
    cyan: {
      contrastText: "#000000",
      dark: "#e8f0f3",
      light: "#f9fbfc",
      main: "#f1f6f8",
    },
    neutral: {
      contrastText: "#000000",
      dark: "#505050",
      light: "#e2e2e2",
      main: "#cccccc",
    },
    primary: {
      contrastText: "#ffffff",
      dark: "#46a0d4",
      light: "#75cbfc",
      main: "#55ade0",
    },
    secondary: {
      contrastText: "#ffffff",
      dark: "#ce383a",
      light: "#ff8886",
      main: "#df5759",
    },
  },

  spacing: 10,

  typography: {
    allVariants: {
      color: "#505050",
    },

    body1: {
      fontSize: "0.875rem",
    },
    body2: {
      fontSize: "1rem",
    },
    caption: {
      color: "#cccccc",
      fontSize: "0.625rem",
    },
    fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
    h1: {
      color: "#000000",
      fontSize: "2.25rem",
      fontWeight: 600,

      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      color: "#000000",
      fontSize: "1.75rem",
      fontWeight: 600,

      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.375rem",
      },
    },
    h3: {
      color: "#000000",
      fontSize: "1.125rem",
      fontWeight: 600,
    },
    h4: {
      color: "#000000",
      fontSize: "1rem",
      fontWeight: 600,
    },
    h5: {
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
  },
});
