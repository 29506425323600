import { t, Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SocialSignBtn from "src/components/SocialSignBtn";
import { socialAuthApps } from "src/fixtures/socialAuthApps";
import { popupAdd } from "src/redux";
import { parseQueryString } from "src/utils";
import useSocialConnect from "src/utils/hoooks/useSocialConnect";

interface IProps {
  termsAgreed?: boolean;
  signIn?: boolean;
  signUp?: boolean;
}

const SocialAuth: FC<IProps> = ({ signIn, termsAgreed }) => {
  const dispatch = useDispatch();
  const location = useLocation<{ next?: string } | undefined>();
  const { auth, status } = useSocialConnect();
  const params = parseQueryString();
  const enabledApps = socialAuthApps.filter(({ isEnabled }) => isEnabled);

  const nextUrl = useMemo(
    () => params.get("next") || params.get("nextFront") || location.state?.next || (signIn ? "/" : "/welcome/"),
    [location.state?.next, params, signIn],
  );

  const handlerOpenSmpAuth = (appId: number) => () => {
    if (termsAgreed === false) {
      dispatch(
        popupAdd({
          importance: "error",
          text: t`You must agree to the Privacy Policy and Terms of Service`,
        }),
      );
      return;
    }

    auth({
      action: signIn ? "signin" : "signup",
      appId,
      nextState: signIn
        ? undefined
        : {
            isNewUser: true,
          },
      nextUrl,
    });
  };

  if (!enabledApps.length) {
    return null;
  }

  return (
    <Box mt={5} textAlign="center">
      <Typography display="block" gutterBottom variant="body1">
        {signIn ? <Trans>Or sign in via social network</Trans> : <Trans>Or sign up via social network</Trans>}
      </Typography>

      <Grid container spacing={2} style={{ display: "inline-block" }}>
        {enabledApps.map((app) => (
          <Grid item key={app.id}>
            <SocialSignBtn
              medium={app.medium}
              isLoading={status === "loading"}
              key={`social-auth-${app.id}`}
              onClick={handlerOpenSmpAuth(app.id)}
              label={<Trans id={signIn ? app.socialTitleIn.id : app.socialTitleUp.id} />}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SocialAuth;
