import * as Sentry from "@sentry/browser";
import axios from "axios";
import { ELanguage } from "src/enums/language";

enum EIcon {
  ru = "&#x1F4C1;",
  en = "&#x1F4D2;",
}

const CARROTQUEST_ROOT_CATALOG = 31106;

export interface ICarrotKnowledgeCategory {
  id: number;
  name: string;
  app: number;
  icon: EIcon;
  priority: number;
  order: number;
}

export interface ICarrotKnowledgeArticleListItem {
  id: number;
  public: boolean;
  name: string;
}

export interface ICarrotKnowledgeArticleItem {
  id: number;
  name: string;
  content: string;
}

interface IApiRequestParams {
  params?: {
    [key: string]: any;
  };
  token?: string;
  url: string;
}

type IGetResponse<Payload> =
  | {
      error: true;
      payload?: undefined;
    }
  | {
      error: false;
      payload: Payload;
    };

async function _get<IApiRequestResult>({ params, url }: IApiRequestParams): Promise<IGetResponse<IApiRequestResult>> {
  try {
    const resp = await axios.request<{
      meta: {
        status: number;
      };
      data: IApiRequestResult;
    }>({
      method: "GET",
      params,
      url: `${process.env.REACT_APP__API_CARROT_KNOWLEDGE_URL}${CARROTQUEST_ROOT_CATALOG}${url}`,
    });

    if (resp.status < 200 || resp.status >= 300) {
      throw resp;
    }

    if (resp.data.meta.status < 200 || resp.data.meta.status >= 300) {
      throw resp.data;
    }

    return {
      error: false,
      payload: resp.data.data,
    };
  } catch (e) {
    if (process.env.REACT_APP_RAVEN_ENABLE) {
      Sentry.captureException(e);
    }

    return {
      error: true,
    };
  }
}

export async function apiCarrotKnowledgeGetArticles(
  lang: ELanguage,
): Promise<IGetResponse<Array<ICarrotKnowledgeArticleListItem>>> {
  return new Promise(async (resolve) => {
    try {
      const { error, payload: categoriesAll } = await _get<Array<ICarrotKnowledgeCategory>>({
        url: "/categories/",
      });

      if (error || !categoriesAll?.length) {
        throw new Error("There are no categories!");
      }

      const categories = categoriesAll.filter((cat) => cat.icon === EIcon[lang]);

      if (!categories.length) {
        throw new Error(`There are no categories in ${lang}!`);
      }

      const responses = await Promise.all(
        categories.map((cat) =>
          _get<Array<ICarrotKnowledgeArticleListItem>>({
            params: {
              category: cat.id,
              include_not_public: false,
            },
            url: "/articles/",
          }),
        ),
      );

      const articles = responses.reduce((acc, { error, payload }) => {
        if (!error && payload) {
          acc = [...acc, ...payload];
        }

        return acc;
      }, [] as ICarrotKnowledgeArticleListItem[]);

      return resolve({
        error: false,
        payload: articles,
      });
    } catch (e) {
      console.error(e);
      return resolve({
        error: true,
        payload: undefined,
      });
    }
  });
}

export async function apiCarrotKnowledgeGetArticleItem(id: ICarrotKnowledgeArticleItem["id"]) {
  return _get<ICarrotKnowledgeArticleItem>({
    url: `/articles/${id}`,
  });
}
