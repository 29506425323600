import { Trans } from "@lingui/macro";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { IconChevronDown, IconLogout, IconUser } from "@tabler/icons";
import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import UiButton from "src/components/UiButton";
import UiMenu from "src/components/UiMenu";
import { useAppContext } from "src/context/app/appContext";
import cssc from "src/utils/emotionComposition";
import useLogout from "src/utils/hoooks/useLogout";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import { classes } from "./index.css";

const AccountMenu: FC = () => {
  const logout = useLogout();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isReport = /^\/app\/report/.test(window.location.pathname);
  const { data: user } = useQueryMe();
  const {
    state: { context },
  } = useAppContext();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget), []);

  const handleClose = useCallback(() => setMenuAnchor(null), []);

  return useMemo(() => {
    if (!user?.id) {
      return (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <UiButton component="a" href="/app/auth/signin/" color="secondary">
              <Trans>Sign in</Trans>
            </UiButton>
          </Grid>

          <Grid item>
            <UiButton component="a" href="/app/auth/signup/" color="secondary">
              <Trans>Sign up</Trans>
            </UiButton>
          </Grid>
        </Grid>
      );
    }

    if (context === "oauth2") {
      return (
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" wrap="nowrap">
          {user.email && (
            <Grid item>
              <Typography css={classes.mail} variant="subtitle2">
                {user.email}
              </Typography>
            </Grid>
          )}
        </Grid>
      );
    }

    if (isMobile) {
      return null;
    }

    return (
      <>
        <Stack direction="row" alignItems="center" css={classes.container}>
          <IconButton css={classes.avatar} onClick={handleOpen}>
            <IconUser />
          </IconButton>

          {user?.email && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              css={cssc([classes.email, isReport && classes.email_hidden])}
            >
              <Typography variant="h4">{user.email}</Typography>

              <IconChevronDown />
            </Stack>
          )}
        </Stack>

        <UiMenu
          onClose={handleClose}
          anchorEl={menuAnchor}
          withBackdrop
          anchorOrigin={{
            horizontal: "right",
            vertical: "center",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "center",
          }}
          css={classes.menu}
        >
          <MenuItem disabled css={classes.menuEmail}>
            {user.email}
          </MenuItem>

          <MenuItem component={Link} to="/profile/">
            <Stack spacing={1} direction="row">
              <IconUser />
              <span>
                <Trans>Profile</Trans>
              </span>
            </Stack>
          </MenuItem>

          <MenuItem onClick={logout}>
            <Stack spacing={1} direction="row">
              <IconLogout />
              <span>
                <Trans>Sign Out</Trans>
              </span>
            </Stack>
          </MenuItem>
        </UiMenu>
      </>
    );
  }, [context, handleClose, handleOpen, isMobile, isReport, logout, menuAnchor, user?.email, user?.id]);
};

export default AccountMenu;
