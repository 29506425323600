import { IconCheck } from "@tabler/icons";
import { FC } from "react";
import CrossIcon from "src/icons/CrossIcon";
import cssc from "src/utils/emotionComposition";

import * as classes from "./index.css";

interface IProps {
  checked: boolean;
}

const OptionCheckbox: FC<IProps> = ({ checked }) => (
  <div css={cssc([classes.checkbox, checked && classes.checkbox_checked])}>
    {checked ? <IconCheck width={20} height={20} /> : <CrossIcon fontSize="inherit" />}
  </div>
);

export default OptionCheckbox;
