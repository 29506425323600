import { t, Trans } from "@lingui/macro";
import { Grid, Hidden, Stack, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { IconFilter, IconSearch } from "@tabler/icons";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import groupPipelineTypes from "src/utils/groupPipelineTypes";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import * as classes from "./index.css";

const CHANGE_NAME_DELAY = 1000;

export interface IFilterForm {
  project_id: (string | number)[] | null;
  source: (string | number)[] | null;
  name: string | null;
}

interface IProps {
  onChange: <K extends keyof IFilterForm>(key: K, val: IFilterForm[K]) => void;
  values: IFilterForm;
}

const Filter: FC<IProps> = ({ onChange, values }) => {
  const theme = useTheme<Theme>();
  const { data: projects } = useQueryProjects();
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: user } = useQueryMe();
  const [name, setName] = useState(values.name);
  const changeNameTimeoutId = useRef(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpenMain, setMobileOpenMain] = useState(false);
  const [mobileOpenName, setMobileOpenName] = useState(false);

  const projectOptions = useMemo(
    () =>
      projects
        ?.filter(({ is_approved }) => is_approved)
        .map(({ id, name }) => ({
          label: name,
          value: id,
        })),
    [projects],
  );

  const pipelineTypesOptions = useMemo(() => {
    const grouped = pipelineTypes && groupPipelineTypes(Object.values(pipelineTypes));

    return (
      grouped &&
      Object.values(grouped).map((group) => ({
        label: group.shortTitle,
        options: group.items
          .filter((pt) => !pt.onlyStaff || user?.is_staff)
          .map((pt) => ({
            label: pt.shortTitle,
            value: pt.id,
          })),
      }))
    );
  }, [pipelineTypes, user?.is_staff]);

  const handleChangeSelect = useCallback(
    <K extends keyof IFilterForm>(key: K) =>
      (value: IFilterForm[K]) => {
        onChange(key, value);
      },
    [onChange],
  );

  const handleChangeName = useCallback(
    (value: string) => {
      setName(value);
      window.clearInterval(changeNameTimeoutId.current);
      changeNameTimeoutId.current = window.setTimeout(() => {
        onChange("name", value);
      }, CHANGE_NAME_DELAY);
    },
    [onChange],
  );

  const handleToggleMainFilterMobile = useCallback(() => {
    setMobileOpenMain((state) => !state);
  }, []);

  const handleToggleNameFilterMobile = useCallback(() => {
    setMobileOpenName((state) => !state);
  }, []);

  return useMemo(
    () => (
      <>
        <Hidden implementation="css" smUp>
          <Stack direction="row" spacing={2} pb={2} mt={-2} justifyContent="center">
            <UiButton
              startIcon={<IconFilter />}
              color="neutral"
              variant="outlined"
              disableElevation
              onClick={handleToggleMainFilterMobile}
              css={mobileOpenMain && classes.button_active}
            />

            <UiButton
              startIcon={<IconSearch />}
              color="neutral"
              variant="outlined"
              disableElevation
              onClick={handleToggleNameFilterMobile}
              css={mobileOpenName && classes.button_active}
            />
          </Stack>
        </Hidden>

        <Grid container spacing={2} justifyContent="center">
          {projectOptions && projectOptions.length > 1 && (
            <Grid item xs={12} sm={4} hidden={isMobile && !mobileOpenMain}>
              <UiSelect
                fullWidth
                isMulti
                value={values.project_id}
                onChange={handleChangeSelect("project_id")}
                isDisabled={!projects?.length}
                options={projectOptions}
                maxMenuHeight={600}
                placeholder={<Trans>Project</Trans>}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4} hidden={isMobile && !mobileOpenMain}>
            <UiSelect
              fullWidth
              isMulti
              value={values.source}
              onChange={handleChangeSelect("source")}
              isDisabled={!pipelineTypesOptions?.length}
              options={pipelineTypesOptions}
              maxMenuHeight={600}
              placeholder={<Trans>Type</Trans>}
            />
          </Grid>

          <Grid item xs={12} sm={4} hidden={isMobile && !mobileOpenName}>
            <UiTextField fullWidth value={name} placeholder={t`Title`} onChange={handleChangeName} endIcon={<IconSearch />} />
          </Grid>
        </Grid>
      </>
    ),
    [
      handleChangeName,
      handleChangeSelect,
      handleToggleMainFilterMobile,
      handleToggleNameFilterMobile,
      isMobile,
      mobileOpenMain,
      mobileOpenName,
      name,
      pipelineTypesOptions,
      projectOptions,
      projects?.length,
      values.project_id,
      values.source,
    ],
  );
};

export default Filter;
