import { EPipelineReportTokenStatus } from "src/enums/pipeline";
import { IPipeline } from "src/redux";
import { IPipelineReportToken } from "src/types/pipeline";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export interface IUseQueryReportTokenPatchParams {
  pipelineId: IPipeline["id"];
  status?: EPipelineReportTokenStatus;
}

export default function useQueryPipelinePatch(
  queryOptions?: IUseQueryMutationOptions<IPipelineReportToken, IUseQueryReportTokenPatchParams>,
) {
  return useMutationBase(queryOptions, {
    method: "PATCH",
    params: ({ pipelineId, ...params }) => params,
    url: ({ pipelineId }) => `share/pipeline/pipeline/${pipelineId}/`,
  });
}
