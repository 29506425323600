import { useMemo, useRef, useState } from "react";
import { InfiniteData, useQueryClient } from "react-query";
import { EProgressType } from "src/enums/progress";
import { EStreamReportStatus } from "src/enums/stream";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import aggregateReportStatusProgress from "src/utils/aggregateReportStatusProgress";
import { serializeFilterParameters } from "src/utils/filterParameters";
import usePipelineAggregation from "src/utils/hoooks/usePipelineAggregation";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import { useQueryPipelineUpdateData } from "src/utils/queries/pipeline/useQueryPipelineGet";
import { IUseQueryInfiniteOptions, IUseQueryInfiniteOutputWrapper, useQueryInfinite } from "src/utils/queries/useQueryBase";

interface IRequestParams {
  limit?: number;
  offset?: number;
  filtered?: {
    project?: (string | number)[] | null;
    source?: (string | number)[] | null;
    name?: string | null;
  };
  ordering?: string;
  id__in?: number[];
}

const QUERY_KEY = "pipelines";

export default function useQueryPipelines(
  queryOptions: IUseQueryInfiniteOptions<IPipeline, IPipelineExtended>,
  params: IRequestParams,
) {
  const pipelineUpdateData = useQueryPipelineUpdateData();
  const [waitForUpdate, setWaitForUpdate] = useState<{ [key: number]: boolean }>({});
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const aggregatePipeline = usePipelineAggregation();
  const queryClient = useQueryClient();
  const countdownId = useRef<number | undefined>();
  const idsForRequest = useMemo(() => params.id__in?.filter((id) => !waitForUpdate[id]) || [], [params.id__in, waitForUpdate]);

  return useQueryInfinite<IPipeline, IPipelineExtended>(
    [QUERY_KEY, params],
    {
      enabled: Boolean(pipelineTypes && (!params.id__in || idsForRequest.length > 0)),
      select: (data) => ({
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          results: page.results.map(aggregatePipeline),
        })),
      }),
      ...queryOptions,
      onSuccess: async (...args) => {
        const data = args[0];

        const pipelineIdsWithTimer = data.pages.reduce((acc, page) => {
          page.results.forEach((pipeline) => {
            const progress = aggregateReportStatusProgress(pipeline.report_status);

            if (progress?.type === EProgressType.TIMER && progress.value >= 5) {
              acc.push(pipeline.id);
            } else {
              pipelineUpdateData(pipeline);
            }
          });

          return acc;
        }, [] as IPipelineExtended["id"][]);

        if (pipelineIdsWithTimer.length > 0) {
          setWaitForUpdate(
            pipelineIdsWithTimer.reduce((acc, id) => {
              acc[id] = true;

              return acc;
            }, {} as { [key: number]: boolean }),
          );

          if (!countdownId.current) {
            countdownId.current = window.setInterval(() => {
              queryClient.setQueryData<InfiniteData<IUseQueryInfiniteOutputWrapper<IPipelineExtended>> | undefined>(
                [QUERY_KEY, params],
                (resp) => {
                  if (!resp) {
                    return resp;
                  }

                  return {
                    ...resp,
                    pages: resp.pages.map((page) => ({
                      ...page,
                      results: page.results.map((item) =>
                        pipelineIdsWithTimer.includes(item.id)
                          ? {
                              ...item,
                              report_status: item.report_status.map((rs) =>
                                rs.status !== EStreamReportStatus.LOCK
                                  ? rs
                                  : {
                                      ...rs,
                                      progress: rs.progress
                                        ? {
                                            ...rs.progress,
                                            items: {
                                              ...rs.progress.items,
                                              [rs.progress.current_item_sign]: {
                                                ...rs.progress.items[rs.progress.current_item_sign],
                                                current: rs.progress.items[rs.progress.current_item_sign].current + 1,
                                              },
                                            },
                                          }
                                        : undefined,
                                    },
                              ),
                            }
                          : item,
                      ),
                    })),
                  };
                },
              );
            }, 1000);
          }
        } else {
          window.clearInterval(countdownId.current);
          setWaitForUpdate({});
        }

        queryOptions?.onSuccess?.apply(queryOptions, args);
      },
    },
    {
      method: "GET",
      params: {
        ...params,
        filtered: serializeFilterParameters(params.filtered),
        id__in: params.id__in?.join(","),
        ordering: params.ordering || "-id",
      },
      url: `pipelines/`,
    },
  );
}
