import { Plural, Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import React, { FC, useCallback, useMemo } from "react";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import { useBillingContext } from "src/context/billing/billingContext";
import useSubscriptionTimeLeft from "src/utils/hoooks/useSubscriptionTimeLeft";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import * as styles from "./index.css";

interface IProps {
  isOpen: boolean;
  optionsNum: number;
  onClose: () => void;
  onBuyOptions: () => void;
}

const ModalSubscriptionFreeDaysRenewal: FC<IProps> = ({ isOpen, onClose, onBuyOptions, optionsNum }) => {
  const {
    actions: { openModalSubscriptionOrder },
  } = useBillingContext();
  const { data: user } = useQueryMe();
  const timeLeftDays = useSubscriptionTimeLeft();

  const subscription = user?.active_subscription;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleBuyOption = useCallback(() => {
    handleClose();
    onBuyOptions();
  }, [handleClose, onBuyOptions]);

  const handleOpenTariffConfirm = useCallback(() => {
    if (subscription) {
      openModalSubscriptionOrder({
        additionalOptionsNum: optionsNum,
        onSuccess: handleClose,
        renewalWithFreeDays: true,
        tariffSign: subscription.tariff_on_period.sign,
      });
    }
  }, [handleClose, openModalSubscriptionOrder, optionsNum, subscription]);

  return useMemo(
    () =>
      !subscription ? null : (
        <>
          <UiDialog open={isOpen} onClose={handleClose} css={styles.container}>
            <DialogContent>
              <Typography textAlign="center">
                <Trans>
                  Your subscription ends{" "}
                  <Plural value={timeLeftDays} _0="today" one="in # day" few="in # days" many="in # days" other="in # days" />.
                  You can buy data sets with a 50% discount or get them for free and subscribe for the next month.
                </Trans>
              </Typography>
            </DialogContent>

            <DialogActions>
              <UiButton onClick={handleBuyOption}>
                <Trans>Buy</Trans>
              </UiButton>

              <UiButton onClick={handleOpenTariffConfirm}>
                <>
                  <Trans>Get free for</Trans>{" "}
                  <Plural value={timeLeftDays || 1} one="# day" few="# days" many="# days" other="# days" />
                  {"*"}
                </>
              </UiButton>
            </DialogActions>

            <Alert severity="info" icon={false}>
              <sup>*</sup>
              <Trans>When paying the fare for the next month</Trans>
            </Alert>
          </UiDialog>
        </>
      ),
    [handleBuyOption, handleClose, handleOpenTariffConfirm, isOpen, subscription, timeLeftDays],
  );
};

export default ModalSubscriptionFreeDaysRenewal;
