import { Trans } from "@lingui/macro";
import { Paper, PaperProps, Stack, Tooltip, Typography } from "@mui/material";
import { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PipelinePicture from "src/components/PipelinePicture";
import UiButton from "src/components/UiButton";
import { EPipelineType } from "src/enums/pipeline";
import { IPipelineExtended } from "src/types/pipeline";
import { IProjectExtended } from "src/types/project";
import cssc from "src/utils/emotionComposition";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import useQueryMe from "src/utils/queries/user/useQueryMe";

import Actions from "./components/Actions";
import * as classes from "./index.css";

interface IProps extends PaperProps {
  pipeline: IPipelineExtended;
  project: IProjectExtended | undefined;
  view: "grid" | "list";
  onActionSuccess: ComponentProps<typeof Actions>["onActionSuccess"];
  onEditPipeline: ComponentProps<typeof Actions>["onEditPipeline"];
  showProjectName: boolean;
}

const Card: FC<IProps> = ({ pipeline, project, onActionSuccess, onEditPipeline, view, showProjectName, ...props }) => {
  const [isDisplayExport, setDisplayExport] = useState(false);
  const { data: pipelineTypes } = useQueryPipelineTypes();
  const { data: me } = useQueryMe();

  const pipelineType = useMemo(() => pipelineTypes?.[pipeline.source], [pipeline.source, pipelineTypes]);

  const link = useMemo(
    () =>
      (pipelineType?.enable_fast_run || !["error", "in_progress_first_run"].includes(pipeline.data_status)) &&
      (pipeline.report_links?.inhouse || pipeline.report_links?.gds),
    [pipeline.data_status, pipeline.report_links?.gds, pipeline.report_links?.inhouse, pipelineType?.enable_fast_run],
  );

  const handleOpenExport = useCallback(() => {
    setDisplayExport(true);
    onActionSuccess("export", pipeline.id);
  }, [onActionSuccess, pipeline.id]);

  return (
    <Paper {...props}>
      <div
        css={
          {
            grid: classes.card_grid,
            list: classes.card_list,
          }[view]
        }
      >
        <div css={cssc([classes.card__group, classes.card__group_main])}>
          <div css={cssc([classes.card__box, classes.card__box_picture])}>
            <PipelinePicture
              name={pipeline.name}
              media={pipeline.media}
              picture={pipeline.profile_picture}
              size={view === "grid" ? "large" : "small"}
              responsive={view === "grid"}
            />
          </div>

          <div css={cssc([classes.card__group, classes.card__group_type_titles])}>
            <Typography component="div" variant="body2" css={cssc([classes.card__box, classes.card__box_type])}>
              {pipelineType &&
                ((pipelineType.group === "ads" &&
                  ([EPipelineType.YA_DIRECT, EPipelineType.YA_DIRECT_CAMPAIGN].includes(pipelineType.id) ? (
                    pipelineType.shortTitle
                  ) : (
                    <Trans>Ads</Trans>
                  ))) ||
                  (pipelineType.group === "public" && <Trans>Public</Trans>) ||
                  (pipelineType.group === "open_account" && <Trans>Competitor analysis</Trans>) || <Trans>Merge</Trans>)}
            </Typography>

            <Typography component="div" variant="body2" css={cssc([classes.card__box, classes.card__box_name])}>
              {project && showProjectName && (
                <Stack css={classes.projectName}>
                  <div css={classes.projectName__color} style={{ color: project.color }} />
                  <div css={classes.projectName__name}>{project.name}</div>
                </Stack>
              )}

              <div css={classes.name}>
                {view === "grid" && project && showProjectName && (
                  <Tooltip title={project.name}>
                    <div css={classes.name__projectColor} style={{ color: project.color }} />
                  </Tooltip>
                )}
                {pipeline.name}
              </div>
            </Typography>
          </div>
        </div>

        <div css={cssc([classes.card__group, classes.card__group_second])}>
          <div css={cssc([classes.card__group, classes.card__group_status_link])}>
            <div css={cssc([classes.card__box, classes.card__box_status])}>
              <Typography variant="body2" component="div" css={[classes.status(pipeline.aggregated_status.color)]}>
                {pipeline.aggregated_status.title}

                {["in_progress_first_run", "in_progress", "ready_partly"].includes(pipeline.data_status) ? (
                  <div>{pipeline.aggregated_status.description}</div>
                ) : (
                  pipeline.aggregated_status.description && (
                    <Tooltip title={pipeline.aggregated_status.description}>
                      <div css={classes.status__ttip} />
                    </Tooltip>
                  )
                )}
              </Typography>
            </div>

            <Typography component="div" variant="body2" css={cssc([classes.card__box, classes.card__box_link])}>
              {link ? (
                <UiButton
                  disableElevation
                  variant="outlined"
                  component={Link}
                  to={link}
                  disabled={me?.email_confirmation_required}
                >
                  <Trans>Open report</Trans>
                </UiButton>
              ) : (
                <UiButton
                  disabled={pipeline.data_status !== "ready" || me?.email_confirmation_required}
                  disableElevation
                  variant="outlined"
                  onClick={handleOpenExport}
                >
                  <Trans>Open report</Trans>
                </UiButton>
              )}
            </Typography>
          </div>

          <div css={cssc([classes.card__box, classes.card__box_actions])}>
            <Actions
              view={view}
              pipeline={pipeline}
              onActionSuccess={onActionSuccess}
              onEditPipeline={onEditPipeline}
              currentAction={isDisplayExport ? "export" : undefined}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Card;
