import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const stack = (theme: Theme) => css`
  ${theme.breakpoints.down("sm")} {
    margin-bottom: -10px;
    flex-direction: row-reverse;
    justify-content: space-between;

    & > :not(style) + :not(style) {
      margin: 0;
    }
  }
`;
