import { EPipelineSubType } from "src/enums/pipeline";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IProject, IProjectExtended, IProjectPipeline, IProjectPipelineStream } from "src/types/project";
import { ISubscription } from "src/types/subscription";
import usePipelineReportLinks from "src/utils/hoooks/usePipelineReportLinks";
import isSubscriptionTrial from "src/utils/isSubscriptionTrial";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

export default function useProjectAggregation() {
  const aggregateReportLinks = usePipelineReportLinks();
  const { data: pipelineTypes } = useQueryPipelineTypes();

  return (project: IProject): IProjectExtended => {
    const { streams, ...spread } = project;

    const streamsMap = streams.reduce((acc: { [key in IProjectPipeline["id"]]: IProjectPipelineStream[] }, stream) => {
      acc[stream.pipeline] = acc[stream.pipeline] || [];

      acc[stream.pipeline].push(stream);

      return acc;
    }, {});

    const activeSubscription = spread.creator.active_subscription.id
      ? (spread.creator.active_subscription as ISubscription)
      : undefined;

    const prevSubscription = spread.creator.previous_subscription?.id
      ? (spread.creator.previous_subscription as ISubscription)
      : undefined;

    return {
      ...spread,
      creator: {
        ...spread.creator,
        active_subscription: activeSubscription,
        isElamaUser:
          activeSubscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA ||
          (!activeSubscription && prevSubscription?.tariff_on_period.sign === ETariffOnPeriodSign.ELAMA),
        isTrialUser:
          (activeSubscription && isSubscriptionTrial(activeSubscription)) ||
          (!activeSubscription && (!prevSubscription || (prevSubscription && isSubscriptionTrial(prevSubscription)))),
        previous_subscription: prevSubscription,
      },
      pipelines: project.pipelines.reduce((acc, pipeline) => {
        if (pipelineTypes?.[pipeline.source].subType !== EPipelineSubType.SINGLEPIPELINE) {
          const streams = streamsMap[pipeline.id];
          const profile_picture = streams.find(({ profile_picture }) => !!profile_picture)?.profile_picture;
          const media = pipelineTypes?.[pipeline.source]?.media;

          acc.push({
            ...pipeline,
            media,
            profile_picture,
            report_links: aggregateReportLinks(pipeline),
            streams,
          });
        }

        return acc;
      }, [] as IProjectExtended["pipelines"][number][]),
    };
  };
}
