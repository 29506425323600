import { Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigOkPublic } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigOkPublic | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigOkPublic: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedGroup, setSelectedGroup] = useState<IPipelineConfigOkPublic["ok_group_data"] | null>(null);

  const {
    data: groups,
    isFetched: groupsFetched,
    isFetching: groupsIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.OK_GROUPS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedGroup) {
          setSelectedGroup(data[0] || null);
        }
      },
    },
  );

  const groupOptions = useMemo(
    () =>
      groups?.map((group) => ({
        data: group,
        label: `${group.name} (${group.id})`,
        value: group.id,
      })) || [],
    [groups],
  );

  const handleSelectGroup = useCallback(
    (value: typeof groupOptions[number]["value"] | null, data: typeof groupOptions[number]["data"] | null) => {
      if (data) {
        setSelectedGroup(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedGroup));

    onChange({
      group_id: selectedGroup?.["id"],
      ok_group_data: selectedGroup || undefined,
    });
  }, [onChangeValidation, onChange, selectedGroup]);

  return (
    <>
      {groupOptions.length === 0 && groupsFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={groupOptions.length > 7}
          isLoading={groupsIsFetching}
          value={selectedGroup?.id}
          onChange={handleSelectGroup}
          options={groupOptions}
          placeholder={<Trans>Community</Trans>}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigOkPublic;
