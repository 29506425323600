export enum EPaymentMethodType {
  BANK_CARD = "bank_card",
  APPLE_PAY = "apple_pay",
  GOOGLE_PAY = "google_pay",
  YOO_MONEY = "yoo_money",
  QIWI = "qiwi",
  WEBMONEY = "webmoney",
  SBERBANK = "sberbank",
  ALFABANK = "alfabank",
  TINKOFF_BANK = "tinkoff_bank",
  B2B_SBERBANK = "b2b_sberbank",
  MOBILE_BALANCE = "mobile_balance",
  CASH = "cash",
  INSTALLMENTS = "installments",
}
