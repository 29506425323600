import { Sorting } from "@devexpress/dx-react-grid";
import { t } from "@lingui/macro";
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { all, call, put, select } from "redux-saga/effects";
import { EPipelineSubType } from "src/enums/pipeline";
import { IModulesActionMap, IRootState, pipelinesTypesGetApi, pipelineUnarchiveApi, userGet } from "src/redux";
import { IPipelinesDashboardGetR, IPipelinesDashboardStatusGetR } from "src/redux/modules/pipelines/actions/dashboard";
import {
  IPipelinesExportDeleteR,
  IPipelinesExportsActionMap,
  IPipelinesExportsGetR,
  IPipelinesExportsPostR,
  IPipelinesExportsStatusGetR,
  IPipelinesExportUrlGetR,
  pipelinesExportsGet,
} from "src/redux/modules/pipelines/actions/exports";
import {
  IPipelinesItemArchiveR,
  IPipelinesItemDeleteR,
  IPipelinesItemGetR,
  IPipelinesItemPostR,
  IPipelinesItemPutR,
  IPipelinesItemPutS,
  IPipelinesItemRestartR,
  IPipelinesItemUnarchiveR,
} from "src/redux/modules/pipelines/actions/item";
import {
  IPipelinesItemsActionMap,
  IPipelinesItemsGetR,
  IPipelinesStatusGetR,
  pipelinesItemsGet,
} from "src/redux/modules/pipelines/actions/items";
import { IPipelinesMappingGetR } from "src/redux/modules/pipelines/actions/mapping";
import { IPipelinesMergeGetR } from "src/redux/modules/pipelines/actions/merge";
import { IPipelinesSamesGetR } from "src/redux/modules/pipelines/actions/sames";
import { arrayStringForSort, FILTERED, IFiltered, PAGE, PAGE_SIZE, prepareErrorMessage, sagaCompile, SORTED } from "src/utils";

import { popupAdd } from "../popups";
import { apiRequestAuthorized } from "../shared";
import {
  pipelineArchiveApi,
  pipelineDeleteApi,
  pipelineExportDeleteApi,
  pipelineExportsGetApi,
  pipelineExportsPostApi,
  pipelineExportsStatusGetApi,
  pipelineExportUrlGetApi,
  pipelineGetApi,
  pipelineMappingGetApi,
  pipelinePostApi,
  pipelinePutApi,
  pipelineRestartApi,
  pipelinesGetApi,
} from "./api";
import { IPipeline, IPipelineExport } from "./data";

const { takeEveryRequest, putTake } = sagaCompile<IModulesActionMap>();

export interface IPipelinesGetSagaResponse {
  meta: {
    count: number;
    next?: string | null;
    prev?: string | null;
    page: number;
    limit: number;
    filtered?: IFiltered[];
    ordering?: Sorting[];
    mode?: IPipelinesItemsActionMap["PIPELINES_ITEMS_GET__R"]["mode"];
  };
  res: AxiosResponse;
  results: IPipeline[];
}

export interface IPipelinesSameGetSagaResponse {
  meta: {
    count: number;
    next: string | null;
    prev: string | null;
  };
  res: AxiosResponse;
  results: IPipeline[];
}

export interface IPipelinesExportsGetSagaResponse {
  meta: {
    count: number;
    page: number;
    limit: number;
    ordering?: Sorting[];
    mode?: IPipelinesExportsActionMap["PIPELINES_EXPORTS_GET__R"]["mode"];
  };
  res: AxiosResponse;
  results: IPipelineExport[];
}

export interface IPipelinesExportsPostSagaResponse {
  results: IPipelineExport;
}

export interface IPipelinesMergeGetSagaResponse {
  meta: { count: number };
  type: EPipelineSubType.ADS | EPipelineSubType.MULTIPIPELINE;
  results: IPipeline[];
}

export function* pipelinesDashboardGetSaga() {
  yield takeEveryRequest(["PIPELINES_DASHBOARD_GET__R", "PIPELINES_DASHBOARD_GET__S", "PIPELINES_DASHBOARD_GET__F"], {
    *onF(action: any) {
      const { error } = action;

      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesDashboardGetR) {
      const { currentFiltered, currentPageSize, currentPage, currentOrdering, selectedProjectId }: any = yield select(
        (state: IRootState) => ({
          currentFiltered: state.modules.pipelinesItemsState[FILTERED],
          currentOrdering: state.modules.pipelinesItemsState[SORTED],
          currentPage: state.modules.pipelinesItemsState[PAGE],
          currentPageSize: state.modules.pipelinesItemsState[PAGE_SIZE],
          selectedProjectId: state.modules.user.selected_project,
        }),
      );

      const filtered = typeof action.payload.filtered !== "undefined" ? action.payload.filtered : currentFiltered;
      const page = typeof action.payload.page !== "undefined" ? action.payload.page : currentPage;
      const limit = typeof action.payload.limit !== "undefined" ? action.payload.limit : currentPageSize;
      const ordering = action.payload.ordering || currentOrdering;

      const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
        filtered: JSON.stringify(filtered),
        limit,
        name: action.payload.name,
        offset: page * limit,
        ordering: arrayStringForSort(ordering),
        selectedProjectId,
      });

      return {
        meta: {
          count: res.data.count,
          filtered,
          limit,
          next: res.data.next,
          ordering,
          page,
          prev: res.data.prev,
        },
        res,
        results: res.data.results,
      };
    },
  });
}

export function* pipelinesDashboardStatusGetSaga() {
  yield takeEveryRequest(
    ["PIPELINES_DASHBOARD_STATUS_GET__R", "PIPELINES_DASHBOARD_STATUS_GET__S", "PIPELINES_DASHBOARD_STATUS_GET__F"],
    {
      *onR(action: IPipelinesDashboardStatusGetR) {
        const selectedProjectId: any = yield select((state: IRootState) => state.modules.user.selected_project);

        const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
          id__in: action.payload.id__in,
          selectedProjectId,
        });

        return {
          meta: {
            count: res.data.count,
            next: res.data.next,
            prev: res.data.prev,
          },
          res,
          results: res.data.results,
        };
      },
    },
  );
}

export function* pipelinesGetSaga() {
  yield takeEveryRequest(["PIPELINES_ITEMS_GET__R", "PIPELINES_ITEMS_GET__S", "PIPELINES_ITEMS_GET__F"], {
    *onF(action: any) {
      const { error } = action;

      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemsGetR) {
      const { currentFiltered, currentPageSize, currentPage, currentOrdering, selectedProjectId, models }: any = yield select(
        (state: IRootState) => ({
          currentFiltered: state.modules.pipelinesItemsState[FILTERED],
          currentOrdering: state.modules.pipelinesItemsState[SORTED],
          currentPage: state.modules.pipelinesItemsState[PAGE],
          currentPageSize: state.modules.pipelinesItemsState[PAGE_SIZE],
          models: state.modules.pipelinesItemsState.models,
          selectedProjectId: state.modules.user.selected_project,
        }),
      );

      const filtered = typeof action.payload.filtered !== "undefined" ? action.payload.filtered : currentFiltered;
      const page = typeof action.payload.page !== "undefined" ? action.payload.page : currentPage;
      const limit = typeof action.payload.limit !== "undefined" ? action.payload.limit : currentPageSize;
      const ordering = action.payload.ordering || currentOrdering;

      const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
        filtered: JSON.stringify(filtered),
        limit: action.payload.mode === "reload" ? models.length : limit,
        name: action.payload.name,
        offset: action.payload.mode === "reload" ? 0 : page * limit,
        ordering: arrayStringForSort(ordering),
        selectedProjectId,
      });

      return {
        meta: {
          count: res.data.count,
          filtered,
          limit,
          mode: action.payload.mode,
          next: res.data.next,
          ordering,
          page,
          prev: res.data.prev,
        },
        res,
        results: res.data.results,
      };
    },
  });
}

export function* pipelinesSamesGetSaga() {
  yield takeEveryRequest(["PIPELINES_SAMES_GET__R", "PIPELINES_SAMES_GET__S", "PIPELINES_SAMES_GET__F"], {
    *onR(action: IPipelinesSamesGetR) {
      const selectedProjectId = yield select((state: IRootState) => state.modules.user.selected_project);
      const { limit, offset, ordering, status, filtered, name } = action.payload;
      const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
        filtered: JSON.stringify(filtered),
        limit,
        name,
        offset,
        ordering,
        selectedProjectId,
        status,
      });
      return {
        meta: {
          count: res.data.count,
          next: res.data.next,
          prev: res.data.prev,
        },
        res,
        results: res.data.results,
      };
    },
  });
}

export function* pipelineStatusGetSaga() {
  yield takeEveryRequest(["PIPELINES_STATUS_GET__R", "PIPELINES_STATUS_GET__S", "PIPELINES_STATUS_GET__F"], {
    *onR(action: IPipelinesStatusGetR) {
      const { id__in } = action.payload;
      const selectedProjectId = yield select((state: IRootState) => state.modules.user.selected_project);
      const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
        id__in,
        selectedProjectId,
      });
      return {
        results: res.data.results,
      };
    },
  });
}

export function* pipelineExportUrlGetSaga() {
  yield takeEveryRequest(["PIPELINES_EXPORT_URL__R", "PIPELINES_EXPORT_URL__S", "PIPELINES_EXPORT_URL__F"], {
    *onR(action: IPipelinesExportUrlGetR) {
      const { exportId, name } = action.payload;
      const res: any = yield call(apiRequestAuthorized, pipelineExportUrlGetApi, {
        exportId,
      });

      if (res.data.url) {
        saveAs(res.data.url, name);
      }

      return {
        exportId,
        url: res.data.url,
      };
    },
  });
}

export function* pipelineExportsGetSaga() {
  yield takeEveryRequest(["PIPELINES_EXPORTS_GET__R", "PIPELINES_EXPORTS_GET__S", "PIPELINES_EXPORTS_GET__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesExportsGetR) {
      const { currentPageSize, currentPage, currentOrdering }: any = yield select((state: IRootState) => ({
        currentOrdering: state.modules.pipelinesExportsState[SORTED],
        currentPage: state.modules.pipelinesExportsState[PAGE],
        currentPageSize: state.modules.pipelinesExportsState[PAGE_SIZE],
      }));

      const page = typeof action.payload.page !== "undefined" ? action.payload.page : currentPage;
      const limit = typeof action.payload.limit !== "undefined" ? action.payload.limit : currentPageSize;
      const ordering = action.payload.ordering || currentOrdering;

      const res: any = yield call(apiRequestAuthorized, pipelineExportsGetApi, {
        id__in: action.payload.id__in,
        limit,
        offset: page * limit,
        ordering: arrayStringForSort(ordering),
        pipelineId: action.payload.pipelineId,
      });
      return {
        meta: {
          count: res.data.count,
          limit,
          mode: action.payload.mode,
          ordering,
          page,
        },
        res,
        results: res.data.results,
      };
    },
  });
}

export function* pipelineExportsStatusGetSaga() {
  yield takeEveryRequest(
    ["PIPELINES_EXPORTS_STATUS_GET__R", "PIPELINES_EXPORTS_STATUS_GET__S", "PIPELINES_EXPORTS_STATUS_GET__F"],
    {
      *onR(action: IPipelinesExportsStatusGetR) {
        const { id__in, pipelineId } = action.payload;
        const res: any = yield call(apiRequestAuthorized, pipelineExportsStatusGetApi, {
          id__in,
          pipelineId,
        });
        return {
          results: res.data.results,
        };
      },
    },
  );
}

export function* pipelineExportsPostSaga() {
  yield takeEveryRequest(["PIPELINES_EXPORTS_POST__R", "PIPELINES_EXPORTS_POST__S", "PIPELINES_EXPORTS_POST__F"], {
    *onF(action: any) {
      const { error } = action;

      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesExportsPostR) {
      const { dateFrom, dateTo, pipelineId, callback, unionByField, pdfReportUrl, type, pages, settings } = action.payload;

      const res: any = yield call(apiRequestAuthorized, pipelineExportsPostApi, {
        dateFrom,
        dateTo,
        pages,
        pipelineId,
        report_url: pdfReportUrl,
        settings,
        type,
        unionByField,
      });
      if (callback) {
        callback(res.data);
      }
      return {
        results: res.data,
      };
    },
  });
}

export function* pipelineExportDeleteSaga() {
  yield takeEveryRequest(["PIPELINES_EXPORT_DELETE__R", "PIPELINES_EXPORT_DELETE__S", "PIPELINES_EXPORT_DELETE__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesExportDeleteR) {
      const { exportId } = action.payload;
      const res: any = yield call(apiRequestAuthorized, pipelineExportDeleteApi, {
        exportId,
      });
      return {
        results: res.data.results,
      };
    },
    *onS() {
      const pipelineId = yield select((state: IRootState) => state.modules.pipelinesCurrentState.model?.id);

      if (pipelineId) {
        yield putTake(pipelinesExportsGet({ pipelineId }), ["PIPELINES_EXPORTS_GET__S", "PIPELINES_EXPORTS_GET__F"]);
      }

      yield put(
        popupAdd({
          text: t`Export successfully deleted`,
        }),
      );
    },
  });
}

export function* pipelineGetSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_GET__R", "PIPELINES_ITEM_GET__S", "PIPELINES_ITEM_GET__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemGetR) {
      const { pipelineId } = action.payload;
      const res: any = yield call(apiRequestAuthorized, pipelineGetApi, {
        pipelineId,
      });
      return {
        pipeline: res.data.results[0],
      };
    },
  });
}

export function* clearCurrentPipelineSaga() {
  yield put({ type: "PIPELINES_CURRENT_CLEAR" });
}

export function* pipelinePostSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_POST__R", "PIPELINES_ITEM_POST__S", "PIPELINES_ITEM_POST__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemPostR) {
      const selectedProjectId = yield select((state: IRootState) => state.modules.user.selected_project);
      const { pipeline, urlAfterCreate } = action.payload;
      const { data: newPipeline }: any = yield call(apiRequestAuthorized, pipelinePostApi, {
        pipeline,
        selectedProjectId,
      });
      return { pipeline: newPipeline, urlAfterCreate };
    },
    *onS() {
      yield put(
        popupAdd({
          text: t`Pipeline successfully created`,
        }),
      );
      yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);
    },
  });
}

export function* pipelinePutSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_PUT__R", "PIPELINES_ITEM_PUT__S", "PIPELINES_ITEM_PUT__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemPutR) {
      const { pipelineId, name, project, run_delta, config } = action.payload;
      const { data }: any = yield call(apiRequestAuthorized, pipelinePutApi, {
        config,
        name,
        pipelineId,
        project,
        run_delta,
      });
      return {
        pipeline: {
          ...data,
          id: pipelineId,
        },
      };
    },
    *onS(action: IPipelinesItemPutS) {
      const changedPipeline = action.payload.pipeline;
      const currentPipeline: any = yield select((state: IRootState) => state.modules.pipelinesCurrentState.model);

      if (currentPipeline?.id === changedPipeline.id && currentPipeline.project !== changedPipeline.project) {
        yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);
      }

      yield put(
        popupAdd({
          text: t`Pipeline successfully updated`,
        }),
      );

      const { selectedProjectId, pipelinesItemsState }: any = yield select((state: IRootState) => ({
        pipelinesItemsState: state.modules.pipelinesItemsState,
        selectedProjectId: state.modules.user.selected_project,
      }));

      if (selectedProjectId !== action.payload.pipeline.project) {
        yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);

        yield putTake(
          pipelinesItemsGet({
            filtered: pipelinesItemsState[FILTERED],
            limit: pipelinesItemsState[PAGE_SIZE],
            mode: "reload",
            ordering: pipelinesItemsState[SORTED],
            page: pipelinesItemsState[PAGE],
          }),
          ["PIPELINES_ITEMS_GET__S", "PIPELINES_ITEMS_GET__F"],
        );
      }
    },
  });
}

export function* pipelineDeleteSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_DELETE__R", "PIPELINES_ITEM_DELETE__S", "PIPELINES_ITEM_DELETE__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemDeleteR) {
      const { pipelineId } = action.payload;
      yield call(apiRequestAuthorized, pipelineDeleteApi, {
        pipelineId,
      });
      return { pipelineId };
    },
    *onS() {
      yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);

      const pipelinesItemsState: any = yield select((state: IRootState) => state.modules.pipelinesItemsState);

      yield putTake(
        pipelinesItemsGet({
          filtered: pipelinesItemsState[FILTERED],
          limit: pipelinesItemsState[PAGE_SIZE],
          mode: "reload",
          ordering: pipelinesItemsState[SORTED],
          page: pipelinesItemsState[PAGE],
        }),
        ["PIPELINES_ITEMS_GET__S", "PIPELINES_ITEMS_GET__F"],
      );

      yield put(
        popupAdd({
          text: t`Pipeline successfully deleted`,
        }),
      );
    },
  });
}

export function* pipelineRestartSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_RESTART__R", "PIPELINES_ITEM_RESTART__S", "PIPELINES_ITEM_RESTART__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemRestartR) {
      const { pipelineId } = action.payload;
      yield call(apiRequestAuthorized, pipelineRestartApi, {
        pipelineId,
      });
      return { pipelineId };
    },
    *onS() {
      yield put(
        popupAdd({
          text: t`Pipeline successfully restarted`,
        }),
      );
    },
  });
}

export function* pipelineArchiveSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_ARCHIVE__R", "PIPELINES_ITEM_ARCHIVE__S", "PIPELINES_ITEM_ARCHIVE__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemArchiveR) {
      const { pipelineId } = action.payload;
      yield call(apiRequestAuthorized, pipelineArchiveApi, {
        pipelineId,
      });
      return { pipelineId };
    },
    *onS() {
      yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);

      const pipelinesItemsState: any = yield select((state: IRootState) => state.modules.pipelinesItemsState);

      yield putTake(
        pipelinesItemsGet({
          filtered: pipelinesItemsState[FILTERED],
          limit: pipelinesItemsState[PAGE_SIZE],
          mode: "reload",
          ordering: pipelinesItemsState[SORTED],
          page: pipelinesItemsState[PAGE],
        }),
        ["PIPELINES_ITEMS_GET__S", "PIPELINES_ITEMS_GET__F"],
      );

      yield put(
        popupAdd({
          text: t`Pipeline successfully archived`,
        }),
      );
    },
  });
}

export function* pipelineUnarchiveSaga() {
  yield takeEveryRequest(["PIPELINES_ITEM_UNARCHIVE__R", "PIPELINES_ITEM_UNARCHIVE__S", "PIPELINES_ITEM_UNARCHIVE__F"], {
    *onF(action: any) {
      const { error } = action;
      yield put(
        popupAdd({
          importance: "error",
          text: prepareErrorMessage(error.response.data),
        }),
      );
    },
    *onR(action: IPipelinesItemUnarchiveR) {
      const { pipelineIds } = action.payload;
      yield call(apiRequestAuthorized, pipelineUnarchiveApi, {
        pipelineIds,
      });
      return { pipelineIds };
    },
    *onS() {
      yield all([putTake(userGet({}), ["USER_GET__S", "USER_GET__F"])]);

      const pipelinesItemsState: any = yield select((state: IRootState) => state.modules.pipelinesItemsState);

      yield putTake(
        pipelinesItemsGet({
          filtered: pipelinesItemsState[FILTERED],
          limit: pipelinesItemsState[PAGE_SIZE],
          mode: "reload",
          ordering: pipelinesItemsState[SORTED],
          page: pipelinesItemsState[PAGE],
        }),
        ["PIPELINES_ITEMS_GET__S", "PIPELINES_ITEMS_GET__F"],
      );

      yield put(
        popupAdd({
          text: t`Pipeline successfully unarchived`,
        }),
      );
    },
  });
}

export function* pipelineMappingGetSaga() {
  yield takeEveryRequest(["PIPELINES_MAPPING_GET__R", "PIPELINES_MAPPING_GET__S", "PIPELINES_MAPPING_GET__F"], {
    *onR(action: IPipelinesMappingGetR) {
      const { pipelineId }: any = yield action.payload;
      const res: any = yield call(apiRequestAuthorized, pipelineMappingGetApi, {
        pipelineId,
      });
      return {
        mapping: res.data,
        res,
      };
    },
  });
}

export function* pipelineMergeGetSaga() {
  yield takeEveryRequest(["PIPELINES_MERGE_GET__R", "PIPELINES_MERGE_GET__S", "PIPELINES_MERGE_GET__F"], {
    *onR(action: IPipelinesMergeGetR) {
      const { limit, filtered, type } = action.payload;
      const selectedProjectId = yield select((state: IRootState) => state.modules.user.selected_project);
      const res: any = yield call(apiRequestAuthorized, pipelinesGetApi, {
        filtered: JSON.stringify(filtered),
        limit,
        selectedProjectId,
      });

      return {
        meta: { count: res.data.count },
        results: res.data.results,
        type,
      };
    },
  });
}

export function* pipelinesTypesGetSaga() {
  yield takeEveryRequest(["PIPELINES_TYPES_GET__R", "PIPELINES_TYPES_GET__S", "PIPELINES_TYPES_GET__F"], {
    *onR() {
      const res: any = yield call(apiRequestAuthorized, pipelinesTypesGetApi, {});

      return {
        results: res.data,
      };
    },
  });
}

export function* pipelinesSaga() {
  yield all([
    pipelinesGetSaga(),
    pipelinesDashboardGetSaga(),
    pipelinesDashboardStatusGetSaga(),
    pipelinesSamesGetSaga(),
    pipelineStatusGetSaga(),
    pipelineGetSaga(),
    pipelinePostSaga(),
    pipelinePutSaga(),
    pipelineDeleteSaga(),
    pipelineRestartSaga(),
    pipelineArchiveSaga(),
    pipelineMappingGetSaga(),
    pipelineExportsGetSaga(),
    pipelineExportsPostSaga(),
    pipelineExportDeleteSaga(),
    pipelineExportUrlGetSaga(),
    pipelineExportsStatusGetSaga(),
    clearCurrentPipelineSaga(),
    pipelineMergeGetSaga(),
    pipelineUnarchiveSaga(),
    pipelinesTypesGetSaga(),
  ]);
}
