import Cookies from "js-cookie";

export default function getAnalytic() {
  try {
    const utm_datafan = Cookies.get("utm_datafan");
    return utm_datafan && JSON.parse(utm_datafan);
  } catch (e) {}

  return {};
}
