import { Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigYaMetrika } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigYaMetrika | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigYaMetrika: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedCounter, setSelectedCounter] = useState<IPipelineConfigYaMetrika["counter"] | null>(null);

  const {
    data: counters,
    isFetched: countersFetched,
    isFetching: countersIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.YA_METRIKA_COUNTERS,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedCounter) {
          setSelectedCounter(data[0] || null);
        }
      },
    },
  );

  const counterOptions = useMemo(
    () =>
      counters?.map((counter) => ({
        data: counter,
        label: `${counter.name} (${counter.id})`,
        value: counter.id,
      })) || [],
    [counters],
  );

  const handleSelectCounter = useCallback(
    (value: typeof counterOptions[number]["value"] | null, data: typeof counterOptions[number]["data"] | null) => {
      if (data) {
        setSelectedCounter(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedCounter));

    onChange({
      counter: selectedCounter || undefined,
      counter_id: selectedCounter?.id,
    });
  }, [onChangeValidation, onChange, selectedCounter]);

  return (
    <>
      {counterOptions.length === 0 && countersFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <UiSelect
          isSearchable={counterOptions.length > 7}
          isLoading={countersIsFetching}
          value={selectedCounter?.id}
          onChange={handleSelectCounter}
          options={counterOptions}
          placeholder={<Trans>Counter</Trans>}
          fullWidth
        />
      )}
    </>
  );
};

export default ConfigYaMetrika;
