import { css } from "@emotion/react/macro";

export const paper = css`
  padding: 18px 30px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const head = css`
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  padding-right: 30px;
  margin-bottom: 20px;
`;

export const head_empty = css`
  padding-right: 0;
`;

export const head__icon = css`
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const head__name = css`
  display: inline;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
  font-size: 1.125rem;
`;

export const head__info = css`
  position: absolute;
  top: 0;
  right: 0;
  color: #505050;
`;

export const spacer = css`
  flex-grow: 1;
`;

export const image = css`
  background: #e2e2e2;
  border-radius: 8px;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  margin-top: auto;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
`;

export const emptyTmplDesc = css`
  padding-top: 75%;
  position: relative;
  overflow: hidden;
`;

export const emptyTmplDesc__inn = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
`;

export const description = css`
  margin-top: auto;
`;

export const benefits = css`
  list-style: none;
  margin: 20px 0;
  padding: 0;
`;

export const benefits__item = css`
  :before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: currentColor;
    position: relative;
    top: -3px;
  }
`;

export const foot = css`
  margin-top: auto;

  :before {
    content: "";
    display: block;
    height: 1px;
    background: #e2e2e2;
    margin: 0 -30px 30px;
  }
`;
