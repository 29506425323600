import { Trans } from "@lingui/macro";
import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { IconMessages } from "@tabler/icons";
import { FC, useMemo } from "react";
import Page404 from "src/app/Page404/Page404";
import Loader from "src/components/Loader";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { getTariffsTableMobile } from "src/fixtures/tariffsTable";
import getCurrencyDisplayName from "src/utils/getCurrencyDisplayName";
import useQueryBillingTariffsGet from "src/utils/queries/billing/useQueryBillingTariffsGet";

import SelectTariffButton from "./components/SelectTariffButton";
import TariffOptionValue from "./components/TariffOptionValue";
import * as classes from "./index.css";

const TariffsMobile: FC = () => {
  const tariffsTable = getTariffsTableMobile();
  const { data: tariffsResp, isLoading: tariffsIsLoading } = useQueryBillingTariffsGet();

  const tariffs = useMemo(() => tariffsResp?.filter(({ sign }) => sign !== ETariffOnPeriodSign.TRIAL_OUTDATED), [tariffsResp]);

  if (tariffsIsLoading) {
    return <Loader />;
  }

  if (!tariffs) {
    return <Page404 />;
  }

  return (
    <>
      {tariffs.map((t) => (
        <Paper css={classes.paperMobile} elevation={7}>
          <Typography css={classes.mobileTariffName}>{t.name}</Typography>

          {tariffsTable.map((o, idx) => {
            if (o.limitKey === "archive_pipelines" && !t.limits.archive_pipelines) {
              return null;
            }

            if (o.limitKey === "access_level" && !t.limits.access_level) {
              return null;
            }

            if (o.limitKey === "access_to_api" && !t.limits.access_to_api) {
              return null;
            }

            return (
              <Stack css={classes.mobileStack} key={idx}>
                <Tooltip
                  title={o.description || ""}
                  disableHoverListener={!Boolean(o.description)}
                  disableTouchListener={!Boolean(o.description)}
                >
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ textDecoration: Boolean(o.description) ? "underline" : undefined }}
                  >
                    {o.label}
                  </Typography>
                </Tooltip>
                <div css={classes.mobileStack__value}>
                  <TariffOptionValue tariff={t} option={o} isMobile />
                </div>
              </Stack>
            );
          })}

          <div css={classes.mobilePrice}>
            <div css={classes.mobilePrice__value}>
              {t.is_negotiable_price ? (
                <IconMessages width={36} height={36} />
              ) : (
                <>
                  {t.price}
                  {getCurrencyDisplayName(t.currency)}
                </>
              )}
            </div>

            <Typography>
              {t.is_negotiable_price ? <Trans>personal tariff on request</Trans> : <Trans>per month</Trans>}
            </Typography>
          </div>

          <Box mt={3}>
            <SelectTariffButton tariff={t} ButtonProps={{ fullWidth: true }} />
          </Box>
        </Paper>
      ))}
    </>
  );
};

export default TariffsMobile;
