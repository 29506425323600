import { t, Trans } from "@lingui/macro";
import { DialogContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useCallback, useMemo, useState, VFC } from "react";
import { useDispatch } from "react-redux";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import { useBillingContext } from "src/context/billing/billingContext";
import { EProjectRole } from "src/enums/project";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { popupAdd } from "src/redux";
import { IProjectExtended } from "src/types/project";
import getRoleDescription from "src/utils/getRoleDescription";
import useQueryProjectInvitePost from "src/utils/queries/project/useQueryProjectInvitePost";
import useQueryProjectMemberCreate from "src/utils/queries/project/useQueryProjectMemberCreate";
import useQueryMe from "src/utils/queries/user/useQueryMe";
import useQueryUsersSearch from "src/utils/queries/user/useQueryUsersSearch";

import * as classes from "./index.css";

interface IProps {
  project: IProjectExtended;
}

const Invite: VFC<IProps> = ({ project }) => {
  const dispatch = useDispatch();
  const {
    actions: { isSuitableSubscription, openModalSubscriptionAlert },
  } = useBillingContext();
  const { data: user } = useQueryMe();
  const [isOpenModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const { mutateAsync: usersSearch, isLoading: userSearchIsLoading } = useQueryUsersSearch();
  const { mutate: invitePost, isLoading: inviteIsLoading } = useQueryProjectInvitePost({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`The invitation has been sent successfully`,
        }),
      );
      setOpenModal(false);
      setEmail("");
    },
  });
  const { mutate: createMember } = useQueryProjectMemberCreate({
    onSuccess: () => {
      dispatch(
        popupAdd({
          text: t`Member successfully added`,
        }),
      );
    },
  });

  const isValidEmail = useMemo(
    () =>
      Boolean(
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
      ),
    [email],
  );

  const roleOptions = useMemo(
    () => [
      { desc: getRoleDescription(EProjectRole.ADMIN), label: t`Admin`, value: EProjectRole.ADMIN },
      { desc: getRoleDescription(EProjectRole.USER), label: t`User`, value: EProjectRole.USER },
    ],
    [],
  );

  const [role, setRole] = useState<EProjectRole | null>(roleOptions[0].value);

  const handleAddMember = useCallback(async () => {
    const resp = await usersSearch(email);

    if (resp.results[0]?.id && role) {
      createMember({
        project: project.id,
        role,
        user: resp.results[0]?.id,
      });
    } else {
      setOpenModal(true);
    }
  }, [createMember, email, project.id, role, usersSearch]);

  const handleInvitePost = useCallback(() => {
    if (role) {
      if (!user?.active_subscription || !isSuitableSubscription(ETariffOnPeriodSign.PROFESSIONAL)) {
        openModalSubscriptionAlert({
          action: "upgrade",
          text: (
            <Trans>
              1 member only is available. <br />
              If you want to add another members, upgrade you tariff to Pro or Agent
            </Trans>
          ),
          title: <Trans>New member</Trans>,
        });
      } else {
        invitePost({
          invited_email: email,
          target_project: project.id,
          target_role: role,
        });
      }
    }
  }, [email, invitePost, isSuitableSubscription, openModalSubscriptionAlert, project.id, role, user?.active_subscription]);

  const handlePreflight = useCallback(
    (callback: () => void) => () => {
      if (
        user?.billing_enabled &&
        (!user?.active_subscription || !isSuitableSubscription(ETariffOnPeriodSign.PROFESSIONAL)) &&
        user?.active_subscription?.tariff_on_period.sign !== ETariffOnPeriodSign.ELAMA
      ) {
        openModalSubscriptionAlert({
          action: "upgrade",
          text: (
            <Trans>
              1 member only is available. <br />
              If you want to add another members, upgrade you tariff to Pro or Agent
            </Trans>
          ),
          title: <Trans>New member</Trans>,
        });
      } else {
        callback();
      }
    },
    [isSuitableSubscription, openModalSubscriptionAlert, user?.active_subscription, user?.billing_enabled],
  );

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={true}>
          <UiTextField
            value={email}
            onChange={setEmail}
            inputProps={{
              placeholder: t`Search by email address`,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <UiSelect value={role} onChange={setRole} fullWidth placeholder={<Trans>Select role</Trans>} options={roleOptions} />
        </Grid>

        <Grid item xs={12} sm="auto">
          <UiButton fullWidth onClick={handlePreflight(handleAddMember)} disabled={!isValidEmail} loading={userSearchIsLoading}>
            <Trans>Add member</Trans>
          </UiButton>
        </Grid>
      </Grid>

      <UiDialog open={isOpenModal} title={<Trans>Invite a member</Trans>} css={classes.dialog} onClose={handleModalClose}>
        <DialogContent>
          <div css={classes.dialog__email}>{email}</div>

          <Box mt={2}>
            <Typography variant="body2">
              <Trans>
                The user with the specified address is not yet registered in DataFan. Send an invitation to him by email?
              </Trans>
            </Typography>
          </Box>

          <Box mt={3} mb={5} textAlign="right">
            <UiButton onClick={handlePreflight(handleInvitePost)} loading={inviteIsLoading}>
              <Trans>Send an invitation</Trans>
            </UiButton>
          </Box>
        </DialogContent>
      </UiDialog>
    </>
  );
};

export default Invite;
