import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { billingPromocodePostPromise, IRootState } from "src/redux";

const DEFAULT_CURRENCY = "RUB";

export default function usePromoCode(): {
  fail: boolean;
  pending: boolean;
  send: (code: string) => void;
  success: boolean;
} {
  const dispatch = useDispatch();
  const currency = useSelector((state: IRootState) => state.modules.user.geoip_currency);
  const [pending, setPending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);

  const send = useCallback(
    async (promocode) => {
      if (!promocode) {
        return;
      }

      try {
        setPending(true);

        await billingPromocodePostPromise(dispatch)({
          currency: currency || DEFAULT_CURRENCY,
          promocode,
        });

        setSuccess(true);
      } catch (e) {
        setFail(true);
      } finally {
        setPending(false);
      }
    },
    [currency, dispatch],
  );

  return { fail, pending, send, success };
}
