import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex !important",
    minHeight: "100vh",
  },

  icn: {
    "& img": {
      maxHeight: "100%",
    },

    alignContent: "center",
    display: "flex",
    height: 60,
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },

  paper: {
    overflow: "hidden",
    padding: theme.spacing(5, 2),
    position: "relative",
    textAlign: "center",
    width: "100%",
  },

  spinner: {
    left: 0,
    position: "absolute !important" as "absolute",
    right: 0,
    top: 0,
  },
}));
