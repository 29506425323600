import { IPipeline } from "src/types/pipeline";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

interface IInput {
  name: string;
  color?: string;
}

export default function useQueryProjectCreate(queryOptions?: IUseQueryMutationOptions<IPipeline, IInput>) {
  const invalidateProjects = useQueryProjectsInvalidate();
  const invalidateMe = useQueryMeInvalidate();

  return useMutationBase<IPipeline, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await Promise.all([invalidateProjects(), invalidateMe()]);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      url: `project/`,
    },
  );
}
