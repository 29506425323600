import { ETariffOnPeriodSign } from "src/enums/tariff";

export const tariffWeights = {
  [ETariffOnPeriodSign.TRIAL]: 0,
  [ETariffOnPeriodSign.TRIAL_OUTDATED]: 0,
  [ETariffOnPeriodSign.TRIAL_SINGLE]: 0,
  [ETariffOnPeriodSign.START]: 1,
  [ETariffOnPeriodSign.ELAMA]: 1,
  [ETariffOnPeriodSign.PROFESSIONAL]: 2,
  [ETariffOnPeriodSign.AGENT]: 3,
  [ETariffOnPeriodSign.ENTERPRISE]: 4,
};
