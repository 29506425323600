import { useAppContext } from "src/context/app/appContext";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IParams {
  password1: string;
  password2: string;
  recovery_token: string;
}

const QUERY_KEY = "auth-set-password";

export default function useQueryAuthSetPassword(params: IParams, queryOptions?: IUseQueryBaseOptions<unknown>) {
  const {
    state: { language },
  } = useAppContext();

  return useQueryBase<unknown>(
    QUERY_KEY,
    {
      cacheTime: 0,
      enabled: false,
      ...queryOptions,
    },
    {
      method: "POST",
      params,
      url: `user/set-password/${language ? "?lang=" + language : ""}`,
    },
  );
}
