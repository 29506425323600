import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const container = (theme: Theme) => css`
  background: ${theme.palette.primary.dark};
  color: ${theme.palette.primary.contrastText};
  font-size: 1rem;
  font-weight: 600;
  padding: 30px 10px;

  a {
    color: inherit;
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }

  .MuiLink-root {
    color: inherit;
    text-decoration: underline;
    display: inline;
    font-weight: inherit;
    margin-top: -3px;

    :hover {
      text-decoration: none;
    }
  }
`;

export const container_warning = (theme: Theme) => css`
  background: ${theme.palette.secondary.dark};
  color: ${theme.palette.secondary.contrastText};
`;

export const btn = css`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
