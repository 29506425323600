import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { FC, useCallback, useMemo, useState } from "react";
import ModalAccountCredentialsShare from "src/components/ModalAccountCredentialsShare";
import ProviderWithIcon from "src/components/ProviderWithIcon";
import UiButton from "src/components/UiButton";
import Confirm from "src/components/UiDialogConfirm";
import UiInfinityTable from "src/components/UiInfinityTable";
import { ICredential } from "src/types/credential";
import { IProjectExtended } from "src/types/project";
import useQueryProjectCredentialsGet from "src/utils/queries/project/useQueryProjectCredentialsGet";
import useQueryProjectCredentialsPost from "src/utils/queries/project/useQueryProjectCredentialsPost";

import * as classes from "./index.css";

interface IProps {
  project: IProjectExtended;
}

const SharedCredentials: FC<IProps> = ({ project }) => {
  const { data: credentials, isLoading: credentialsIsLoading } = useQueryProjectCredentialsGet(project.id);
  const [isOpenShareModal, setIsModalShareModal] = useState(false);
  const [deletedId, setDeletedId] = useState<number | null>(null);
  const { mutate: credentialsPost, isLoading: credentialsPostIsLoading } = useQueryProjectCredentialsPost({
    onSuccess: () => {
      setDeletedId(null);
    },
  });

  const handleOpenShareModal = useCallback(() => {
    setIsModalShareModal(true);
  }, []);

  const handleCloseShareModal = useCallback(() => {
    setIsModalShareModal(false);
  }, []);

  const handleRemoveCredential = useCallback(
    (deletedId: ICredential["id"] | null) => () => {
      setDeletedId(deletedId);
    },
    [],
  );

  const handleRemoveCredentialConfirm = useCallback(async () => {
    if (deletedId) {
      credentialsPost({
        account_credentials_ids: credentials?.filter(({ id, own }) => own && id !== deletedId).map(({ id }) => id) || [],
        projectId: project.id,
      });
    }
  }, [credentials, credentialsPost, deletedId, project.id]);

  const sharedCredentialIds = useMemo(() => credentials?.map(({ id }) => id), [credentials]);

  return useMemo(
    () => (
      <Paper elevation={6}>
        <Box p={3}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h2">
                <Trans>Shared data sources</Trans>
              </Typography>
            </Grid>

            <Grid item>
              <UiButton disableElevation variant="outlined" onClick={handleOpenShareModal} startIcon={<AddIcon />}>
                <Trans>Add</Trans>
              </UiButton>
            </Grid>
          </Grid>
        </Box>

        <UiInfinityTable
          rows={credentials || []}
          paperElevation={0}
          columns={[
            {
              name: "external_id",
              title: "ID",
            },
            {
              getCellValue: (credential: ICredential) => (credential.own ? <Trans>You</Trans> : credential.user),
              name: "user",
              title: t`Owner`,
            },
            {
              getCellValue: (credential: ICredential) => <strong>{credential.name}</strong>,
              name: "name",
              title: t`Title`,
            },
            {
              getCellValue: (row: ICredential) => <ProviderWithIcon provider={row.medium} size={24} />,
              name: "medium",
              title: t`Source`,
            },
            {
              name: "used_by_streams",
              title: t`Publics / Ads`,
            },
            {
              getCellValue: (credential: ICredential) => (
                <div css={classes.actions}>
                  <UiButton
                    disabled={!credential.own}
                    onClick={handleRemoveCredential(credential.id)}
                    variant="text"
                    startIcon={<DeleteIcon fontSize={"large"} />}
                    css={classes.btnDelete}
                  />
                </div>
              ),
              name: "actions",
              title: " ",
            },
          ]}
          loading={credentialsIsLoading}
          enableSorting={false}
          totalRows={credentials?.length}
          tableProps={{
            columnExtensions: [
              { columnName: "external_id", width: 200 },
              { columnName: "medium", width: 150 },
              { columnName: "used_by_streams", width: 180 },
              { align: "right", columnName: "actions", width: 90 },
            ],
          }}
          perPage={20}
          masonryBreakPoints={{
            sm: 6,
            xs: 12,
          }}
        />

        <ModalAccountCredentialsShare
          open={isOpenShareModal}
          onClose={handleCloseShareModal}
          projectId={project.id}
          sharedCredentialIds={sharedCredentialIds}
        />

        <Confirm
          open={!!deletedId}
          isLoaded={credentialsPostIsLoading}
          handleClose={handleRemoveCredential(null)}
          handleAction={handleRemoveCredentialConfirm}
          maxWidth="xs"
          text={t`All active streams created by other project members and that use this account will be stopped, are you sure?`}
        />
      </Paper>
    ),
    [
      credentials,
      credentialsIsLoading,
      credentialsPostIsLoading,
      deletedId,
      handleCloseShareModal,
      handleOpenShareModal,
      handleRemoveCredential,
      handleRemoveCredentialConfirm,
      isOpenShareModal,
      project.id,
      sharedCredentialIds,
    ],
  );
};

export default SharedCredentials;
