import { Plural, t, Trans } from "@lingui/macro";
import { PickersDay } from "@mui/lab";
import { PickersDayProps } from "@mui/lab/PickersDay/PickersDay";
import { DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment, { Moment } from "moment";
import { FC, MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";
import UiButton from "src/components/UiButton";
import UiDatePicker from "src/components/UiDatePicker";
import UiDialog from "src/components/UiDialog";
import UiTextField from "src/components/UiTextField";
import CalendarIcon from "src/icons/CalendarIcon";
import cssc from "src/utils/emotionComposition";

import { classes } from "./index.css";

export interface UiDateRangePickerProps {
  value?: [Moment | null, Moment | null];
  open?: boolean;
  title?: string;
  onChange: (period: [Moment | null, Moment | null]) => void;
  fullWidth?: boolean;
  maxDate?: Moment;
  minDate?: Moment;
  disabled?: boolean;
}

interface IPeriodPreset {
  value: number;
  unit: "month" | "week" | "year";
  label: string;
}

const eventApply = new Event("apply");
const eventClose = new Event("close");

const UiDateRangePicker: FC<UiDateRangePickerProps> = ({
  value,
  title,
  onChange,
  open: defaultOpen,
  fullWidth,
  maxDate,
  minDate,
  disabled,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(defaultOpen);
  const [startValue, setStartValue] = useState(value?.[0] || null);
  const [endValue, setEndValue] = useState(value?.[1] || null);
  const [hoverDay, setHoverDay] = useState<Moment | null>(null);
  const [showMobileStartDay, setShowMobileStartDay] = useState(false);
  const [showMobileEndDay, setShowMobileEndDay] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const PERIOD_PRESETS: IPeriodPreset[] = useMemo(
    () => [
      {
        label: t`Last week`,
        unit: "week",
        value: 1,
      },
      {
        label: t`Last month`,
        unit: "month",
        value: 1,
      },
      {
        label: t`Last 3 months`,
        unit: "month",
        value: 3,
      },
      {
        label: t`Last 6 months`,
        unit: "month",
        value: 6,
      },
      {
        label: t`Last year`,
        unit: "year",
        value: 1,
      },
    ],
    [],
  );

  const inputValue = useMemo(() => {
    if (!startValue) {
      return "";
    }

    let str = `${startValue.format("DD.MM.YYYY")} - `;

    if (endValue) {
      str += endValue.format("DD.MM.YYYY");
    }

    return str;
  }, [endValue, startValue]);

  const leftDate = useMemo(() => moment().subtract(1, "month"), []);

  const selectedNum = useMemo(() => (endValue ? endValue.diff(startValue, "day") + 1 : 1), [endValue, startValue]);

  const handleHoverPickerDay = useCallback((date: Moment) => () => setHoverDay(date), []);

  const handleBlurPickerDay = useCallback(
    (date: Moment) => () => {
      setHoverDay((state) => (state === date ? null : state));
    },
    [],
  );

  const handleFocusInput = useCallback(() => {
    setOpen(true);
    inputRef.current?.blur();
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    inputRef.current?.parentElement?.classList.remove("Mui-focused");

    inputRef.current?.dispatchEvent(eventClose);
  }, []);

  const handleApply = useCallback(() => {
    if (!endValue && startValue) {
      setEndValue(startValue);
    }

    inputRef.current?.dispatchEvent(eventApply);

    setOpen(false);
    inputRef.current?.parentElement?.classList.remove("Mui-focused");
  }, [endValue, startValue]);

  const handleChangeDate = useCallback(
    (date: Moment | null) => {
      if (date === null) {
        setStartValue(null);
        setEndValue(null);
        return;
      }

      if (startValue === null || endValue !== null || date.isBefore(startValue)) {
        setStartValue(date);
        setEndValue(null);

        if (isMobile) {
          setShowMobileStartDay(false);
          setShowMobileEndDay(true);
        }
      } else {
        setEndValue(date);

        if (isMobile) {
          setShowMobileStartDay(true);
          setShowMobileEndDay(false);
        }
      }
    },
    [endValue, isMobile, startValue],
  );

  const handleSelectPreset = useCallback(
    (preset: IPeriodPreset) => () => {
      setStartValue(moment().subtract(preset.value, preset.unit).add(1, "day"));
      setEndValue(moment());
    },
    [],
  );

  const renderWeekPickerDay = useCallback(
    (date: Moment, selectedDates: Array<Moment | null>, pickersDayProps: PickersDayProps<Moment>) => {
      const isActive = date.isSame(startValue, "date") || date.isSame(endValue, "date");
      const isHighlight =
        date.isAfter(startValue, "date") &&
        (date.isBefore(endValue, "date") || (endValue === null && date.isBefore(hoverDay, "date")));

      return (
        <PickersDay
          onMouseEnter={handleHoverPickerDay(date)}
          onMouseLeave={handleBlurPickerDay(date)}
          css={(isActive && classes.day_active) || (isHighlight && classes.day_hl)}
          {...pickersDayProps}
        />
      );
    },
    [endValue, handleBlurPickerDay, handleHoverPickerDay, hoverDay, startValue],
  );

  const renderHeadInputValue = useCallback(
    (v: Moment | null) => {
      if (!v) {
        return "";
      }

      if (isMobile) {
        return v.format("DD.MM.YYYY");
      }

      return v.format("D MMMM YYYY");
    },
    [isMobile],
  );

  const handleFocusHeadInput = useCallback(
    (input: "startDay" | "endDay") => (e: MouseEvent) => {
      if (!isMobile) {
        return false;
      }

      if (input === "startDay") {
        setShowMobileStartDay(!showMobileStartDay);
        setShowMobileEndDay(false);
      } else {
        setShowMobileEndDay(!showMobileEndDay);
        setShowMobileStartDay(false);
      }
    },
    [isMobile, showMobileEndDay, showMobileStartDay],
  );

  useUpdateEffect(() => {
    if (!open) {
      if (startValue && endValue) {
        onChange([startValue, endValue]);
      } else {
        onChange([null, null]);
        setStartValue(null);
        setEndValue(null);
      }
    }
  }, [open]);

  useUpdateEffect(() => {
    if (value?.[0] && value?.[1]) {
      setStartValue(value[0]);
      setEndValue(value[1]);
    }
  }, [value]);

  return (
    <>
      <UiTextField
        readOnly
        endIcon={<CalendarIcon />}
        value={inputValue}
        onFocus={handleFocusInput}
        inputRef={inputRef}
        fullWidth={fullWidth}
        css={classes.input}
        disabled={disabled}
      />

      <UiDialog
        open={Boolean(open)}
        fullWidth
        title={title || t`Select period`}
        onClose={handleCloseDialog}
        css={classes.dialog}
      >
        <DialogContent>
          <Grid container spacing={isMobile ? 1.6 : 3}>
            <Grid item xs={isMobile ? 6 : undefined}>
              <Typography mb={1.6}>
                <Trans>From</Trans>:
              </Typography>
              <div
                css={cssc([classes.headInput, showMobileStartDay && classes.headInput_active])}
                onClick={handleFocusHeadInput("startDay")}
              >
                <UiTextField fullWidth readOnly endIcon={<CalendarIcon />} value={renderHeadInputValue(startValue)} />
              </div>

              {!isMobile && (
                <UiDatePicker
                  hideHead
                  defaultCalendarMonth={leftDate}
                  value={startValue}
                  onChange={handleChangeDate}
                  renderDay={renderWeekPickerDay}
                  minDate={minDate}
                  maxDate={maxDate}
                  isStatic
                />
              )}
            </Grid>

            <Grid item xs={isMobile ? 6 : undefined}>
              <Typography mb={1.6}>
                <Trans>To</Trans>:
              </Typography>
              <div
                css={cssc([classes.headInput, showMobileEndDay && classes.headInput_active])}
                onClick={handleFocusHeadInput("endDay")}
              >
                <UiTextField
                  fullWidth
                  readOnly
                  endIcon={<CalendarIcon />}
                  value={renderHeadInputValue(endValue) || renderHeadInputValue(startValue)}
                />
              </div>
              {!isMobile && (
                <UiDatePicker
                  hideHead
                  onChange={handleChangeDate}
                  value={endValue}
                  renderDay={renderWeekPickerDay}
                  minDate={minDate}
                  maxDate={maxDate}
                  isStatic
                />
              )}
            </Grid>

            {!showMobileStartDay && !showMobileEndDay ? (
              <Grid item xs={isMobile ? 12 : true}>
                <Typography mb={1.6}>
                  <Trans>Preset</Trans>:
                </Typography>
                <Grid container spacing={1.6} flexDirection="column">
                  {PERIOD_PRESETS.map((preset) => (
                    <Grid item key={preset.label}>
                      <UiButton
                        variant="outlined"
                        disableElevation
                        fullWidth
                        textAlign="left"
                        onClick={handleSelectPreset(preset)}
                      >
                        {preset.label}
                      </UiButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <div css={classes.datePickerMobile}>
                <UiDatePicker
                  hideHead
                  onChange={handleChangeDate}
                  value={showMobileStartDay ? startValue : endValue}
                  renderDay={renderWeekPickerDay}
                  minDate={minDate}
                  maxDate={maxDate}
                  isStatic
                />
              </div>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>
                <Plural
                  value={selectedNum}
                  _0="Not selected"
                  one={
                    <Trans>
                      <b># day</b> selected
                    </Trans>
                  }
                  few={
                    <Trans>
                      <b># days</b> selected
                    </Trans>
                  }
                  many={
                    <Trans>
                      <b># days</b> selected
                    </Trans>
                  }
                  other={
                    <Trans>
                      <b># days</b> selected
                    </Trans>
                  }
                />
              </Typography>
            </Grid>

            <Grid item>
              <UiButton onClick={handleApply}>OK</UiButton>
            </Grid>
          </Grid>
        </DialogActions>
      </UiDialog>
    </>
  );
};

export default UiDateRangePicker;
