import { SearchPanel as SearchPanelBase } from "@devexpress/dx-react-grid";
import { SearchPanel, SearchPanelProps } from "@devexpress/dx-react-grid-material-ui";
import { t } from "@lingui/macro";
import { IconSearch } from "@tabler/icons";
import { FC } from "react";
import { createPortal } from "react-dom";
import UiTextField from "src/components/UiTextField";

const Input =
  (portal?: HTMLElement) =>
  ({ value, onValueChange }: SearchPanelBase.InputProps) =>
    portal ? (
      createPortal(<UiTextField fullWidth value={value} onChange={onValueChange} endIcon={<IconSearch />} />, portal)
    ) : (
      <UiTextField fullWidth value={value} onChange={onValueChange} endIcon={<IconSearch />} />
    );

export interface UiDxSearchPanelProps extends SearchPanelProps {
  portal?: HTMLElement;
}

const UiDxSearchPanel: FC<UiDxSearchPanelProps> = ({ portal, ...props }) => (
  <SearchPanel
    messages={{
      searchPlaceholder: t`Search` + "...",
    }}
    inputComponent={Input(portal)}
    {...props}
  />
);

export default UiDxSearchPanel;
