import { css } from "@emotion/react/macro";

export const button = css`
  &:hover {
    opacity: 0.8;
  }

  border-color: #ffffff !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export const stack = css`
  margin-top: -10px;
  margin-bottom: -10px;
`;
