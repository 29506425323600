export const serializeTariffs = (items: any[]) =>
  items.map((item) => ({
    id: item.id,
    period: item.period.name,
    priceEur: item.eur_price,
    priceEurDiscount: item.eur_discount_price,
    priceRub: item.rub_price,
    priceRubDiscount: item.rub_discount_price,
    sign: item.sign,
    title: item.tariff.name,
  }));

export const serializeOptions = (items: any[]) =>
  items.map((item) => ({
    id: item.id,
    name: item.name,
    priceEur: item.eur_price,
    priceRub: item.rub_price,
    sign: item.sign,
    tariffId: item.tariff_on_period,
  }));
