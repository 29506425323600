import { Trans } from "@lingui/macro";
import { Box, Link, Typography } from "@mui/material";
import { FC } from "react";
import ConnectorInstruction from "src/components/ConnectorInstruction";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import ConnectorPowerBiIcon from "src/icons/ConnectorPowerBiIcon";

import ImgStep1Ru from "./assets/step_1_ru.jpg";
import ImgStep2Ru from "./assets/step_2_ru.jpg";
import ImgStep3Ru from "./assets/step_3_ru.jpg";
import ImgStep4Ru from "./assets/step_4_ru.jpg";
import ImgStep5Ru from "./assets/step_5_ru.jpg";
import ImgStep6Ru from "./assets/step_6_ru.jpg";
import ImgStep7Ru from "./assets/step_7_ru.jpg";
import ImgStep8Ru from "./assets/step_8_ru.jpg";
import ImgStep9Ru from "./assets/step_9_ru.jpg";
import * as classes from "./index.css";

const URL = "https://github.com/evikbook/DatafanConnectors";

const ConnectorPowerBI: FC = () => (
  <Section>
    <SectionHead
      title={<Trans>Connector DataFan — PowerBI</Trans>}
      subTitle={<Trans>Comprehensive tools for social media performance dashboard creation</Trans>}
      icon={<ConnectorPowerBiIcon />}
    />

    <Typography variant="body2" mt={5} textAlign="center">
      <UiButton target="_blank" component="a" href={URL}>
        <Trans>Create report</Trans>
      </UiButton>
    </Typography>

    <div css={classes.instruction}>
      <Typography variant="h2" mb={6}>
        <Trans>DataFan connector installation</Trans>
      </Typography>

      <ConnectorInstruction
        steps={[
          {
            picture: ImgStep1Ru,
            text: (
              <Trans>
                <Link target="_blank" href={URL} component="a">
                  Download the extension
                </Link>{" "}
                file Datafan.mez and put it into the [Documents]\Power BI Desktop\Custom Connectors directory. If directory
                doesn't exist, please create it
              </Trans>
            ),
            title: <Trans>Download connector file</Trans>,
          },
          {
            picture: ImgStep2Ru,
            text: <Trans>Open Power BI Desktop</Trans>,
            title: <Trans>Launch Power BI</Trans>,
          },
          {
            picture: ImgStep3Ru,
            text: (
              <Trans>
                Note: to load extensions in development, the security level for extensions in Power BI Desktop should be leveled
                down to enable loading unsigned/uncertified connectors. To do that go to to File/Options and
                settings/Options/Data Extensions/Allow any extension to load without warning or validation. Click OK and restart
                Power BI
              </Trans>
            ),
            title: <Trans>Configure security settings</Trans>,
          },
          {
            picture: ImgStep4Ru,
            text: (
              <Trans>
                Before initiating an import make sure your public or ad account is connected to DataFan and data are collected.
                Also make sure you're logged in into DataFan in your browser
              </Trans>
            ),
            title: <Trans>Connect your publics and ad accounts to DataFan</Trans>,
          },
          {
            picture: ImgStep5Ru,
            text: (
              <Trans>Click "Get Data" and pick Datafan Projects from the list of available connectors, click "Connect"</Trans>
            ),
            title: <Trans>Import your data from DataFan</Trans>,
          },
          {
            picture: ImgStep6Ru,
            text: (
              <Trans>
                Input your DataFan credentials in the authorisation window. If you're already authorised at DataFan in browser,
                the window won't appear
              </Trans>
            ),
            title: <Trans>Log into DataFan</Trans>,
          },
          {
            picture: ImgStep7Ru,
            text: (
              <Trans>
                There's a list of DataFan projects available. Open required project and choose the dataset. Then click "upload"{" "}
              </Trans>
            ),
            title: <Trans>Select datasets to be imported</Trans>,
          },
          {
            picture: ImgStep8Ru,
            text: <Trans>Now data are available for analysis and visualization</Trans>,
            title: <Trans>Data are successfully uploaded</Trans>,
          },
          {
            picture: ImgStep9Ru,
            text: <Trans>To update the data just click "update"</Trans>,
            title: <Trans>Update your data</Trans>,
          },
        ]}
      />

      <Box textAlign="center" mt={6}>
        <UiButton target="_blank" component="a" href={URL}>
          <Trans>Create report</Trans>
        </UiButton>
      </Box>
    </div>
  </Section>
);

export default ConnectorPowerBI;
