import { Trans } from "@lingui/macro";
import { Link } from "@mui/material";
import { FC } from "react";
import { useLocation } from "react-router";
import cssc from "src/utils/emotionComposition";

import { classes } from "./index.css";

const Footer: FC = () => {
  const location = useLocation<{ isNewUser?: boolean } | undefined>();

  return (
    <footer css={cssc([classes.footer, location.state?.isNewUser && classes.footer_lock])}>
      <Link href="/offer-for-service/" css={classes.link}>
        <Trans>Offer for service</Trans>
      </Link>
      <Link href="/terms-of-use/" css={classes.link}>
        <Trans>Terms of use</Trans>
      </Link>
      <Link href="/privacy-policy/" css={classes.link}>
        <Trans>Privacy policy</Trans>
      </Link>
    </footer>
  );
};

export default Footer;
