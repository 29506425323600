import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DatasetMergeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.4,22.1H2.8c-0.5,0-0.9-0.4-0.9-0.9v-4.5c0-0.5-0.4-0.9-0.9-0.9S0,16.2,0,16.7v4.5C0,22.7,1.3,24,2.8,24h4.6
				c0.5,0,0.9-0.4,0.9-0.9S7.9,22.1,7.4,22.1z"
      />
      <path
        d="M6.9,7.2c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,1,0,1.3l2.6,2.6H0.9C0.4,11.1,0,11.5,0,12C0,12.6,0.4,13,0.9,13h7.2
				l-2.6,2.6c-0.4,0.4-0.4,1,0,1.3c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3L9.8,14c0.5-0.5,0.8-1.2,0.8-2
				c0-0.8-0.3-1.5-0.8-2L6.9,7.2z"
      />
      <path
        d="M0.9,8.3c0.5,0,0.9-0.4,0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h4.6c0.5,0,0.9-0.4,0.9-0.9S7.9,0,7.4,0H2.8C1.3,0,0,1.3,0,2.8
				v4.5C0,7.8,0.4,8.3,0.9,8.3z"
      />
      <path
        d="M23.1,11.1h-7.3l2.6-2.6c0.4-0.4,0.4-1,0-1.3c-0.4-0.4-1-0.4-1.3,0L14.2,10c-0.5,0.5-0.8,1.2-0.8,2c0,0.8,0.3,1.5,0.8,2
				l2.9,2.9c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.3L15.9,13h7.2c0.5,0,0.9-0.4,0.9-0.9
				C24,11.5,23.6,11.1,23.1,11.1z"
      />
      <path
        d="M21.2,0h-4.6c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h4.6c0.5,0,0.9,0.4,0.9,0.9v4.5c0,0.5,0.4,0.9,0.9,0.9
				S24,7.8,24,7.3V2.8C24,1.3,22.7,0,21.2,0z"
      />
      <path
        d="M23.1,15.8c-0.5,0-0.9,0.4-0.9,0.9v4.5c0,0.5-0.4,0.9-0.9,0.9h-4.6c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h4.6
				c1.6,0,2.8-1.3,2.8-2.8v-4.5C24,16.2,23.6,15.8,23.1,15.8z"
      />
    </SvgIcon>
  );
}
