import { IProject } from "src/types/project";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

interface IInput {
  project_id: IProject["id"];
}

export default function useQueryProjectDeclineInvite(queryOptions?: IUseQueryMutationOptions<unknown, IInput>) {
  const invalidateProjects = useQueryProjectsInvalidate();

  return useMutationBase<unknown, IInput>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await invalidateProjects();

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "DELETE",
      url: "project/approve-member/",
    },
  );
}
