import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const classes = {
  datePickerMobile: css`
    margin: 30px auto 0;
  `,
  day_active: css`
    background: #55ade0 !important;
    color: #ffffff !important;
  `,
  day_hl: css`
    background: #f1f6f8 !important;
  `,
  dialog: (theme: Theme) => css`
    .MuiPaper-root {
      max-width: 850px;

      > .MuiGrid-root {
        ${theme.breakpoints.down("sm")} {
          justify-content: flex-end;
          flex-direction: row-reverse;
          padding-left: 15px;

          .MuiButtonBase-root {
            margin-right: 5px;
          }
        }
      }
    }
  `,
  headInput: css`
    position: relative;

    :after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `,
  headInput_active: css`
    .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #505050;
    }

    .MuiSvgIcon-root {
      cursor: default;
      color: #000000;
    }
  `,
  headInput_desktop: css`
    cursor: default !important;

    input {
      cursor: default;
    }

    :hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }

      .MuiSvgIcon-root {
        cursor: default;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
  `,
  input: css`
    cursor: pointer;
  `,
};
