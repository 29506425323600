import { Trans } from "@lingui/macro";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import InternalTransactions from "src/app/Billing/components/InternalTransactions";
import Offer from "src/app/Billing/components/Offer";
import PaymentHistory from "src/app/Billing/components/PaymentHistory";
import Projects from "src/app/Billing/components/Projects";
import PromoCodeModal from "src/app/Billing/components/PromoCodeModal";
import Section, { SectionHead } from "src/components/Section";
import UiTabs from "src/components/UiTabs";
import { useAppContext } from "src/context/app/appContext";
import { PrivateFC } from "src/types/helpers";

import * as classes from "./index.css";

const Billing: PrivateFC = ({ user }) => {
  const history = useHistory();
  const { state } = useAppContext();
  const [activeTab, setActiveTab] = useState(0);
  const isRub = user?.geoip_currency === "RUB";

  const handleClosePromocode = useCallback(() => {
    history.push("/billing/", { disableScrollTop: true });
  }, [history]);

  const renderPromoCodeModal = useCallback(
    () => <PromoCodeModal isOpen={true} handleClose={handleClosePromocode} />,
    [handleClosePromocode],
  );

  if (!user.billing_enabled) {
    return <Redirect to="/" />;
  }

  return (
    <Section>
      <SectionHead title={<Trans>My billing</Trans>}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={state.sidebar.expanded ? 12 : 6} xl={6}>
            <Paper css={classes.paperLeft} elevation={8}>
              <Offer user={user} />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={state.sidebar.expanded ? 12 : 6} xl={6}>
            <div css={classes.paperRight}>
              <UiTabs
                value={activeTab}
                onChange={setActiveTab}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label={<Trans>Resources and projects</Trans>} />
                <Tab label={<Trans>Payment history</Trans>} />
                <Tab label={<Trans>Bonus account</Trans>} />
              </UiTabs>

              <Divider />

              {activeTab === 0 && <Projects user={user} />}

              {activeTab === 1 && <PaymentHistory />}

              {activeTab === 2 && <InternalTransactions />}
            </div>
          </Grid>
        </Grid>
      </SectionHead>

      <Typography variant="body1" align="center" color="textSecondary">
        <Box component="span" display="block" mt={2} mb={2}>
          <Link to={{ pathname: "/billing/promocode/", state: { disableScrollTop: true } }}>
            <Trans>Activate promocode</Trans>
          </Link>
        </Box>
        <Trans>You can use the individual development of a report costing from</Trans> {isRub ? "5000 ₽" : "100 €"}
        <br />
        <Trans>Email us at</Trans> <a href="mailto:support@datafan.pro">support@datafan.pro</a>
      </Typography>

      <Switch>
        <Route exact={true} path="/billing/promocode/" render={renderPromoCodeModal} />
      </Switch>
    </Section>
  );
};

export default Billing;
