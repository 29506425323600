import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Moment } from "moment";
import { FC, ReactNode, useCallback, useMemo, useRef } from "react";
import UiDialog from "src/components/UiDialog";
import UiTextField from "src/components/UiTextField";

import { classes } from "./index.css";

interface IProp {
  children: ReactNode;
  open: boolean;
  value: Moment | null;
  isStatic?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Wrapper: FC<IProp> = ({ children, open, value, isStatic, onOpen, onClose }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const inputValue = useMemo(() => (value ? value.format("DD.MM.YYYY") : undefined), [value]);

  const handleFocusInput = useCallback(() => {
    onOpen && onOpen();
    inputRef.current?.blur();
  }, [onOpen]);

  if (isStatic) {
    return <>{children}</>;
  }

  return (
    <>
      <UiTextField readOnly endIcon={<CalendarTodayIcon />} value={inputValue} onFocus={handleFocusInput} inputRef={inputRef} />

      <UiDialog open={open} onClose={onClose} hideCross withoutPadding css={classes.wrapper}>
        {children}
      </UiDialog>
    </>
  );
};

export default Wrapper;
