import { Trans } from "@lingui/macro";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IconInfoCircle } from "@tabler/icons";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import NoDataAlert from "src/components/PipelineCreateForm/components/CreatePublicAds/components/NoDataAlert";
import UiSelect from "src/components/UiSelect";
import { ECredentialMetaObject } from "src/enums/credential";
import { ICredential } from "src/types/credential";
import { IPipelineType } from "src/types/pipeline";
import { IPipelineConfigFbPublic } from "src/types/pipelineConfig";
import { IProject } from "src/types/project";
import useQueryCredentialMetaGet from "src/utils/queries/credentials/useQueryCredentialMetaGet";

interface IProps {
  credentialId: ICredential["id"];
  projectId: IProject["id"];
  pipelineType: IPipelineType;
  onChange: (config: IPipelineConfigFbPublic | null) => void;
  onChangeValidation: (isValid: boolean) => void;
}

const ConfigVkPublic: FC<IProps> = ({ projectId, credentialId, pipelineType, onChange, onChangeValidation }) => {
  const [selectedPage, setSelectedPage] = useState<IPipelineConfigFbPublic["page"] | null>(null);

  const {
    data: pages,
    isFetched: pagesFetched,
    isFetching: pagesIsFetching,
  } = useQueryCredentialMetaGet(
    {
      credentialId,
      object: ECredentialMetaObject.FACEBOOK_PAGES,
      projectId,
    },
    {
      onSuccess: (data) => {
        if (!selectedPage) {
          setSelectedPage(data[0] || null);
        }
      },
    },
  );

  const pageOptions = useMemo(
    () =>
      pages?.map((page) => ({
        data: page,
        label: `${page.name} (${page.id})`,
        value: page.id,
      })) || [],
    [pages],
  );

  const handleSelectPage = useCallback(
    (value: typeof pageOptions[number]["value"] | null, data: typeof pageOptions[number]["data"] | null) => {
      if (data) {
        setSelectedPage(data);
      }
    },
    [],
  );

  useEffect(() => {
    onChangeValidation(Boolean(selectedPage));

    onChange({
      page: selectedPage || undefined,
      page_id: selectedPage?.["id"],
    });
  }, [onChangeValidation, onChange, selectedPage]);

  return (
    <>
      {pageOptions.length === 0 && pagesFetched ? (
        <NoDataAlert pipelineType={pipelineType} />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <UiSelect
            isSearchable={pageOptions.length > 7}
            isLoading={pagesIsFetching}
            value={selectedPage?.id}
            onChange={handleSelectPage}
            options={pageOptions}
            placeholder={<Trans>Page</Trans>}
            fullWidth
          />

          <Tooltip
            title={
              <Trans>
                If you don't see the page you need in the list of available pages, please check your access level. Your role in
                the community should be Editor, Moderator or Administrator
              </Trans>
            }
          >
            <span>
              <IconInfoCircle />
            </span>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};

export default ConfigVkPublic;
