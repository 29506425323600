import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ProjectsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M19,21H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H19a3,3,0,0,1,3,3v9A3,3,0,0,1,19,21ZM5,8A1,1,0,0,0,4,9v9a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1Z" />
      <path d="M16,8a1,1,0,0,1-1-1V5a1,1,0,0,0-1-1H10A1,1,0,0,0,9,5V7A1,1,0,0,1,7,7V5a3,3,0,0,1,3-3h4a3,3,0,0,1,3,3V7A1,1,0,0,1,16,8Z" />
      <path d="M12,13a1,1,0,1,1,1-1h0A1,1,0,0,1,12,13Z" />
      <path d="M12,16.13a21,21,0,0,1-9.45-2.24,1,1,0,0,1,.9-1.78,19.13,19.13,0,0,0,17.1,0,1,1,0,1,1,.9,1.78A21,21,0,0,1,12,16.13Z" />
    </SvgIcon>
  );
}
