import { AxiosError } from "axios";
import { useAppContext } from "src/context/app/appContext";
import { ELanguage } from "src/enums/language";
import { useMutationBase } from "src/utils/queries/useQueryBase";
import useQueryMe, { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

interface IInput {
  confirm_password?: string;
  new_password?: string;
  language?: ELanguage;
  email?: string;
}

export default function useQueryMePatch(props?: {
  onSuccess?: () => void;
  onFail?: (response: AxiosError["response"]) => void;
}) {
  const { updateState } = useAppContext();
  const { data: user } = useQueryMe();
  const userInvalidate = useQueryMeInvalidate();

  const { onSuccess, onFail } = props || {};

  const { mutateAsync: mutateMe, isLoading: meIsLoading } = useMutationBase<unknown, IInput>(undefined, {
    method: "PATCH",
    params: ({ email, ...params }) => params,
    url: "user/me/",
  });

  const { mutateAsync: mutateEmail, isLoading: emailIsLoading } = useMutationBase<unknown, IInput>(undefined, {
    method: "PATCH",
    params: ({ email, confirm_password }) => ({ email, password: confirm_password }),
    url: "user/change-email/",
  });

  return {
    isLoading: meIsLoading || emailIsLoading,
    mutate: (params: IInput) =>
      Promise.all([
        (params.language && params.language !== user?.language) || params.new_password ? mutateMe(params) : Promise.resolve(),
        params.email && params.email !== user?.email ? mutateEmail(params) : Promise.resolve(),
      ])
        .then(() => {
          if (params.language) {
            updateState({ language: params.language }, "ls");
          }

          setTimeout(() => {
            onSuccess && onSuccess();
            userInvalidate();
          });
        })
        .catch((e: AxiosError) => {
          onFail && onFail(e.response);
        }),
  };
}
