import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { ChangeEvent, FC, ReactNode, useCallback } from "react";
import { NavLink } from "react-router-dom";
import UiButton from "src/components/UiButton";
import UiPhoneInput from "src/components/UiPhoneInput";
import UiTextField from "src/components/UiTextField";

import * as classes from "./index.css";

interface IField {
  onChange: (event: any) => any;
  value?: string;
  name: string;
  title: string;
  type: string;
}

interface ILink {
  to:
    | string
    | {
        pathname: string;
        state: { next?: string };
      };
  title: ReactNode;
}

interface IProps {
  onSubmit: () => void;
  fields: IField[];
  links?: ILink[];
  checkbox?: {
    onChange: (event: any) => any;
    title: ReactNode;
    value: boolean;
  };

  button: {
    title: ReactNode;
    disabled?: boolean;
  };
}

const AuthForm: FC<IProps> = (props) => {
  const { onSubmit, fields, button, links, checkbox } = props;

  const handleChangeCheckbox = useCallback(
    (event: any, checked: boolean) => {
      checkbox?.onChange(checked);
    },
    [checkbox],
  );

  const handleSubmitForm = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();

      setTimeout(onSubmit, 100);
    },
    [onSubmit],
  );

  const renderFields = () =>
    fields.map((field) => (
      <Box mt={2} mb={2} key={field.name}>
        {field.type === "phone" ? (
          <UiPhoneInput
            placeholder={field.title}
            fullWidth
            {...field}
            inputProps={{ required: true }}
            onlyCountries={["ru"]}
            country="ru"
            countryCodeEditable={false}
          />
        ) : (
          <UiTextField id={field.name} placeholder={field.title} fullWidth required {...field} />
        )}
      </Box>
    ));

  const renderLinks = (links: ILink[]) =>
    links.map((link) => (
      <Grid item key={typeof link.to === "string" ? link.to : link.to.pathname}>
        <NavLink to={link.to} css={classes.link}>
          {link.title}
        </NavLink>
      </Grid>
    ));

  return (
    <form css={classes.form} onSubmit={handleSubmitForm}>
      {renderFields()}

      {checkbox && (
        <Box mb={2}>
          <FormControlLabel
            checked={checkbox.value}
            onChange={handleChangeCheckbox}
            control={<Checkbox color="primary" />}
            label={checkbox.title}
          />
        </Box>
      )}

      <UiButton type="submit" fullWidth loading={button.disabled}>
        {button.title}
      </UiButton>

      {links && (
        <Box mt={2}>
          <Grid container justifyContent={links.length > 1 ? "space-between" : "center"}>
            {renderLinks(links)}
          </Grid>
        </Box>
      )}
    </form>
  );
};

export default AuthForm;
