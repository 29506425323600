import { t, Trans } from "@lingui/macro";
import { Paper, Stack, StepContent } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import moment, { Moment } from "moment";
import React, { ComponentProps, FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import Section, { SectionHead } from "src/components/Section";
import UiButton from "src/components/UiButton";
import UiDateRangePicker from "src/components/UiDateRangePicker";
import { EExportStatus, EExportType } from "src/enums/export";
import ConnectorExcelIcon from "src/icons/ConnectorExcelIcon";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import useQueryExport from "src/utils/queries/export/useQueryExport";
import useQueryExportCreate from "src/utils/queries/export/useQueryExportCreate";
import useQueryExportUrl from "src/utils/queries/export/useQueryExportUrl";
import useQueryPipelineGet from "src/utils/queries/pipeline/useQueryPipelineGet";

import SelectPipeline from "./components/SelectPipeline";

const ConnectorExcelCreateReport: FC = () => {
  const location = useLocation<{ pipelineId?: IPipeline["id"] } | undefined>();
  const [activeStep, setActiveStep] = useState(location.state?.pipelineId ? 1 : 0);
  const steps = useMemo(
    () => [t`Choosing a public/ad account`, t`Data collection`, t`Additional settings`, t`Excel report generation`],
    [],
  );
  const [tempPipelineId, setTempPipelineId] = useState<IPipeline["id"] | null>(location.state?.pipelineId || null);
  const [pipelineId, setPipelineId] = useState<IPipeline["id"] | null>(location.state?.pipelineId || null);
  const [refetchPipeline, setRefetchPipeline] = useState(false);
  const [refetchExportEnabled, setRefetchExportEnabled] = useState(true);
  const [period, setPeriod] = useState<[Moment, Moment]>([moment().subtract(31, "days"), moment()]);
  const {
    data: pipeline,
    isFetching: isPipelinePending,
    isFetched: isPipelineFetched,
    refetch: pipelineRefetch,
  } = useQueryPipelineGet(pipelineId || undefined, {
    enabled: Boolean(pipelineId),
    onSuccess: (data) => {
      const isReady = data.data_status === "ready";

      setRefetchPipeline(!isReady);

      if (isReady && pipelineId) {
        setActiveStep(2);

        if (data.data_first_date && data.data_last_date) {
          setPeriod([moment(data.data_first_date), moment(data.data_last_date)]);
        }
      } else {
        setActiveStep(1);
      }
    },
    refetchInterval: refetchPipeline ? 3000 : false,
  });
  const { data: exportCreateData, mutate: createExport } = useQueryExportCreate(pipelineId || undefined);

  const { data: exportFreshData } = useQueryExport(
    {
      enabled: typeof exportCreateData?.id !== "undefined" && refetchExportEnabled,
      onError: () => setRefetchExportEnabled(false),
      onSuccess: (data) => {
        setRefetchExportEnabled(![EExportStatus.SUCCESS, EExportStatus.FAILURE].includes(data.status));
      },
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
    },
    exportCreateData?.id,
  );

  const { data: exportUrl, isFetching: isExportUrlFetching } = useQueryExportUrl(exportFreshData?.id, {
    enabled: typeof exportFreshData?.id !== "undefined" && exportFreshData.status === EExportStatus.SUCCESS,
  });

  const isNextEnable = useMemo(() => {
    if (activeStep === 0) {
      return Boolean(tempPipelineId);
    }

    if (activeStep === 1) {
      return pipeline?.data_status === "ready";
    }

    if (activeStep === 2) {
      return period[0].toDate() <= period[1].toDate();
    }
  }, [activeStep, period, pipeline?.data_status, tempPipelineId]);

  const handleChangePeriod = useCallback<ComponentProps<typeof UiDateRangePicker>["onChange"]>((newPeriod) => {
    if (moment.isMoment(newPeriod[0]) && moment.isMoment(newPeriod[1])) {
      setPeriod(newPeriod as [Moment, Moment]);
    }
  }, []);

  const handleBack = useCallback(() => {
    if (activeStep === 1) {
      setPipelineId(null);
    }

    setActiveStep((state) => (state === 2 ? 0 : state - 1));
  }, [activeStep]);

  const handleNext = useCallback(() => {
    if (activeStep === 0) {
      if (pipelineId) {
        pipelineRefetch();
      } else {
        setPipelineId(tempPipelineId);
        setActiveStep((state) => state + 1);
      }
    } else {
      setActiveStep((state) => state + 1);
    }
  }, [activeStep, pipelineId, pipelineRefetch, tempPipelineId]);

  const handleCreateNewPipeline = useCallback((pipeline: IPipelineExtended) => {
    setPipelineId(pipeline.id);
    setActiveStep((state) => state + 1);
  }, []);

  useUpdateEffect(() => {
    if (activeStep === 3) {
      createExport({
        dateFrom: period[0].format("YYYY-MM-DD"),
        dateTo: period[1].format("YYYY-MM-DD"),
        type: EExportType.EXCEL,
      });
    }
  }, [activeStep]);

  useUpdateEffect(() => {
    if (exportUrl) {
      setActiveStep(4);
    }
  }, [exportUrl]);

  return (
    <Section>
      <SectionHead title={<Trans>Excel report generation</Trans>} icon={<ConnectorExcelIcon />} />

      <Paper sx={{ padding: 3 }}>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>

              <StepContent>
                {activeStep === 0 && (
                  <SelectPipeline
                    pipelineId={tempPipelineId}
                    onSelect={setTempPipelineId}
                    onCreateNew={handleCreateNewPipeline}
                  />
                )}

                {activeStep === 1 && pipeline && isPipelineFetched && (
                  <Box pt={1} pb={1}>
                    <Typography variant="body2" color={pipeline.aggregated_status.color} component="div">
                      <div>{pipeline.aggregated_status.title}</div>
                      <div>{pipeline.aggregated_status.description}</div>
                    </Typography>
                  </Box>
                )}

                {activeStep === 2 && (
                  <Box pt={2} pb={2}>
                    <UiDateRangePicker onChange={handleChangePeriod} value={period} maxDate={moment()} fullWidth />
                  </Box>
                )}

                {activeStep === 3 && (refetchExportEnabled || isExportUrlFetching) && (
                  <Box pt={2} pb={1}>
                    <CircularProgress size={24} />
                  </Box>
                )}

                {activeStep !== 1 && (
                  <Stack spacing={2} direction="row" mt={2}>
                    <UiButton variant="text" disabled={activeStep === 0} onClick={handleBack}>
                      <Trans>Back</Trans>
                    </UiButton>

                    <UiButton onClick={handleNext} disabled={!isNextEnable} loading={isPipelinePending}>
                      <Trans>Next</Trans>
                    </UiButton>
                  </Stack>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length && (
          <Box mt={2}>
            <UiButton component="a" download href={exportUrl}>
              <Trans>Download report</Trans>
            </UiButton>
          </Box>
        )}
      </Paper>
    </Section>
  );
};

export default ConnectorExcelCreateReport;
