import { Trans } from "@lingui/macro";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconCreditCard } from "@tabler/icons";
import React, { ReactNode, useCallback, useState, VFC } from "react";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import PurchaseOptionWidget, { PurchaseOptionWidgetProps } from "src/components/PurchaseOptionWidget";
import UiButton from "src/components/UiButton";
import UiDialog from "src/components/UiDialog";
import { useBillingContext } from "src/context/billing/billingContext";
import { ETariffOnPeriodSign } from "src/enums/tariff";

import * as classes from "./index.css";

export interface ModalSubscriptionAlertProps {
  title?: string | ReactNode;
  text: string | ReactNode;
  isOpen: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  disableClose?: boolean;
  action: undefined | "upgrade" | "renew" | "buy_option";
  extraParams?: {
    additionalOptionsNum?: number;
    tariffSign?: ETariffOnPeriodSign;
  };
}

const ModalSubscriptionAlert: VFC<ModalSubscriptionAlertProps> = ({
  action,
  isOpen,
  title,
  text,
  onSuccess,
  onClose,
  extraParams,
  disableClose,
}) => {
  const {
    actions: { renewSubscription, openModalSubscriptionOrder },
  } = useBillingContext();
  const [isTemporallyClosed, toggleTemporallyClosed] = useToggle(false);
  const [purchaseOptionWidgetProps, setPurchaseOptionWidgetProps] = useState<null | PurchaseOptionWidgetProps>(null);

  const handleClickPayButton = useCallback(() => {
    toggleTemporallyClosed(true);

    renewSubscription({
      additionalOptionsNum: extraParams?.additionalOptionsNum || 0,
      onClose: toggleTemporallyClosed,
      onSuccess: () => {
        toggleTemporallyClosed(false);
        onSuccess && onSuccess();
      },
    });
  }, [extraParams?.additionalOptionsNum, onSuccess, renewSubscription, toggleTemporallyClosed]);

  const handleClickBuyOptionsButton = useCallback(() => {
    setPurchaseOptionWidgetProps({
      onSuccess,
    });
  }, [onSuccess]);

  const handleClickUpgradeButton = useCallback(() => {
    if (!extraParams?.tariffSign) {
      return;
    }

    openModalSubscriptionOrder({
      additionalOptionsNum: extraParams?.additionalOptionsNum,
      onSuccess,
      renewalWithFreeDays: false,
      tariffSign: extraParams.tariffSign,
    });
  }, [extraParams?.additionalOptionsNum, extraParams?.tariffSign, onSuccess, openModalSubscriptionOrder]);

  return (
    <UiDialog
      open={isOpen && !isTemporallyClosed}
      hideCross
      withoutPadding
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      css={classes.dialog}
      disableCloseOnBackdropClick={disableClose}
    >
      <Paper variant="filled" css={classes.paper}>
        <Box sx={{ mb: 4 }}>
          <IconCreditCard size={68} />
        </Box>
        <Typography sx={{ mb: 2 }} variant="h2">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>
          {text}
        </Typography>

        {purchaseOptionWidgetProps ? (
          <Box sx={{ height: 52 }}>
            <PurchaseOptionWidget {...purchaseOptionWidgetProps} />
          </Box>
        ) : (
          <>
            {action === "renew" && (
              <UiButton size="large" onClick={handleClickPayButton}>
                <Trans>Go to payment</Trans>
              </UiButton>
            )}

            {action === "upgrade" &&
              (extraParams?.tariffSign ? (
                <UiButton onClick={handleClickUpgradeButton}>
                  <Trans>Upgrade tariff</Trans>
                </UiButton>
              ) : (
                <UiButton component={Link} to="/tariffs/" onClick={onClose}>
                  <Trans>Upgrade tariff</Trans>
                </UiButton>
              ))}

            {action === "buy_option" && (
              <UiButton size="large" onClick={handleClickBuyOptionsButton}>
                <Trans>Buy now</Trans>
              </UiButton>
            )}
          </>
        )}
      </Paper>
    </UiDialog>
  );
};

export default ModalSubscriptionAlert;
