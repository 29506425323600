import { EPipelineStatus, EPipelineType } from "src/enums/pipeline";
import { EStreamReportStatus, EStreamStatus } from "src/enums/stream";
import { IPipeline, IPipelineDataStatus } from "src/types/pipeline";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";

export default function usePipelineDataStatus() {
  const { data: pipelineTypes } = useQueryPipelineTypes();

  return (pipeline: IPipeline): IPipelineDataStatus => {
    const isPipelineSupportFastRun = pipelineTypes?.[pipeline.source].enable_fast_run;

    if (pipeline.status === EPipelineStatus.ARCHIVED) {
      return "archived";
    }

    const streams = [EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source)
      ? pipeline.streams_merge || []
      : pipeline.streams;

    if ([EPipelineType.MERGE, EPipelineType.MERGE_PLAN_FACT].includes(pipeline.source)) {
      if (pipeline.report_status.some(({ status }) => status !== EStreamReportStatus.SUCCESS) && pipeline.is_first_run) {
        return "in_progress_first_run";
      }

      return "ready";
    }

    if (
      [EPipelineStatus.CREDENTIAL_LOST, EPipelineStatus.ARCHIVED, EPipelineStatus.DELETED].includes(pipeline.status) ||
      streams.some(
        ({ status }) =>
          status !== null &&
          [
            EStreamStatus.CREDENTIAL_LOST,
            EStreamStatus.CREDENTIAL_NOT_PERMISSION,
            EStreamStatus.CREDENTIAL_NOT_ACCOUNT_PERMISSION,
            EStreamStatus.CREDENTIAL_LOST_SHARED,
          ].includes(status),
      ) ||
      pipeline.report_status.some((rs) => rs.status !== EStreamReportStatus.SUCCESS && rs.retry_count >= 5)
    ) {
      return "error";
    }

    if (pipeline.report_status.some(({ status }) => status !== EStreamReportStatus.SUCCESS)) {
      if (
        isPipelineSupportFastRun &&
        pipeline.is_first_run &&
        !streams.some(({ escaping_fast_already }) => !escaping_fast_already)
      ) {
        return "ready_partly";
      }

      if (pipeline.is_first_run) {
        return "in_progress_first_run";
      }

      return "in_progress";
    }

    return "ready";
  };
}
