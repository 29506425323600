import { t } from "@lingui/macro";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { EInvoiceStatus, popupAdd } from "src/redux";
import { IInvoice } from "src/types/billing";
import { IPaymentMethod } from "src/types/paymentMethod";
import useNativePopup from "src/utils/hoooks/useNativePopup";
import useQueryBillingInvoiceCreate from "src/utils/queries/billing/useQueryBillingInvoiceCreate";
import useQueryBillingInvoiceGet from "src/utils/queries/billing/useQueryBillingInvoiceGet";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import useQueryMe, { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

const INVOICE_FETCH_DELAY = 1000;

declare var cp: any;

export interface IOrder {
  id?: number;
  system: "yandex" | "cloudpayments" | "maib";
  options?: object[];
  promocode?: string;
  renewalWithFreeDays: boolean;
}

export default function usePayment() {
  const dispatch = useDispatch();
  const meInvalidate = useQueryMeInvalidate();
  const projectsInvalidate = useQueryProjectsInvalidate();
  const tempInvoiceSecret = useRef<string | undefined>();
  const [processingInvoiceSecret, setProcessingInvoiceSecret] = useState<string | undefined>();
  const [processingOrder, setProcessingOrder] = useState<IOrder | undefined>();
  const { data: user } = useQueryMe();
  const { mutateAsync: createInvoice } = useQueryBillingInvoiceCreate({
    onError: () => setPaymentPending(false),
  });
  const [isPaymentPending, setPaymentPending] = useState<boolean>(false);
  const [completeCallback, setCompleteCallback] = useState<{
    fn?: (success: boolean) => void;
  }>();

  useQueryBillingInvoiceGet(
    {
      secret: processingInvoiceSecret,
    },
    {
      onSuccess: async (invoice) => {
        if (invoice && invoice.status !== EInvoiceStatus.PROCESSING) {
          setProcessingInvoiceSecret(undefined);

          if (invoice.status === EInvoiceStatus.PAID) {
            await Promise.all([meInvalidate, projectsInvalidate({ refetchInactive: true })]);
          }

          if (invoice.status === EInvoiceStatus.CANCELED) {
            if (invoice.yandex_kassa_payment.cancellation_details?.reason === "country_forbidden") {
              dispatch(
                popupAdd({
                  importance: "error",
                  text: t`Your card was issued out of Russian Federation. So please fill in the payment form again`,
                }),
              );

              if (processingOrder) {
                return setTimeout(() => {
                  payOrder({
                    callback: completeCallback?.fn,
                    order: {
                      ...processingOrder,
                      system: "maib",
                    },
                  });
                }, 3000);
              }
            } else {
              dispatch(
                popupAdd({
                  importance: "error",
                  text: t`Your payment failed. Please contact support`,
                }),
              );
            }
          }

          if (completeCallback?.fn) {
            completeCallback.fn(invoice.status === EInvoiceStatus.PAID);
            setCompleteCallback(undefined);
          }

          setPaymentPending(false);
          setProcessingOrder(undefined);
        }
      },
      refetchInterval: processingInvoiceSecret ? INVOICE_FETCH_DELAY : false,
      refetchIntervalInBackground: true,
    },
  );

  const { open: openBillingWindow } = useNativePopup<{ success: boolean }>({
    onClose: () => {
      window.name = "";
    },
    onMessage: (data) => {
      if (data.success) {
        window.name = "";

        setPaymentPending(true);
        setProcessingInvoiceSecret(tempInvoiceSecret.current);
        tempInvoiceSecret.current = undefined;
      }
    },
    onOpen: () => {
      window.name = "datafan-payment-parent";
    },
    single: true,
  });

  const openPaymentWidget = useCallback(
    ({ order, invoice }: { order: IOrder; invoice: IInvoice; callback?: (success: boolean) => void }) => {
      if (user) {
        if (order.system === "cloudpayments" && cp) {
          document.querySelectorAll('[role="presentation"] [tabindex]').forEach((el) => el.removeAttribute("tabindex"));

          const widget = new cp.CloudPayments({
            language: {
              en: "en-US",
              ru: "ru-RU",
            }[user.language],
          });

          widget.pay(
            "auth",
            {
              accountId: user.id,
              amount: Number(invoice.total),
              currency: user.geoip_currency,
              description: invoice.description,
              invoiceId: invoice.id,
              publicId: process.env.REACT_APP__CLOUDPAYMENTS_WIDGET_PUBLIC_ID,
              skin: "mini",
            },
            {
              onFail: () => {
                setPaymentPending(false);
              },
              onSuccess: () => {
                setProcessingInvoiceSecret(invoice.secret);
              },
            },
          );
        } else if (order.system === "yandex") {
          if (invoice.yandex_kassa_payment.confirmation.type === "embedded" && YooMoneyCheckoutWidget) {
            const checkout = new window.YooMoneyCheckoutWidget({
              confirmation_token: invoice.yandex_kassa_payment.confirmation.confirmation_token,
              customization: {
                modal: true,
              },
              error_callback: (event: any) => {
                if (event.error === "token_expired") {
                  popupAdd({
                    importance: "error",
                    text: t`The payment time has expired`,
                  });
                }
              },
            });

            checkout.on("modal_close", () => {
              setPaymentPending(false);
            });

            checkout.on("fail", () => {
              setProcessingInvoiceSecret(invoice.secret);
              checkout.destroy();
            });

            checkout.on("success", () => {
              setProcessingInvoiceSecret(invoice.secret);
              checkout.destroy();
            });

            checkout.render();
          }
        }
      }
    },
    [user],
  );

  const payOrder = useCallback(
    async ({
      order,
      callback,
      autoRenewalId,
      savePaymentMethod,
    }: {
      order: IOrder;
      callback?: (success: boolean) => void;
      autoRenewalId?: IPaymentMethod["id"];
      savePaymentMethod?: boolean;
    }) => {
      setProcessingOrder(order);
      setCompleteCallback({ fn: callback });
      setPaymentPending(true);

      const isRedirect = order.system === "yandex" && !savePaymentMethod && !autoRenewalId;

      const billingPopup = isRedirect || order.system === "maib" ? openBillingWindow() : undefined;

      const invoice = await createInvoice({
        ...order,
        autoRenewalId,
        isRedirect,
        savePaymentMethod,
      }).catch(() => {
        if (billingPopup) {
          billingPopup.close();
        }
      });

      if (!invoice) {
        return;
      }

      if (order.system === "maib" && process.env.REACT_APP__MAIB_PAYMENT_URL && billingPopup) {
        tempInvoiceSecret.current = invoice.secret;
        billingPopup.location.href = `${process.env.REACT_APP__MAIB_PAYMENT_URL}?trans_id=${invoice.maib_payment.payment_id}`;
        setPaymentPending(false);
        return false;
      } else if (invoice.yandex_kassa_payment.confirmation.confirmation_url && billingPopup) {
        tempInvoiceSecret.current = invoice.secret;
        billingPopup.location.href = invoice.yandex_kassa_payment.confirmation.confirmation_url;
        setPaymentPending(false);
        return false;
      } else if (billingPopup) {
        billingPopup.close();
      }

      if (invoice.full_paid_internally) {
        setPaymentPending(false);
        callback && callback(true);
        return true;
      }

      if (autoRenewalId) {
        setProcessingInvoiceSecret(invoice.secret);
        return true;
      }

      if (order.system === "yandex" && autoRenewalId && invoice.yandex_kassa_payment.cancellation_details?.reason) {
        setPaymentPending(false);
        dispatch(
          popupAdd({
            importance: "error",
            text: invoice.yandex_kassa_payment.cancellation_details.message,
          }),
        );
        return false;
      }

      if (!document.getElementById("payment-widget")) {
        const script = document.createElement("script");

        script.id = "payment-widget";
        script.src = (
          order.system === "yandex"
            ? process.env.REACT_APP__YOOKASSA_WIDGET_SRC
            : process.env.REACT_APP__CLOUDPAYMENTS_WIDGET_SRC
        ) as string;

        script.addEventListener("load", () => {
          openPaymentWidget({
            callback,
            invoice,
            order,
          });
        });

        document.body.appendChild(script);
      } else {
        openPaymentWidget({
          callback,
          invoice,
          order,
        });
      }
    },
    [createInvoice, dispatch, openBillingWindow, openPaymentWidget],
  );

  return { isPaymentPending, payOrder };
}
