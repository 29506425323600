import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material/styles";

export const classes = {
  btn: css`
    padding: 11px;
    color: inherit;
  `,
  btn_expended: css`
    color: #cccccc;
  `,
  container: (theme: Theme) => css`
    width: 305px;
    align-items: center;
    margin-left: -194px;
    transition: margin-left 300ms;

    ${theme.breakpoints.down("lg")} {
      width: 271px;
      margin-left: -199px;
    }
    ${theme.breakpoints.down("sm")} {
      margin-left: -207px;
    }
  `,
  container_expanded: css`
    margin-left: 0;
  `,
  logo: css`
    display: block;
    height: 32px;
    margin-left: 40px;
    margin-right: 74px;
  `,
};
