import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import MediaFavicon from "src/components/MediaFavicon";
import { IPipelineExtended } from "src/types/pipeline";

interface IProps {
  pipeline: IPipelineExtended;
}

const PipelineName: FC<IProps> = ({ pipeline }) => (
  <Grid container spacing={1} wrap="nowrap">
    {pipeline.media && (
      <Grid item>
        <MediaFavicon media={pipeline.media} />
      </Grid>
    )}

    <Grid item>
      <Typography variant="body2" display="inline">
        {pipeline.name}
      </Typography>
    </Grid>
  </Grid>
);

export default PipelineName;
