import { IExport } from "src/types/export";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";

export default function useQueryExportDelete(queryOptions?: IUseQueryMutationOptions<unknown, IExport["id"]>) {
  return useMutationBase<unknown, IExport["id"]>(queryOptions, {
    method: "DELETE",
    params: () => null,
    url: (exportId) => `export/${exportId}/`,
  });
}
