import { Trans } from "@lingui/macro";
import EditIcon from "@mui/icons-material/Edit";
import { Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { FC, useCallback, useState } from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Page404 from "src/app/Page404/Page404";
import SharedCredentials from "src/app/Projects/pages/Edit/components/SharedCredentials";
import Loader from "src/components/Loader";
import Section, { SectionHead } from "src/components/Section";
import { EProjectRole } from "src/enums/project";
import useQueryProjectGet from "src/utils/queries/project/useQueryProjectGet";
import useQueryProjectMembersGet from "src/utils/queries/project/useQueryProjectMembersGet";

import Invite from "./components/Invite";
import Members from "./components/Members";
import ModalEdit from "./components/ModalEdit";
import * as classes from "./index.css";

const ProjectsEdit: FC = () => {
  const params = useParams<{ projectId: string }>();
  const [isOpenModal, setOpenModal] = useState(false);
  const { data: project, isLoading: projectIsLoading } = useQueryProjectGet(Number(params.projectId));

  const { data: members } = useQueryProjectMembersGet(Number(params.projectId));

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  if (projectIsLoading) {
    return <Loader />;
  }

  if (!project) {
    return <Page404 />;
  }

  if (!project.is_approved) {
    return <Redirect to={`/projects/${project.id}/accept-invite/`} />;
  }

  return (
    <Section>
      <SectionHead>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <div css={classes.head__color(project.color)} />

          <Typography variant="h1">{project.name}</Typography>

          {project.role === EProjectRole.OWNER && (
            <IconButton onClick={handleOpenModal} size="large">
              <EditIcon fontSize="medium" />
            </IconButton>
          )}
        </Stack>

        {project.role === EProjectRole.OWNER && (
          <>
            <Typography variant="h2" sx={{ mb: 3, mt: 6 }}>
              <Trans>Members</Trans>
            </Typography>

            <Invite project={project} />

            <Members project={project} />
          </>
        )}
      </SectionHead>

      {[EProjectRole.ADMIN, EProjectRole.OWNER].includes(project.role) && members && members.length > 1 && (
        <SharedCredentials project={project} />
      )}

      <ModalEdit isOpen={isOpenModal} project={project} onClose={handleCloseModal} />
    </Section>
  );
};

export default ProjectsEdit;
