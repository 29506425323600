import { css } from "@emotion/react/macro";
import { UiButtonProps } from "src/components/UiButton/index";

export const classes = {
  button: (align: NonNullable<UiButtonProps["textAlign"]>) => css`
    justify-content: ${{
      center: "center",
      left: "flex-start",
      right: "flex-end",
    }[align]} !important;
  `,
  progress: css`
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  `,
};
