import { IPipeline } from "src/types/pipeline";
import { IPipelineConfig } from "src/types/pipelineConfig";
import { useQueryProjectsInvalidate } from "src/utils/queries/project/useQueryProjects";
import { IUseQueryMutationOptions, useMutationBase } from "src/utils/queries/useQueryBase";
import { useQueryMeInvalidate } from "src/utils/queries/user/useQueryMe";

export default function useQueryPipelineCreate(queryOptions?: IUseQueryMutationOptions<IPipeline, IPipelineConfig>) {
  const invalidateProjects = useQueryProjectsInvalidate();
  const invalidateMe = useQueryMeInvalidate();

  return useMutationBase<IPipeline, IPipelineConfig>(
    {
      ...queryOptions,
      onSuccess: async (...args) => {
        await Promise.all([invalidateProjects(), invalidateMe()]);

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, args);
        }
      },
    },
    {
      method: "POST",
      url: `pipeline/`,
    },
  );
}
