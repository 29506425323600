import { useQueryClient } from "react-query";
import { EProgressType } from "src/enums/progress";
import { IExport } from "src/types/export";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "export-excel-get";

export default function useQueryExport(queryOptions: IUseQueryBaseOptions<IExport>, exportId?: number | string) {
  const queryClient = useQueryClient();

  return useQueryBase<IExport>(
    [QUERY_KEY, exportId],
    {
      enabled: typeof exportId !== "undefined",
      ...queryOptions,
      onSuccess: async (data) => {
        if (queryOptions?.refetchInterval && data.progress) {
          const progress = data.progress.items[data.progress.current_item_sign];

          if (progress.type === EProgressType.TIMER && progress.current + 1 < progress.total) {
            setTimeout(() => {
              if (data.progress) {
                queryClient.setQueryData([QUERY_KEY, exportId], {
                  ...data,
                  progress: {
                    ...data.progress,
                    items: {
                      ...data.progress?.items,
                      [data.progress.current_item_sign]: {
                        ...progress,
                        current: progress.current + 1,
                      },
                    },
                  },
                });
              }
            }, 1000);
          }
        }

        if (queryOptions?.onSuccess) {
          queryOptions.onSuccess.apply(queryOptions, [data]);
        }
      },
    },
    {
      method: "GET",
      url: `export/${exportId}/`,
    },
  );
}
