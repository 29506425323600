interface IIndexed {
  [key: string]: any;
}

export function normalizeObjectsArray<A extends IIndexed>(
  arr: Array<A>,
  key: keyof A,
): {
  [key: string]: A;
} {
  return arr.reduce((acc, item) => {
    acc[item[key]] = item;

    return acc;
  }, {} as IIndexed);
}
