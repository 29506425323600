const API_URL = process.env.REACT_APP__API_BASE_URL || "http://127.0.0.1:8080/api";
const API_VERSION = process.env.REACT_APP__API_VERSION || 0;

let refreshTokenPromise: Promise<{
  token: string;
  refresh_token: string;
}> | null;

export default async function apiRefreshToken() {
  const refreshToken = window.localStorage.getItem("refresh_token");

  if (!refreshToken) {
    return null;
  }

  try {
    refreshTokenPromise =
      refreshTokenPromise ||
      fetch(`${API_URL}/${API_VERSION}/api-token-refresh/`, {
        body: JSON.stringify({ refresh_token: refreshToken }),
        headers: { "content-type": "application/json" },
        method: "POST",
      })
        .then((resp) => resp.json())
        .finally(() => {
          refreshTokenPromise = null;
        });

    return await refreshTokenPromise;
  } catch (e) {
    return null;
  }
}
