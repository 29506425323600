import { GridSize, useMediaQuery } from "@mui/material";
import { Breakpoint, Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";

export type ITableMasonryBreakPoints = {
  [point in Breakpoint]?: GridSize;
};

export default function useTableMasonry(breakPoints: ITableMasonryBreakPoints | undefined): boolean {
  const theme = useTheme();
  const sortedValues = (Object.keys(theme.breakpoints.values) as Breakpoint[]).sort((k1, k2) =>
    theme.breakpoints.values[k1] > theme.breakpoints.values[k2] ? -1 : 1,
  );

  return useMediaQuery((theme: Theme) => {
    if (breakPoints) {
      const maxPointIndex = sortedValues.findIndex((key) => typeof breakPoints[key] !== "undefined");
      const maxPoint = sortedValues[Math.max(maxPointIndex - 1, 0)];

      return maxPoint ? theme.breakpoints.down(maxPoint) : "undefined";
    }

    return "undefined";
  });
}
