import { QueryKey } from "react-query/types/core/types";
import { IPipeline } from "src/redux";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

export default function useQueryPipelineUnarchive(
  queryKey: QueryKey,
  pipelineId: IPipeline["id"],
  queryOptions?: IUseQueryBaseOptions<unknown>,
) {
  return useQueryBase(queryKey, queryOptions, {
    method: "POST",
    params: {
      pipeline_ids: [pipelineId],
    },
    url: `pipeline/unarchive/`,
  });
}
