import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import MediaFavicon from "src/components/MediaFavicon";
import { ICredential } from "src/types/credential";

import * as classes from "./index.css";

interface IProps {
  models: ICredential[];
}

const Credentials: FC<IProps> = ({ models }) => (
  <Stack direction="row" spacing={1}>
    {models.map(({ name, medium, id }) => (
      <Stack direction="row" spacing={1} key={id} css={classes.chip}>
        <MediaFavicon media={medium} />
        <Typography color="inherit">{name}</Typography>
      </Stack>
    ))}
  </Stack>
);

export default Credentials;
