import { IOAuthData, IOAuthDataRequestGet } from "src/types/oAuth";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

const QUERY_KEY = "oauth-get";

export default function useQueryOAuthGet(params: IOAuthDataRequestGet, queryOptions: IUseQueryBaseOptions<IOAuthData>) {
  return useQueryBase<IOAuthData>([QUERY_KEY, params], queryOptions, {
    method: "GET",
    params,
    url: `o/authorize/`,
  });
}
