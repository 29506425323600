import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  container: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  dialogTitle: {
    marginBottom: theme.spacing(2),
  },
  hint: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4, 0),
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(3),
  },
}));
