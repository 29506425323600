import produce from "immer";
import { IModulesActionMap, IModulesState } from "src/redux/modules/index";
import { IPipeline } from "src/redux/modules/pipelines/data";
import { actionCompile, IActionT, IActionTPromiseCreator, LOADING } from "src/utils";
import { normalizeObjectsArray } from "src/utils/normalizeObjectsArray";

const { createActionCreator, createPromiseCreator } = actionCompile<IModulesActionMap, IModulesState>();

export interface IPipelinesMappingActionMap {
  PIPELINES_MAPPING_GET__F: {};
  PIPELINES_MAPPING_GET__R: {
    pipelineId: IPipeline["id"];
  };
  PIPELINES_MAPPING_GET__S: {
    mapping: any;
  };

  PIPELINES_MAPPING_CLEAR: {};
}

export const pipelinesMappingGet = createActionCreator("PIPELINES_MAPPING_GET__R");
export type IPipelinesMappingGetF = IActionT<IModulesActionMap, "PIPELINES_MAPPING_GET__F">;
export type IPipelinesMappingGetR = IActionT<IModulesActionMap, "PIPELINES_MAPPING_GET__R">;
export type IPipelinesMappingGetS = IActionT<IModulesActionMap, "PIPELINES_MAPPING_GET__S">;
export const pipelinesMappingGetPromise = createPromiseCreator<"PIPELINES_MAPPING_GET__R", "PIPELINES_MAPPING_GET__S">(
  pipelinesMappingGet,
);
export type IPipelinesMappingGetPromise = IActionTPromiseCreator<
  IModulesActionMap,
  "PIPELINES_MAPPING_GET__R",
  "PIPELINES_MAPPING_GET__S"
>;

export const clearPipelinesMapping = createActionCreator("PIPELINES_MAPPING_CLEAR");

export const pipelinesMappingReducers = {
  PIPELINES_MAPPING_CLEAR: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesMappingState[LOADING] = false;
      draft.pipelinesMappingState.models = undefined;
      draft.pipelinesMappingState.modelsNormalized = undefined;
    }),

  PIPELINES_MAPPING_GET__F: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesMappingState[LOADING] = false;
    }),
  PIPELINES_MAPPING_GET__R: (modulesState: IModulesState): IModulesState =>
    produce(modulesState, (draft) => {
      draft.pipelinesMappingState[LOADING] = true;
    }),
  PIPELINES_MAPPING_GET__S: (modulesState: IModulesState, action: IPipelinesMappingGetS): IModulesState => {
    const { mapping } = action.payload;

    return produce(modulesState, (draft) => {
      draft.pipelinesMappingState[LOADING] = false;
      draft.pipelinesMappingState.models = mapping;
      draft.pipelinesMappingState.modelsNormalized = normalizeObjectsArray(mapping, "name");
    });
  },
};
