import { useQueryClient } from "react-query";
import { ICredential } from "src/types/credential";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  email: string;
  own: boolean;
}

const QUERY_KEY = "credential-info-get";

export default function useQueryCredentialInfo(
  credentialId: ICredential["id"] | undefined,
  queryOptions?: IUseQueryBaseOptions<IResponse>,
) {
  return useQueryBase<IResponse>(
    [QUERY_KEY, credentialId],
    {
      enabled: credentialId !== undefined,
      refetchOnMount: false,
      ...queryOptions,
    },
    {
      method: "GET",
      url: `account-credentials/info/${credentialId}/`,
    },
  );
}

export function useQueryCredentialsInvalidate() {
  const clientQuery = useQueryClient();

  return (credentialId: ICredential["id"]) =>
    clientQuery.invalidateQueries([QUERY_KEY, credentialId], { refetchInactive: true });
}
