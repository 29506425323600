import { FC } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "src/components/PrivateRoute";

import ConnectorExcelCreateReport from "./pages/CreateReport";
import ConnectorExcelHome from "./pages/Home";

const ConnectorExcel: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ConnectorExcelHome} />
      <PrivateRoute exact path={`${path}create/`} component={ConnectorExcelCreateReport} />
    </Switch>
  );
};

export default ConnectorExcel;
