import { TabProps, Tabs, TabsProps } from "@mui/material";
import { Children, FC, PropsWithChildren, ReactElement, SyntheticEvent, useCallback, useState } from "react";
import { useMount, useUnmount } from "react-use";
import UiSelect from "src/components/UiSelect";

interface UiTabsProps extends Omit<TabsProps, "onChange" | "href"> {
  onChange: (value: any) => void;
  selectMode?: number;
}

const UiTabs: FC<UiTabsProps> = ({ onChange, children, value, selectMode, ...props }) => {
  const [isSelectMode, setSelectMode] = useState(false);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      onChange(newValue);
    },
    [onChange],
  );

  const handleChangeSelect = useCallback(
    (newValue: number | null) => {
      if (newValue !== null) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const handleSetMode = useCallback(() => {
    if (selectMode) {
      setSelectMode(window.innerWidth < selectMode);
    }
  }, [selectMode]);

  useMount(() => {
    window.addEventListener("resize", handleSetMode);
    handleSetMode();
  });

  useUnmount(() => {
    window.removeEventListener("resize", handleSetMode);
  });

  if (isSelectMode) {
    const options = Children.map(children, (child, index) => {
      const item = child as ReactElement<PropsWithChildren<TabProps>>;

      return {
        label: item.props.label as string,
        value: item.props.value || index,
      };
    });

    return options ? <UiSelect fullWidth value={value} options={options} onChange={handleChangeSelect} /> : null;
  }

  return (
    <Tabs onChange={handleChange} value={value} selectionFollowsFocus={true} {...props}>
      {children}
    </Tabs>
  );
};

export default UiTabs;
