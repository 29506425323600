import { t, Trans } from "@lingui/macro";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { IconBriefcase, IconInfoCircle } from "@tabler/icons";
import equal from "fast-deep-equal";
import moment from "moment";
import React, { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";
import PeriodDataCollection from "src/components/ModalPipelineEdit/components/PeriodDataCollection";
import ReportSharing from "src/components/ModalPipelineEdit/components/ReportSharing";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import { useBillingContext } from "src/context/billing/billingContext";
import { EPipelineType } from "src/enums/pipeline";
import { EProjectRole } from "src/enums/project";
import { ETariffOnPeriodSign } from "src/enums/tariff";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import { IStreamConfig } from "src/types/stream";
import { IUser } from "src/types/user";
import deserializePipelineConfig from "src/utils/deserializePipelineConfig";
import useQueryCredentialInfo from "src/utils/queries/credentials/useQueryCredentialInfo";
import useQueryCredentialsGet from "src/utils/queries/credentials/useQueryCredentialsGet";
import { IUseQueryPipelinePatchParams } from "src/utils/queries/pipeline/useQueryPipelinePatch";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import AdsYaCounter from "./components/AdsYaCounter";
import Credentials from "./components/Credentials";
import FbAdsAttributes from "./components/FbAdsAttributes";
import FrequencyUpdate from "./components/FrequencyUpdate";
import InstFbAds from "./components/InstFbAds";
import SelectProjectOption from "./components/SelectProjectOption";
import VkGroupToken from "./components/VkGroupToken";
import * as classes from "./index.css";

type IExtraSettings = NonNullable<IStreamConfig["extra_settings"]>;
type IYandexMetrikaSettings = IExtraSettings["yandex_metrika"];
type IVkGroupCredentialId = IStreamConfig["account_public_credentials_id"];

interface IProps {
  pipeline: IPipelineExtended;
  user: IUser;
  owner: IUser | undefined;
  onSubmit: (params: IUseQueryPipelinePatchParams) => void;
  isLoading: boolean;
  dialogRef: HTMLDivElement | null;
}

interface IForm {
  name: IPipeline["name"];
  run_delta: IPipeline["run_delta"];
  project: IPipeline["project"];
  fbAdsAccountEnabled: boolean;
  fbAdsAccountSettings:
    | {
        id: IExtraSettings["facebook_ad_account_id"];
        name: IExtraSettings["facebook_ad_account_name"];
      }
    | undefined;
  yandexMetrikaIsEnabled: boolean;
  yandexMetrikaSettings: IYandexMetrikaSettings;
  vkGroupIsEnabled: boolean;
  vkGroupCredentialId: IVkGroupCredentialId;
  dateFrom: string | undefined;
}

interface ILocationState {
  pipelineEditForm: IForm;
  pipelineEditId: IPipeline["id"];
}

const ModalPipelineEditCommon: FC<IProps> = ({ pipeline, user, onSubmit, isLoading, dialogRef, owner }) => {
  const {
    actions: { isSuitableSubscription },
  } = useBillingContext();
  const location = useLocation<ILocationState | undefined>();
  const initialForm = useMemo<IForm>(() => {
    const fbAdAccountId = pipeline.streams[0].config_source.extra_settings?.facebook_ad_account_id;
    const fbAdAccountName = pipeline.streams[0].config_source.extra_settings?.facebook_ad_account_id;
    const yandexMetrikaSettings = pipeline.streams[0].config_source.extra_settings?.yandex_metrika;
    const vkGroupCredId = pipeline.streams.find((s) => s.config_source.account_public_credentials_id)?.config_source
      .account_public_credentials_id;
    const dateFrom = pipeline.streams[0].config_source.date_from;

    return {
      dateFrom,
      fbAdsAccountEnabled: Boolean(fbAdAccountId),
      fbAdsAccountSettings: fbAdAccountId
        ? {
            id: fbAdAccountId,
            name: fbAdAccountName,
          }
        : undefined,
      name: pipeline.name,
      project: pipeline.project,
      run_delta: pipeline.run_delta,
      vkGroupCredentialId: vkGroupCredId,
      vkGroupIsEnabled: Boolean(vkGroupCredId),
      yandexMetrikaIsEnabled: Boolean(yandexMetrikaSettings),
      yandexMetrikaSettings,
    };
  }, [pipeline.name, pipeline.project, pipeline.run_delta, pipeline.streams]);
  const [form, setForm] = useState<IForm>(location.state?.pipelineEditForm || initialForm);
  const credentialIds = useMemo(
    () => pipeline.streams.map(({ config_source: { account_credentials_id } }) => account_credentials_id),
    [pipeline.streams],
  );
  const { data: projects } = useQueryProjects();
  const { data: credentials } = useQueryCredentialsGet(
    {
      enabled: credentialIds.length > 0,
    },
    {
      id__in: credentialIds,
    },
  );

  const { data: credInfo } = useQueryCredentialInfo(credentialIds?.[0]);

  const projectOptions = useMemo(
    () =>
      projects?.map((project) => ({
        data: project,
        isDisabled: project.role !== EProjectRole.OWNER,
        label: project.name,
        value: project.id,
      })),
    [projects],
  );

  const startDate = useMemo(
    () =>
      pipeline && pipeline.streams[0].config_source.date_from
        ? t`At ${moment(pipeline.streams[0].config_source.date_from).format("D MMMM YYYY")}`
        : undefined,
    [pipeline],
  );

  const targets = useMemo(
    () =>
      pipeline && pipeline.streams.map(({ source, config_source }) => deserializePipelineConfig(source, config_source)).flat(),
    [pipeline],
  );

  const instagramOpenAccount = useMemo(() => pipeline.streams[0].config_source.search_account_username, [pipeline]);

  const { uniqueTargets } = useMemo(
    () =>
      (targets || []).reduce(
        (
          acc: {
            hashMap: { [key: string]: boolean | undefined };
            uniqueTargets: NonNullable<typeof targets>;
          },
          target,
        ) => {
          const hash = `${target.name}${target.ids.join("")}`;

          if (!acc.hashMap[hash]) {
            acc.hashMap[hash] = true;
            acc.uniqueTargets.push(target);
          }

          return acc;
        },
        {
          hashMap: {},
          uniqueTargets: [],
        },
      ),
    [targets],
  );

  const handleChangeForm = useCallback(
    <A extends keyof IForm>(key: A) =>
      (value: IForm[A] | null) => {
        if (value !== null) {
          setForm((state) => ({
            ...(state || {}),
            [key]: value,
          }));
        }
      },
    [],
  );

  const handleSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      const config = (() => {
        if ([EPipelineType.FB_ADS, EPipelineType.VK_ADS].includes(pipeline.source)) {
          return pipeline.streams.map((stream) => ({
            ...stream.config_source,
            date_from: form.dateFrom,
            extra_settings: {
              ...stream.config_source.extra_settings,
              yandex_metrika: form.yandexMetrikaIsEnabled ? form.yandexMetrikaSettings : undefined,
            },
            id: stream.id,
            source: stream.source,
          }));
        } else if ([EPipelineType.INST_MEDIA, EPipelineType.INST_BA_MEDIA].includes(pipeline.source)) {
          return pipeline.streams.map((stream) => ({
            ...stream.config_source,
            date_from: form.dateFrom,
            extra_settings: {
              ...stream.config_source.extra_settings,
              facebook_ad_account_id: form.fbAdsAccountEnabled ? form.fbAdsAccountSettings?.id : undefined,
              facebook_ad_account_name: form.fbAdsAccountEnabled ? form.fbAdsAccountSettings?.name : undefined,
            },
            id: stream.id,
            source: stream.source,
          }));
        } else if (pipeline.source === EPipelineType.MYTARGET) {
          return pipeline.streams.map((stream) => ({
            ...stream.config_source,
            date_from: form.dateFrom,
            id: stream.id,
            source: stream.source,
          }));
        } else if (
          [EPipelineType.VK_COMMUNITY_POST, EPipelineType.VK_COMMUNITY, EPipelineType.VK_POST].includes(pipeline.source)
        ) {
          return pipeline.streams.map((stream) => {
            const { walls, group_ids, ...configSource } = stream.config_source;
            const groupId = walls?.[0].id || group_ids?.[0].id;

            return {
              ...configSource,
              account_public_credentials_id: form.vkGroupIsEnabled ? form.vkGroupCredentialId : undefined,
              date_from: form.dateFrom,
              extra_settings: {},
              group_id: groupId,
              id: stream.id,
              source: stream.source,
            };
          });
        }
      })();

      const updatedForm = (Object.keys(form) as Array<keyof IForm>).reduce((acc, key) => {
        if (!["name", "project", "run_delta"].includes(key)) {
          return acc;
        }

        if (initialForm[key] !== form[key]) {
          if (key === "run_delta" && form.run_delta) {
            acc[key] = Math.abs(form.run_delta);
          } else {
            acc[key] = form[key] as any;
          }
        }

        return acc;
      }, {} as Partial<IForm>);

      const oldYaCounter = pipeline.streams.find((stream) => stream.config_source.extra_settings?.yandex_metrika?.counter)
        ?.config_source.extra_settings?.yandex_metrika?.counter;

      const oldYaGoalIds = pipeline.streams
        .find((stream) => stream.config_source.extra_settings?.yandex_metrika?.goals)
        ?.config_source.extra_settings?.yandex_metrika?.goals?.map(({ id }) => id)
        .sort();

      const newYaGoalIds = form.yandexMetrikaSettings?.goals?.map(({ id }) => id).sort();

      onSubmit({
        ...updatedForm,
        config: config as any,
        is_new_ya_settings: Boolean(
          form.yandexMetrikaSettings?.counter &&
            (oldYaCounter !== form.yandexMetrikaSettings?.counter ||
              JSON.stringify(oldYaGoalIds) !== JSON.stringify(newYaGoalIds)),
        ),
      });
    },
    [form, initialForm, onSubmit, pipeline.source, pipeline.streams],
  );

  const hasChanges = useMemo(
    () => !equal(JSON.parse(JSON.stringify(form)), JSON.parse(JSON.stringify(initialForm))),
    [form, initialForm],
  );

  const credentialId = useMemo(() => pipeline.streams[0].config_source.account_credentials_id, [pipeline.streams]);

  const persistState = useMemo(
    () => ({
      pipelineEditForm: form,
      pipelineEditId: pipeline.id,
    }),
    [form, pipeline.id],
  );

  const vkGroupId = useMemo(
    () => pipeline.streams.find((s) => s.config_source.walls)?.config_source.walls?.[0].id,
    [pipeline.streams],
  );

  useMount(() => {
    const paper = dialogRef?.querySelector("[role=dialog]");

    if (hasChanges && paper) {
      paper.scrollTo(0, paper.clientHeight);
    }
  });

  return (
    <>
      <div css={classes.status} style={{ color: pipeline.aggregated_status.color }}>
        <Typography variant="h4" css={classes.status__title}>
          {pipeline.aggregated_status.title}
        </Typography>

        <Typography css={classes.status__desc}>{pipeline.aggregated_status.description}</Typography>
      </div>

      <form onSubmit={handleSubmitForm}>
        <div css={classes.form__row}>
          <UiTextField
            fullWidth
            label={<Trans>Name of the public / ad account</Trans>}
            value={form.name}
            onChange={handleChangeForm("name")}
          />
        </div>

        {user.id === pipeline.user && (
          <div css={classes.form__row}>
            <UiSelect
              fullWidth
              label={
                <Stack direction="row" spacing={0.6}>
                  <IconBriefcase width={18} />
                  <span>
                    <Trans>Project</Trans>
                  </span>
                </Stack>
              }
              value={form.project}
              options={projectOptions}
              onChange={handleChangeForm("project")}
              components={{
                Option: SelectProjectOption,
              }}
            />
          </div>
        )}

        <PeriodDataCollection css={classes.form__row} pipeline={pipeline} onChange={handleChangeForm("dateFrom")} />

        {startDate && (
          <div css={classes.form__row}>
            <UiTextField fullWidth disabled value={startDate} label={<Trans>Data collection start date</Trans>} />
          </div>
        )}

        {owner && (
          <div css={classes.form__row}>
            <UiTextField fullWidth disabled value={owner.humand_name} label={<Trans>Creator</Trans>} />
          </div>
        )}

        {credentials && credentials.length > 0 && (
          <div css={classes.form__row}>
            <Typography mb={1.6}>
              <Trans>Source</Trans>
            </Typography>

            <Credentials models={credentials} />
          </div>
        )}

        {uniqueTargets.length > 0 &&
          uniqueTargets.map(({ type, ids, name }) => (
            <div css={classes.form__row} key={ids.join()}>
              <UiTextField
                disabled
                value={`${name ? name + " " : ""}(${ids.join(", ")})`}
                fullWidth
                label={
                  <>
                    {type === "ads" && <Trans>Ad account</Trans>}
                    {type === "client" && <Trans>Client</Trans>}
                    {type === "community" && <Trans>Community</Trans>}
                    {type === "page" && <Trans>Page</Trans>}
                    {type === "instagram_account" && <Trans>Instagram account</Trans>}
                  </>
                }
              />
            </div>
          ))}

        {instagramOpenAccount && (
          <div css={classes.form__row}>
            <UiTextField
              disabled
              value={`https://instagram.com/${instagramOpenAccount}`}
              fullWidth
              label={<Trans>Page</Trans>}
            />
          </div>
        )}

        {pipeline.source === EPipelineType.FB_ADS && <FbAdsAttributes rowCss={classes.form__row} pipeline={pipeline} />}

        {user.active_subscription && isSuitableSubscription(ETariffOnPeriodSign.START) && pipeline.run_delta && (
          <div css={classes.form__row}>
            <FrequencyUpdate
              runDelta={form.run_delta}
              subscription={user.active_subscription}
              onChange={handleChangeForm("run_delta")}
            />
          </div>
        )}

        {[EPipelineType.FB_ADS, EPipelineType.INST_MEDIA, EPipelineType.INST_BA_MEDIA].includes(pipeline.source) && (
          <Divider sx={{ mb: 3, mt: 4 }} />
        )}

        {(EPipelineType.FB_ADS === pipeline.source || EPipelineType.VK_ADS === pipeline.source) && (
          <div css={classes.form__row}>
            <AdsYaCounter
              isOwner={Boolean(credInfo?.own)}
              source={pipeline.source}
              enabled={form.yandexMetrikaIsEnabled}
              settings={form.yandexMetrikaSettings}
              onToggle={handleChangeForm("yandexMetrikaIsEnabled")}
              onChange={handleChangeForm("yandexMetrikaSettings")}
              pipeline={pipeline}
              persistState={persistState}
            />
          </div>
        )}

        {[EPipelineType.INST_MEDIA, EPipelineType.INST_BA_MEDIA].includes(pipeline.source) && (
          <div css={classes.form__row}>
            <InstFbAds
              isOwner={Boolean(credInfo?.own)}
              credentialId={credentialId}
              enabled={form.fbAdsAccountEnabled}
              projectId={pipeline.project}
              adAccountId={form.fbAdsAccountSettings?.id}
              onToggle={handleChangeForm("fbAdsAccountEnabled")}
              onChange={handleChangeForm("fbAdsAccountSettings")}
              persistState={persistState}
            />
          </div>
        )}

        {[EPipelineType.VK_COMMUNITY, EPipelineType.VK_POST, EPipelineType.VK_COMMUNITY_POST].includes(pipeline.source) &&
          vkGroupId &&
          credentialIds?.[0] && (
            <div css={classes.form__row}>
              <VkGroupToken
                isOwner={Boolean(credInfo?.own)}
                pipeline={pipeline}
                enabled={form.vkGroupIsEnabled}
                groupCredentialId={form.vkGroupCredentialId}
                groupId={vkGroupId}
                onToggle={handleChangeForm("vkGroupIsEnabled")}
                onChange={handleChangeForm("vkGroupCredentialId")}
                persistState={persistState}
                credentialId={credentialIds?.[0]}
              />
            </div>
          )}

        {pipeline.report_links?.inhouse && (
          <div css={classes.form__row}>
            <Stack direction="row" spacing={0.6} alignItems="center">
              <span>
                <Trans>Access to the report via link</Trans>
              </span>

              <Tooltip
                title={
                  <Trans>
                    Anyone who has a link to the report will have access to the report via the link. To disable a link, disable
                    access.
                  </Trans>
                }
              >
                <IconButton size="extraSmall">
                  <IconInfoCircle />
                </IconButton>
              </Tooltip>
            </Stack>

            <ReportSharing pipelineId={pipeline.id} reportLink={pipeline.report_links.inhouse} />
          </div>
        )}

        <div css={classes.saveBtn}>
          <UiButton type="submit" disabled={!hasChanges} loading={isLoading}>
            <Trans>Save changes</Trans>
          </UiButton>
        </div>
      </form>
    </>
  );
};

export default ModalPipelineEditCommon;
