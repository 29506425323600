import { t } from "@lingui/macro";
import { EProjectRole } from "src/enums/project";

export default function getRoleDescription(role: EProjectRole) {
  return {
    [EProjectRole.OWNER]: t`You can do anything) Manage projects, create and delete datasets, use them for reports, and share project credentials`,
    [EProjectRole.ADMIN]: t`You can create and delete datasets, use them to create reports and share your project credentials`,
    [EProjectRole.USER]: t`You can use ready-made datasets to create reports`,
  }[role];
}
