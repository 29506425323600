import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FC, ReactNode } from "react";

import * as classes from "./index.css";

interface IProps {
  icon?: ReactNode;
  title?: ReactNode;
  message: ReactNode;
}

const Message: FC<IProps> = ({ icon, title, message }) => (
  <Paper css={classes.paper}>
    {icon && (
      <Grid container justifyContent="center" css={classes.icon}>
        {icon}
      </Grid>
    )}
    {title && (
      <Typography variant="h1" align="center" css={classes.title}>
        {title}
      </Typography>
    )}
    <Typography component="div" align="center">
      {message}
    </Typography>
  </Paper>
);

export default Message;
