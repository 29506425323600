import { css } from "@emotion/react/macro";
import { Theme } from "@mui/material";

export const actions = (theme: Theme) => css`
  justify-content: flex-end;
  
  ${theme.breakpoints.down("md")} {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: -15px;
    
    .MuiButton-root {
      margin-left: 0;
    }
  },
`;

export const btnDelete = css`
  color: #757575;

  :hover {
    color: #000000;
  }
`;

export const health = (theme: Theme) => css`
  color: ${theme.palette.secondary.dark};

  :before {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;
    background: currentColor;
    border-radius: 50%;
    position: relative;
    top: -1px;
  }
`;

export const health_bad = (theme: Theme) => css`
  color: ${theme.palette.secondary.dark};
`;

export const health_good = (theme: Theme) => css`
  color: ${theme.palette.aqua.main};
`;
