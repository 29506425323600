import { EPipelineMedia } from "src/enums/pipeline";
import { ETmplSource } from "src/enums/tmpl";
import { IAggTmpl, ITmpl } from "src/types/tmpl";
import { serializeFilterParameters } from "src/utils/filterParameters";
import useQueryBase, { IUseQueryBaseOptions } from "src/utils/queries/useQueryBase";

interface IResponse {
  count: number;
  results: ITmpl[] | undefined;
}

const QUERY_KEY = "tmpls";

export default function useQueryTmpls(
  queryOptions?: IUseQueryBaseOptions<IResponse, IAggTmpl[] | undefined>,
  filter?: {
    tmplUseOnWelcomePage?: boolean;
  },
) {
  return useQueryBase<IResponse, IAggTmpl[] | undefined>(
    [QUERY_KEY, filter],
    {
      ...queryOptions,
      select: (data: IResponse) =>
        data?.results?.map((tmpl) => ({
          ...tmpl,
          source: tmpl.source.map(
            (media) =>
              ({
                [ETmplSource.MERGE]: EPipelineMedia.DATAFAN,
                [ETmplSource.MERGE_PLAN_FACT]: EPipelineMedia.DATAFAN,
                [ETmplSource.FB]: EPipelineMedia.FB,
                [ETmplSource.OK]: EPipelineMedia.OK,
                [ETmplSource.GOOGLE]: EPipelineMedia.GOOGLE,
                [ETmplSource.INSTAGRAM]: EPipelineMedia.INSTAGRAM,
                [ETmplSource.MYTARGET]: EPipelineMedia.MYTARGET,
                [ETmplSource.VK]: EPipelineMedia.VK,
                [ETmplSource.TIKTOK]: EPipelineMedia.TIKTOK,
                [ETmplSource.TIKTOK_ADS]: EPipelineMedia.TIKTOK,
              }[media]),
          ),
        })),
    },
    {
      method: "GET",
      params: {
        filtered: serializeFilterParameters(filter),
        limit: 1000,
        offset: 0,
      },
      url: `tmpls/`,
    },
  );
}
