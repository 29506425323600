import { css } from "@emotion/react/macro";
import { Trans } from "@lingui/macro";
import { Stack } from "@mui/material";
import { IconBriefcase } from "@tabler/icons";
import React, { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SelectProjectOption from "src/components/ModalPipelineEdit/components/SelectProjectOption";
import * as classes from "src/components/ModalPipelineEdit/index.css";
import PipelineTypeList, { IItemProps } from "src/components/PipelineTypeList";
import UiButton from "src/components/UiButton";
import UiSelect from "src/components/UiSelect";
import UiTextField from "src/components/UiTextField";
import { EPipelineType } from "src/enums/pipeline";
import { EProjectRole } from "src/enums/project";
import { EPipelineSubType } from "src/redux";
import { IPipeline, IPipelineExtended } from "src/types/pipeline";
import { IProject, IProjectExtended } from "src/types/project";
import { IStream } from "src/types/stream";
import { IUser } from "src/types/user";
import generateMergeConfig from "src/utils/generateMergeConfig";
import generateMergePlanFactConfig from "src/utils/generateMergePlanFactConfig";
import useQueryPipelineTypes from "src/utils/queries/dataset/useQueryPipelineTypes";
import { IUseQueryPipelinePatchParams } from "src/utils/queries/pipeline/useQueryPipelinePatch";
import useQueryProjects from "src/utils/queries/project/useQueryProjects";

import SelectboxChip from "./components/SelectboxChip";
import SelectboxMenu from "./components/SelectboxMenu";

interface IProps {
  pipeline: IPipelineExtended;
  onSubmit: (params: IUseQueryPipelinePatchParams) => void;
  isLoading: boolean;
  user: IUser;
  owner: IUser | undefined;
}

interface IPersistState {
  selectedAds?: number[];
  selectedPublics?: number[];
}

const ModalPipelineEditMerge: FC<IProps> = ({ pipeline, onSubmit, isLoading, user, owner }) => {
  const [name, setName] = useState(pipeline.name);
  const [projectId, setProjectId] = useState<IProject["id"] | null>(pipeline.project);
  const [newPipelineGroup, setNewPipelineGroup] = useState<undefined | "ads" | "public">();
  const currentPipelineIds = useMemo(
    () =>
      pipeline.streams.reduce(
        (acc, stream) => {
          if (stream.config_source.streams) {
            Object.values(stream.config_source.streams).forEach((subStream) => {
              if (stream.config_source.group === "ads" && !acc.ads.includes(subStream.pipelineId)) {
                acc.ads.push(subStream.pipelineId);
              } else if (!acc.publics.includes(subStream.pipelineId)) {
                acc.publics.push(subStream.pipelineId);
              }
            });
          }

          return acc;
        },
        {
          ads: [],
          publics: [],
        } as {
          ads: number[];
          publics: number[];
        },
      ),
    [pipeline],
  );
  const location = useLocation<IPersistState | undefined>();
  const [selectedAds, setSelectedAds] = useState<number[] | null>(
    location.state?.selectedAds || currentPipelineIds.ads || null,
  );
  const [selectedPublics, setSelectedPublics] = useState<number[] | null>(
    location.state?.selectedPublics || currentPipelineIds.publics || null,
  );
  const { data: projects, isLoading: projectsIsLoading } = useQueryProjects();
  const { data: pipelineTypes, isLoading: pipelineTypesIsLoading } = useQueryPipelineTypes();

  const existingStreamIds = useMemo(
    () =>
      pipeline.streams.reduce((acc: { [group: string]: IStream["id"] }, { id, config_source }) => {
        if (config_source.group) {
          acc[config_source.group] = id;
        }

        return acc;
      }, {}),
    [pipeline.streams],
  );

  const projectOptions = useMemo(
    () =>
      projects?.map((project) => ({
        data: project,
        isDisabled: project.role !== EProjectRole.OWNER,
        label: project.name,
        value: project.id,
      })),
    [projects],
  );

  const project = useMemo(() => projects?.find(({ id }) => id === pipeline.project), [pipeline.project, projects]);

  const pipelines = useMemo(() => projects?.map(({ pipelines }) => pipelines).flat(), [projects]);

  const pipelinesNormalized = useMemo(
    () =>
      pipelines?.reduce((acc, pipeline) => {
        acc[pipeline.id] = pipeline;
        return acc;
      }, {} as { [key in IPipeline["id"]]: IProjectExtended["pipelines"][number] }),
    [pipelines],
  );

  const adsOptions = useMemo(
    () =>
      (project &&
        pipelineTypes &&
        pipelines
          ?.filter(({ source }) => pipelineTypes[source].subType === EPipelineSubType.ADS)
          .map((pipeline) => ({
            data: pipeline,
            label: pipeline.name,
            value: pipeline.id,
          }))) ||
      [],
    [pipelineTypes, pipelines, project],
  );

  const publicOptions = useMemo(
    () =>
      (project &&
        pipelineTypes &&
        pipelines
          ?.filter(({ source }) => pipelineTypes[source].subType === EPipelineSubType.MULTIPIPELINE)
          .map((pipeline) => ({
            data: pipeline,
            label: pipeline.name,
            value: pipeline.id,
          }))) ||
      [],
    [pipelineTypes, pipelines, project],
  );

  const handleOpenPipelinesModal = useCallback(
    (group: "ads" | "public") => () => {
      setNewPipelineGroup(group);
    },
    [],
  );

  const handleClosePipelinesModal = useCallback(() => {
    setNewPipelineGroup(undefined);
  }, []);

  const pipelineTypeListFilter = useCallback((pt) => pt.group === newPipelineGroup, [newPipelineGroup]);

  const renderListItemComponent = useCallback(
    ({ sourceId, children }: IItemProps) => (
      <Link
        to={{
          pathname: `/dataset-create/${sourceId}/`,
          state: {
            nextState: {
              pathname: location.pathname,
              state: {
                selectedAds,
                selectedPublics,
              },
            },
          },
        }}
        css={css`
          text-decoration: none;
        `}
      >
        {children}
      </Link>
    ),
    [location.pathname, selectedAds, selectedPublics],
  );

  const isValid = useMemo(
    () => name !== "" && (selectedAds?.length || 0) + (selectedPublics?.length || 0) >= 2,
    [name, selectedAds, selectedPublics],
  );

  const handleSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (pipelinesNormalized && projectId) {
        const selectedAdsPipelines = selectedAds?.map((id) => pipelinesNormalized[id]) || [];
        const selectedPublicPipelines = selectedPublics?.map((id) => pipelinesNormalized[id]) || [];
        const selectedPipelines = [...selectedAdsPipelines, ...selectedPublicPipelines];
        const generator = pipeline.source === EPipelineType.MERGE ? generateMergeConfig : generateMergePlanFactConfig;
        const config: any = pipelineTypes && selectedPipelines && generator(selectedPipelines, pipelineTypes);

        onSubmit({
          config: config.map((configItem: any) => ({
            ...configItem,
            id: existingStreamIds[configItem.group as EPipelineSubType],
          })),
          name,
          project: projectId,
        });
      }
    },
    [
      existingStreamIds,
      name,
      onSubmit,
      pipeline.source,
      pipelineTypes,
      pipelinesNormalized,
      projectId,
      selectedAds,
      selectedPublics,
    ],
  );

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <div css={classes.form__row}>
          <UiTextField onChange={setName} value={name} fullWidth label={<Trans>Name of the public / ad account</Trans>} />
        </div>

        {owner && (
          <div css={classes.form__row}>
            <UiTextField fullWidth disabled value={owner.humand_name} label={<Trans>Creator</Trans>} />
          </div>
        )}

        {user.id === pipeline.user && (
          <div css={classes.form__row}>
            <UiSelect
              fullWidth
              label={
                <Stack direction="row" spacing={0.6}>
                  <IconBriefcase width={18} />
                  <span>
                    <Trans>Project</Trans>
                  </span>
                </Stack>
              }
              value={projectId}
              options={projectOptions}
              onChange={setProjectId}
              components={{
                Option: SelectProjectOption,
              }}
            />
          </div>
        )}

        <div css={classes.form__row}>
          <UiSelect
            fullWidth
            isMulti
            isLoading={projectsIsLoading || pipelineTypesIsLoading}
            value={selectedAds}
            options={adsOptions}
            onChange={setSelectedAds}
            label={<Trans>Ads</Trans>}
            components={{
              Menu: SelectboxMenu(handleOpenPipelinesModal("ads")),
              MultiValueLabel: SelectboxChip,
            }}
          />
        </div>

        <div css={classes.form__row}>
          <UiSelect
            fullWidth
            isMulti
            isLoading={projectsIsLoading || pipelineTypesIsLoading}
            value={selectedPublics}
            options={publicOptions}
            onChange={setSelectedPublics}
            label={<Trans>Public</Trans>}
            components={{
              Menu: SelectboxMenu(handleOpenPipelinesModal("public")),
              MultiValueLabel: SelectboxChip,
            }}
          />
        </div>

        <div css={classes.saveBtn}>
          <UiButton type="submit" disabled={!isValid} loading={isLoading}>
            <Trans>Save changes</Trans>
          </UiButton>
        </div>
      </form>

      <PipelineTypeList
        open={Boolean(newPipelineGroup)}
        handleClose={handleClosePipelinesModal}
        filter={pipelineTypeListFilter}
        ListItemComponent={renderListItemComponent}
      />
    </>
  );
};

export default ModalPipelineEditMerge;
